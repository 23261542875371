import { Component, OnInit, ViewChild } from '@angular/core';
import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from 'src/app/valueObject/config';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { EnitTradeRequestListService } from 'src/app/service/enit-trade-request-list.service'
import { EnitService } from 'src/app/service/enit.service'
import { UserDetailsService } from 'src/app/service/user-details.service';
@Component({
  selector: 'app-neat-nonneat-report-admin',
  templateUrl: './neat-nonneat-report-admin.component.html',
  styleUrls: ['./neat-nonneat-report-admin.component.css']
})
export class NeatNonneatReportAdminComponent implements OnInit {
  dtOptions: any;
  config: any = config;
  nonNeatReqsts: any= [];
  dtTrigger: Subject<any> = new Subject<any>();
  viewUser: any = {};
  entityTypeData: any;
  keyword: string = "name";
  access: any = {};
  menuCode: string = 'userlist';
  text: string;
  showMore: boolean;
  entityType="member"
  memId : any;
  comments: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  userIdRequestSearch: any;
  newNeatReqData : any[]=[];
  searchForm :FormGroup;
  user: any = {};
  memCode: any;
  memName: any;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private enitTradeService: EnitTradeService,
    private fb: FormBuilder,
    private enitService: EnitService,
    private userDetailsService: UserDetailsService,
  ) { }
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails(); //REMOVE THIS IF ITS NOT WORKING PROPERLY
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.titleService.setTitle('Neat Non-Neat Report');
    this.displayList();
    this.searchForm=this.fb.group({
      reqType:[],
    })
    this.dtOptions = {
      responsive: true,
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      buttons: [
        {
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }
        },
      ]
    };
  }
  clearSearch() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function (col) {
        $('#userAccessList .column-search input').val('');
        $('#userAccessList .column-search select').val('.*');
        $('#userAccessList').DataTable()
          .column(col)
          .search('', true)
          .draw();
      })
    })
  }
  get f(){
    return this.searchForm.controls;
  }
  reset() {
    this.userIdRequestSearch.reset();
  }
  ngAfterViewInit() {
    // this.getUserList();
    // this.displayList();
  }
  displayList() {
    this.spinner.show();
    $("#NonNeatRequestList").DataTable().destroy();
    this.enitService.getNonNeatReportList().pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.nonNeatReqsts = [];
        this.dtTrigger.next(true);
        this.setSearchFunction();
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.nonNeatReqsts = response.data;
          this.enitTradeService.addNeatUser(this.nonNeatReqsts)
          this.dtTrigger.next(true);
          this.setSearchFunction();
          this.cdref.detectChanges();
        } else {
          this.toastr.error(response.message);
          this.dtTrigger.next(true);
          this.setSearchFunction();
          this.cdref.detectChanges();
        }
      }
    );  
  }
  setSearchFunction() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        $('#NonNeatRequestList .column-search').on('keyup change', 'input', function () {
          $('#NonNeatRequestList').DataTable()
            .column($(this).parent().index())
            .search(this.value)
            .draw();
        });
      });
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        $('#NonNeatRequestList .column-search').on('keyup change', 'select', function () {
          $('#NonNeatRequestList').DataTable()
            .column($(this).parent().index())
            .search("^" + this.value + "$", true, false, false)
            .draw();
        });
      });
    });
  }
  viewUserData(memId: number) {
    this.viewUser = this.nonNeatReqsts.find(nonNeatReqsts => nonNeatReqsts.memId == memId);
  }
  editUserData(memId: number) {
    this.router.navigate([''], { "queryParams": { "memId": memId } });
  }
  ngOnDestroy(): void {
  }
  processNonNeatRequest(num_REF_NO: number) {
    this.router.navigate(['tradeAdmin/eUseridRequestA/displayNonNeatReport/NonNeatRequestProcessPage'], { "queryParams": { "num_REF_NO": num_REF_NO } });
  }
}
