<!-- <p>profile works!</p> -->
<div class="card card-body">
    <!-- <div class="profile">
       <h5><strong>User Name:</strong></h5><span>{{user.userName}}</span><br>
       <h5><strong>Entity Code:</strong></h5><span>{{user.entityCode}}</span><br>
       <h5><strong>Entity Type:</strong></h5><span>{{user.entityType}}</span><br>
       <h5><strong>Email Address:</strong></h5><span>{{user.userEmail}}</span><br>
       <h5><strong>Mobile Number:</strong></h5><span>{{user.userMobile}}</span><br>
    </div> -->

    <div class="row ">

        <div class="col-lg-12">
            <div class="icon-head">
                <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" fill="currentColor"
                    class="bi bi-person-circle icon-increase" viewBox="0 0 20 20">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg>
            </div>
        </div>
    </div>
    <br>
    <!-- <div class="row">
        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
            <h6>{{viewUser.userName}}</h6>
            <p>{{viewUser.userLoginId}}</p>
        </div>
    </div> -->
    <div class="row font">
        <div class="col-lg-12" >
            <p><strong>User Name : </strong> <span>{{user.userName}}</span></p>
        </div>
        <div class="col-lg-12">
            <p><strong>Entity Code : </strong> <span>{{user.entityCode}}</span></p>
        </div>
        <!-- <div class="col-lg-12">
            <p><strong>Entity Type : </strong> <span>{{user.entityType}}</span></p>
         </div> -->
        <div class="col-lg-12" >
            <p><strong>Email Id : </strong> <span>{{user.emailId}}</span></p>
        </div>
        <div class="col-lg-12" >
            <p><strong>Mobile Number : </strong> <span>{{user.userMobile}}</span></p>
        </div>
        
    </div>
    </div>
