import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { CollateralService } from 'src/app/service/collateral.service';
import { config } from 'src/app/valueObject/config';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError, elementAt } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';

interface rejectionReasonsArray{
  value: number;
  desc: string;
}
@Component({
  selector: 'app-neat-nonneat-process-page-admin',
  templateUrl: './neat-nonneat-process-page-admin.component.html',
  styleUrls: ['./neat-nonneat-process-page-admin.component.css']
})
export class NeatNonneatProcessPageAdminComponent implements OnInit {
  reqObj: any;
  nonNeatReq : FormGroup;
  status: boolean = false;
  myDataObj: any = {};
  submitFlag: number = 0;
  show : boolean ;
  submitted: boolean = false;
  config: any = config;
  entityType: any;
  memCode: any;
  memName: any;
  menucode ="NonNeatRequestProcessPage";
  user: any = {};
  access: any = {};
  date: Date = new Date();
  proUndertaking : any;
  rejectionReasons: rejectionReasonsArray[];
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private enitService: EnitService,
    private userDetailsService:UserDetailsService,
    private titleService: Title,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['num_REF_NO']) {
        this.reqObj = enitTradeService.getNonNeatRequest(params['num_REF_NO']);
      }
    });
   }
   get f(){
    return this.nonNeatReq.controls;
  }
  
  ngOnInit(): void {
  
    
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.titleService.setTitle('Neat Non-Neat Process Page');
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;

    if(this.reqObj.nud_PRO_UNDERTAKING == 'N'){
      this.proUndertaking = "No"
    }
    else if (this.reqObj.nud_PRO_UNDERTAKING == 'Y'){
      this.proUndertaking = "Yes"
    }
    else if (this.reqObj.nud_PRO_UNDERTAKING == null){
      this.proUndertaking = ""
    }
    this.nonNeatReq =this.formbuilder.group({
    memCode : [10000+this.reqObj.num_MEM_ID],
      memName : [this.reqObj.mem_NM],
      refNo : [this.reqObj.num_REF_NO],
      productType : [this.reqObj.nud_PRODUCT_TYPE],
      version : [this.reqObj.nud_SW_VER],
      tapIp : [this.reqObj.nud_TAP_IP],
      nud_PRO_ENABLED : [this.reqObj.nud_PRO_ENABLED],
      nud_PRO_UNDERTAKING : [this.proUndertaking],
      currentStatus : [this.reqObj.num_STATUS],
      updateStatus : ['', Validators.required],
      rejReason : [''],
      reasons: this.formbuilder.array([]),
    })

    this.rejectionReasons = [
      {value: 1, desc: "Stamp Paper/Franking should be of Rs.300/- or as applicable, whichever is higher"},
      {value: 2, desc: "Under Processing"},
      {value: 3, desc: "Stamp paper should be purchased in name of member should be executed in favor of NSEIL"},
      {value: 4, desc: "Date of execution to be mentioned on the Undertaking"},
      {value: 5, desc: "Undertaking to be executed within validity of stamp paper (Date of execution is on or after the date of, and is within six months of, the stamp paper purchase date)."},
      {value: 6, desc: "Clauses of Undertaking should be as per format."},
      {value: 7, desc: "Details of witness (Name, address and signature) should be provided."},
      {value: 8, desc: "Undertaking should be duly notarised (notary||chr(39)||s stamp on all pages)"},
      {value: 9, desc: "Undertaking should be signed by authorised person/s of the company / firm on all pages (as per board resolution)"},
      {value: 10, desc: "Common seal (for Corporates only) should be affixed on the undertaking in the presence of all authorized signatories"},
      {value: 11, desc: "Copy of board resolution (for Corporates only) should state name , designation and specimen sign of authorised person/s"},
      {value: 12, desc: "Copy of board resolution (for Corporates only) should state specifically in whose presence common seal to affixed on the undertkaing"},
      {value: 13, desc: "Date of Board meeting should be mentioned in the last paragraph of undertaking should match with date of board resolution."},
      {value: 14, desc: "Network diagram should be duly notarised and signed by authorised person/s."},
      {value: 15, desc: "Other"},
     
    ]
    this.setFormData();
  }

  setFormData(){
    let array = this.formbuilder.array([]);
    // this.rejectionReasons.forEach((element, index)=>{
    //   array.push(this.formbuilder.group({
    //     checked: false,
    //     value:[element.value],
    //     desc:[element.desc],
    //   }))
    // })
    // this.f.reasons = array;
  }

  showDiv(){
    if (this.f.updateStatus.value=='REJECTED') {
      this.show=true;
      this.f.rejReason.setValidators([Validators.required]);
      this.f.rejReason.updateValueAndValidity();
    }else if (this.f.updateStatus.value=='APPROVED') {
      this.show=false;
      this.f.rejReason.clearValidators();
      this.f.rejReason.updateValueAndValidity();
    }
  }

  back(){
    this.router.navigate(['tradeAdmin/eUserIDRequestA/displayNonNeatReport'])
  }

  reset(){
    this.f.updateStatus.setValue(null);
    this.f.rejReason.setValue(null);
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    let requestObject = {
      //"rejection":[],
      numMemId: this.f.memCode.value-10000,
      numStatus: this.f.updateStatus.value,
      numRefNo: this.f.refNo.value,
      numUpdBy: 'NSE ADMIN',
      numUpdDt: this.date,
      numReasonReturn: this.f.rejReason.value,
    }
    // this.f.reasons['controls'].forEach((element, index)=>{
    //   if(element.value.checked){
    //     requestObject.rejection.push({
    //       "rejreason": element.value.desc
    //     })
    //   }
    // })
    this.enitService.saveNonNeatReporttData(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
        this.submitted = false;
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        if(this.f.updateStatus.value=="REJECTED"){
          this.toastr.success("Request rejected")
          this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: true });
        }
        else if(this.f.updateStatus.value=="APPROVED"){
          this.toastr.success("Request approved");
          this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: true });
        }
        
        this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: true });
        this.submitted = true;
      } else {
        this.toastr.error(response.message);
        this.submitted = false;
      }
    })
  }
}
