import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/app/valueObject/config';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CyberSecurityService {

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  config: any = config;
  constructor(private http: HttpClient) { }

  updateQuaterlyIncidentForm(incidentFormObject) {
    let URL = environment.URL + this.config.URL.quaterlyIncidentReport;
    return this.http.post(URL, incidentFormObject, this.options);
  }

  getEnable(memId: number){
    let URL = environment.URL + this.config.URL.getEnable + "?" + "memId=" + memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options)
  }

  fetchIncidentIdReport(memId:any,quater:any,year:any){
    let URL = environment.URL + this.config.URL.fetchIncidentIdReport + "/" + memId + "/" +"?" + "quarter="+ quater + "&year="+year;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options)
  }
}
