import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  constructor(private userDetailsService: UserDetailsService, private router: Router, private toastr: ToastrService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let access = this.userDetailsService.checkUserAccess(next.routeConfig.path || '') || {};
      if(access.isAccessAllowed=='Y'){
        return true;
      }else{
        this.router.navigate(['']);
        this.toastr.warning("You are not authorised to view this page");
        return false;
      }
    
  }

}
