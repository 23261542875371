<header>
    <div class="container-fluid">
        <div class="d-flex">
            <div class="logo"><img src="assets/images/NSE_IX-logo.png"></div>
            <div class="positionRight d-flex">
                <div class="d-flex justify-content-end">
                    <!-- <div class="dropdown changepass">
                        <a href="" class="mb-0 dropdown-toggle contact" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="d-inline-flex">
                                User Manual<span class="caret"></span>
                            </div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item pointer forgot-pass" (click)="openUserManual('c2n')">C2N User Manual
                            </a>
                            <a class="dropdown-item pointer forgot-pass" (click)="openUserManual('cel')">CEL User Manual
                            </a>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="logo"><img src="assets/images/nselogo.gif"></div> -->
            </div>
        </div>

    </div>
</header>
<!-- <div class="col-lg-12 login_back"> -->
    <!-- <div class="row login-slider"> -->
        <!-- <div class="col-md-5 backgroundimg offset-sm-2">
            <div id="loginslider" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <h1>Margins</h1>
                        <p>NSE IFSC Clearing Corporation Limited (NICCL) has developed a comprehensive risk containment
                            mechanism.
                            The most critical component of a risk containment mechanism for NICCL is the online position
                            monitoring and margining system. </p>
                        <p>The actual margining and position monitoring is done on-line, on an intra-day basis. NICCL,
                            uses the SPAN® (Standard Portfolio Analysis of Risk) system for the purpose of margining,
                            which is a portfolio based system.
                        </p>
                    </div>
                    <div class="carousel-item">
                        <h1>Collateral</h1>
                        <p>Clearing members are required to provide liquid assets which adequately cover various margins
                            and liquid net worth requirements.</p>
                        <p>A clearing member may deposit liquid assets in the form of cash and fixed deposit receipts
                            and any other form of collateral as may be prescribed from time to time.
                        </p>
                    </div>
                    <div class="carousel-item">
                        <app-contact></app-contact>
                    </div>
                </div>
                <ol class="carousel-indicators">
                    <li data-target="#loginslider" data-slide-to="0" class="active"></li>
                    <li data-target="#loginslider" data-slide-to="1"></li>
                    <li data-target="#loginslider" data-slide-to="2"></li>
                </ol>
            </div>
        </div> -->
        <!-- <div class="col-md-4 form-section">
            <div class="login-inner-form">

                <router-outlet></router-outlet>
            </div>
        </div> -->

    <!-- </div> -->
<div class="row login-slider1">
<div class="col-lg-8 login_back">
    
    <div class="col-md-7 ">
        <div id="loginslider" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <h1>ENIT</h1>
                    <p>The Exchange has provided a facility ENIT or Electronic NSE Interface for Trading Members which is a fully electronic, internet enabled application. 
                        This interface provides a two way mechanism for exchange of information for various processes between the Trading Members and the Exchange.  </p>
                </div>
                <!-- <div class="carousel-item">
                    <h1>UCI</h1>
                    <p>The UCC system is for identifying and tracking clients in the securities market. Each investor or client who wishes to participate in trading activities is assigned a unique identification code by their trading member (broker). 
                        The UCC helps in maintaining client-level data, tracking transactions, and ensuring compliance with regulatory requirements.</p>
                -->
                    <!-- <p>Under the UCC system, each investor or client who wishes to participate in trading activities is assigned a unique identification code by their trading member (broker). The UCC helps in maintaining client-level data, 
                        tracking transactions, and ensuring compliance with regulatory requirements.
                    </p>
                    <p>
                        Client identification and tracking process is an integral part of the systems and processes employed by NSE IFSC and its trading members to ensure compliance and maintain an orderly and transparent trading environment.
                    </p> -->
                <!-- </div> -->
                <div class="carousel-item">
                    <h1>Extranet</h1>
                    <p>The Extranet Module is an electronic platform that facilitates secure communication and data exchange between NSE and Market Participants (Clearing  & Trading Members). It provides a secure and reliable channel for transmitting market data, trade-related information, reports, and other critical data between NSE and its members.</p>
                    <p>Some key features and functions of the Extranet Module may include:-
                        Market Data Distribution | Order Placement and Execution | Risk Management | Clearing and Settlement | Reporting and Compliance</p>
                    <!-- <p>
                        Some key features and functions of the Extranet Module may include:
                        Market Data Distribution | Order Placement and Execution | Risk Management | Clearing and Settlement | Reporting and Compliance                        
                    </p> -->
                </div>
                <div class="carousel-item">
                    <h1>Inspection</h1>
                    <p>Its a regulatory framework.It is designed to ensure compliance by trading members (brokers) with the rules, regulations, and guidelines set forth by NSE IFSC and regulatory authorities such as IFSCA.</p>
                    <p>The inspection module involves regular inspections and audits of trading members' operations, systems, and processes. The primary objective of these inspections is to assess the adequacy and effectiveness of the trading members' internal controls, risk management systems, and adherence to regulatory requirements.
                    </p>
                    <p>
                        The inspection module covers various aspects, including but not limited to:
                        Risk Management | Compliance | Trading Systems and Infrastructure | Financial and Operational Controls | Investor Protection                                                                   
                    </p>
                    <!-- <p>
                        The inspection module helps maintain market integrity, safeguard investor interests, and promote a transparent and fair trading environment. 
                        It assists in identifying any deficiencies or violations and enables appropriate corrective actions or disciplinary measures, if required.
                    </p> -->
                </div>
                <!-- <div class="carousel-item">
                    <app-contact></app-contact>
                </div> -->
            </div>
            
        </div>
    </div>
</div>
<div class="col-lg-4 login-form">
    <div class="form-section">
        <div class="login-inner-form1">

            <router-outlet></router-outlet>
        </div>
    </div>
</div>
</div>


    <div class="copyright">
        Best viewed with Chrome browser. Copyright © 2022 NSE IFSC Limited. All rights reserved
    </div>
<!-- </div> -->