import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-et-messsage-rate-admin',
  templateUrl: './et-messsage-rate-admin.component.html',
  styleUrls: ['./et-messsage-rate-admin.component.css']
})
export class EtMesssageRateAdminComponent implements OnInit {
  searchForm: FormGroup;
  config: any = config;
  messageReport: any = []
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bsValue: any;
  bsValue1: any;
  entityType: string;
  memName: string
  memCode: string;
  mrcList: any = [];
  refTypeList: any = [];
  refStatusList: any = [];
  memAndNameList: any = [];
  fDate: any;
  tDate: any;
  selectedMember: any;
  selectedCode: any;
  menuCode: string = 'displayMRCReport';
  user: any = []
  maxDate: Date = new Date();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private enitService: EnitTradeService,
    private toastr: ToastrService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private datepipe: DatePipe,
  ) { }
  get f() {
    return this.searchForm.controls
  }
  ngOnInit(): void {
    this.titleService.setTitle('Message rate Change MIS');
    this.user = this.userDetailsService.getUserDetails();
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.getDropDowndata();
    $('.selectpicker').selectpicker();
    this.titleService.setTitle('Message Rate Change MIS');
    this.searchForm = this.fb.group({
      name: [''],
      memCode: [''],
      requestType: [''],
      requestStatus: [''],
      requestFDate: [''],
      requestTDate: [''],
      referenceNo: ['']
    })
    this.onSubmit();
  }
  getDropDowndata() {
    this.enitService.getDropdownData().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.refTypeList = [];
        this.refStatusList = [];
        this.memAndNameList = [];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.refTypeList = response.data.refReqTypeList;
        this.refStatusList = response.data.refStatusList;
        this.memAndNameList = response.data.memAndNameList;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }

  onMemberChange(){
    this.selectedCode = this.memAndNameList.find(data => data.spDesc == this.f.name.value);
    this.f.memCode.setValue(this.selectedCode.spMemId);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    }
  
    onCodeChange(){
      this.selectedMember = this.memAndNameList.find(data => data.spMemId == this.f.memCode.value);
      this.f.name.setValue(this.selectedMember.spDesc);
      this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    }
    onSubmit() {
      this.spinner.show()
      $("#MIS").DataTable().destroy();
      this.fDate = this.datepipe.transform(this.f.requestFDate?.value, 'dd-MMM-yyyy');
      this.tDate = this.datepipe.transform(this.f.requestTDate?.value, 'dd-MMM-yyyy');
      let requestObject = {
        "memberIdAdminMis": this.f.memCode.value,
        "memberCodeAdminMis": null,
        "cusRequestType": this.f.requestType.value || null,
        "cusStatus": this.f.requestStatus.value || null,
        "fromDateStr": this.fDate || null,
        "toDateStr": this.tDate || null,
        "cusRefNo": this.f.referenceNo.value || null,
      }
      this.enitService.getMessageRateReport(requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.messageReport = [];
          this.setDatatable();
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((respone: any) => {
        this.spinner.hide();
        if (respone.statusCode == 200) {
          this.messageReport = respone.data;
          this.enitService.addMrcList(this.messageReport);
          this.setDatatable();
        }
        // else if (respone.statusCode == 404) {
        //   //this.toastr.warning("No Data Found");
        //   this.messageReport = [];
        //   this.dtTrigger.next(true);
        //   this.cdref.detectChanges();
        // }
        else {
          this.toastr.error(respone.message)
          this.messageReport = [];
          this.setDatatable();
        }
      })
    }
    setDatatable() {
      $("#MIS").DataTable().destroy();
      setTimeout(() => {
        $("#MIS").DataTable({
          responsive: true,
          processing: true,
          orderCellsTop: true,
          dom: "Blrtip",
          buttons: [{
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6,7],
            }
          }, {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6,7],
            }
          }, {
            extend: 'pdf',
            orientation: 'landscape',
            pageSize: 'A4',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6,7],
            }
          }],
          initComplete: function () {
            this.api().columns().every(function () {
              $('#MIS .column-search input').val('');
              $('#MIS .column-search select').val('.*');
              $('#MIS .column-search').on('keyup change', 'input', function () {
                $('#MIS').DataTable()
                  .column($(this).parent().index())
                  .search(this.value)
                  .draw();
              });
              $('#MIS .column-search').on('keyup change', 'select', function () {
                $('#MIS').DataTable()
                  .column($(this).parent().index())
                  .search("^" + this.value + "$", true, false, false)
                  .draw();
              });
            })
          }
        });
        this.cdref.detectChanges();
      }, 1);
    }
    onReset() {
      this.searchForm.reset();
      $('.selectpicker').selectpicker('refresh');
    }
    open(id: number) {
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayMRCReport/messageRateModal'], { "queryParams": { "id": id }})
    }

    ngOnDestroy() {
      this.dtTrigger?.unsubscribe();
    }
}
