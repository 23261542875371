import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { config } from '../valueObject/config';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MemberportalSsoService {

  config: any = config;

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  applicationList: any = [];
  memberPortalDetails: any = {};
  //envURL: string = window.location.protocol + '//' + window.location.hostname;

  envURL: string = window.location.protocol + '//' + window.location.hostname + ((window.location.hostname.includes('nseix') || window.location.hostname.includes('nseifsc')) ? '' : (':' + window.location.port))

  setApplicationList(menuList: any) {
    var menusSubList: any;
    //var applicationdetails: { menuCode: string, applicationName: string, ssosegment: string, ssosegreq: number, ssoservice: string, ssoSrvcAdmin: number, ssoCreate: string, cisRevampAppl: string }={};

    menuList.forEach(menus => {

      if (menus.sappType === "I") {
        if (menus?.userMenusImpls) {
          menusSubList = menus.userMenusImpls;
          menusSubList.forEach((submenus) => {
            if (submenus.sappType === "E") {
              var applicationdetails: any = {};
              applicationdetails.menuCode = submenus.menuCode
              applicationdetails.applicationName = submenus.sdmsUrl;
              applicationdetails.ssosegment = submenus.ssoSegment;
              applicationdetails.ssosegreq = submenus.sdmsSegReq;
              applicationdetails.ssoservice = submenus.svcName;
              applicationdetails.ssoSrvcAdmin = submenus.ssoSrvcadmin;
              applicationdetails.ssoCreate = submenus.ssoCreate;
              applicationdetails.cisRevampAppl = submenus.cisRevampAppl;
              this.applicationList.push(applicationdetails);
            }
          })
        }
      }

      if (menus.sappType === 'E') {
        if (menus?.userMenusImpls) {
          menusSubList = menus.userMenusImpls;
          menusSubList.forEach((submenus) => {
            if (submenus.sappType === "E") {
              var applicationdetails: any = {};
              applicationdetails.menuCode = submenus.menuCode
              applicationdetails.applicationName = submenus.sdmsUrl;
              applicationdetails.ssosegment = submenus.ssoSegment;
              applicationdetails.ssosegreq = submenus.sdmsSegReq;
              applicationdetails.ssoservice = submenus.svcName;
              applicationdetails.ssoSrvcAdmin = submenus.ssoSrvcadmin;
              applicationdetails.ssoCreate = submenus.ssoCreate;
              applicationdetails.cisRevampAppl = submenus.cisRevampAppl;
              this.applicationList.push(applicationdetails);
            }
          })
        }
        else {
          var applicationdetails: any = {};
          applicationdetails.menuCode = menus.menuCode
          applicationdetails.applicationName = menus.sdmsUrl;
          applicationdetails.ssosegment = menus.ssoSegment;
          applicationdetails.ssosegreq = menus.sdmsSegReq;
          applicationdetails.ssoservice = menus.svcName;
          applicationdetails.ssoSrvcAdmin = menus.ssoSrvcadmin;
          applicationdetails.ssoCreate = menus.ssoCreate;
          applicationdetails.cisRevampAppl = menus.cisRevampAppl;
          this.applicationList.push(applicationdetails);
        }
      }
    })

  }


  constructor(private http: HttpClient, private cookieService: CookieService) {

    let applicationDtls: { menuCode: string, applicationName: string, ssosegment: string, ssosegreq: number, ssoservice: string, ssoSrvcAdmin: number, cisRevampAppl: string }

    //   this.applicationList = [{
    //     menuCode: "eListing",
    //     applicationName: "LISTING",
    //   },
    //   {
    //     menuCode: "eListingCorp",
    //     applicationName: "LISTING"
    //   },
    //   {
    //     menuCode: "inspectionMember",
    //     applicationName: "inspection",
    //     ssosegment: "Domestic",
    //     ssosegreq: 4,
    //     ssoservice: "Inspection-Audit",
    //     ssoSrvcAdmin: 0,
    //     ssoCreate: "YES"
    //   },
    //   {
    //     menuCode: "internalAudit",
    //     applicationName: "inspection",
    //     ssosegment: "Domestic",
    //     ssosegreq: 4,
    //     ssoservice: "Inspection-Domestic",
    //     ssoSrvcAdmin: 0,
    //     ssoCreate: "YES"
    //   },
    //   {
    //     menuCode: "eEnit",
    //     applicationName: "ENITRevPortal",
    //     ssosegment: null,
    //     ssosegreq: 0,
    //     ssoservice: "ENIT-NEW-COMPLIANCE:ENIT-System Audit:ENIT-Revamp:ENIT-NEW-TRADE",
    //     ssoSrvcAdmin: 0,
    //     ssoCreate: "YES"
    //   },
    //   {
    //     menuCode: "eEnitRevamp",
    //     applicationName: "ENITRevPortal",
    //     ssosegment: null,
    //     ssosegreq: 0,
    //     ssoservice: "ENIT-NEW-COMPLIANCE:ENIT-System Audit:ENIT-Revamp:ENIT-NEW-TRADE",
    //     ssoSrvcAdmin: 0,
    //     ssoCreate: "YES"
    //   },
    //   {
    //     menuCode: "eEnitTradeRevamp",
    //     applicationName: "enit",
    //     ssosegment: null,
    //     ssosegreq: null,
    //     ssoservice: null,
    //     ssoSrvcAdmin: null,
    //     ssoCreate: null
    //   },
    //   {
    //     menuCode: "eMembership",
    //     applicationName: "LISTING"
    //   },

    //   {
    //     menuCode: "eUci",
    //     applicationName: "LISTING"
    //   },

    //   {
    //     menuCode: "eExtranet",
    //     applicationName: "LISTING"
    //   }];
  }

  submitUserIdRequest(menuCode: string) {
    const applicationDtls = this.applicationList.find(applicationCode => applicationCode.menuCode === menuCode);
    const applicationName = applicationDtls.applicationName;
    let frameQueryParam = "";
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    this.cookieService.set("ssoticketcookie", localStorage.getItem('token'), { path: "/" });

    if (`${applicationDtls['cisRevampAppl']}` === 'N') {
      if (`${applicationDtls['ssoCreate']}` === 'Y') {
        frameQueryParam = applicationName + "/ssoCreate?ssoticket=" + localStorage.getItem('token');
      }

      for (const applicationKey in applicationDtls) {
        if (applicationKey === 'ssoCreate' && `${applicationDtls[applicationKey]}` === 'null') {
          frameQueryParam = applicationName;
        }
        if (applicationKey === 'menuCode' || applicationKey === 'applicationName' || applicationKey === 'cisRevampAppl' || applicationKey === 'ssoCreate') {
          // ByPass these Property
        }
        else {
          if (`${applicationDtls[applicationKey]}` === 'null') {
            // No Concatenation
          }
          else {
            if (applicationKey === 'ssosegment') {
              this.cookieService.set("ssosegmentcookie", `${applicationDtls[applicationKey]}`, { path: "/" });
            }
            frameQueryParam = frameQueryParam + "&" + applicationKey + "=" + `${applicationDtls[applicationKey]}`;
          }
        }
      }
    }
    else {
      frameQueryParam = applicationName;
      this.revampApplication(menuCode).subscribe((response: any) => {
      })
    }
    window.open(this.envURL + frameQueryParam, "_blank");
  }

  revampApplication(applicationName: any) {
    let appName = '';
    let appId = '';
    if (applicationName == 'rInspection') {
      appName = 'INSP-REVAMPAPPL';
      appId = '97bffa4a-fa39-40a7-bf8b-05924359eaee'
    } else if (applicationName == 'membership') {
      appName = 'MEMBERSHIP-REVAMPAPPL';
      appId = '97bffa4a-fa39-40a7-bf8b-05924359eabb'
    } else if (applicationName == 'enit') {
      appName = 'MEMBER-PORTAL',
        appId = '55c96450-cdba-40d7-bdf1-4dbeca31f95c'
    }

    let requestObject = {
      "userId": localStorage.getItem('username'),
      "jwtToken": localStorage.getItem('token'),
      // "applicationName": (applicationName ==='rInspection')?"INSP-REVAMPAPPL":"MEMBER-PORTAL",
      // "applicationId": (applicationName ==='rInspection')?'97bffa4a-fa39-40a7-bf8b-05924359eaee':"55c96450-cdba-40d7-bdf1-4dbeca31f95c"
      "applicationName": appName,
      "applicationId": appId,
    }

    if (applicationName === 'rInspection') {
      return this.http.post(this.envURL + this.config.URL.revampTradeApplicationInspection, requestObject);
    } else if (applicationName === 'membership') {
      return this.http.post(this.envURL + this.config.URL.revampMembership, requestObject);
    } else {
      return this.http.post(this.envURL + this.config.URL.revampTradeApplication, requestObject);
    }
  }
}