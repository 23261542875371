import { ChangeDetectorRef, Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { config } from 'src/app/valueObject/config';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomValidators } from 'src/app/shared/customValidators';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $ :  any;
@Component({
  selector: 'app-etm-message-rate-user-id-request',
  templateUrl: './etm-message-rate-user-id-request.component.html',
  styleUrls: ['./etm-message-rate-user-id-request.component.css']
})
export class EtmMessageRateUserIdRequestComponent implements OnInit {
  messageRateRequestForm: FormGroup;
  config: any = config;
  submitted = false;
  timTapIp : any = [];
  memCode: any;
  memId: any;
  readOnlyData : any = [];
  menucode ="mrcChangeRequest";
  user: any = {};
  access: any = {};
  @ViewChild('selectpicker') selectPicker: ElementRef;
  entityType: any;
  memName: any;
  module: any="Message Rate Change";
  reqtype: number = 3;
  loginId: string;
  verifyOTP: boolean;
  mrcError: boolean = false;
  messaegRate: any;
  currentMessageRate: any;

  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private enitService: EnitTradeService,
    private toastr: ToastrService,
    private userDetailsService: UserDetailsService,
    private cdref: ChangeDetectorRef,
  ) { }
  get f(){
    return this.messageRateRequestForm.controls;
  }
  newItemEvent(event: boolean){
    this.verifyOTP=event;
    console.log(this.verifyOTP);
    if(this.verifyOTP == true){
      $('#cashdeposite').modal('hide');
    }
  }

  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
  $('.selectpicker').selectpicker();
  this.entityType = this.user.entityType;
  this.memCode = this.user.entityCode;
  this.memName = this.user.userName;
  this.memId = (<number>this.user.entityCode-10000)+"";
  this.getTapID();
  this.titleService.setTitle('Message Rate Change Request');
  this.messageRateRequestForm=this.fb.group({
    tapIP:[null, Validators.required],
    changeReqType:[null, Validators.required],
    category:[null, Validators.required],
    boxID:[null],
    currentMsgRate:[null, Validators.required],
    proposedMsgRate:[null, Validators.required],
    name:[null, ],
    number:[null, [ Validators.pattern("^[0-9]{10}$")]],
    email:[null, [ Validators.email]],
    //confirmation:[null, Validators.required]
  })
  this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    this.loginId = this.user.loginId;
  }
  ngAfterViewInit() {
    $('.selectpicker').selectpicker();
  }
  reset(){
    this.messageRateRequestForm.reset();
    $('.selectpicker').selectpicker('refresh');
    this.submitted = false;
    this.verifyOTP = false;
    this.mrcError = false;
  }

  changeRate(){
    if(this.f.proposedMsgRate.value=="100"){
      this.messaegRate = 'A'
    }
    else if(this.f.proposedMsgRate.value=="200"){
      this.messaegRate = 'B'
    }
    else if(this.f.proposedMsgRate.value=="400"){
      this.messaegRate = 'C'
    }
    else if(this.f.proposedMsgRate.value=="800"){
      this.messaegRate = 'D'
    }
    // if(this.f.proposedMsgRate.value == this.f.currentMsgRate.value){
    //  this.mrcError = true;
    // }
    // else if(this.f.proposedMsgRate.value != this.f.currentMsgRate.value){
    //   this.mrcError = false;
    // }
    if(this.f.category.value == this.messaegRate){
      this.mrcError = true;
    }
    else if(this.f.category.value != this.messaegRate){
      this.mrcError = false;
    }
  }

  errorMessage(){
   
  }
  getTapID(){
    this.enitService.getTapID(this.memCode, this.memId).pipe(
      catchError((error: any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
      this.timTapIp = response.data;
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();
      $('.selectpicker').selectpicker('refresh');
      }
      else
      this.toastr.error(response.message)
      
    })
  }
  getReadonlyData(){
    let requestObject = {
      "memId" : this.memId,
      "memCode" : this.memCode ,
      "selectedTapIP" : this.f.tapIP.value ,
      "memName" :this.memName ,

    }
    this.enitService.getReadonlyData(this.memId,this.f.tapIP.value ).pipe(
      catchError((error: any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
      this.readOnlyData = response.data[0]
      this.f.category.setValue(this.readOnlyData.cusNMCategory);
      this.f.boxID.setValue(this.readOnlyData.cusTapIpId);
      this.f.currentMsgRate.setValue(this.readOnlyData.cusNMCategoryAndMessage);
      this.currentMessageRate = this.readOnlyData.currentMessageRate;
      }
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker('refresh');
    })
  }
  onSubmit(){
    this.submitted = true;
    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
      "selectedTapIP" : this.f.tapIP.value,
      "memName" : '',
      // "selectedMsgReqType" : this.f.changeReqType.value,
      "selectedMsgReqType" : "MRC",
      "cusTidTapIdCM" : this.f.boxID.value,
      "mrcPersonName" : this.f.name.value,
      "cusCusContactNm" : this.f.number.value,
      "proposedMessageRateCategory" : this.f.proposedMsgRate.value,
      "cusEmail": this.f.email.value,
      "currentMessageRate": this.currentMessageRate,
    }
  this.spinner.show();
  this.enitService.submitMessageRateChangeRequest(requestObject).pipe(
  catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
  ).subscribe((response:any)=>{
    if(response.statusCode == 200){
      let refNo =  response.data
      this.toastr.success("Request Submitted With Reference Number: " +refNo)
      this.reset();
      this.submitted=true;
      // window.location.reload();
    }
    else{
      this.toastr.error(response.message);
      this.submitted = false;
    }
  })
  }
}
