<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Neat User Name Change</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="/enit/eTrade/AdminUserIdRequestList">Enit</a></li>
                    <li class="active"><a routerLink="/eTrade/eUseridRequest/displayChngNeatUsrNmRequest"
                            aria-current="page">Neat User Name Change</a>
                    </li>

                </ul>
            </div>

        </div>
    </div>
    <div>

        <form [formGroup]="neatUserNameChange">
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h6>
                        REQUEST FOR NEAT USER NAME CHANGE
                    </h6>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>SEBI/IFSCA  Registration Code</label>
                            <input type="text" class="form-control" readonly value="" formControlName="SebiRegiCode">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Id</label>
                            <select class="form-control selectpicker" formControlName="cusDlrId" data-style="btn-info"
                                title="Select User Id" (change)="selectDlrId()" data-live-search="true">

                                <option *ngFor="let data of cusDlrId" value="{{data}}">{{data}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Name</label>
                            <input type="text" value="" class="form-control" formControlName="UserName" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Existing TAP/Child IP <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="ExistingTapIp" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>TAP ID</label>
                            <input type="text" value="" class="form-control" formControlName="TapId" readonly>
                            <!-- <p *ngIf="tapIDError" class="error nocase">
                                TAP ID required
                            </p> -->

                        </div>
                    </div>
                </div>

                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h6>DETAILS OF THE PROPOSED USER</h6>
                    <div class="row">
                    <div class="col-lg-3 form-group">
                        <label>Certificate Details</label>
                        <select class="form-control selectpicker" formControlName="certificationType"
                            title="Select Certificate Details" data-style="btn-info" (change)="changeCertiDetails()"
                            data-live-search="true">
                            <option value="NISM"> NISM</option>
                            <option value="NISM-CPE"> NISM CPE</option>
                        </select>
                        <p *ngIf="f.certificationType.invalid && f.certificationType.touched" class="error nocase">
                            Certificate details required
                        </p>
                    </div>

                    <div *ngIf="f.certificationType.value == 'NISM'" class="col-lg-3 form-group">
                        <label>Exam Type <span class="red">*</span></label>
                        <select class="form-control selectpicker" formControlName="ExamType" title="Select Exam Type"
                            data-live-search="true" data-style="btn-info">

                            <option value="Series VIII. Equity derivatives">Series VIII. Equity derivatives</option>
                            <option value="Series XIII. Common Derivatives">Series XIII. Common Derivatives</option>
                        </select>
                        <p *ngIf="f.ExamType.invalid && f.ExamType.touched" class="error nocase">
                            Exam type required
                        </p>
                    </div>

                    <div *ngIf="f.certificationType.value == 'NISM'" class="col-lg-3 form-group">
                        <label>Registration No <span class="red">*</span></label>
                        <input type="number" class="form-control" readonly placeholder="NISM"
                            formControlName="registraionNo">
                    </div>

                    <div *ngIf="f.certificationType.value == 'NISM'" class="col-lg-3 form-group">
                        <label>NISM Registration No. as issued by NISM : *** (12 digit registration
                            no)<span class="red">*</span></label>
                        <input type="number" class="form-control" placeholder="" formControlName="registraionNoType">

                        <div *ngIf="f.registraionNoType.invalid && f.registraionNoType.touched">
                            <p *ngIf="f.registraionNoType.errors.required" class="error nocase">
                                Registration number required
                            </p>
                            <p *ngIf="f.registraionNoType.errors.pattern" class="error nocase">
                                Registration number should contain 12 digits
                            </p>
                        </div>
                    </div>
  
                        <div class="col-lg-3 form-group" *ngIf="f.certificationType?.value">
                            <label>Validity Date <span class="red">*</span></label>
                           <div class="input-group">
                                <input type="text" class="form-control datepicker" [(bsValue)]="bsValue"
                                    value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="ValidateDateType" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="Select Validity Date" [minDate]="minDateValidity">
                                <div class="input-group-append pointer">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar" [(bsValue)]="bsValue" bsDatepicker
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div>
                            <p *ngIf="f.ValidateDateType.invalid && f.ValidateDateType.touched" class="error nocase">
                                Validity date required
                            </p>
                        </div>

                    </div>

                    <div class="row" *ngIf="f.certificationType.value == 'NISM-CPE'">
                        <div class="col-lg-3 form-group">
                        <label>Registration No <span class="red">*</span></label>
                                <select class="form-control selectpicker" formControlName="certificationType1"
                                    title="Select Certificate Details" data-style="btn-info" (change)="changeCertiDetails()"
                                    data-live-search="true">
                                    <option *ngFor="let data of refcertilist" value="{{data.refValue}}"> {{data.refValue}}
                                    </option>
                                </select>
                                <p *ngIf="f.certificationType1.invalid && f.certificationType1.touched" class="error nocase">
                                    Certificate details required
                                </p>
 
                        </div>
                        <div *ngIf="f.certificationType1.value == 'NISM-CPE'" class="d-flex col-lg-10 form-group">
                            <label for="">Kindly enter the NISM certification details in any of the following formats mandatorily CPE Code as issued by NISM: ABCDE/*****-**/***** & **/CITY- 7 digit Certificate No.</label> 
                        </div>

                        <div class="row" *ngIf="f.certificationType1.value == 'NISM-CPE'">
                            <div class="col-lg-12 ml-3 ">
                                <div class="input-group">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="nismRegNo4"
                                            [ngClass]="{ 'is-invalid': f.nismRegNo4.invalid && f.nismRegNo4.touched && f.nismRegNo4.errors.pattern }">
                                        <div *ngIf="f.nismRegNo4.invalid && f.nismRegNo4.touched">
                                            <p *ngIf="f.nismRegNo4.errors.required" class="error nocase">
                                                Please enter part 1 for NISM-CPE
                                            </p>
                                            <p *ngIf="f.nismRegNo4.invalid && f.nismRegNo4.touched && f.nismRegNo4.errors.pattern"
                                                class="error nocase">
                                                Please enter part 1 for NISM-CPE correctly
                                            </p>
                                        </div>
                                    </div>
    
                                    <div class="input-group-append">
                                        &nbsp; / &nbsp;
                                    </div>
    
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="nismRegNo5"
                                            [ngClass]="{ 'is-invalid': f.nismRegNo5.invalid && f.nismRegNo5.touched && f.nismRegNo5.errors.pattern }">
                                        <div *ngIf="f.nismRegNo5.invalid && f.nismRegNo5.touched">
                                            <p *ngIf="f.nismRegNo5.errors.required" class="error nocase">
                                                Please enter part 2 for NISM-CPE
                                            </p>
                                            <p *ngIf="f.nismRegNo5.invalid && f.nismRegNo5.touched && f.nismRegNo5.errors.pattern"
                                                class="error nocase">
                                                Please enter part 2 for NISM-CPE correctly
                                            </p>
                                        </div>
                                    </div>
    
                                    <div class="input-group-append">
                                        &nbsp; - &nbsp;
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="nismRegNo6"
                                            [ngClass]="{ 'is-invalid': f.nismRegNo6.invalid && f.nismRegNo6.touched && f.nismRegNo6.errors.pattern }">
                                        <div *ngIf="f.nismRegNo6.invalid && f.nismRegNo6.touched">
                                            <p *ngIf="f.nismRegNo6.errors.required" class="error nocase">
                                                Please enter part 3 for NISM-CPE
                                            </p>
                                            <p *ngIf="f.nismRegNo6.invalid && f.nismRegNo6.touched && f.nismRegNo6.errors.pattern"
                                                class="error nocase">
                                                Please enter part 3 for NISM-CPE correctly
                                            </p>
                                        </div>
                                    </div>
                                    <div class="input-group-append">
                                        &nbsp; / &nbsp;
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="nismRegNo7"
                                            [ngClass]="{ 'is-invalid': f.nismRegNo7.invalid && f.nismRegNo7.touched && f.nismRegNo7.errors.pattern }">
                                        <div *ngIf="f.nismRegNo7.invalid && f.nismRegNo7.touched">
                                            <p *ngIf="f.nismRegNo7.errors.required" class="error nocase">
                                                Please enter part 4 for NISM-CPE
                                            </p>
                                            <p *ngIf="f.nismRegNo7.invalid && f.nismRegNo7.touched && f.nismRegNo7.errors.pattern"
                                            class="error nocase">
                                                Please enter part 4 for NISM-CPE correctly
                                            </p>
                                        </div>
                                    </div>
                                    <div class="input-group-append">
                                        &nbsp; / &nbsp;
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="nismRegNo8"
                                            [ngClass]="{ 'is-invalid': f.nismRegNo8.invalid && f.nismRegNo8.touched && f.nismRegNo8.errors.pattern }">
                                        <div *ngIf="f.nismRegNo8.invalid && f.nismRegNo8.touched">
                                            <p *ngIf="f.nismRegNo8.errors.required" class="error nocase">
                                                Please enter part 5 for NISM-CPE
                                            </p>
                                            <p *ngIf="f.nismRegNo8.invalid && f.nismRegNo8.touched && f.nismRegNo8.errors.pattern"
                                                class="error nocase">
                                                Please enter part 5 for NISM-CPE correctly
                                            </p>
                                        </div>
                                    </div>
                                    <div class="input-group-append">
                                        &nbsp; - &nbsp;
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="nismRegNo9"
                                            [ngClass]="{ 'is-invalid': f.nismRegNo9.invalid && f.nismRegNo9.touched && f.nismRegNo9.errors.pattern }">
                                        <div *ngIf="f.nismRegNo9.invalid && f.nismRegNo9.touched">
                                            <p *ngIf="f.nismRegNo9.errors.required" class="error nocase">
                                                Please enter part 6 for NISM-CPE
                                            </p>
                                            <p *ngIf="f.nismRegNo9.invalid && f.nismRegNo9.touched && f.nismRegNo9.errors.pattern"
                                                class="error nocase">
                                                Please enter part 6 for NISM-CPE correctly
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                       

                        <div class="col-lg-4 form-group">
                            <div *ngIf="f.certificationType1.value == 'NISM' || f.certificationType1.value == 'MCXSX'" >                            
                                <label *ngIf="f.certificationType1.value == 'MCXSX'">Please enter registration number as
                                    follows: ****** (only numbers)</label>
                                <label *ngIf="f.certificationType1.value == 'NISM'">NISM Registration No. as issued by NISM :
                                    ************ (12 digit registration no)</label>
                                    <input type="number" class="form-control mt-1" formControlName="registraionNoType">
                                <div *ngIf="f.registraionNoType.invalid && f.registraionNoType.touched">
                                    <p *ngIf="f.registraionNoType.errors.required" class="error nocase">
                                        Registration number required
                                    </p>
                                    <p *ngIf="f.registraionNoType.errors.pattern" class="error nocase">
                                        Registration number should contain 12 digits
                                    </p>
                                </div>
                            </div>
                        </div>
            </div>

                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Title <span class="red">*</span></label>
                            <select class="form-control selectpicker" formControlName="TitleType" data-style="btn-info"
                            title="Select Title" data-live-search="true">
                            <option *ngFor="let data of titleList" value="{{data.refValue}}">{{data.refValue}}
                            </option>
                        </select>
                            <p *ngIf="f.TitleType.invalid && f.TitleType.touched" class="error nocase">
                                Title required
                            </p>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>First Name <span class="red">*</span></label>
                            <input type="text" formControlName="firstNameType" placeholder="First Name" class="form-control" onKeyPress="return event.charCode != 32">
    
                            <p *ngIf="f.firstNameType.invalid && f.firstNameType.touched" class="error nocase">
                                First name required
                            </p>
                        </div>
    
                        <div class="col-lg-3 form-group">
                            <label>Middle Name</label>
                            <input type="text" formControlName="middleNameType" placeholder="Middle Name" class="form-control" onKeyPress="return event.charCode != 32">
                            <p *ngIf="f.middleNameType.invalid && f.middleNameType.errors.maxlength" class="error nocase">
                                Middle name exceeding limit
                            </p>
                        </div>
    
                        <div class="col-lg-3 form-group">
                            <label>Last Name <span class="red">*</span></label>
                            <input type="text" formControlName="lastNameType" placeholder="Last Name" class="form-control" onKeyPress="return event.charCode != 32">
    
                            <p *ngIf="f.lastNameType.invalid && f.lastNameType.touched" class="error nocase">
                                Last name required
                            </p>
                        </div>
                      
                    </div>

                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Father's/Husband's Name<span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="FatherNameType" onKeyPress="return event.charCode != 32">
                            <!-- <p *ngIf="f.FatherNameType.invalid && f.FatherNameType.touched" class="error nocase">
                                 Father's/Husband's name required
                            </p> -->
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Date Of Birth </label>
                          <div class="input-group">
                                <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue1"
                                    value="{{bsValue1 | date:'MM/dd/yyyy'}}" formControlName="BODType" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="Select Date Of Birth" [maxDate]="maxDate">
                                <div class="input-group-append pointer" >
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar" [(bsValue)]="bsValue1" bsDatepicker
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div>

                            <!-- <p *ngIf="f.BODType.invalid && f.BODType.touched" class="error nocase">
                                 Date of birth required
                            </p> -->
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>PAN Number </label>
                            <input type="text" class="form-control" formControlName="PanType" style="text-transform: uppercase;">

                            <DIV *ngIf="f.PanType.invalid && f.PanType.touched" class="error nocase">

                                <!-- <div *ngIf="f.PanType.errors.required"> PAN number required</div> -->
                                <P *ngIf="f.PanType.errors.pattern" class="error nocase">Enter correct PAN number</P>
                            </DIV>

                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Mobile Number </label>
                            <input type="number" class="form-control" formControlName="MobileNoType">

                            <!-- <div *ngIf="f.MobileNoType.invalid && f.MobileNoType.touched" class="error nocase">

                                <div *ngIf="f.MobileNoType.errors.required"> Mobile number required</div> 
                                <div
                                    *ngIf="f.MobileNoType.errors.pattern || f.MobileNoType.errors.maxlength || f.MobileNoType.errors.minlength">
                                    Mobile number should contain 10 digits</div>
                            </div> -->

                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Qualification <span class="red">*</span></label>
                            <select class="form-control selectpicker" formControlName="QualificationType" data-style="btn-info"
                            title="Select Qualification" data-live-search="true">
                            <option *ngFor="let data of qualificationList" value="{{data.refValue}}">{{data.refDesc}}
                            </option>
                        </select>
                            <p *ngIf="f.QualificationType.invalid && f.QualificationType.touched" class="error nocase">
                                 Qualification required
                            </p>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Reason <span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="reason">
                            <p *ngIf="f.reason.invalid && f.reason.touched" class="error nocase">
                                Reason for name change required
                           </p>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Other details if any </label>
                            <input type="text" class="form-control" formControlName="userRemarks">
                        </div>
                        <!-- <div class="col-lg-3 form-group">
                            <label>Percentage <span class="red">*</span></label>
                            <input type="number" class="form-control" formControlName="PercentageType">

                            <p *ngIf="f.PercentageType.invalid && f.PercentageType.touched" class="error nocase">
                                Percentage required
                            </p>
                            <p *ngIf="f.PercentageType.invalid && f.PercentageType.errors.max" class="error nocase">
                                Percentage exceeding limit
                           </p>
                            <p *ngIf="f.PercentageType.invalid && f.PercentageType.errors.pattern" class="error nocase">
                               Percentage should contain numbers only
                            </p> 
                        </div> -->
                    </div>

                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Email ID </label>
                            <input type="email" class="form-control" formControlName="EmailType">
                            <div *ngIf="f.EmailType.invalid && f.EmailType.touched">
                                <!-- <p *ngIf="f.EmailType.errors.required" class="error nocase">
                                    Email ID required
                                </p> -->
                                <p *ngIf="f.EmailType.errors.email" class="error nocase">
                                    Please enter valid email ID
                                </p>
                            </div>

                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Status Of Office <span class="red">*</span></label>
                            <select class="form-control selectpicker" formControlName="OfficeStatusType" data-style="btn-info"
                                title="Select Status" (change)="getRelationshipList()" data-live-search="true">
                                <option *ngFor="let data of refOffcList" value="{{data.refValue}}">{{data.refValue}}
                                </option>
                            </select>

                            <p *ngIf="f.OfficeStatusType.invalid && f.OfficeStatusType.touched" class="error nocase">
                                 Status of office required
                            </p>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Relationship Of Proposed User <span class="red">*</span></label>
                            <select class="form-control selectpicker" formControlName="RelationshipOfUserType"
                            title="Select Relationship Of Proposed User" data-style="btn-info" data-live-search="true">
                            <option *ngFor="let data of relationshipList" value="{{data.refValue}}">{{data.refValue}}
                            </option>
                        </select>
                            <p *ngIf="f.RelationshipOfUserType.invalid && f.RelationshipOfUserType.touched"
                                class="error nocase">
                                 Relationship of proposed user required
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card card-body col-lg-12 mt-3 colorborder" >
                    <H6>DECLARATION OF USER ID REQUEST</H6>

                    <p> <input type="checkbox" formControlName="CheckType" name="tc"> I/We hereby agree and bind
                        myself/ourselves to be
                        responsible for all acts, quotations made and transactions done, trades made, or effected by <span class="bold">{{f.firstNameType.value}} {{f.lastNameType.value}}</span> as
                        our
                        Approved User on the Trading System of the NSE IFSC LTD. I/We shall ensure that he/she will not
                        execute any order on his/her own account or on account of anyone without such order having
                        my/our
                        prior approval in writing. We shall ensure that the proposed approved user <span class="bold">{{f.firstNameType.value}} {{f.lastNameType.value}}</span> will not function /
                        place
                        orders / deal on the trading system in any manner that would represent that the trades have been
                        placed as a sub-broker unless he is a SEBI/IFSCA registered sub-broker. In case of <span class="bold">{{f.firstNameType.value}} {{f.lastNameType.value}}</span> ceasing to be
                        associated with us as Employee, we shall communicate to you and seek the disablement of the User
                        ID
                        so allotted against this application. We are also aware that the NISM certificate has a validity
                        of
                        3 years and accordingly the User IDs so allotted would be withdrawn by the Exchange on the
                        expiry of
                        such period unless the said certification is revalidated. I/We certify that we have not applied
                        for
                        any other User ID in the name of <span class="bold">{{f.firstNameType.value}} {{f.lastNameType.value}}</span> on the same segment for which this ID's now being applied for.
                        The

                        User ID so allotted would be utilized by <span class="bold">{{f.firstNameType.value}} {{f.lastNameType.value}}</span> only to access the NEAT trading system.</p>

                        <p>
                            <input type="checkbox" formControlName="CheckType1"> 
                            I/We declare to abide by the rules/regulations as communicated by the Exchange/SEBI/IFSCA/other Regulators from time to time.  
                        </p>

                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>date</label>
                          <input type="text" class="form-control" formControlName="DateType" readonly>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Place <span class="red">*</span></label>
                         <select class="form-control selectpicker" formControlName="PlaceType" title="Select Place" data-style="btn-info" >
                                <option *ngFor="let data of cityList" value="{{data.cityNm}}"> {{data.cityNm}}</option>
                            </select>
                            <p *ngIf="f.PlaceType.invalid && f.PlaceType.touched" class="error nocase">
                                 Place required
                            </p>
                        </div>

                        <div class="col-sm-12">
                            <label>Kindly download user declaration</label>&nbsp;&nbsp;<a class="declaration pointer"
                                (click)="openFileFormat()">Download Declaration</a>
                        </div>
                        <div class="col-sm-12">
							<label><font color="red">Kindly attach declaration document</font></label>
						</div>
                        <div class="col-sm-2 form-group">
							<label>Kindly upload declaration document and PAN Card Copy -self attested (In Single PDF
                                Document)<em class="text-danger">*</em></label>
						</div>
                        <div class="col-sm-4">
							<input type="file" accept="application/pdf" formControlName="declarationAndPan" class="form-control" title="Kindly upload declaration document and PAN Card Copy -self attested (In Single PDF Document)"(change)="onFileSelected($event)">
                            <div *ngIf="fileError" >
                                <p class="error nocase">File should be in PDF format</p>
                             </div>
						</div>
                        <div class="col-sm-2 form-group" *ngIf="f.certificationType?.value">
							<label>Kindly upload valid certificate<em class="text-danger">*</em></label>
						</div>
                        <div class="col-sm-4" *ngIf="f.certificationType?.value">
							<input type="file" accept="application/pdf" formControlName="certificate" class="form-control" title="Kindly upload valid certificate." (change)="onFileSelected1($event)">
                            <div *ngIf="fileError1" >
                                <p class="error nocase">File should be in PDF format</p>
                             </div>
						</div>
                    </div>

                    <div class="row justify-content-start mb-5">
                        <div class="col-lg-4">
                            <div class="row justify-content-start">
                                <div class="col-lg-4">
                                    <button class="btn btn-primary full-width" [disabled]="verifyOTP!=true || submitted"
                                        type="submit" (click)="onSubmit()">Submit</button>
                                </div>
                                <div class="col-lg-4">
                                    <button type="button" class="btn btn-cancel full-width" (click)="reset()">Reset</button>
                                </div>
                                <div class="col-lg-4">
                                    <button class="btn  btn-primary full-width"  data-toggle="modal" [disabled]="neatUserNameChange.invalid  || fileError || fileError1"  data-target="#cashdeposite">Certify</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</div>

<div class="modal fade" id="cashdeposite" tabindex="-1" role="dialog" aria-labelledby="cashdepositeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
              </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verfiy-request-by-otp  [module]="module" [reqtype]="reqtype" [loginId]="loginId" [memId]="memId" (newItemEvent)="newItemEvent($event)"></app-verfiy-request-by-otp>
        </div>
    </div>
</div>

