import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Output, Renderer2 } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as EventEmitter from 'events';
import { ToastrService } from 'ngx-toastr';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
import { UserDetailsService } from 'src/app/service/user-details.service';

@Component({
  selector: 'app-half-yearly-form-submission',
  templateUrl: './half-yearly-form-submission.component.html',
  styleUrls: ['./half-yearly-form-submission.component.css']
})
export class HalfYearlyFormSubmissionComponent implements OnInit {

  halfYearlyForm: FormGroup;
  totalASum:number = 0;
  totalBSum:number = 0;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userDetailsService: UserDetailsService,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef,
    private renderer: Renderer2) { }

  get f() {
    return this.halfYearlyForm.controls;
  }
  get formUploadFile() {
    return this.halfYearlyForm.get("uploadScannedCertificateFile") as FormArray;
  }

  ngOnInit(): void {
    this.halfYearlyForm = this.formBuilder.group({
      memberName: [],
      memberCode:  [],
      asOnDate: ["31-Mar-2023", [Validators.required]],
      nameOfCaFirm: [],
      membershipNoCertifyingCa: [],
      methodOfComputation: ["Computation of Networth as per IFSCA (Capital Market Intermediaries) Regulations, 2021"],
      uploadScannedCertificateFile: this.formBuilder.array([this.initUploadFileRows()]),
      revisedNetWorthUploadFile: this.formBuilder.array([]),
      reservedCreatedOfProfit:[0.00,Validators.pattern(this.regex)],
      cerditBalanceProfitAndLoss: [0.00],
      securityPermium: [0.00],
      accumulatedLosses: [0],
      deferredExpenditure: [0],
      miscellaneousExpenses: [0],
      otherActivitiesOutsideIfsc: [0],
      totalNetworth: [],
      totalA: [],
      totalB: []
    })
  }

  addUploadFile() {
    this.formUploadFile.push(this.initUploadFileRows());
  }
  deleteUploadFile(index) {
    this.formUploadFile.removeAt(index);
  }
  initUploadFileRows() {
    return this.formBuilder.group({
      halfYearlyNetworthFileId: [],
      halfYearlyNetworthOriginalFile: [],
      halfYearlyFileType: [],
      halfYearlyNetworthUploadFiles: File
    });
  }

  totalAcal(){
    this.totalASum=this.halfYearlyForm.value.reservedCreatedOfProfit + this.halfYearlyForm.value.cerditBalanceProfitAndLoss
    +this.halfYearlyForm.value.securityPermium;
    this.halfYearlyForm.get('totalA').setValue(this.totalASum.toFixed(2));   
    
    this.totalBSum=this.halfYearlyForm.value.accumulatedLosses + this.halfYearlyForm.value.deferredExpenditure + this.halfYearlyForm.value.miscellaneousExpenses
    + this.halfYearlyForm.value.otherActivitiesOutsideIfsc;
    this.halfYearlyForm.get('totalB').setValue(this.totalBSum.toFixed(2));

    this.halfYearlyForm.get('totalNetworth').setValue((this.totalASum-this.totalBSum).toFixed(2));
  }
  validateNumber(e: any){
    let input = e.target.value +String.fromCharCode(e.charCode);
    // const reg = /^\d*(?:[.,]\d{1,2})?$/;
    // const reg=/^\d*\.?\d{0,2}$/g;
    const reg= /^-?\d*(\.\d{0,2})?$/;
    if(!reg.test(input)){
      e.preventDefault();
    }
  }

  saveHalfYearlyForm(){
    
  }
}
