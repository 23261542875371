import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuGuard } from 'src/app/service/menu.guard';
import { SubmenuGuard } from 'src/app/service/submenu.guard';
import { EtmNewneatUseridRequestComponent } from './EnitTrade/etm-newneat-userid-request/etm-newneat-userid-request.component';
import { EtUseridRequestListComponent } from './EnitTrade/et-userid-request-list/et-userid-request-list.component';
import { DisableNeatUserIdComponent } from './EnitTrade/disable-neat-user-id/disable-neat-user-id.component';
import { NeatUserValidityUpdationComponent } from './EnitTrade/neat-user-validity-updation/neat-user-validity-updation.component';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { EtNewneatRequestProcessPageComponent } from './EnitTrade/et-newneat-request-process-page/et-newneat-request-process-page.component';
import { ShiftingNeatIdRequestComponent } from './EnitTrade/shifting-neat-id-request/shifting-neat-id-request.component';
import { DisplayAlgoUndertakingDetailsComponent } from './EnitTrade/display-algo-undertaking-details/display-algo-undertaking-details.component';
import { EtNeatToNonneatComponent } from './EnitTrade/et-neat-to-nonneat/et-neat-to-nonneat.component';
import {NeatNonneatReportComponent} from './EnitTrade/neat-nonneat-report/neat-nonneat-report.component';
import { NeatNonneatProcesspageComponent } from './EnitTrade/neat-nonneat-processpage/neat-nonneat-processpage.component';
import { NeatUserValidityUpdationModalComponent} from'./EnitTrade/neat-user-validity-updation-modal/neat-user-validity-updation-modal.component';
import {DisableNeatUserIdAdminComponent} from './EnitTrade/disable-neat-user-id-admin/disable-neat-user-id-admin.component'
import {ShiftingNeatIdAdminRequestComponent} from './EnitTrade/shifting-neat-id-admin-request/shifting-neat-id-admin-request.component'
import {PasswordOrUnlockRequestComponent} from './EnitTrade/password-or-unlock-request/password-or-unlock-request.component'
import {PasswordOrUnlockRequestAdminComponent} from './EnitTrade/password-or-unlock-request-admin/password-or-unlock-request-admin.component'
import { EtmMessageRateUserIdRequestComponent } from './EnitTrade/etm-message-rate-user-id-request/etm-message-rate-user-id-request.component';
import { EtmMessageRateChangeReportComponent } from './EnitTrade/etm-message-rate-change-report/etm-message-rate-change-report.component';
import { EtmMessageRateModalComponent } from './EnitTrade/etm-message-rate-modal/etm-message-rate-modal.component';
import { EtmNeatUserNameChangeRequestComponent } from './EnitTrade/etm-neat-user-name-change-request/etm-neat-user-name-change-request.component';
import { VerfiyRequestByOtpComponent } from './EnitTrade/verfiy-request-by-otp/verfiy-request-by-otp.component';
import { UnlockResetModalComponent} from './EnitTrade/unlock-reset-modal/unlock-reset-modal.component';
import { EtChangeNameProcessPageComponent } from './EnitTrade/et-change-name-process-page/et-change-name-process-page.component'

const routes: Routes = [
    {path:'verifyOtp',
      children: [
      { path: '', component: VerfiyRequestByOtpComponent },
      ]
    },
    {
          path: 'eUseridRequest', canActivate: [MenuGuard], canDeactivate: [SubmenuGuard],         
          children: [
            {path: 'displayDisableNeatIdRequestPage',canDeactivate: [SubmenuGuard], component: DisableNeatUserIdComponent },
            {path: 'displayNewNeatIdRequest',canDeactivate: [SubmenuGuard], component : EtmNewneatUseridRequestComponent},
            {path: 'mrcChangeRequest',canDeactivate: [SubmenuGuard], component: EtmMessageRateUserIdRequestComponent},
            {path: 'displayChngNeatUsrNmRequest',canDeactivate: [SubmenuGuard],component: EtmNeatUserNameChangeRequestComponent},
            {
              path: 'mrcChangeReport',canDeactivate: [SubmenuGuard],
              children: [
                { path: '', component: EtmMessageRateChangeReportComponent },
                { path: 'messageRateModal', component: EtmMessageRateModalComponent }
              ]
            },
            {path: 'displayNeatToNonNeatRequest',canDeactivate: [SubmenuGuard], component: EtNeatToNonneatComponent},
            {
              path: 'displayNonNeatReport',canDeactivate: [SubmenuGuard],
              children: [
                { path: '', component: NeatNonneatReportComponent },
                { path: 'NonNeatRequestProcessPage', component: NeatNonneatProcesspageComponent }
              ]
            },           
          {path:'displayShiftingOfNeatIdRequestPage',canDeactivate: [SubmenuGuard],component:ShiftingNeatIdRequestComponent},
          {path:'displayNeatUsrValidityUpdtRequest',canDeactivate: [SubmenuGuard],component:NeatUserValidityUpdationComponent},
          {path:'displayUIRequestProcessPage',      canDeactivate:[SubmenuGuard],
            children: [
                { path: '', component: EtUseridRequestListComponent },
                { path: 'NeatUsrValidityUpdationProcessPage', component: NeatUserValidityUpdationModalComponent },
                { path: 'ShiftingNeatIdAdminRequest', component: ShiftingNeatIdAdminRequestComponent },
                { path: 'DisableNeatUserIdAdminComponent', component: DisableNeatUserIdAdminComponent },
                { path: 'AdminUserIdRequestProcessPage', component: EtNewneatRequestProcessPageComponent},
                { path: 'changeName', component: EtChangeNameProcessPageComponent},
            ]}
          ]
      },
      {
        path: 'eMemberReportingPreTrade', canActivate: [MenuGuard], canDeactivate: [SubmenuGuard],
        loadChildren: () => import('./EnitTrade/ctcl/ctcl.module').then(m => m.CtclModule) 
      },
      {
        path: 'eUnlockuser', canActivate: [MenuGuard], canDeactivate: [SubmenuGuard],
        children: [
           {path: 'anewRequestPwdReset',canDeactivate: [SubmenuGuard], component: PasswordOrUnlockRequestComponent },
           {path: 'displayPasswordMis',canDeactivate: [SubmenuGuard],
           children:[
            {path:'',component: PasswordOrUnlockRequestAdminComponent},
            {path:'modalForm',component: UnlockResetModalComponent}
            ], },
        ] 
      }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MenuGuard, SubmenuGuard],
})
export class EnitRoutingModule {}
