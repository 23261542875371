<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Cyber Security Report</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li   class="active"><a routerLink="complianceAdmin/cyberSecurityAdmin/cyberSecurityList" aria-current="page">Cyber Security Report</a></li>  
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body colorborder">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Member Code</label>
                    <select class="form-control selectpicker" formControlName="memCode" data-style="btn-info" title="Select Member Code" (change)="memCodeChange()">
                        <option  *ngFor="let data of memList"  value="{{data.memberId}}">{{data.memberId*1+10000}}</option>
                    </select>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Member Name</label>
                    <input type="text" class="form-control" formControlName="memname" readonly>  
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Submission From Date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue"
                            value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="submissionFDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" placeholder="" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar"  [(bsValue)]="bsValue" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Submission End Date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue1"
                            value="{{bsValue1 | date:'MM/dd/yyyy'}}" formControlName="submissiontTDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" placeholder="" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar"  [(bsValue)]="bsValue1" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
               
            </div>

            <div class="row">
                <div class="col-lg-3 form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <button class="btn btn-primary full-width" type="submit" (click)="onSubmit()">Search</button>
                        </div>
                        <div class="col-lg-6">
                            <button class="btn btn-cancel full-width"  (click)="onReset()">Reset</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </form>
        <table id="cisMIS" class="overflow table common-table nowrap" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
            
                    <th>Reference No.</th>
                    <th>Member Code</th>
                    <th>Member Name</th>
                    <th>Quater Name</th>    
                    <th>Year</th>                  
                    <th>Nill Submission</th>
                    <th>Submission Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of cisreport">
                    <td (click)="open(data.incidentRequestId)" class="refNo pointer "><u>{{data.incidentRequestId}}</u></td>
                    <td>{{data.memberId*1+10000}}</td>
                    <td>{{data.memberName}}</td>
                    <td>{{data.quaterName}}</td>
                    <td>{{data.year}}</td>
                    <td>{{(data.nillSubmission=='Y')?'Yes' : 'No'}}</td>
                    <td>{{data.submissionDate}}</td>
                   
                </tr>
            </tbody>
        </table>
</div>
<!-- <button class="link" (click)="clickButton()">clickButton</button> -->
</div>


