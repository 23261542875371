import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login.service';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import * as bcrypt from 'bcryptjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/customValidators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-validateUser',
  templateUrl: './validateUser.component.html',
  styleUrls: ['./validateUser.component.css']
})
export class ValidateUserComponent implements OnInit {
  validateUserForm: FormGroup;
  captchaURL: string;
  captchaSource: string;
  hiddenCaptcha: string;
  config: any = config;
  message: string;
  page: string;
  submitButton: string = 'Submit';
  randomString: string;
  userPattern: any;
  captchaPattern: string;

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      if (params['page']) {
        this.page = params['page'];
        if (this.page == 'accountLock') {
          this.submitButton = 'Unlock Account';
        } else if (this.page == 'forgotPass') {
          this.submitButton = 'Reset Password';
        }
      }
    });
  }

  get f() { return this.validateUserForm.controls; }

  ngOnInit(): void {
    this.titleService.setTitle("Unlock Account")
    this.validateUserForm = this.fb.group({
      username: [null, Validators.required],
      captcha: [null, Validators.required],
    });
    this.userPattern = this.config.data.userPattern;
    this.captchaPattern=this.config.data.captchaPattern;
    this.captchaURL = this.config.URL.captchaURL;
    this.reloadCaptcha();

  }

  onSubmit() {
    if (!this.validateUserForm.valid) {
      return;
    }
    let loginRequest: any = {
      "userName": this.f.username.value,
      "captcha": this.f.captcha.value,
      "randomString": this.randomString
    };
    this.loginService.validateUser(this.config.URL.validateURL, loginRequest).pipe(
      catchError((error: any) => {
        this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
        this.reset();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message) {
        let str = response.message;
        let encryptedMessage = bcrypt.hashSync("SUCCESSFULL", str.substr(0, 29))
        if (encryptedMessage == response.message) {
          localStorage.setItem('username', this.f.username.value);
          localStorage.setItem(btoa('salt'), btoa(response.data[0].salt));
          this.router.navigate(['/login/otp'], { "queryParams": { 'page': this.page } });
        } else {
          encryptedMessage = bcrypt.hashSync("invalidCred", str.substr(0, 29))
          if (encryptedMessage == response.message) {
            this.message = this.config.failureResponse.login["invalidCred"];
            this.toastr.error(this.config.failureResponse.login["invalidCred"])
            this.reset();
          }
          encryptedMessage = bcrypt.hashSync("invalidCaptcha", str.substr(0, 29))
          if (encryptedMessage == response.message) {
            this.message = this.config.failureResponse.login["invalidCaptcha"];
            this.toastr.error(this.config.failureResponse.login["invalidCaptcha"])
            this.reset();
          }
        }

        // switch (response.message) {
        //   case "SUCCESSFULL": {
        //     localStorage.setItem('username', this.f.username.value);
        //     localStorage.setItem('salt', response.data[0].salt);
        //     this.router.navigate(['otp'], { "queryParams": { 'page': this.page } });
        //     break;
        //   }
        //   case "invalidCred":
        //   case "invalidCaptcha": {
        //     this.message = this.config.failureResponse.login[response.message];
        //     this.reset();
        //     break;
        //   }
        //   default: {
        //     this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
        //     this.reset();
        //     break;
        //   }
        // }
      }


    });
  }

  reset() {
    this.validateUserForm.reset();
    this.reloadCaptcha();
  }

  reloadCaptcha(): void {
    this.loginService.getCaptcha(this.captchaURL).subscribe(
      (response: any) => {
        this.captchaSource = response.data[0].captchaImage;
        this.randomString = response.data[0].randomString;
      }
    );
  }
}


