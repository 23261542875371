import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/valueObject/user';
import { config } from 'src/app/valueObject/config';
import { AppService } from 'src/app/service/app.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { Title } from '@angular/platform-browser';
import { UserControlService } from 'src/app/service/user-control.service';
declare var $: any;

@Component({
	selector: 'app-accessdetails',
	templateUrl: './accessdetails.component.html',
	styleUrls: ['./accessdetails.component.css']
})
export class AccessdetailsComponent implements OnInit, AfterViewInit {
	submitted = false;
	checked: any;
	verify: any;
	userAccessForm: FormGroup;
	multiSelectMenu: FormGroup;
	accessMapping: FormArray;
	userMenusImpls: FormArray;
	userMenusImpls2: FormArray;
	userSearchForm: FormGroup;
	user: any = {};
	action: string;
	initialValue: string;
	config: any = config;
	menuList: any = [];
	message: string;
	userDetails: any;
	access: any;
	manageAccess: any = [];
	manageAccessData: any;
	menuCode: string = 'useraccess';
	disableManage: boolean = true;
	userControlId: string = '';
	disableField: boolean = false;

	constructor(private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private userService: UserService,
		private appService: AppService,
		private toastr: ToastrService,
		private userDetailsService: UserDetailsService,
		private router: Router,
		private titleService: Title,
		private spinner: NgxSpinnerService,
		private cdref: ChangeDetectorRef,
		private userControlService: UserControlService) {
		this.route.queryParams.subscribe(params => {
			if (params['id']) {
				this.user = this.userService.getUserById(params['id']);
			}
		});

	}

	get f() { return this.userAccessForm.controls; }
	get sf() { return this.userSearchForm.controls; }
	get ms() { return this.multiSelectMenu.controls; }

	ngOnInit(): void {
		this.titleService.setTitle('Access Management');
		this.userAccessForm = this.formBuilder.group({
			checked: false,
			verify: false,
			accessMapping: this.formBuilder.array([]),
		});
		this.multiSelectMenu = this.formBuilder.group({
			// roleId: [''],
			isAccessAllowed: [''],
			accessType: ['']
		});
		this.userSearchForm = this.formBuilder.group({
			loginId: [null]
		});

		this.getDataForUser(this.user.userId);
		this.userControlId = this.userControlService.getUserControlId();
		this.userDetails = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails();
		this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menuCode) : this.userDetailsService.checkUserAccess(this.menuCode);
		if (this.access.accessType === "R") {
			this.action = 'view';
		}
		if (this.action == 'view' || this.access.roleId == '2') {
			this.disableField = true
		}
	}

	ngAfterViewInit(): void {
		$('.selectpicker').selectpicker('refresh');
	}

	getDataForUser(userId: number) {
		this.spinner.show();
		this.appService.getUserDetails(userId).pipe(
			catchError((error: any) => {
				this.spinner.hide();
				if (error.status != 200) {
					return throwError(error.error);
				}
			})
		).subscribe(
			(response: any) => {
				this.spinner.hide();
				this.menuList = response.data[0].menusList;
				this.setFormData();
			}
		);
	}

	getAccessData() {
		this.user = this.userService.getUserById(this.sf.loginId.value);
		this.getDataForUser(this.user.userId);
	}
	onVerifyAll() {
		if (this.f.verify.value) {
			this.f.accessMapping['controls'].forEach(menuAccess => {
				this.checkRecordMenu(menuAccess, "approve");
			});
		}
		else {
			this.f.accessMapping['controls'].forEach(menuAccess => {
				this.checkRecordMenu(menuAccess, "reject");
			});
		}
	}

	onSelectAll() {
		this.f.accessMapping['controls'].forEach(element => {
			element['controls'].checked.setValue(this.f.checked.value);
			if (element.value.userMenusImpls.length > 0) {
				element['controls'].userMenusImpls['controls'].forEach(element1 => {
					element1['controls'].checked.setValue(this.f.checked.value);
					if (element1.value.userMenusImpls2.length > 0) {
						element1['controls'].userMenusImpls2['controls'].forEach(element2 => {
							element2['controls'].checked.setValue(this.f.checked.value);
						});
					}
				});
			}
		});
		if (this.f.checked.value) {
			this.disableManage = false;
			this.cdref.detectChanges();
		}
	}

	onSelectMenu(menuAccess) {
		if (menuAccess.value.userMenusImpls.length > 0) {
			menuAccess['controls'].userMenusImpls['controls'].forEach(element => {
				element['controls'].checked.setValue(menuAccess.value.checked);
				if (element.value.userMenusImpls2.length > 0) {
					element['controls'].userMenusImpls2['controls'].forEach(element1 => {
						element1['controls'].checked.setValue(menuAccess.value.checked);
					});
				}
			});
		}
		if (menuAccess.value.checked) {
			this.disableManage = false;
			this.cdref.detectChanges();
		}
	}

	onSelectSubMenu(menuAccess, subMenuAccess) {
		if (subMenuAccess.value.userMenusImpls2.length > 0) {
			subMenuAccess['controls'].userMenusImpls2['controls'].forEach(element => {
				element['controls'].checked.setValue(subMenuAccess.value.checked);
			});
		}
		if (subMenuAccess.value.checked) {
			menuAccess['controls'].checked.setValue(true);
			this.disableManage = false;
			this.cdref.detectChanges();
		}

	}
	onSelectSubMenu2(menuAccess, subMenuAccess, subMenuAccess2) {
		if (subMenuAccess2.value.checked) {
			menuAccess['controls'].checked.setValue(true);
			subMenuAccess['controls'].checked.setValue(true);
			this.disableManage = false;
			this.cdref.detectChanges();
		}
	}

	onSave() {
		// edits the selected menu options
		this.f.accessMapping['controls'].forEach(element => {
			if (element.value.checked) {
				// if (this.ms.roleId.value)
				// 	element['controls'].roleId.setValue(this.ms.roleId.value);
				if (this.ms.isAccessAllowed.value)
					element['controls'].isAccessAllowed.setValue(this.ms.isAccessAllowed.value);
				if (this.ms.accessType.value)
					element['controls'].accessType.setValue(this.ms.accessType.value);
				element['controls'].checked.setValue(false);
			}
			if (element.value.userMenusImpls.length > 0) {
				element['controls'].userMenusImpls['controls'].forEach(element1 => {
					if (element1.value.checked) {
						// if (this.ms.roleId.value)
						// 	element1['controls'].roleId.setValue(this.ms.roleId.value);
						if (this.ms.isAccessAllowed.value)
							element1['controls'].isAccessAllowed.setValue(this.ms.isAccessAllowed.value);
						if (this.ms.accessType.value)
							element1['controls'].accessType.setValue(this.ms.accessType.value);
						element1['controls'].checked.setValue(false);
					}
					if (element1.value.userMenusImpls2.length > 0) {
						element1['controls'].userMenusImpls2['controls'].forEach(element2 => {
							if (element2.value.checked) {
								// if (this.ms.roleId.value)
								// 	element2['controls'].roleId.setValue(this.ms.roleId.value);
								if (this.ms.isAccessAllowed.value)
									element2['controls'].isAccessAllowed.setValue(this.ms.isAccessAllowed.value);
								if (this.ms.accessType.value)
									element2['controls'].accessType.setValue(this.ms.accessType.value);
								element2['controls'].checked.setValue(false);
							}
						});
					}
				});
			}
		});
		this.f.checked.setValue(false);
		$('.selectpicker').selectpicker('refresh');
		this.multiSelectMenu.reset();
	}

	setFormData() {
		let menuListFinal = [];
		let entityTypeMenuData = this.config.data.entityTypeData.filter(type => type.id == this.user.entityType)[0];
		let userTypeMenuData = this.config.data.userTypeData.filter(type => type.id == this.user.userType)[0];

		this.menuList.forEach(element => {
			if (userTypeMenuData.menu.includes(element.menuCode) && menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					// roleId: element.roleId,
					userId: element.userId,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (userTypeMenuData.menu.includes(element1.menuCode) && (menuListFinal.filter(data => data.menuCode == element.menuCode).length > 0 && menuListFinal.filter(data => data.menuCode == element.menuCode)[0].userMenusImpls && menuListFinal.filter(data => data.menuCode == element.menuCode)[0].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							// roleId: element1.roleId,
							userId: element1.userId,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (userTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						menuListFinal[menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.menuList.forEach(element => {
			if (entityTypeMenuData.menu.includes(element.menuCode) && menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					// roleId: element.roleId,
					userId: element.userId,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (entityTypeMenuData.menu.includes(element1.menuCode) && (menuListFinal.filter(data => data.menuCode == element.menuCode).length > 0 && menuListFinal.filter(data => data.menuCode == element.menuCode)[0].userMenusImpls && menuListFinal.filter(data => data.menuCode == element.menuCode)[0].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							// roleId: element1.roleId,
							userId: element1.userId,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (entityTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						menuListFinal[menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})

		let accessMappingControl = <FormArray>this.f.accessMapping;
		menuListFinal.forEach((element, index) => {
			accessMappingControl.push(this.formBuilder.group({
				checked: false,
				verify: false,
				userMenuMapId: [element.userMenuMapId],
				userId: [element.userId],
				menuId: [element.menuId],
				menuName: [element.menuName],
				menuCode: [element.menuCode],
				// roleId: [{ value: element.roleId, disabled: (this.action == 'view' || this.access.roleId == '2' || (element.menuCode!='admin' && element.menuCode!='bank')) }],
				isAccessAllowed: [{ value: element.isAccessAllowed || 'N', disabled: (this.action == 'view') }],
				accessType: [{ value: (element.menuCode == 'enit' ? 'W' : element.accessType) || 'R', disabled: (this.action == 'view' || element.menuCode == 'common' || element.menuCode == 'enit') }],
				isActive: [element.isActive],
				isDeleted: [element.isDeleted],
				checkedStatus: [element.checkedBy ? 'approve' : null],
				checkedBy: [element.checkedBy || null],
				checkedDate: [element.checkedDate || null],
				createdBy: [element.createdBy],
				createdDate: [element.createdDate],
				lastModifiedBy: [element.lastModifiedBy],
				lastModifiedDate: [element.lastModifiedDate],
				userMenusImpls: this.formBuilder.array([])
			}));
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					(accessMappingControl.at(accessMappingControl.length - 1).get('userMenusImpls') as FormArray).push(this.formBuilder.group({
						checked: false,
						verify: false,
						userMenuMapId: [element1.userMenuMapId],
						userId: [element1.userId],
						menuId: [element1.menuId],
						menuName: [element1.menuName],
						menuCode: [element1.menuCode],
						// roleId: [{ value: element1.roleId, disabled: (this.action == 'view' || this.access.roleId == '2' || (element.menuCode!='admin' && element.menuCode!='bank')) }],
						isAccessAllowed: [{ value: element1.isAccessAllowed || 'N', disabled: (this.action == 'view') }],
						accessType: [{ value: (element.menuCode == 'enit' ? 'W' : element1.accessType) || 'R', disabled: (this.action == 'view' || element1.menuCode == 'common' || element1.menuCode == 'enit') }],
						isActive: [element1.isActive],
						isDeleted: [element1.isDeleted],
						checkedStatus: [element1.checkedBy ? 'approve' : null],
						checkedBy: [element1.checkedBy || null],
						checkedDate: [element1.checkedDate || null],
						createdBy: [element1.createdBy],
						createdDate: [element1.createdDate],
						lastModifiedBy: [element1.lastModifiedBy],
						lastModifiedDate: [element1.lastModifiedDate],
						userMenusImpls2: this.formBuilder.array([])
					}))
					let menuMappingControl = <FormArray>(accessMappingControl.at(accessMappingControl.length - 1).get('userMenusImpls') as FormArray);
					if (element1.userMenusImpls) {
						element1.userMenusImpls.forEach(element2 => {
							(menuMappingControl.at(menuMappingControl.length - 1).get('userMenusImpls2') as FormArray).push(this.formBuilder.group({
								checked: false,
								verify: false,
								userMenuMapId: [element2.userMenuMapId],
								userId: [element2.userId],
								menuId: [element2.menuId],
								menuName: [element2.menuName],
								menuCode: [element2.menuCode],
								// roleId: [{ value: element2.roleId, disabled: (this.action == 'view' || this.access.roleId == '2' || (element.menuCode!='admin' && element.menuCode!='bank')) }],
								isAccessAllowed: [{ value: element2.isAccessAllowed || 'N', disabled: (this.action == 'view') }],
								accessType: [{ value: (element.menuCode == 'enit' ? 'W' : element2.accessType) || 'R', disabled: (this.action == 'view' || element2.menuCode == 'common' || element2.menuCode == 'enit') }],
								isActive: [element2.isActive],
								isDeleted: [element2.isDeleted],
								checkedStatus: [element2.checkedBy ? 'approve' : null],
								checkedBy: [element2.checkedBy || null],
								checkedDate: [element2.checkedDate || null],
								createdBy: [element2.createdBy],
								createdDate: [element2.createdDate],
								lastModifiedBy: [element2.lastModifiedBy],
								lastModifiedDate: [element2.lastModifiedDate],
							}))
						});
					}
				});
			}
		});
		this.accessMapping = <FormArray>this.f.accessMapping;
		this.cdref.detectChanges();
		$('.selectpicker').selectpicker('refresh');
	}

	getUserMenuImpls(i: number) {
		this.userMenusImpls = <FormArray>this.accessMapping.at(i).get('userMenusImpls');
	}

	onSubmit() {
		let requestObject = { "userMenuMappings": [] };
		this.userAccessForm.getRawValue().accessMapping.forEach((element, index) => {
			requestObject.userMenuMappings.push(
				{
					"userMenuMapId": element.userMenuMapId,
					"userId": element.userId,
					"menuId": element.menuId,
					"menuName": element.menuName,
					"menuCode": element.menuCode,
					"roleId": element.roleId || 1,
					"isAccessAllowed": element.isAccessAllowed || 'N',
					"accessType": element.accessType || 'R',
					"isActive": element.isAccessAllowed || 'N',
					"isDeleted": element.isDeleted,
					"checkedBy": element.checkedBy,
					"checkedDate": new Date(element.checkedDate),
					"createdBy": element.createdBy,
					"createdDate": new Date(element.createdDate),
					"lastModifiedBy": element.lastModifiedBy,
					"lastModifiedDate": new Date(element.lastModifiedDate)
				});

			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					requestObject.userMenuMappings.push(
						{
							"userMenuMapId": element1.userMenuMapId,
							"userId": element1.userId,
							"menuId": element1.menuId,
							"menuName": element1.menuName,
							"menuCode": element1.menuCode,
							"roleId": element1.roleId || 1,
							"isAccessAllowed": element1.isAccessAllowed || 'N',
							"accessType": element1.accessType || 'R',
							"isActive": element.isAccessAllowed || 'N',
							"isDeleted": element1.isDeleted,
							"checkedBy": element1.checkedBy,
							"checkedDate": new Date(element1.checkedDate),
							"createdBy": element1.createdBy,
							"createdDate": new Date(element1.createdDate),
							"lastModifiedBy": element1.lastModifiedBy,
							"lastModifiedDate": new Date(element1.lastModifiedDate)
						})
					if (element1.userMenusImpls2) {
						element1.userMenusImpls2.forEach(element2 => {
							requestObject.userMenuMappings.push(
								{
									"userMenuMapId": element2.userMenuMapId,
									"userId": element2.userId,
									"menuId": element2.menuId,
									"menuName": element2.menuName,
									"menuCode": element2.menuCode,
									"roleId": element2.roleId || 1,
									"isAccessAllowed": element2.isAccessAllowed || 'N',
									"accessType": element2.accessType || 'R',
									"isActive": element.isAccessAllowed || 'N',
									"isDeleted": element2.isDeleted,
									"checkedBy": element2.checkedBy,
									"checkedDate": new Date(element2.checkedDate),
									"createdBy": element2.createdBy,
									"createdDate": new Date(element2.createdDate),
									"lastModifiedBy": element2.lastModifiedBy,
									"lastModifiedDate": new Date(element2.lastModifiedDate)
								})
						});
					}
				});
			}
		});

		this.appService.saveUserMenuMappings(requestObject).pipe(
			catchError((error: any) => {
				this.spinner.hide();
				this.cdref.detectChanges();
				this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
				if (error.status != 200) {
					return throwError(error.error);
				}
			})
		).subscribe(
			(response: any) => {
				if (response.statusCode == 200) {
					this.message = 'User access details updated Successfully';
					this.toastr.success(this.message);
					this.router.navigate(['../'], { skipLocationChange: true, relativeTo: this.route });
				} else {
					this.message = response.message;
					this.toastr.error(this.message);
				}
			}
		)
	}

	checkRecordMenu(menuAccess, checkStatus) {
		if (checkStatus == 'approve') {
			menuAccess['controls'].checkedBy.setValue(this.userDetails.userId);
			menuAccess['controls'].checkedDate.setValue(new Date());
			menuAccess['controls'].isActive.setValue('Y');
		} else {
			menuAccess['controls'].checkedBy.setValue(null);
			menuAccess['controls'].checkedDate.setValue(null);
			menuAccess['controls'].isActive.setValue('N');
		}
		if (menuAccess['controls'].userMenusImpls.length > 0) {
			menuAccess['controls'].userMenusImpls['controls'].forEach(element => {
				if (checkStatus == 'approve') {
					element['controls'].checkedBy.setValue(this.userDetails.userId);
					element['controls'].checkedDate.setValue(new Date());
					element['controls'].isActive.setValue('Y');
				} else {
					element['controls'].checkedBy.setValue(null);
					element['controls'].checkedDate.setValue(null);
					element['controls'].isActive.setValue('N');
				}
				if (element['controls'].userMenusImpls2.length > 0) {
					element['controls'].userMenusImpls2['controls'].forEach(element1 => {
						if (checkStatus == 'approve') {
							element1['controls'].checkedBy.setValue(this.userDetails.userId);
							element1['controls'].checkedDate.setValue(new Date());
							element1['controls'].isActive.setValue('Y');
						} else {
							element1['controls'].checkedBy.setValue(null);
							element1['controls'].checkedDate.setValue(null);
							element1['controls'].isActive.setValue('N');
						}
					});
				}
			});
		}
	}

	checkRecordSubMenu(menuAccess, subMenuAccess, checkStatus) {
		if (checkStatus == 'approve') {
			menuAccess['controls'].checkedBy.setValue(this.userDetails.userId);
			menuAccess['controls'].checkedDate.setValue(new Date());
			menuAccess['controls'].isActive.setValue('Y');
			subMenuAccess['controls'].checkedBy.setValue(this.userDetails.userId);
			subMenuAccess['controls'].checkedDate.setValue(new Date());
			subMenuAccess['controls'].isActive.setValue('Y');
		} else {
			subMenuAccess['controls'].checkedBy.setValue(null);
			subMenuAccess['controls'].checkedDate.setValue(null);
			subMenuAccess['controls'].isActive.setValue('N');
		}
		if (subMenuAccess['controls'].userMenusImpls2.length > 0) {
			subMenuAccess['controls'].userMenusImpls2['controls'].forEach(element => {
				if (checkStatus == 'approve') {
					element['controls'].checkedBy.setValue(this.userDetails.userId);
					element['controls'].checkedDate.setValue(new Date());
					element['controls'].isActive.setValue('Y');
				} else {
					element['controls'].checkedBy.setValue(null);
					element['controls'].checkedDate.setValue(null);
					element['controls'].isActive.setValue('N');
				}
			});
		}
	}

	checkRecordSubMenu2(menuAccess, subMenuAccess, subMenuAccess2, checkStatus) {
		if (checkStatus == 'approve') {
			menuAccess['controls'].checkedBy.setValue(this.userDetails.userId);
			menuAccess['controls'].checkedDate.setValue(new Date());
			menuAccess['controls'].isActive.setValue('Y');
			subMenuAccess['controls'].checkedBy.setValue(this.userDetails.userId);
			subMenuAccess['controls'].checkedDate.setValue(new Date());
			subMenuAccess['controls'].isActive.setValue('Y');
			subMenuAccess2['controls'].checkedBy.setValue(this.userDetails.userId);
			subMenuAccess2['controls'].checkedDate.setValue(new Date());
			subMenuAccess2['controls'].isActive.setValue('Y');
		} else {
			subMenuAccess2['controls'].checkedBy.setValue(null);
			subMenuAccess2['controls'].checkedDate.setValue(null);
			subMenuAccess2['controls'].isActive.setValue('N');
		}

	}

}
