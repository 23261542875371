import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { config } from '../valueObject/config';

@Injectable({
  providedIn: 'root'
})
export class CelService {

  celDealerData: any[] = [];
  config: any = config;
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getUserList(userControlId) {
    let URL = environment.URL + this.config.URL.celUserListURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  addUser(user: any) {
    let URL = environment.URL + this.config.URL.addCelUserURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

  updateUser(user: any) {
    let URL = environment.URL + this.config.URL.updateCelUserURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, user, this.options);
  }

  getBranchAdminMapping(userControlId: any) {
    let URL = environment.URL + this.config.URL.getBranchAdminMapping;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  getBranches() {
    let URL = environment.URL + this.config.URL.getBranches;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  addBranchAdminMapping(requestObject: any) {
    let URL = environment.URL + this.config.URL.addBranchAdminMapping;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  deleteBranchAdminMapping(requestObject: any) {
    let URL = environment.URL + this.config.URL.deleteBranchAdminMapping;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  getCelLimit(userControlId: any) {
    let URL = environment.URL + this.config.URL.getCelLimit;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  getCelLimitPerUser(userId) {
    let URL = environment.URL + this.config.URL.getCelLimitPerUser + '/' + userId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  saveCelLimit(requestObject) {
    let URL = environment.URL + this.config.URL.saveCelLimit;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  saveProductCoeff(requestObject) {
    let URL = environment.URL + this.config.URL.saveProdCoeff;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  getProdCoef(userControlId: any) {
    let URL = environment.URL + this.config.URL.getProdCoef;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  getDealerList(userControlId: string) {
    let URL = environment.URL + this.config.URL.getCelDealerList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  enableCelMonitoring(userId: string, monitoringStatus: string) {
    let URL = environment.URL + this.config.URL.enableCelMonitoring + '/' + userId + '/' + monitoringStatus;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, {}, this.options);
  }
  addDealerData(celDealerData: any[]) {
    this.celDealerData = celDealerData;
  }

  getBranchAlert(userControlId: any) {
    let URL = environment.URL + this.config.URL.getBranchAlert;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  saveBranchAlert(requestObject) {
    let URL = environment.URL + this.config.URL.saveBranchAlert;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  getCelUtilization(userControlId: string) {
    let URL = environment.URL + this.config.URL.getCelUtilization;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  getNotification() {
    let URL = environment.URL + this.config.URL.getMarketAlert;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  getCelAudit(userControlId: any) {
    let URL = environment.URL + this.config.URL.getCelAudit;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  getMMInfo() {
    let URL = environment.URL + this.config.URL.getMMInfo;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  deleteBranchEmail(branchEmailId) {
    let URL = environment.URL + this.config.URL.deleteBranchEmail + "/" + branchEmailId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  sendVerificationLink(verificationId) {
    let URL = environment.URL + this.config.URL.sendVerificationLink + "/" + verificationId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);

  }
}
