import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: any[] = [];

  constructor() { }

  addMenu(menuList: any[]) {
    this.menu = menuList;
  }

  getAllMenu() {
    return this.menu;
  }

  getMenuById(id: number) {
    return this.menu.find(data => data.menuId == id);
  }
}
