import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $:any;
@Component({
  selector: 'app-shifting-neat-id-request',
  templateUrl: './shifting-neat-id-request.component.html',
  styleUrls: ['./shifting-neat-id-request.component.css']
})
export class ShiftingNeatIdRequestComponent implements OnInit {
  ShiftingNeatUser: FormGroup;
  user:any={};
  cusDlrId: any=[];
   memCode: String;
  memId: String;
  submitted = false;
  config: any = 'config';
  message: string;
  memName:String;
  menucode = 'displayShiftingOfNeatIdRequestPage';
  access:any={};
  entityType:any;
  module: any="SHIFTING";
  reqtype: number = 3;
  loginId: string;
  verifyOTP: boolean;
  tapIPList: any = [];
  ipError: boolean = false;
  ipLevels : any = [];
  existingIpList : any = []
  selectedIp: any;
  primaryIp: any;
  finalArray: any =[];
  ipsToDeleteSet: any;
  constructor(private formbuilder: FormBuilder, 
    private toastr: ToastrService,
    private EnitService: EnitService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private userDetailsService: UserDetailsService,
    private enitTradeService: EnitTradeService) { }
    
    newItemEvent(event: boolean){
      this.verifyOTP=event;
      // console.log(this.verifyOTP);
      if(this.verifyOTP == true){
        $('#cashdeposite').modal('hide');
      }
    }

  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.memName = this.user.userName;
    this.titleService.setTitle('Shifting Neat ID');
    $('.selectpicker').selectpicker();
    this.ShiftingNeatUser = this.formbuilder.group({
      cusDlrId: ['',Validators.required],
       UserName : [''],
       TypeOfUser:[''],
       ExistingTapIp:['',Validators.required],
       TapId:['',Validators.required],
       ExistingTapChildIpAddress:[''],
       existingTerminalTapIp:[''],
       ShiftTapId:['',Validators.required],
       EmailId:['',[ Validators.email]],
       MobileNo:['',[
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        Validators.minLength(10), Validators.maxLength(10)]],
       ReasonForShiftingUser:['',Validators.required],
       CheckType: [false, Validators.pattern('true')],
       CheckType1: [false, Validators.pattern('true')],
       ShiftTapChildIpAddress:[''],
    })
    this.loginId = this.user.loginId;
    this.getShifingIdDetails(this.memCode,this.memId);
  }
  get f() { return this.ShiftingNeatUser.controls; }
  reset(){
    this.ShiftingNeatUser.reset();
    $('.selectpicker').selectpicker('refresh');
    this.submitted = false;
    this.verifyOTP = false;
  }
  getShifingIdDetails(memCode: String,memId: String){
    this.EnitService.getShifingIdDetails(memCode,memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response:any)=>{
      if(response.statusCode==200){
        this.cusDlrId=response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }
  selectDlrId(){
   this.EnitService.getSingleDealerIdDetails(this.memCode, this.memId, this.f.cusDlrId.value).pipe(
    catchError((error: any) => {
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
  ).subscribe((response:any)=>{
    if(response.data.cusDlrTidTapId==null){
        this.toastr.warning('Tap ID for User ID '+ this.f.cusDlrId.value + ' not found');
        this.f.UserName.setValue(response.data.cusDlrNm);
        this.f.TypeOfUser.setValue(response.data.cusDlrType);
        this.f.TapId.setValue(null);
        this.f.ExistingTapChildIpAddress.setValue(response.data.txtRegOffAddress);
        this.f.existingTerminalTapIp.setValue(response.data.noOfTerminals);
        this.f.ShiftTapChildIpAddress.setValue(response.data.txtRegOffAddress);
        this.ipLevels = response.data.ipLevels;
        this.existingIpList = this.ipLevels;
        this.primaryIp = this.ipLevels.find(data => data.tapIpType == "P")
        this.f.ExistingTapIp.setValue(this.primaryIp.ipLevelsId.tapIP);
        this.selectedIp = this.ipLevels.find(data => data.ipLevelsId.tapIP == this.f.ExistingTapIp.value);
        this.f.TapId.setValue(this.selectedIp.ipLevelsId.boxId);
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');

       }
       else{
        this.f.UserName.setValue(response.data.cusDlrNm);
        this.f.TypeOfUser.setValue(response.data.cusDlrType);
        this.f.TapId.setValue(null);
        this.f.ExistingTapChildIpAddress.setValue(response.data.txtRegOffAddress);
        this.f.existingTerminalTapIp.setValue(response.data.noOfTerminals);
        this.f.ShiftTapChildIpAddress.setValue(response.data.txtRegOffAddress);
        this.ipLevels = response.data.ipLevels;
        this.existingIpList = this.ipLevels;
        this.primaryIp = this.ipLevels.find(data => data.tapIpType == "P")
        this.f.ExistingTapIp.setValue(this.primaryIp.ipLevelsId.tapIP);
        this.selectedIp = this.ipLevels.find(data => data.ipLevelsId.tapIP == this.f.ExistingTapIp.value);
        this.f.TapId.setValue(this.selectedIp.ipLevelsId.boxId);
        this.cdref.detectChanges();
          $('.selectpicker').selectpicker();
          $('.selectpicker').selectpicker('refresh');
      }
    })

    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
    };
    this.enitTradeService.getTapipdetails(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.tapIPList = [];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.tapIPList = response.data.cusTapIpList;
        this.ipsToDeleteSet = new Set(this.existingIpList.map(ip => ip.ipLevelsId.tapIP));
        // console.log(this.ipsToDeleteSet);
        this.finalArray = this.tapIPList.filter((ips => {
          return !this.ipsToDeleteSet.has(ips.cusTapIp);
        }))
        // console.log(this.finalArray);
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }

  
  setTapId(){
    this.selectedIp = this.ipLevels.find(data => data.ipLevelsId.tapIP == this.f.ExistingTapIp.value);
    this.f.TapId.setValue(this.selectedIp.ipLevelsId.boxId);
  }

  getTapIpList() {
    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
    };
    this.enitTradeService.getTapipdetails(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.tapIPList = [];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.tapIPList = response.data.cusTapIpList;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }
  checkIp(){
    if(this.f.ExistingTapIp.value == this.f.ShiftTapId.value){
      this.message='You cannot shift to the same TAP/DC IP. \n Please select different TAP/DC IP.';
      //this.toastr.error(this.message);
      this.ipError = true;
    }
    else{
      this.ipError = false;
    }
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    let requestObject = {
			"memId":this.memId,
			"memCode":this.memCode,
			"cusDlrUsrId":this.f.cusDlrId.value,
			"cusDlrNm":this.f.UserName.value,
			"cusDlrType":this.f.TypeOfUser.value,
			"cusDlrTapIp":this.f.ExistingTapIp.value,
			"cusDlrTidTapId":this.f.TapId.value,
			"noOfTerminals":this.f.existingTerminalTapIp.value,
			"txtRegOffAddress":this.f.ExistingTapChildIpAddress.value,
			"cusEmail":this.f.EmailId.value,
			"cusContactNo":this.f.MobileNo.value,
			"cusReason":this.f.ReasonForShiftingUser.value ,
      "txtTrmnOffAddress":this.f.ShiftTapChildIpAddress.value,
      "selectedTapIp":this.f.ShiftTapId.value,
    };
    this.EnitService.SaveShiftingNeatUserIdRequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        let refNo = response.data;
        // ?this.message = 'Tap IP Change Request Sent Successfully';
        this.toastr.success("Request successfully generated With Reference Number: " +refNo);
        this.ShiftingNeatUser.reset();
        $('.selectpicker').selectpicker('refresh');
        this.submitted=true;
        // window.location.reload();
      } else {
        this.toastr.error(response.message)
        this.submitted = false;
      }
    })
  }
}


