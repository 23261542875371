import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { BranchUserService } from 'src/app/service/branch-user.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { BranchAdminService } from 'src/app/service/branch-admin.service';
import { CelService } from 'src/app/service/cel.service';
import { catchError } from 'rxjs/operators';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-assign-admin-to-branch-form',
  templateUrl: './assign-admin-to-branch-form.component.html',
  styleUrls: ['./assign-admin-to-branch-form.component.css']
})
export class AssignAdminToBranchFormComponent implements OnInit, AfterViewInit, OnDestroy {

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  access: any = {};
  users: any = [];
  branchData: any = [];
  action: string = '';
  userForm: FormGroup;
  adminForm: FormGroup;
  userData: any = [];
  selectedBranchId: string = '';
  submittedBranch: boolean = false;
  config: any = config;

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private branchUserService: BranchUserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private branchAdminService: BranchAdminService,
    private celService: CelService) {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.userData = this.branchAdminService.getBranchById(params['id']);
        this.action = 'edit';
      } else {
        this.action = 'add';
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Assign Branch Admin");
    this.userForm = this.formBuilder.group({
      userId: [this.userData.userId, Validators.required],
      userName: [this.userData.userName, Validators.required]
    })
    this.adminForm = this.formBuilder.group({
      branchId: [null, Validators.required]
    })
    this.getBranchData();
  }

  ngAfterViewInit() {
    this.setDatatable();
    $('.selectpicker').selectpicker();
  }

  get uf() { return this.userForm.controls }
  get af() { return this.adminForm.controls }

  getBranchData() {
    this.celService.getBranches().pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.branchData = [];
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          let branchIdArray = this.userData.branches?.map(data => data.branchId);
          this.branchData = response.data[0];
          this.branchData = this.branchData.filter(data => !branchIdArray.includes(data.branchId));
          this.cdref.detectChanges();
          $('.selectpicker').selectpicker('refresh');
        } else {
          this.toastr.error(response.message);
          this.branchData = [];
          this.cdref.detectChanges();
          $('.selectpicker').selectpicker('refresh');
        }
      }
    );
  }

  setDatatable() {
    $("#branchTable").DataTable().destroy();
    setTimeout(() => {
      $("#branchTable").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "lrtip"
      });
      this.cdref.detectChanges();
    }, 1);
  }

  deleteBranchId(branchId) {
    this.selectedBranchId = branchId;
  }

  deleteBranch() {
    let requestObject = {
      loginId: this.userData.userId,
      branchCode: this.selectedBranchId
    };
    this.celService.deleteBranchAdminMapping(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Branch removed successfully");
          this.userData.branches = this.userData.branches.filter(data => data.branchId != this.selectedBranchId);
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.setDatatable();
        }
      }
    );
  }

  onSave() {
    let branchAdminData = this.branchAdminService.getBranchAdminData();
    let count = 0;
    branchAdminData.forEach(data => {
      let branchArray = data.branches.filter(data1 => data1.branchId == this.af.branchId.value);
      if (branchArray.length > 0 && data.status == 'Y')
        count++;
    });
    if (count >= 5) {
      this.toastr.error("This branch is already assigned to " + count + " admin users");
    } else {
      if (!this.userData.branches)
        this.userData.branches = [];
      this.userData.branches.push({
        branchId: this.af.branchId.value,
        branchName: this.branchData.find(data => data.branchId == this.af.branchId.value).branchName
      })
      this.branchData = this.branchData.filter(data => this.af.branchId.value != data.branchId);
      this.af.branchId.setValue(null);
      this.setDatatable();
      $('.selectpicker').selectpicker('refresh');
    }

  }

  onSubmit() {
    this.celService.addBranchAdminMapping(this.userData).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Admin user - branch mapping updated successfully");
          this.router.navigate(['../assignBranchAdmin'], { relativeTo: this.route, skipLocationChange: true });
          this.cdref.detectChanges();
        } else {
          this.toastr.error(response.message);
          this.cdref.detectChanges();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger?.unsubscribe();
  }
}
