import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponentComponent } from './login-component/login-component.component';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { ValidateUserComponent } from './validateUser/validateUser.component';
import { OtpComponent } from './otp/otp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';
import { LoginService } from 'src/app/service/login.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContactComponent } from './contact/contact.component';
import { PasswordPolicyComponent } from './password-policy/password-policy.component';
import { LoginBaseComponent } from './login-base/login-base.component';

@NgModule({
  declarations: [
    LoginBaseComponent,
    LoginComponentComponent,
    ForgotpassComponent,
    ValidateUserComponent,
    OtpComponent, 
    ChangePasswordComponent, 
    PasswordExpiredComponent,
    ProfileComponent,
    ContactComponent,
    PasswordPolicyComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    LoginService
  ]
})
export class LoginModule { }
