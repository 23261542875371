<div class="container-fluid mt-3 mb-3 theme">
  <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
    <div class="col-lg-10">
      <div class="d-flex">
        <h1>AI & ML Eligibility List </h1>
        <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
          <li><a routerLink="">Home</a></li>
          <li><a>Enit Compliance </a></li>
          <li class="active"> <a routerLink="/complianceAdmin/mlAndAiAdmin/aimlEleigibleUsers" skipLocationChange
              aria-current="page">AI & ML Eligibility List</a></li>
        </ul>
      </div>
    </div>

  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please wait.</p>
  </ngx-spinner>
  <div class="card card-body">
    <form [formGroup]="searchForm">
      <div class="row">
        <div class="col-lg-3 form-group">
          <label>Year</label>

          <select class="form-control selectpicker" formControlName="year" data-style="btn-info" title="Select Year"
            data-live-search="true" (change)="change()">
            <option *ngFor="let data of quarterDetails.yearData" value="{{data}}">{{data}}</option>
          </select>
        </div>
        <div class="col-lg-3 form-group">
          <label>Quarter</label>
          <select class="form-control selectpicker" formControlName="quarter" data-style="btn-info"
            title="Select Quarter" (change)="change()">
            <!-- <option *ngFor="let data of quarterDetails.quarterList" value="{{data.quarter}}">{{data.quarter}}</option> -->
            <option value="Q1">Q1</option>
            <option value="Q2">Q2</option>
            <option value="Q3">Q3</option>
            <option value="Q4">Q4</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 form-group">
          <div class="row">
            <div class="col-lg-6">
              <button class="btn btn-primary full-width" type="submit" [disabled]="searchForm.invalid"
                (click)="onSubmit()">Search</button>
            </div>
            <div class="col-lg-6">
              <button class="btn btn-cancel full-width" (click)="onReset()">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row align-self-end mr-3">
      <div>
          <button class="btn btn-primary full-width" *ngIf="selectedYear===currentYear && selectedQuarter===currentQuarter && (eligibleAIMLData !=0 )"  
          data-toggle="modal" data-target="#notifyAll" data-backdrop="static" data-keyboard="false" skipLocationChange>NotifyAll</button>
      </div>
      <div class="">
        <button class="btn btn-primary full-width ml-2" *ngIf="selectedYear===currentYear && selectedQuarter===currentQuarter" routerLink="add-AIMLEligibility" skipLocationChange>Add</button>
      </div>
      <div class="">
        <button class="btn btn-primary full-width ml-3" data-toggle="modal" data-target="#uploadFileModal"
          data-backdrop="static" data-keyboard="false" skipLocationChange>UploadFile</button>
      </div>

      <div class="">
        <i class="fa fa-question-circle ml-4 mt-1" style="font-size: 25px;" aria-hidden="true" (click)="downloadFile()"></i>
      </div>

    </div>
    <table id="eligibleAIMLTable" class="overflow table common-table nowrap" datatable [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th class="normalwrap">Member Code</th>
          <th class="normalwrap">Member Name</th>
          <th class="normalwrap">Quarter Start Date</th>
          <th class="normalwrap">Quarter End Date</th>
          <th>Action</th>
        </tr>
        <tr class="column-search">
          <th><input type="text" class="form-control form-control-sm" /></th>
          <th><input type="text" class="form-control form-control-sm" /></th>
          <th><input type="text" class="form-control form-control-sm" /></th>
          <th><input type="text" class="form-control form-control-sm" /></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of  eligibleAIMLData">
          <td>{{10000+data.memberId}}</td>
          <td>{{data.memberName}}</td>
          <td>{{data.quarterStartDate | date:'yyyy-MMM-dd'}}</td>
          <td>{{data.quarterEndDate | date:'yyyy-MMM-dd'}}</td>
          <td>
            <i  (click)="deleteFromEligibleList(data.quarterId)"  *ngIf="selectedYear===currentYear && selectedQuarter===currentQuarter" class="fa fa-trash p-1" aria-hidden="true"
              data-toggle="modal" data-target="#delete" data-backdrop="static" data-keyboard="false"></i> &nbsp;
              <i  (click)="selectIdnotifySelectedUser(data.quarterId)"  *ngIf="selectedYear===currentYear && selectedQuarter===currentQuarter" class="fa fa-bell p-1" aria-hidden="true"
              data-toggle="modal" data-target="#notifySingle" data-backdrop="static"></i> 
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body text-center">
        <h6>Are you sure you want to delete Member  {{selectedDeleteRow}}</h6>
        <div class="d-flex justify-content-center mt-3">
          <div class="col-3"><button type="button" class="btn btn-secondary full-width"
              data-dismiss="modal">Cancel</button></div>
          <div class="col-3"><button type="button" class="btn btn-primary full-width" data-dismiss="modal"
              (click)="deleteAIMLEligible()">Delete</button></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- File Upload popup -->
<div class="modal fade" id="uploadFileModal" tabindex="-1" role="dialog" aria-labelledby="uploadFileModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body text-center">
        <form [formGroup]="fileUploadForm">
          <h6>Select File For Upload</h6>
          <div class="modal-body">

            <div class="row">
              <div class="col-lg-10 form-group">
                <input id="upload_file"  class="form-control" accept=".xls" formControlName="file" type="file" #file
                  (change)="onChange($event)" />
                <p *ngIf="fileUploadForm.get('file').invalid && fileUploadForm.get('file').touched" class="error">
                  Please upload file
                </p>
                <div *ngIf="fileError" >
                  <p class="error nocase">File should be in xls format</p>
               </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                data-dismiss="modal">Cancel</button></div>
            <div class="col-3"><button class="btn btn-primary full-width" data-dismiss="modal"
                [disabled]="fileUploadForm.invalid || fileError " (click)="onFileSubmit()">Submit</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<!-- Notifify All Members-->
  <div class="modal fade" id="notifyAll" tabindex="-1" role="dialog" aria-labelledby="notifyALLModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body text-center">
          <h6>Are you sure you want to Notify All Members</h6>
          <div class="d-flex justify-content-center mt-3">
            <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                data-dismiss="modal">Cancel</button></div>
            <div class="col-3"><button type="button" class="btn btn-primary full-width" data-dismiss="modal"
                 (click)="notifyCurrentQuarterUser()">NotifyAll</button></div> 
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Notifify Single Members-->
  <div class="modal fade" id="notifySingle" tabindex="-1" role="dialog" aria-labelledby="notifyALLModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body text-center">
          <div>
          <h6 *ngIf="notifySelectedRow">Are you sure you want to Notify {{10000 *1 +notifySelectedRow.memberId}} Member</h6>
        </div>
          <div class="d-flex justify-content-center mt-3">
            <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                data-dismiss="modal">Cancel</button></div>
            <div class="col-3"><button type="button" class="btn btn-primary full-width" data-dismiss="modal"
                 (click)="notifySelectedUser()">Notify</button></div> 
          </div>
        </div>
      </div>
    </div>
  </div>