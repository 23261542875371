import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from '../valueObject/config';


@Injectable({
  providedIn: 'root'
})
export class ExtranetService {
  config: any = config;
  tmLimitData: any[] = [];
  fileType: string;
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  uploadExtranetFile(file: File, fileType: string) {
    let URL = environment.URL + this.config.URL.uploadExtranetFile;
    let fileOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType)
    return this.http.post(URL, formData, fileOptions);
  }

  getExtranetFiles(folderType, userControlId) {
    let URL = environment.URL + this.config.URL.getExtranetFiles + '?folderType=' + folderType;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  getFileDownloadable(fileName) {
    let URL = environment.URL + this.config.URL.getFileDownloadExt;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, fileName, this.options);
  }

  getExtranetFolderList(userControlId) {
    let URL = environment.URL + this.config.URL.getFolderList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

}

