import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EnitService } from 'src/app/service/enit.service';
declare var $: any

@Component({
  selector: 'app-et-change-name-process-page',
  templateUrl: './et-change-name-process-page.component.html',
  styleUrls: ['./et-change-name-process-page.component.css']
})
export class EtChangeNameProcessPageComponent implements OnInit {
  reqObj: any={};
  changeName: FormGroup;
  submitted = false;
  message: string;
  memId: String;
  user:any={};
  access:any={};
  entityType:any;
  userName:any
  menucode: string='';
  config: any = 'config';

  @ViewChild('selectpicker') selectPicker: ElementRef;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private EnitService: EnitService,
    private userDetailsService: UserDetailsService,
    private titleService: Title,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
  ) { 
    this.route.queryParams.subscribe((params) => {
      if (params['refNo']) {
        this.reqObj = enitTradeService.getNewNeatRequestData(params['refNo']);
      }
    });
  }

  get f(){
    return this.changeName.controls;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Process Name Change Request');
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.userName = this.user.userName
    this.changeName = this.formbuilder.group({
      memCode:[10000+this.reqObj.memId],
      memName:[this.reqObj.memName],
      userName:[this.reqObj.cusDlrNm], //confused
      tapChildIp:[this.reqObj.cusTapIp],
      tapId:[this.reqObj.cusTidId],
      reqId:[this.reqObj.cusReqIdBigD],
      userId:[this.reqObj.dlr_id], //confused
      certifiedBy:[this.reqObj.examOf],
      regNo:[this.reqObj.examId],//confused
      title:[this.reqObj.title],
      firstName:[this.reqObj.firstName],
      middleName:[this.reqObj.middleName],
      lastName:[this.reqObj.lastName],
      dob:[this.reqObj.cusDlrDOBStr],
      validityDate:[this.reqObj.dlex_valid_dt],
      percentage:[this.reqObj.dlex_marks],
      qualification:[this.reqObj.dlr_qualification],
      fatherHusband:[this.reqObj.dlr_father_name],
      relationship:[this.reqObj.dlr_mem_relation],
      panNo:[this.reqObj.cusDlrPan],
      contact:[this.reqObj.dlr_CELL_CONTACT],
      email:[this.reqObj.cusEmail],
      remarks:[this.reqObj.cusReqStatus=="SUB"?'-':this.reqObj.cusRemarks],
      updatedStatus:['', Validators.required],

      //new fields
      reason:[this.reqObj.cusReason],
      userRemarks:[this.reqObj.rationale]
    })
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');

  }


  back(){ //change the path 
    if(this.entityType=='ENIT'){
      this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage']);
    }
    else if(this.entityType!='ENIT'){
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    }
  }

  submit(){
    let requestObject = {
      "memId":this.f.memCode.value,
      "cusRemarks": this.f.remarks.value,
      "cusRefNo":this.reqObj.refNo,
      "cusStatus":this.f.updatedStatus.value,
      "memName":this.f.memName.value,
      "cusDlrUsrId":this.reqObj.dlr_id,
      "reqUpdBy": this.userName,   
    } 

    this.EnitService.ApproveUserIdrequest(requestObject).pipe(
      catchError((error: any) => {
        
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
            this.message = 'Request Updated Successfully';
            this.toastr.success(this.message);
            this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
         } else {
        this.submitted = false;
        // this.toastr.error(response.message);
        this.toastr.error("Couldn't Update Request");
      }
    })

    // this.enitTradeService.updateNameChange(requestObject).pipe(
    //   catchError((error: any)=> {
    //     this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
    //     this.submitted = false;
    //     if (error.status != 200) {
    //       return throwError(error.error);
    //     }
    //   })
    // ).subscribe((response: any)=>{
    //   if(response.statusCode == 200){
    //     this.toastr.success("Request Updated Successfully");
    //     this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    //   }
    // })


  }

  reset(){
    this.f.updatedStatus.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    this.f.remarks.setValue('');
  }
}
