import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValidateUserComponent } from './validateUser/validateUser.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { LoginBaseComponent } from './login-base/login-base.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { OtpComponent } from './otp/otp.component';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';
import { ComponentComponent } from '../component/component.component';
import { ProfileComponent} from './profile/profile.component'
const routes: Routes = [

  { path: '', component: LoginComponentComponent },
  { path: 'otp', component: OtpComponent },
  { path: 'validateuser', component: ValidateUserComponent },
  { path: 'forgotpassword', component: ForgotpassComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'passwordexpired', component: PasswordExpiredComponent },
  { path: 'profile', component: ProfileComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
