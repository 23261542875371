import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitService } from 'src/app/service/enit.service';
import { config } from 'src/app/valueObject/config';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $: any;

@Component({
  selector: 'app-et-neat-to-nonneat',
  templateUrl: './et-neat-to-nonneat.component.html',
  styleUrls: ['./et-neat-to-nonneat.component.css'],
})
export class EtNeatToNonneatComponent implements OnInit {
  neatToNonNeat: FormGroup;
  activatedCTCLNeatId : any = [];
  config: any = config;
  submitted = false;
  message: string;
  memId: String ;
  fileName: string;
  file: File;
  isFileUploaded: boolean = false;
  base64Output: string;
  dlrUserId : any ;
  disabled: any = true;
  entityType: any;
  memCode: any;
  memName: any;
  menucode ="displayNeatToNonNeatRequest";
  user: any = {};
  access: any = {};
  constructor(
    private route: ActivatedRoute,
    private formbuilder: FormBuilder, 
    private toastr: ToastrService,
    private enitService: EnitService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private userDetailsService:UserDetailsService
  ) {}
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.titleService.setTitle('Request For CTCL to Non Neat Conversion')
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.titleService.setTitle('Conversion: NEAT/CTCL To NON NEAT ID');
    this.neatToNonNeat = this.formbuilder.group({
      NeatUserId : [null,Validators.required],
      TapChildIp : ['',Validators.required],
      ProductType : ['',Validators.required],
      CtclUndertaking : ['',Validators.required],
      ProFacility : ['',Validators.required],
      ProUndertaking : [''],
      EmpanelledVendorName : ['',Validators.required],
      SoftwareVersionNo : ['',[Validators.required, Validators.maxLength(30)]],
      NonNeatForCtclPurposeCheck : ['',Validators.required],
      RulesAndRegulationsCheck : ['',Validators.required],
    })
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    this.getActivatedCTCLNeatId(this.memId);
  }
  get f() { 
    return this.neatToNonNeat.controls; 
   }
  selectDlrId(){ //by Siddhant 2022
    this.enitService.getDetailsOnUserIdSelect(this.f.NeatUserId.value).subscribe(
      (response : any) =>{
        if(response.statusCode==200){
        this.f.TapChildIp.setValue(response.data.tim_Tap_Ip);
      }
    }
    )
  }
  getActivatedCTCLNeatId(memId : any){ //by Siddhant 2022
    this.enitService.getActivatedCtclNeatId(memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response:any)=>{
        if(response.statusCode==200){
          this.activatedCTCLNeatId = response.data;
          this.cdref.detectChanges();
          $('.selectpicker').selectpicker('refresh');
        }
      }
    )
  }
  reset(){
  this.neatToNonNeat.reset();
  this.f.NeatUserId.setValue(null);
  this.f.ProductType.setValue(null);
  this.f.CtclUndertaking.setValue(null);
  this.f.ProFacility.setValue(null);
  this.f.ProUndertaking.setValue(null);
  this.f.EmpanelledVendorName.setValue(null);
  $('.selectpicker').selectpicker('refresh');
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    let reqObj = {
      "memId" : this.memId,
      "nudSegCd" : null,
      "nudTapIp" : this.f.TapChildIp.value,
      "nudBrUsrId" : null,
      "nudProductType" : this.f.ProductType.value,
      "nudVendorId" : null,
      "nudSwVer" : this.f.SoftwareVersionNo.value,
      "nudUserId" : this.f.NeatUserId.value,
      "nudProEnabled" :this.f. ProFacility.value ,
      "nudFixEnabled" : null,
      "nudProUndertaking" : this.f.ProUndertaking.value || null,
      "nudDirectNetFlag" : null,
      "nudApprovalType" : null,
      "nudUploadedToCtcl" : null,
      "ctclUndertaking" : this.f.CtclUndertaking.value
    }
    this.enitService.saveNeatNonNeatConvRequest(reqObj).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        let refNo = response.data;
        this.toastr.success('Request Saved Successfully With Reference Number: ' +refNo);
        this.neatToNonNeat.reset();
        $('.selectpicker').selectpicker('refresh');
      } else {
        this.toastr.error(response.message);
        this.submitted = false;
      }
    })
  }
  chngProFaclityToTrue(){
    this.cdref.detectChanges();
     if(this.f.ProFacility.value=="Y"){
      this.disabled = false
      this.f.ProUndertaking.setValue(null);
      this.f.ProUndertaking.setValidators([Validators.required]);
      this.f.ProUndertaking.updateValueAndValidity();
    }
    else if (this.f.ProFacility.value=="N"){
      // this.disabled = true;
      this.neatToNonNeat.get('ProUndertaking').disable();
      this.f.ProUndertaking.setValue(null);
      this.f.ProUndertaking.clearValidators();
      this.f.ProUndertaking.updateValueAndValidity();
    }
    
    $('.selectpicker').selectpicker('refresh');
  }
}
