import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $:any; 
@Component({
  selector: 'app-disable-neat-user-id-admin',
  templateUrl: './disable-neat-user-id-admin.component.html',
  styleUrls: ['./disable-neat-user-id-admin.component.css']
})
export class DisableNeatUserIdAdminComponent implements OnInit {
  DisableNeatUserIdAdmin: FormGroup;
  reqObj: any={};
  submitted = false;
  config: any = 'config';
  message: string;
  fullname: any;
  entityType: any;
  user: any ={};
  access: any ={};
  space:any;
  status: any;
  @ViewChild('selectpicker') selectPicker: ElementRef;
  constructor(
    private toastr: ToastrService,
    private EnitService: EnitService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private formbuilder : FormBuilder,
    private userDetailsService : UserDetailsService,
    private spinner : NgxSpinnerService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['refNo']) {
        this.reqObj = enitTradeService.getNewNeatRequestData(params['refNo']);
        if(this.reqObj.cusDlrNm==null){
          this.reqObj.space=" ";
           this.reqObj.fullname=this.reqObj.firstName.concat(  this.reqObj.space).concat(  this.reqObj.middleName).concat(  this.reqObj.space).concat(  this.reqObj.lastName);
           
          //  this.space=" ";
          //  this.fullname=this.reqObj.firstName.concat(  this.space).concat(  this.reqObj.middleName).concat(  this.space).concat(  this.reqObj.lastName);
         }else{
           this.reqObj.fullname=this.reqObj.cusDlrNm;
         }
     }
    });
  }
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    $('.selectpicker').selectpicker('refresh');
    this.titleService.setTitle('Disable Neat User Id');

    
    this.DisableNeatUserIdAdmin = this.formbuilder.group({
      memCode:[10000+this.reqObj.memId],
      memberName:[this.reqObj.memName],
      userId:[this.reqObj.dlr_id],
      Username:[this.reqObj.fullname],
      typeOfUser:[this.reqObj.dlr_user_type],
      reasonOfdisable:[this.reqObj.cusReason],
      tapIp:[this.reqObj.cusTapIp],
      tapId:[this.reqObj.cusTidId],
      existingCertificationDetails:[this.reqObj.noOfTerminals],
      CurrentStatus:[],
      oldAddress:[this.reqObj.addr],
      //CurrentStatus:[(this.reqObj.cusReqStatus=='APPR')?'Approved':(this.reqObj.cusReqStatus=='RJCT')?'Rejected':'In Progress'],
      remark:[(this.reqObj.cusReqStatus=='APPR')?this.reqObj.cusRemarks:(this.reqObj.cusReqStatus=='RJCT')?this.reqObj.cusRemarks:''],
      UpdatedStatus:['', Validators.required]
    })
    if(this.reqObj.cusReqStatus=='APPR'){
      this.f.CurrentStatus.setValue('APPROVED');
    }else if(this.reqObj.cusReqStatus=='RJCT'){
      this.f.CurrentStatus.setValue('REJECTED');
    }else if(this.reqObj.cusReqStatus=='UNDER_PROC'){
      this.f.CurrentStatus.setValue('UNDER PROCESSING');
    }else if(this.reqObj.cusReqStatus=='DOC_AWAT'){
      this.f.CurrentStatus.setValue('DOCUMENT AWAITED');
    }else if(this.reqObj.cusReqStatus=='DOC_RECVD'){
      this.f.CurrentStatus.setValue('DOCUMENT RECEIVED');
    }else if(this.reqObj.cusReqStatus=='SUB'){
      this.f.CurrentStatus.setValue('SUBMITTED');
    }



  //  CurrentStatus:[(this.reqObj.cusReqStatus=='APPR')?'Approved':(this.reqObj.cusReqStatus=='RJCT')?'Rejected':'In Progress'],
  }

  // <input *ngIf="reqObj.cusReqStatus=='UNDER_PROC'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="UNDER PROCESSING">
  //  <input *ngIf="reqObj.cusReqStatus=='DOC_AWAT'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="DOCUMENT AWAITED">
  //    <input *ngIf="reqObj.cusReqStatus=='DOC_RECVD'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="DOCUMENT RECEIVED">
                                


  get f() {
    return this.DisableNeatUserIdAdmin.controls;
  }
  back(){
    if(this.entityType=='ENIT'){
      this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage']);
    }
    else if(this.entityType!='ENIT'){
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    }
  }
  onSubmit() {
    this.spinner.show()
    let requestObject = {
			"memId":this.f.memCode.value,
      "cusRemarks": this.f.remark.value,
      "cusRefNo":this.reqObj.refNo,
      "cusStatus":this.f.UpdatedStatus.value,
      "memName":this.f.memberName.value,
      "cusDlrUsrId":this.reqObj.dlr_id     
    };
    this.EnitService.ApproveUserIdrequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
          if(this.f.UpdatedStatus.value=='APPR'){
            this.message = 'Request Approved Successfully';
            this.toastr.success(this.message);
            this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
          }else if(this.f.UpdatedStatus.value=='RJCT'){
            this.message = 'Request Rejected Successfully';
            this.toastr.success(this.message);
            this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
          }else if(this.f.UpdatedStatus.value=='UNDER_PROC'){
            this.message = 'Request  Under Process ';
            this.toastr.success(this.message);
            this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
          }else if(this.f.UpdatedStatus.value=='DOC_AWAT'){
            this.message = 'Request Is Waiting ';
            this.toastr.success(this.message);
            this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
          }
      } else {
        this.submitted = false;
        this.toastr.error(response.message);
      }
    })
  }
}
