import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {EnitComplianceServiceService} from 'src/app/service/enit-compliance-service.service'
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { threadId } from 'worker_threads';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
declare var $ : any;

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {

  addNewEmailTemplate: FormGroup;
  editEmailTemplateForm: FormGroup;
  config: any = config;
  popUpNew = false
  popUpExisting = false;
  selectForFunctionalityList = true;
  existingFunction: String = '';
  existingTemplate: String = '';
  name = 'Angular';
  existingFunctionList :any =[]
  newFunctionList :any[]
  tokenList :any[]
  text: string = '';
  deleteReqData: any = [];
  deleteRow: any;
  message: any;
  dtOptions:any
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('textContent', {static:false}) textContext: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(
    private emailTemplateService : EnitComplianceServiceService,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cdref: ChangeDetectorRef
   ) { }

   get femailTemplate() {return this.addNewEmailTemplate.controls;}
   get editTemplate() {return this.editEmailTemplateForm.controls;}
 

  ngOnInit(): void {
  this.getFunctionality();

  this.addNewEmailTemplate=this.formbuilder.group({
    addNewTemplate:['',Validators.required],
    addNewFunction:['',Validators.required]
  })

 this.editEmailTemplateForm=this.formbuilder.group({
  existingFunctionFormControlName:['',Validators.required],
  existingTemplateFormControlName:['',Validators.required]
 })

  }
  setDatatable(){
    $("#existingFunction").DataTable().destroy();
    setTimeout(()=> {
      $("#existingFunction").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: 'Blrtip',
        pageLength: 2,
        buttons:
          [{
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1],
            }
          }, {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1],
            }
          }, {
            extend: 'pdf',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1],
            }
          }]
      })
      this.cdref.detectChanges();
    }, 1)
  }

  popUpForAddingNewTemplate(): void{

    this.popUpNew =true;
    this.selectForFunctionalityList=true;
    this.getTokenList();

  }

  popUpForExistingTemplate(fName:String,fTemplate:String): void{
    this.popUpExisting=true;
    this.selectForFunctionalityList=false;
    this.getTokenList();
    this.existingTemplate=fTemplate;
    this.editTemplate.existingTemplateFormControlName.setValue(this.existingTemplate);
    this.existingFunction=fName;
    this.editTemplate.existingFunctionFormControlName.setValue(this.existingFunction);
  }

  editEmailTemplate(){
    let requestObject ={
      "functName": this.editTemplate.existingFunctionFormControlName.value,
      "emailTemplate": this.editTemplate.existingTemplateFormControlName.value
    }

    this.emailTemplateService.editEmailTeamplate(requestObject).pipe(
    catchError((error: any)=> {
      this.toastr.error(
      this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
      this.toastr.success("Email id Updated Successfully");
      this.getFunctionality();
      this.existingTemplate=null;
      }
      else
      this.toastr.error(response.message);
    })
  }
  

  submit(){
    const textarea = (<HTMLInputElement> document.getElementById('emailTemplate'));
  }

  save() {
    const content = this.textContext.nativeElement.innerHTML;
  }

 
   viewList(){
    let requestObject ={
      //"emailid": this.femail.emailId.value
      
    }
    this.emailTemplateService.viewEmailList(requestObject).pipe(
    catchError((error: any)=> {
      this.toastr.error(
      this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.toastr.success("Email id Submitted Successfully");
      }
      else
      this.toastr.error(response.message);
    })
  }

  getFunctionality(){

    this.emailTemplateService.getFunctionality().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      //$("#existingFunction").DataTable().destroy();
      this.existingFunctionList = response.data;
      this.setDatatable();
      this.getNewFunctionality();
      // this. dtTrigger.next(true);
    })
  }

  
  

  getNewFunctionality(){

    this.emailTemplateService.getNewFunctionality().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      this.newFunctionList = response.data;
    })
  }

  
  getTokenList(){
    this.emailTemplateService.getTokenList().pipe(
      catchError((error :any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.tokenList = [];
        if(error.status!=200){
          return throwError(error.error);
        }
      }
      )).subscribe((response:any) =>{
        if(response.statusCode =200){
          this.tokenList = response.data;

       } else {

        }
      })
  }


  saveEmail(){
    let requestObject=
    {
      "emailTemplate" : this.femailTemplate.addNewTemplate.value,
      "functName" : this.femailTemplate.addNewFunction.value
    }
    this.emailTemplateService.saveEmailTemplate(requestObject).pipe(
      catchError((error: any)=> {
        this.toastr.error(
        this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
      ).subscribe((response: any)=>{
        if(response.statusCode == 200){
          this.toastr.success("Email id Submitted Successfully");
          this.getFunctionality();
          this.femailTemplate.addNewTemplate.setValue(null);
          this.femailTemplate.addNewFunction.setValue("");
        }
        else
        this.toastr.error(response.message);
      })
    }

    deleteEmailTemplate(functionName: String) {
      this.deleteRow = functionName;
    }

    deleteEmailId() {
      this.spinner.show();
      let requestObject = {
        functName: this.deleteRow
      }
     this.emailTemplateService.deleteEmailTemplate(requestObject).pipe(
        catchError((error: any) => {
         this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
         if (error.status != 200) {
           return throwError(error.error);
          }
        })
      ).subscribe(
        (response: any) => {
          if (response.statusCode == 200) {
            this.message = "EmailTemaplate deleted !";
            this.toastr.success(this.message);
            this.getFunctionality();
         }
         else {
            this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
          }
        }
  
      )
    }

}
