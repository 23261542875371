<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <!-- <h1>{{action=='add'?'Add Neat User':'Process New Neat Request'}}</h1> -->
                <h1>Process New Neat Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eTrade/eUseridRequest/displayNonNeatReport/NonNeatRequestProcessPage"
                            aria-current="page">Process New Neat Request</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div>
        <form [formGroup]="nonNeatReq">
            <div class="card card-body col-lg-12 mt-3 colorborder">
                <!-- <h4>Process NON NEAT Requests</h4> -->
                
                <div class="row">
                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Member Code &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="memCode">
                    </div>

                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Member Name &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="memName">
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Reference No &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="refNo">
                    </div>

                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Product Type &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="productType">
                    </div>

                </div> 
                <div class="row">
                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Version &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="version">
                    </div>
                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">TAP IP &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                        
                        
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="tapIp">
                    </div>
                </div> 

                <div class="row">
                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Actual Pro Details &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <span readonly class="form-control">
                            Pro enabled : {{(f.nud_PRO_ENABLED.value == 'N')? 'No' : 'Yes'}}
                            <!-- <input type="text"  readonly formControlName="nud_PRO_ENABLED"> -->
                        </span>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-lg-2 form-group"></div>
                    <div class="col-lg-3 form-group">
                        <span readonly class="form-control">
                            Pro Undertaking : {{proUndertaking}}
                        </span>
                        <!-- <input type="text" class="form-control" readonly formControlName="nud_PRO_UNDERTAKING"> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 form-group">
                        <label style="font-size: 14px;">Current Status &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" readonly formControlName="currentStatus">
                    </div>

                    <!-- <div class="col-lg-2 form-group" *ngIf="f.currentStatus.value=='Under Processing' && entityType!='ENIT'">
                        <label style="font-size: 14px;">Status &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                    </div>
                    <div class="col-lg-3 form-group" *ngIf="f.currentStatus.value=='Under Processing' && entityType!='ENIT'">
                      <input type="text" class="form-control" readonly formControlName="rejReason"> 
                        <select required class="form-control" formControlName="updateStatus" (change)="showDiv()"  title="Select Status">
                            <option value="APPROVED">APPROVED</option>
                            <option value="REJECTED">REJECTED</option>
                        </select>
                    </div> -->

                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="row">
                            <div class="col-lg-6">
                                <button type="button" class="btn btn-cancel full-width"  (click)="back()">Back</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
           

        </form>
    </div>
</div>