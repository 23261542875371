<div class="container-fluid mt-3 mb-3 dashboard">
    <h1 hidden>Dashboard</h1>
    <!-- <div class="row " *ngIf="userDetails && userDetails.entityType && userDetails.entityType=='CM' && (tradeInquiryMenu.isAccessAllowed == 'Y' || giveUpMenu.isAccessAllowed == 'Y')">
        <div class="col-lg-4 pr-2">
            <div class="card card-body">
                <div class="d-flex center-height">
                    <div class="blocknum blue">{{approvedTrades.count + rejectedTrades.count + pendingTrades.count || 0}}</div>
                    <div class="ml-3">
                        <h2>All Trades</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 pr-2 pl-2">
            <div class="card card-body">
                <div class="d-flex center-height">
                    <div class="blocknum green">{{approvedTrades.count || 0}}</div>
                    <div class="ml-3">
                        <h2>Confirmed Trades</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 pl-2">
            <div class="card card-body">
                <div class="d-flex center-height">
                    <div class="blocknum red">{{rejectedTrades.count || 0}}</div>
                    <div class="ml-3">
                        <h2>Rejected Trades</h2>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="row mt-3" *ngIf="userDetails && userDetails.entityType && userDetails.entityType=='CM'">
        <div class="col-lg-6 pr-2"
            *ngIf="userDetails && userDetails.entityType && userDetails.entityType=='CM' && tmLimitMenu.isAccessAllowed == 'Y'">
            <div class="card card-body">
                <h3>TM Limit Set</h3>
                <div class="hidesearchbox hidentries hidepagination">
                    <table id="tmLimitSetup" class="table common-table nowrap" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th class="pre-wrap">TM participant Id</th>
                                <th class="pre-wrap">Name</th>
                                <th class="pre-wrap">set limit (In US $)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of tmLimitData">
                                <td>{{data.tmCode}}</td>
                                <td class="textwrap">{{data.companyName}}</td>
                                <td>{{data.limit | currency:'USD': 'symbol-narrow'}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row justify-content-end data-table-viewmore  align-items-center">
                        <div class="mr-3 mb-0 view-more">
                            <a class="view-more" routerLink="/trade/tmlimitset">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 pl-2"
            *ngIf="userDetails && userDetails.entityType && userDetails.entityType=='CM' && tmEnableMenu.isAccessAllowed == 'Y'">
            <div class="card card-body">
                <h3>TM Enablement</h3>
                <div class=" hidesearchbox hidentries hidepagination">
                    <table id="tmEnable" class=" table common-table nowrap" datatable [dtOptions]="dtOptions1"
                        [dtTrigger]="dtTrigger1">
                        <thead>
                            <tr>
                                <th class="pre-wrap"> TM Id</th>
                                <th class="pre-wrap"> Name</th>
                                <th class="pre-wrap"> Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of tmEnableData">
                                <td>{{data.prtyPrtyId}}</td>
                                <td>{{data.prtyName}}</td>
                                <td> <span [ngClass]="{'green':data.prtyStatus==1,'red':data.prtyStatus==0}"
                                        class="mfont">{{data.prtyStatus==0?"Disabled":"Enabled"}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row justify-content-end data-table-viewmore  align-items-center">
                        <div class="mr-3 mb-0 view-more">
                            <a class="view-more" routerLink="/trade/tmenable">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="row mt-3">
        <div class="col-lg-6 pr-2"
            *ngIf="userDetails && userDetails.entityType && (userDetails.entityType=='CM' || userDetails.entityType=='TM') && marginMenu.isAccessAllowed == 'Y'">
            <div class="card card-body">
                <h3>Margin Summary</h3>
                <div class="d-flex height-center justify-content-start align-items-center">
                    <div class="pl-0 mb-0 last-update" *ngIf="marginSummary && marginSummary.timestamp">
                        last updated :{{marginSummary && marginSummary.timestamp|date:'medium'}}
                    </div>
                    <div class="pl-2 mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16" (click)="getMarginSummary()">
                            <path
                                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path fill-rule="evenodd"
                                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                        </svg>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table common-table">
                        <thead>
                            <tr>
                                <th class="pre-wrap"> Effective Collateral</th>
                                <th class="pre-wrap text-right">{{marginSummary.effectiveColl|
                                    currency:'USD':'symbol-narrow'}}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th class="pre-wrap"> Amount</th>-->
    <!-- <th class="pre-wrap" colspan="2">Utilization %</th> -->
    <!--</tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Initial margin + Buy Premium + ICMTM + Consolidated Crystallized Obligation </td>
                                <td> {{marginSummary
                                    &&(marginSummary.initialMargins+marginSummary.buyPremium+marginSummary.icmtm+
                                    marginSummary.cOBG)| currency:'USD':'symbol-narrow'}}
                                </td>-->
    <!-- <td>
                                    <div class="badgenum green">{{marginSummary.initialMarginUtil}} </div>
                                </td> -->
    <!--</tr>
                            <tr>
                                <td>Extreme Loss Margin</td>
                                <td>{{marginSummary.exposureMargins| currency:'USD':'symbol-narrow'}}</td>-->
    <!-- <td>
                                    <div class="badgenum red">{{marginSummary.exposureMarginUtil}}</div>
                                </td> -->
    <!--</tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-6 min-height-chart pl-2 hidden">
            <div class="card card-body h-100">
                <h3>Trading Status</h3>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="chartjs-container">
                            <canvas baseChart [colors]="colors" width="100%" height="100%" [data]="doughnutChartData"
                                [labels]="doughnutChartLabels" [legend]="doughnutChartLegend"
                                [chartType]="doughnutChartType">
                            </canvas>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="table-center">
                            <div class="table table-responsive">
                                <table class=" table common-table nowrap">
                                    <tbody>
                                        <tr>
                                            <td>Approve </td>
                                            <td> 4041.00 </td>
                                            <td>
                                                <div class="badgenum blue">100 </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Pending</td>
                                            <td>1088.00</td>
                                            <td>
                                                <div class="badgenum red">0.15</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reject</td>
                                            <td>1088.00</td>
                                            <td>
                                                <div class="badgenum yellow">0.8</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!--<div class="row mt-3 hidden">
        <div class="col-lg-12 pr-2">
            <div class="card card-body">
                <h3>EPI</h3>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="bar-chart-wrapper">
                            <canvas baseChart [datasets]="barChartDataepi" [colors]="barChartColorsepi"
                                [labels]="barChartLabelsepi" [options]="barChartOptionsepi" [legend]="barChartLegendepi"
                                [chartType]="barChartTypeepi"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    <!-- <div class="col-lg-6 pl-2">
            <div class="card card-body">
                <h3>CIM Activity</h3>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="chartjs-container">
                            <canvas height="100" baseChart [datasets]="barChartData" [colors]="barChartColors"
                                [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
                                [chartType]="barChartType"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>-->



    <div class="card-container mt ">

        <div class="col-lg-12">
            <div class="row justify-content-left">

                <div class="col-lg-2" *ngFor="let menu of menuListFinal">
                    <div class="card  card-container mt card shadow1">
                        <img src='{{"assets/images/New_images/"+menu.menuCode+".png"}}' alt="Avatar" class="image ">
                        <div class="card-body">
                            
                                <a *ngIf="menu?.sappType=='I'" role="button" [routerLink]="menu.menuCode">
                                    <h2 class="card-title">{{menu.menuName}}</h2> </a>
                                <a *ngIf="menu?.sappType=='E'" role="button" (click)="accessApplication(menu)" href="#">
                                    <h2 class="card-title">{{menu.menuName}}</h2> </a>
                            

                        </div>
                        <div class="overlay" *ngIf="menu?.userMenusImpls?.length> 0">
                            <ul>
                                <li class="dashFont" *ngFor="let sublist of menu.userMenusImpls; let i = index">
                                    <a *ngIf="sublist?.sappType=='I'"
                                        [routerLink]="'/'+menu.menuCode+'/'+sublist.menuCode">{{sublist.menuName}} </a>
                                    <a *ngIf="sublist?.sappType=='E'" (click)="accessApplication(sublist)" href="#"
                                        class="dashboard-nav-child">{{sublist.menuName}} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-2">
                    <div class="card  card-container mt card shadow1">
                        <img src="../../../assets/images/New_images/ENIT.png" alt="Avatar" class="image ">
                        <div class="card-body">
                            <h2 class="card-title">ENIT</h2>

                        </div>
                        <div class="overlay">
                            <ul>
                                <li class="dashFont"> <a routerLink="#">Trade</a> </li>
                                <li class="dashFont"><a routerLink="#">Compliance</a> </li>
                                <li class="dashFont"><a routerLink="#">System Audit</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="card  card-container mt card shadow1">
                        <img src="../../../assets/images/New_images/Extranet.png" alt="Avatar" class="image">
                        <div class="card-body">
                            <h2 class="card-title">Extranet</h2>
                        </div>
                        <div class="overlay">
                            <ul>
                                <li class="dashFont"> <a routerLink="#">Member</a> </li>
                                <li class="dashFont"><a routerLink="#">Common</a> </li>
                                <li class="dashFont"><a routerLink="#">Circular</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="card  card-container mt card shadow1">
                        <img src="../../../assets/images/New_images/Inspection.png" alt="Avatar" class="image">
                        <div class="card-body">
                            <h2 class="card-title">INSPECTION</h2>

                        </div>
                        <div class="overlay">
                            <ul>
                                <li class="dashFont"> <a routerLink="#">Inspection</a> </li>
                                <li class="dashFont"><a routerLink="#">Inspection - Internal Audit</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="card card-container mt card shadow1">
                        <img src="../../../assets/images/New_images/UCI.png" alt="Avatar" class="image">
                        <div class="card-body">
                            <h2 class="card-title">UCI</h2>

                        </div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>

    <!-- <div class="card-container mt ">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-3 form-group">
                <div class="card  card-container mt card shadow">
                    <img src="../../../assets/images/New_images/ENIT.png" alt="Avatar" class="image ">
                    <div class="card-body">
                        <h5 class="card-title">ENIT</h5>

                    </div>
                    <div class="overlay">

                        <ul>
                            <li class="dashFont"> <a routerLink="#">Enit Trade</a> </li>
                            <li class="dashFont"><a routerLink="#">Enit Compliance</a> </li>
                            <li class="dashFont"><a routerLink="#">Enit Survay</a> </li>
                        </ul>


                    </div>
                </div>
            </div>
            <div class="col-lg-3 form-group">
                <div class="card  card-container mt card shadow">
                    <img src="../../../assets/images/New_images/Extranet.png" alt="Avatar" class="image">
                    <div class="card-body">
                        <h5 class="card-title">UCI</h5>

                    </div>
                </div>
            </div>


            <div class="col-lg-3 form-group">
                <div class="card  card-container mt card shadow">
                    <img src="../../../assets/images/New_images/Inspection.png" alt="Avatar" class="image">
                    <div class="card-body">
                        <h5 class="card-title">INSPECTION</h5>

                    </div>
                    <div class="overlay">
                        <ul>
                            <li class="dashFont"> <a routerLink="#">Enit Trade</a> </li>
                            <li class="dashFont"><a routerLink="#">Enit Compliance</a> </li>
                            <li class="dashFont"> <a routerLink="#">Enit Survay</a> </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 form-group">

                <div class="card  card-container mt card shadow">
                    <img src="../../../assets/images/New_images/Listing_corp.png" alt="Avatar" class="image">
                    <div class="card-body">
                        <h5 class="card-title">Listing_corp</h5>

                </div>
            </div>




        </div>
    </div>
</div> -->

</div>