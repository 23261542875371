import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toast, ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {EnitComplianceServiceService} from 'src/app/service/enit-compliance-service.service'
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
import { DatePipe } from '@angular/common'
declare var $:any;

@Component({
  selector: 'app-ml-ai-modal',
  templateUrl: './ml-ai-modal.component.html',
  styleUrls: ['./ml-ai-modal.component.css']
})
export class MlAiModalComponent implements OnInit {
  requestForm : FormGroup;
  memCode: String;
  memId: any;
  config: any = config;
  user: any = {};
  access: any = {};
  memName:string;
  entityType:any;
  formIndex: number = 1;
  number: number = 1;
  list : any = [1];
  mlAiData: any;
  machineDetails: any;
  machineDetailsArray: []
  formArrayLength : number;
  memberData: any;

  constructor(
    private formBuilder : FormBuilder,
    private router : Router,
    private titleService : Title,
    private cdref : ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private mlAiService: EnitComplianceServiceService,
  ) { 
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.mlAiData = this.mlAiService.getMLAIByRef(params['id']);
      }
    });
  }

  get f(){
    return this.requestForm.controls;
  }

  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.entityType = this.user.entityType;
    this.titleService.setTitle("ML & AI Details");
    //this.getMemberDetails();
    // this.getMachineDetails();
    this.requestForm = this.formBuilder.group({
    registrationNumber:[],
    entityCategory:['Trading Member'],
    memberName:[],
    panNO:[],
    form : this.formBuilder.array([]),
    mlaisystemAuditReport:[this.mlAiData.mlaisystemAuditReport],
    mlaisystemAdverse: [this.mlAiData.mlaisystemAdverse],
  })
    $('.selectpicker').selectpicker();

    if(this.mlAiData.mlaiNillStatus == "1"){
      $('#nillSubmission').modal('show');
      this.getMemberDetails();

    }
    else if(this.mlAiData.mlaiNillStatus == "0"){
      $('#nillSubmission').modal('hide');
      this.getMachineDetails();
    }
  }

  toggle(j){
    var div = document.getElementById(j);
    $(div).toggle();
  }

  get form(){
    return this.requestForm.controls['form'] as FormArray
  }
  getMachineDetails(){
    let requestObject = {
      "mlaiID": null,
      "qcdMemId": {
          "memId": this.mlAiData.memId,
      },
      "mlaiRefNO": this.mlAiData.mlaiRefNO,
      "memCode": this.mlAiData.memSebiNoPart2,
    }

    this.mlAiService.getDataByRefId(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      ((response: any) => {
        if(response.statusCode == 200 ){
          this.f.panNO.setValue(response.data.panNO);
          this.f.registrationNumber.setValue(response.data.registrationNumber);
          this.f.memberName.setValue(response.data.memberName);
          this.machineDetailsArray = response.data.mlaiSystemDetails;
          this.formArrayLength = this.machineDetailsArray.length;
          const add =  this.requestForm.controls.form as FormArray;
          this.machineDetailsArray.forEach((machineDetails: any)=>
            {
              add.push(this.formBuilder.group({
                systemName: [machineDetails.systemName ],
                applicationDateStr: [machineDetails.applicationDateStr ],
                typeOfArea: [machineDetails.typeOfArea ],
                systemOrder: [machineDetails.systemOrder ],
                systemActivities: [machineDetails.systemActivities ],
                systemDisseiminate: [machineDetails.systemDisseiminate ],
                systemUsedArea: [machineDetails.systemUsedArea ],
                systemClaims: [machineDetails.systemClaims ],
                systemTools: [machineDetails.systemTools ],
                systemProject: [machineDetails.systemProject ],
                systemKeyControls: [machineDetails.systemKeyControls ],
                systemAudit: [machineDetails.systemAudit ],
                sysyemDescription: [machineDetails.sysyemDescription],
                systemSafeGuard: [machineDetails.systemSafeGuard ],
              }))
            }
          )

          this.f.form = add;
        }
      })
    )
  }

  getMemberDetails(){
    this.mlAiService.getMemberDetails(this.memCode, this.memId).pipe(
      catchError((error: any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.memberData = response.data;
        this.f.registrationNumber.setValue(this.memberData.memSebiRegNo);
        this.f.memberName.setValue(this.memberData.memNm);
        this.f.panNO.setValue(this.memberData.memItPanNo);
      }
    })
  }
  

  back(){
    if(this.entityType == 'ENIT'){
      this.router.navigate(['eCompliance/mlAndAi/mlaireport'])
      $('#nillSubmission').modal('hide');
    }
    else if(this.entityType =='ADMIN'){
      this.router.navigate(['complianceAdmin/mlAndAiAdmin/mlaireportAdmin'])
      $('#nillSubmission').modal('hide');
    }
  }
}
