<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Request For Neat User Validity Updation</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="/eTrade/eUseridRequest/displayNeatUsrValidityUpdtRequest"
                            aria-current="page">Request For Neat User Validity Updation</a></li>

                </ul>
            </div>

        </div>
    </div>
    <div>

        <!-- <form [formGroup]="userValidityUpdation" (ngSubmit)="onSubmit()"> -->
        <form [formGroup]="validityUpdation" >
          

                <div class="card card-body col-lg-12 mt-3 ">
                    <!-- <h6>
                        REQUEST FOR NEAT USER VALIDITY UPDATION
                    </h6> -->
                    <div class="row">
                        <div class="col-lg-3 form-group">

                            <label>SEBI Registration Code</label>
                            <input type="text" class="form-control" readonly value="" formControlName="SebiRegiCode">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>User Id</label>
                            <select class="form-control selectpicker" formControlName="cusDlrId" data-style="btn-info"
                                title="Select User Id" (change)="selectDlrId()" data-live-search="true">
                                <option *ngFor="let data of cusDlrId" value="{{data}}">{{data}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Name</label>
                            <input type="text" value="" class="form-control" formControlName="UserName" disabled>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>TAP/Child IP <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="ChildTapIpType" disabled>

                            <p *ngIf="f.ChildTapIpType.invalid && f.ChildTapIpType.touched" class="error nocase">
                                 TAP/Child IP Required
                            </p>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>TAP ID</label>
                            <input type="text" value="" class="form-control" formControlName="TapId" disabled>

                            <p *ngIf="f.TapId.invalid && f.TapId.touched" class="error nocase">
                             TAP ID Required
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card card-body col-lg-12 mt-3 ">
                    <h6>
                        Existing Certification Details
                    </h6>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Certified By</label>
                            <input type="text" class="form-control" readonly minlength="12" maxlength="12" value=""
                                formControlName="CertifiedBy">
                            <div *ngIf="f.CertifiedBy.invalid && f.CertifiedBy.touched">
                                <p *ngIf="f.CertifiedBy.errors.pattern" class="error nocase">
                                    Please enter 12 digit of SEBI Registration Code
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Registration No</label>
                            <input type="text" class="form-control" readonly minlength="12" maxlength="12" value=""
                                formControlName="RegistrationNo">

                            <!-- <div *ngIf="f.SebiRegType.invalid && f.SebiRegType.touched">
                                <p *ngIf="f.SebiRegType.errors.pattern" class="error nocase">
                                    Please enter 12 digit of SEBI Registration Code
                                </p>
                            </div> -->

                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Validity Date</label>
                            <input type="text" class="form-control" readonly minlength="12" maxlength="12" value=""
                                formControlName="ValidityDate">

                            <!-- <div *ngIf="f.SebiRegType.invalid && f.SebiRegType.touched">
                                <p *ngIf="f.SebiRegType.errors.pattern" class="error nocase">
                                    Please enter 12 digit of SEBI Registration Code
                                </p>
                            </div> -->
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Percentage</label>
                            <input type="text" class="form-control" formControlName="Percentage" readonly>
                        </div>

                    </div>
                </div>
                <div class="card card-body col-lg-12 mt-3">
                    <h6>DETAILS OF THE PROPOSED USER</h6>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>certificate Details <span class="red">*</span></label>
                            <select class="form-control " formControlName="certificationType"
                                title="Select certificate Details" data-style="btn-info">
                                <option selected disabled value=""> Select Title</option>
                                <option selected value="1"> NISM</option>
                                <option selected value="2"> NISM CPE</option>
                            </select>
                            <p *ngIf="f.certificationType.invalid && f.certificationType.touched" class="error nocase">
                             Certificate Details Required
                            </p>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Exam Type <span class="red">*</span></label>
                            <select class="form-control " formControlName="ExamType" title="Select Exam Type"
                                data-style="btn-info">
                                <option selected disabled value=""> Select Title</option>
                                <option selected value="1">Series VIII. Equity derivatives</option>
                                <option selected value="2">Series XIII. Common Derivatives</option>
                            </select>
                            <p *ngIf="f.ExamType.invalid && f.ExamType.touched" class="error nocase">
                             Exam Type Required
                            </p>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Validity Date <span class="red">*</span></label>
                            <!-- <input type="date" class="form-control" formControlName="ValidateDateType"> -->
                            <div class="input-group">
                                <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue1"
                                    value="{{bsValue1 | date:'dd/MM/yyyy'}}" formControlName="ValidateDateType" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="Select Validity Date">
                                <div class="input-group-append pointer">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar" [(bsValue)]="bsValue1" bsDatepicker
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div>
                            <p *ngIf="f.ValidateDateType.invalid && f.ValidateDateType.touched" class="error nocase">
                             Validity Date Required
                            </p>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Registration No <span class="red">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Registration Number"
                                formControlName="registratioNumber">



                            <div *ngIf="f.registratioNumber.invalid && f.registratioNumber.touched"
                                class="error nocase">

                                <div *ngIf="f.registratioNumber.errors.required">required Registration  Number</div>
                                <div
                                    *ngIf=" f.registratioNumber.errors.maxlength || f.registratioNumber.errors.minlength">
                                    Registration Number Required Of 12 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Title <span class="red">*</span></label>
                            <select class="form-control " formControlName="TitleType" title="Select Title"
                                data-style="btn-info">
                                <!-- <option selected disabled value=""> Select Title</option> -->
                                <option selected value="1">MS</option>
                                <option selected value="2">MR</option>
                                <option selected value="2">DR</option>
                            </select>

                            <p *ngIf="f.TitleType.invalid && f.TitleType.touched" class="error nocase">
                                 Title Required
                            </p>
                        </div>
                        <div class="col-lg-9 form-group">

                            <label>Enter Your Name <span class="red">*</span></label>

                            <div class="input-group form-group">
                                <input type="text" formControlName="firstNameType" placeholder="first name"
                                    class="form-control"><br>
                                <input type="text" formControlName="middleNameType" placeholder="middle name"
                                    class="form-control">
                                <input type="text" formControlName="lastNameType" placeholder="last name"
                                    class="form-control">
                            </div>
                            <p *ngIf="f.firstNameType.invalid && f.firstNameType.touched" class="error nocase">
                                First Name Required
                               </p>
                               <p *ngIf="f.middleNameType.invalid && f.middleNameType.touched" class="error nocase">
                                Middle Name Required
                               </p>
                               <p *ngIf="f.lastNameType.invalid && f.lastNameType.touched" class="error nocase">
                                Last Name Required
                                </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Father's/Husband's Name <span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="FatherNameType">
                            <p *ngIf="f.FatherNameType.invalid && f.FatherNameType.touched" class="error nocase">
                                 Father's/Husband's Name Required
                            </p>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Date Of Birth <span class="red">*</span></label>
                            <!-- <input type="date" class="form-control" formControlName="BODType"> -->
                            <div class="input-group">
                                <input type="text" class="form-control datepicker" [maxDate]="maxDate" [(bsValue)]="bsValue"
                                    value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="BODType" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="Select Date Of Birth">
                                <div class="input-group-append pointer">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar" [maxDate]="maxDate" [(bsValue)]="bsValue" bsDatepicker
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div>

                            <p *ngIf="f.BODType.invalid && f.BODType.touched" class="error nocase">
                             Date Of Birth Required
                            </p>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>PAN Number <span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="PanType">

                            <DIV *ngIf="f.PanType.invalid && f.PanType.touched" class="error nocase">

                                <div *ngIf="f.PanType.errors.required">PAN Number Required</div>
                                <P *ngIf="f.PanType.errors.pattern" class="error nocase">Enter Correct PAN number</P>
                            </DIV>
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Mobile Number <span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="MobileNoType">
<!-- 
                            <div *ngIf="f.MobileNoType.invalid && f.MobileNoType.touched" class="error nocase">

                                <div *ngIf="f.MobileNoType.errors.required">required Mobile Number</div>
                                <div
                                    *ngIf="f.MobileNoType.errors.pattern || f.MobileNoType.errors.maxlength || f.MobileNoType.errors.minlength">
                                   Mobile Number Should Be 10 Digit</div>
                            </div> -->

                            <div *ngIf="f.MobileNoType.invalid && f.MobileNoType.touched" class="error nocase">

                                <div *ngIf="f.MobileNoType.errors.required">Required Mobile Number</div>
                                <div
                                    *ngIf="f.MobileNoType.errors.maxlength || f.MobileNoType.errors.minlength">
                                    Mobile Number Should Be 10 Digit
                                </div>
                                <div
                                    *ngIf="f.MobileNoType.errors.pattern" >
                                    Mobile Number Should Contain Numbers Only
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Qualification <span class="red">*</span></label>
                            <select class="form-control " formControlName="QualificationType"
                                title="Select Qualificaton" data-style="btn-info">
                                <!-- <option selected disabled value=""> Select Title</option> -->
                                <option selected value="1">SSC</option>
                                <option selected value="2">High School</option>
                                <option selected value="2">Diploma</option>

                            </select>
                            <p *ngIf="f.QualificationType.invalid && f.QualificationType.touched" class="error nocase">
                                 Qualification Required
                            </p>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Percentage <span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="PercentageType" max="100">

                            <p *ngIf="f.PercentageType.invalid && f.PercentageType.touched" class="error nocase">
                                 Percentage Required
                            </p>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Email ID <span class="red">*</span></label>
                            <input type="email" class="form-control" formControlName="EmailType">
                            <div *ngIf="f.EmailType.invalid && f.EmailType.touched">
                                <p *ngIf="f.EmailType.errors.required" class="error nocase">
                                    Email ID Required
                                </p>
                                <p *ngIf="f.EmailType.errors.email" class="error nocase">
                                    Please enter valid email ID
                                </p>
                            </div>

                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Upload Certificate <span class="red">*</span></label>
                            <input class="form-control" type="file" (change)="onFileSelected($event)"
                                formControlName="UploadCertificate" placeholder="Select A File">
                            <!-- base64 Output : <br />
                                     console.log( {{base64Output}}   ) -->

                            <!-- {{base64Output}}                          -->
                        </div>

                        <div *ngIf="fileName" class="col-lg-4">
                            <label>Attached file</label>
                            <p>{{fileName}}
                                <span class="ml-2">
                                    <svg (click)="removeFile()" xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path
                                            d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                    </svg>
                                </span>
                            </p>
                        </div>
                    </div>

                </div>

                <div class="card card-body col-lg-12 mt-3">
                    <div class=" form-group">
                        <p> <input type="checkbox" formControlName="CheckType">
                            I/We declare to abide by the rules/regulations as communicated by the Exchange/SEBI/other
                            Regulators from time to time.
                        </p>
                    </div>
                    <div class="row justify-content-start mb-5">
                        <div class="col-lg-8">
                            <div class="row justify-content-start">
                                <div class="col-lg-2">
                                    <button class="btn btn-primary full-width" 
                                        type="submit" (click)="onSubmit()">Submit</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn btn-primary full-width" (click)="reset()">Reset</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn btn-cancel full-width" (click)="back()">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</div>