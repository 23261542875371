import { Component, OnInit } from '@angular/core';
import  jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-pdf-downloader-component',
  templateUrl: './pdf-downloader-component.component.html',
  styleUrls: ['./pdf-downloader-component.component.css'],
})
export class PdfDownloaderComponentComponent implements OnInit {


  constructor(
   
  ) {}

  ngOnInit(): void {}

  // title = 'html-to-pdf-angular-application';
  // public convetToPDF() {
  //   var data = document.getElementById('contentToConvert');
  //   html2canvas(data).then((canvas) => {
  //     // Few necessary setting options
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL('image/png');
  //     let pdf = this.jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  //     var position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //     pdf.save('new-file.pdf'); // Generated PDF
  //   });
  // }

  title = 'html-to-pdf-angular-application';
  public cnvrtToPDF(elementID : string) {
    var data = document.getElementById(elementID);
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 290;
      var pageHeight = 100;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      console.log( canvas.height+","+canvas.width);
      var heightLeft =imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
      var position = 50;
      pdf.addImage(contentDataURL, 'PNG',5, position, imgWidth, heightLeft);
      pdf.save('new-file.pdf'); // Generated PDF

      console.log("PDF IN COMVERTION........");
    });
  }

  
  public convertToPDF(elementID : string) {
    var data = document.getElementById(elementID);
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      //var imgWidth = canvas.width - 5000;
      var imgWidth = 625
      //var pageHeight = 100;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      console.log( canvas.height+","+canvas.width);
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'px', 'a4'); // A4 size page of PDF
      var pageHeight = pdf.internal.pageSize.getHeight();
      
      var pageWidth = pdf.internal.pageSize.getWidth();
      

      // var pageHeight = imgHeight + 5;
      // var pageWidth = imgWidth + 5;

      //var position = 50;

      var canvasHeight = canvas.height;
      //var canvasHeight = pageHeight;
      var totalPages = Math.ceil(canvasHeight / imgHeight);

      for (let i = 0; i < totalPages; i++) {
        const canvasOffset = -(pageHeight * i);

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(contentDataURL, 'PNG',5, canvasOffset, imgWidth, imgHeight, undefined, 'NONE');
        
      }

      pdf.save('new-file.pdf'); // Generated PDF

      console.log("PDF IN COMVERTION........");
    });
  }

  generatePDF(elementID : string){
    // Define the height and width of the PDF pages
    var pdfWidth = 210;
    var pdfHeight = 297;

    // Define the div that contains the form
    var formContainer = document.getElementById(elementID);

    // Get the total height of the form
    var formHeight = formContainer.offsetHeight;

    // Calculate the number of pages needed to print the form
    var pages = Math.ceil(formHeight / pdfHeight);

    var pdf = new jspdf({
      orientation : 'portrait',
      unit : 'mm',
      format : [pdfWidth, pdfHeight]
    });

    // Loop through each page and add it to the PDF
    for (let i = 0; i < pages; i++) {
      // Set the page height and y-coordinate offset
      var pageHeight = i === pages - 1 ? formHeight - (pdfHeight * i) : pdfHeight;
      var yOffset = i * pdfHeight;

      // Use html2canvas to render the form as an image
      html2canvas(formContainer,{
        windowHeight : pdfHeight,
        y : yOffset,
        allowTaint : true
      }).then(function(canvas){
        // Add the image of the form to the PDF
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdfWidth, pageHeight);
        // Add a new page if necessary
        if (i !== pages - 1) {
          pdf.addPage();
        }

        
      })
    }

    // Save the PDF
    pdf.save('form.pdf');

  }


  public convertToPDF2(elementID : string) {
    var data = document.getElementById(elementID);
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
      var pageHeight = pdf.internal.pageSize.getHeight();
      var pageWidth = pdf.internal.pageSize.getWidth();

      var dpi = window.devicePixelRatio || 1;

      var canvasHeight = canvas.height;

      var heightInMm = (canvasHeight / dpi) * 25.4;

      var totalPages = Math.ceil(heightInMm / pageHeight);

      for (let i = 0; i < totalPages; i++) {
        const canvasOffset = -(pageHeight * i);

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(contentDataURL, 'PNG',0, canvasOffset, pageWidth, pageHeight, undefined, 'NONE');
        
      }

      pdf.save('new-file.pdf'); // Generated PDF

      console.log("PDF IN COMVERTION........");
    });
  }
}
