<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Password Reset/Unlock Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/ePasswordResetUserId/displayAdminPasswordMis" aria-current="page">Password Reset/Unlock Request Admin </a></li>
                    <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUnlockuser/displayPasswordMis" aria-current="page">Password Reset/Unlock Request</a></li>
                </ul>
            </div>
        </div>
    </div>    
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please wait.</p>
    </ngx-spinner>  
    <div class="card card-body colorborder">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Request Type</label>
                    <select class="form-control selectpicker" formControlName="requestType" data-style="btn-info" title="Select Request Type">
                        <option  value="RESET">Password Reset</option>
                        <option  value="UNLOCK">Unlocking</option>
                    </select>
                   
                </div>
                <div class="col-lg-3 form-group">
                    <label>Request From date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue"
                            value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="requestFDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" title="Select Date" [maxDate]="maxDate" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar"  [(bsValue)]="bsValue" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Request To date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue1" 
                            value="{{bsValue1 | date:'MM/dd/yyyy'}}" formControlName="requestTDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" title="Select Date" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar"  [(bsValue)]="bsValue1" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="f.requestTDate.invalid">
                        <p *ngIf="f.requestTDate.errors.required"  class="error nocase">
                            Please enter To Date
                        </p>
                    </div>
                </div>

              
                
                <div class="col-lg-3 form-group" *ngIf="entityType=='ENIT'">
                    <label>Request Status</label>
                    <select  class="form-control selectpicker" formControlName="requestStatus" data-style="btn-info" title="Select Request Status">
                        <option value="PENDING">Pending</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="REJECTED-USER STATUS IS ACTIVE">Rejected-User Status Is Active</option>
                        <option value="REJECTED-USER IS ALREADY UNLOCKED">Rejected-User Is Already Unlocked</option>
                        <option value="REJECTED-PASSWORD IS ALREADY DEFAULT">Rejected-Password Is Already default</option>
                        <option value="REJECTED">Rejected</option>
                    </select>
                </div>
                <div class="col-lg-3 form-group" *ngIf="entityType!='ENIT'">
                    <label>Request Status</label>
                    <select  class="form-control selectpicker" formControlName="requestStatus" data-style="btn-info" title="Select Request Status">
                        <option value="PENDING">Pending</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="REJECTED-USER STATUS IS ACTIVE">Rejected-User Status Is Active</option>
                        <option value="REJECTED-USER IS ALREADY UNLOCKED">Rejected-User Is Already Unlocked</option>
                        <!-- <option  value="REJECTED-PASSWORD IS ALREADY DEFAULT">Rejected-Password Is Already default</option> -->
                        <option value="REJECTED">Rejected</option>
                    </select>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-3 form-group" *ngIf="entityType!='ENIT'">
                    <label>Member Name</label>
                    <!-- <input type="text" class="form-control" formControlName="memName" readonly> -->
                    <select class="form-control selectpicker" formControlName="memName" data-style="btn-info" title="Select Member Name" (change)="onMemberChange()" data-live-search="true">
                        <option *ngFor="let data of memNameAndCodeList"  value="{{data.spDesc}}">{{data.spName}}</option>
                    </select>
                   
                </div>
                <div class="col-lg-3 form-group" *ngIf="entityType!='ENIT'">
                    <label>Member Code</label>
                    <select class="form-control selectpicker" formControlName="memCode" data-style="btn-info" title="Select Member Code" (change)="onCodeChange()" data-live-search="true">
                        <option *ngFor="let data of memNameAndCodeList"  value="{{data.spMemId}}">{{data.symbol}}</option>
                    </select>
                    <!-- <input type="text" class="form-control" formControlName="requestType" > -->
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <button class="btn btn-primary full-width" type="submit" [disabled]="searchForm.invalid" (click)="onSubmit()">Search</button>
                        </div>
                        <div class="col-lg-6">
                            <button class="btn btn-cancel full-width"  (click)="onReset()">Reset</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </form>
        <div class="">
        <div class="row clearSearch " *ngIf="filter==true">
            <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <table id="MIS" class="table common-table nowrap" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th>Request Reference No.</th>
                    <th *ngIf="entityType!='ENIT'">Member Code</th>
                    <th *ngIf="entityType!='ENIT'">Member Name</th>
                    <th>User ID</th>
                    <th *ngIf="entityType=='ENIT'">Request Type</th>
                    <th *ngIf="entityType=='ENIT'">Mobile</th>
                    <th *ngIf="entityType=='ENIT'">Email</th>
                    <th>Request Date</th>
                    <th>Process date</th>
                    <th>Status</th>
                    <th>Remarks/Rejection Reason</th>
                    <th>Reason For Unlock</th>
                </tr>
                <tr class="column-search">
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th *ngIf="entityType!='ENIT'"><input type="text" class="form-control form-control-sm" /></th>
                    <th *ngIf="entityType!='ENIT'"><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th *ngIf="entityType=='ENIT'"><input type="text" class="form-control form-control-sm" /></th>
                    <th *ngIf="entityType=='ENIT'"><input type="text" class="form-control form-control-sm" /></th>
                    <th *ngIf="entityType=='ENIT'"><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
 
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of requestList">
                    <td>
                    <span *ngIf="entityType!='ENIT'" (click)="open(data.pcmReqRefNo)" class="underline pointer"><u>{{data.pcmReqRefNo}}</u></span>
                    <span *ngIf="entityType=='ENIT'" (click)="open(data.pcmReqRefNo)" class="underline pointer"><u>{{data.pcmReqRefNo}}</u></span>
                    </td>
                    <td *ngIf="entityType!='ENIT'">{{data.memCode}}</td>
                    <td *ngIf="entityType!='ENIT'">{{data.memName}}</td>
                    <td>{{data.pcmCorpMgrUsrId}}</td>
                    <td *ngIf="entityType=='ENIT'">{{(data.pcmAction=="R")?' RESET':'UNLOCK'}}</td>
                    <td *ngIf="entityType=='ENIT'">{{data.pcmMobileNo}}</td>
                    <td *ngIf="entityType=='ENIT'" class="nocase">{{data.pcmEmailId}}</td>
                    <td>{{data.pcmCreatedDate | date}}</td>
                    <td>{{data.pcmModifyDate | date}}</td>
                    <td>{{data.pcmStatus}}</td>
                    <td>{{data.pcmRemarks}}</td>
                    <td>{{data.pcmResonforUnlock?.refValue}}</td><!--needs to be checked-->
                </tr>
            </tbody>
        </table>
    </div>
</div>
</div>