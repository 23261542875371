export const incidentSymptoms: any = [
    {
      name: "System Crashes",
      value: "SYSTEM_CRASH"
    },
    {
      name: "New User Accounts \ Accounting discrepancies",
      value: "NEW_USER_ACCOUNTS"
    },
    {
      name: "Failed or Successfull Social Engineering attempt",
      value: "SOCIAL_ENGINEERING"
    },
    {
      name: "Unexplained, poor system performance",
      value: "POOR_SYSTEM_PERFORMANCE"
    },
    {
      name: "Unaccounted for Change in DNS tables, router rules, or firewall rule",
      value: "CHANGE_DNS_ROUTES_FIREWALL"
    },
    {
      name: "Unexplained elevation or use of privileges operation of program or sniffer device to capture network traffic",
      value: "ELEVATION"
    },
    {
      name: "An indicated last time of usage of a account that does not correspond to actual last time of usage of that user",
      value: "LAST_TIME_USAGE"
    },
    {
      name: "An System alarm or similar indication from an intrusion detection tool",
      value: "SYSTEM_ALARM"
    },
    {
      name: "Altered Home pages, which are usually the international target for visibility,or Other pages on the Web Server",
      value: "VISIBILITY"
    },
    {
      name: "Anomalies",
      value: "ANOMALIES"
    },
    {
      name: "Suspicious Probes",
      value: "SUSPICIOUS_PROBES"
    },
    {
      name: "Suspicious Browsing New files",
      value: "SUSPICIOUS_BROWSING"
    },
    {
      name: "Changes in file lengths or dates",
      value: "CHANGE_IN_FILE"
    },
    {
      name: "Attempts to write to system",
      value: "WRITESYS"
    },
    {
      name: "Data modification or deletion",
      value: "DATAMOD"
    },
    {
      name: "Denial of service",
      value: "DOS"
    },
    {
      name: "Door knob rattling",
      value: "DKR"
    },
    {
      name: "Unusual time of usage",
      value: "UNUSUALUSAGE"
    },
    {
      name: "Unusual usage patterns",
      value: "UNUSUALPATTERN"
    },
    {
      name: "Unusual log file entries",
      value: "UNUSUALLOG"
    },
    {
      name: "Presence of new setuid or setgid files Changes in system directories and files",
      value: "SETUGID"
    },
    {
      name: "Presence of cracking utilities",
      value: "CRAUTIL"
    },
    {
      name: "Activity during non-working hours or holidays",
      value: "NONWORK"
    },
    {
      name: "Other (Please specify)",
      value: "OTHER"
    },
  ];
