import { Component, OnInit } from '@angular/core';
import { config } from 'rxjs/internal/config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-cyber-security-memberreport-upload',
  templateUrl: './cyber-security-memberreport-upload.component.html',
  styleUrls: ['./cyber-security-memberreport-upload.component.css']
})
export class CyberSecurityMemberreportUploadComponent implements OnInit {
  config: any = config;
  fileUpload: FormGroup;
  fileName: string;
  file: File;
  user: any = {};
  access: any = {};
  menuCode: string = "memberReportUpload";
  userControlId: string = '';
   fileError: any;
   entityCode: any;
  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private titleService: Title,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private userControlService: UserControlService,
    private enitComplianceServiceService: EnitComplianceServiceService,
  ) { }

  get f() { return this.fileUpload.controls; }

  ngOnInit(): void {
   
    this.titleService.setTitle('Cyber-Security-Member-Report')
    this.fileUpload = this.fb.group({
     // fileType: ['', Validators.required],
      file: ['', Validators.required]
    })

    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menuCode) : this.userDetailsService.checkUserAccess(this.menuCode) || {};
    this.entityCode= this.user.entityCode;

  }

  onChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      console.log(this.file.size)
      if (this.file.type == 'application/pdf' || this.file.size <=1000000)  {
        this.fileError = false;
      }
      else {
        this.fileError = true;
      }
    }
  }

  removeFile() {
    this.fileUpload.reset();
    this.file = null;
  }


  onSubmit() {
    this.enitComplianceServiceService.uploadCyberSecurityReport(this.file,this.entityCode).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          this.removeFile();
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.removeFile();
        this.toastr.success("File Uploaded Successfully");
      }
      else {
        this.removeFile();
        this.toastr.error(response.message);
      }
    }
    )
  }

}
