import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common'
declare var $: any;


@Component({
  selector: 'app-etm-newneat-userid-request',
  templateUrl: './etm-newneat-userid-request.component.html',
  styleUrls: ['./etm-newneat-userid-request.component.css'],
})
export class EtmNewneatUseridRequestComponent implements OnInit {

  addNeatUser: FormGroup;
  submitted = false;
  user: any = {};
  memCode: String;
  refcertilist: any = [];
  refOffcList: any = [];

  QualificationType: any = [];
  menucode: string = 'displayNewNeatIdRequest';
  access: any = {};

  action: string = 'add';
  config: any = 'config';
  message: string;
  getDisplayNewNeatIdRequest: any;
  EnitDetails: any;
  sebiData: any = [];
  enitId: number = 0;
  signedFormId: number;
  fileData: string;
  date: Date = new Date();
  formDate: any;
  bsValue: any;
  bsValue1: any;
  maxDate: any //added for age more than 18 years
  entityType: any;
  memName: any;
  memId: any;
  module: any = "New Neat User ID";
  reqtype: number = 3;
  loginId: string;
  verifyOTP: boolean;
  titleList: any = [];
  qualificationList: any = [];
  cityList: any = [];
  cusTapIpList: any = [];
  base64Output: string;
  base64Output1: string;
  noOfTerminal: any;
  tapId: any;
  relationshipList: any = [];
  registrationNumber: string;
  registrationNumber1: string;
  registrationNumber2: string;
  bodString: any;
  validityString: any;
  fileError: boolean = false;
  fileError1: boolean = false;
  maxDateToday: any;   //set max date as of today 
  maxMonthToday: any;  // set max month 
  maxMonth: any; //set max month according to JS

   //for validity date
  validityDate: any;
  validityMonth: any;
  minDateValidity: any;

  constructor(
    private route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private enitTradeService: EnitTradeService,
    private enitService: EnitService,
    private toastr: ToastrService,
    private router: Router,
    private userDetailsService: UserDetailsService,
    private datepipe: DatePipe,
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['reqId']) {
        this.enitId = params['reqId'];
        // this.user = this.EnitService.getUserByMemCode(params['sebiRegType']);
      }
    });
  }
  get f() { return this.addNeatUser.controls; }

  newItemEvent(event: boolean) {
    this.verifyOTP = event;
    if (this.verifyOTP == true) {
      $('#cashdeposite').modal('hide');
    }
  }

 
 
  ngOnInit(): void {
    this.formDate = this.datepipe.transform(this.date, 'dd-MMM-yyyy');
    this.maxDateToday = this.datepipe.transform(this.date, 'dd');  // gives date
    this.maxMonthToday = this.datepipe.transform(this.date, 'MM'); // gives month but acc to normal calendar
    this.maxMonth = this.maxMonthToday - 1; // gives month acc to JS 

    const currentYear=new Date().getFullYear();
    //const currentYear = 2022 ==> used for validity date after december
    this.maxDate = new Date(currentYear-18, this.maxMonth , this.maxDateToday);

    this.validityDate = this.maxDateToday -1;
    if(this.maxMonthToday == 12){
      this.validityMonth = 0;
      this.minDateValidity = new Date(currentYear+1, this.validityMonth , this.validityDate);
    }
    else if (this.maxMonthToday != 12){
      this.validityMonth = this.maxMonthToday;
      this.minDateValidity = new Date(currentYear, this.validityMonth , this.validityDate);
    }
    $('.selectpicker').selectpicker();
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode - 10000) + "";
    this.entityType = this.user.entityType;
    this.loginId = this.user.loginId;
    this.memName = this.user.userName;

    

    this.addNeatUser = this.formbuilder.group({
      cusTapIp1: [null, Validators.required],
      sebiRegType: [''],
      RegistrationOfficeType: ['', Validators.maxLength(255)],
      TapIpType: [''],
      ExistingTapIPType: [''],
      // certificationType: ['', Validators.required],
      certificationType: [''],
      ExamType: [''],
      registraionNoType: [''],
      registraionNo: [], //added for hardcoded NISM
      // ValidateDateType: ['', Validators.required],
      ValidateDateType: [''],
      TitleType: [null, Validators.required],
      firstNameType: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z ]*$')]],
      middleNameType: [null, [
        // Validators.required,
        //Validators.minLength(3),
        Validators.maxLength(70),
        //Validators.pattern('^[a-zA-Z ]*$')
      ]],
      lastNameType: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z ]*$')]],
      FatherNameType: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z ]*$')]],
        // FatherNameType: [null, 
        //   [Validators.minLength(3),
        //   Validators.maxLength(70),
        //   Validators.pattern('^[a-zA-Z ]*$')]
        // ],
      BODType: [null, Validators.required],
      //BODType: [null],
      PanType: [null, [Validators.required, Validators.pattern('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$')]],
      // PanType: [null, [Validators.pattern('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$')]],
      // MobileNoType: [null, 
      //   [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      // Validators.minLength(10), Validators.maxLength(10)]
      MobileNoType: [null],
      QualificationType: [null, Validators.required],
      // PercentageType: [null, [Validators.required, Validators.max(100)]],
      EmailType: [null, 
        [Validators.email]
      ],
      OfficeStatusType: ['', Validators.required],
      RelationshipOfUserType: ['', Validators.required],
      CheckType: [false, Validators.pattern('true')],
      CheckType1: [false, Validators.pattern('true')],
      DateType: [this.formDate, Validators.required],
      PlaceType: ['', Validators.required],

      enableCtcl: ['', Validators.required],
      productType: [''],
      declarationAndPan: ['', Validators.required],
      // certificate:['', Validators.required],
      certificate: [''],
      nismRegNo4: [],
      nismRegNo5: [],
      nismRegNo6: [],
      nismRegNo7: [],
      nismRegNo8: [],
      nismRegNo9: [],
      certificationType1: [],
      // new fields as per req
      userRemarks: [''],
      userType: ['', Validators.required],
      branchId:[null,Validators.required],//-----------> this will become a dropdown (pending)
    });

    this.enitService.getUserByMemCode1(this.enitId)
    this.titleService.setTitle('Add New Neat User')
    this.getSebiRegDetailsByMemId();
    this.getTapIpList();
    this.dropDownData();
  }

  getUserTypedropdown(){
    
  }

  //  by vedanti
  reset() {
    this.f.certificationType.setValue(null);
    this.f.cusTapIp1.setValue(null);
    this.f.ExamType.setValue(null);
    this.f.certificationType1.setValue(null);
    this.f.TitleType.setValue(null);
    this.f.QualificationType.setValue(null);
    this.f.OfficeStatusType.setValue(null);
    this.f.RelationshipOfUserType.setValue(null);
    this.f.enableCtcl.setValue(null);
    this.f.productType.setValue(null);
    this.f.PlaceType.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');

    this.addNeatUser.markAsPristine();
    this.addNeatUser.markAsUntouched();
    
    
    this.f.TapIpType.setValue('')
    this.f.ExistingTapIPType.setValue('')
    this.f.registraionNoType.setValue('')
    this.f.ValidateDateType.setValue('')
    this.f.nismRegNo4.setValue('')
    this.f.nismRegNo5.setValue('')
    this.f.nismRegNo6.setValue('')
    this.f.nismRegNo7.setValue('')
    this.f.nismRegNo8.setValue('')
    this.f.nismRegNo9.setValue('')
    this.f.firstNameType.setValue('')
    this.f.middleNameType.setValue('')
    this.f.lastNameType.setValue('')
    this.f.FatherNameType.setValue('')
    this.f.BODType.setValue('')
    this.f.PanType.setValue('')
    this.f.MobileNoType.setValue('')
    // this.f.PercentageType.setValue('')
    this.f.EmailType.setValue('')
    this.f.declarationAndPan.setValue('')
    this.f.certificate.setValue('')
    this.f.CheckType.setValue(false);
    this.f.CheckType1.setValue(false);
    this.submitted = false;
    this.verifyOTP = false;
    this.fileError = false;
    this.fileError1 = false;
  }

  openFileFormat() {

    let link = document.createElement("a");
    link.download = "DeclarationByApprovedUser";
    link.href = "assets/DeclarationByApprovedUser.doc";
    link.click();
  }

  onFacilityChange() {
    if (this.f.enableCtcl.value == 'YES') {
      this.f.productType.setValue('');
      this.f.productType.setValidators(Validators.required);
      this.f.productType.updateValueAndValidity();
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();
    }
    else if (this.f.enableCtcl.value == 'NO') {
      this.f.productType.setValue('');
      this.f.productType.clearValidators();
      this.f.productType.updateValueAndValidity();
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();
    }
  }

  changeCertiDetails() {
    if (this.f.certificationType.value == 'NISM') {
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();

      this.f.registraionNoType.setValidators([Validators.required, Validators.pattern("^[0-9]{12}$")]);
      this.f.registraionNoType.updateValueAndValidity();

      this.f.ExamType.setValidators(Validators.required);
      this.f.ExamType.updateValueAndValidity();

      this.f.nismRegNo4.setValue(null);
      this.f.nismRegNo5.setValue(null);
      this.f.nismRegNo6.setValue(null);
      this.f.nismRegNo7.setValue(null);
      this.f.nismRegNo8.setValue(null);
      this.f.nismRegNo9.setValue(null);


      this.f.nismRegNo4.clearValidators();
      this.f.nismRegNo4.updateValueAndValidity();

      this.f.nismRegNo5.clearValidators();
      this.f.nismRegNo5.updateValueAndValidity();

      this.f.nismRegNo6.clearValidators();
      this.f.nismRegNo6.updateValueAndValidity();

      this.f.nismRegNo7.clearValidators();
      this.f.nismRegNo7.updateValueAndValidity();

      this.f.nismRegNo8.clearValidators();
      this.f.nismRegNo8.updateValueAndValidity();

      this.f.nismRegNo9.clearValidators();
      this.f.nismRegNo9.updateValueAndValidity();

      this.f.ValidateDateType.setValidators(Validators.required);
      this.f.ValidateDateType.updateValueAndValidity()

      this.f.certificate.setValidators(Validators.required);
      this.f.certificate.updateValueAndValidity();
    }
    else if (this.f.certificationType.value == 'NISM-CPE') {
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();

      this.f.certificationType1.setValidators(Validators.required);
      this.f.certificationType1.updateValueAndValidity();

      this.f.ExamType.clearValidators();
      this.f.ExamType.updateValueAndValidity();

      this.f.ValidateDateType.setValidators(Validators.required);
      this.f.ValidateDateType.updateValueAndValidity();

      this.f.certificate.setValidators(Validators.required);
      this.f.certificate.updateValueAndValidity();

      if (this.f.certificationType1.value == 'NISM' || this.f.certificationType1.value == 'MCXSX') {
        
        this.f.registraionNoType.setValidators([Validators.required, Validators.pattern("^[0-9]{12}$")]);
        this.f.registraionNoType.updateValueAndValidity();



        this.f.nismRegNo4.clearValidators();
        this.f.nismRegNo4.updateValueAndValidity();

        this.f.nismRegNo5.clearValidators();
        this.f.nismRegNo5.updateValueAndValidity();

        this.f.nismRegNo6.clearValidators();
        this.f.nismRegNo6.updateValueAndValidity();

        this.f.nismRegNo7.clearValidators();
        this.f.nismRegNo7.updateValueAndValidity();

        this.f.nismRegNo8.clearValidators();
        this.f.nismRegNo8.updateValueAndValidity();

        this.f.nismRegNo9.clearValidators();
        this.f.nismRegNo9.updateValueAndValidity();
      }
      else if (this.f.certificationType1.value == 'NISM-CPE') {
        // this.f.nismRegNo4.setValidators([Validators.required, Validators.pattern("^[A-Za-z]{5}$")])
        this.f.nismRegNo4.setValidators([Validators.required])
        this.f.nismRegNo4.updateValueAndValidity();
        // this.f.nismRegNo5.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9]{10}$")])
        this.f.nismRegNo5.setValidators([Validators.required])
        this.f.nismRegNo5.updateValueAndValidity();
        // this.f.nismRegNo6.setValidators([Validators.required, Validators.pattern("^[0-9]{8}$")])
        this.f.nismRegNo6.setValidators([Validators.required])
        this.f.nismRegNo6.updateValueAndValidity();
        // this.f.nismRegNo7.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9]{20}$")])
        this.f.nismRegNo7.setValidators([Validators.required])
        this.f.nismRegNo7.updateValueAndValidity();
        // this.f.nismRegNo8.setValidators([Validators.required, Validators.pattern("^[A-Za-z]{20}$")])
        this.f.nismRegNo8.setValidators([Validators.required])
        this.f.nismRegNo8.updateValueAndValidity();
        // this.f.nismRegNo9.setValidators([Validators.required, Validators.pattern("^[0-9]{10}$")])
        this.f.nismRegNo9.setValidators([Validators.required])
        this.f.nismRegNo9.updateValueAndValidity();

        this.f.registraionNoType.setValue(null);

        this.f.registraionNoType.clearValidators();
        this.f.registraionNoType.updateValueAndValidity();
     }
    }
  }


  downloadDeclaration() {
    this.enitService.downloadDeclaration().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("Declaration File base 64");
        this.fileData = response.data;
      }
      else {
        this.toastr.error("failed in base 64")
      }
    })
  }

  // downloadFile(){
  //   let fileName = 'Declaration File'
  //   let file = this.fileData;
  //   let contentType = 'text';
  //   let blob = base64StringToBlob(file, contentType);
  //   saveAs(blob, fileName, {
  //     autoBOM: true
  //   });
  // }


  dropDownData() {
    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
    };

    this.enitTradeService.displayqualificationlist(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.qualificationList = response.data.refQualificationList;
        this.titleList = response.data.titleListProp;
        this.refOffcList = response.data.refOffcStatusList;
        this.refcertilist = response.data.refCertificateList;
        this.cityList = response.data.placesList;

        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }

  getTapIpList() {
    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
    };
    this.enitTradeService.getTapipdetails(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cusTapIpList = [];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.cusTapIpList = response.data.cusTapIpList;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }

  getDetailsOnTapIp() {
    this.enitTradeService.getDetailsOnTapIp(this.memId, this.f.cusTapIp1.value).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      // cusDlrTidTapId
      if (response.statusCode == 200) {
        this.noOfTerminal = response.data.NoOfTerminal;
        this.tapId = response.data.selectedTapIpDtlsObj.cusTidTapId;
       // this.tapId = response.data.selectedTapIpDtlsObj.cusTidId;
        this.f.ExistingTapIPType.setValue(this.noOfTerminal);
        this.f.TapIpType.setValue(this.tapId);
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }

  getRelationshipList() {
    this.enitTradeService.getRelationshipList(this.f.OfficeStatusType.value).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          this.relationshipList = []
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.relationshipList = response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }

  onFileSelected(event) {
    if(event.target.files && event.target.files[0]){
      let file = event.target.files[0];
      if(file.type == 'application/pdf'){
        // console.log("CORRECT TYPE");
        this.fileError = false;
      }
      else{
        // console.log("INCORRECT TYPE");
        this.fileError = true;
      }
    }
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;
    });
  }

  onFileSelected1(event) {
    if(event.target.files && event.target.files[0]){
      let file = event.target.files[0];
      if(file.type == 'application/pdf'){
        // console.log("CORRECT TYPE");
        this.fileError1 = false;
      }
      else{
        // console.log("INCORRECT TYPE");
        this.fileError1 = true;
      }
    }
    this.convertFile1(event.target.files[0]).subscribe(base641 => {
      this.base64Output1 = base641;
    });
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;


  }
  convertFile1(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  onSubmit() {
    if (this.f.certificationType.value == 'NISM') {
      this.registrationNumber = this.f.registraionNoType.value
    }
    else if (this.f.certificationType.value == 'NISM-CPE') {

      if (this.f.certificationType1.value == 'NISM') {
        this.registrationNumber = this.f.registraionNoType.value
      }
      else if (this.f.certificationType1.value == 'MCXSX') {
        this.registrationNumber1 = this.f.registraionNoType.value
      }
      else if (this.f.certificationType1.value == 'NISM-CPE') {
        this.registrationNumber2 = (this.f.nismRegNo4.value).concat(this.f.nismRegNo5.value).concat(this.f.nismRegNo6.value).concat(this.f.nismRegNo7.value).concat(this.f.nismRegNo8.value).concat(this.f.nismRegNo9.value)
      }
    }

    this.bodString = this.datepipe.transform(this.f.BODType.value, 'dd-MMM-yyyy');
    this.validityString = this.datepipe.transform(this.f.ValidateDateType.value, 'dd-MMM-yyyy');
    let requestObject = {

      "memId": this.memId,
      "memCode": this.memCode,
      "selectedTapIp": this.f.cusTapIp1.value,
      "cusTidId": this.f.TapIpType.value, // remove if not needed     
      "noOfExistingTerminals": "", //--missining in customecertifcate      
      "cusCertificateType":this.f.certificationType.value,
      "cusExamTypeNISM ":this.f.ExamType.value || null,
      //"cusCertificateType": this.f.ExamType.value || null,
      "cusExamTypeNCFM": "",
      "cusExamTypeOther": this.f.certificationType1.value || null,
      "cusExamNISMRegNo": this.registrationNumber || null,
      "cusExamMCXSXRegNo": this.registrationNumber1 || null,
      "cusExamMCXMCCPRegNo": this.registrationNumber2 || null,
      "cusExamNCDEXRegNo": "",
      "cusExamCmpltNo": this.registrationNumber || this.registrationNumber1 || this.registrationNumber2,
      "cusTitle": this.f.TitleType.value,
      "cusFirstName": this.f.firstNameType.value,
      "cusMiddleName": this.f.middleNameType.value,
      "cusLastName": this.f.lastNameType.value,
      "cusDOBStr": this.bodString || '',
      "cusCertValidDtStr": this.validityString,
      // "cusExamPercentage": this.f.PercentageType.value,
      "cusFatherHusbandName": this.f.FatherNameType.value || null,
      "cusPanNo": this.f.PanType.value?.toUpperCase() || null,
      "cusContactNo": this.f.MobileNoType.value || null,
      "cusEmailId": this.f.EmailType.value,
      "cusSelectedQualification": this.f.QualificationType.value,
      "selectedOffStatus": "",
      "cusSelectedRelationship": this.f.RelationshipOfUserType.value,
      "cusEnableCTCLFacility": this.f.enableCtcl.value,
      "cusProductTypeCTCL": this.f.productType.value || null,
      "cusDeclDateStr": this.formDate,
      "selectedPlaceId": this.f.PlaceType.value,
      "fileUploadPanBase64": this.base64Output,
      "fileUploadPanFileName": "",
      "fileUploadCertArrFileBase64": this.base64Output1,
      "fileUploadCertArrFileName": "",
      "fileUploadPanArr": "",
      "fileUploadCertArr": "",

      //adding new fields 
      "dlrUsrType":this.f.userType.value,
      "rationale":this.f.userRemarks.value,
      "branchId":this.f.branchId.value,

      // userRemarks: [''],
      // userType: [''],
      // branchId:[]
    }
    this.enitTradeService.submitUserIdRequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.submitted = true;
        let refNo = response.data;
        this.message = 'Requested Successfully With Reference Number: ' + refNo;
        this.toastr.success(this.message);
        this.reset();
        this.getSebiRegDetailsByMemId();
        $('.selectpicker').selectpicker('refresh');
      } else {
        this.submitted = false;
      }
    })
  }

  getSebiRegDetailsByMemId() {
    this.enitService.getSebiDetailsBymemId(this.memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.f.sebiRegType.setValue(response.data.memSebiRegCode)
        this.f.RegistrationOfficeType.setValue(response.data.registeredOffice)
      }
      else {
        this.toastr.error(response.message)
      }
    })
  }



}
