import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenumanagementRoutingModule } from './menumanagement-routing.module';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { MenuComponent } from './menu/menu.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AddMenuComponent, MenuComponent],
  imports: [
    CommonModule,
    MenumanagementRoutingModule,
    DataTablesModule,
    FormsModule, 
    ReactiveFormsModule,
  ]
})
export class MenumanagementModule { }
