<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Add Role</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a href="">Home</a></li>
                    <li><a href="/role">Role Management</a></li>
                    <li class="active"> <a href="/role/add-role" aria-current="page">Add Role</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <form [formGroup]='roleForm' (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Role type<span class="red">*</span> </label>
                    <select class="form-control selectpicker" formControlName="roleType" data-style="btn-info">
                        <option value='' selected>Select role type</option>
                        <option value='user'>User</option>
                        <option value='entity'>Entity</option>
                    </select>
                    <p *ngIf="f.roleType.invalid && f.roleType.touched" class="error nocase">
                        Please select role type
                    </p>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Role name<span class="red">*</span> </label>
                    <input type="hidden" class="form-control" formControlName="roleId">
                    <input type="text" class="form-control" formControlName="roleName" placeholder="Enter role name">
                    <div *ngIf="f.roleName.invalid && f.roleName.touched">
                        <p class="error nocase">
                            Please enter role name
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Role code<span class="red">*</span> </label>
                    <input type="text" class="form-control" formControlName="roleCode" placeholder="Enter role code">
                    <div *ngIf="f.roleCode.invalid && f.roleCode.touched">
                        <p class="error nocase">
                            Please enter role code
                        </p>
                    </div>
                </div>
            </div>
            <table class="table common-table">
                <thead>
                    <tr>
                        <th class="pre-wrap">Menu</th>
                        <th class="pre-wrap">Submenu</th>
                        <th class="pre-wrap">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="menuForm" formArrayName="menuForm">
                        <ng-container *ngFor="let menu of menuForm.controls;let i=index" [formGroupName]="i">
                            <tr>
                                <td class="title"><strong>{{menu.value.menuName}}</strong> </td>
                                <td></td>
                                <td>
                                    <svg *ngIf="!menu.value.isActive" (click)="checkRecordMenu(menu,'Y')"
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-check-square" viewBox="0 0 16 16">
                                        <path
                                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                        <path
                                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                    </svg>
                                    <svg *ngIf="menu.value.isActive" (click)="checkRecordMenu(menu,'N')"
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="fillColor bi bi-check-square-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                    </svg>
                                </td>
                            </tr>
                            <ng-container formArrayName="subMenuForm">
                                <ng-container *ngFor="let subMenu of menuForm.get('subMenuForm').controls; let j=index"
                                    [formGroupName]="j">
                                    <tr>
                                        <td></td>
                                        <td>{{subMenu.value.menuName}}</td>
                                        <td>
                                            <svg *ngIf="!subMenu.value.isActive"
                                                (click)="checkRecordSubMenu(menu,subMenu,'Y')"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                <path
                                                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                <path
                                                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                            </svg>
                                            <svg *ngIf="subMenu.value.isActive"
                                                (click)="checkRecordSubMenu(menu,subMenu,'N')"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="fillColor bi bi-check-square-fill"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                            </svg>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
            <div class="justify-content-start pb-2 pl-2">
                <div class="row justify-content-start">
                    <div class="col-lg-2">
                        <button class="btn btn-primary full-width">Submit</button>
                    </div>
                    <div class="col-lg-2">
                        <button class="btn btn-cancel full-width" type="button" routerLink="../" skipLocationChange>Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>