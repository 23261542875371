import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageFormatter'
})
export class MessageFormatterPipe implements PipeTransform {

  transform(data: string, ...args: any[]): string {
    let finalOutput:string=data;
    for (let i=0;i<args.length;i++){
        finalOutput=finalOutput + " "+args[i];
    }
    return finalOutput;
  }

}
