import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/app/valueObject/config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RbdServiceService {

  constructor(private http: HttpClient) { }
  config: any = config;

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  saveDetails(details: any) {
    let URL = environment.URL + this.config.URL.saveDetails;
    return this.http.post(URL, details);
  }

  getParameterFromType(pType: any) {
    let URL = environment.URL + this.config.URL.getParameterFromType + "?pType=" + pType;
    return this.http.get(URL);
  }

  uploadFile(file: File) {
    let URL = environment.URL + this.config.URL.uploadFile;
    let formParams = new FormData();
    formParams.append('file', file);

    return this.http.post(URL, formParams, this.options);
  }

  sendOtpOnForRbdEmail(details: any) {
    let URL = environment.URL + this.config.URL.sendOtpOnForRbdEmail;
    return this.http.post(URL, details);
  }

  verfiyRbdOtp(details: any) {
    let URL = environment.URL + this.config.URL.verfiyRbdOtp;
    return this.http.post(URL, details);
  }

  celaringMeberDetails() {
    let URL = environment.URL + this.config.URL.celaringMeberDetails;
    return this.http.get(URL);
  }

  countryDetails() {
    let URL = environment.URL + this.config.URL.getCountryDetails;
    return this.http.get(URL);
  }

  getExchangeAndRegulators(countryId: number) {
    let URL = environment.URL + this.config.URL.getExchangeAndRegulators + '?countryId=' + countryId;

    return this.http.get(URL);
  }
}
