import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-password-or-unlock-request-admin',
  templateUrl: './password-or-unlock-request-admin.component.html',
  styleUrls: ['./password-or-unlock-request-admin.component.css']
})
export class PasswordOrUnlockRequestAdminComponent implements OnInit {

  searchForm: FormGroup;
  config: any = config;
  requestList: any = []
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bsValue: any;
  bsValue1: any;
  entityType: string;
  memName: string
  memCode: string;
  fDate: any;
  tDate: any;
  menucode = "";
  user: any = {};
  access: any = {};
  memId: any;
  memNameAndCodeList: any = [];
  selectedCode: any;
  selectedMember: any;
  maxDate: Date = new Date();
  fromDateStr: any;
  toDateStr: any;
  filter: boolean = false
  
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private enitTradeService: EnitTradeService,
    private toastr: ToastrService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private datepipe: DatePipe,
  ) { }

  get f() {
    return this.searchForm.controls;
  }
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode - 10000) + "";
    this.memName = this.user.userName;

    if(this.entityType!='ENIT'){
      this.displayAdminMis();
    }

    this.titleService.setTitle('Password Reset / Unlock Request')    
    this.searchForm = this.fb.group({
      memName: [],
      memCode: [],
      requestType: [''],
      requestStatus: [''],
      requestFDate: [''],
      requestTDate: [''],
      referenceNo: ['']
    })

    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }
  ngAfterViewInit() {
    $('.selectpicker').selectpicker();
  }
  onMemberChange(){
    this.selectedCode = this.memNameAndCodeList.find(data => data.spDesc == this.f.memName.value);
    this.f.memCode.setValue(this.selectedCode.spMemId);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    }
  
    onCodeChange(){
      this.selectedMember = this.memNameAndCodeList.find(data => data.spMemId == this.f.memCode.value);
      this.f.memName.setValue(this.selectedMember.spDesc);
      this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    }
  displayAdminMis(){
    let requestObject={
      "memCode":"ADMIN",
      "memId":null
    }

    this.enitTradeService.displayAdminMis(requestObject).pipe(
      catchError((error: any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          this.memNameAndCodeList = []
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.memNameAndCodeList = response.data.memDetList1;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }
  open(pcmReqRefNo: string){
    if(this.entityType != 'ENIT'){
      this.router.navigate(['tradeAdmin/ePasswordResetUserId/displayAdminPasswordMis/modalForm'],{ "queryParams": { "pcmReqRefNo": pcmReqRefNo } })
    }
    else if (this.entityType = 'ENIT'){
      this.router.navigate(['eTrade/eUnlockuser/displayPasswordMis/modalForm'],{ "queryParams": { "pcmReqRefNo": pcmReqRefNo } })
    }
    
  }
  clearSearch() {
    this.setDatatable();
  }

  toDate(event:any){

 if(event.target.value!=null){
  console.log("valid");
      this.f.requestTDate.setValidators(Validators.required)
      this.f.requestTDate.updateValueAndValidity();

    }
    if(event.target.value==null){
      console.log("Invalid");
      this.f.requestTDate.clearValidators();
      this.f.requestTDate.updateValueAndValidity()
    }
  }

  // new function to set datatable
  setDatatable() {
    $("#MIS").DataTable().destroy();
    setTimeout(() => {
      $("#MIS").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#MIS .column-search input').val('');
            $('#MIS .column-search select').val('.*');
            $('#MIS .column-search').on('keyup change', 'input', function () {
              $('#MIS').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#MIS .column-search').on('keyup change', 'select', function () {
              $('#MIS').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  onReset(){
    this.searchForm.reset();
    $('.selectpicker').selectpicker('refresh');
  }
  onSubmit(){
    this.spinner.show();
    $('#MIS').DataTable().destroy();

    this.fromDateStr = this.datepipe.transform(this.f.requestFDate.value, 'dd-MMM-yyyy');
    this.toDateStr = this.datepipe.transform(this.f.requestTDate.value, 'dd-MMM-yyyy');
    if(this.entityType=='ENIT'){
      
      let requestObject = {
        "pcmAction": this.f.requestType.value,
        "memId":this.memId,     
        "pcmStatus":this.f.requestStatus.value, 
        "fromDateStr": this.fromDateStr  ,
        "toDateStr": this.toDateStr ,  
        // "memId":null,
        }
        this.enitTradeService.getResetUnlockMis(requestObject).pipe(
          catchError((error: any) => {
            this.requestList = [];
            this.setDatatable();
            this.filter = false
            this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
            if (error.status != 200) {
              return throwError(error.error);
            }
          })
        ).subscribe((response: any)=>{
          this.spinner.hide();
          if(response.statusCode == 200){
            this.requestList = response.data;
            this.setDatatable();
            this.filter = true
            this.enitTradeService.addReqList(this.requestList);
          }
          else{
            this.setDatatable();
            this.toastr.error(response.message);
            
          }
        })
    }
    else if(this.entityType!='ENIT'){
      let requestObject = {
        "pcmAction": this.f.requestType.value,
        "memCode":-1,     
        "pcmStatus":this.f.requestStatus.value,      
        "memId":this.f.memCode?.value || null,
        "fromDateStr":   this.fromDateStr,
        "toDateStr": this.toDateStr, 
        }
        this.enitTradeService.getResetUnlockMis(requestObject).pipe(
          catchError((error: any) => {
            this.requestList = [];
            this.setDatatable();
            this.filter = false
            this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
            if (error.status != 200) {
              return throwError(error.error);
            }
          })
        ).subscribe((response: any)=>{
          this.spinner.hide();
          if(response.statusCode == 200){
             this.requestList = response.data;
             this.filter = true
             this.setDatatable();
             this.enitTradeService.addReqList(this.requestList);
          }
          else{
            this.setDatatable();
            this.toastr.error(response.message);
          }
        })
    }
  }
}


