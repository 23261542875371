<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Add Menu</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a href="">Home</a></li>
                    <li><a href="/menu">Menu Management</a></li>
                    <li class="active"> <a href="/menu/add-menu" aria-current="page">Add Menu</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <div class="row">
            <div class="col-lg-6">
                <form [formGroup]='menuForm' (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Menu name<span class="red">*</span> </label>
                            <input type="hidden" class="form-control" formControlName="menuId">
                            <input type="text" class="form-control" formControlName="menuName"
                                placeholder="Enter menu name">
                            <div *ngIf="f.menuName.invalid && f.menuName.touched">
                                <p class="error nocase">
                                    Please enter menu name
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Menu code<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="menuCode"
                                placeholder="Enter menu code">
                            <div *ngIf="f.menuCode.invalid && f.menuCode.touched">
                                <p class="error nocase">
                                    Please enter menu code
                                </p>
                            </div>
                        </div>
                    </div>
                    <table id="subMenuList" class="table common-table" *ngIf="menuData.subMenuData">
                        <thead>
                            <tr>
                                <th class="pre-wrap">Submenu Name</th>
                                <th class="pre-wrap">Submenu Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="subMenuForm">
                                <ng-container *ngFor="let subMenu of menuData.subMenuData;let i=index"
                                    [formGroupName]="i">
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <input type="hidden" class="form-control" formControlName="menuId">
                                                <input type="text" value="" class="form-control"
                                                    formControlName="menuName" placeholder="Enter sub-menu name">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" value="" class="form-control"
                                                    formControlName="menuCode" placeholder="Enter sub-menu code">
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <tr>
                                <td colspan="2">
                                    <div class="text-right">
                                        <button class="btn btn-primary" (click)="addMenu()">Add New</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="justify-content-start pb-2 pl-2">
                        <div class="row justify-content-start">
                            <div class="col-lg-2">
                                <button class="btn btn-primary full-width"
                                    [disabled]="!!(action=='view')">Submit</button>
                            </div>
                            <div class="col-lg-2">
                                <button class="btn btn-cancel full-width" type="button"
                                    routerLink="../" skipLocationChange>Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="offset-lg-2"></div>
            <div class="col-lg-3">
                <h6>Menu mapping</h6>
                <ul>
                    <li *ngFor="let menu of allMenuData">{{menu.menuName}}
                        <ul>
                            <li *ngFor="let subMenu of menu.subMenuData">{{subMenu.menuName}}</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>