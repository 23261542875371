import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
declare var $: any
@Component({
  selector: 'app-et-message-rate-admin-modal',
  templateUrl: './et-message-rate-admin-modal.component.html',
  styleUrls: ['./et-message-rate-admin-modal.component.css']
})
export class EtMessageRateAdminModalComponent implements OnInit {
  modalData: any;
  modalForm: FormGroup;
  modalDetails: any;
  date: any;
  config: any =config;
  user: any=[];
  entityType: any;
  memCode: any;
  memName: any;
  access: any = {};
  menucode: string = 'displayMRCReport'
  constructor(
    private enitService: EnitTradeService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private userDetailsService: UserDetailsService,
    private cdref: ChangeDetectorRef,
  ) { 
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.modalData = this.enitService.getMrcByRef(params['id']);
      }
    });
  }
  get f() {
    return this.modalForm.controls;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Message Rate Change MIS')
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.modalDetails = this.modalData.mrcDtlsList[0];
    this.modalForm = this.fb.group({
      refNo: [this.modalData.mrcRefNum],
      memberName: [this.modalData.mrcMemName],
      memberCode: [this.modalData.mrcMemCode],
      reqType: [this.modalData.mrcReqType], // no value
      tapIP: [this.modalDetails.mrdTapip],
      category: [this.modalData.mrcCat],
      boxID: [this.modalData.mrcBoxID],
      currentMsgRate: [this.modalDetails.mrdCurrentMsg],
      // proposedMsgRate: [this.modalDetails.mrdPropMsg],
      proposedMsgRate: [],
      name: [this.modalData.mrcPersonName],
      number: [this.modalData.mrcPersonNum],
      email: [this.modalData.mrcPersonMail],
      currentStatus: [],
      remarks: [(this.modalData.mrcReqStatus=='APPR')?this.modalData.mrcRemarks:(this.modalData.mrcReqStatus=='RJCT')?this.modalData.mrcRemarks:''],
      revisedStatus: ['', Validators.required],
    })
    if(this.modalData.mrcReqStatus=='APPR'){
      this.f.currentStatus.setValue('Approved');
    }else if(this.modalData.mrcReqStatus=='RJCT'){
      this.f.currentStatus.setValue('Rejected');
    }else{
      this.f.currentStatus.setValue('Submitted to Exchange');
    }

    if(this.modalDetails.mrdPropMsg == "100"){
      this.f.proposedMsgRate.setValue("Category A (100 m.p.s)")
    }
    else if(this.modalDetails.mrdPropMsg == "200"){
      this.f.proposedMsgRate.setValue("Category B (200 m.p.s)")
    }
    else if(this.modalDetails.mrdPropMsg == "400"){
      this.f.proposedMsgRate.setValue("Category C (400 m.p.s)")
    }
    else if(this.modalDetails.mrdPropMsg == "800"){
      this.f.proposedMsgRate.setValue("Category D (800 m.p.s)")
    }




    // else if(this.modalData.mrcReqStatus=='UNDER_PROC'){
    //   this.f.currentStatus.setValue('Under Processing');
    // }else if(this.modalData.mrcReqStatus=='SUB'){
    //   this.f.currentStatus.setValue('Submitted to Exchange');
    // } removed because other options were removed

    $('.selectpicker').selectpicker();
  }
  back(){
    this.router.navigate(['tradeAdmin/eUserIDRequestA/displayMRCReport'])
  }
  reset() {
    this.f.revisedStatus.setValue(null);
    this.f.remarks.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    // $('.selectpicker').selectpicker();
  }
  onSubmit(){
    // let requestObject ={
    //   "cusRefNo": this.f.refNo.value,
    //   "cusStatus": this.f.revisedStatus.value,
    //   "cusRemarks": this.f.remarks.value,
    // }
    let requestObject ={
      "memCode": this.memCode,
      "cusRefNo": this.f.refNo.value,
      "cusStatus": this.f.revisedStatus.value,
      "cusRemarks": this.f.remarks.value,
      "tapIp": this.f.tapIP.value,
      "proposedMessageRateCategory": this.modalDetails.mrdPropMsg //check this

    }
    this.enitService.approvalForMrcStatus(requestObject).pipe(
    catchError((error: any)=> {
      this.toastr.error(
      this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.toastr.success("Request Submitted Successfully");
        this.router.navigate(['tradeAdmin/eUserIDRequestA/displayMRCReport'])
      }
      else
      this.toastr.error(response.message);
    })
  }
}
