<form [formGroup]='otpForm' (ngSubmit)="onSubmit()">
    <div id="slide-otp" class="otp">
        <div class="alert alert-success nocase" role="alert" *ngIf="!!message">
            {{message}} 
        </div>

        <div class="row">
            <div class="col-sm-12 form-group">
                <label>Enter Otp</label>
                <input type="text" class="form-control" formControlName="otp" autocomplete="off">
                <p *ngIf="otpForm.get('otp').invalid && otpForm.get('otp').touched" class="error nocase">
                    Otp is required
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 mb-1">
                <button class="btn btn-primary full-width" type="submit">Submit</button>
            </div>
            <div class="col-lg-4 mb-1">
                <button class="btn btn-cancel full-width" type="button" id="back" (click)="back()">
                    Back
                </button>
            </div>
        </div>
        
        <div class="row justify-content-center">

            <div class ="mt-2">

                <button class="btn btn-secondary " type="button" [disabled]="resendDisable"

                    (click)="generateOTP()">{{resendDisable?(timeVal| minuteSeconds):''}} Resend
                    
                    </button>

            </div>
        </div>
    </div>
</form>