import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccessmanagementRoutingModule } from './accessmanagement-routing.module';
import { UseraccesslistComponent } from './useraccesslist/useraccesslist.component';
import { AccessdetailsComponent } from './accessdetails/accessdetails.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [UseraccesslistComponent, AccessdetailsComponent],
  imports: [
    CommonModule,
    AccessmanagementRoutingModule,
    DataTablesModule,
    FormsModule, ReactiveFormsModule,
    NgxSpinnerModule
  ]
})
export class AccessmanagementModule { }
