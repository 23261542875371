import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CelService } from 'src/app/service/cel.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, AfterViewInit, OnDestroy {

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  user: any = {};
  access: any = {};
  notifications: any = [];
  userControlId: string = '';
  menucode: string = 'notifications';
  config: any = config;
  subscription: Subscription;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private celService: CelService) { }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Notifications");
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
  }

  ngAfterViewInit() {
    this.getBranchUserData();
  }

  getBranchUserData() {
    this.celService.getNotification().pipe(
      catchError((error: any) => {
        this.notifications = [];
        this.setDatatable();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.notifications = response.data[0];
        this.setDatatable();
      } else {
        this.notifications = [];
        this.setDatatable();
        this.toastr.warning(response.message);
      }
    })
  }

  getCelType(type) {
    if (type == 0)
      return 'future buy limit';
    else if (type == 1)
      return 'future sell limit';
    else if (type == 2)
      return 'option buy limit';
    else if (type == 3)
      return 'option sell limit';
  }

  getAlertType(type) {
    if (type == 0)
      return 'notification';
    else if (type == 1)
      return 'warning';
    else if (type == 2)
      return 'breach';
  }

  setDatatable() {
    $("#userList").DataTable().destroy();
    setTimeout(() => {
      $("#userList").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        pageLength: 100,
        lengthMenu: [10, 25, 50, 100],
        buttons: [{
          extend: 'copy',
          footer: true,
          titleAttr: 'Copy',
          exportOptions: {
            titleAttr: 'Copy',
          }
        }, {
          extend: 'excel',
          footer: true,
          titleAttr: 'Export to Excel',
          exportOptions: {
            titleAttr: 'Export to excel',
          }
        }, {
          extend: 'pdf',
          footer: true,
          titleAttr: 'Download as PDF',
          exportOptions: {
            titleAttr: 'Download as PDF',
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#userList .column-search input').val('');
            $('#userList .column-search select').val('.*');
            $('#userList .column-search').on('keyup change', 'input', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#userList .column-search').on('keyup change', 'select', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  ngOnDestroy() {
    this.dtTrigger?.unsubscribe();
    this.subscription?.unsubscribe();
  }

}
