<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Setup</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="complianceAdmin/adminSetupMenu/adminSetup" aria-current="page"> Setup</a></li>  
                </ul>
            </div>


        </div>
    </div>


<div class="card card-body">
<div class="row">
    <div id="accordion" class="col-lg-6">
  
        <div class="card-header pointer" id="Combinedheading">

            <h6 class="mb-0" data-toggle="collapse" data-target="#collapseCombined" aria-expanded="false"
                aria-controls="collapseCombined">
                <i class="fa fa-angle-up pull-right rotate-icon"></i> Period Settings
            </h6>
        </div>
        <div id="collapseCombined" class="collapse show" aria-labelledby="Combinedheading" data-parent="#accordion">

            <form [formGroup]="DisableAdminForm">

                <div class="card card-body">

                    <div>
                        <br>
                        <div class="row">
                            <div class="col-lg-4 form-group ">
                                <button type="button" (click)="openModalYear()" class="btn btn-primary col-10" >Add New Year</button> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 form-group ">
                                <label>financial Year</label>
                                <select id="financialYearSelect" value="true" class="form-control requestTypePicker"
                                    formControlName="adminFinancial" data-style="btn-info" title="Select request Type"
                                    data-live-search="true" (change)="enableFinacialYear()" (change)="getPeriodListData()">
                                    <option value="">Select</option>
                                    <option *ngFor="let adminReqDetails of adminRequestTypeList"
                                        value="{{adminReqDetails.id}}">
                                        <span>{{adminReqDetails.name}}</span>
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <label> Period Yearly </label>
                                <select id="myselect" class="form-control requestTypePicker"
                                        formControlName="adminPeriod" data-style="btn-info" title="Select request Type"
                                        data-live-search="true" (change)="getPeriodListData()">
                                        <option value="">Select</option>

                                        <option *ngFor="let adminPeriodDetails of viewPeriodList"
                                        value="{{adminPeriodDetails.refValue}}">
                                        <span>{{adminPeriodDetails.refValue}}</span>
                                    </option> 

                                </select>
                            </div>
                            
                        </div>

                    </div>
        <table id="halfYearly"   class="table common-table table-bordered table-hover nowrap col-lg-8" *ngIf="f.adminPeriod.value=='HALF YEARLY'" >
            <thead>
                <tr>
                    <th class="normalwrap"> Period</th>
                    <th class="normalwrap"> Type</th>
                    <th class="normalwrap"> FromDate</th>
                    <th class="normalwrap"> ToDate</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let periodData of PeriodListData">
                    <td>{{periodData.quarter}}</td>
                    <td>{{periodData.type}}</td>
                    <td>{{periodData.fromDate | date}}</td>
                    <td>{{periodData.endDate | date}}</td>
                    </tr>
                
            </tbody>
        </table>

        <table id="quarterly"  class="table common-table table-bordered table-hover nowrap col-lg-8" *ngIf="f.adminPeriod.value=='QUARTER'" >
            <thead>
                <tr>
                    <th class="normalwrap"> Period</th>
                    <th class="normalwrap"> Type</th>
                    <th class="normalwrap"> FromDate</th>
                    <th class="normalwrap"> ToDate</th>
                </tr>
            </thead>
            <tbody>
                
                <tr *ngFor="let periodData of PeriodListData">
                <td>{{periodData.quarter}}</td>
                <td>{{periodData.type}}</td>
                <td>{{periodData.fromDate | date}}</td>
                <td>{{periodData.endDate | date}}</td>
                </tr>
            
        </tbody>
        </table>
        <table id="halfYearly"  class="table common-table table-bordered table-hover nowrap col-lg-8" *ngIf="f.adminPeriod.value=='YEARLY'" >
            <thead>
                <tr>
                    <th class="normalwrap"> Period</th>
                    <th class="normalwrap"> Type</th>
                    <th class="normalwrap"> FromDate</th>
                    <th class="normalwrap"> ToDate</th>
                </tr>
            </thead>
            <tbody>
                
                    <tr *ngFor="let periodData of PeriodListData">
                    <td>{{periodData.quarter}}</td>
                    <td>{{periodData.type}}</td>
                    <td>{{periodData.fromDate | date}}</td>
                    <td>{{periodData.endDate | date}}</td>
                    </tr>
                
            </tbody>
        </table>
                </div>
            </form>
        </div>
    <!-- </div> -->

</div>

<div id="accordion1" class="col-lg-6">
    <!-- <div class="card card-body"> -->
        <div class="card-header pointer" id="Emailheading">
            <h6 class="mb-0" data-toggle="collapse" data-target="#collapseEmail" aria-expanded="false"
                aria-controls="collapseEmail">
                <i class="fa fa-angle-up pull-right rotate-icon"></i> Exchange Notification Email Settings
            </h6>
        </div>
        <!-- <div class="collapse" id="collapseExchangeEmail"> -->
        <div id="collapseEmail" class="collapse show" aria-labelledby="Emailheading" data-parent="#accordion1">
            <form [formGroup]="ViewExistingEmailForm">
            <div class="card card-body">
             <div class="row">
            <div class="col-lg-3 form-group">
                <button type="button" (click)="openModal()" class="btn btn-primary col-10">Add Email Id</button>
            </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-9" >
                <table id="exchangeEmailTable" class="table common-table table-bordered table-hover nowrap" datatable [dtOptions]="dtOptions1"
                [dtTrigger]="dtTrigger1">
                    <thead>
                        <tr>
                            <th class="normalwrap">Serial No.</th>
                            <th class="normalwrap">Existing Email ID</th>
                            <th class="normalwrap">Edit</th>
                            <th class="normalwrap">Delete</th>
                       </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let emailData of emailIdGridList; let srNo=index">
                           
                            <td>{{srNo+1}}</td>
                           
                            <td class="nocase">{{emailData.emailID}}</td>
                            <td><i class="fa fa-pencil" (click)="filterData(emailData.emailID,emailData.serialNo)"   
                                aria-hidden="true" data-toggle="modal" data-target="#editEmailId"></i></td>
                            <td><i class="fa fa-trash pointer" (click)="deleteExchangeEmailIdData(emailData.serialNo)" class="fa fa-trash pointer" aria-hidden="true" data-toggle="modal"
                                data-target="#deleteEmail"></i></td>
                        </tr>
                       
                    </tbody>
                </table>
                
               </div>
                
                <div class="overlay" *ngIf="popupEmailEdit" class="col-lg-10">
                    <div class="card card-body">
                    <div class="popup justify-content-start" >
                        <h2 align="center">Emailid Edit</h2>
                       <br>
                    <form >
                        <div class="col-lg-6">
                            <input type="text" class="form-control" id="emailId"  placeholder="emailId@abc.com,emailId1@abc.com">
                        </div>
                           <br>
                        <div class="col-lg-4">
                            <p align="center">
                                <button class="btn btn-primary full-width" type="submit" (click)="submit()" float right>Submit</button>
                            </p>
                        </div>
                     </form>
                        <a class="close" (click)="popupEmailEdit = false">&times;</a>
                     </div>
                </div> 
                </div>

            
            </div>
            </div>
        </form>
    </div>
</div>
</div>
</div>


<br>
<div id="accordion2">
    <div class="card card-body">
       

        <div class="card-header pointer" id="Memberheading">
            <h6 class="mb-0" data-toggle="collapse" data-target="#collapseMember" aria-expanded="false"
                aria-controls="collapseMember">
                <i class="fa fa-angle-up pull-right rotate-icon"></i> Member Notification Email Settings
            </h6>
        </div>
        <div id="collapseMember" class="collapse show" aria-labelledby="Emailheading" data-parent="#accordion2">
            <div class="card card-body">
                <app-email-template></app-email-template>
            </div>
        </div>
    </div>
</div>

<div id="accordion3">
    <div class="card card-body">

        <!-- <form [formGroup]="CutOffDateForm"> -->

            <div class="card-header pointer" id="cutOffheading">
                <h6 class="mb-0" data-toggle="collapse" data-target="#collapseCutoff" aria-expanded="false"
                    aria-controls="collapseCutoff">
                    <i class="fa fa-angle-up pull-right rotate-icon"></i> Cut off Day Settings
                </h6>
            </div>
            <div id="collapseCutoff" class="collapse show" aria-labelledby="cutOffheading" data-parent="#accordion3">
                <div class="card card-body">
                    <div class="row clearSearch">
                        <button type="button" class="btn btn-cancel btn-sm" aria-label="close" (click)="clearSearch()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                                viewBox="0 0 15 15">
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg> Filter</button>
                    </div>
                    <div class="col-lg-4 form-group ">
                        <button type="button" (click)="openCutoffModal()"
                            class="btn btn-primary col-6">Add New Cutoff Day</button>
                    </div>

                    <table id="cutoffDateTable" class="table common-table table-bordered table-hover" datatable [dtOptions]="dtOptions2"
                        [dtTrigger]="dtTrigger2">
                        <thead>
                            <tr>
                                <th class="pre-wrap">Sr No</th>
                                <th class="pre-wrap" style="width: 400px;">Module</th>
                                <th class="pre-wrap" style="width: 400px;">Year</th>
                                <!-- <th class="pre-wrap">Type</th> -->
                                <th class="pre-wrap text-center">Quarter Submission Cut-Off Date</th>
                                <!-- <th class="pre-wrap">Cut off Day</th>  -->
                                <th class="pre-wrap text-center">Edit</th>
                                <th class="pre-wrap text-center">Delete</th>
                            </tr>
                            <tr class="column-search">
                                <th></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of cutOffDayGridList; let i=index">
                                <td>{{i+1}}</td>
                                <td>{{data.moduleName}}</td>
                                <td>{{data.year}}</td>
                                <td>
                                    <table class="inner-table">
                                        <tbody>
                                            <tr *ngFor="let quarter of data.quarterData">
                                                <td class="text-left" style="width: 225px;">{{quarter.quaterName}}</td>
                                                <td class="text-left" style="width: 225px;">
                                                    {{quarter.cutOffDate|date:'mediumDate'}}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <i class="fa fa-pencil pointer usercontrol" aria-hidden="true"
                                        (click)="filterCutOfData(data.id)" data-toggle="modal"
                                        data-target="#editCutOffDate"></i>
                                </td>
                                <td><i class="fa fa-trash pointer" (click)="deleteCutOffYear(data.moduleName,data.year)" class="fa fa-trash pointer" aria-hidden="true" data-toggle="modal"
                                    data-target="#deleteCutOffDate"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        <!-- </form> -->
    </div>
</div>

<div class="modal fade" aria-hidden="true" id="cutOffModal">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="card card-body">
            <form [formGroup]="AddCutOffDateForm">
                <div class="modal-content">
                    <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-header">
                        <h5 class="modal-title">Add new Cut Off Year</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <label>Year</label>
                            </div>
                            <div class="col-lg-4">
                                <input class="form-control" type="text" formControlName="cutOffYear"
                                    placeholder="please enter the year">
                            </div>
                        </div>

                        <br>


                        <div class="row">
                            <div class="col-lg-4">
                                <label>Select Module</label>
                            </div>

                            <div class="col-lg-4 form-group ">

                                <select id="CutoffModuleSelect" value="true" class="form-control requestTypePicker"
                                    formControlName="cutOffModule" data-style="btn-info" title="Select request Type"
                                    data-live-search="true" >
                                    <option value="">Select</option>
                                    <option *ngFor="let cutOffDetails of viewModuleList"
                                    value="{{cutOffDetails.refDesc}}">
                                   <span>{{cutOffDetails.refDesc}}</span>
                                   </option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close" 
                        [disabled]="AddCutOffDateForm.invalid"  (click)="onSubmitCutOffDate()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" aria-hidden="true" id="editCutOffDate">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="card card-body">
            <form [formGroup]="UpdateCutOffForm">
                <div class="modal-content">
                    <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-header">
                        <h5 class="modal-title">edit Cut Off Date</h5>
                    </div>
                    <div class="modal-body">
                        <div> Module : {{selectedRecord.moduleName}}</div>
                        <div> Year : {{selectedRecord.year}}</div>

                        <div class="row">
                            <div class="col-lg-4">

                            </div>
                        </div>
                        <ng-container *ngIf="selectedRecord.quarterData" formArrayName="quarterData">
                            <ng-container *ngFor="let data of selectedRecord.quarterData;let i=index"
                                [formGroupName]="i">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <label>{{data.quaterName}}</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="text" class="form-control datepicker" [minDate]="minDate"
                                            formControlName="cutOffDate" [(bsValue)]="bsValue[i]"
                                            value="{{bsValue[i] | date:'MM/dd/yyyy'}}" bsDatepicker
                                            [bsConfig]="{ isAnimated: true}" placeholder="select cut off date">

                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        
                        <br>
                        <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close" (click)="updateCutOffDate()">update</button>

                    </div>


                </div>
            </form>
        </div>
    </div>
</div>





</div>
<div class="modal fade"  aria-hidden="true" id="emailModal">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
       
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h5 class="modal-title">Add new Email Id</h5>
            </div>
        <div class="modal-body">
            <form [formGroup]="addEmail">
                    <div class="row">
                    <div class="col-lg-4">
                    <label>Email</label>
                </div>
               </div>
               <div class="row">
                <div class="col-lg-10">
                    <input class="form-control" type="text" formControlName="emailId" placeholder="abc@abc.com">
                </div>
                   
                </div>
                <!-- <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close" [disabled]="addEmail.invalid" (click)="onSubmit()">Submit</button> -->
                <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close" [disabled]="addEmail.invalid" (click)="onSubmit()">Submit</button>

            </form>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" aria-hidden="true" id="yearModal">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
         <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
             <div class="modal-header">
                <h5 class="modal-title">Add new year</h5>
             </div>
             <div class="modal-body">
                 <form [formGroup]="addYearFrom">

                    <div class="row">
                        <div class="col-lg-4">
                        <label>Year</label>
                    </div>
                   </div>
                   <div class="row">
                    <div class="col-lg-10">
                        <input class="form-control" type="text" formControlName="addYear">
                    </div>
                       
                    </div>
                    <div class="mt-2">
                        <button class="btn btn-primary"  type="submit" data-dismiss="modal" aria-label="Close" [disabled]="addYearFrom.invalid" (click)="onSubmitYear()">Submit</button>
                    </div>
                          
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deleteEmail" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body text-center">
            <h6>Are you sure you want to delete this entry?</h6>
            <div class="d-flex justify-content-center mt-3">
              <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                  data-dismiss="modal">Cancel</button></div>
              <div class="col-3"><button type="button" class="btn btn-primary full-width" (click)="deleteEmailIdData()"
                  data-dismiss="modal">Delete</button></div>

            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="modal fade"  aria-hidden="true" id="editEmailId">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
       <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h5 class="modal-title">edit Email Id</h5>
            </div>
        <div class="modal-body">
            <form [formGroup]="addEmail">


                <div class="row">
                    <div class="col-lg-4">
                    <label>Email Id</label>
                </div>
               </div>
               <div class="row">
                <div class="col-lg-10">
                    <input class="form-control" type="text" formControlName="emailId">
                    <!-- <textarea contenteditable  formControlName="emailId" cols="25" rows="1"></textarea> -->
                </div>
                   
                </div>
                <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close" [disabled]="addEmail.invalid" (click)="editEmail()">Update</button>
            </form>
        </div>
       </div>
    </div>
</div>

<div class="modal fade" id="deleteCutOffDate" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body text-center">
            <h6>Are you sure you want to delete this entry?</h6>
            <div class="d-flex justify-content-center mt-3">
              <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                  data-dismiss="modal">Cancel</button></div>
              <div class="col-3"><button type="button" class="btn btn-primary full-width" (click)="deleteCutOffDetails()"
                  data-dismiss="modal">Delete</button></div>

            </div>
          </div>

        </div>
      </div>
    </div>