import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { catchError, retry } from 'rxjs/operators';
import { CtclService } from 'src/app/service/ctcl.service';
import { Subscription } from 'stompjs';
import { CtclIdModificationRequest, CtclIdModificationResponse, NeatAndterminalResponse, CtclResponseForModification, CtclNeatIdAndTerminalOffice, ctclDisplayDataResponse, CtclModificationSubmitReq, CtclResponseForDeactivation, CtclIdActivationResponse } from '../ctcl-request-response';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { VerifyRequestByOtpComponent } from '../verify-request-by-otp/verify-request-by-otp.component';
import { UserDetailsService } from 'src/app/service/user-details.service';

declare var $: any;

@Component({
  selector: 'ctcl-id-modification',
  templateUrl: './ctcl-id-modification.component.html',
  styleUrls: ['./ctcl-id-modification.component.css']
})
export class CtclIdModificationComponent implements OnInit, OnDestroy {

  constructor(
    private ctclService: CtclService, 
    private httpClient: HttpClient, 
    private cdref: ChangeDetectorRef, 
    private fb: FormBuilder, 
    private toastrSrv: ToastrService, 
    private host: ElementRef<HTMLElement>, 
    private route: ActivatedRoute, 
    private router: Router, 
    private userDetailsService: UserDetailsService) {

    this.route.queryParams.subscribe(params => {

      this.displayFromReport = params['displayFromReport'] === undefined ? "NO" : params['displayFromReport'];
      this.memberOrAdminReport = params['memberOrAdminReport'] === undefined ? "NA" : params['memberOrAdminReport'];
      this.ctclRequestIdReport = params['ctclRequestIdReport'] === undefined ? "" : params['ctclRequestIdReport'];
      const ctclModifiedRequestId = this.ctclRequestIdReport.replace('_', '\\').replace('_', '\\');
      this.ctclRequestFromReport = this.ctclService.getCtclReportByRef(ctclModifiedRequestId);
    });

  }

  ngAfterViewInit(): void {
    if (this.displayFromReport === 'YES') {
      this.ctclRequestStatusDetails.nativeElement.value = this.ctclReqStatus;

      if (this.reqExam.get('ctclExamDtlsExamOf').value === 'NISM-CPE') {
        if (this.reqExam.get('ctclExamDtlsExamId').value !== '') {
          let splittedExamId: any[] = this.reqExam.get('ctclExamDtlsExamId').value.split('/')
          if (splittedExamId.length == 4) {
            this.nismexamIdPart1.nativeElement.value = splittedExamId[0];
            this.nismexamIdPart4.nativeElement.value = splittedExamId[2];

            let splitExamIdPart2: any[] = splittedExamId[1].split('-')
            if (splitExamIdPart2.length == 2) {

              this.nismexamIdPart2.nativeElement.value = splitExamIdPart2[0];
              this.nismexamIdPart3.nativeElement.value = splitExamIdPart2[1];
            }

            let splitExamIdPart4 = splittedExamId[3].split('-')
            if (splitExamIdPart4.length == 2) {
              this.nismexamIdPart5.nativeElement.value = splitExamIdPart4[0];
              this.nismexamIdPart6.nativeElement.value = splitExamIdPart4[1];
            }
          }
        }
      }
      else {
        this.nismexamIdPart0.nativeElement.value = this.reqExam.get('ctclExamDtlsExamId').value;
      }
    }
  }

  ngOnDestroy(): void {
  }

  neatUserIdList: any[] = [];
  officeTerminalList: any[] = [];
  terminalIdList: any[] = [];
  purposeOfCtclIdList: any[] = [];
  cityList: any[] = [];
  ctclRelationList: any[] = [];
  ctclLocdtlsConnModeList: any[] = [];
  ctclExamDtlsTitleList: any[] = [];
  ctclExamDtlsCertificatetypeList: any[] = [];
  ctclAddressList: any[] = [];
  disableSubmitButton: boolean = false;
  ctclIdActivationURL: string = "";
  dobOfApprovedPersonError: string = "";
  validityDateOfApprovedPersonError: string = "";
  activationDateError: string = "";
  modificationDateError: string = "";
  examIdPart1: string = "";
  examIdPart2: string = "";
  examIdPart3: string = "";
  examIdPart4: string = "";
  examIdPart5: string = "";
  examIdPart6: string = "";
  examIdPartError1: string = "";
  examIdPartError2: string = "";
  examIdPartError3: string = "";
  examIdPartError4: string = "";
  examIdPartError5: string = "";
  examIdPartError6: string = "";
  examIdError: string = "";
  ctclModificationInitialData: CtclIdModificationResponse;
  ctclActSubmitRequest: any = {};
  declarationCertified: boolean = false;
  ctclIdModificationSub;
  ctclReqStatus: string = "";
  ctclApprovalRejectionReq;
  testData: string = "";
  ctclRequestFromReport: any = {};
  displayFromReport: string = "NO";
  memberOrAdminReport: string = "NA";
  ctclRequestIdReport: string = "";

  userDetails: any = {};
  ctclExamRegistrationNum: any = ""
  // for OTP
  module: any = "CTCLModify";
  reqtype: number = 4;
  loginId: string = "";
  verifyOTP: boolean = false;
  memId: Number = 0;

  errorInmodificationDate: boolean = true;
  errorInactivationDate: boolean = true;
  errorInbirthDate: boolean = false;
  errorInvalidityDate: boolean = false;

  @ViewChild('approveRejectComment') approveRejectComment: ElementRef;
  @ViewChild('ctclRequestStatusDetails') ctclRequestStatusDetails: ElementRef;
  @ViewChild(VerifyRequestByOtpComponent) verifyOtpComponent!: VerifyRequestByOtpComponent;
  @ViewChild('nismexamIdPart0') nismexamIdPart0 !: ElementRef;
  @ViewChild('nismexamIdPart1') nismexamIdPart1 !: ElementRef;
  @ViewChild('nismexamIdPart2') nismexamIdPart2 !: ElementRef;
  @ViewChild('nismexamIdPart3') nismexamIdPart3 !: ElementRef;
  @ViewChild('nismexamIdPart4') nismexamIdPart4 !: ElementRef;
  @ViewChild('nismexamIdPart5') nismexamIdPart5 !: ElementRef;
  @ViewChild('nismexamIdPart6') nismexamIdPart6 !: ElementRef;

  newItemEvent(event: boolean) {
    this.verifyOTP = event;
    if (this.verifyOTP === true) {
      $('#ctclmodification').modal('hide');
    }
  }

  resetExclusionList: any = {};

  ngOnInit(): void {
    if (this.displayFromReport === 'YES') {
      this.ctclRequestDisabledFields = {
        ctclRqdtlsNeatUserIdDisable: true,
        ctclRqdtlsTerminalIdDisable: true,
        ctclRqdtlsAllotDateDisable: true,
        ctclRqdtlsLoginIdDisable: true,
        ctclRqdtlsPurposeCtclIdDisable: true,
        ctclRqdtlsAllotDateStrDisable: true,
        ctclRqdtlsModificationStrDateDisable: true,
        ctclRqdtlsDisableDateStrDisable: true,
        ctclLocdtlsOfficeStatusDisable: true,
        ctclRqdtlsFatherFirstNameDisable: true,
        ctclRqdtlsFatherMiddleNameDisable: true,
        ctclRqdtlsFatherLastNameDisable: true,
        ctclRqdtlsPanDisable: true,
        ctclRqdtlsCellContactDisable: true,
        ctclRqdtlsEmailDisable: true,
        ctclRqdtlsRelationshipDisable: true,
        ctclReqStatusDisable: true,
        existingAddressDtls: {
          adFlatNoDisable: true,
          adBuildingNameDisable: true,
          adFloorDisable: true,
          cityNmDisable: true,
          stateNmDisable: true,
          adPincodeDisable: true
        },
        addressDtls: {
          adFlatNoDisable: true,
          adBuildingNameDisable: true,
          adFloorDisable: true,
          adStreetNameDisable: true,
          adLandmarkDisable: true,
          adColonyDisable: true,
          adCityDisable: true,
          adStateDisable: true,
          adPincodeDisable: true,
          cityNameDisable: true,
          stateNameDisable: true
        },
        ctclRqdtlsLocationId: {
          ctclAddStdNoDisable: true,
          ctclAddPhoneNoDisable: true,
          ctclLocdtlsConnModeDisable: true
        },
        ctclRqdtlsExamId: {
          ctclExamDtlsExamOfDisable: true,
          ctclExamDtlsExamTypeDisable: true,
          ctclExamDtlsExamIdDisable: true,
          ctclExamDtlsCertificatetypeDisable: true,
          ctclExamDtlsTitleDisable: true,
          ctclExamDtlsFirstNameDisable: true,
          ctclExamDtlsMiddleNameDisable: true,
          ctclExamDtlsLastNameDisable: true,
          ctclExamDtlsDobStrDisable: true,
          ctclExamDtlsValidDtDisable: true,
          ctclExamDtlsValidDtStrDisable: true
        },
        ctclRqdtlsSubBrokerId: {
          ctclSubAuthDtlsSebiRegNoDisable: true,
          ctclSubAuthDtlsNameDisable: true,
          ctclAuthPerNameDisable: true
        }

      }
    }
    else {
      this.ctclRequestDisabledFields = {
        ctclRqdtlsNeatUserIdDisable: false,
        ctclRqdtlsTerminalIdDisable: false,
        ctclRqdtlsAllotDateDisable: false,
        ctclRqdtlsLoginIdDisable: false,
        ctclRqdtlsPurposeCtclIdDisable: false,
        ctclRqdtlsAllotDateStrDisable: false,
        ctclRqdtlsModificationStrDateDisable: false,
        ctclRqdtlsDisableDateStrDisable: false,
        ctclLocdtlsOfficeStatusDisable: false,
        ctclRqdtlsFatherFirstNameDisable: false,
        ctclRqdtlsFatherMiddleNameDisable: false,
        ctclRqdtlsFatherLastNameDisable: false,
        ctclRqdtlsPanDisable: false,
        ctclRqdtlsCellContactDisable: false,
        ctclRqdtlsEmailDisable: false,
        ctclRqdtlsRelationshipDisable: false,
        ctclReqStatusDisable: false,
        existingAddressDtls: {
          adFlatNoDisable: false,
          adBuildingNameDisable: false,
          adFloorDisable: false,
          cityNmDisable: false,
          stateNmDisable: false,
          adPincodeDisable: false
        },
        addressDtls: {
          adFlatNoDisable: false,
          adBuildingNameDisable: false,
          adFloorDisable: false,
          adStreetNameDisable: false,
          adLandmarkDisable: false,
          adColonyDisable: false,
          adCityDisable: false,
          adStateDisable: true,
          adPincodeDisable: false,
          cityNameDisable: false,
          stateNameDisable: false
        },
        ctclRqdtlsLocationId: {
          ctclAddStdNoDisable: false,
          ctclAddPhoneNoDisable: false,
          ctclLocdtlsConnModeDisable: false
        },
        ctclRqdtlsExamId: {
          ctclExamDtlsExamOfDisable: false,
          ctclExamDtlsExamTypeDisable: false,
          ctclExamDtlsExamIdDisable: false,
          ctclExamDtlsCertificatetypeDisable: false,
          ctclExamDtlsTitleDisable: false,
          ctclExamDtlsFirstNameDisable: false,
          ctclExamDtlsMiddleNameDisable: false,
          ctclExamDtlsLastNameDisable: false,
          ctclExamDtlsDobStrDisable: false,
          ctclExamDtlsValidDtDisable: false,
          ctclExamDtlsValidDtStrDisable: false
        },
        ctclRqdtlsSubBrokerId: {
          ctclSubAuthDtlsSebiRegNoDisable: false,
          ctclSubAuthDtlsNameDisable: false,
          ctclAuthPerNameDisable: false
        }
      }
    }


    this.userDetails = this.userDetailsService.getUserDetails() || {};
    this.loginId = this.userDetails.loginId;
    this.memId = this.ctclService.memId;

    this.ctclRequestValidationFields = {
      ctclRqdtlsTerminalId: [
        { error: "emptyValue", message: "Please Enter 12-digit Terminal Id." },
        { error: "lengthIssue", message: "Terminal Id should be of 12-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],

      ctclRqdtlsLoginId: [
        { error: "maxlength", message: "Login Id is required." },
      ],
      adFlatNo: [
        { error: "maxlength", message: "Flat/Door is required and should not be more than 50-digits." },
      ],
      adBuildingName: [
        { error: "maxlength", message: "Building Name is required and should not be more than 100-digits." },
      ],
      adFloor: [
        { error: "maxlength", message: "Floor should not be more than 50-digits." }
      ],
      adStreetName: [
        { error: "maxlength", message: "Street/Road Name is required and should not be more than 100-digits." }
      ],
      adLandmark: [
        { error: "maxlength", message: "Landmark should not be more than 100-digits." }
      ],
      adColony: [
        { error: "maxlength", message: "Area/Locality/Colony is required and should not be more than 100-digits." }
      ],
      adPincode: [
        { error: "maxlength", message: "Pin Code is required and should not be more than 6-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclAddStdNo: [
        { error: "maxlength", message: "STD Code is required and should not be more than 4-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclAddPhoneNo: [
        { error: "maxlength", message: "Phone No is required and should not be more than 10-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclExamDtlsExamId: [
        { error: "maxlength", message: "Registration No is required and should not be more than 12-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclRqdtlsFatherFirstName: [
        { error: "maxlength", message: "First Name is required and should not be more than 100-digits." }
      ],
      ctclRqdtlsFatherMiddleName: [
        { error: "maxlength", message: "Middle Name is required and should not be more than 100-digits." }
      ],
      ctclRqdtlsFatherLastName: [
        { error: "maxlength", message: "Last Name is required and should not be more than 100-digits." }
      ],
      ctclExamDtlsFirstName: [
        { error: "maxlength", message: "First Name is required and should not be more than 100-digits." }
      ],
      ctclExamDtlsMiddleName: [
        { error: "maxlength", message: "Middle Name is required and should not be more than 70-digits." }
      ],
      ctclExamDtlsLastName: [
        { error: "maxlength", message: "Last Name is required and should not be more than 70-digits." }
      ],
      ctclRqdtlsPan: [
        { error: "maxlength", message: "PAN No. is required and should not be more than 10-digits." },
        { error: "pattern", message: "Please enter valid PAN" }
      ],
      ctclRqdtlsCellContact: [
        { error: "maxlength", message: "Mobile Number is required and should not be more than 10-digits." },
        { error: "pattern", message: "Should be minimum 10-digits and Only Number allowed." }
      ],
      ctclRqdtlsEmail: [
        { error: "maxlength", message: "Email ID is required and should not be more than 50-digits." },
        { error: "email", message: "Please enter valid Email ID." }
      ],
      ctclAuthPerName: [
        { error: "maxlength", message: "Name should not be more than 50-digits." },
      ],
      examIdPartError1: [
        { error: "maxlength", message: "Field should not be more than 5-digits." },
        { error: "pattern", message: "Only text allowed." }
      ],
      examIdPartError2: [{
        error: "maxlength", message: "Field should not be more than 10-digits."
      }],
      examIdPartError3: [{
        error: "maxlength", message: "Field should not be more than 8-digits."
      }],
      examIdPartError4: [{
        error: "maxlength", message: "Field should not be more than 20-digits."
      }],
      examIdPartError5: [{
        error: "maxlength", message: "Field should not be more than 20-digits."
      }],
      examIdPartError6: [{
        error: "maxlength", message: "Field should not be more than 10-digits."
      }],
      ctclRqdtlsRelationship: [
        {
          error: "required", message: "Field is required."
        }
      ]

    }

    this.ctclRequest = this.fb.group({
      ctclReqId: [null],
      ctclReqType: ["3"],
      ctclReqRefNo: [null],
      ctclReqMemId: [this.ctclService.memId],
      ctclReqStatus: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclReqStatusDisable }),
      ctclReqRemarks: [null],
      ctclReqCrtDt: [null],
      ctclReqUpdDt: [null],
      ctclReqCrtBy: [null],
      ctclReqUpdBy: [null],
      addressDtls: [null],
      fromDate: [null],
      toDate: [null],
      submDateStr: [null],
      reqTypeStr: [null],
      existingAddressDtls: [null],
      memName: [null],
      memCode: [this.ctclService.memCode],
      addressValid: [true],
      ctclRequestDetails: this.fb.group({
        ctclRqdtlsSeq: [null],
        ctclRqdtlsData: [null],
        ctclRqdtlsMarketSegment: [null],
        ctclRqdtlsNeatUserId: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsNeatUserIdDisable }, Validators.required),
        ctclRqdtlsTerminalId: [{ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsTerminalIdDisable }, [Validators.required, this.terminalIdValidation, Validators.pattern('[0-9]*'), Validators.maxLength(12), Validators.minLength(1)]],
        ctclRqdtlsLoginId: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsLoginIdDisable }, [Validators.required]),
        ctclRqdtlsPurposeCtclId: [{ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsPurposeCtclIdDisable }, Validators.required],
        ctclRqdtlsAllotDate: [{ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsAllotDateDisable }, Validators.required],
        ctclRqdtlsMapin: [null],
        ctclRqdtlsPan: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsPanDisable }, [Validators.required, Validators.maxLength(10), Validators.pattern('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$')]),
        ctclRqdtlsRelationship: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsRelationshipDisable }, [Validators.required]),
        ctclRqdtlsFatherFirstName: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsFatherFirstNameDisable }, [Validators.required, Validators.maxLength(100)]),
        ctclRqdtlsFatherMiddleName: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsFatherMiddleNameDisable }, [Validators.maxLength(100)]),
        ctclRqdtlsFatherLastName: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsFatherLastNameDisable }, [Validators.required, Validators.maxLength(100)]),
        ctclRqdtlsCrtDt: [null],
        ctclRqdtlsUpdDt: [null],
        ctclRqdtlsCrtBy: [null],
        ctclRqdtlsUpdBy: [null],
        ctclRqdtlsMemId: [null],
        ctclRqdtlsDisableDate: [null],
        ctclRqdtlsLtrRefNo: [null],
        ctclRqdtlsModificationDate: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsModificationStrDateDisable }, Validators.required),
        ctclRqdtlsCellContact: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsCellContactDisable }, [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]{10}')]),
        ctclRqdtlsEmail: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsEmailDisable }, [Validators.required, Validators.maxLength(50), Validators.email]),
        ctclRqdtlsAllotDateStr: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsAllotDateStrDisable }),
        ctclRqdtlsDisableDateStr: [null],
        ctclRqdtlsModificationDateStr: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsModificationStrDateDisable }, Validators.required),
        ctclRqdtlsMarketSegmentStr: [null],
        fatherFullName: [null],
        ctclRqdtlsDataType: [null],
        existingAddressDtls: this.fb.group({
          adFlatNo: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.existingAddressDtls.adFlatNoDisable }),
          adBuildingName: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.existingAddressDtls.adBuildingNameDisable }),
          adFloor: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.existingAddressDtls.adFloorDisable }),
          cityNm: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.existingAddressDtls.cityNmDisable }),
          stateNm: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.existingAddressDtls.stateNmDisable }),
          adPincode: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.existingAddressDtls.adPincodeDisable })
        }),
        ctclRqdtlsLocationId: this.fb.group({
          ctclLocdtlsLocid: [null],
          ctclLocdtlsAddPar1: [null],
          ctclLocdtlsAddPar2: [null],
          ctclLocdtlsAddPar3: [null],
          ctclLocdtlsAddCity: [null],
          ctclLocdtlsAddPinCode: [null],
          ctclLocdtlsState: [null],
          ctclAddStdNo: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclAddStdNoDisable }, [Validators.required, Validators.maxLength(4), Validators.pattern('[0-9]*')]),
          ctclAddIsdNo: [null],
          ctclAddPhoneNo: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclAddPhoneNoDisable }, [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]*')]),
          ctclLocdtlsConnMode: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclLocdtlsConnModeDisable }, [Validators.required]),
          ctclLocdtlsOfficeStatus: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclLocdtlsOfficeStatusDisable }, [Validators.required]),
          ctclLocdtlsCrtDt: [null],
          ctclLocdtlsUpdDt: [null],
          ctclLocdtlsCrtBy: [null],
          ctclLocdtlsUpdBy: [null],
          addressDtls: this.fb.group({
            adId: [null],
            adCity: new FormControl({ value: 3, disabled: this.ctclRequestDisabledFields.addressDtls.adCityDisable }, [Validators.required]),
            adDistrict: [2],
            adState: new FormControl({ value: 1, disabled: this.ctclRequestDisabledFields.addressDtls.adStateDisable }),
            adFlatNo: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.addressDtls.adFlatNoDisable }, [Validators.required, Validators.maxLength(50)]),
            adBuildingName: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.addressDtls.adBuildingNameDisable }, [Validators.required, Validators.maxLength(100)]),
            adFloor: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.addressDtls.adFloorDisable }, Validators.maxLength(50)),
            adStreetName: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.addressDtls.adStreetNameDisable }, [Validators.required, Validators.maxLength(100)]),
            adLandmark: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.addressDtls.adLandmarkDisable }, Validators.maxLength(100)),
            adColony: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.addressDtls.adColonyDisable }, [Validators.required, Validators.maxLength(100)]),
            adCountry: [null],
            adPincode: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.addressDtls.adPincodeDisable }, [Validators.required, Validators.maxLength(6), Validators.pattern('[0-9]*')]),
            creationDate: [null],
            updationDate: [null],
            createdBy: [null],
            updatedBy: [null],
            isDeleted: [null],
            cityNm: new FormControl({ value: "GANDHINAGAR", disabled: this.ctclRequestDisabledFields.addressDtls.adCityDisable }, Validators.required),
            distNm: ["GANDHINAGAR"],
            stateNm: new FormControl({ value: "GUJARAT", disabled: this.ctclRequestDisabledFields.addressDtls.adStateDisable }),
            cityCd: [3],
            distCd: [2],
            stateCd: [1],
            address: [null],
            cityName: [{ value: "GANDHINAGAR", disabled: this.ctclRequestDisabledFields.addressDtls.cityNameDisable }],
            distName: ["GANDHINAGAR"],
            stateName: [{ value: "GUJARAT", disabled: this.ctclRequestDisabledFields.addressDtls.stateNameDisable }]
          })
        }),
        ctclRqdtlsExamId: this.fb.group({
          ctclExamDtlsSeqNo: [null],
          ctclExamDtlsExamOf: new FormControl({ value: "NISM", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamOfDisable }),
          ctclExamDtlsExamId: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable }),
          ctclExamDtlsValidDt: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsValidDtDisable }),
          ctclExamDtlsTitle: new FormControl({ value: "MR.", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsTitleDisable }),
          ctclExamDtlsFirstName: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsFirstNameDisable }, [Validators.required, Validators.maxLength(100)]),
          ctclExamDtlsMiddleName: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsMiddleNameDisable }, [Validators.maxLength(70)]),
          ctclExamDtlsLastName: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsLastNameDisable }, [Validators.required, Validators.maxLength(70)]),
          ctclExamDtlsDob: new FormControl({ value: "" , disabled:false}, [Validators.required]),
          ctclExamDtlsMarks: [null],
          ctclExamDtlsCrtDt: [null],
          ctclExamDtlsUpdDt: [null],
          ctclExamDtlsCrt_by: [null],
          ctclExamDtlsUpd_by: [null],
          ctclExamDtlsExamType: new FormControl({ value: "NISM", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamTypeDisable }),
          ctclExamDtlsCertificatetype: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsCertificatetypeDisable }),
          ctclExamDate: [null],
          ctclExamDtlsDobStr: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsDobStrDisable }),
          ctclExamDateStr: [""],
          ctclExamDtlsValidDtStr: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsValidDtStrDisable }),
          personFullName: [null],
          ctclExamOf: [null]
        }),
        ctclRqdtlsSubBrokerId: this.fb.group({
          ctclSubAuthDtlsSeqNo: [null],
          ctclSubAuthDtlsSebiRegNo: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSebiRegNoDisable }),
          ctclSubAuthDtlsName: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsNameDisable }),
          ctclAuthPerName: new FormControl({ value: null, disabled: this.ctclRequestDisabledFields.ctclRqdtlsSubBrokerId.ctclAuthPerNameDisable }, [Validators.maxLength(50)]),
        }),
      })
    });

    if (this.displayFromReport === 'YES') {
      this.updateEntireFormdata(this.ctclRequestFromReport);
    }
    else {
      this.ctclIdActivationURL = this.ctclService.ctclIdActivationURL;
      this.fetchModificationDisplayData();

    }
  }

  declarationCertifiedChanged(event) {
    if (event.target.checked)
      this.declarationCertified = true;
    else
      this.declarationCertified = false;
  }
  validyDateCalculation(validityDate: any) {
    if (validityDate === '') {
      this.errorInvalidityDate = false;
      this.validityDateOfApprovedPersonError = "";
    }
    else {
      if (!Date.parse(validityDate)) {
        this.errorInvalidityDate = true;
        this.validityDateOfApprovedPersonError = "Validity Date is inValid.";
        this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt').setValue("");
        this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDtStr').setValue("");
      } else {
        this.validityDateOfApprovedPersonError = "";
        let today = new Date();
        let validityDateValue = new Date(validityDate);
        validityDateValue.setDate(validityDateValue.getDate());
        let differenceInTime = validityDateValue.getTime() - today.getTime();
        let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

        if (differenceInDays < 30) {
          this.errorInvalidityDate = true;
          this.validityDateOfApprovedPersonError = "Validity Date should be greater than 30.";
        }
        else {
          this.errorInvalidityDate = false;
          this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt').setValue(this.ctclService.formatDate(validityDateValue, "yyyy-MM-dd"));
          this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDtStr').setValue(this.ctclService.formatDate(validityDateValue, "dd-MM-yyyy"));

        }
      }
    }
  }

  activationDateCalculation(activationDate: string) {

    this.errorInmodificationDate = true;
    this.errorInactivationDate = true;
    if (!Date.parse(activationDate)) {
      this.activationDateError = "Activation Date is inValid.";
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsAllotDateStr').setValue("");
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsAllotDate').setValue("");
    } else {

      this.activationDateError = "";
      let today = new Date();
      let activationDateValue = new Date(activationDate);
      activationDateValue.setDate(activationDateValue.getDate());
      let differenceInTime = activationDateValue.getTime() - today.getTime();

      let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < -1) {
        this.activationDateError = "Activation Date should not be past Date.";
      }
      else {
        let ctclmodificationDate = new Date(this.reqDtls.get('ctclRqdtlsModificationDate').value);

        let today = new Date();
        let activationDateValue1 = new Date(activationDate);
        activationDateValue1.setDate(activationDateValue1.getDate());
        let differenceInTime1 = ctclmodificationDate.getTime() - activationDateValue1.getTime();

        let differenceInDays1 = Math.floor(differenceInTime1 / (1000 * 3600 * 24));

        if (differenceInDays1 <= 0) {
          this.activationDateError = "Activation Date should be less than Modification Date.";
          this.ctclRequest.setErrors({
            errorInactivationDate: true
          });
        }
        else {
          this.activationDateError = "";
          this.modificationDateError = "";

          this.errorInmodificationDate = false;
          this.errorInactivationDate = false;

          this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsAllotDateStr').setValue(this.ctclService.formatDate(activationDateValue, "dd-MM-yyyy"));
          this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsAllotDate').setValue(this.ctclService.formatDate(activationDateValue, "yyyy-MM-dd"));
        }
      }
    }
  }
  ageCalculation(dateOfBirthValue: any) {

    this.errorInbirthDate = true;

    if (!Date.parse(dateOfBirthValue)) {
      this.dobOfApprovedPersonError = "Date Of Birth Date is inValid.";
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDob').setValue("");
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDobStr').setValue("");
    } else {

      let dateOfBirthValueToFormat = new Date(dateOfBirthValue);
      dateOfBirthValueToFormat.setDate(dateOfBirthValueToFormat.getDate());

      this.dobOfApprovedPersonError = "";
      var dobStr = dateOfBirthValue;
      var dobSubStr = dobStr.split("-");
      var parsedDate = new Date(dobSubStr[2], dobSubStr[1] - 1, dobSubStr[0]);
      var parsedDt = new Date(dobSubStr);
      var birthDate = new Date();

      birthDate = parsedDt;



      var today = new Date();
      var age = today.getFullYear() - birthDate.getFullYear();
      var month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age <= 18) {
        this.dobOfApprovedPersonError = "Age should be greater than 18.";
      }
      else {
        this.errorInbirthDate = false;
        this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDob').setValue(this.ctclService.formatDate(dateOfBirthValueToFormat, "yyyy-MM-dd"));
        this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDobStr').setValue(this.ctclService.formatDate(dateOfBirthValueToFormat, "dd-MM-yyyy"));
      }
    }
  }

  modificationDateCalculation(modificationDate: string) {
    this.errorInmodificationDate = true;
    this.errorInactivationDate = true;
    if (!Date.parse(modificationDate)) {
      this.modificationDateError = "Modification Date is inValid.";
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsModificationDateStr').setValue("");
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsModificationDate').setValue("");
    } else {


      this.modificationDateError = "";
      let today = new Date();
      let modificationDateValue = new Date(modificationDate);
      modificationDateValue.setDate(modificationDateValue.getDate());
      let differenceInTime = modificationDateValue.getTime() - today.getTime();

      let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < -1) {
        this.modificationDateError = "Modification Date should not be past Date.";
      }
      else {
        let ctclActivationdate = new Date(this.reqDtls.get('ctclRqdtlsAllotDate').value);

        let today = new Date();
        let modificationDateValue1 = new Date(modificationDate);
        modificationDateValue1.setDate(modificationDateValue1.getDate());
        let differenceInTime1 = modificationDateValue1.getTime() - ctclActivationdate.getTime();

        let differenceInDays1 = Math.floor(differenceInTime1 / (1000 * 3600 * 24));

        if (differenceInDays1 <= 0) {
          this.modificationDateError = "Modification Date should be greater than Activation Date.";
        }
        else {
          this.activationDateError = "";
          this.modificationDateError = "";
          this.errorInmodificationDate = false;
          this.errorInactivationDate = false;
          this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsModificationDateStr').setValue(this.ctclService.formatDate(modificationDateValue, "dd-MM-yyyy"));
          this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsModificationDate').setValue(this.ctclService.formatDate(modificationDateValue, "yyyy-MM-dd"));
        }
      }

    }
  }
  ctclRequest: FormGroup;
  ctclRequestDisabledFields: any;
  ctclRequestValidationFields: any;


  ctclIdModificationSubmit() {

    const cityDistictStatete = this.ctclAddressList.find((address) => address.cityId == this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId.addressDtls.adCity').value);
    let ctclRequestToSubmit = this.ctclRequest.value;
    ctclRequestToSubmit.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls.adState = cityDistictStatete.stateId;
    ctclRequestToSubmit.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamId = this.ctclExamRegistrationNum;
    //this.ctclActSubmitRequest.ctclRequestModel = this.ctclRequest.value;
    this.ctclActSubmitRequest.ctclRequestModel = ctclRequestToSubmit;
    this.ctclActSubmitRequest.ctclRequestModel.ctclRequestDetails.ctclRqdtlsAllotDateStr = this.reqDtls.get('ctclRqdtlsAllotDateStr').value;
    this.ctclActSubmitRequest.ctclDisplayData = this.ctclModificationInitialData.data;
    this.ctclActSubmitRequest.ctclDisplayData.ctclAddressList = this.ctclAddressList;
    this.ctclService.submitctclActivationRequest(this.ctclActSubmitRequest).subscribe((ctclActivationRes) => {
      if (ctclActivationRes.statusCode === 200) {
        this.toastrSrv.success(ctclActivationRes.message + " and Request ID: " + ctclActivationRes.data);
        this.terminalIdList = [];
        this.resetFormData({});
      }
    })
  }


  fetchNeatUserId() {
    this.httpClient.get(this.ctclIdActivationURL).subscribe((neatUserIdResponse) => {
      // console.log("neatUserIdResponse==>");
      // console.log(neatUserIdResponse);
    }, (neatUserIdResError) => {
      alert("Error While Fetching the data");
    })

  }

  resetFormData(exclusionList: any) {
    let ctclRqdtlsNeatUserIdPrevValue = this.reqDtls.get('ctclRqdtlsNeatUserId').value
    this.ctclRequest.reset();
    if (Object.keys(exclusionList).length !== 0) {
      if (Object.keys(exclusionList).find(data => data === 'ctclRqdtlsNeatUserId') === 'ctclRqdtlsNeatUserId')
        this.reqDtls.patchValue({ ctclRqdtlsNeatUserId: ctclRqdtlsNeatUserIdPrevValue, ctclRqdtlsTerminalId: '', ctclRqdtlsRelationship: '', ctclRqdtlsPurposeCtclId: '' });
    }
    else {
      this.reqDtls.patchValue({ ctclRqdtlsNeatUserId: '', ctclRqdtlsTerminalId: '', ctclRqdtlsRelationship: '', ctclRqdtlsPurposeCtclId: '' });

    }
    this.reqExam.patchValue({ ctclExamDtlsExamOf: 'NISM', ctclExamDtlsExamType: 'NISM', ctclExamDtlsCertificatetype: '' });
    this.reqLocation.patchValue({ ctclLocdtlsOfficeStatus: '', ctclLocdtlsConnMode: '' });
    this.reqAddrDtls.patchValue({ adCity: '' });
    this.ctclRequest.patchValue({
      ctclReqMemId: this.ctclService.memId,
      memCode: this.ctclService.memCode,
      ctclReqType: '3'
    });

    this.reqAddrDtls.patchValue({
      adState: 1,
      stateCd: 1,
      stateNm: "GUJARAT",
      stateName: "GUJARAT",
      adDistrict: 2,
      distCd: 2,
      distNm: "GANDHINAGAR",
      distName: "GANDHINAGAR",
      adCity: 3,
      cityCd: 3,
      cityNm: "GANDHINAGAR",
      cityName: "GANDHINAGAR"
    });


    $('#ctclRqdtlsAllotDate').val('');
    $('#ctclRqdtlsModificationDate').val('');
    $('#ctclExamDtlsValidDt').val('');
    $('#ctclExamDtlsDob').val('');
    this.cdref.detectChanges();
    $('.terminalSelectpicker').selectpicker('refresh');
    $('.neatSelectpicker').selectpicker('refresh');
    $('.terminalOfficeSelectpicker').selectpicker('refresh');
    $('.citySelectpicker').selectpicker('refresh');
    $('.connModePicker').selectpicker('refresh');
    $('#declarationCertified').prop("checked", false);
    $('.ctclExamDtlsCertificatetypePicker').selectpicker('refresh');
    $('.relationShipSelectpicker').selectpicker('refresh');
    $('.neatPurposeSelectpicker').selectpicker('refresh');




  }

  registerCertificateChange(formcontrol: any) {

    if (this.reqExam.get('ctclExamDtlsExamOf').value === 'NISM') {

      this.ctclExamRegistrationNum = this.nismexamIdPart0.nativeElement.value;
      this.reqExam.patchValue({ ctclExamDtlsExamId: formcontrol.value })
    }
    else {

      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamId').patchValue(
        this.examIdPart1 + "/" + this.examIdPart2 + "-" + this.examIdPart3 + "/" + this.examIdPart4 + "/" + this.examIdPart5 + "-" + this.examIdPart6
      )
      this.ctclExamRegistrationNum = this.nismexamIdPart1.nativeElement.value + "/" + this.nismexamIdPart2.nativeElement.value + "-" + this.nismexamIdPart3.nativeElement.value + "/" + this.nismexamIdPart4.nativeElement.value + "/" + this.nismexamIdPart5.nativeElement.value + "-" + this.nismexamIdPart6.nativeElement.value;

      if (formcontrol.id === "examIdPart1") {
        if (formcontrol.value.length > 5) {
          this.examIdPartError1 = "maxlength";
        }
        else {
          this.examIdPartError1 = "";
        }
      }

      if (formcontrol.id === "examIdPart2") {
        if (formcontrol.value.length > 10) {
          this.examIdPartError2 = "maxlength";
        }
        else {
          this.examIdPartError2 = "";
        }
      }

      if (formcontrol.id === "examIdPart3") {
        if (formcontrol.value.length > 8) {
          this.examIdPartError3 = "maxlength";
        }
        else {
          this.examIdPartError3 = "";
        }
      }

      if (formcontrol.id === "examIdPart4") {
        if (formcontrol.value.length > 20) {
          this.examIdPartError4 = "maxlength";
        }
        else {
          this.examIdPartError4 = "";
        }
      }

      if (formcontrol.id === "examIdPart5") {
        if (formcontrol.value.length > 20) {
          this.examIdPartError5 = "maxlength";
        }
        else {
          this.examIdPartError5 = "";
        }
      }

      if (formcontrol.id === "examIdPart6") {
        if (formcontrol.value.length > 10) {
          this.examIdPartError6 = "maxlength";
        }
        else {
          this.examIdPartError6 = "";
        }
      }
    }
  }
  terminalIdValidation(terminalIdControl: FormControl) {
    if ((terminalIdControl.value)?.length == 0) {
      return { emptyValue: true }
    }
    else if ((terminalIdControl.value)?.length < 12) {
      return { lengthIssue: true }
    }
  }


  getNeatDeactivatedateAndTerminalIdExistance() {
    if (this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value === '' || (this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsTerminalId').value).length == 0) {
      // Dont Do Processing, unless Neat User Id selected
    }
    else {
      this.ctclService.getNeatIDDeactivateDate(this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value, this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsTerminalId').value).subscribe((neatIdDeactivationRes) => {
      })

    }
  }


  neatUserIdChange() {

    if (this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value === '') {
      // On Selecting blank Value no API service will be called
      this.terminalIdList = [];
      this.resetFormData({});
      this.cdref.detectChanges();
      $('.terminalSelectpicker').selectpicker('refresh');
    }
    else {

      this.ctclService.loadCtclNeatAndTerminalData(this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value).subscribe((responseData: NeatAndterminalResponse) => {
        if (responseData.status === "OK" && responseData.statusCode === 200) {
          this.terminalIdList = responseData.data.TerminalIds;
          this.reqDtls.patchValue({
            ctclRqdtlsTerminalId: ""
          });
          this.cdref.detectChanges();
          $('.terminalSelectpicker').selectpicker('refresh');
        }

      }, (errorData) => {
        alert("Error occured while processing request:: " + errorData.message)
      }, () => {
      });

    }

  }


  handleError() {
    // console.log("handle error");
  }


  fetchModificationReportDisplayData() {
    this.ctclService.loadCtclDataForActivationReport(this.ctclRequestIdReport).subscribe((responseData: CtclResponseForDeactivation) => {
      if (responseData.status === "OK" && responseData.statusCode === 200) {
        this.updateEntireFormdata(responseData.data);
      }
    }, (errorData) => {
      alert("Error occured while processing request:: " + errorData.message)
    }, () => {
    });

  }

  updateEntireFormdata(responseData: any) {
    this.ctclReqStatus = responseData.ctclReqStatus;
    // $("#ctclRqdtlsAllotDate").datepicker(responseData.ctclRequestDetails.ctclRqdtlsAllotDate);
    this.ctclRequest.patchValue({
      ctclReqId: responseData.ctclReqId,
      ctclReqRefNo: responseData.ctclReqRefNo,
      ctclReqStatus: responseData.ctclReqStatus,
      ctclReqRemarks: responseData.ctclReqRemarks,
      ctclReqCrtDt: responseData.ctclReqCrtDt,
      ctclReqUpdDt: responseData.ctclReqUpdDt,
      ctclReqCrtBy: responseData.ctclReqCrtBy,
      ctclReqUpdBy: responseData.ctclReqUpdBy,
      addressDtls: responseData.addressDtls,
      fromDate: responseData.fromDate,
      toDate: responseData.toDate,
      submDateStr: responseData.submDateStr,
      reqTypeStr: responseData.reqTypeStr,
      existingAddressDtls: responseData.existingAddressDtls,
      memName: responseData.memName,
      addressValid: responseData.addressValid
    })
    this.reqDtls.patchValue({
      ctclRqdtlsSeq: responseData.ctclRequestDetails.ctclRqdtlsSeq,
      ctclRqdtlsData: responseData.ctclRequestDetails.ctclRqdtlsData,
      ctclRqdtlsMarketSegment: responseData.ctclRequestDetails.ctclRqdtlsMarketSegment,
      ctclRqdtlsNeatUserId: responseData.ctclRequestDetails.ctclRqdtlsNeatUserId,
      ctclRqdtlsTerminalId: responseData.ctclRequestDetails.ctclRqdtlsTerminalId,
      ctclRqdtlsLoginId: responseData.ctclRequestDetails.ctclRqdtlsLoginId,
      ctclRqdtlsPurposeCtclId: responseData.ctclRequestDetails.ctclRqdtlsPurposeCtclId,
      //ctclRqdtlsAllotDate: (new Date(responseData.ctclRequestDetails.ctclRqdtlsAllotDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }) + ' ' + new Date(responseData.ctclRequestDetails.ctclRqdtlsAllotDate).toLocaleTimeString(),
      ctclRqdtlsAllotDate: responseData.ctclRequestDetails.ctclRqdtlsAllotDate,
      ctclRqdtlsMapin: responseData.ctclRequestDetails.ctclRqdtlsMapin,
      ctclRqdtlsPan: responseData.ctclRequestDetails.ctclRqdtlsPan,
      ctclRqdtlsRelationship: responseData.ctclRequestDetails.ctclRqdtlsRelationship,
      ctclRqdtlsFatherFirstName: responseData.ctclRequestDetails.ctclRqdtlsFatherFirstName,
      ctclRqdtlsFatherMiddleName: responseData.ctclRequestDetails.ctclRqdtlsFatherMiddleName,
      ctclRqdtlsFatherLastName: responseData.ctclRequestDetails.ctclRqdtlsFatherLastName,
      ctclRqdtlsCrtDt: responseData.ctclRequestDetails.ctclRqdtlsCrtDt,
      ctclRqdtlsUpdDt: responseData.ctclRequestDetails.ctclRqdtlsUpdDt,
      ctclRqdtlsCrtBy: responseData.ctclRequestDetails.ctclRqdtlsCrtBy,
      ctclRqdtlsUpdBy: responseData.ctclRequestDetails.ctclRqdtlsUpdBy,
      ctclRqdtlsMemId: responseData.ctclRequestDetails.ctclRqdtlsMemId,
      ctclRqdtlsDisableDate: responseData.ctclRequestDetails.ctclRqdtlsDisableDate,
      ctclRqdtlsLtrRefNo: responseData.ctclRequestDetails.ctclRqdtlsLtrRefNo,
      ctclRqdtlsModificationDate: responseData.ctclRequestDetails.ctclRqdtlsModificationDate,
      ctclRqdtlsCellContact: responseData.ctclRequestDetails.ctclRqdtlsCellContact,
      ctclRqdtlsEmail: responseData.ctclRequestDetails.ctclRqdtlsEmail,
      //ctclRqdtlsAllotDateStr: responseData.ctclRequestDetails.ctclRqdtlsAllotDateStr,
      ctclRqdtlsAllotDateStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsAllotDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      //ctclRqdtlsDisableDateStr: responseData.ctclRequestDetails.ctclRqdtlsDisableDateStr,
      ctclRqdtlsDisableDateStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsDisableDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      //ctclRqdtlsModificationDateStr: responseData.ctclRequestDetails.ctclRqdtlsModificationDateStr,
      ctclRqdtlsModificationDateStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsModificationDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      ctclRqdtlsMarketSegmentStr: responseData.ctclRequestDetails.ctclRqdtlsMarketSegmentStr,
      fatherFullName: responseData.ctclRequestDetails.fatherFullName,
      ctclRqdtlsDataType: responseData.ctclRequestDetails.ctclRqdtlsDataType
    })

    this.reqLocation.patchValue({
      ctclLocdtlsLocid: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsLocid,
      ctclLocdtlsAddPar1: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPar1,
      ctclLocdtlsAddPar2: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPar2,
      ctclLocdtlsAddPar3: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPar3,
      ctclLocdtlsAddCity: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddCity,
      ctclLocdtlsAddPinCode: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPinCode,
      ctclLocdtlsState: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsState,
      ctclAddStdNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddStdNo,
      ctclAddIsdNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddIsdNo,
      ctclAddPhoneNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddPhoneNo,
      ctclLocdtlsConnMode: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsConnMode,
      ctclLocdtlsOfficeStatus: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsOfficeStatus,
      ctclLocdtlsCrtDt: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsCrtDt,
      ctclLocdtlsUpdDt: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsUpdDt,
      ctclLocdtlsCrtBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsCrtBy,
      ctclLocdtlsUpdBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsUpdBy
    })


    this.reqAddrDtls.patchValue({
      adId: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adId,
      adCity: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adCity,
      adDistrict: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adDistrict,
      adState: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adState,
      adFlatNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adFlatNo,
      adBuildingName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adBuildingName,
      adFloor: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adFloor,
      adStreetName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adStreetName,
      adLandmark: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adLandmark,
      adColony: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adColony,
      adCountry: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adCountry,
      adPincode: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adPincode,
      creationDate: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.creationDate,
      updationDate: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.updationDate,
      createdBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.createdBy,
      updatedBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.updatedBy,
      isDeleted: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.isDeleted,
      cityNm: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.cityNm,
      distNm: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.distNm,
      stateNm: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.stateNm,
      cityCd: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.cityCd,
      distCd: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.distCd,
      stateCd: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.stateCd,
      address: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.address,
      cityName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.cityName,
      distName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.distName,
      stateName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.stateName
    });



    this.reqExam.patchValue({
      ctclExamDtlsSeqNo: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsSeqNo,
      ctclExamDtlsExamOf: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamOf === '' ? "NISM" : responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamOf,
      ctclExamDtlsExamId: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamId,
      //ctclExamDtlsValidDt: (new Date(responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }) + ' ' + new Date(responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt).toLocaleTimeString(),
      ctclExamDtlsValidDt: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt,
      ctclExamDtlsTitle: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsTitle,
      ctclExamDtlsFirstName: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsFirstName,
      ctclExamDtlsMiddleName: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsMiddleName,
      ctclExamDtlsLastName: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsLastName,
      ctclExamDtlsDob: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDob,
      ctclExamDtlsMarks: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsMarks,
      ctclExamDtlsCrtDt: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsCrtDt,
      ctclExamDtlsUpdDt: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsUpdDt,
      ctclExamDtlsCrt_by: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsCrt_by,
      ctclExamDtlsUpd_by: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsUpd_by,
      ctclExamDtlsExamType: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamType === '' ? "NISM" : responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamType,
      ctclExamDtlsCertificatetype: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsCertificatetype,
      ctclExamDate: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDate,
      //ctclExamDtlsDobStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDobStr,
      ctclExamDtlsDobStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDob)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      ctclExamDateStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDateStr,
      //ctclExamDtlsValidDtStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDtStr,
      ctclExamDtlsValidDtStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt !== null ? (new Date(responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }) : responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt,
      personFullName: responseData.ctclRequestDetails.ctclRqdtlsExamId.personFullName,
      ctclExamOf: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamOf
    });

    this.reqSubBroker.patchValue({
      ctclSubAuthDtlsSeqNo: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSeqNo,
      ctclSubAuthDtlsSebiRegNo: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSebiRegNo,
      ctclSubAuthDtlsName: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsName,
      ctclAuthPerName: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclAuthPerName

    })


    if (this.reqExam.get('ctclExamDtlsExamOf').value === 'NISM-CPE') {
      if (this.reqExam.get('ctclExamDtlsExamId').value !== '') {
        this.ctclExamRegistrationNum = this.reqExam.get('ctclExamDtlsExamId').value;
        let splittedExamId: any[] = this.reqExam.get('ctclExamDtlsExamId').value.split('/')
        if (splittedExamId.length == 4) {
          this.nismexamIdPart1.nativeElement.value = splittedExamId[0];
          this.nismexamIdPart4.nativeElement.value = splittedExamId[2];

          let splitExamIdPart2: any[] = splittedExamId[1].split('-')
          if (splitExamIdPart2.length == 2) {

            this.nismexamIdPart2.nativeElement.value = splitExamIdPart2[0];
            this.nismexamIdPart3.nativeElement.value = splitExamIdPart2[1];
          }

          let splitExamIdPart4 = splittedExamId[3].split('-')
          if (splitExamIdPart4.length == 2) {
            this.nismexamIdPart5.nativeElement.value = splitExamIdPart4[0];
            this.nismexamIdPart6.nativeElement.value = splitExamIdPart4[1];
          }
        }
      }
    }
    else {
      this.nismexamIdPart0.nativeElement.value = this.reqExam.get('ctclExamDtlsExamId').value;
      this.ctclExamRegistrationNum = this.reqExam.get('ctclExamDtlsExamId').value;
    }


    this.cdref.detectChanges();
    $('.neatSelectpicker').selectpicker('refresh');
    $('.terminalOfficeSelectpicker').selectpicker('refresh');
    $('.citySelectpicker').selectpicker('refresh');
    $('.connModePicker').selectpicker('refresh');
    $('#declarationCertified').prop("checked", false);
    $('.ctclExamDtlsCertificatetypePicker').selectpicker('refresh');
    $('.relationShipSelectpicker').selectpicker('refresh');
    $('.neatPurposeSelectpicker').selectpicker('refresh');


  }

  terminalIdChange() {

    if (this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsTerminalId').value === '') {
      //this.terminalIdList = [];
      this.resetExclusionList.ctclRqdtlsNeatUserId = true;
      this.resetFormData(this.resetExclusionList);
      // On Selecting blank Value no API service will be called
    }
    else {
      this.ctclService.loadCtclDataForModification(this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value, this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsTerminalId').value).subscribe((responseData: CtclResponseForModification) => {
        if (responseData.status === "OK" && responseData.statusCode === 200) {
          this.updateEntireFormdata(responseData.data);
        }
      }, (errorData) => {
        alert("Error occured while processing request:: " + errorData.message)
      }, () => {
      });

    }
  }


  fetchModificationDisplayData() {

    forkJoin([
      this.ctclService.loadCtclModificationData(),
      this.ctclService.getNeatAndTerminalOffice()
    ]).subscribe(responses => {
      if (responses[0].status === "OK" && responses[0].statusCode === 200 && responses[1].status === "OK" && responses[1].statusCode === 200) {
        this.populateCtclModificationPage(responses[0], responses[1]);
      }
    },
      (responseError) => {
        alert("Error While Processing Data")
      });
  }

  verifyFormEnabled() {
    // console.log(this.ctclRequest);
  }

  populateCtclModificationPage(ctclResponseData: CtclIdModificationResponse, ctclNeatAndTerminalOfficeRes: CtclNeatIdAndTerminalOffice) {

    this.ctclModificationInitialData = ctclResponseData;
    this.neatUserIdList = ctclNeatAndTerminalOfficeRes.data.neatId;
    this.purposeOfCtclIdList = ctclResponseData.data.purposeCtclList.filter((purposeOfCtcl) => purposeOfCtcl.refModelName === "PURPOSE_CTCL_ID");
    this.officeTerminalList = ctclNeatAndTerminalOfficeRes.data.RefMaster.filter((terminalOffice) => terminalOffice.refModelName === "STATUS_CTCL_OFF_COMM");


    this.ctclExamDtlsCertificatetypeList = ctclResponseData.data.ctclExamDtlsCertificatetypeList.filter((certificateType) => certificateType.refModelName === "CTCL_CERTIFICATE_TYPE");
    this.ctclExamDtlsTitleList = ctclResponseData.data.titleList.filter((titles) => titles.refModelName === "TITLE");
    this.ctclLocdtlsConnModeList = ctclResponseData.data.ctclConnModeList.filter((connectionMode) => connectionMode.refModelName === "CTCL_CONN_MODE");
    this.ctclRelationList = ctclResponseData.data.ctclRelationList.filter((relation) => relation.refModelName === "CTCL_RELATION_COMM");
    this.ctclAddressList = ctclResponseData.data.ctclAddressList;
    this.cdref.detectChanges();
    $('.neatSelectpicker').selectpicker('refresh');
    $('.terminalOfficeSelectpicker').selectpicker('refresh');
    $('.neatPurposeSelectpicker').selectpicker('refresh');
    $('.ctclExamDtlsCertificatetypePicker').selectpicker('refresh');
    $('.ctclExamDtlsTitlePicker').selectpicker('refresh');
    $('.connModePicker').selectpicker('refresh');
    $('.relationShipSelectpicker').selectpicker('refresh');
    $('.citySelectpicker').selectpicker('refresh');
  }

  ctclLocdtlsOfficeStatusChange() {
    // console.log()
  }

  cityChange() {


    const cityDistictStatete = this.ctclAddressList.find((address) => address.cityId == this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId.addressDtls.adCity').value);

    const addressDetails = this.reqAddrDtls.get('ctclRequestDetails.ctclRqdtlsLocationId.addressDtls');

    this.reqAddrDtls.patchValue({
      adState: cityDistictStatete.stateId,
      stateCd: cityDistictStatete.stateId,
      stateNm: cityDistictStatete.stateNm,
      stateName: cityDistictStatete.stateNm,

      adDistrict: cityDistictStatete.districtId,
      distCd: cityDistictStatete.districtId,
      distNm: cityDistictStatete.districtNm,
      distName: cityDistictStatete.districtNm,

      adCity: cityDistictStatete.cityId,
      cityCd: cityDistictStatete.cityId,
      cityNm: cityDistictStatete.cityNm,
      cityName: cityDistictStatete.cityNm
    });
  }


  certifiedByChange() {

    if (this.reqExam.get('ctclExamDtlsExamOf').value === 'NISM') {
    }
    // this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId').patchValue({
    //   ctclExamDtlsTitle: "MR.",
    //   ctclExamDtlsFirstName: "",
    //   ctclExamDtlsMiddleName: "",
    //   ctclExamDtlsLastName: "",
    //   ctclExamDtlsDobStr: "",
    //   ctclExamDtlsValidDtStr: "",
    //   ctclRqdtlsPan: "",
    //   ctclRqdtlsCellContact: "",
    //   ctclRqdtlsEmail: ""
    // })

  }

  get existingAddr() {
    return this.ctclRequest.get('ctclRequestDetails.existingAddressDtls');
  }

  get reqLocation() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId');
  }

  get reqAddrDtls() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId.addressDtls');
  }

  get reqExam() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId');
  }

  get reqSubBroker() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsSubBrokerId');
  }

  get reqDtls() {
    return this.ctclRequest.get('ctclRequestDetails');
  }

  ctclApproveRejAction(actionType: string) {

    if (actionType === 'Back') {
      if (this.memberOrAdminReport === 'MEMBER') {
        this.router.navigate(['/eTrade/eMemberReportingPreTrade/displayCtclIdReport'])
      }
      else if (this.memberOrAdminReport === 'ADMIN') {
        this.router.navigate(['/tradeAdmin/eMemberReportingTradeA/displayCtclIdReport'])
      }
    }
    else {
      this.ctclApprovalRejectionReq = {};
      this.ctclApprovalRejectionReq.remarks = this.approveRejectComment.nativeElement.value;
      this.ctclApprovalRejectionReq.status = actionType;
      this.ctclApprovalRejectionReq.refNo = this.ctclRequestIdReport.replace('_', '\\').replace('_', '\\');

      this.ctclService.approvalRejectionOfCtcl(this.ctclApprovalRejectionReq).subscribe(responseData => {
        if (responseData.statusCode === 200) {
          this.ctclRequestStatusDetails.nativeElement.value = actionType;
          this.ctclReqStatus = actionType;
          this.reqDtls.patchValue({ ctclReqStatus: actionType, ctclReqRemarks: this.approveRejectComment.nativeElement.value });
          this.toastrSrv.success("Request " + this.ctclApprovalRejectionReq.refNo + " " + actionType + " successfully");
        }
      })
      // console.log(this.approveRejectComment);
      // console.log("Button Clicked::" + actionType);
    }
  }
  verifyOtpComponentCall() {
    this.verifyOtpComponent.initializeComponent("CTCLModify", 4, this.loginId, this.memId);
  }

}