<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Request For Disable User ID</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/DisableNeatUserIdAdminComponent"
                            aria-current="page">Request For Disable User ID</a></li>
                    <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUseridRequest/displayUIRequestProcessPage/DisableNeatUserIdAdminComponent"
                                aria-current="page">Request For Disable User ID</a></li>
                </ul>
            </div>

        </div>
    </div>
    <div>
        <form [formGroup]="DisableNeatUserIdAdmin" >
                <div class="card card-body col-lg-12 mt-3 colorborder"> 
                    <div class="row">                                                     
                        <div class="col-lg-3 form-group">   
                                <!-- <label>Member Code</label>
                                <input type="text" class="form-control" readonly
                                 formControlName="memCode"> -->
                                 <label>Member Code</label>
                                 <input type="text" class="form-control" readonly formControlName="memCode">
                        </div>
                            <div class="col-lg-3 form-group">                            
                            <label>Member Name</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="memberName">

                            </div>
                            <div class="col-lg-3 form-group">
                            
                                
                                <label>User Id</label>
                                <input type="text" class="form-control" readonly 
                                 formControlName="userId">

                            </div>
                            <div class="col-lg-3 form-group">                        
                                <label>User Name</label>
                                <input type="text" class="form-control"  readonly 
                                 formControlName="Username" >
                            </div>
                    </div> 
                    <div class="row">
                                                     
                        <div class="col-lg-3 form-group">
                          
                            
                            <label>Type Of User</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="typeOfUser">

                        </div>
                        <div class="col-lg-3 form-group">
                               
                            <label>Reason for disabling the User ID</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="reasonOfdisable">

                    
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Tap Ip</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="tapIp">
                        </div>
                        <div class="col-lg-3 form-group">                               
                            <label>Tap Id</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="tapId">                    
                        </div>
                        <div class="col-lg-3 form-group">                               
                            <label>No of existing terminals at the above equipment ID</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="existingCertificationDetails" >                    
                        </div>
                        <div class="col-lg-3 form-group">                               
                            <label>Existing TAP/Child IP Address</label>
                            <input type="text" class="form-control" readonly 
                             formControlName="oldAddress">                    
                        </div>                     
                    </div>                     
                     <!-- if data is submitted then load this div -->
                     <div class="" *ngIf="entityType!='ENIT'">
                     <div *ngIf="reqObj.cusReqStatus=='APPR' || reqObj.cusReqStatus=='RJCT'" class="">
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Current Status </label>
                                <input *ngIf="reqObj.cusReqStatus=='APPR'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="APPROVED">
                                <input *ngIf="reqObj.cusReqStatus=='RJCT'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="REJECTED">
                                <input *ngIf="reqObj.cusReqStatus=='UNDER_PROC'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="UNDER PROCESSING">
                                <input *ngIf="reqObj.cusReqStatus=='DOC_AWAT'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="DOCUMENT AWAITED">
                                <input *ngIf="reqObj.cusReqStatus=='DOC_RECVD'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="DOCUMENT RECEIVED">
                                <input *ngIf="reqObj.cusReqStatus=='SUB'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="SUBMITTED">

                                
                            </div>
                            <div class="col-lg-3 form-group">
                                <label for="">Remarks  </label>
                                <input type="text" class="form-control" readonly formControlName="remark">
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    


                     <!-- if data is submitted then load this div -->
                     <div *ngIf="reqObj.cusReqStatus!='APPR' && reqObj.cusReqStatus!='RJCT'"  class="">
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Current Status  </label>
                                <input   formControlName="CurrentStatus" type="text" class="form-control" readonly value="SUBMITTED">
                               
                            </div>
                            <div class="col-lg-3 form-group">
                                <label>Select Status</label>
                 
                                <select class="form-control form-control-sm" formControlName="UpdatedStatus" title="Select Status" >
                                    <option value="" selected hidden>SELECT STATUS</option>
                                    <option value="APPR">APPROVED</option>
                                    <option value="RJCT">REJECTED</option>
                                    <option value="UNDER_PROC">UNDER PROCESSING</option>
                                    <option value="DOC_AWAT">DOCUMENT AWAITED</option>
                                    <option value="DOC_RECVD">DOCUMENT RECEIVED</option>
                                </select>
                                <!-- <select class="form-control form-control-sm">
                                    <option value=".*" selected>Select Status</option>
                                    <option value=".*Active.*">Active</option>
                                    <option value=".*Deactive.*">Deactive</option>
                                </select> -->
                            </div>
                            <div class="col-lg-3 form-group">
                                <label for="">Remarks  </label>
                                <input type="text" class="form-control" formControlName="remark">
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-lg-3 form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button class="btn  btn-primary full-width" type="submit" (click) = "onSubmit()" [disabled]="DisableNeatUserIdAdmin.invalid" >Submit</button>
                                </div>
                                <div class="col-lg-6">
                                    <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                                </div>
                                
                            </div>
                            </div>
                        </div>

                        <!-- <div class="row"> ADD THIS ABOVE TO CHANGE BUTTONS VIEW
                            <div class="col-lg-4">
                                <div class="row">
                                    
                                <div class="col-lg-3 form-group">
                                    <button class="btn  btn-primary full-width" type="submit" (click) ="onSubmit()">Submit</button>
                                </div>
                                <div class="col-lg-3 form-group">
                                    <button class="btn  btn-cancel full-width" type="button" (click)="reset()">Reset</button>
                                </div>
                                <div class="col-lg-3 form-group">
                                    <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                                </div>
                               
                            </div>
                            </div>
                            </div> -->
                    </div>      
                </div>    
                
                <div class="row"  *ngIf="entityType=='ENIT'">
                    <div class="col-lg-3 form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                        </div>
                        
                    </div>
                    </div>
                </div>
                
                </div>   
        </form>
   
    </div>

</div>
