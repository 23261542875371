import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from 'src/app/valueObject/config';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivateCelUserService } from 'src/app/service/activate-cel-user.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $: any;

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent implements OnInit, AfterViewInit, OnDestroy {

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  config: any = config;
  user: any = {};
  access: any = {};
  message: any;
  users: any = [];
  celActivationData: any = [];
  selectedUser: any = {};
  menucode = 'celActivate';
  monitorEnable: FormGroup;
  userControlId: string = '';
  enableStatus: string = 'Y';

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(
    private fb: FormBuilder,
    private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private celActivateService: ActivateCelUserService) { }

  get f() {
    return this.monitorEnable.controls;
  }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - User Activation");
    this.userDetailsService.getUserDetails();
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
  }

  ngAfterViewInit() {
    this.getCelActivationData();
  }

  enableId(userId, status) {
    this.selectedUser = this.celActivationData.find(data => data.userId == userId);
    this.enableStatus = status;
  }

  enableUser() {
    this.selectedUser.status = this.enableStatus;
    this.celActivateService.activateCelMonitoring(this.selectedUser.userId, this.selectedUser.status).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.message = response.data[0];
        this.toastr.success("CEL User " + ((this.enableStatus == 'Y') ? 'Activated' : 'Suspended'));
        this.getCelActivationData();
      } else {
        this.message = "Unable to change user status";
        this.toastr.error(this.message);
      }
    });
  }

  getCelActivationData() {
    this.celActivateService.getCelActivation(this.userControlId).pipe(
      catchError((error: any) => {
        this.celActivationData = [];
        this.setDatatable();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.celActivationData = response.data[0];
        this.celActivateService.addCelActivationData(this.celActivationData);
        this.setDatatable();
      } else {
        this.celActivationData = [];
        this.setDatatable();
        this.toastr.error(response.message);
      }
    });
  }

  setDatatable() {
    $("#celActivateTable").DataTable().destroy();
    setTimeout(() => {
      $("#celActivateTable").DataTable({
        orderCellsTop: true,
        responsive: true,
        dom: 'Blrtip',
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10, 25, 50, 100],
        processing: true,
        buttons: [{
          extend: 'copy',
          footer: true,
          titleAttr: 'Copy',
          exportOptions: {
            titleAttr: 'Copy',
          }
        }, {
          extend: 'excel',
          footer: true,
          titleAttr: 'Export to Excel',
          exportOptions: {
            titleAttr: 'Export to excel',
          }
        }, {
          extend: 'pdf',
          footer: true,
          titleAttr: 'Download as PDF',
          exportOptions: {
            titleAttr: 'Download as PDF',
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#celActivateTable .column-search input').val('');
            $('#celActivateTable .column-search select').val('.*');
            $('#celActivateTable .column-search').on('keyup change', 'input', function () {
              $('#celActivateTable').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#celActivateTable .column-search').on('keyup change', 'select', function () {
              $('#celActivateTable').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  ngOnDestroy() {
    this.dtTrigger?.unsubscribe();
  }

}
