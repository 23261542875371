import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuGuard } from 'src/app/service/menu.guard';
import { SubmenuGuard } from 'src/app/service/submenu.guard';
import { VerfiyRequestByOtpComponent } from './EnitTrade/verfiy-request-by-otp/verfiy-request-by-otp.component';
import { MlAiRequestComponent } from './EnitCompliance/ml-ai-request/ml-ai-request.component';
import { MlAndAiComponentListComponent} from './EnitCompliance/ml-and-ai-component-list/ml-and-ai-component-list.component';
import { MlAiModalComponent } from './EnitCompliance/ml-ai-modal/ml-ai-modal.component'
import { QuaterlyIncidentReportComponent } from './EnitCompliance/quaterly-incident-report/quaterly-incident-report.component';
import { CyberSecurityMemberListComponent } from './EnitCompliance/cyber-security-member-list/cyber-security-member-list.component';
import { AdminCyberSecurityReportComponent } from './EnitCompliance/admin-cyber-security-report/admin-cyber-security-report.component';
import {CyberSecurityMemberreportUploadComponent} from './EnitCompliance/cyber-security-memberreport-upload/cyber-security-memberreport-upload.component';
import { HalfYearlyFormSubmissionComponent } from './EnitCompliance/half-yearly/half-yearly-form-submission/half-yearly-form-submission.component';
import { HalfYearlyFormSubmissionHistoryComponent } from './EnitCompliance/half-yearly/half-yearly-form-submission-history/half-yearly-form-submission-history.component';

const routes: Routes = [
  {
    path: 'verifyOtp',
    children: [{ path: '', component: VerfiyRequestByOtpComponent },]
  },
  //ML AI related routing here: 
  {
    path: 'mlAndAi',canDeactivate: [SubmenuGuard],
    children: [
      { 
        path: 'mlal', component: MlAiRequestComponent 
      },
      { 
        path: 'mlaireport',canDeactivate: [SubmenuGuard],
        children: [
          { path: '', component: MlAndAiComponentListComponent },
          { path: 'mlAiForm', component: MlAiModalComponent }
        ]
      },
    ]
  },
// Quaterly Report:
{
  path: 'incidentReport',canDeactivate: [SubmenuGuard],
  children: [
    {
      path: 'incidentQuaterlyReptSubm', component: QuaterlyIncidentReportComponent,
    },
    {
      path: 'cyberSecurityReport',canDeactivate: [SubmenuGuard],
      children: [
        { path: '', component: CyberSecurityMemberListComponent },
        { path: 'cyberSecurityReportMember', component: AdminCyberSecurityReportComponent }
      ]
    },
    {
      path: 'memberReportUpload', component: CyberSecurityMemberreportUploadComponent,
    }
  ]
},

{
  path: 'halfYearlyReport', canDeactivate: [SubmenuGuard],
  children: [
      {path: 'halfYearlyFormSubmission',component: HalfYearlyFormSubmissionComponent},
      {path: 'halfYearlyFormHistory',component: HalfYearlyFormSubmissionHistoryComponent}
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MenuGuard, SubmenuGuard],
})
export class EnitComplainceRoutingModule { }