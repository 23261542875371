<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Role Management</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a href="">Home</a></li>
                    <li class="active"><a href="/role" aria-current="page">Role Management</a></li>
                </ul>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" data-toggle="modal" data-target="#rolepopup">Add Role</button>
            </div>
        </div>
    </div>
    <div class="">
        <div class="row clearSearch">
            <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <table id="roleList" class="table common-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th class="pre-wrap">Role Type</th>
                    <th class="pre-wrap">Role Name</th>
                    <th class="pre-wrap">Role Code</th>
                    <th class="pre-wrap">Menu List</th>
                    <th class="pre-wrap">Edit</th>
                </tr>
                <tr class="column-search">
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let role of roleData">
                    <td>{{role.roleType}}</td>
                    <td>{{role.roleName}}</td>
                    <td>{{role.roleCode}}</td>
                    <td>
                        <ul>
                            <li *ngFor="let menu of role.menuData">{{menu.menuName}}</li>
                        </ul>
                    </td>
                    <td><i class="fa fa-pencil" aria-hidden="true" (click)="editRole(role.roleId)"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="rolepopup" tabindex="-1" role="dialog" aria-labelledby="userpopupLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <form [formGroup]='roleForm' (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="form-group">
                        <label>Role type<span class="red">*</span> </label>
                        <select class="form-control selectpicker" formControlName="roleType" data-style="btn-info">
                            <option value='' selected>Select role type</option>
                            <option value='user'>User</option>
                            <option value='entity'>Entity</option>
                        </select>
                        <p *ngIf="f.roleType.invalid && f.roleType.touched" class="error nocase">
                            Please select role type
                        </p>
                    </div>
                    <div class="form-group">
                        <label>Role name<span class="red">*</span> </label>
                        <input type="hidden" class="form-control" formControlName="roleId">
                        <input type="text" class="form-control" formControlName="roleName"
                            placeholder="Enter role name">
                        <div *ngIf="f.roleName.invalid && f.roleName.touched">
                            <p class="error nocase">
                                Please enter role name
                            </p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Role code<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="roleCode"
                            placeholder="Enter role code">
                        <div *ngIf="f.roleCode.invalid && f.roleCode.touched">
                            <p class="error nocase">
                                Please enter role code
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btn-group d-flex" role="group" aria-label="Basic example">
                    <button type="Submit" class="btn btn-primary col-6" data-dismiss="modal" aria-label="Close"><svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill=""
                            class="whiteColor bi bi-person-check-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg> Submit </button>
                    <button type="button" class="btn btn-secondary close col-6" data-dismiss="modal"
                        aria-label=" Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor"
                            class="bi bi-x" viewBox="0 0 15 15">
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg> Cancel </button>
                </div>
            </form>
        </div>
    </div>
</div>