import { ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent {
  verificationId:string;
  message:string="Verifying Email ID...";
  constructor(private loginService: LoginService,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private cdref: ChangeDetectorRef) {
      this.route.queryParams.subscribe(params => {
        if (params['id']) {
          this.verificationId = params['id'];
        }
      });
     }

  ngOnInit(): void {
    this.titleService.setTitle("Verify Email");
    this.verifyEmail();
  }

  verifyEmail(){
    this.spinner.show();
    this.loginService.verifyEmail(this.verificationId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.message="Sorry, we are unable to verify your email ID.";
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.spinner.hide();
          this.message="Congratulations, your email ID has been verified.";
          this.cdref.detectChanges();
        } else {
          this.spinner.hide();
          this.message=response.message;
          this.cdref.detectChanges();
        }
      }
    );
  }
}
