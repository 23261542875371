import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserControlService {

  private userControlId: string = '';
  private userControlDetails: any = {};

  constructor() { }

  setUserControlId(userControlId) {
    this.userControlId = userControlId;
  }

  getUserControlId() {
    if (this.userControlId) {
      return this.userControlId;
    } else {
      return null;
    }

  }

  setUserControlDetails(userControlDetails) {
    this.userControlDetails = userControlDetails;
  }

  getUserControlDetails() {
    return this.userControlDetails;
  }

  checkUserAccess(menuCode: string) {
    if (this.userControlDetails) {
      let menu = this.userControlDetails.menusList.filter(menu => (menu.menuCode == menuCode));
      if (menu.length == 0) {
        this.userControlDetails.menusList.forEach(element => {
          if (element.userMenusImpls) {
            let menu1 = element.userMenusImpls.filter(submenu => (submenu.menuCode == menuCode));
            menu.push(...menu1);
            element.userMenusImpls.forEach(element1 => {
              if (element1.userMenusImpls) {
                let menu1 = element1.userMenusImpls.filter(submenu => (submenu.menuCode == menuCode));
                menu.push(...menu1);
              }
            })
          }
        });
      }
      return menu[0];
    } else {
      return null;
    }
  }
}
