import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CyberSecurityService } from 'src/app/service/cyber-security.service';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
import { DatePipe } from '@angular/common';
import { incidentType } from 'src/app/component/enit/EnitCompliance/quaterly-incident-report/incidentType';
import { incidentSymptoms } from 'src/app/component/enit/EnitCompliance/quaterly-incident-report/incidentSymptoms';
import { ActivatedRoute, Route, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-admin-cyber-security-report',
  templateUrl: './admin-cyber-security-report.component.html',
  styleUrls: ['./admin-cyber-security-report.component.css'],
})
export class AdminCyberSecurityReportComponent implements OnInit {
  readOnlyData: any;
  memCode: number;
  memId: any;
  memName: string;
  entityType: any;
  user: any = {};
  memberData: any = {};
  config: any = config;
  isReadOnly: boolean = true;
  nillSubmission: string;
  nill: string;
  quaterlyForm: FormGroup;
  checkBoxForm: FormGroup;
  quarterDetails: any = [];
  enableData: any;
  enable: boolean;
  formArray1: any = [];
  formArray2: any = [];
  formArray3: any = [];
  formArray4: any = [];
  formArray5: any = [];
  quarterList: any;
  eligibleQuarter: any;
  eligibleYear: any;
  year: any;
  cutOffDate: any;
  isSubmitted: any;
  incidentType: any = incidentType;
  incidentSymptoms: any = incidentSymptoms;
  incidentTypeCheckBox: string[];
  incidentSymptomsCheckBox: string[];
  incidentId:any;
  get f() {
    return this.quaterlyForm.controls;
  }

  get formBriefInfo() {
    return this.quaterlyForm.get("briefInfoTable") as FormArray;
  }
  get formDateTimeInfo() {
    return this.quaterlyForm.get("dateAndTimeTable") as FormArray;
  }
  get formInfoAffected() {
    return this.quaterlyForm.get("infoAffectedSystemTable") as FormArray;
  }
  get formInfoAgency() {
    return this.quaterlyForm.get("infoAgencyNotifyTable") as FormArray;
  }
  get formIpSuspect() {
    return this.quaterlyForm.get("ipSuspectedSourceTable") as FormArray;
  }

  constructor(private formBuilder: FormBuilder,
    private cyberSecurityService: CyberSecurityService,
    private toastr: ToastrService,
    private mlAiService: EnitComplianceServiceService,
    private enitComplianceServiceService: EnitComplianceServiceService,
    private userDetailsService: UserDetailsService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private router : Router) { 
      this.route.queryParams.subscribe(params => {
        if (params['id']) {
          this.readOnlyData = this.enitComplianceServiceService.getaddCyberSecurityByRef(params['id']);
        }
      });
    }

  hasOtherIncident: boolean = false;
  selectedIncidentNames: [string];
  selectedBehaviourNames: [string];
  hasOtherBehaviour: boolean = false;

  ngOnInit(): void {

    this.user = this.userDetailsService.getUserDetails() || {};
    this.memCode = (<number>this.readOnlyData.memberId*1+10000);
    this.memId = (<number>this.user.entityCode - 10000) + "";
    this.entityType = this.user.entityType;
    this.isReadOnly = true;

    this.quaterlyForm = this.formBuilder.group({
      memberName: [this.readOnlyData.memberName],
      stockExchangeName: ['NSE IFSC Limited', [Validators.required]],
      // memberId: [this.readOnlyData.memberId*1+10000],
      memberId: [this.memCode],
      quaterName: [this.readOnlyData.quaterName +'-'+'('+this.readOnlyData.year+')'],
      cyberAttackObserved: [this.readOnlyData.cyberAttackObserved],
      nillSubmission: [this.readOnlyData.nillSubmission],
      rptOfficerName: [this.readOnlyData.rptOfficerName],
      rptOfficerOrgName: [this.readOnlyData.rptOfficerOrgName],
      rptOfficerTitle: [this.readOnlyData.rptOfficerTitle],
      rptOfficerFaxPhoneNum: [this.readOnlyData.rptOfficerFaxPhoneNum],
      rptOfficerMobileNum: [this.readOnlyData.rptOfficerMobileNum],
      rptOfficerEmail: [this.readOnlyData.rptOfficerEmail],
      rptOfficerAddress: [this.readOnlyData.rptOfficerAddress],
      affectedSystemPhysicalLocation: [this.readOnlyData.affectedSystemPhysicalLocation],
      incidentType: [],
      incidentDescr: [this.readOnlyData.incidentDescr],
      incidentOtherDescr: [this.readOnlyData.incidentOtherDescr],
      incidentSymptoms: [],
      incidentSymptomsDescr:  [this.readOnlyData.incidentSymptomsDescr],
      incidentSymptomsOtherDescr:  [this.readOnlyData.incidentSymptomsOtherDescr],
      problemExprEarlier: [this.readOnlyData.problemExprEarlier],
      problemExprEarlierDescr: [this.readOnlyData.problemExprEarlierDescr],
      problemExperince: [this.readOnlyData.problemExperince],
      agencyNotified: [this.readOnlyData.agencyNotified],
      numberOfHostAffected: [this.readOnlyData.numberOfHostAffected],
      preventiveMeasureApplied: [this.readOnlyData.preventiveMeasureApplied],
      preventiveMeasureAppliedDescr: [this.readOnlyData.preventiveMeasureAppliedDescr],
      briefInfoTable: this.formBuilder.array([]),
      dateAndTimeTable: this.formBuilder.array([]),
      infoAffectedSystemTable: this.formBuilder.array([]),
      infoAgencyNotifyTable: this.formBuilder.array([]),
      ipSuspectedSourceTable: this.formBuilder.array([]),
      incidentForm: this.formBuilder.array([]),
      behaviourForm: this.formBuilder.array([])
    })
    this.createFormInputs();
    this.selectedIncidentNames = this.readOnlyData.incidentType?.split("|");
    this.selectedBehaviourNames = this.readOnlyData.incidentSymptoms?.split("|");
    this.selectOtherIncident();
    this.selectOtherBehaviour();
    this.quaterlyForm.controls.incidentForm = this.createIncidents(this.incidentType);
    this.quaterlyForm.controls.behaviourForm = this.createBehaviours(this.incidentSymptoms);
    if(this.readOnlyData.cyberAttackObserved == 'Y'){

      this.f.affectedSystemPhysicalLocation.setValue(this.readOnlyData.affectedSystemPhysicalLocation);
      this.f.incidentDescr.setValue(this.readOnlyData.incidentDescr)
      this.f.incidentSymptomsDescr.setValue(this.readOnlyData.incidentSymptomsDescr)
      this.f.problemExprEarlier.setValue(this.readOnlyData.problemExprEarlier)
      this.f.numberOfHostAffected.setValue(this.readOnlyData.numberOfHostAffected)
      this.f.preventiveMeasureApplied.setValue(this.readOnlyData.preventiveMeasureApplied)

      
    if (this.f.problemExprEarlier.value == 'Y') {
      this.f.problemExprEarlierDescr.setValue(this.readOnlyData.problemExprEarlierDescr) 
    }

      this.formArray1 = this.readOnlyData.incidentBriefInfor
      const add = this.quaterlyForm.controls.briefInfoTable as FormArray;
      if (this.formArray1.length > 0) {
        this.formArray1.forEach((Details: any) => {
          add.push(this.formBuilder.group({
            incidentDate: [this.datePipe.transform(Details.incidentDate, 'MM/dd/yyyy')],
            incidentTime: [Details.incidentTime],
            cyberAttackBriefInfo: [Details.cyberAttackBriefInfo]
          }))
        })
        this.quaterlyForm.controls.briefInfoTable = add;
      }
     


      this.formArray2 = this.readOnlyData.incidentDateTime
      const add2 = this.quaterlyForm.controls.dateAndTimeTable as FormArray;
      this.formArray2.forEach((Details: any) => {
        add2.push(this.formBuilder.group({
          incidentDate: [this.datePipe.transform(Details.incidentDate, 'MM/dd/yyyy')],
          incidentTime: [Details.incidentTime]
        }))
      })
      this.quaterlyForm.controls.dateAndTimeTable = add2;


      this.formArray3 = this.readOnlyData.incidentAffectSystmInfo
          const add3 = this.quaterlyForm.controls.infoAffectedSystemTable as FormArray;
          this.formArray3.forEach((Details: any) => {
            add3.push(this.formBuilder.group({
              ipAddress: [Details.ipAddress],
              hostName: [Details.hostName],
              operatingSystem: [Details.operatingSystem],
              lastPatched: [Details.lastPatched],
              hardwareModel: [Details.hardwareModel]
            }))
          })
          this.quaterlyForm.controls.infoAffectedSystemTable = add3;

          if (this.f.agencyNotified.value == 'Y') {
            this.formArray4 = this.readOnlyData.incidentAgencyInfo
            const add4 = this.quaterlyForm.controls.infoAgencyNotifyTable as FormArray;
            this.formArray4.forEach((Details: any) => {
              add4.push(this.formBuilder.group({
                lawEnforcement: [Details.lawEnforcement],
                privateAgency: [Details.privateAgency],
                affectedProductVendor: [Details.affectedProductVendor],
                otherAgency: [Details.otherAgency]
              }))
            })
            this.quaterlyForm.controls.infoAgencyNotifyTable = add4;
      }

      this.formArray5 = this.readOnlyData.incidentSuspectSourceInfo
          const add5 = this.quaterlyForm.controls.ipSuspectedSourceTable as FormArray;
          this.formArray5.forEach((Details: any) => {
            add5.push(this.formBuilder.group({
              sourceIp: [Details.sourceIp],
              otherInfo: [Details.otherInfo]
            }))
          })
          this.quaterlyForm.controls.ipSuspectedSourceTable = add5;
         
    }
        
  }

  initBriefInfoRows() {
    return this.formBuilder.group({
      incidentDate: [],
      incidentTime: [],
      cyberAttackBriefInfo: []
    });
  }
  initDateAndTimeRows() {
    return this.formBuilder.group({
      incidentDate: [],
      incidentTime: [],
    })
  }
  initInfoAffectedSystemRows() {
    return this.formBuilder.group({
      ipAddress: [],
      hostName: [],
      operatingSystem: [],
      lastPatched: [],
      hardwareModel: []
    })
  }
  initInfoAgencyNotifyRows() {
    return this.formBuilder.group({
      lawEnforcement: [],
      privateAgency: [],
      affectedProductVendor: [],
      otherAgency: []
    })
  }
  initIpSuspectedSourceRows() {
    return this.formBuilder.group({
      sourceIp: [],
      otherInfo: []
    })
  }

  createFormInputs() {
    // this.checkBoxForm = new FormGroup({
    //   incidents: this.createIncidents(this.incidentType),
    //   behaviours: this.createBehaviours(this.incidentSymptoms)
    // });
    this.getSelectedIncidents();
    this.getSelectedBehaviours();
  }

  createIncidents(incidentsInput) {
    // const arr = incidentsInput.map(incident => {
    //   return new FormControl(incident.selected || false);
    // });
    // return new FormArray(arr);
    const arr = incidentsInput.map(incident => {
      let inc = this.selectedIncidentNames?.find(data => data == incident.value);
      if (inc)
        incident.selected = true;
      return new FormControl(incident.selected || false);
    });
    return new FormArray(arr);
  }

  getSelectedIncidents() {
    this.selectedIncidentNames = _.map(
      this.quaterlyForm.controls.incidentForm["controls"],
      (incident, i) => {
        return incident.value && this.incidentType[i].value;
      }
    );
    this.getSelectedIncidentName();
  }

  getSelectedIncidentName() {
    this.selectedIncidentNames = _.filter(
      this.selectedIncidentNames,
      function (incident) {
        if (incident !== false) {
          return incident;
        }
      }
    );
  }

  getSelectedIncidentValues(incident: any) {
    if (this.incidentTypeCheckBox.some(x => x === incident)) {
      return true;
    }
    else {
      return false;
    }
  }


  createBehaviours(behavoiursInput) {
    // const arr = behavoiursInput.map(behaviour => {
    //   return new FormControl(behaviour.selected || false);
    // });
    // return new FormArray(arr);
    const arr = behavoiursInput.map(behaviour => {
      let inc = this.selectedBehaviourNames?.find(data => data == behaviour.value);
      if (inc)
        behaviour.selected = true;
      return new FormControl(behaviour.selected || false);
    });
    return new FormArray(arr);
  }

  getSelectedBehaviours() {
    this.selectedBehaviourNames = _.map(
      this.quaterlyForm.controls.behaviourForm["controls"],
      (behaviour, i) => {
        return behaviour.value && this.incidentSymptoms[i].value;
      }
    );
    this.getSelectedBehavioursName();
  }

  getSelectedBehavioursName() {
    this.selectedBehaviourNames = _.filter(
      this.selectedBehaviourNames,
      function (behaviour) {
        if (behaviour !== false) {
          return behaviour;
        }
      }
    );
  }

  getSelectedBehaviourValues(symptoms: any) {
    if (this.incidentSymptomsCheckBox.some(x => x === symptoms)) {
      return true;
    }
    else {
      return false;
    }
  }


  selectOtherIncident() {
    this.hasOtherIncident = this.selectedIncidentNames.some(x => x === "OTHER");
  }
  selectOtherBehaviour() {
    this.hasOtherBehaviour = this.selectedBehaviourNames.some(x => x === "OTHER");
  }
  addIncidentBriefInfo() {
    this.formBriefInfo.push(this.initBriefInfoRows());
  }
  deleteIncidentBriefInfo(index) {
    this.formBriefInfo.removeAt(index);
  }
  addInfoAffectedSystem() {
    this.formInfoAffected.push(this.initInfoAffectedSystemRows());
  }
  deleteInfoAffectedSystem(index) {
    this.formInfoAffected.removeAt(index);
  }
  addDate() {
    this.formDateTimeInfo.push(this.initDateAndTimeRows());
  }
  deleteDate(index) {
    this.formDateTimeInfo.removeAt(index);
  }
  addAgencyNotifiedSytem() {
    this.formInfoAgency.push(this.initInfoAgencyNotifyRows());
  }
  deleteAgencyNotifiedSytem(index) {
    this.formInfoAgency.removeAt(index);
  }
  addIncidentSuspectSource() {
    this.formIpSuspect.push(this.initIpSuspectedSourceRows());
  }
  deleteIncidentSuspectSource(index) {
    this.formIpSuspect.removeAt(index);
  }
  back(){
    if(this.entityType == 'ENIT'){
      this.router.navigate(['eCompliance/incidentReport/cyberSecurityReport'])
    }
    else if(this.entityType =='ADMIN'){
      this.router.navigate(['complianceAdmin/cyberSecurityAdmin/cyberSecurityList'])
    }
  }

 


  onNillSubmission(event) {
    this.nillSubmission = event.target.checked;
    if (this.nillSubmission) {
      this.nill = 'Y';
    }
    else {
      this.nill = 'N';
    }
  }
}