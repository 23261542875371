import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from 'src/app/service/app.service';
import { RoleService } from 'src/app/service/role.service';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {

  config: any = config;
  roleData: any = [];
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  viewUser: any = {};
  roleForm: FormGroup;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;


  constructor(private appService: AppService,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.titleService.setTitle('Role Management');
    this.roleForm = this.formBuilder.group({
      roleId: [null, Validators.required],
      roleType: ['', Validators.required],
      roleName: ['', Validators.required],
      roleCode: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.getRoleData();
  }

  get f() { return this.roleForm.controls; }

  getRoleData() {
    this.appService.getRoleList().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.roleData = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.roleData = response.data;
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.roleData = [];
          this.setDatatable();
        }
      }
    );
  }

  onSubmit() {
    let requestObject = {
      roleId: this.f.roleId.value,
      roleType: this.f.roleType.value,
      roleName: this.f.roleName.value,
      roleCode: this.f.roleCode.value
    }
    this.appService.addRole(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.toastr.success("Role added successfully");
        } else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  editRole(id: number) {
    this.router.navigate(['add-role'], { "queryParams": { "id": id }, relativeTo: this.route, skipLocationChange: true });
  }

  setDatatable() {
    $("#roleList").DataTable().destroy();
    setTimeout(() => {
      $("#roleList").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2],
          }
        }, {
          extend: 'pdf',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#roleList .column-search input').val('');
            $('#roleList .column-search select').val('.*');
            $('#roleList .column-search').on('keyup change', 'input', function () {
              $('#roleList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#roleList .column-search').on('keyup change', 'select', function () {
              $('#roleList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
