<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Menu Management</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a href="">Home</a></li>
                    <li class="active"><a href="/menu" aria-current="page">Menu Management</a></li>
                </ul>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" routerLink="add-menu" skipLocationChange>Add Menu</button>
            </div>
        </div>
    </div>
    <div class="">
        <div class="row clearSearch">
            <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <table id="menuList" class="table common-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th class="pre-wrap">Menu Name</th>
                    <th class="pre-wrap">Menu Code</th>
                    <th class="pre-wrap">Submenu</th>
                    <th class="pre-wrap">Edit</th>
                </tr>
                <tr class="column-search">
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let menu of menuData">
                    <td>{{menu.menuName}}</td>
                    <td>{{menu.menuCode}}</td>
                    <td>
                        <ul>
                            <li *ngFor="let subMenu of menu.subMenuData">{{subMenu.menuName}}</li>
                        </ul>
                    </td>
                    <td><i class="fa fa-pencil" aria-hidden="true" (click)="editMenu(menu.menuId)"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
