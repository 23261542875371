<div class="row">
    <div class="col-lg-12">
        <button class="AddButton btn btn-primary mb-2" type="button" (click)="popUpForAddingNewTemplate()"
            aria-hidden="true" data-toggle="modal" data-target="#NewTemplateModal">
            Add New Template
        </button>
    </div>
</div>


<div class="row">
    <div class="col-lg-12">
        <table id="existingFunction" style="table-layout: fixed;" class=" table common-table table-hover nowrap"
            datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

            <thead>
                <tr>
                    <th class="normalwrap" width="250px"> Functionality</th>
                    <th class="pre-wrap" width="750px"> Content</th>
                    <th class="normalwrap text-right" width="50px">Edit</th>
                    <th class="normalwrap text-right" width="50px">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let eFList of existingFunctionList">
                    <td>{{eFList.functName}}</td>
                    <td class="pre-wrap">{{eFList.emailTemplate}}</td>
                    <td class="text-right"><i class="fa fa-pencil"
                            (click)="popUpForExistingTemplate(eFList.functName,eFList.emailTemplate)" aria-hidden="true"
                            data-toggle="modal" data-target="#EditModal"></i></td>
                    <td class="text-right"><i class="fa fa-trash pointer" (click)="deleteEmailTemplate(eFList.functName)"
                            aria-hidden="true" data-toggle="modal" data-target="#delete"></i></td>

                </tr>
            </tbody>

        </table>


    </div>
</div>


<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center">
                <h6>Are you sure you want to delete this entry?</h6>
                <div class="d-flex justify-content-center mt-3">
                    <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                            data-dismiss="modal">Cancel</button></div>
                    <div class="col-3"><button type="button" class="btn btn-primary full-width"
                            (click)="deleteEmailId()" data-dismiss="modal">Delete</button></div>

                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">

    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <form [formGroup]="editEmailTemplateForm">
                <div class="modal-content">
                    <div class="modal-header">
                        <div formControlName="existingFunctionFormControlName"><label class="custom">Functionality Name:
                            </label>
                            {{existingFunction}}
                            <!-- <h2 align="center"  >{{existingFunction}}</h2>  -->
                        </div>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-lg-8">
                                <textarea contenteditable formControlName="existingTemplateFormControlName"
                                    id="emailTemplate" cols="90" rows="10">{{existingTemplate}}</textarea>
                                <!-- <input class="form-control" type="text" formControlName="existingTemplateFormControlName"  value="existingTemplate"> -->

                            </div>
                            <div class="col-lg-4">
                                <table style="width:150px" class=" table common-table nowrap">

                                    <thead>
                                        <tr>
                                            <th class="normalwrap" width="10%">Sr. No.</th>
                                            <th class="pre-wrap" width="30%">Token Name</th>
                                            <th class="normalwrap" width="60%"> Token Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let tList of tokenList ,let srNo=index">
                                            <!-- <td >{{tList.tokenId}}</td> -->
                                            <td>{{srNo+1}}</td>
                                            <td>{{tList.refValue}}</td>
                                            <td>{{tList.refDesc}}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>


                        <br>
                        <div class="modal-footer">
                            <button class="btn btn-primary " data-dismiss="modal" type="submit"
                                (click)="editEmailTemplate()" float right>Submit</button>
                        </div>


                    </div>


                </div>

            </form>
            <!-- <div class="popup justify-content-start" > -->

        </div>
    </div>
</div>

<div class="modal fade" id="NewTemplateModal" tabindex="-1" aria-hidden="true">

    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <form [formGroup]="addNewEmailTemplate">
                <div class="modal-content">
                    <!-- <div class="modal-header">
                        <div formControlName="newFunctionFormControlName"><label class="custom">Functionality Name:
                            </label>
                            {{existingFunction}}
                            
                        </div>
                    </div> -->
                    <div class="modal-header justify-content-center">
                        <label class="custom align-center"> Select Functionality to add Email Template
                        </label>
                    </div>
                    <br>
                    <div class="col-lg-3" *ngIf="selectForFunctionalityList">
                        <select ng-model="cr" class="form form-control" formControlName="addNewFunction">
                            <option value="">Select</option>
                            <option *ngFor="let nFList of newFunctionList" value="{{nFList.functName}}">
                                {{nFList.functName}}
                            </option>
                        </select>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-lg-8">
                                <textarea contenteditable name="emailTemplate" id="emailTemplate"
                                    formControlName="addNewTemplate" cols="90" rows="10"
                                    placeholder="Add email template here..."></textarea>
                                <!-- <input class="form-control" type="text" formControlName="existingTemplateFormControlName"  value="existingTemplate"> -->

                            </div>
                            <div class="col-lg-4">
                                <table style="width:150px" class=" table common-table nowrap">

                                    <thead>
                                        <tr>
                                            <th class="normalwrap" width="10%">Sr. No.</th>
                                            <th class="pre-wrap" width="30%">Token Name</th>
                                            <th class="normalwrap" width="60%"> Token Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let tList of tokenList ,let srNo=index">
                                            <!-- <td >{{tList.tokenId}}</td> -->
                                            <td>{{srNo+1}}</td>
                                            <td>{{tList.refValue}}</td>
                                            <td>{{tList.refDesc}}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>


                        <br>


                        <div class="modal-footer">
                            <button class="btn btn-primary " data-dismiss="modal" type="submit" (click)="saveEmail()"
                                float right>Submit</button>
                        </div>




                    </div>


                </div>

            </form>
            <!-- <div class="popup justify-content-start" > -->

        </div>
    </div>
</div>