import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { SseClient } from 'ngx-sse-client';
import { environment } from 'src/environments/environment';
import { MemberportalSsoService } from 'src/app/service/memberportal-sso.service';
declare var $: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

	selectedIndex = -1;
	tmEnableData: any = [];
	tmLimitData: any;
	marginSummary: any = {};
	userDetails: any = {};
	status: boolean = false;
	config: any = config;
	subscription: Subscription;
	user: any = []
	dtTrigger: Subject<any> = new Subject<any>();
	dtTrigger1: Subject<any> = new Subject<any>();
	tmEnableMenu: any = {};
	tmLimitMenu: any = {};
	tradeInquiryMenu: any = {};
	giveUpMenu: any = {}
	marginMenu: any = {};
	pendingTrades: any = [];
	rejectedTrades: any = [];
	approvedTrades: any = [];
	subscription1: Subscription;
	dtOptions: any = {};
	dtOptions1: any = {};
	menuListFinal: any = [];

	constructor(
		private userDetailsService: UserDetailsService,
		private toastr: ToastrService,
		private titleService: Title,
		private sseClient: SseClient,
		private cdref: ChangeDetectorRef,
		private memberPortalSSO: MemberportalSsoService) { }

	ngOnInit(): void {
		this.titleService.setTitle('Home')
		this.userDetails = this.userDetailsService.getUserDetails()
		this.userDetailsService.getUserDetailsDashboard().pipe(
			catchError((error: any) => {
				if (error.status != 200) {
					return throwError(error.error);
				}
			})
		).subscribe((response: any) => {
			if (response.statusCode == 200) {
				this.userDetailsService.setUserDetails(response.data && response.data[0]);
				this.userDetails = response.data[0];
				let menuList = this.userDetails.menusList;
				this.setMenuData();

			} else {
				this.userDetails = null;
				this.toastr.clear();
				this.toastr.warning("Session Expired. Please login again.");
				this.userDetailsService.logout();
			}
		});
	}

	setMenuData() {
		let entityTypeMenuData = this.config.data.entityTypeData.find(type => type.id == this.userDetails.entityType);
		let userTypeMenuData = this.config.data.userTypeData.find(type => type.id == this.userDetails.userType);
		let celUserTypeMenuData = this.config.data.celUserTypeData.find(type => type.id == this.userDetails.celUserType);
		this.userDetails.menusList.forEach(element => {
			if (celUserTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (celUserTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (celUserTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.userDetails.menusList.forEach(element => {
			if (userTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (userTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (userTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.userDetails.menusList.forEach(element => {
			if (entityTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (entityTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (entityTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
	}

	accessApplication(menu: any) {

		// let requestObject = {
		// 	"applicationName": applicationName
		//   };


		console.log("Inspection Application Name::" + menu.menuCode);
		this.memberPortalSSO.submitUserIdRequest(menu.menuCode);
	}



	clickEvent(id: any) {
		this.status = !id;
	}

	showDiv(index: number) {
		this.selectedIndex = index;
	}

	doughnutChartLabels: Label[] = ['Approve', 'Reject', 'Pending'];
	doughnutChartData: MultiDataSet = [
		[53, 40, 7]
	];
	segmentShowStroke: false;
	doughnutChartType: ChartType = 'doughnut';
	public doughnutChartLegend: boolean = false;

	colors: Color[] = [
		{
			backgroundColor: [
				'#0E62FF',
				'#FFB844',
				'#FC5A6B'
			],
			borderWidth: [0, 0, 0, 0]
		}

	];

	// horizontal 
	public barChartOptions: ChartOptions = {
		responsive: true,
		legend: { position: 'top' }

	};
	public barChartType: ChartType = 'bar';
	public barChartLegend = true;
	public barChartData: ChartDataSets[] = [
		{ data: [50, 77], label: 'Confirm', barThickness: 30, },
		{ data: [17, 20], label: 'Reject', barThickness: 20, },
	];
	public barChartLabels: string[] = ['Fixed Deposit', 'EPI Funds',];
	barChartColors: Color[] = [
		{ backgroundColor: '#0E62FF' },
		{ backgroundColor: '#FC5A6B' },
	]

	//horizontal

	public barChartOptionsepi: ChartOptions = {
		responsive: true,
		aspectRatio: 1,
		maintainAspectRatio: true
	};
	public barChartTypeepi: ChartType = 'horizontalBar';
	public barChartLegendepi = false;

	// public barChartDataepi: ChartDataSets[] = [
	//   { data: [50, 60, 40, 55, 77, 60], label: 'Buy Credit for Future Limit', barThickness: 10, },
	//   { data: [17, 20, 15, 20, 30, 25], label: 'Buy Credit for Future Utilization', barThickness: 10 },
	//   { data: [55, 77, 60, 50, 60, 45], label: 'Sell Credit for Future Limit', barThickness: 10 },
	//   { data: [17, 20, 15, 45, 25, 20], label: 'Sell Credit for Future Utilization', barThickness: 10 },
	//   { data: [50, 70, 50, 58, 77, 50], label: 'Buy Credit for Options Long Limit', barThickness: 10 },
	//   { data: [17, 20, 15, 30, 35, 25], label: 'Buy Credit for Options Long Utilization', barThickness: 10 },
	//   { data: [50, 70, 50, 58, 77, 50], label: 'Sell Credit for Options Short Limit', barThickness: 10 },
	//   { data: [17, 20, 15, 30, 35, 25], label: 'Sell Credit for Options Short Utilization', barThickness: 10 },
	// ];

	public barChartDataepi: ChartDataSets[] = [];
	public barChartLabelsepi: string[] = [];
	barChartColorsepi: Color[] = [
		{ backgroundColor: '#42267a' },
		{ backgroundColor: '#3b86e2' },
		{ backgroundColor: '#42267a' },
		{ backgroundColor: '#3b86e2' },
		{ backgroundColor: '#42267a' },
		{ backgroundColor: '#3b86e2' },
		{ backgroundColor: '#42267a' },
		{ backgroundColor: '#3b86e2' },
	]
	celMonitoringData: any = [];
	buyFutureLimit: any = [];
	buyFutureUtilization: any = [];
	sellFutureLimit: any = [];
	sellFutureUtilization: any = [];
	buyOptLongLimit: any = [];
	buyOptLongUtilization: any = [];
	sellOptShortLimit: any = [];
	sellOptShortUtilization: any = [];

	getCelMonitoringData() {
		let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
		this.subscription?.unsubscribe();
		this.subscription = this.sseClient.stream((environment.SSEURL + this.config.URL.getCelUtilization), { reconnectionDelay: 10000 }, { headers }, 'GET').subscribe((event) => {
			if (event.type == 'error') {
				const errorEvent = event as ErrorEvent;
				if (errorEvent.error) {
					this.celMonitoringData = [];
					this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
				}
			} else {
				const messageEvent = event as MessageEvent;
				var response = JSON.parse(messageEvent.data);
				if (response.statusCode == 200) {
					this.celMonitoringData = response.data;
					this.barChartLabelsepi = [];
					this.buyFutureLimit = [];
					this.buyFutureUtilization = [];
					this.sellFutureLimit = [];
					this.sellFutureUtilization = [];
					this.buyOptLongLimit = [];
					this.buyOptLongUtilization = [];
					this.sellOptShortLimit = [];
					this.sellOptShortUtilization = [];
					this.celMonitoringData.forEach(element => {
						this.barChartLabelsepi.push(element.branchId + " - " + element.userId);
						this.buyFutureLimit.push(element.netBuyCreditFutureLimit);
						this.buyFutureUtilization.push(element.netBuyCreditFuture);
						this.sellFutureLimit.push(element.netSellCreditFutureLimit);
						this.sellFutureUtilization.push(element.netSellCreditFuture);
						this.buyOptLongLimit.push(element.netBuyCreditOptionLongLimit);
						this.buyOptLongUtilization.push(element.netBuyCreditOptionLong);
						this.sellOptShortLimit.push(element.netSellCreditOptionShortLimit);
						this.sellOptShortUtilization.push(element.netSellCreditOptionShortLimit);
					});
					this.barChartDataepi = [
						{ data: this.buyFutureLimit, label: 'Buy Credit for Future Limit', barThickness: 10, minBarLength: 5 },
						{ data: this.buyFutureUtilization, label: 'Buy Credit for Future Utilization', barThickness: 10, minBarLength: 5 },
						{ data: this.sellFutureLimit, label: 'Sell Credit for Future Limit', barThickness: 10, minBarLength: 5 },
						{ data: this.sellFutureUtilization, label: 'Sell Credit for Future Utilization', barThickness: 10, minBarLength: 5 },
						{ data: this.buyOptLongLimit, label: 'Buy Credit for Options Long Limit', barThickness: 10, minBarLength: 5 },
						{ data: this.buyOptLongUtilization, label: 'Buy Credit for Options Long Utilization', barThickness: 10, minBarLength: 5 },
						{ data: this.sellOptShortLimit, label: 'Sell Credit for Options Short Limit', barThickness: 10, minBarLength: 5 },
						{ data: this.sellOptShortUtilization, label: 'Sell Credit for Options Short Utilization', barThickness: 10, minBarLength: 5 },
					]
					this.cdref.detectChanges();
				} else {
					this.celMonitoringData = [];
					this.toastr.warning(response.message);
				}
			}
		})
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
		this.subscription1?.unsubscribe();
	}

}

