<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>{{action=='add'?'Add User':'Edit User'}}</h1>

                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="/admin/celUserMgmt" skipLocationChange>CEL User Management</a></li>
                    <li class="active"><a routerLink="/admin/addCelUser" skipLocationChange aria-current="page">
                            {{action=='add'?'Add CEL User':'Edit CEL User'}}</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="card card-body">
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please wait.</p>
        </ngx-spinner>
        <form [formGroup]='adduserForm' (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Login ID<span class="red">*</span> </label>
                    <input type="text" value="" class="form-control" formControlName="userLoginId"
                        placeholder="Enter Login ID">
                    <div *ngIf="f.userLoginId.invalid && f.userLoginId.touched">
                        <p *ngIf="f.userLoginId.errors.required" class="error nocase">
                            Please enter login ID
                        </p>
                        <p *ngIf="f.userLoginId.errors.pattern" class="error nocase">
                            Login ID should start with alphanumeric character. It should contain at least 5
                            characters & should not contain any white spaces.
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Entity Type<span class="red">*</span> </label>
                    <input type="text" value="" class="form-control" formControlName="entityType"
                        placeholder="Enter Entity Type" readonly>
                    <p *ngIf="submitted && !f.entityType.value" class="error nocase">
                        Please enter entity type
                    </p>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Entity Code<span class="red">*</span> </label>
                    <select class="form-control selectpicker" formControlName="entityCode" data-live-search="true"
                        title="Select Entity Code" data-style="btn-info">
                        <option *ngFor="let data of entityCodeData" value="{{data.entityCode}}">{{data.entityCode
                            +'-'+ data.companyName}}</option>
                    </select>
                    <p *ngIf="submitted && !f.entityCode.value" class="error nocase">
                        Please enter entity code
                    </p>
                </div>
                <div class="col-lg-3 form-group">
                    <label>User Name<span class="red">*</span> </label>
                    <input type="text" value="" class="form-control" formControlName="userName">
                    <div *ngIf="f.userName.invalid && f.userName.touched" class="error nocase">
                        <p *ngIf="f.userName.errors.required" class="error">
                            Please enter user name
                        </p>
                        <p *ngIf="f.userName.errors.pattern" class="error">
                            User Name should contain alphanumeric value.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label class="full-width">
                        <span class="row">
                            <span class="col-lg-4">Email ID<span class="red">*</span></span>
                            <span class="col-lg-8 text-right">
                                <span *ngIf="user.isEmailVerified=='Y'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="fillColor bi bi-check-circle-fill ml-2" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>Verified
                                </span>
                                <a *ngIf="user.isEmailVerified=='N'"
                                    (click)="sendVerificationLink(user.userId)"> Send Verification Link
                                </a>
                            </span>
                        </span>
                    </label>
                    <input type="email" value="" class="form-control" formControlName="userEmail" email
                        placeholder="Enter Email Address">
                    <div *ngIf="f.userEmail.invalid && f.userEmail.touched">
                        <p *ngIf="f.userEmail.errors.required" class="error nocase">
                            Please enter email ID
                        </p>
                        <p *ngIf="f.userEmail.errors.email" class="error nocase">
                            Please enter valid email ID
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Mobile Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <select class="selectpicker form-control" formControlName="countryCode"
                                data-style="btn-info">
                                <option *ngFor="let data of countryCodeData" value='{{data.id}}'>
                                    +{{data.id}}</option>
                            </select>
                        </div>
                        <input type="text" value="" class="form-control" formControlName="userMobile"
                            placeholder="Enter Mobile Number">
                    </div>
                    <div *ngIf="f.userMobile.invalid && f.userMobile.touched">
                        <p *ngIf="f.userMobile.errors.pattern" class="error nocase">
                            Please enter valid mobile number
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>CEL User Type<span class="red">*</span> </label>
                    <select class="selectpicker form-control" formControlName="celUserType" data-style="btn-info"
                        title="Select CEL User Type">
                        <option *ngFor="let type of celUserTypeData" value='{{type.id}}'>{{type.name}}</option>
                    </select>
                    <p *ngIf="f.celUserType.invalid && f.celUserType.touched" class="error nocase">
                        Please select CEL user type
                    </p>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Status<span class="red">*</span> </label>
                    <select class="form-control selectpicker" formControlName="isActive" data-style="btn-info">
                        <option value='Y'>Active</option>
                        <option value='N'>Deactive</option>
                    </select>
                    <p *ngIf="f.isActive.invalid && f.isActive.touched" class="error nocase">
                        Status is required
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group" *ngIf="action=='update'">
                    <label>Is deleted </label>
                    <select class="form-control selectpicker" formControlName="isDeleted" data-style="btn-info">
                        <option value='Y'>Yes</option>
                        <option value='N'>No</option>
                    </select>
                    <p *ngIf="f.isDeleted.invalid && f.isDeleted.touched" class="error nocase">
                        Is deleted is required
                    </p>
                </div>
                <div class="col-lg-3 form-group" *ngIf="user.createdByString">
                    <label>Created by </label>
                    <p>{{user.createdByString}} on {{user.createdDate|date:"medium"}}</p>
                </div>
                <div class="col-lg-3 form-group" *ngIf="user.updatedByString">
                    <label>Last modified by </label>
                    <p>{{user.updatedByString}} on {{user.lastModifiedOn|date:"medium"}}</p>
                </div>
                <div class="col-lg-3 form-group" *ngIf="user.deactivationDate">
                    <label>Deactivation Date </label>
                    <p>{{user.deactivationDate|date:"medium"}}</p>
                </div>
            </div>
            <div class="row justify-content-start mb-5">
                <div class="col-lg-8">
                    <div class="row justify-content-start">
                        <div class="col-lg-2 usercontrol">
                            <button class="btn btn-primary full-width"
                                [disabled]="adduserForm.invalid || !!(action=='view') || submitted">Submit</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-cancel full-width" type="button" routerLink="../celUserMgmt"
                                skipLocationChange>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>