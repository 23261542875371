<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>ML and AI Report</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eCompliance/mlAndAi/mlaireport" aria-current="page">ML and AI Report</a></li>  
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body colorborder">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Member Name</label>
                    <input type="text" class="form-control" formControlName="memname" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Member Code</label>
                    <input type="text" class="form-control" formControlName="memCode" readonly>  
                </div>
                <div class="col-lg-3 form-group">
                    <label>Quarter Start Date</label>
                    <select class="form-control selectpicker" formControlName="QuarterstartDate" data-style="btn-info" title="Select Request Type">
                        <option  *ngFor="let data of QuarterstartDateList" value="{{data}}" >{{data}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Submission From Date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue"
                            value="{{bsValue | date:'MM/ddd/yyyy'}}" formControlName="submissionFDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" placeholder="" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar"  [(bsValue)]="bsValue" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Submission End Date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue1"
                            value="{{bsValue1 | date:'MM/ddd/yyyy'}}" formControlName="submissiontTDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" placeholder="" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar"  [(bsValue)]="bsValue1" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
               
            </div>

            <div class="row">
                <div class="col-lg-3 form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <button class="btn btn-primary full-width" type="submit" (click)="onSubmit()">Search</button>
                        </div>
                        <div class="col-lg-6">
                            <button class="btn btn-cancel full-width"  (click)="onReset()">Reset</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </form>
        <table id="mlaiMIS" class="overflow table common-table nowrap" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                   
                    <th>Reference No.</th>
                    <th>Member Code</th>
                    <th>Member Name</th>
                    <th>Quarter Start Date</th>
                    <th>Quarter End Date</th>
                    <th>Submission Date</th>
                    <th>Audit Report</th>
                    <th>System Adverse</th>
                    <th>NIL Submission</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mlaireport">
                  
                    <!-- (click)="open(data.RefNum)" -->
                    <td (click)="open(data.mlaiRefNO)"  class="refNo pointer "><u>{{data.mlaiRefNO}}</u></td>
                    <td>{{data.memSebiNoPart2}}</td>
                    <td>{{data.memName}}</td>
                    <td>{{data.mlaiqtrStartdate | date}}</td>
                    <td>{{data.mlaiqtrEndDt | date}}</td>
                    <td>{{data.mlaisubmissionDate|date}}</td>
                    <td>{{(data.mlaisystemAuditReport=='Y')?'YES':'NO'}}</td>
                    <td>{{(data.mlaisystemAdverse=='Y')?'YES':'NO'}}</td>
                    <td>{{(data.mlaiNillStatus=='0')?'NO':'YES'}}</td>
                </tr>
            </tbody>
        </table>
</div>


