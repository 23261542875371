<!-- <div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="/digitalsignature" aria-current="page">DIgital</a></li>
                     <button (click)="myFunc()">Click Me</button> 
                </ul>
            </div>

        </div>
    </div> -->

    <!-- <button (click)="myFunc()">Click Me</button> -->
    <button type="button" class="btn btn-primary full-width" (click)="myFunc()">Certify</button>
    <br>
    <br>
 

    