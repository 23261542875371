import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/valueObject/config';

@Injectable({
  providedIn: 'root'
})
export class EnitTradeRequestListService {


  newNeatReqData: any[] = [];
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  config: any = config;
  constructor(private http: HttpClient) { }

  displayList(requestObj) {
    let URL = environment.URL + this.config.URL.displayUIRequestProcessPage;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObj, this.options);
  }



  getUserByMemCode1(SebiRegType: string) {
    let URL = environment.URL + this.config.URL.getNewNeatIdRequestbySebiNo;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  addNeatUser(userList: any[]) {
    this.newNeatReqData = userList;
  }

  getAllRequests() {
    return this.newNeatReqData;
  }




}
