import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { config } from 'src/app/valueObject/config';
import { AppService } from 'src/app/service/app.service';
import { catchError } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { UserDetails } from '../valueObject/userDetails';
import { User } from '../valueObject/user';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { SseClient } from 'ngx-sse-client';
import { environment } from 'src/environments/environment';
import { MemberportalSsoService } from '../service/memberportal-sso.service';
import { DatePipe } from '@angular/common';


declare var $: any;

@Component({
	selector: 'app-component',
	templateUrl: './component.component.html',
	styleUrls: ['./component.component.css'],
	providers: [DatePipe]
})
export class ComponentComponent implements OnInit, AfterViewInit {

	config: any = config;
	userDetails: any = {};
	user: any = {};
	collateralMenu: any = {};
	notifications: any = [];
	subscription: Subscription;
	menuListFinal: any = [];
	today = Date.now();

	constructor(private titleService: Title,
		private appService: AppService,
		private router: Router,
		private userDetailsService: UserDetailsService,
		private toastr: ToastrService,
		private sseClient: SseClient,
		private cdref: ChangeDetectorRef,
		private memberPortalSsoServ: MemberportalSsoService,
		public datepipe: DatePipe) { }


	ngOnInit(): void {
		this.titleService.setTitle("Home");
		this.getCurrentDate();
	}

	ngAfterViewInit() {
		this.getUserDetails();
	}

	fullscreen: boolean = true;
	popOverScreen: boolean = false;
	clicktoggle() {
		this.fullscreen = !this.fullscreen;
		this.popOverScreen = !this.popOverScreen;
	}

	hideSidebar() {
		this.fullscreen = true;
	}

	getUserDetails() {
		this.appService.getUserDetails(0).pipe(
			catchError((error: any) => {
				this.userDetails = null;
				this.toastr.clear();
				this.toastr.warning("Session Expired. Please login again.");
				this.logout();
				this.cdref.detectChanges();
				if (error.status != 200) {
					return throwError(error.error);
				}
			})
		).subscribe(
			(response: any) => {
				if (response.statusCode == 200) {
					this.userDetailsService.setUserDetails(response.data && response.data[0]);
					this.userDetails = response.data[0];
					this.setMenuData();
					if (this.userDetails.celUserType == 1 || this.userDetails.celUserType == 2)
						this.getNotification();
					let menuList = this.userDetails.menusList;
					this.memberPortalSsoServ.setApplicationList(menuList);
					this.collateralMenu = menuList.find(data => data.menuCode == 'collateral');
					this.cdref.detectChanges();
				} else {
					this.userDetails = null;
					this.toastr.clear();
					this.toastr.warning("Session Expired. Please login again.");
					this.logout();
					this.cdref.detectChanges();
				}
			}
		);
	}

	setMenuData() {
		let entityTypeMenuData = this.config.data.entityTypeData.find(type => type.id == this.userDetails.entityType);
		let userTypeMenuData = this.config.data.userTypeData.find(type => type.id == this.userDetails.userType);
		let celUserTypeMenuData = this.config.data.celUserTypeData.find(type => type.id == this.userDetails.celUserType);
		this.userDetails.menusList.forEach(element => {
			if (celUserTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (celUserTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (celUserTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.userDetails.menusList.forEach(element => {
			if (userTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (userTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (userTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.userDetails.menusList.forEach(element => {
			if (entityTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (entityTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (entityTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
	}

	accessApplication(menu: any) {

		// let requestObject = {
		// 	"applicationName": applicationName
		//   };


		console.log("Inspection Application Name::" + menu.menuCode);
		this.memberPortalSsoServ.submitUserIdRequest(menu.menuCode);
	}

	changePassword() {
		this.router.navigate(['/form/changepassword'], { queryParams: { 'page': 'home', 'username': this.userDetails.loginId } })
	}

	profile() {
		this.router.navigate(['/form/profile']);
	}

	logout() {
		this.appService.logout({}).pipe(
			catchError((error: any) => {
				localStorage.removeItem('token');
				localStorage.removeItem('userDetails');
				localStorage.removeItem('username');
				localStorage.removeItem('salt');
				this.router.navigate(['/login']);
				if (error.status != 200) {
					return throwError(error.error);
				}
			})
		).subscribe((response: any) => {
			localStorage.removeItem('token');
			localStorage.removeItem('userDetails');
			localStorage.removeItem('username');
			localStorage.removeItem('salt');
			this.router.navigate(['/login']);
		});
	}

	getNotification() {
		let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
		this.subscription?.unsubscribe();
		this.subscription = this.sseClient.stream((environment.SSEURL + this.config.URL.getMarketAlert), { reconnectionDelay: 10000 }, { headers }, 'GET').subscribe((event) => {
			if (event.type == 'error') {
				const errorEvent = event as ErrorEvent;
				if (errorEvent.error) {
					this.notifications = [];
					this.cdref.detectChanges();
				}
			} else {
				const messageEvent = event as MessageEvent;
				var response = JSON.parse(messageEvent.data);
				if (response.statusCode == 200) {
					this.notifications = response.data[0];
					this.cdref.detectChanges();
				} else {
					this.notifications = [];
					this.cdref.detectChanges();
				}
			}
		})
	}

	getCelType(type) {
		if (type == 0)
			return 'future buy limit';
		else if (type == 1)
			return 'future sell limit';
		else if (type == 2)
			return 'option buy limit';
		else if (type == 3)
			return 'option sell limit';
	}

	getAlertType(type) {
		if (type == 0)
			return 'notification';
		else if (type == 1)
			return 'warning';
		else if (type == 2)
			return 'breach';
	}

	getCurrentDate() {
		setInterval(() => {
			this.today = Date.now();
		}, 100);
	}
}
