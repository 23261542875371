<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Request For Neat User Validity Updation</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                   <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/NeatUsrValidityUpdationProcessPage"
                                aria-current="page">Request For Neat User Validity Updation</a></li>
                 <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUseridRequest/displayUIRequestProcessPage/NeatUsrValidityUpdationProcessPage"
                                    aria-current="page">Request For Neat User Validity Updation</a></li>
                          
                </ul>
            </div>

        </div>
    </div>
    <div>
        <form [formGroup]="validityUpdationAdmin" >
                <div class="card card-body col-lg-12 mt-3 ">               
                   <div class="row">                                                           
                        <div class="col-lg-3 form-group">                               
                                <label>Member Code</label>
                                <input type="text" class="form-control" readonly formControlName="memId">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Member Name</label>
                            <input type="text" class="form-control" readonly formControlName="memName">
                        </div>
                        <div class="col-lg-3 form-group">
                                <label>User Id</label>
                                <input type="text" class="form-control" readonly formControlName="userId">

                        </div>
                        <div class="col-lg-3 form-group">
                                <label>UserName</label>
                                <input type="text" class="form-control" readonly formControlName="firstName">
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Tap Ip</label>
                            <input type="text" class="form-control" readonly formControlName="tapIp">
                        </div>
                        <div class="col-lg-3 form-group">
                               
                            <label>Tap Id</label>
                            <input type="text" class="form-control" readonly formControlName="tapId">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Exisitng Certification DetailsCertification details:</label>
                            <input type="text" class="form-control" readonly formControlName="existingCertificationDetails">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Registration No of Certificate:</label>
                            <input type="text" class="form-control" readonly formControlName="oldRegiNo">
                        </div>
                            
                    </div>     

                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Validity Date:</label>
                            <input type="text" class="form-control" readonly formControlName="validityDate">
                        </div>
                        <div class="col-lg-3 form-group">  
                            <label>Percentage:</label>
                            <input type="text" class="form-control" readonly formControlName="percentage">                    
                        </div>
                        <div class="col-lg-3 form-group">                             
                            <label>Proposed User Certification DetailsCertification details:</label>
                            <input type="text" class="form-control" readonly formControlName="newCertificationDetails">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Registration No of Certificate:</label>
                            <input type="text" class="form-control" readonly formControlName="newRegiNo">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Validity Date::</label>
                            <input type="text" class="form-control" readonly formControlName="newValidityDate">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Percentage:</label>
                            <input type="text" class="form-control" readonly formControlName="newPercentage">
                        </div>
                    </div>
                <div *ngIf="entityType!='ENIT'">
                           <!-- if data is submitted then load this div -->
                     <div *ngIf="reqObj.cusReqStatus=='APPR' || reqObj.cusReqStatus=='RJCT'" class="card card-body col-lg-12 mt-3 ">
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Current Status  </label>
                                <input *ngIf="reqObj.cusReqStatus=='APPR'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="APPROVED">
                                <input *ngIf="reqObj.cusReqStatus=='RJCT'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="REJECTED">
                            </div>
                            <div class="col-lg-3 form-group">
                                <label for="">Remarks  </label>
                                <input type="text" class="form-control" readonly formControlName="remark">
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                            </div>
                        </div>
                    </div>   
                        
                      
                     <!-- if data is submitted then load this div -->
                    <div *ngIf="reqObj.cusReqStatus!='APPR' && reqObj.cusReqStatus!='RJCT'" >
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Current Status  </label>
                                <input   formControlName="CurrentStatus" type="text" class="form-control" readonly value="In Progress">
                               
                            </div>
                            <div class="col-lg-3 form-group">
                                <label>Select Status</label>
                 
                                <select class="form-control form-control-sm" formControlName="UpdatedStatus" >
                                    <option value="" selected hidden>SELECT STATUS</option>
                                    <option value="APPR">APPROVED</option>
                                    <option value="RJCT">REJECTED</option>
                                    <option value="UNDER_PROC">UNDER PROCESSING</option>
                                    <option value="DOC_AWAT">DOCUMENT AWAITED</option>

                                </select>
                              
                            </div>
                            <div class="col-lg-3 form-group">
                                <label for="">Remarks  </label>
                                <input type="text" class="form-control"  formControlName="remark">
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <button class="btn  btn-primary full-width" type="submit" (click) ="onSubmit()">Submit</button>
                            </div>
                            <div class="col-lg-3 form-group">
                                <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                            </div>
                        </div>
                    </div>        
                </div> 
                <div class="col-lg-4" *ngIf="entityType=='ENIT'">
                <div class="row" >
                    <div class="col-lg-3 form-group">
                        <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                    </div>
                </div>
            </div>
            </div>
        </form>
    </div>
</div>
