import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from 'src/app/service/app.service';
import { RoleService } from 'src/app/service/role.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  roleForm: FormGroup;
  menuForm: FormArray;
  roleData: any = {};
  action: string;
  config: any = config;
  menuData: any = [];
  userDetails: any;

  get f() { return this.roleForm.controls; }

  get mf() { return this.menuForm.controls; }

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private roleService: RoleService,
    private userDetailsService: UserDetailsService) {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.getRoleData(params['id']);
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Menu Management');
    this.roleForm = this.formBuilder.group({
      roleId: ['', Validators.required],
      roleType: ['', Validators.required],
      roleName: ['', Validators.required],
      roleCode: ['', Validators.required],
      menuForm: this.formBuilder.array([])
    });
    this.userDetails = this.userDetailsService.getUserDetails();
  }

  getRoleData(id) {
    this.appService.getRoleDataById(id).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.roleData = {};
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.roleData = response.data;
          this.setFormData();
          this.cdref.detectChanges();
        } else {
          this.toastr.error(response.message);
          this.roleData = {};
          this.cdref.detectChanges();
        }
      }
    );
  }

  setFormData() {
    this.f.roleId.setValue(this.roleData.roleId);
    this.f.roleType.setValue(this.roleData.roleType);
    this.f.roleName.setValue(this.roleData.roleName);
    this.f.roleCode.setValue(this.roleData.roleCode);
    let menuForm = <FormArray>this.f.menuForm;
    this.menuData.forEach((element, index) => {
      menuForm.push(this.formBuilder.group({
        menuId: [element.menuId],
        menuName: [element.menuName],
        menuCode: [element.menuCode],
        isActive: [element.isActive],
        createdBy: [element.createdBy],
        createdDate: [element.createdDate],
        checkedBy: [element.checkedBy],
        checkedDate: [element.checkedDate],
        lastModifiedBy: [element.lastModifiedBy],
        lastModifiedDate: [element.lastModifiedDate],
        subMenuForm: this.formBuilder.array([])
      }));
      if (element.subMenuData) {
        element.subMenuData.forEach(element1 => {
          (menuForm.at(menuForm.length - 1).get('subMenuForm') as FormArray).push(this.formBuilder.group({
            menuId: [element1.menuId],
            menuName: [element1.menuName],
            menuCode: [element1.menuCode],
            isActive: [element1.isActive],
            createdBy: [element.createdBy],
            createdDate: [element.createdDate],
            checkedBy: [element.checkedBy],
            checkedDate: [element.checkedDate],
            lastModifiedBy: [element.lastModifiedBy],
            lastModifiedDate: [element.lastModifiedDate],
          }))
        });
      }
    });
    this.menuForm = <FormArray>this.f.menuForm;
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  onSubmit() {
    let requestObject = {
      roleId: this.f.roleId.value,
      roleType: this.f.roleType.value,
      roleName: this.f.roleName.value,
      roleCode: this.f.roleCode.value,
      menuData: this.f.menuForm.value.filter(data => (data.menuName != null && data.menuCode != null))
    }
    this.appService.saveRole(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.toastr.success("Menu details updated successfully");
          this.router.navigate(["../"], { relativeTo: this.route, skipLocationChange: true });
        } else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  checkRecordMenu(menu, status) {
    menu['controls'].checkedBy.setValue(this.userDetails.userId);
    menu['controls'].checkedDate.setValue(new Date());
    menu['controls'].lastModifiedBy.setValue(this.userDetails.userId);
    menu['controls'].lastModifiedDate.setValue(new Date());
    menu['controls'].isActive.setValue(status);
    if (menu['controls'].subMenuForm.length > 0) {
      menu['controls'].subMenuForm['controls'].forEach(element => {
        element['controls'].checkedBy.setValue(this.userDetails.userId);
        element['controls'].checkedDate.setValue(new Date());
        element['controls'].lastModifiedBy.setValue(this.userDetails.userId);
        element['controls'].lastModifiedDate.setValue(new Date());
        element['controls'].isActive.setValue(status);
      });
    }
  }

  checkRecordSubMenu(menu, subMenu, status) {
    if (status == 'Y') {
      menu['controls'].checkedBy.setValue(this.userDetails.userId);
      menu['controls'].checkedDate.setValue(new Date());
      menu['controls'].lastModifiedBy.setValue(this.userDetails.userId);
      menu['controls'].lastModifiedDate.setValue(new Date());
      menu['controls'].isActive.setValue(status);
    }
    subMenu['controls'].checkedBy.setValue(this.userDetails.userId);
    subMenu['controls'].checkedDate.setValue(new Date());
    subMenu['controls'].lastModifiedBy.setValue(this.userDetails.userId);
    subMenu['controls'].lastModifiedDate.setValue(new Date());
    subMenu['controls'].isActive.setValue(status);
  }

}
