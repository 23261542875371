export const incidentType: any = [
    {
      name: "Phishing",
      value: "PHISHING"
    },
    {
      name: "Network scanning /Probing Break- in/Root Compromise",
      value: "NETWORK_SCANNING"
    },
    {
      name: "Virus/Malicious Code",
      value: "VIRUS"
    },
    {
      name: "Website Defacement",
      value: "WEBSITEDEFACEMENT"
    },
    {
      name: "System Misuse",
      value: "SYSTEM_MISUSE"
    },
    {
      name: "Spam",
      value: "SPAM"
    },
    {
      name: "Bot/Botnet",
      value: "BOT"
    },
    {
      name: "Email Spoofing",
      value: "EMAIL"
    },
    {
      name: "Denial of Service(DoS)",
      value: "DENIEL_OF_SERVICE"
    },
    {
      name: "Distributed Denial of Service(DDoS)",
      value: "DDOS"
    },
    {
      name: "User Account Compromise",
      value: "USER"
    },
    {
      name: "Website Intrusion",
      value: "WEBINTRUSION"
    },
    {
      name: "Social Engineering",
      value: "SOCIALENGINEERING"
    },
    {
      name: "Technical Vulnerability",
      value: "TECHNICAL_VULENRABILITY"
    },
    {
      name: "IP Spoofing",
      value: "IP_SOOFING"
    },
    {
      name: "Ransomware",
      value: "RANSOMWARE"
    },
    {
      name: "Other (Please specify)",
      value: "OTHER"
    },
  ];