import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { LesService } from 'src/app/service/les.service'
import { Subject, catchError, first, throwError } from 'rxjs';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-les-operation-screen',
  templateUrl: './les-operation-screen.component.html',
  styleUrls: ['./les-operation-screen.component.css']
})
export class LesOperationScreenComponent implements OnInit {
  user: any = {}
  config: any = config;
  dtTrigger: Subject<any> = new Subject<any>();
  searchForm: FormGroup;
  historicalSearchForm: FormGroup;
  dtOptions: any;
  memberRequestList: any = [];
  lesOperationalSchemeDataList: any = [];
  bsValue: any;
  bsValue1: any;
  entityType: any;
  lesSchemeList: any = [];
  lesClientList: any = [];
  hourlyFileList: any = [];
  dailyFileList: any = {};
  HistoricalDailyFileList: any = [];
  enableIncentiveFiles: boolean = true;
  enableTmCode: boolean = true;
  currentOrderDate: any = this.datePipe.transform(new Date(), 'dd-MMM-yyyy');
  listHourlyMemberFiles: any = [];
  listDailyMemberFiles: any = [];
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;
  downloadFileName: any;

  constructor(private userDetailsService: UserDetailsService,
    private formbuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private lesService: LesService,
    private datePipe: DatePipe) { }
  ngOnInit(): void {
    this.titleService.setTitle('LES RealTime Presence');
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    this.enableIncentiveFiles = this.user.entityType == 'ADMIN' ? false : true;
    this.enableTmCode = this.user.entityType != 'ADMIN' ? false : true;
    this.searchForm = this.formbuilder.group({
      tmCode: [null],
      clientCode: [null],
      lesSchemes: [null],
    })

    this.historicalSearchForm = this.formbuilder.group({
      incentiveDate: [null],
    })
    this.getClientsAndSchemeList();
    this.getAllLesOprnlSchemeData();
    this.getHourlyAndDailyFileRecord();
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker();
  }

  get f() {
    return this.searchForm.controls;
  }

  get d() {
    return this.historicalSearchForm.controls;
  }

  getAllLesOprnlSchemeData() {
    this.spinner.show();
    let requestObject = {
      "tmCode": ((this.entityType == 'ADMIN') ? this.f.tmCode.value : this.user.entityCode),
      "clientCode": this.f.clientCode.value || null,
      "schemeName": this.f.lesSchemes.value || null,
    }
    this.lesService.getAllLesOprnlSchemeData(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.lesOperationalSchemeDataList = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.lesOperationalSchemeDataList = response.data;
        this.setDatatable();
        this.currentOrderDate = this.datePipe.transform((this.lesOperationalSchemeDataList[0]) ? this.lesOperationalSchemeDataList[0]?.orderDate : new Date(), 'dd-MMM-yyyy');
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      } else {
        this.lesOperationalSchemeDataList = [];
        this.setDatatable();

      }
    })

  }
  getClientsAndSchemeList() {
    this.lesService.getClientsAndSchemeList().pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.lesSchemeList = [];
        this.lesClientList = [];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        // this.lesSchemeList = this.user.entityType == 'ADMIN' ? response.data.adminSchemesList : response.data.schemesList;
        // this.lesClientList = response.data.clientList;
        this.lesSchemeList = response.data.schemesData;
        this.lesClientList = response.data.clientIds;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }

  getHourlyAndDailyFileRecord() {
    let requestObject = {
      "memberCode": this.user.entityCode,
      "startDate": this.d.incentiveDate.value ? this.datePipe.transform(this.d.incentiveDate.value[0], 'yyyy-MM-dd') : null,
      "endDate": this.d.incentiveDate.value ? this.datePipe.transform(this.d.incentiveDate.value[1], 'yyyy-MM-dd') : null,
    }
    this.lesService.getListMemberFiles(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.listHourlyMemberFiles = [];
        this.listDailyMemberFiles = [];
        this.HistoricalDailyFileList = []
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.listHourlyMemberFiles = response.data.Hourly;
        this.listDailyMemberFiles = response.data.Daily;
        this.HistoricalDailyFileList = response.data.Historical;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }
  setDatatable() {
    $('#lesRequest').DataTable().destroy();
    setTimeout(() => {
      $("#lesRequest").DataTable({
        orderCellsTop: true,
        processing: true,
        responsive: true,
        pageLength:25,
        dom: 'Blrtip',
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#lesRequest .column-search input').val('');
            $('#lesRequest .column-search select').val('.*');
            $('#lesRequest .column-search').on('keyup change', 'input', function () {
              $('#lesRequest').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#lesRequest .column-search').on('keyup change', 'select', function () {
              $('#lesRequest').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }
  onReset() {
    this.searchForm.reset();
    window.location.reload();
    $('.selectpicker').selectpicker('refresh');
  }

  historicalDataReset() {
    this.historicalSearchForm.reset();
    window.location.reload();
    $('.selectpicker').selectpicker('refresh');
  }

  downloadHourlyAndDailyFile(fileName: any, fileType: any) {
    this.spinner.show();
    if (fileType != 'HISTORICAL') {
      this.downloadFileName = fileName + ".csv"
    } else {
      this.downloadFileName = fileName
    }

    this.lesService.downloadHourlyAndDailyFile(fileName, fileType).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        let file = response.data;
        let contentType = 'application/csv'
        let blob = base64StringToBlob(file, contentType);
        let blobURL = window.URL.createObjectURL(blob)
        saveAs(blob, this.downloadFileName, {
          autoBOM: true
        });
        this.toastr.success("File downloaded successfully");
      }
      else {
        this.toastr.info(response.message);
      }
    })
  }
  setHistoricalDatatable() {
    $('#historicalData').DataTable().destroy();
    setTimeout(() => {
      $("#historicalData").DataTable({
        orderCellsTop: true,
        processing: true,
        responsive: true,
        // pageLength: 5,
        dom: '',
        initComplete: function () {
          this.api().columns().every(function () {
            $('#historicalData .column-search input').val('');
            $('#historicalData .column-search select').val('.*');
            $('#historicalData .column-search').on('keyup change', 'input', function () {
              $('#historicalData').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#historicalData .column-search').on('keyup change', 'select', function () {
              $('#historicalData').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }
}
