<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Product Coefficient Setup</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"> <a routerLink="/cel/prodCoefSetup" skipLocationChange
                            aria-current="page">Product Coefficient
                            Setup</a></li>
                </ul>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please wait.</p>
        </ngx-spinner>
    </div>
    <div class="">
        <div class="row clearSearch">
            <button type="button" class="btn btn-cancel btn-sm" aria-label="close" (click)="clearSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <div class="col-lg-6">
            <div class="row newReload">
                <div class="pl-4 mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        (click)="getBranchUserData()" class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16">
                        <title>Reload</title>
                        <path
                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                    </svg>
                </div>
            </div>
        </div>
        <table id="userList" class="table common-table prod-coef-table" datatable [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th class="pre-wrap">Sr No</th>
                    <th class="pre-wrap">Branch ID</th>
                    <th class="pre-wrap">Branch Name</th>
                    <th class="pre-wrap">User ID</th>
                    <th class="pre-wrap">User Name</th>
                    <th class="pre-wrap text-center">Product Coefficient</th>
                    <th class="pre-wrap">Edit</th>
                </tr>
                <tr class="column-search">
                    <th></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let data of branchUserData; let i=index">
                    <tr>
                        <td>{{i+1}}</td>
                        <td>{{data.branchId}}</td>
                        <td>{{data.branchName}}</td>
                        <td>{{data.userId}}</td>
                        <td>{{data.userName}}</td>
                        <td>
                            <table class="inner-table">
                                <thead>
                                    <tr>
                                        <th class="pre-wrap">Symbol</th>
                                        <th class="pre-wrap">Futures</th>
                                        <th class="pre-wrap">Options Long</th>
                                        <th class="pre-wrap">Options Short</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prod of data.prodCoef">
                                        <td>{{prod.symbol}}</td>
                                        <td class="text-right">{{prod.futures}}</td>
                                        <td class="text-right">{{prod.optLong}}</td>
                                        <td class="text-right">{{prod.optShort}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <i class="fa fa-pencil pointer usercontrol" aria-hidden="true" data-toggle="modal"
                                data-target="#prodCoefModal" (click)="editBranchData(data.userId)"></i>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="prodCoefModal" tabindex="-1" role="dialog" aria-labelledby="prodCoefModallabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <form [formGroup]="prodCoefForm">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Branch ID<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="branchId"
                                placeholder="Enter Branch ID" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Branch Name<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="branchName"
                                placeholder="Enter Branch Name" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User ID<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="userId" placeholder="Enter User ID"
                                readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Name<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="userName"
                                placeholder="Enter User Name" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <table id="prodCoef" class="table common-table">
                                <thead>
                                    <tr>
                                        <th rowspan="2" class="pre-wrap">Symbol</th>
                                        <th colspan="3" class="pre-wrap text-center">Product Coeffient</th>
                                    </tr>
                                    <tr>
                                        <th class="pre-wrap">Futures</th>
                                        <th class="pre-wrap">Options Long</th>
                                        <th class="pre-wrap">Options Short</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="prodCoefArray">
                                    <tr *ngFor="let data of selectedUser.prodCoef; let i=index" [formGroupName]="i">
                                        <td><input type="text" class="form-control" formControlName="symbol"
                                                placeholder="Enter Symbol" readonly></td>
                                        <td>
                                            <input type="number" class="form-control" formControlName="futures" min="1"
                                                max="100" placeholder="Enter Futures Product Coeffient">
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.futures.errors?.required && pf.prodCoefArray.controls[i].controls?.futures.touched"
                                                class="error nocase">
                                                Please enter future product coeffient
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.futures.errors?.max && pf.prodCoefArray.controls[i].controls?.futures.touched"
                                                class="error nocase">
                                                Product Coefficient should not be greater than 100
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.futures.errors?.min && pf.prodCoefArray.controls[i].controls?.futures.touched"
                                                class="error nocase">
                                                Product Coefficient should not be less than 1
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.futures.errors?.noDecimalNumberValidation && pf.prodCoefArray.controls[i].controls?.futures.touched"
                                                class="error nocase">
                                                Product Coefficient should contain only integer values
                                            </p>
                                        </td>
                                        <td>
                                            <input type="number" class="form-control" formControlName="optLong" min="1"
                                                max="100" placeholder="Enter Options Long Product Coeffient">
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optLong.errors?.required && pf.prodCoefArray.controls[i].controls?.optLong.touched"
                                                class="error nocase">
                                                Please enter options long product coeffient
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optLong.errors?.max && pf.prodCoefArray.controls[i].controls?.optLong.touched"
                                                class="error nocase">
                                                Product Coefficient should not be greater than 100
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optLong.errors?.min && pf.prodCoefArray.controls[i].controls?.optLong.touched"
                                                class="error nocase">
                                                Product Coefficient should not be less than 1
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optLong.errors?.noDecimalNumberValidation && pf.prodCoefArray.controls[i].controls?.optLong.touched"
                                                class="error nocase">
                                                Product Coefficient should contain only integer values
                                            </p>
                                        </td>
                                        <td>
                                            <input type="number" class="form-control" formControlName="optShort" min="1"
                                                max="100" placeholder="Enter Options Short Product Coeffient">
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optShort.errors?.required && pf.prodCoefArray.controls[i].controls?.optShort.touched"
                                                class="error nocase">
                                                Please enter options short product coeffient
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optShort.errors?.max && pf.prodCoefArray.controls[i].controls?.optShort.touched"
                                                class="error nocase">
                                                Product Coefficient should not be greater than 100
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optShort.errors?.min && pf.prodCoefArray.controls[i].controls?.optShort.touched"
                                                class="error nocase">
                                                Product Coefficient should not be less than 1
                                            </p>
                                            <p *ngIf="pf.prodCoefArray.controls[i].controls?.optShort.errors?.noDecimalNumberValidation && pf.prodCoefArray.controls[i].controls?.optShort.touched"
                                                class="error nocase">
                                                Product Coefficient should contain only integer values
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="btn-group d-flex" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary col-6" data-dismiss="modal" (click)="onSubmit()"
                        aria-label="Close" [disabled]="prodCoefForm.controls.prodCoefArray.invalid"> Save </button>
                    <button type="button" class="btn btn-secondary close col-6" data-dismiss="modal"
                        aria-label=" Close"> Cancel </button>
                </div>
            </form>
        </div>
    </div>
</div>