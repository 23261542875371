<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Message Rate Change Request Detail</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eTrade/eUseridRequest/mrcChangeReport/messageRateModal" aria-current="page">Message Rate Change Request Detail</a></li>  
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body colorborder">
        <form [formGroup]="modalForm" class="formSignClass" id="messageRateRequest">
        
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Reference Number</label>
                    <input type="text" class="form-control selectpicker" formControlName="refNo" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Member Name</label>
                    <input type="text" class="form-control selectpicker" formControlName="memberName" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Member Code</label>
                    <input type="text" class="form-control" formControlName="memberCode" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Type Of Change Request</label>
                    <input type="text" class="form-control" formControlName="reqType" readonly>
                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Tap IP</label>
                    <input type="text" class="form-control" formControlName="tapIP" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Category</label>
                    <input type="text" class="form-control" formControlName="category" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Box ID</label>
                    <input type="text" class="form-control" formControlName="boxID" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Current Message Rate</label>
                    <input type="text" class="form-control" formControlName="currentMsgRate" readonly>
                </div>
                
            </div>
            <div class="row"> 
                <div class="col-lg-3 form-group">
                    <label>Proposed Message rate bifurcation</label>
                    <input type="text" class="form-control" formControlName="proposedMsgRate" readonly>                  
                </div>
                <div class="col-lg-3 form-group">
                    <label>Name of concerned person</label>
                    <input type="email" class="form-control" formControlName="name"readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Contact Number</label>
                    <input type="text" class="form-control" formControlName="number" readonly>
                    
                </div>
                <div class="col-lg-3 form-group">
                    <label>Email Address</label>
                    <input type="text" class="form-control" formControlName="email" readonly>                  
                </div>
                
            </div>
            <div class="row"> 
                <div class="col-lg-3 form-group">
                    <label>Current Status</label>
                    <input type="email" class="form-control" formControlName="currentStatus"readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Remarks</label>
                    <input type="text" class="form-control" formControlName="remarks" readonly>
                    
                </div>
            </div>
            <div class="row justify-content-start mb-5">
                <div class="col-lg-4">
                    <div class="row justify-content-start">
                       
                        <!-- <div class="col-lg-3">
                            <button type="button" class="btn btn-cancel full-width" (click)="reset()">Reset</button>
                        </div> -->
                        <div class="col-lg-4">
                            <button type="button" class="btn btn-cancel full-width" (click)="back()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
