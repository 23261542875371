import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'rxjs/internal/config';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import {EnitComplianceServiceService} from 'src/app/service/enit-compliance-service.service'
declare var $: any;

@Component({
  selector: 'app-ml-and-ai-component-admin-list',
  templateUrl: './ml-and-ai-component-admin-list.component.html',
  styleUrls: ['./ml-and-ai-component-admin-list.component.css']
})
export class MlAndAiComponentAdminListComponent implements OnInit {

  entityType: any;
  user: any ={};
  access: any ={};
  searchForm: FormGroup;
  QuarterstartDateList:any=[];
  mlaireport:any =[];
  config: any = config;
  memList = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bsValue: any;
  bsValue1: any;
  memName: string;
  memCode: string;
  fDate: any;
  tDate: any;
  menucode = "mlaireportAdmin";
  maxDate: Date = new Date();

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(  private toastr: ToastrService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private enitComplianceServiceService :EnitComplianceServiceService,
    private formbuilder : FormBuilder,
    private userDetailsService : UserDetailsService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe) {   }

  get f() {
    return this.searchForm.controls
  }
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.getQuarterDropDowndata();
    this.getAllMembers();
    this.titleService.setTitle('Machine Learning & Artificial Intelligence Report');
    $('.selectpicker').selectpicker();
 
    this.searchForm = this.fb.group({
      memname: [],
      memCode: [],
      submissionFDate: [''],
      submissiontTDate: [''],
      QuarterstartDate:['']
    })
    this.onSubmit();
  }

  setDatatable(){
    setTimeout(() => {
      $("#mlaiMIS").DataTable({
      responsive: true,
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      buttons: [
        {
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          }
        },
      ]
      }, 1)

    })
      
  }
  getQuarterDropDowndata() {
    this.enitComplianceServiceService.getQuarterDropDowndata().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      this.QuarterstartDateList=[];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.QuarterstartDateList = response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }

  getAllMembers(){
    this.enitComplianceServiceService.getAllMembers().pipe(
      catchError((error: any) => {
        this.memList = [];
        this.dtTrigger.next(true);
        this.cdref.detectChanges();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.memList= response.data.memberDetails;
        this.cdref.detectChanges();
         $('.selectpicker').selectpicker('refresh');
      } else {
        this.memList = [];
        //this.dtTrigger.next(true);
        this.cdref.detectChanges();
        this.toastr.error(response.message);
      }
    });
  }

  GenerateSebiReport() {
    
  }

  open(id:number){
    this.router.navigate(['complianceAdmin/mlAndAiAdmin/mlaireportAdmin/mlAiForm'], { "queryParams": { "id": id } }) 
  }

  onReset() {
    this.f.submissionFDate.setValue(null);
    this.f.submissiontTDate.setValue(null);
    this.f.QuarterstartDate.setValue(null);
    this.f.memname.setValue(null)
    this.f.memCode.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }


  memCodeChange(){
    let member = this.memList.find(data => data.memberId == this.f.memCode.value);
    this.f.memname.setValue(member.memberName);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }
  

  onSubmit(){
    $("#mlaiMIS").DataTable().destroy();
    let requestObject = {
      "isAdmin": null,
      "memId":  this.f.memCode.value || null,
      "memName": null,
      "memCode":null,
      "formHidden": null,
      "qtrStartDateStr": this.datepipe.transform(this.f.submissionFDate.value, 'dd-MMM-yyyy')|| null,
      "qtrLastDateStr": this.datepipe.transform(this.f.submissiontTDate.value, 'dd-MMM-yyyy')|| null,
      "isSubmit": null,
      "isDataUploaded": null,
      "memRefId": null,
      "quarterStartDate":this.f.QuarterstartDate.value || null,
      "presentFlag": false
    }
    this.spinner.show();
    this.enitComplianceServiceService.getMLandAiReport(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.mlaireport = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((respone: any) => {
      this.spinner.hide();
      if (respone.statusCode == 200) {
        this.mlaireport = respone.data;
        this.enitComplianceServiceService.addMlAiList(this.mlaireport);
        this.setDatatable();
      }
      else if (respone.statusCode == 404) {
        this.toastr.warning("No Data Found")
        this.setDatatable();
      }
      else {
        this.toastr.error(respone.message)
        this.setDatatable();
      }
    })
  }
}
