<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>User Control</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"><a routerLink="/usercontrol" skipLocationChange>User Control</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body colorborder">
        <form [formGroup]='searchUserForm'>
            <div class="row">
                <div class="col-lg-2 form-group">
                    <label>Login ID<span class="red">*</span> </label>
                    <div class="input-group mb-3">
                        <input type="text" value="" class="form-control" formControlName="userLoginId"
                            placeholder="Enter Login ID">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-search pointer" viewBox="0 0 16 16" (click)="getMenuList()">
                                    <path
                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="f.userLoginId.invalid && f.userLoginId.touched">
                        <p *ngIf="f.userLoginId.errors.required" class="error nocase">
                            Please enter login ID
                        </p>
                    </div>
                </div>
                <div class="col-lg-2 form-group" *ngIf="menuList && menuList.length>0">
                    <label>Module</label>
                    <select class="form-control selectpicker" formControlName="menuCode" data-style="btn-info"
                        (change)="selectMenu()" title="Select Menu">
                        <option *ngFor="let data of menuListFinal" value="{{data.menuCode}}">{{data.menuName}}
                        </option>
                    </select>
                    <p *ngIf="f.menuCode.invalid && f.menuCode.touched" class="error nocase">
                        Please enter menu
                    </p>
                </div>
                <div class="col-lg-2 form-group" *ngIf="subMenuList && subMenuList.length>0">
                    <label>Menu </label>
                    <select class="form-control selectpicker" formControlName="subMenuCode" data-style="btn-info"
                        (change)="selectSubMenu()" title="Select Menu">
                        <option *ngFor="let data of subMenuList" value="{{data.menuCode}}">{{data.menuName}}
                        </option>
                    </select>
                    <p *ngIf="f.subMenuCode.invalid && f.subMenuCode.touched" class="error nocase">
                        Please enter sub menu
                    </p>
                </div>
                <div class="col-lg-2 form-group" *ngIf="subMenuList2 && subMenuList2.length>0">
                    <label>Sub Menu </label>
                    <select class="form-control selectpicker" formControlName="subMenuCode2" data-style="btn-info"
                        title="Select Menu">
                        <option *ngFor="let data of subMenuList2" value="{{data.menuCode}}">{{data.menuName}}
                        </option>
                    </select>
                    <p *ngIf="f.subMenuCode2.invalid && f.subMenuCode2.touched" class="error nocase">
                        Please enter sub menu 2
                    </p>
                </div>
                <div class="col-lg-2 my-auto" *ngIf="menuList && menuList.length>0">
                    <button type="button" class="btn btn-primary full-width" (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </form>
        <div class="card card-body usercontrolbody">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>