<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Password Reset/Unlock Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/ePasswordResetUserId/displayAdminPasswordMis/modalForm" aria-current="page">Password Reset/Unlock Request</a></li>
                    <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUnlockuser/displayPasswordMis/modalForm" aria-current="page">Password Reset/Unlock Request</a></li>
                   
                </ul>
            </div>
        </div>
    </div>    
    <!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please wait.</p>
    </ngx-spinner>   -->
    <div class="card card-body colorborder">
        <form [formGroup]="modalForm">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Request Reference Number</label>
                    <input type="text" class="form-control" formControlName="refNumber" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Corporate Manager User ID</label>
                    <input type="text" class="form-control" formControlName="mgrUserId" readonly>
                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Member Name</label>
                    <input type="text" class="form-control" formControlName="memName" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Member Code</label>
                    <input type="text" class="form-control" formControlName="memCode" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Processing date & time</label>
                    <input type="text" class="form-control" formControlName="procDateTIme" readonly>
                </div>
                

                <div class="col-lg-3 form-group">
                    <label>Request Date & Time</label>
                    <input type="text" class="form-control" formControlName="reqDateTime" readonly>
                </div>
            </div>
<div *ngIf="entityType=='ENIT'">
    <div class="row">
        <div class="col-lg-1">
            <button class="btn btn-cancel full-width"  (click)="back()">Back</button>
        </div>
    </div>

</div>
        <div *ngIf="entityType!='ENIT'">
            <div class="row">  
                

                <div class="col-lg-3 form-group">
                    <label>Status</label>
                    <input type="text" *ngIf="modalData.pcmStatus!='PENDING'" class="form-control" formControlName="status" readonly>
                    <select class="form-control selectpicker" *ngIf="modalData.pcmStatus=='PENDING'" formControlName="UpdateStatus" data-style="btn-info" title="Select Request Status">
                       
                        <option value="COMPLETED">Completed</option>
                        <option value="REJECTED-USER STATUS IS ACTIVE">Rejected-User Status Is Active</option>
                        <option value="REJECTED-USER IS ALREADY UNLOCKED">Rejected-User Is Already Unlocked</option>
                        <!-- <option value="REJECTED-PASSWORD IS ALREADY DEFAULT">Rejected-Password Is Already default</option> -->
                        <!-- <option value="REJECTED">Rejected</option> -->
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 form-group">
                    <div class="row">
                        <div class="col-lg-4">
                            <button class="btn btn-cancel full-width"  (click)="back()">Back</button>
                        </div>
                        <div class="col-lg-4" *ngIf="modalData.pcmStatus=='PENDING'">
                            <button class="btn btn-primary full-width" [disabled]="modalForm.invalid"  (click)="onSubmit()">Submit</button>
                        </div>
                        <div class="col-lg-4" *ngIf="modalData.pcmStatus=='PENDING'">
                            <button class="btn btn-cancel full-width"  (click)="reset()">Reset</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        </form>
     </div>   

</div>