<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <!-- <h1>{{action=='add'?'Add Neat User':'Process New Neat Request'}}</h1> -->
                <h1>Process New Neat Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/AdminUserIdRequestProcessPage"
                            aria-current="page">Process New Neat Request</a></li>
                     <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUseridRequest/displayUIRequestProcessPage/AdminUserIdRequestProcessPage"
                                aria-current="page">Process New Neat Request</a></li>
                      
                </ul>
            </div>
        </div>
    </div>
    <div>
        <form [formGroup]="newNeatRequest">
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Member Code</label>
                            <input type="text" class="form-control" readonly formControlName="memId">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Member Name</label>
                            <input type="text" class="form-control" readonly formControlName="memName">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>SEBI Reg No</label>
                            <input type="text" class="form-control" readonly formControlName="sebiRegNo">
                        </div>
                        <div class="col-lg-3 form-group" *ngIf="reqObj.cusReqStatus=='APPR'">
                            <label>Request Id</label>
                            <input type="text" class="form-control" readonly formControlName="reqId">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group" *ngIf="reqObj.cusReqStatus=='APPR'">
                            <label>Dealer Id Assigned</label>
                            <input type="text" class="form-control" readonly formControlName="assignedDlrId">
                        </div>
                        <div class="col-lg-3 form-group" *ngIf="reqObj.cusReqStatus=='APPR'">
                            <label>Tap Id Assigned</label>
                            <input type="text" class="form-control" readonly formControlName="assignedTapId">
                        </div>
                    </div>
                </div>
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h5>Registered Office Details</h5>
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Address</label>
                            <textarea class="form-control" readonly  formControlName="address"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>TAP IP</label>
                            <input type="text" class="form-control" readonly formControlName="tapIp">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>No of existing terminals at the above equipment Id</label>
                            <input type="text" class="form-control" readonly formControlName="noOfExistingTerminals">
                        </div>
                    </div>
                </div>
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h5>Office Where Terminal Is Located</h5>
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Address</label>
                            <textarea class="form-control" readonly value="" formControlName="address1"></textarea>
                        </div>
                    </div>
                </div>

                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h5>Details of the Proposed User</h5>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" readonly  formControlName="title">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>First Name</label>
                            <input type="text" class="form-control" readonly  formControlName="firstName">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Middle Name</label>
                            <input type="text" class="form-control" readonly   formControlName="middleName">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Last Name</label>
                            <input type="text" class="form-control" readonly  formControlName="lastName">
                        </div>

                    </div>
                    <div class="row">
                      
                        <div class="col-lg-3 form-group">
                            <label>Certified By</label>
                            <input type="text" class="form-control" readonly   formControlName="certifiedBy">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Registration No Of Certificate</label>
                            <input type="text" class="form-control" readonly   formControlName="registrationNo">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Date Of Birth</label>
                            <input type="text" class="form-control" readonly   formControlName="dob">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Validity Date </label>
                            <input type="text" class="form-control" readonly   formControlName="validityDate">
                        </div>
                        <!-- <div class="col-lg-3 form-group">
                            <label>Percentage</label>
                            <input type="text" class="form-control" readonly   formControlName="percentage">
                        </div> -->

                        <div class="col-lg-3 form-group">
                            <label>PAN Number</label>
                            <input type="text" class="form-control" readonly   formControlName="panNo">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Mobile Number</label>
                            <input type="text" class="form-control" readonly  formControlName="mobileNo">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Email</label>
                            <input type="text" class="form-control" readonly  formControlName="email">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Father/Husband Name</label>
                            <input type="text" class="form-control" readonly  formControlName="fatherHusbandname">
                        </div>
                        
                        <div class="col-lg-3 form-group">
                            <label>Qualification</label>
                            <input type="text" class="form-control" readonly  formControlName="qualification">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Other details if any </label>
                            <input type="text" class="form-control" readonly formControlName="userRemarks">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Branch ID</label>
                            <input type="text" class="form-control" readonly formControlName="branchId">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Type</label>
                            <input type="text" class="form-control" readonly formControlName="userType">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Relationship of Proposed User with Trading Member/Regd. sub-broker</label>
                            <input type="text" class="form-control" readonly  formControlName="relationshipWithUser">
                        </div>
                    </div>



                </div>

                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h5>Convert the proposed User Id in CTCL/NNF</h5>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Enable NNF/CTCL facility on User id</label>
                            <input type="text" class="form-control" readonly  formControlName="nnfCtclFacility">
                        </div>
                        <div class="col-lg-3 form-group" *ngIf="entityType=='ENIT'">
                            <label>Remarks</label>
                            <input type="text" class="form-control" readonly  formControlName="rem">
                        </div>
                    </div>
                    <div class="row" *ngIf="entityType=='ENIT'">
                        <div class="col-lg-3 form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button class="btn btn-cancel full-width" type="button" (click)="back()">Back</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            <div *ngIf="entityType!='ENIT'">
                <!-- if data is submitted then load this div -->
                <div *ngIf="reqObj.cusReqStatus=='APPR' || reqObj.cusReqStatus=='RJCT'" class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Current Status </label>
                            <input *ngIf="reqObj.cusReqStatus=='APPR'" type="text" class="form-control" readonly
                                value="APPROVED">
                            <input *ngIf="reqObj.cusReqStatus=='RJCT'" type="text" class="form-control" readonly
                                value="REJECTED">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Remarks  </label>
                            <input type="text" class="form-control" readonly formControlName="rem">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- otherwise load this div for submission of data -->

                <div *ngIf="reqObj.cusReqStatus==null || !reqObj.cusReqStatus || reqObj.cusReqStatus=='' || reqObj.cusReqStatus=='SUB'"
                    class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                    
                        <div class="col-lg-3 form-group">
                            <label>Select Status</label>
                            <select class="form-control selectpicker" formControlName="updatedStatus" (change)="onStatusChange()" title="Select Status">
                                <option value="" hidden></option>
                                <option value="APPR">APPROVED</option>
                                <option value="RJCT">REJECTED</option>
                                <!-- <option value="UNDER_PROC">UNDER PROCESSING</option>
                                <option value="DOC_AWAT">DOCUMENT AWAITED</option>
                                <option value="DOC_RECVD">DOCUMENT RECEIVED</option> -->
                            </select>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Remarks</label>
                            <input type="text" class="form-control" formControlName="remarks">

                        </div>
                        <div class="col-lg-3 form-group" *ngIf ="f.updatedStatus.value=='APPR'">
                            <label>Dealer Id</label>
                            <input type="number" class="form-control" formControlName="dealerId" >
                            <!-- <p *ngIf="f.dealerId.errors && f.dealerId.touched" class="error nocase">
                               Dealer ID Required
                            </p> -->
                            <div *ngIf="f.dealerId.invalid && f.dealerId.touched" class="error nocase">

                                <div *ngIf="f.dealerId.errors.required">Dealer ID Required</div>
                                <!-- <div *ngIf="f.dealerId.errors.maxlength">
                                    Dealer ID cannot exceed 4 digits
                                </div> -->
                            </div>
                        </div>

                        <div class="col-lg-3 form-group"  *ngIf ="f.updatedStatus.value=='APPR'">
                            <label>Tap Id</label>
                            <input *ngIf="reqObj.cusTidId!=null" type="number" class="form-control" formControlName="tapId"  readonly >
                            <input *ngIf="reqObj.cusTidId==null" type="number" class="form-control" formControlName="tapId">
                            <!-- <p *ngIf="f.tapId.errors && f.tapId.touched" class="error nocase">
                                Tap ID Required
                             </p> -->
                             <div *ngIf="f.tapId.invalid && f.tapId.touched" class="error nocase">

                                <div *ngIf="f.tapId.errors.required">    
                                    Tap ID Required
                                </div>
                                <!-- <div *ngIf="f.tapId.errors.maxlength">
                                    Tap ID Required cannot exceed 5 digits
                                </div> -->
                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-start mb-5">
                        <div class="col-lg-4">
                            <div class="row justify-content-start">
                               
                                <div class="col-lg-3">
                                    <button  class="btn btn-cancel full-width" (click)="reset()">Reset</button>
                                </div>
                                <div class="col-lg-3">
                                    <button class="btn btn-primary full-width" [disabled]="newNeatRequest.invalid" (click)="submit()">Submit</button>
                                </div>
                                <div class="col-lg-3">
                                    <button  class="btn btn-cancel full-width" (click)="back()">Back</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </form>
    </div>


</div>