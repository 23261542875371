<!-- <aside class="offcanvas sidebar" id="sidebar">
    <nav class="dashboard-nav-list">

        <a class="dashboard-nav-item" routerLinkActive="active" routerLink="" [routerLinkActiveOptions]="{exact: true}">
            <img src="assets/images/dashboard-blue.png"> dashboard </a>
        <nav class="dashboard-nav-list">
            <ul>
                <li class='dashboard-nav-dropdown' *ngFor="let menu of menuList;let i = index" aria-hidden="true">
                    <a class="dashboard-nav-item nav-link"
                        [ngClass]="{'dropdown dashboard-nav-dropdown-toggle': menu?.userMenusImpls?.length>0}"
                        data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"
                        [routerLink]="menu.menuCode" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: false}">
                        {{menu.menuName}}</a>

                    <ng-container *ngIf="menu?.userMenusImpls?.length> 0">
                        <ng-container class='dashboard-nav-dropdown'
                            *ngFor="let sublist of menu.userMenusImpls; let j = index" aria-hidden="true">
                            <div class='dashboard-nav-dropdown-menu dropdown-menu' (click)="menuRedirection($event)">
                                <a [ngClass]="{'dropdown dashboard-nav-dropdown-toggle nav-link dashboard-nav-item dropdown-submenu': sublist?.userMenusImpls?.length> 0,
                                    'dashboard-nav-dropdown-item':sublist?.userMenusImpls==null}"
                                    data-toggle="dropdownlevel2" role="button" aria-haspopup="true"
                                    aria-expanded="false" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: false}"
                                    [routerLink]="'/'+menu.menuCode+'/'+sublist.menuCode">{{sublist.menuName}}</a>
                            </div>
                            <ng-container *ngIf="sublist?.userMenusImpls?.length> 0">
                                <ng-container *ngFor="let submenu of sublist.userMenusImpls"
                                    class='dashboard-nav-dropdown' aria-hidden="true">
                                    <div class='dashboard-nav-dropdown-menu dropdown-menu'
                                        (click)="stopRedirection($event)">
                                        <a class="dashboard-nav-dropdown-item" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: false}"
                                            [routerLink]="'/'+menu.menuCode+'/'+sublist.menuCode+'/'+submenu.menuCode">{{submenu.menuName}}</a>
                                    </div>
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </ng-container>
                </li>
            </ul>
        </nav>
    </nav>
</aside> -->





<div class="offcanvas sidebar" id="sidebar">

    <a class="dashboard-nav-item" routerLinkActive="active" routerLink="" [routerLinkActiveOptions]="{exact: true}">

        <img src="assets/images/dashboard-blue.png"> dashboard </a>

    <nav class="dashboard-nav-list">

        <ul>

            <!-- <li class='dashboard-nav-dropdown' *ngFor="let menu of menuListFinal" aria-hidden="true"> -->

            <li class='dashboard-nav-dropdown' *ngFor="let menu of menuListFinal" aria-hidden="true">
                <!-- <span> <img src='{{"assets/images/New_images/"+menu.menuCode+".png"}}' alt="Avatar" class="image "> <a class="dashboard-nav-item nav-link" *ngIf="menu?.sappType=='I'"
                    [ngClass]="{'dropdown dashboard-nav-dropdown-toggle': menu?.userMenusImpls?.length>0}"
                    data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                    [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active" [routerLink]="menu.menuCode">

                    {{menu.menuName}} </a>
                </span> -->

                <a class="dashboard-nav-item nav-link" *ngIf="menu?.sappType=='I'"
                    [ngClass]="{'dropdown dashboard-nav-dropdown-toggle': menu?.userMenusImpls?.length>0}"
                    data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                    [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active" [routerLink]="menu.menuCode">

                    <img src='{{"assets/images/New_images/"+menu.menuCode+".png"}}' alt="Avatar" class="image ">
                    {{menu.menuName}} </a>

                <ng-container *ngIf="menu?.userMenusImpls?.length> 0">
                    <div class='dashboard-nav-dropdown-menu dropdown-menu dropdown' (click)="stopRedirection($event)">

            <li *ngFor="let sublist of menu.userMenusImpls; let i = index" class="dashboard-nav-dropdown-item"
                [ngClass]="{' dropdown d-block': sublist?.childmenu?.length>0}" data-toggle="dropdown" role="button"
                aria-haspopup="true" aria-expanded="false" routerLinkActive="active">

                <a [ngClass]="{'dropdown dashboard-nav-dropdown-toggle': sublist?.userMenusImpls?.length> 0}"
                    *ngIf="sublist?.sappType=='I'" [routerLink]="'/'+menu.menuCode+'/'+sublist.menuCode"
                    (click)="hiddenMenus[i]=!hiddenMenus[i]" [routerLinkActiveOptions]="{exact: false}"
                    class="dashboard-nav-child">{{sublist.menuName}} </a>


                <a *ngIf="sublist?.sappType=='E'" (click)="accessApplication(sublist)"
                    class="dashboard-nav-child">{{sublist.menuName}}</a>
                <ng-container *ngIf="sublist?.userMenusImpls?.length> 0">

                    <ul>

                        <li class="dashboard-nav-dropdown-item" routerLinkActive="active"
                            *ngFor="let submenu of sublist.userMenusImpls">

                            <a [routerLink]="'/'+menu.menuCode+'/'+sublist.menuCode+'/'+submenu.menuCode"
                                [routerLinkActiveOptions]="{exact: true}" *ngIf="submenu?.sappType=='I'"
                                class="dashboard-nav-child">{{submenu.menuName}} </a>

                            <a *ngIf="submenu?.sappType=='E'" (click)="accessApplication(submenu)"
                                class="dashboard-nav-child">{{submenu.menuName}} </a>

                        </li>

                    </ul>

                </ng-container>

            </li>
</div>

</ng-container>
<a class="dashboard-nav-item nav-link" *ngIf="menu?.sappType=='E'" role="button" (click)="accessApplication(menu)">
    <img src='{{"assets/images/New_images/"+menu.menuCode+".png"}}' alt="Avatar" class="image "> {{menu.menuName}} </a>
</li>
</ul>

</nav>

</div>