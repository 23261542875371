import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { idLocale } from 'ngx-bootstrap/chronos';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BranchUserService } from 'src/app/service/branch-user.service';
import { CelService } from 'src/app/service/cel.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-edit-limit-setup',
  templateUrl: './edit-limit-setup.component.html',
  styleUrls: ['./edit-limit-setup.component.css']
})
export class EditLimitSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  access: any = {};
  user: any = {};
  users: any = [];
  branchUserData: any = {};
  userForm: FormGroup;
  prodCoefForm: FormGroup;
  selectedSymbol: string = '';
  branchId: string = '';
  submittedBranch: boolean = false;
  submittedUser: boolean = false;
  action: string = 'add';
  symbolData: any = ['NIFTY', 'NIFTYIT', 'BANKNIFTY', 'FINNIFTY'];
  config: any = config;
  menucode: string = 'limitSetup';
  userControlId: string = '';

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private celService: CelService) {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.getLimitData(params['id']);
        this.action = 'edit';
      } else {
        this.action = 'add';
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Limit Setup");
    this.userForm = this.formBuilder.group({
      branchId: [null, Validators.required],
      branchName: [null, Validators.required],
      userId: [null, Validators.required],
      userName: [null, Validators.required],
      buyCreditOptLong: [null, [Validators.required, Validators.min(0), Validators.max(9999999999999)]],
      sellCreditOptShort: [null, [Validators.required, Validators.min(0), Validators.max(9999999999999)]],
      buyCreditFuture: [null, [Validators.required, Validators.min(0), Validators.max(9999999999999)]],
      sellCreditFuture: [null, [Validators.required, Validators.min(0), Validators.max(9999999999999)]],
      prodCoef: this.formBuilder.array([])
    })
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
  }

  ngAfterViewInit() {
    $('.selectpicker').selectpicker();
  }

  get uf() { return this.userForm.controls; }

  getLimitData(userId) {
    this.celService.getCelLimitPerUser(userId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.branchUserData = {};
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.branchUserData = response.data[0];
          this.setFormData();
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.branchUserData = {};
          this.setDatatable();
        }

      }
    );
  }

  setFormData() {
    this.uf.branchId.setValue(this.branchUserData.branchId);
    this.uf.branchName.setValue(this.branchUserData.branchName);
    this.uf.userId.setValue(this.branchUserData.userId);
    this.uf.userName.setValue(this.branchUserData.userName);
    this.uf.buyCreditOptLong.setValue(this.branchUserData.buyCreditOptLong);
    this.uf.sellCreditOptShort.setValue(this.branchUserData.sellCreditOptShort);
    this.uf.buyCreditFuture.setValue(this.branchUserData.buyCreditFuture);
    this.uf.sellCreditFuture.setValue(this.branchUserData.sellCreditFuture);
    let prodCoefControl = <FormArray>this.uf.prodCoef;
    this.branchUserData.prodCoef.forEach(data => {
      prodCoefControl.push(this.formBuilder.group({
        symbol: [data.symbol],
        optShort: [data.optShort, [Validators.required, Validators.min(0), Validators.max(100)]],
        optLong: [data.optLong, [Validators.required, Validators.min(0), Validators.max(100)]],
        futures: [data.futures, [Validators.required, Validators.min(0), Validators.max(100)]]
      })
      )
    })
    this.cdref.detectChanges();
  }

  onSubmit() {
    this.submittedBranch = true;
    this.cdref.detectChanges();
    console.log(this.userForm.value);
    let requestObject = this.userForm.value;
    this.celService.saveCelLimit(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submittedBranch = false;
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("CEL limit data saved successfully");
        this.router.navigate(['../limitSetup'], { relativeTo: this.route, skipLocationChange: true });
      } else {
        this.toastr.error(response.message);
        this.submittedBranch = false;
        this.cdref.detectChanges();
      }

    })
  }

  setDatatable() {
    $("#prodCoef").DataTable().destroy();
    setTimeout(() => {
      $("#position").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "rtip"
      });
      this.cdref.detectChanges();
    }, 1);
  }

  ngOnDestroy(): void {
    this.dtTrigger?.unsubscribe();
  }
}
