<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>CTCL ID De-Activation</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eTrade/eMemberReportingPreTrade/displayCtclDeactivation"
                            aria-current="page">CTCL ID De-Activation</a></li>

                </ul>
            </div>

        </div>
    </div>

    <form [formGroup]="ctclRequest">
        <div class="container-fluid mt-3 mb-3 theme">
            <div formGroupName="ctclRequestDetails">

                <div class="card">
                    <div class="card-body">
                        <div class="row">


                            <ng-container *ngIf="displayFromReport==='YES'; else neatIdForNonReport">
                                <div class="col-lg-3 form-group">
                                    <label>NEAT User ID<span class="red">*</span> </label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsNeatUserId"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsNeatUserIdDisable">
                                </div>
                            </ng-container>

                            <ng-template #neatIdForNonReport>
                                <div class="col-lg-3 form-group">
                                    <label>NEAT User ID<span class="red">*</span> </label>
                                    <select class="form-control neatSelectpicker" formControlName="ctclRqdtlsNeatUserId"
                                        data-style="btn-info" (change)="neatUserIdChange()" [attr.disable]>
                                        <option selected value="">--select--</option>
                                        <option *ngFor="let neatUser of neatUserIdList" value="{{neatUser}}">
                                            {{neatUser}}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>




                            <ng-container *ngIf="displayFromReport==='YES'; else terminalIdForNonReport">
                                <div class="col-lg-3 form-group">
                                    <label>12 Digit CTCL Terminal Id<span class="red">*</span> </label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsTerminalId"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsTerminalIdDisable">
                                </div>
                            </ng-container>

                            <ng-template #terminalIdForNonReport>
                                <div class="col-lg-3 form-group">
                                    <label>12 Digit CTCL Terminal Id<span class="red">*</span> </label>
                                    <select class="form-control terminalSelectpicker"
                                        formControlName="ctclRqdtlsTerminalId" data-style="btn-info"
                                        (change)="terminalIdChange()">
                                        <option selecetd value="">--select--</option>

                                        <option *ngFor="let terminalId of terminalIdList"
                                            value="{{terminalId.terminalIdUnqData}}">{{terminalId.terminalId}}
                                        </option>


                                    </select>

                                </div>
                            </ng-template>



                            <div class="col-lg-3 form-group">
                                <label>Login id for 12 Digit CTCL Terminal Id </label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsLoginId"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLoginIdDisable">
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Purpose of CTCL Id</label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsPurposeCtclId"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsPurposeCtclIdDisable">
                            </div>
                        </div>

                        <div class="row">

                            <ng-container *ngIf="displayFromReport==='YES'; else dateOfActivationForNonReport">
                                <div class="col-lg-6 form-group">
                                    <label>Date of activation of the 12 digit CTCL id by the trading member to the
                                        approved
                                        person
                                        operating
                                        terminal<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsAllotDateStr"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsAllotDateDisable">
                                </div>
                            </ng-container>

                            <ng-template #dateOfActivationForNonReport>
                                <div class="col-lg-6 form-group">
                                    <label>Date of activation of the 12 digit CTCL id by the trading member to the
                                        approved
                                        person
                                        operating
                                        terminal<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsAllotDateStr" disabled>
                                </div>
                            </ng-template>



                            <ng-container *ngIf="displayFromReport==='YES'; else dateOfDeactivationForNonReport">
                                <div class="col-lg-3 form-group">
                                    <label>Date of Disablement of CTCL Id<span class="red">*</span> </label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsDisableDateStr"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsDisableDateDisable">
                                </div>
                            </ng-container>

                            <ng-template #dateOfDeactivationForNonReport>
                                <div class="col-lg-3 form-group">
                                    <label>Date of Disablement of CTCL Id<span class="red">*</span> </label>
                                    <input type="date" value="" class="form-control" id="ctclRqdtlsDisableDate"
                                        formControlName="ctclRqdtlsDisableDate"
                                        (blur)="deactivationDateCalculation($event.target.value)">
                                    <div class="error nocase" *ngIf="deactivationDateError !='' ">
                                        {{deactivationDateError}}</div>
                                </div>
                            </ng-template>


                        </div>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-header">
                        Office Details
                    </div>
                    <div class="card-body">
                        <div formGroupName="ctclRqdtlsLocationId">
                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label>Status of Office where CTCL terminal is located</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclLocdtlsOfficeStatus"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclLocdtlsOfficeStatusDisable">
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="!ctclRequest.get('addressValid').value; else existingLocationDisplay">
                            <div formGroupName="existingAddressDtls">
                                <h4> Existing Address</h4>
                                <div class="row">
                                    <div class="col-lg-3 form-group">
                                        <label>Address Line1</label>
                                        <input type="text" value="" class="form-control" formControlName="adFlatNo"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.adFlatNoDisable">
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Address Line2</label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="adBuildingName"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.adBuildingNameDisable">
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Address Line3</label>
                                        <input type="text" value="" class="form-control" formControlName="adFloor"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.adFloorDisable">
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>City</label>
                                        <input type="text" value="" class="form-control" formControlName="cityName"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.cityNmDisable">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3 form-group">
                                    <div formGroupName="existingAddressDtls">
                                        <label>State</label>
                                        <input type="text" value="" class="form-control" formControlName="stateName"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.stateNmDisable">
                                    </div>
                                </div>

                                <div class="col-lg-3 form-group">
                                    <div formGroupName="existingAddressDtls">
                                        <label>Pin Code<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adPincode"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.adPincodeDisable">
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="row">
                                        <div class="col-lg-12 form-group">
                                            <div
                                                [ngStyle]="{'color': '#666666','font-size':'12px','line-height':'17px','font-family':'Poppins SemiBold','text-transform':'capitalize'}">
                                                Telephone Number</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 form-group">
                                            <div formGroupName="ctclRqdtlsLocationId">
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclAddStdNo"
                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclAddStdNoDisable">
                                            </div>
                                        </div>

                                        <div class="col-lg-8 form-group">
                                            <div formGroupName="ctclRqdtlsLocationId">
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclAddPhoneNo"
                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclAddPhoneNoDisable">
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <div formGroupName="ctclRqdtlsLocationId">
                                            <label>Mode of Connectivity</label>
                                            <input type="text" value="" class="form-control"
                                                formControlName="ctclLocdtlsConnMode"
                                                [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclLocdtlsConnModeDisable">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>


                    <ng-template #existingLocationDisplay>
                        <div formGroupName="ctclRqdtlsLocationId">
                            <div formGroupName="addressDtls">
                                <div class="row">
                                    <div class="col-lg-3 form-group">
                                        <label>Flat/Door No<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adFlatNo"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adFlatNoDisable">
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Building Name<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="adBuildingName"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adBuildingNameDisable">
                                    </div>


                                    <div class="col-lg-3 form-group">
                                        <label>Floor</label>
                                        <input type="text" value="" class="form-control" formControlName="adFloor"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adFloorDisable">
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Street/Road Name<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adStreetName"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adStreetNameDisable">
                                        <small class="error nocase"
                                            *ngIf="reqAddrDtls.get('adStreetName')?.errors && reqAddrDtls.get('adStreetName')?.errors.required">Field
                                            is required</small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-3 form-group">
                                        <label>Landmark</label>
                                        <input type="text" value="" class="form-control" formControlName="adLandmark"
                                            placeholder="Enter Landmark"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adLandmarkDisable">
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Area/Locality/Colony<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adColony"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adColonyDisable">
                                    </div>
                                    <div class="col-lg-3 form-group">
                                        <label>City<span class="red">*</span></label>
                                        <input type="text" value="cityName" class="form-control"
                                            formControlName="cityName"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adCityDisable">
                                    </div>


                                    <div class="col-lg-3 form-group">
                                        <label>State</label>
                                        <input type="text" value="" class="form-control" formControlName="stateName"
                                            [attr.disabled]="ctclRequestDisabledFields.addressDtls.adStateDisable">
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <div formGroupName="ctclRqdtlsLocationId">
                                    <div formGroupName="addressDtls">
                                        <label>Pin Code<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adPincode"
                                            [attr.disabled]="ctclRequestDisabledFields.existingAddressDtls.adPincodeDisable">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-12 form-group">
                                        <div
                                            [ngStyle]="{'color': '#666666','font-size':'12px','line-height':'17px','font-family':'Poppins SemiBold','text-transform':'capitalize'}">
                                            Telephone Number<span class="red">*</span></div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 form-group">
                                        <div formGroupName="ctclRqdtlsLocationId">
                                            <input type="text" value="" class="form-control"
                                                formControlName="ctclAddStdNo"
                                                [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclAddStdNoDisable">
                                        </div>
                                    </div>

                                    <div class="col-lg-8 form-group">
                                        <div formGroupName="ctclRqdtlsLocationId">
                                            <input type="text" value="" class="form-control"
                                                formControlName="ctclAddPhoneNo"
                                                [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclAddPhoneNoDisable">
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-3">
                                <div formGroupName="ctclRqdtlsLocationId">
                                    <label>Mode of Connectivity</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclLocdtlsConnMode"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsLocationId.ctclLocdtlsConnModeDisable">
                                </div>
                            </div>
                        </div>


                    </ng-template>

                </div>

                <div class="card mt-3">
                    <div class="card-header">
                        Details Of The Proposed User
                    </div>
                    <div class="card-body">
                        <div formGroupName="ctclRqdtlsExamId">

                            <div class="row">
                                <div class="col-lg-3">
                                    <label>Certified By</label>
                                </div>

                                <div class="col-lg-3">
                                    <div class="form-check" [style]="{'padding-left':'0rem'}">
                                        <label>Exam Type</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" value="NISM" class="form-check-input"
                                            id="certifiedByRadioNISM" formControlName="ctclExamDtlsExamOf"
                                            [checked]="reqExam.get('ctclExamDtlsExamOf').value==='NISM'"
                                            [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamOfDisable">
                                        <label class="form-check-label" for="certifiedByRadioNISM">NISM</label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input type="radio" value="NISM-CPE" class="form-check-input"
                                            id="certifiedByRadioNISM_CPE" formControlName="ctclExamDtlsExamOf"
                                            [checked]="reqExam.get('ctclExamDtlsExamOf').value==='NISM-CPE'"
                                            [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamOfDisable">
                                        <label class="form-check-label" for="certifiedByRadioNISM_CPE">NISM-CPE</label>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" value="NISM" class="form-check-input" id="examType"
                                            formControlName="ctclExamDtlsExamType" disabled>
                                        <label class="form-check-label" for="examType">NISM</label>
                                    </div>
                                </div>
                            </div>


                            <div class="row" [ngStyle]="{'margin-top':'15px'}">

                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-12 form-group">
                                            <div
                                                [ngStyle]="{'color': '#666666','font-size':'12px','line-height':'17px','font-family':'Poppins SemiBold','text-transform':'capitalize'}">
                                                Registration No Of Certificate</div>
                                        </div>
                                    </div>

                                    <div
                                        *ngIf="reqExam.get('ctclExamDtlsExamOf').value == 'NISM'; else nismcertificate">
                                        <div class="row">
                                            <div class="col-lg-1 form-group">
                                                <input type="text" value="" class="form-control" value="NISM"
                                                    formControlName="ctclExamDtlsExamType" id="ctclExamType1" disabled>
                                            </div>

                                            <div class="col-lg-5 form-group">
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclExamDtlsExamId"
                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                            </div>
                                        </div>
                                        <div class="row" [ngStyle]="{'margin-top':'-8px'}">

                                            <div class="col-lg-12 form-group">
                                                <div [ngStyle]="{'color': '#666666','font-size':'12px'}">NISM
                                                    Registration
                                                    No.
                                                    as issued by NISM : ************ (12 digit registration
                                                    no)</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-lg-12">
                                            <ng-template #nismcertificate>
                                                <div class="row">
                                                    <div class="col-lg-10">
                                                        <div class="row">
                                                            <div class="col-lg-1 form-group">
                                                                <input type="text" class="form-control" id="examIdPart1"
                                                                    #nismexamIdPart1
                                                                    (input)="examIdPart1 = $event.target.value"
                                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">

                                                            </div>/
                                                            <div class="col-lg-2 form-group">
                                                                <input type="text" class="form-control" id="examIdPart2"
                                                                    #nismexamIdPart2
                                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                            </div>-

                                                            <div class="col-lg-1 form-group">
                                                                <input type="text" class="form-control" id="examIdPart3"
                                                                    #nismexamIdPart3
                                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                            </div>
                                                            <div class="col-lg-2 form-group">
                                                                <input type="text" class="form-control" id="examIdPart4"
                                                                    #nismexamIdPart4
                                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                            </div>/
                                                            <div class="col-lg-2 form-group">
                                                                <input type="text" class="form-control" id="examIdPart5"
                                                                    #nismexamIdPart5
                                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                            </div>-

                                                            <div class="col-lg-2 form-group">
                                                                <input type="text" class="form-control" id="examIdPart6"
                                                                    #nismexamIdPart6
                                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row" [ngStyle]="{'margin-top':'-8px'}">

                                                    <div class="col-lg-12 form-group">
                                                        <div [ngStyle]="{'color': '#666666','font-size':'12px'}">Kindly
                                                            enter
                                                            the NISM certification details in any of the following
                                                            formats
                                                            mandatorily
                                                            CPE Code as issued by NISM: ABCDE/*****-**/***** & **/CITY-
                                                            7 digit
                                                            Certificate No.</div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label>Certificate Type</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsCertificatetype"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsCertificatetypeDisable">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <div [ngStyle]="{'color': 'red','margin-bottom':'-10px'}">Name of approved person
                                        operating CTCL Terminal</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3 form-group">
                                    <label>Title</label>
                                    <input type="text" value="" class="form-control" formControlName="ctclExamDtlsTitle"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsTitleDisable">
                                </div>

                                <div class="col-lg-3 form-group">
                                    <label>First Name</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsFirstName"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsFirstNameDisable">
                                </div>

                                <div class="col-lg-3 form-group">
                                    <label>Middle Name</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsMiddleName"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsMiddleNameDisable">
                                </div>

                                <div class="col-lg-3 form-group">
                                    <label>Last Name</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsLastName"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsLastNameDisable">
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <div [ngStyle]="{'color': 'red','margin-bottom':'-10px'}">Father's name of approved
                                    person(First name, Middle Name,Last
                                    name)</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Title</label>
                                <input type="text" value="" class="form-control" value="MR." disabled>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>First Name</label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsFatherFirstName"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsFatherFirstNameDisable">
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Middle Name</label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsFatherMiddleName"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsFatherMiddleNameDisable">
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Last Name</label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsFatherLastName"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsFatherLastNameDisable">
                            </div>
                        </div>


                        <div formGroupName="ctclRqdtlsExamId">
                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label>Date of birth of approved person</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsDobStr"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsDobStrDisable">
                                </div>

                                <div class="col-lg-6 form-group">
                                    <label>Validity of NISM/NISM-CPE Certifications of the approved person</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsValidDtStr"
                                        [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsValidDtStrDisable">
                                </div>
                            </div>

                        </div>


                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <label>PAN of the approved person operating CTCL Terminal</label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsPan"
                                    style="text-transform: uppercase;"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsPanDisable">
                            </div>

                            <div class="col-lg-6 form-group">
                                <label>Mobile Number</label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsCellContact"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsCellContactDisable">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <label>Email ID</label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsEmail"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsEmailDisable">
                            </div>

                            <div class="col-lg-6 form-group">
                                <label>Relationship of the approved person operating CTCL Terminal with trading
                                    member:</label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsRelationship"
                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsRelationshipDisable">
                            </div>
                        </div>


                        <ng-container
                            *ngIf="reqLocation.get('ctclLocdtlsOfficeStatus').value==='REGISTERED SUB-BROKER OFFICE'">
                            <div class="card mt-3">
                                <div class="card-header">
                                    Details Of The Proposed User
                                </div>
                                <div class="card-body">
                                    <div formGroupName="ctclRqdtlsSubBrokerId">

                                        <div class="row">
                                            <div class="col-lg-6 form-group">
                                                <label>Sub Broker Code to whom CTCL Terminal is allotted&nbsp;<spane
                                                        [style]="{color:'red'}">*</spane></label>
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclSubAuthDtlsSebiRegNo"
                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSebiRegNoDisable">
                                            </div>

                                            <div class="col-lg-6 form-group">
                                                <label>Name of Sub Broker to whom CTCL Terminal is allotted (if
                                                    applicable)</label>
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclSubAuthDtlsName"
                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsNameDisable">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="reqLocation.get('ctclLocdtlsOfficeStatus').value==='APPROVED AUTHORIZED PERSON OFFICE'">
                            <div class="card mt-3">
                                <div class="card-header">
                                    Authorized Person
                                </div>
                                <div class="card-body">
                                    <div formGroupName="ctclRqdtlsSubBrokerId">

                                        <div class="row">
                                            <div class="col-lg-6 form-group">
                                                <label>Name of Authorized Person to whom terminal is allotted</label>
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclAuthPerName"
                                                    [attr.disabled]="ctclRequestDisabledFields.ctclRqdtlsSubBrokerId.ctclAuthPerNameDisable">
                                            </div>

                                        </div>


                                    </div>
                                </div>

                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>

            <ng-container *ngIf="displayFromReport==='YES'">
                <div class="card mt-3">
                    <div class="card-body">
                        <ng-container *ngIf="displayFromReport ==='YES'">
                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label>Current Status<span class="red">*</span></label>
                                    <input type="text" class="form-control" disabled #ctclRequestStatusDetails>
                                </div>

                                <ng-container
                                    *ngIf="memberOrAdminReport==='ADMIN' && (ctclReqStatus ==='Approved' || ctclReqStatus ==='Rejected')">
                                    <div class="col-lg-6 form-group">
                                        <label>Comment</label>
                                        <input placeholder="Please enter Approve/Reject Comment" type="text" [value]=""
                                            class="form-control" #approveRejectComment disabled
                                            formControlName="ctclReqRemarks">
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="memberOrAdminReport==='ADMIN' && (ctclReqStatus !='Approved' && ctclReqStatus !='Rejected') ">
                                    <div class="col-lg-6 form-group">
                                        <label>Comment</label>
                                        <input placeholder="Please enter Approve/Reject Comment" type="text"
                                            formControlName="ctclReqRemarks" class="form-control" #approveRejectComment>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="memberOrAdminReport !='ADMIN'">
                                    <div class="col-lg-6 form-group">
                                        <label>Comment</label>
                                        <input placeholder="Please enter Approve/Reject Comment" type="text" [value]=""
                                            class="form-control" disabled #approveRejectComment
                                            formControlName="ctclReqRemarks">
                                    </div>
                                </ng-container>

                            </div>
                        </ng-container>


                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="displayFromReport!=='YES'">
                <div class="card mt-3">
                    <div class="card-header">
                        Declaration
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="declarationCertified"
                                        (change)="declarationCertifiedChanged($event)">
                                    <p class="form-check-label" for="declarationCertified">I/We declare to abide by
                                        the
                                        rules/regulations as communicated by the Exchange/SEBI/IFSCA/other Regulators
                                        from
                                        time to
                                        time.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </ng-container>


            <ng-container *ngIf="memberOrAdminReport==='NA' && displayFromReport==='NO'">

                <div class="card mt-3">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-lg-5">
                                <div class="row">

                                    <div class="col-lg-3">
                                        <button type="button" class="btn btn-primary col-12"
                                            [disabled]="ctclRequest.invalid || errorInDeactivationDate" (click)="verifyOtpComponentCall()"
                                            data-toggle="modal" data-target="#ctcldeactivation">Certify</button>
                                    </div>

                                    <div class="col-lg-3">
                                        <button type="submit" class="btn btn-primary col-12"
                                            [disabled]="ctclRequest.invalid || !declarationCertified || !verifyOTP || errorInDeactivationDate "
                                            (click)="ctclIdDeactivationSubmit()">Submit</button>
                                    </div>
                                    <div class="col-lg-3">
                                        <button type="button" class="btn btn-secondary col-12"
                                            (click)="resetFormData()">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="memberOrAdminReport==='ADMIN' && displayFromReport==='YES' && (ctclReqStatus !=='Approved' && ctclReqStatus !=='Rejected'); else displayBackButton ">
                <div class="card mt-3">
                    <div class="card-body">

                        <app-ctcl-id-approvereject
                            (approveOrRejectSelection)="ctclApproveRejAction($event)"></app-ctcl-id-approvereject>
                    </div>
                </div>
            </ng-container>

            <ng-template #displayBackButton>
                <ng-container *ngIf="displayFromReport==='YES'">
                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-3">
                                    <button type="button" class="btn btn-secondary col-12"
                                        (click)="ctclApproveRejAction('Back')">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-template>




        </div>
    </form>
</div>

<div data-keyboard=false data-backdrop=false class="modal fade" id="ctcldeactivation" tabindex="-1" role="dialog"
    aria-labelledby="ctcldeactivationLabel" aria-hidden="true" keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
            </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verify-request-by-otp (newItemEvent)="newItemEvent($event)"
                #verifyOtpComponent></app-verify-request-by-otp>
        </div>
    </div>
</div>