import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { config } from 'src/app/valueObject/config';
import { AppService } from 'src/app/service/app.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UserControlService } from 'src/app/service/user-control.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit, AfterViewInit {
  Checkedstatus: any;
  submitted = false;
  adduserForm: FormGroup;
  user: any = {};
  action: string = 'add';
  initialEntityType: string;
  initialEntityCode: string;
  config: any = config;
  entityTypeData: any = [];
  userTypeData: any = [];
  entityCodeData: any = [];
  message: string;
  userDetails: any;
  disableEntity: boolean = true;
  disableStatus: boolean = false;
  menuCode = 'userlist';
  access: any = {};
  userControlId: string = '';
  countryCodeData: any = [];

  @ViewChild('selectpicker') selectPicker: ElementRef;


  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private appService: AppService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private userControlService: UserControlService) {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.user = this.userService.getUserById(params['id']);

      }
    });
  }

  get f() { return this.adduserForm.controls; }

  ngOnInit(): void {
    this.titleService.setTitle('User Management');
    this.getEntityTypeData();
    // this.entityTypeData = this.config.data.entityTypeData;
    this.userTypeData = this.config.data.userTypeData;
    this.userControlId = this.userControlService.getUserControlId();
    this.countryCodeData = this.config.data.countryCodeData;
    this.userDetails = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails();

    if (this.user && this.user.userId) {
      this.action = 'update';
      this.adduserForm = this.formBuilder.group({
        userLoginId: [this.user.userLoginId, [Validators.required, Validators.pattern("^[a-zA-Z0-9]{1}[a-zA-Z0-9_]{4,}$")]],
        entityType: [this.user.entityType || 'TM', Validators.required],
        entityCode: [this.user.entityCode, Validators.required],
        // userName: [this.user.userName, [Validators.required]], // Validators.pattern(".*[a-zA-Z]+.*")]],
        userName: [this.user.userName, [Validators.required, Validators.pattern("^[\-a-zA-Z0-9\(\)\&\_\@\.\ ]{1,50}$")]],
        userType: [this.user.userType, Validators.required],
        userEmail: [this.user.userEmail, [Validators.required, Validators.email]],
        countryCode: [this.user.countryCode],
        userMobile: [this.user.userMobile, Validators.pattern("^[0-9]{8,12}$")],
        isActive: [this.user.isActive, Validators.required],
        isDeleted: [this.user.isDeleted, Validators.required],
        comments: [this.user.comments]

      })
      this.adduserForm.get('userType').disable();
    } else {
      this.action = 'add';
      this.adduserForm = this.formBuilder.group({

        userLoginId: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9]{1}[a-zA-Z0-9_]{4,}$")]],
        entityType: ['TM', Validators.required],
        entityCode: [(this.userDetails.entityType != 'ADMIN') ? this.userDetails.entityCode : '', Validators.required],
        // userName: [null, [Validators.required, Validators.pattern(".*[a-zA-Z0-9&-_@. ]+.*")]], // Validators.pattern(".*[a-zA-Z]+.*")]],
        userName: [null, [Validators.required,  Validators.pattern("^[\-a-zA-Z0-9\(\)\&\_\@\.\ ]{1,50}$")]],
        userType: ['', Validators.required],
        userEmail: [null, [Validators.required, Validators.email]],
        userMobile: [null, Validators.pattern("^[0-9]{8,12}$")],
        countryCode: [91],
        isActive: ['Y', Validators.required],
        comments: [this.user.comments]
      });

    }
    if (this.userDetails.entityType == 'ADMIN') {
      this.disableEntity = false;
    }
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menuCode) : this.userDetailsService.checkUserAccess(this.menuCode);
    if (this.access.accessType === "R") {
      this.action = 'view';
      this.adduserForm.disable();
    }
    if (this.disableEntity || this.action == 'view') {
      this.adduserForm.get('entityType').disable();
      this.adduserForm.get('entityCode').disable();
    }
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  ngAfterViewInit() {
    $('.selectpicker').selectpicker();
  }

  getEntityTypeData() {
    this.appService.getEntityTypeData().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.entityTypeData = response.data[0].userCreationDropdown;
        this.entityCodeData = this.entityTypeData.find(data => data.entityType == 'TM').entityAndCompanyBeans;
        if (this.action == 'update' || this.action == 'view') {
          this.entityCodeData = this.entityTypeData.find(data => data.entityType == this.user.entityType).entityAndCompanyBeans;
          this.f.entityCode.setValue(this.user.entityCode);
          this.f.userType.setValue(this.user.userType);
        } else if (this.userDetails.entityType != 'ADMIN') {
          this.entityCodeData = this.entityTypeData.find(data => data.entityType == this.userDetails.entityType).entityAndCompanyBeans;
          this.f.entityCode.setValue(this.userDetails.entityCode);
        }
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');

      } else {
        this.toastr.error(response.message);
      }

    })
  }

  onSubmit() {
    this.submitted = true;
    let requestObject = {
      "userMaster": {
        "userId": this.user.userId || null,
        "userLoginId": this.f.userLoginId.value,
        "userName": this.f.userName.value,
        "entityCode": this.f.entityCode.value,
        "entityType": this.f.userType.value==3?'AUDITOR':this.f.entityType.value,
        "userEmail": this.f.userEmail.value,
        "countryCode": this.f.countryCode.value,
        "userMobile": this.f.userMobile.value,
        "userType": this.f.userType.value,
        "isActive": this.f.isActive.value,
        "isDeleted": this.f.isDeleted ? this.f.isDeleted.value : 'N',
        "checkedBy": this.user.checkedBy || null,
        "checkedDate": this.user.checkedDate || null,
        "createdBy": this.user.createdBy || this.userDetailsService.getUserDetails().userId,
        "createdDate": this.user.createdDate || new Date(),
        "lastModifiedBy": this.userDetailsService.getUserDetails().userId,
        "lastModifiedOn": new Date(),
        "approval": null
      },
      "sessionManagement": {
        "userId": this.userDetails.loginId,
        "jwtToken": localStorage.getItem('token'),
        "applicationName": "MEMBER-PORTAL",
        "applicationId": "55c96450-cdba-40d7-bdf1-4dbeca31f95c"

      }
    };
    if (this.action == 'add') {
      this.appService.addUser(requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.submitted = false;
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.message = 'User created successfully';
          this.toastr.success(this.message);
          this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: true });
        } else {
          this.toastr.error(response.message);
          this.submitted = false;
        }

      })
    } else {
      this.appService.updateUser(this.action, requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.submitted = false;
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.message = 'User updated Successfully. Changes in email ID (if any) will reflect after verification.';
          this.toastr.success(this.message);
          this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: true });
        } else {
          this.message = response.message;
          this.toastr.error(this.message);
        }
      })
    }
  }

  selectEntityType() {
    if (this.userDetails.entityType == 'NICCL' || this.userDetails.entityType == 'ADMIN') {
      this.f.entityCode.setValue('');
      this.f.userName.setValue('');
    }
    this.entityCodeData = this.entityTypeData.find(data => data.entityType == this.f.entityType.value).entityAndCompanyBeans;
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  selectEntityCode() {
    this.f.userName.setValue(this.entityCodeData.find(data => data.entityCode == this.f.entityCode.value).companyName);
  }
  selectBranchCode(item) {
    this.f.bankBranchCode.setValue(item.bankBranchCode);
  }

  onChangeSearchType(e) {
    this.f.userName.setValue(null);
    this.f.entityCode.setValue('');
  }
  onChangeSearchCode(e) {
    this.f.userName.setValue(null);
  }

  onChangeSearchBranchCode(e) {
  }

  onFocused(e) {

  }

  sendVerificationLink(userId) {
    this.spinner.show();
    this.appService.sendVerificationLink(userId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Verification link sent to email ID");
        } else {
          this.toastr.error(response.message);
        }
      }
    );
  }
}
