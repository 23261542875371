import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { throwError, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';
import { config } from 'src/app/valueObject/config';
import { catchError } from 'rxjs/operators';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';

declare var $: any
@Component({
  selector: 'app-aimleligible-user',
  templateUrl: './aimleligible-user.component.html',
  styleUrls: ['./aimleligible-user.component.css']
})
export class AIMLEligibleUserComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  aimlEligibleForm: FormGroup;
  fileUploadForm: FormGroup;
  config: any = config;
  message: any;
  currentDate: any;
  data: any = {};
  deleteRow: any;
  notifyUser: any;
  dtOptions: any = {};
  requestList: any = [];
  eligibleAIMLData: any = [];
  quarterDetails: any = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  selectedYear: any;
  selectedQuarter: any;
  selectedStartDate: any;
  selectedEndDate: any;
  selectedRecord: any;
  quarterList: any;
  currentYear: any;
  currentQuarter: any;
  selectedDeleteRow: any;
  notifySelectedRow: any;
  isDisabled = false;
  file: File;
  fileError: any;
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private aimlEligibleService: EnitComplianceServiceService,
    private toastr: ToastrService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private datepipe: DatePipe,
  ) { }

  get f() {
    return this.aimlEligibleForm.controls;
  }
  get g() {
    return this.searchForm.controls;
  }
  get k() {
    return this.fileUploadForm.controls;
  }

  ngOnInit(): void {
    this.titleService.setTitle('AI & ML Report')
    this.dtOptions = {
      responsive: true,
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      buttons:
        [
          {
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          },
          {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          }, {
            extend: 'pdf',
            orientation: 'landscape',
            pageSize: 'A4',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          },
        ]
    }
    this.getQuarterData();
    this.aimlEligibleForm = this.fb.group({
      quarterId: [null, Validators.required],
      memberId: [null, Validators.required],
      memberName: [null, Validators.required],
      quarterStartDate: [null, Validators.required],
      quarterEndDate: [null, Validators.required],
      memberEmail: [null, Validators.required],
    })
    this.searchForm = this.fb.group({
      year: [null, Validators.required],
      quarter: [null, Validators.required],
    })
    this.fileUploadForm = this.fb.group({
      file: ['', Validators.required],
    })
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }
    setDatatable(){
    setTimeout(() => {
      $("#eligibleAIMLTable").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons:
          [
            {
              extend: 'copy',
              titleAttr: 'Copy',
              exportOptions: {
                columns: [0, 1, 2, 3],
              }
            },
            {
              extend: 'excel',
              titleAttr: 'Export to excel',
              exportOptions: {
                columns: [0, 1, 2, 3],
              }
            }, {
              extend: 'pdf',
              orientation: 'landscape',
              pageSize: 'A4',
              titleAttr: 'Download as PDF',
              exportOptions: {
                columns: [0, 1, 2, 3],
              }
            }],
            initComplete: function () {
              this.api().columns().every(function () {
                $('#eligibleAIMLTable .column-search input').val('');
                $('#eligibleAIMLTable .column-search select').val('.*');
                $('#eligibleAIMLTable .column-search').on('keyup change', 'input', function () {
                  $('#eligibleAIMLTable').DataTable()
                    .column($(this).parent().index())
                    .search(this.value)
                    .draw();
                });
                $('#eligibleAIMLTable .column-search').on('keyup change', 'select', function () {
                  $('#eligibleAIMLTable').DataTable()
                    .column($(this).parent().index())
                    .search("^" + this.value + "$", true, false, false)
                    .draw();
                });
              })
            }
      })
      this.cdref.detectChanges();
    }, 1)  
    }


  clearSearch() {
    this.setDatatable();
  }
  getEligibleAIMLData() {
    $("#eligibleAIMLTable").DataTable().destroy();

    var requestObject;
    if (this.selectedRecord == null) {
      requestObject = {
        "qrtrStartDate": 0,
        "qrtrEndDate": 0
      }
    } else {
      requestObject = {
        "qrtrStartDate": this.datepipe.transform(this.selectedRecord.qrtrStartDate, 'yyyy-MM-dd'),
        "qrtrEndDate": this.datepipe.transform(this.selectedRecord.qrtrEndDate, 'yyyy-MM-dd')
      }
    }
    this.aimlEligibleService.getAimlEligibleData(requestObject).pipe(
      catchError((error: any) => {
        this.eligibleAIMLData = [];
        this.setDatatable();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.eligibleAIMLData = response.data;
        this.setDatatable();
      } else {
        this.eligibleAIMLData = [];
        this.setDatatable();
        this.toastr.error(response.message);
      }
    });
  }

  getQuarterData() {
    this.aimlEligibleService.getQuarterDetails().pipe(
      catchError((error: any) => {
        this.quarterDetails = [];
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        //$('.selectpicker').selectpicker('refresh');
        this.quarterDetails = response.data;
        this.quarterList = this.quarterDetails.quarterList;
        this.selectedQuarter = this.quarterDetails.currentQuarter.quarter;
        this.selectedYear = this.quarterDetails.currentQuarter.year;
        this.g.year.setValue(this.selectedYear || null);
        this.g.quarter.setValue(this.selectedQuarter || null);
        this.currentYear = this.selectedYear
        this.currentQuarter = this.selectedQuarter;

        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');

        if (this.selectedQuarter == null) {
          this.changeOnUnavilable();
          this.toastr.warning("Current Quarter data not available");
        } else {
          this.change();
          this.currentYear = this.selectedYear
          this.currentQuarter = this.selectedQuarter;
        }

        this.getEligibleAIMLData();

      } else {
        this.quarterDetails = [];
        this.toastr.error(response.message);
      }
    });
  }
  ngOnDestroy() {
    this.dtTrigger?.unsubscribe();
  }
  onReset() {
     this.searchForm.reset();
     window.location.reload();
    $('.selectpicker').selectpicker('refresh');
  }


  onSubmit() {
    this.getEligibleAIMLData();
  }
  deleteAIMLEligible() {
    let aimlReqObject = this.eligibleAIMLData.find(data => data.quarterId == this.deleteRow);
    this.aimlEligibleService.deleteQuarterTradeUser(aimlReqObject.quarterId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.message = this.selectedDeleteRow + " is deleted from current quarter list"
          this.toastr.success(this.message);
          this.getEligibleAIMLData();
        }
        else {
          this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
        }
      })
  }

  deleteFromEligibleList(quarterId: string) {
    this.deleteRow = quarterId;
    this.selectedDeleteRow = this.eligibleAIMLData.find(data => data.quarterId == this.deleteRow).memberId * 1 + 10000;
  }

  selectIdnotifySelectedUser(quarterId: string) {
    this.notifySelectedRow = this.eligibleAIMLData.find(data => data.quarterId == quarterId);
  }

  notifySelectedUser() {
    let notifyRequest = {
      "quarterId": this.notifySelectedRow.quarterId,
      "memberId": this.notifySelectedRow.memberId,
      "memberName": this.notifySelectedRow.memberName,
      "memberEmail": this.notifySelectedRow.memberEmail,
      "quarterStartDate": this.notifySelectedRow.quarterStartDate,
      "quarterEndDate": this.notifySelectedRow.quarterEndDate
    }

    this.aimlEligibleService.notifyTradeUser(notifyRequest).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          // this.message = response.message;
          this.message = "Notification is sent to " + (10000 + 1 * this.notifySelectedRow.memberId);
          this.toastr.success(this.message);
        }
        else {
          this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
        }
      }
    )
    this.getEligibleAIMLData();

  }
  change() {

    this.selectedRecord = this.quarterList.find(data => (data.year == this.g.year.value && data.quarter == this.g.quarter.value));
    this.selectedYear = this.selectedRecord.year;
    this.selectedQuarter = this.selectedRecord.quarter;
  }

  changeOnUnavilable() {
    this.selectedRecord = null;
    this.selectedYear = 0;
    this.selectedQuarter = "Q1";
  }

  changeYear() {
    this.selectedRecord = this.quarterList.find(data => (data.year == this.g.year.value && data.quarter == this.g.quarter.value));

  }
  notifyCurrentQuarterUser() {
    if (this.eligibleAIMLData = null) {
      this.toastr.warning("No members to notify")
    }
    else {
      this.selectedRecord = this.quarterList.find(data => (data.year == this.g.year.value && data.quarter == this.g.quarter.value));
      this.selectedStartDate = this.datepipe.transform(this.selectedRecord.qrtrStartDate, 'yyyy-MM-dd');
      this.selectedEndDate = this.datepipe.transform(this.selectedRecord.qrtrEndDate, 'yyyy-MM-dd');

      this.toastr.success("This will notify to all eligible members");
      var requestObject = {
        "year": this.g.year.value,
        "quarter": this.g.quarter.value,
        "qrtrStartDate": this.selectedStartDate,
        "qrtrEndDate": this.selectedEndDate,
      }
      this.aimlEligibleService.notifyAllTradeUser(requestObject).pipe(
        catchError((error: any) => {
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
      });
      this.getEligibleAIMLData();
    }
  }

  //for File upload
  onChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      if (this.file.type == 'application/vnd.ms-excel') {
        this.fileError = false;
      }
      else {
        this.fileError = true;
      }
    }
  }
  removeFile() {
    this.fileUploadForm.reset();
    this.file = null;
  }
  onFileSubmit() {
    this.spinner.show();
    this.aimlEligibleService.aiMlFileUpload(this.file).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          this.removeFile();
          this.getQuarterData();
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.removeFile();
        this.toastr.success("File uploaded successfully !!");
        this.getQuarterData();
      } else {
        this.removeFile();
        this.toastr.error(response.message);
        this.getQuarterData();
      }
    })
  }
  downloadFile() {
    // this.spinner.show();
     let fileName ="MLAI";
     this.aimlEligibleService.getFileDownloadable().pipe(
       catchError((error: any) => {
         this.spinner.hide();
         this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
         if (error.status != 200) {
           return throwError(error.error);
         }
       })
     ).subscribe((response: any) => {
      // this.spinner.hide();
       if (response.statusCode == 200) {
         let dnldFile = response.data;
         let contentType = 'application/vnd.ms-excel';
         let blob = base64StringToBlob(dnldFile, contentType);
         saveAs(blob,fileName, {
           autoBOM: true
         });
         this.toastr.success("File downloaded Succesfully");
       } else {
        this.toastr.error("File download Failed");
       }
     })
   }
}
