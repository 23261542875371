import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from 'src/app/valueObject/config'
import * as bcrypt from 'bcryptjs';
import { LoginService } from 'src/app/service/login.service';
import { Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.css']
})
export class LoginComponentComponent implements OnInit {

  submitted = false;
  config: any = config;
  captchaSource: string;
  response: any;
  loginForm: FormGroup;
  message: string;
  captchaURL: string;
  randomString: string;
  fieldTextType: boolean = false;
  oldSession: string = "";
  userPattern: string;
  captchaPattern: string;
  fakeConf: string = "";

  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private titleService: Title,
    private router: Router,
    private toastr: ToastrService) { }

  get f() { return this.loginForm.controls; }

  ngOnInit(): void {
    this.titleService.setTitle("Login");
    this.loginForm = this.formBuilder.group({
      username: [null, [Validators.required,Validators.maxLength(50)]],
      password: [null, Validators.required],
      captcha: [null, Validators.required]
    });
    this.captchaURL = this.config.URL.captchaURL;
    this.reloadCaptcha();
    this.userPattern = this.config.data.userPattern;
    this.captchaPattern = this.config.data.captchaPattern;
  }

  convertToBase64(response) {
    let responseText = response;
    let responseTextLen = responseText.length;
    let binary = "";
    for (let i = 0; i < responseTextLen; ++i) {
      binary += String.fromCharCode(responseText.charCodeAt(i) & 255)
    }
    return window.btoa(binary);
  }

  onSubmit() {
    this.submitted = true;
    let loginRequest: any = {
      "userName": this.f.username.value,
      "captcha": this.f.captcha.value,
      "randomString": this.randomString
    };
    if (!this.loginForm.valid) {
      return;
    }
    this.loginService.validateUser(this.config.URL.validateURL, loginRequest).pipe(
      catchError((error: any) => {
        this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
        this.loginForm.reset();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message) {
        let str = response.message;
        let encryptedMessage = bcrypt.hashSync("SUCCESSFULL", str.substr(0, 29))
        if (encryptedMessage == response.message) {
          let encryptedPass = null;
          if (response.data[0].entityType == 'ADMIN') {
            // encryptedPass = this.f.password.value;
            encryptedPass = CryptoJS.AES.encrypt(this.f.password.value, this.randomString).toString();

          } else {
            encryptedPass = bcrypt.hashSync(this.f.password.value, response.data[0].salt);
            localStorage.setItem(btoa('salt'), btoa(response.data[0].salt));
          }
          this.f.password.setValue(encryptedPass);
          //let authHeader = 'Basic ' + window.btoa(this.f.username.value + ":" + encryptedPass)
          this.login();
        } else {
          encryptedMessage = bcrypt.hashSync("invalidCred", str.substr(0, 29))
          if (encryptedMessage == response.message) {
            // this.message = this.config.failureResponse.login["invalidCred"];
            this.toastr.error(this.config.failureResponse.login["invalidCred"])
            this.reset();
          }
          encryptedMessage = bcrypt.hashSync("invalidCaptcha", str.substr(0, 29))
          if (encryptedMessage == response.message) {
            // this.message = this.config.failureResponse.login["invalidCaptcha"];
            this.toastr.error(this.config.failureResponse.login["invalidCaptcha"])
            this.reset();
          }
        }

        // switch (response.message) {
        //   case "SUCCESSFULL": {
        //     let encryptedPass = '';
        //     if (response.data[0].type == 'int') {
        //       encryptedPass = CryptoJS.AES.encrypt(this.f.password.value, response.data[0].salt).toString();
        //     } else {
        //       encryptedPass = bcrypt.hashSync(this.f.password.value, (response.data[0].salt));
        //       localStorage.setItem('salt', response.data[0].salt);
        //     }
        //     this.f.password.setValue(encryptedPass);
        //     //let authHeader = 'Basic ' + window.btoa(this.f.username.value + ":" + encryptedPass)
        //     this.login();
        //     break;
        //   }
        //   case "invalidCred":
        //   case "invalidCaptcha": {
        //     this.message = this.config.failureResponse.login[response.message];
        //     this.reset();
        //     break;
        //   }
        //   default: {
        //     this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
        //     this.reset();
        //     break;
        //   }
        // }
      }
    });
  }

  login() {
    let loginRequest = {
      "userName": this.f.username.value,
      "password": this.f.password.value,
      "salt": this.randomString
    };
    this.loginService.authenticateUser(this.config.URL.authenticateURL, loginRequest).pipe(
      catchError((error: any) => {
        // this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"]; //error.statusText;
        this.toastr.error(this.config.failureResponse.login["SERVICE_UNAVAILABLE"]);
        this.loginForm.reset();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message) {
        switch (response.message) {
          case "SUCCESSFULL": {
            localStorage.setItem('token', response.data && response.data[0].token || '');
            localStorage.setItem('username', this.f.username.value);
            this.toastr.success("User has been authenticated, Please enter OTP for further validation");
            this.router.navigate(['/login/otp'], { "queryParams": { 'page': 'login' } });
            break;
          }
          case "LOGGED_IN_OTHER_SYSTEM":
            $("#alreadyLoggedIn").modal('show');
            this.oldSession = response.data[0];
            break;
          case "invalidCred":
          case "invalidCaptcha":
          case "userLocked":
          case "USER_DISABLED_MESSAGE": {
            // this.message = this.config.failureResponse.login[response.message];
            this.toastr.error(this.config.failureResponse.login[response.message]);
            this.reset();
            break;
          }
          case "IS_FIRST_TIME_LOGIN_MSG":
          case "USER_PASSWORD_EXPIRED":
          case "defaultPassword": {
            localStorage.setItem('token', response.data && response.data[0].token || '');
            localStorage.setItem('username', this.f.username.value);
            this.router.navigate(['/login/passwordexpired'], { "queryParams": { 'message': response.message, 'username': this.f.username.value } });
            this.reset();
            break;
          }
          default: {
            // this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
            this.toastr.error(this.config.failureResponse.login["SERVICE_UNAVAILABLE"]);
            this.reset();
            break;
          }
        }
      }
    });
  }

  logoutFromPreviousSession() {
    localStorage.setItem('token',this.oldSession);
    this.loginService.logoutFromPreviousSession(this.oldSession).pipe(
      catchError((error: any) => {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message == "SUCCESSFULL") {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        this.login();
      }
    });
  }

  goToValidateUser(page: string) {
    this.router.navigate(['/login/validateuser'], { 'queryParams': { 'page': page } });
  }

  reset() {
    this.loginForm.reset();
    this.reloadCaptcha();
    this.fakeConf = "";
  }

  reloadCaptcha(): void {
    this.loginService.getCaptcha(this.captchaURL).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.captchaSource = response.data[0].captchaImage;
          this.randomString = response.data[0].randomString;
        }
      }
    );
  }

  toggleFieldTypeTextTrue() {
    this.fieldTextType = true;
  }

  toggleFieldTypeTextFalse() {
    this.fieldTextType = false;
  }

  removeSavePassword() {
    let inputValue = this.f.password.value || "";
    let numChars = inputValue.length;
    let showText = "";
    for (let i = 0; i < numChars; i++) {
      showText += "*";
    }
    this.fakeConf = showText;
  }

}