
<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Half Yearly Networth</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active" routerLink="eCompliance/halfYearlyReport/halfYearlyFormSubmission"><a aria-current="page">Half Yearly Networth</a></li>

                </ul>
            </div>

        </div>
    </div>


    <div class="container-fluid mt-3 mb-3 theme">
        <div class="card card-body col-lg-12 mt-3 colorborder">
            <form [formGroup]="halfYearlyForm">
                
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label for="memberName">Member Name</label>
                        <input class="form-control" readonly formControlName="memberName" id="memberName">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="memberCode">Member Code</label>
                        <input type="text" class="form-control" readonly
                            id="memberCode" formControlName="memberCode">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="asOnDate">As On Date</label>
                        <input type="text" class="form-control" readonly formControlName="asOnDate" id="asOnDate">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="nameOfCaFirm">Name of CA Firm</label>
                        <input class="form-control" id="nameOfCaFirm" formControlName="nameOfCaFirm">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label for="membershipNoCertifyingCa">Membership No. of Certifying CA</label>
                        <input type="text" class="form-control" formControlName="membershipNoCertifyingCa"
                            id="membershipNoCertifyingCa" >
                    </div>
                    <div class="col-lg-9 form-group">
                        <label for="methodOfComputation">Method of Computation of Net Worth</label>
                        <input type="text" readonly class="form-control" id="methodOfComputation" formControlName="methodOfComputation">
                    </div>
                </div>
                <hr>

                <div class="row justify-content-start">
                    <div class="col-lg-8 form-group">
                        <label for="uploadNetworthCertificate">Upload Scanned copy of Networth Certificate & Computation<em class="text-danger">*</em></label>
                        <!-- <ng-container *ngFor="let file of halfYearlyForm.controls.uploadFile.controls; let i = index"
                        [formGroupName]="i">
                        <input type="file" accept=".pdf" class="form-control"
                            id="uploadNetworthCertificate">
                            <div class="col-lg-2">
                            <i *ngIf="(i+1)<halfYearlyForm.controls.uploadFile.controls.length" class="fa fa-trash fa-x" (click)="deleteUploadFile(i)"></i>
                            <i *ngIf="(i+1)==halfYearlyForm.controls.uploadFile.controls.length" class="fa fa-plus fa-x" (click)="addUploadFile()"></i>
                            </div>
                        </ng-container> -->
                       
                        
                        <table class="table table-stripped table-bordered">
                            <thead>
                                <tr>
                                    <th class="col-lg-5 form-group">Upload Files</th>
                                    <th class="col-lg-3 form-group">Action</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="uploadScannedCertificateFile">
                                <ng-container *ngFor="let files of halfYearlyForm.controls.uploadScannedCertificateFile['controls']; let i = index" [formGroupName]="i">
                                <tr >
                                    <td>
                                        <input type="file" accept=".pdf" id="uploadFile"
                                            class="form-control">
                                    </td>
                                    <td>
                                        <i *ngIf="(i+1)<halfYearlyForm.controls.uploadScannedCertificateFile.controls.length" class="fa fa-trash fa-2x" (click)="deleteUploadFile(i)"></i>
                                        <i *ngIf="(i+1)==halfYearlyForm.controls.uploadScannedCertificateFile.controls.length" class="fa fa-plus fa-2x" (click)="addUploadFile()"></i>
                                    </td>

                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    
                    </div>
                    <div class="col-lg-4 form-group">
                        <label for="revisedUpload">Scanned copy of Revised Networth</label>
                        <input type="file" accept=".pdf" class="form-control" id="revisedUpload">
                    </div>
                </div>
                <hr>
                <h6>Computation of Networth as per IFSCA (Capital Market Intermediaries) Regulations, 2021</h6>
                <div class="row">
                    <div class="col-lg-6">
                    <table class="table table-stripped table-bordered">
                     <thead>
                      <tr>
                        <th width="70%">Particulars</th>
                        <th width="30%">Amount in USD $</th>
                      </tr>
                     </thead>
                     <tbody>
                         <tr>
                             <td colspan="2" height="56px"><b>Paid up share capital or capital contribution</b></td>
                         </tr>
                         <tr>
                             <td>Reserves created out of the profit</td>
                             <td><input type="number" name="reservedCreatedOfProfit" maxlength="15" (keypress)="validateNumber($event)" (input)="totalAcal()" formControlName="reservedCreatedOfProfit" id="reservedCreatedOfProfit" class="form-control"></td> 
                         </tr>
                         <tr>
                             <td>Credit balance of profit and loss account</td>
                             <td><input type="number" name="cerditBalanceProfitAndLoss" maxlength="15" (keypress)="validateNumber($event)" (input)="totalAcal()" formControlName="cerditBalanceProfitAndLoss" id="cerditBalanceProfitAndLoss" class="form-control"></td>
                         </tr>
                         <tr>
                            <td>Securities premium account</td>
                            <td><input type="number" name="securityPermium" maxlength="15" (keypress)="validateNumber($event)" (input)="totalAcal()" formControlName="securityPermium" id="securityPermium" class="form-control"></td>
                        </tr>
                         <tr>
                             <td><b>Total - A </b></td>
                             <td><input type="number" name="totalA"  readonly="readonly" id="totalA" formControlName="totalA" class="form-control"></td>
                         </tr>
                     </tbody>
                     </table>
                    </div>


                    <div class="col-lg-6">
                        <table class="table table-stripped table-bordered" style="width: 100%;">
                            <thead>
                             <tr>
                               <th width="70%">Particulars</th>
                               <th width="30%">Amount in USD $</th>
                             </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Aggregate value of accumulated losses</td>
                                    <td><input type="number" maxlength="15" (keypress)="validateNumber($event)" name="accumulatedLosses" (input)="totalAcal()" formControlName="accumulatedLosses" id="accumulatedLosses" class="form-control"></td>
                                </tr>
                                <tr>
                                    <td>Deferred expenditure not written off</td>
                                    <td><input type="number" maxlength="15" (keypress)="validateNumber($event)" name="deferredExpenditure" (input)="totalAcal()" formControlName="deferredExpenditure" id="deferredExpenditure" class="form-control"></td>
                                </tr>
                                <tr>
                                    <td>Miscellaneous expenses not written off</td>
                                    <td><input type="number" maxlength="15" (keypress)="validateNumber($event)" name="miscellaneousExpenses" (input)="totalAcal()" formControlName="miscellaneousExpenses" id="miscellaneousExpenses" class="form-control"></td>
                                </tr>
                                <tr>
                                    <td>Minimum net worth requirements applicable for other activities outside IFSC</td>
                                    <td><input type="number" maxlength="15" (keypress)="validateNumber($event)" name="otherActivitiesOutsideIfsc" (input)="totalAcal()" formControlName="otherActivitiesOutsideIfsc" id="otherActivitiesOutsideIfsc" class="form-control"></td>
                                </tr>
                                <tr>
                                    <td><b>Total - B </b></td>
                                    <td><input type="number" name="totalB" readonly="readonly" formControlName="totalB" id="totalB" class="form-control"></td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                    <div class="col-lg-12">
                    <table class="table table-stripped table-bordered" style="width: 100%;">
                        <tbody>
                            <tr>
                                <td>Total Networth (A-B)</td>
                                <td><input type="number" name="totalNetworth" formControlName="totalNetworth" readonly="readonly" id="totalNetworth" class="form-control"></td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
                </div>



                <div class="text-center" style="text-align: center;">
                    <button routerLink="/" class="btn btn-primary" type="submit" (click)="saveHalfYearlyForm()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
