<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Message Rate Change Report</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="tradeAdmin/eUserIDRequestA/displayMRCReport"
                            aria-current="page">Message Rate Change Request</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body colorborder">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Member Name</label>
                    <select class="form-control selectpicker" formControlName="name" data-style="btn-info"
                        title="Select Member Name" (change)="onMemberChange()" data-live-search="true">
                        <option *ngFor="let data of memAndNameList" value="{{data.spName}}">{{data.spName}}</option>
                    </select>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Member Code</label>
                    <select class="form-control selectpicker" formControlName="memCode" data-style="btn-info"
                        title="Select Member Code" (change)="onCodeChange()" data-live-search="true">
                        <option *ngFor="let data of memAndNameList" value="{{data.spMemId}}">{{data.symbol}}</option>
                    </select>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Request Type</label>
                    <select class="form-control selectpicker" formControlName="requestType" data-style="btn-info"
                        title="Select Request Type">
                        <option *ngFor="let data of refTypeList" value="{{data.refValue}}">{{data.refDesc}}</option>
                    </select>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Request Status</label>
                    <select class="form-control selectpicker" formControlName="requestStatus" data-style="btn-info"
                        title="Select Request Status">
                        <option *ngFor="let data of refStatusList" value="{{data.refValue}}">{{data.refDesc}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Request From date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker" [(bsValue)]="bsValue"
                            value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="requestFDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" placeholder="" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar" [(bsValue)]="bsValue" bsDatepicker [maxDate]="maxDate"
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Request To date</label>
                    <div class="input-group pointer">
                        <input type="text" class="form-control datepicker" [(bsValue)]="bsValue1"
                            value="{{bsValue1 | date:'MM/dd/yyyy'}}" formControlName="requestTDate" bsDatepicker
                            [bsConfig]="{ isAnimated: true}" placeholder="">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa fa-calendar" [(bsValue)]="bsValue1" bsDatepicker
                                    [bsConfig]="{ isAnimated: true }"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Reference Number</label>
                    <input type="text" class="form-control" formControlName="referenceNo">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <button class="btn btn-primary full-width" type="submit" (click)="onSubmit()">Search</button>
                        </div>
                        <div class="col-lg-6">
                            <button class="btn btn-cancel full-width"  (click)="onReset()">Reset</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </form>
        <table id="MIS" class="overflow table common-table nowrap" datatable [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th>Member Name</th>
                    <th>Member Code</th>
                    <th>Reference No.</th>
                    <th>Submission Date</th>
                    <th>Request Type</th>
                    <th>Status</th>
                    <th>Remarks</th>
                    <th>Tap IP</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of messageReport">
                    <td>{{data.mrcMemName}}</td>
                    <td>{{data.mrcMemCode}}</td>
                    <td (click)="open(data.mrcRefNum)" class="underline pointer"><u>{{data.mrcRefNum}}</u></td>
                    <td>{{data.mrcCreateddate | date}}</td>
                    <td>{{data.mrcReqType}}</td>
                    <td>{{(data.mrcReqStatus=='APPR')?'Approved': (data.mrcReqStatus=='RJCT')?'Rejected' :data.mrcReqStatus}}</td>
                    <td>{{data.mrcRemarks}}</td>
                    <td>{{data.mrcTapip}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>