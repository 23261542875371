<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>User ID Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a>Enit</a></li>
                    <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage" aria-current="page">User Id
                            Request</a></li>
                    <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUserIDRequest/displayUIRequestProcessPage" aria-current="page">User Id
                            Request</a></li>
                </ul>
            </div>
        </div>
    </div>

    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please wait.</p>
    </ngx-spinner>
    <div class="">
        <div class="row clearSearch ">
            <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>

        <table id="UserIdRequestList" class="overflow table common-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <!-- <th class="pre-wrap">No</th> -->
                    <th class="pre-wrap">Member Name</th>
                    <th class="pre-wrap">Member Code</th>
                    <th class="pre-wrap">Ref No</th>
                    <th class="pre-wrap">Submission Date</th>
                    <th class="pre-wrap">Request Type</th>
                    <th class="pre-wrap">Exam Type</th>
                    <th class="pre-wrap"> Remarks</th>
                    <th class="pre-wrap">Certificate files</th>
                    <th class="pre-wrap">Declaration files</th>
                    <th class="pre-wrap">Status</th>
                    <!-- <th class="pre-wrap">edit</th> -->
                </tr>
                <tr class="column-search">
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th>
                        <select class="form-control form-control-sm">
                            <option value=".*" default selected>SELECT</option>
                            <option value=".*NEW NEAT USER ID.*">NEW NEAT USER ID</option>
                            <option value=".*CHANGE NAME FOR USER.*">CHANGE NAME FOR USER</option>
                            <option value=".*USER ID VALIDITY UPDATION.*">USER ID VALIDITY UPDATION</option>
                            <option value=".*SHIFTING OF USER ID.*">SHIFTING OF USER ID</option>
                            <option value=".*DISABLE USER ID.*">DISABLE USER ID</option>
                            <option value=".*MULTIPLE LOCK NEAT USER ID.*">MULTIPLE LOCK NEAT USER ID</option>
                            <option value=".*CHANGE USER ID STATUS.*">CHANGE USER ID STATUS</option>
                            <option value=".*SLBM ENABLEMENT.*">SLBM ENABLEMENT</option>
                            <option value=".*SLBM DISABLEMENT.*">SLBM DISABLEMENT</option>
                            <option value=".*REMOVAL FO MULTIPLE LOCK.*">REMOVAL FO MULTIPLE LOCK</option>
                            <option value=".*USER PAN UPDATE.*">USER PAN UPDATE</option>
                            <option value=".*CHANGE NEAT USER ID OFFICE STATUS.*">CHANGE NEAT USER OFFICE STATUS</option>
                        </select>

                        <!-- <select class="form-control form-control-sm">
                            <option value=".*" default selected>SELECT</option>
                            <option value=".*NEW NEAT USER ID.*">NEW NEAT USER ID</option>
                            <option value=".*CHANGE NAME FOR USER.*">CHANGE NAME FOR USER</option>
                            <option value=".*DISABLE USER ID.*">DISABLE USER ID</option>
                        </select> -->
                    </th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th>
                        <select class="form-control ">
                            <option value=".*" default selected>Select Status</option>
                            <option value=".*APPROVED.*">APPROVED</option>
                            <option value=".*REJECTED.*">REJECTED</option>
                            <option value=".*SUBMITTED.*">SUBMITTED</option>
                            <option value=".*UNDER PROC.*">UNDER PROC</option>
                            <option value=".*DOC AWAIT.*">DOC AWAIT</option>
                            <option value=".*DOC RECVD.*">DOC RECD</option>
                        </select>
                    </th>
                    <!-- <th></th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                   
                    <td>{{user.memName}}</td>
                    <td>{{10000+user.memId}}</td>
                    <td  (click)="processNewNeatRequest(user.refNo,user.requestType)" class="btn-link underline pointer" color="blue"> <u>{{user.refNo}}</u></td>
                    <td>{{user.dlr_cert_dt}}</td>
                 <td><span *ngIf = "user.requestType == 1">NEW NEAT USER ID</span>
                    <span *ngIf = "user.requestType == 2">CHANGE NAME FOR USER</span>
                    <span *ngIf = "user.requestType == 12">USER ID VALIDITY UPDATION</span>
                    <span *ngIf = "user.requestType == 3">SHIFTING OF USER ID</span>
                    <span *ngIf = "user.requestType == 4">DISABLE USER ID</span>
                    <span *ngIf = "user.requestType == 5">MULTIPLE LOCK NEAT USER ID</span>
                    <span *ngIf = "user.requestType == 6">CHANGE USER ID STATUS</span>
                    <span *ngIf = "user.requestType == 7">SLBM ENABLEMENT</span>
                    <span *ngIf = "user.requestType == 8">SLBM DISABLEMENT</span>
                    <span *ngIf = "user.requestType == 15">REMOVAL FO MULTIPLE LOCK</span>
                    <span *ngIf = "user.requestType == 14">USER PAN UPDATE</span>
                    <span *ngIf = "">CHANGE NEAT USER OFFICE STATUS</span>
                </td>


                <!-- <td><span *ngIf = "user.requestType == 1">NEW NEAT USER ID</span>
                    <span *ngIf = "user.requestType == 2">CHANGE NAME FOR USER</span>
                    <span *ngIf = "user.requestType == 4">DISABLE USER ID</span>
                </td> -->
                    <td>{{user.cusDlrExamType}}</td>
                    <td>{{user.cusRemarks}}</td>
                    <td class="nocase pointer" (click)="downloadCertification(user.dlrCertAttachOtherFile,user.memId)"><u>{{user.dlrCertAttachOtherFile}}</u></td>
                    <td class="nocase pointer" (click)="downloadDeclaration(user.dlrDeclarationFile,user.memId)"><u>{{user.dlrDeclarationFile}}</u></td>
                    <td>
                        <span *ngIf="user.cusReqStatus == 'APPR'">APPROVED</span>
                        <span *ngIf="user.cusReqStatus == 'RJCT'">REJECTED</span>
                        <span *ngIf="user.cusReqStatus == 'SUB'">SUBMITTED</span>
                        <span *ngIf="user.cusReqStatus == 'UNDER_PROC'">UNDER PROC</span>
                        <span *ngIf="user.cusReqStatus == 'DOC_AWAT'">DOC AWAIT</span>
                        <span *ngIf="user.cusReqStatus == 'DOC_RECVD'">DOC RECVD</span>
                    </td>
                    <!-- <td><i class="fa fa-pencil pointer" aria-hidden="true" (click)="processNewNeatRequest(user.refNo,user.requestType)"></i> -->
                    <!-- </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>
