<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Message Rate Change Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eTrade/eUseridRequest/mrcChangeRequest"
                    aria-current="page">Message Rate Change Request</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body colorborder">
        <form [formGroup]="messageRateRequestForm" id="messageRateRequest">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>TAP IP<span class="red">*</span></label>
                    <select class="form-control selectpicker" formControlName="tapIP" data-style="btn-info"
                        title="Select User Id" (change)="getReadonlyData()" data-live-search="true">
                        <option *ngFor="let data of timTapIp" value="{{data.timTapIp}}">{{data.timTapIp}}</option>
                    </select>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Current Message Rate<span class="red">*</span></label>
                    <input type="text" class="form-control" formControlName="currentMsgRate" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Category<span class="red">*</span></label>
                    <input type="text" class="form-control" formControlName="category" readonly>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-3 form-group">
                    <label>Box ID</label>
                    <input type="text" class="form-control" formControlName="boxID" readonly>
                </div>
                
                <!-- <div class="col-lg-3 form-group">
                    <label>Type of Change Request<span class="red">*</span></label>
                    <select class="form-control selectpicker" formControlName="changeReqType" data-style="btn-info"
                        title=" Select Request Type">
                        <option value="MRC">Message Rate Change Request</option>
                    </select>
                </div> -->
                <div class="col-lg-3 form-group">
                    <label>Proposed Message Rate<span class="red">*</span></label>
                    <select class="form-control selectpicker" formControlName="proposedMsgRate" data-style="btn-info" title="Select Category" (change)="changeRate()">
                        <option value="100">Category A (100 m.p.s)</option>
                        <option value="200">Category B (200 m.p.s)</option>
                        <option value="400">Category C (400 m.p.s)</option>
                        <option value="800">Category D (800 m.p.s)</option>
                    </select>
                    <p *ngIf="mrcError" class="error nocase">
                        Current & proposed message rate should be different
                    </p>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Name of Concerned Person</label>
                    <input type="text" class="form-control" formControlName="name" >
                    <!-- <div *ngIf="f.name.invalid && f.name.touched">
                        <p *ngIf="f.name.errors.required" class="error nocase">
                            Please enter name
                        </p>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-lg-3 form-group">
                    <label>Name of Concerned Person<span class="red">*</span></label>
                    <input type="text" class="form-control" formControlName="name" >
                    <div *ngIf="f.name.invalid && f.name.touched">
                        <p *ngIf="f.name.errors.required" class="error nocase">
                            Please enter name
                        </p>
                    </div>
                </div> -->
                <div class="col-lg-3 form-group">
                    <label>Contact Number</label>
                    <input type="text" class="form-control" formControlName="number">
                    <div *ngIf="f.number.invalid && f.number.touched">
                        <!-- <p *ngIf="f.number.errors.required" class="error nocase">
                            Please enter mobile number
                        </p> -->
                        <p *ngIf="f.number.errors.pattern" class="error nocase">
                            Please enter valid mobile number
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Email ID</label>
                    <input type="email" class="form-control" formControlName="email">
                    <div *ngIf="f.email.invalid && f.email.touched">
                        <!-- <p *ngIf="f.email.errors.required" class="error nocase">
                            Please enter email ID
                        </p> -->
                        <p *ngIf="f.email.errors.email" class="error nocase">
                            Please enter valid email ID
                        </p>
                    </div>
                </div>
            </div>
            <!-- <div class=" form-group">
                <h6>Note: Requests placed after 10PM will be processed on next working day. 
                    Effective date of change shall be t+1 working day.</h6>
                <p> <input required type="checkbox" formControlName="confirmation">
                    We confirmed to have noted the above mentioned points.
                </p>
            </div> -->
            <div class="row justify-content-start">
                <div class="col-lg-8">
                    <div class="row justify-content-start">
                        <div class="col-lg-2">
                            <button class="btn btn-primary full-width"  [disabled]="verifyOTP!=true || submitted"  type="submit" (click)="onSubmit()">Submit</button>
                        </div>
                        <div class="col-lg-2">
                            <button type="button" class="btn btn-cancel full-width" type="reset" (click)="reset()">Reset</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn  btn-primary full-width" [disabled]="messageRateRequestForm.invalid || mrcError" data-toggle="modal"  data-target="#cashdeposite">Certify</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="cashdeposite" tabindex="-1" role="dialog" aria-labelledby="cashdepositeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
              </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verfiy-request-by-otp  [module]="module" [reqtype]="reqtype" [loginId]="loginId" [memId]="memId" (newItemEvent)="newItemEvent($event)"></app-verfiy-request-by-otp>
        </div>
    </div>
</div>