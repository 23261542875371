import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-etm-message-rate-change-report',
  templateUrl: './etm-message-rate-change-report.component.html',
  styleUrls: ['./etm-message-rate-change-report.component.css']
})
export class EtmMessageRateChangeReportComponent implements OnInit {
  searchForm: FormGroup;
  config: any = config;
  messageReport: any = []
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bsValue: any;
  bsValue1: any;
  entityType: string;
  memName: string;
  memCode: string;
  mrcList: any = [];
  refTypeList: any = [];
  refStatusList: any = [];
  memAndNameList: any = [];
  fDate: any;
  tDate: any;
  selectedMember: any;
  selectedCode: any;
  menucode = "mrcChangeReport";
  user: any = {};
  access: any = {};
  maxDate: Date = new Date();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private enitService: EnitTradeService,
    private toastr: ToastrService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private datepipe: DatePipe,
  ) { }
  get f() {
    return this.searchForm.controls
  }
  ngOnInit(): void {

    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.getDropDowndata();

    this.titleService.setTitle('Message Rate Change MIS')
    $('.selectpicker').selectpicker();
    this.titleService.setTitle('Message Rate Change MIS');
    this.searchForm = this.fb.group({
      name: [this.memName],
      memCode: [this.memCode],
      requestType: [''],
      requestStatus: [''],
      requestFDate: [''],
      requestTDate: [''],
      referenceNo: ['']
    })

    this.onSubmit();
  }
  getDropDowndata() {
    this.enitService.getDropdownData().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.refTypeList = [];
        this.refStatusList = [];
        this.memAndNameList = [];
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.refTypeList = response.data.refReqTypeList;
        this.refStatusList = response.data.refStatusList;
        this.memAndNameList = response.data.memAndNameList;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }
  setDatatable() {
    $("#MIS").DataTable().destroy();
    setTimeout(() => {
      $("#MIS").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#MIS .column-search input').val('');
            $('#MIS .column-search select').val('.*');
            $('#MIS .column-search').on('keyup change', 'input', function () {
              $('#MIS').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#MIS .column-search').on('keyup change', 'select', function () {
              $('#MIS').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  onSubmit() {
    this.spinner.show();
    $("#MIS").DataTable().destroy();
    this.fDate = this.datepipe.transform(this.f.requestFDate?.value, 'dd-MMM-yyyy');
    this.tDate = this.datepipe.transform(this.f.requestTDate?.value, 'dd-MMM-yyyy');
    let requestObject = {
      "memberIdAdminMis": this.f.memCode.value - 10000,
      "memberCodeAdminMis": null,
      "cusRequestType": this.f.requestType.value || null,
      "cusStatus": this.f.requestStatus.value || null,
      "fromDateStr": this.fDate || null,
      "toDateStr": this.tDate || null,
      "cusRefNo": this.f.referenceNo.value || null,
    }
    this.enitService.getMessageRateReport(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.messageReport = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((respone: any) => {
      this.spinner.hide();
      if (respone.statusCode == 200) {
        this.messageReport = respone.data;
        this.enitService.addMrcList(this.messageReport);
        this.setDatatable();
      }
      else if (respone.statusCode == 404) {
        this.toastr.warning("No Data Found");
      }
      else {
        this.toastr.error(respone.message)
      }

    })
  }
  onReset() {
    this.f.requestType.setValue(null);
    this.f.requestStatus.setValue(null);
    this.f.requestFDate.setValue(null);
    this.f.requestTDate.setValue(null);
    this.f.referenceNo.setValue(null);
    $('.selectpicker').selectpicker('refresh');
  }
  open(id: number) {
    this.router.navigate(['eTrade/eUseridRequest/mrcChangeReport/messageRateModal'], { "queryParams": { "id": id } }) // just for testing purpose
  }
  ngOnDestroy() {
    this.dtTrigger?.unsubscribe();
  }
}
