import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from 'src/app/service/app.service';
import { MenuService } from 'src/app/service/menu.service';
import { config } from 'src/app/valueObject/config';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.css']
})
export class AddMenuComponent implements OnInit {

  menuForm: FormGroup;
  subMenuForm: FormArray;
  menuData: any = {};
  action: string;
  config: any = config;
  allMenuData: any = [];

  get f() { return this.menuForm.controls; }

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private menuService: MenuService) {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.menuData = this.menuService.getMenuById(params['id']);
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Menu Management');
    this.allMenuData = this.menuService.getAllMenu();
    if (this.menuData && this.menuData.menuId) {
      this.action = 'update';
    } else {
      this.action = 'add';
      this.menuData = {
        menuId: null,
        menuName: null,
        menuCode: null,
        subMenuData: [{
          menuId: null,
          menuName: null,
          menuCode: null
        }]
      }
    }
    this.menuForm = this.formBuilder.group({
      menuId: [this.menuData.menuId, Validators.required],
      menuName: [this.menuData.menuName, Validators.required],
      menuCode: [this.menuData.menuCode, Validators.required],
      subMenuForm: this.formBuilder.array([])
    });
    let subMenuFormArray = <FormArray>this.f.subMenuForm;
    this.menuData.subMenuData.forEach((element, index) => {
      subMenuFormArray.push(this.formBuilder.group({
        menuId: [element.menuId],
        menuName: [element.menuName],
        menuCode: [element.menuCode]
      }));
    });
  }

  onSubmit() {
    let requestObject = {
      menuId: this.f.menuId.value,
      menuName: this.f.menuName.value,
      menuCode: this.f.menuCode.value,
      subMenuData: this.f.subMenuForm.value.filter(data => (data.menuName != null && data.menuCode != null))
    }
    this.appService.saveMenu(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.toastr.success("Menu details updated successfully");
          this.router.navigate(["../"], { relativeTo: this.route, skipLocationChange: true });
        } else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  addMenu() {
    this.menuData.subMenuData.push({
      menuId: null,
      menuName: null,
      menuCode: null
    })
    let subMenuFormArray = <FormArray>this.f.subMenuForm;
    subMenuFormArray.push(this.formBuilder.group({
      menuId: [null],
      menuName: [null],
      menuCode: [null]
    }));
  }

}
