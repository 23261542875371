import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DigiSignServiceService {
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };


  private baseURL : string = "http://localhost:8095/enit";
  // private dataObj : any ={
  //   isFormSignedFlag : null,
  //   signedFormId : null,
  //   formData:null,
  //   sifySignData:null,
  // }

  constructor(private http:HttpClient) { }

  sendDigiFormData(data : any){
    return this.http.post<any>(`${this.baseURL}/CertifyFormData`,data);
  }
}
