import { Component } from '@angular/core';

@Component({
  selector: 'app-application-popover',
  templateUrl: './application-popover.component.html',
  styleUrls: ['./application-popover.component.css']
})
export class ApplicationPopoverComponent {

}
