import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LimitSetupComponent} from './limit-setup/limit-setup.component';
import {CelRoutingModule} from './cel-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditLimitSetupComponent } from './edit-limit-setup/edit-limit-setup.component';
import { AssignAdminToBranchListComponent } from './assign-admin-to-branch-list/assign-admin-to-branch-list.component';
import { AssignAdminToBranchFormComponent } from './assign-admin-to-branch-form/assign-admin-to-branch-form.component';
import { MonitoringEnableComponent } from './monitoring-enable/monitoring-enable.component';
import { BranchAlertSetupComponent } from './branch-alert-setup/branch-alert-setup.component';
import { CelMonitoringComponent } from './cel-monitoring/cel-monitoring.component';
import { ProductCoefficientComponent } from './product-coefficient/product-coefficient.component';
import { CelUserManagementComponent } from './cel-user-management/cel-user-management.component';
import { AddCelUserComponent } from './add-cel-user/add-cel-user.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { NotificationComponent } from './notification/notification.component';
import { CelAuditComponent } from './cel-audit/cel-audit.component';

@NgModule({
  declarations: [LimitSetupComponent, EditLimitSetupComponent, AssignAdminToBranchListComponent, AssignAdminToBranchFormComponent, MonitoringEnableComponent, BranchAlertSetupComponent, CelMonitoringComponent, ProductCoefficientComponent, CelUserManagementComponent, AddCelUserComponent, UserActivationComponent, NotificationComponent, CelAuditComponent],
  imports: [
    CommonModule,
    CelRoutingModule,
    NgxSpinnerModule,
    DataTablesModule,
    FormsModule, 
    ReactiveFormsModule,
  ]
})
export class CelModule { }
