<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>CTCL to Non Neat Conversion</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="eTrade/eUseridRequest/displayNeatToNonNeatRequest"
                            aria-current="page"> CTCL to Non Neat Conversion </a></li>

                </ul>
            </div>
        </div>
    </div>
    <div>
        <form [formGroup]="neatToNonNeat" >
           
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                     <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">NEAT USER ID <span class="red">*</span> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                            <!-- <pre> <h6> NEAT USER ID     : </h6></pre> -->
                        </div>

                        <div class="col-lg-3 form-group">
                            <!-- <label>NEAT USER ID</label> -->
                            <select  class="form-control selectpicker" formControlName="NeatUserId" data-style="btn-info"
                                title="Select User Id" (change)="selectDlrId()" data-live-search="true">
                                <option *ngFor="let data of activatedCTCLNeatId" value="{{data}}">{{data}}
                                </option>
                            </select>
                        </div>
        
                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">TAP/Child IP<span class="red">*</span> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <!-- <label>User Name</label> -->
                            <input type="text" value="" class="form-control" formControlName="TapChildIp" readonly >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">Product Type <span class="red">*</span>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                        </div>
                        <div class="col-lg-3 form-group">
                          
                            <select  class="form-control selectpicker" formControlName="ProductType" data-style="btn-info"
                                title="Select Product Type" >
                                <option  value="CTCL">CTCL</option>
                                <option  value="IBT">IBT</option>
                            </select>
                        </div>

                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">CTCL Undertaking Submitted to the Exchange <span class="red">*</span>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <select  class="form-control selectpicker" formControlName="CtclUndertaking" data-style="btn-info"
                                title="Select Ctcl Undertaking " >
                                <option  value="Yes">Yes</option>
                                <option  value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">Whether Pro facility has been enabled on the requested NEAT User Id<span class="red">*</span> :</label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <select  class="form-control selectpicker" formControlName="ProFacility" data-style="btn-info"
                                title="Select Pro Facility" (change)="chngProFaclityToTrue()">
                                <option  value="Y">Yes</option>
                                <option  value="N">No</option>
                            </select>
                        </div>

                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">Pro Undertaking Submitted to the Exchange <span class="red">*</span>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <select  class="form-control selectpicker" formControlName="ProUndertaking" data-style="btn-info"
                                title="Select Pro Undertaking">
                                <option  value="Y">Yes</option>
                                <option  value="N">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">Name of Empanelled Vendor/Application ServiceProvider<span class="red">*</span> :</label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <select  class="form-control selectpicker" formControlName="EmpanelledVendorName" data-style="btn-info"
                                title="Select Empanelled Vendor Name" >
                                <option  value="In-house">In-house</option>
                            </select>
                        </div>

                        <div class="col-lg-2 form-group mx-3 my-1">
                            <label style="font-size: 14px;">Software Version No. to be used <span class="red">*</span>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="SoftwareVersionNo">
                            
                            <div *ngIf="f.SoftwareVersionNo.invalid && f.SoftwareVersionNo.touched">
                                <p *ngIf="f.SoftwareVersionNo.errors.required" class="error nocase">
                                   Software version required
                                </p>
                                <p *ngIf="f.SoftwareVersionNo.errors.maxlength" class="error nocase">
                                    Please enter valid software version
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                        <div class="col-lg-12 form-group mx-3 my-1">
                            <input  type="checkbox" id="confirmChkbox" formControlName="NonNeatForCtclPurposeCheck" required>
                            "I /we understand that the "NON NEAT" user id will be user only for CTCL purpose and the 
                            login is not allowed in NEAT through such user id. Further, I understand that risk management for the 
                            Non Neat user id would be handled separately".
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 form-group mx-3 my-1">
                            <input  type="checkbox" id="confirmChkbox" formControlName="RulesAndRegulationsCheck" required>
                            "I/We declare to abide by the rules/regulations as communicated by the Exchange/SEBI/other Regulators from time to time".
                        </div>
                    </div>

                    <div class="row justify-content-start mt-2 ml-0">
                        <div class="col-lg-6">
                            <div class="row justify-content-start">
                                <div class="col-lg-2">
                                    <button class="btn btn-primary full-width"  (click)="onSubmit()" [disabled]="neatToNonNeat.invalid">Submit</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn btn-cancel full-width" (click)="reset()">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</div>
