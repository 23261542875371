<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Alert Threshold Setup</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"> <a routerLink="/cel/alertSetup" skipLocationChange aria-current="page">Alert
                            Threshold Setup</a></li>
                </ul>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please wait.</p>
        </ngx-spinner>
    </div>
    <div class="">
        <div class="row clearSearch">
            <button type="button" class="btn btn-cancel btn-sm" aria-label="close" (click)="clearSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <div class="col-lg-6">
            <div class="row newReload">
                <div class="pl-4 mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        (click)="getBranchData()" class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16">
                        <title>Reload</title>
                        <path
                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                    </svg>
                </div>
            </div>
        </div>
        <table id="branchAlert" class="table common-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th class="pre-wrap">Sr No</th>
                    <th class="pre-wrap">Branch id</th>
                    <th class="pre-wrap">Branch Name</th>
                    <th class="pre-wrap">Notification Alert Threshold</th>
                    <th class="pre-wrap">Warning Alert Threshold</th>
                    <th class="pre-wrap">Edit</th>
                </tr>
                <tr class="column-search">
                    <th></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of branchData; let i=index">
                    <td>{{i+1}}</td>
                    <td>{{data.branchCode}}</td>
                    <td>{{data.branchName}}</td>
                    <td>{{data.notificationPercent+'%'}}<span
                            *ngIf="data.notificationPercent!=data.updatedNotificationPercent">(Updated Value =
                            {{data.updatedNotificationPercent}}%. This will be effective from next trading day)</span>
                    </td>
                    <td>{{data.warningPercent+'%'}}<span
                            *ngIf="data.warningPercent!=data.updatedWarningPercent">(Updated Value =
                            {{data.updatedWarningPercent}}%. This will be effective from next trading day)</span></td>
                    <td>
                        <i class="fa fa-pencil pointer usercontrol" aria-hidden="true" data-toggle="modal"
                            data-target="#alertUpdate" (click)="editBranchData(data.branchId)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal fade" id="alertUpdate" tabindex="-1" role="dialog" aria-labelledby="alertUpdatelabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <form [formGroup]="alertForm">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Branch ID<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="branchCode"
                                placeholder="Enter Branch Id" readonly>
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Branch Name<span class="red">*</span> </label>
                            <input type="text" class="form-control" formControlName="branchName"
                                placeholder="Enter Branch Name">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Notification Alert Threshold<span class="red">*</span> </label>
                            <input type="number" class="form-control" min="1" max="99"
                                formControlName="notificationPercent" placeholder="Enter notification alert">
                            <p *ngIf="af.notificationPercent.errors?.required && af.notificationPercent.touched"
                                class="error nocase">
                                Please enter Notification Alert Threshold
                            </p>
                            <p *ngIf="af.notificationPercent.errors?.max && af.notificationPercent.touched"
                                class="error nocase">
                                Notification Alert Threshold should not be greater than 99
                            </p>
                            <p *ngIf="af.notificationPercent.errors?.min && af.notificationPercent.touched"
                                class="error nocase">
                                Notification Alert Threshold should not be less than 1
                            </p>
                            <p *ngIf="af.notificationPercent.errors?.greater && af.notificationPercent.touched"
                                class="error nocase">
                                Notification Alert Threshold should be less than Warning Alert Threshold
                            </p>
                            <p *ngIf="af.notificationPercent.errors?.noDecimalNumberValidation && af.notificationPercent.touched"
                                class="error nocase">
                                Notification Alert Threshold should contain only integer values
                            </p>
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Warning Alert Threshold<span class="red">*</span> </label>
                            <input type="number" class="form-control" min="1" max="99" formControlName="warningPercent"
                                placeholder="Enter warning alert">
                            <p *ngIf="af.warningPercent.errors?.required && af.warningPercent.touched"
                                class="error nocase">
                                Please enter Warning Alert Threshold
                            </p>
                            <p *ngIf="af.warningPercent.errors?.max && af.warningPercent.touched" class="error nocase">
                                Warning Alert Threshold should not be greater than 99
                            </p>
                            <p *ngIf="af.warningPercent.errors?.min && af.warningPercent.touched" class="error nocase">
                                Warning Alert Threshold should not be less than 1
                            </p>
                            <p *ngIf="af.warningPercent.errors?.greater && (af.notificationPercent.touched || af.warningPercent.touched)"
                                class="error nocase">
                                Warning Alert Threshold should be greater than Notification Alert Threshold
                            </p>
                            <p *ngIf="af.warningPercent.errors?.noDecimalNumberValidation && af.warningPercent.touched"
                                class="error nocase">
                                Warning Alert Threshold should contain only integer values
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            Additional Email ID's (If Any):
                            <button class="btn btn-secondary btn-sm" (click)="addEmail()">Add Email ID</button>
                            <table class="table common-table" *ngIf="emailArray?.controls?.length>0">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Email ID</th>
                                        <th></th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container formArrayName="branchEmail">
                                        <tr *ngFor="let email of emailArray.controls; let i=index" [formGroupName]="i">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <input type="email" value="" class="form-control"
                                                    [disabled]="(af.branchEmail.controls[i].controls.branchEmailId!=null)?true:null"
                                                    formControlName="emailId" email placeholder="Enter Email Address">
                                                <div
                                                    *ngIf="af.branchEmail.controls[i].controls.emailId.invalid && af.branchEmail.controls[i].controls.emailId.touched">
                                                    <p *ngIf="af.branchEmail.controls[i].controls.emailId.errors.required"
                                                        class="error nocase">
                                                        Please enter email ID
                                                    </p>
                                                    <p *ngIf="af.branchEmail.controls[i].controls.emailId.errors.email"
                                                        class="error nocase">
                                                        Please enter valid email ID
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <span *ngIf="af.branchEmail.controls[i].controls.isVerified.value=='Y'">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="fillColor bi bi-check-circle-fill"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                    </svg>Verified
                                                </span>
                                                <a class="btn btn-link"
                                                    *ngIf="af.branchEmail.controls[i].controls.isVerified.value=='N'"
                                                    (click)="sendVerificationLink(af.branchEmail.controls[i].controls.branchEmailId.value)">
                                                    Send Verification Link
                                                </a>
                                            </td>
                                            <td><i data-backdrop="static" data-keyboard="false"
                                                    (click)="deleteEmail(email,i)"
                                                    class="fa fa-trash pointer usercontrol" aria-hidden="true"
                                                    data-toggle="modal" data-target="#delete"></i>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="btn-group d-flex" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary col-6" [disabled]="alertForm.invalid"
                        (click)="submit()" aria-label="Close"> Submit </button>
                    <button type="button" class="btn btn-secondary close col-6" data-dismiss="modal" aria-label=" Close"
                        data-dismiss="modal"> Cancel </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center">
                <h6>Are you sure you want to delete this entry?</h6>
                <div class="d-flex justify-content-center mt-3">
                    <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                            data-dismiss="modal">Cancel</button></div>
                    <div class="col-3"><button type="button" class="btn btn-primary full-width"
                            (click)="deleteEmailId()" data-dismiss="modal">Delete</button></div>
                </div>
            </div>
        </div>
    </div>
</div>