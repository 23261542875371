import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.css']
})
export class PasswordPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
