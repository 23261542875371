import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $: any
@Component({
  selector: 'app-shifting-neat-id-admin-request',
  templateUrl: './shifting-neat-id-admin-request.component.html',
  styleUrls: ['./shifting-neat-id-admin-request.component.css']
})
export class ShiftingNeatIdAdminRequestComponent implements OnInit {
  ShiftingOfNeatUserIdAdmin: FormGroup;
  reqObj: any = {};
  menucode: string = '';
  access: any = {};
  user: any = {};
  submitted = false;
  config: any = 'config';
  message: string;
  entityType: any;
  shiftingBoxId: any;
  netId: any;
  constructor(
    private toastr: ToastrService,
    private EnitService: EnitService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private formbuilder: FormBuilder,
    private userDetailsService: UserDetailsService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['refNo']) {
        this.reqObj = enitTradeService.getNewNeatRequestData(params['refNo']);
        if (this.reqObj.cusDlrNm == null) {
          this.reqObj.space = " ";
          this.reqObj.fullname = this.reqObj.firstName.concat(this.reqObj.space).concat(this.reqObj.middleName).concat(this.reqObj.space).concat(this.reqObj.lastName);
        } else {
          this.reqObj.fullname = this.reqObj.cusDlrNm;
        }
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Shifting Neat ID');
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    $('.selectpicker').selectpicker();
    this.ShiftingOfNeatUserIdAdmin = this.formbuilder.group({
      memCode: [10000 + this.reqObj.memId],
      memberName: [this.reqObj.memName],
      userId: [this.reqObj.dlr_id],
      Username: [this.reqObj.fullname],
      typeOfUser: [this.reqObj.dlr_user_type],
      TapIp: [this.reqObj.cusTapIp],
      tapId: [this.reqObj.cusTidId],
      shiftTapip: [this.reqObj.cusProposedDlrTapIp],
      existingtapipaddress: [this.reqObj.addr],
      Reason: [this.reqObj.cusReason],
      shiftinftapipAddress: [this.reqObj.addr],
      CurrentStatus: [],
      remark: [(this.reqObj.cusReqStatus == 'APPR') ? this.reqObj.cusRemarks : (this.reqObj.cusReqStatus == 'RJCT') ? this.reqObj.cusRemarks : '', Validators.required],
      UpdatedStatus: [null, Validators.required],
      boxId : [''],
    })
    if (this.reqObj.cusReqStatus == 'APPR') {
      this.f.CurrentStatus.setValue('APPROVED');
    } else if (this.reqObj.cusReqStatus == 'RJCT') {
      this.f.CurrentStatus.setValue('REJECTED');
    } else if (this.reqObj.cusReqStatus == 'UNDER_PROC') {
      this.f.CurrentStatus.setValue('UNDER PROCESSING');
    } else if (this.reqObj.cusReqStatus == 'DOC_AWAT') {
      this.f.CurrentStatus.setValue('DOCUMENT AWAITED');
    } else if (this.reqObj.cusReqStatus == 'DOC_RECVD') {
      this.f.CurrentStatus.setValue('DOCUMENT RECEIVED');
    } else if (this.reqObj.cusReqStatus == 'SUB') {
      this.f.CurrentStatus.setValue('SUBMITTED');
    }
    this.getShiftingBoxId();
  }
  get f() {
    return this.ShiftingOfNeatUserIdAdmin.controls;
  }

  getShiftingBoxId(){
    this.EnitService.getShiftingBoxId(this.reqObj.cusProposedDlrTapIp).pipe(
     catchError((error : any) =>{
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      this.submitted = false;
      if (error.status != 200) {
       return throwError(error.error);
      }
    })
    ).subscribe((response : any)=> {
      if (response.statusCode == 200){
        this.shiftingBoxId = response.data.tidtapid;
        this.netId = response.data.tidid ;
        this.f.boxId.setValue(this.shiftingBoxId);
      }
    })

  }
  back() {
    if (this.entityType == 'ENIT') {
      this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage']);
    }
    else if (this.entityType != 'ENIT') {
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    }
  }
  reset() {
    this.f.UpdatedStatus.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    this.f.remark.setValue("");
  }
  statusChange(){
    if(this.f.UpdatedStatus.value == "RJCT"){
      this.f.remark.setValidators(Validators.required);
      this.f.remark.updateValueAndValidity();
    }
    else{
      this.f.remark.clearValidators();
      this.f.remark.updateValueAndValidity();
    }
  }
  onSubmit() {
    this.submitted = true;
    let requestObject = {
      "memId": this.f.memCode.value - 10000,
      "cusRemarks": this.f.remark.value,
      "cusRefNo": this.reqObj.refNo,
      "cusStatus": this.f.UpdatedStatus.value,
      "memName": this.f.memberName.value,
      "cusDlrUsrId": this.reqObj.dlr_id,
      "DLR_PROP_TAP_IP": this.reqObj.cusProposedDlrTapIp,
      "cusTidTapId" : this.f.boxId.value,
      "cusDlrNetId" : this.netId,
      "cusTidId": this.f.tapId.value,
      "fromShiftTapip": this.reqObj.fromShiftTapip ,
      "toShiftTapip": this.reqObj.toShiftTapip,
      "ipType": this.reqObj.ipType,
    };
    this.EnitService.ApproveUserIdrequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.message = 'Request Updated Successfully';
        this.toastr.success(this.message);
        this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
      } else {
        this.toastr.error(response.message)
        this.submitted = false;
      }
    })
  }
}
