<div class="row">

    <div class="col-lg-2">
        <button type="button" class="btn btn-secondary col-12"
            (click)="submitApproveOrRejectReq('Back')">Back</button>
    </div>

    <div class="col-lg-2">
        <button type="button" class="btn btn-primary col-12"
            (click)="submitApproveOrRejectReq('Approved')">Approve</button>
    </div>

    <div class="col-lg-2">
        <button type="submit" class="btn btn-secondary col-12"
            (click)="submitApproveOrRejectReq('Rejected')">Reject</button>
    </div>
</div>