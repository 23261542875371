import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BranchUserService } from 'src/app/service/branch-user.service';
import { CelService } from 'src/app/service/cel.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { CustomValidators } from 'src/app/shared/customValidators';
import { config } from 'src/app/valueObject/config';
declare var $:any;
@Component({
  selector: 'app-product-coefficient',
  templateUrl: './product-coefficient.component.html',
  styleUrls: ['./product-coefficient.component.css']
})
export class ProductCoefficientComponent implements OnInit, AfterViewInit, OnDestroy {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {
    processing: true,
    orderCellsTop: true,
    dom: "Blrtip",
    buttons: [{
      extend: 'copy',
      titleAttr: 'copy',
      exportOptions: {
        stripHtml: false,
        columns: [0, 1, 2, 3, 4, 5],
      }
    }, {
      extend: 'excel',
      titleAttr: 'Export to excel',
      exportOptions: {
        stripHtml: false,
        columns: [0, 1, 2, 3, 4, 5],
      }
    }, {
      extend: 'pdf',
      orientation: 'landscape',
      pageSize: 'A4',
      titleAttr: 'Download as PDF',
      exportOptions: {
        stripHtml: false,
        columns: [0, 1, 2, 3, 4, 5],
      }
    },
    ]
  };
  access: any = {};
  users: any = [];
  branchUserData: any = [];
  selectedBranchId: string = '';
  selectedUser: any = {};
  userControlId: string = '';
  user: any = {};
  config: any = config;
  menucode: string = 'prodCoefSetup';
  prodCoefForm: FormGroup;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private celService: CelService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Product Coeffient Setup");
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};

    this.prodCoefForm = this.fb.group({
      userId: [null],
      userName: [null],
      branchId: [null],
      branchName: [null],
      prodCoefArray: new FormArray([])
    })
  }

  ngAfterViewInit() {
    this.getBranchUserData();
  }

  get pf() { return this.prodCoefForm.controls }

  getBranchUserData() {
    this.celService.getProdCoef(this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.branchUserData = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.branchUserData = response.data[0];
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.branchUserData = [];
          this.setDatatable();
        }

      }
    );
  }

  editBranchData(userId) {
    this.selectedUser = this.branchUserData.find(data => data.userId == userId);
    this.prodCoefForm = this.fb.group({
      userId: [null],
      userName: [null],
      branchId: [null],
      branchName: [null],
      prodCoefArray: new FormArray([])
    })
    this.pf.prodCoefArray = new FormArray([]);
    this.setFormData();
  }

  setFormData() {
    this.pf.branchId.setValue(this.selectedUser.branchId);
    this.pf.branchName.setValue(this.selectedUser.branchName);
    this.pf.userId.setValue(this.selectedUser.userId);
    this.pf.userName.setValue(this.selectedUser.userName);
    let prodCoefControl = <FormArray>this.pf.prodCoefArray;
    // prodCoefControl.controls = [];
    this.selectedUser.prodCoef.forEach((data, index) => {
      prodCoefControl.push(this.fb.group({
        symbol: [data.symbol],
        optShort: [data.optShort, [Validators.required, Validators.min(1), Validators.max(100), CustomValidators.noDecimalNumberValidation]],
        optLong: [data.optLong, [Validators.required, Validators.min(1), Validators.max(100), CustomValidators.noDecimalNumberValidation]],
        futures: [data.futures, [Validators.required, Validators.min(1), Validators.max(100), CustomValidators.noDecimalNumberValidation]]
      }));
      if (index == this.selectedUser.prodCoef.length - 1)
        this.cdref.detectChanges();
    });
  }

  setDatatable(){
    $("#userList").DataTable().destroy();
    setTimeout(() => {
      $("#userList").DataTable({
        processing: true,
    orderCellsTop: true,
    dom: "Blrtip",
    buttons: [{
      extend: 'copy',
      titleAttr: 'copy',
      exportOptions: {
        stripHtml: false,
        columns: [0, 1, 2, 3, 4, 5],
      }
    }, {
      extend: 'excel',
      titleAttr: 'Export to excel',
      exportOptions: {
        stripHtml: false,
        columns: [0, 1, 2, 3, 4, 5],
      }
    }, {
      extend: 'pdf',
      orientation: 'landscape',
      pageSize: 'A4',
      titleAttr: 'Download as PDF',
      exportOptions: {
        stripHtml: false,
        columns: [0, 1, 2, 3, 4, 5],
      }
    }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#userList .column-search input').val('');
            $('#userList .column-search select').val('.*');
            $('#userList .column-search').on('keyup change', 'input', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#userList .column-search').on('keyup change', 'select', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  onSubmit() {
    console.log(this.prodCoefForm.value);
    let requestObject = this.selectedUser;
    requestObject.prodCoef = this.prodCoefForm.controls.prodCoefArray.value;
    this.celService.saveProductCoeff(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("Product Coefficient data saved successfully");
        this.getBranchUserData();
      } else {
        this.toastr.error(response.message);
      }
    })
  }

  ngOnDestroy(): void {
    this.dtTrigger?.unsubscribe();
  }
}
