import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from 'src/app/service/access.guard';
import { MenuGuard } from 'src/app/service/menu.guard';
import { SubmenuGuard } from 'src/app/service/submenu.guard';
import { LimitSetupComponent } from './limit-setup/limit-setup.component';
import { EditLimitSetupComponent } from './edit-limit-setup/edit-limit-setup.component';
import { AssignAdminToBranchListComponent } from './assign-admin-to-branch-list/assign-admin-to-branch-list.component';
import { AssignAdminToBranchFormComponent } from './assign-admin-to-branch-form/assign-admin-to-branch-form.component';
import { MonitoringEnableComponent } from './monitoring-enable/monitoring-enable.component';
import { BranchAlertSetupComponent } from './branch-alert-setup/branch-alert-setup.component';
import { CelMonitoringComponent } from './cel-monitoring/cel-monitoring.component';
import { ProductCoefficientComponent } from './product-coefficient/product-coefficient.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { NotificationComponent } from './notification/notification.component';
import { CelAuditComponent } from './cel-audit/cel-audit.component';


const routes: Routes = [
  { path: 'limitSetup', canDeactivate: [SubmenuGuard], component: LimitSetupComponent },
  { path: 'editLimitSetup', canDeactivate: [SubmenuGuard], component: EditLimitSetupComponent },
  { path: 'assignBranchAdmin', canDeactivate: [SubmenuGuard], component: AssignAdminToBranchListComponent },
  { path: 'editAssignBranchAdmin', canDeactivate: [SubmenuGuard], component: AssignAdminToBranchFormComponent },
  { path: 'monitoringEnable', canDeactivate: [SubmenuGuard], component: MonitoringEnableComponent },
  { path: 'alertSetup', canDeactivate: [SubmenuGuard], component: BranchAlertSetupComponent },
  { path: 'celMonitoring', canDeactivate: [SubmenuGuard], component: CelMonitoringComponent },
  { path: 'prodCoefSetup', canDeactivate: [SubmenuGuard], component: ProductCoefficientComponent },
  { path: 'activateCelUser', canDeactivate: [SubmenuGuard], component: UserActivationComponent },
  { path: 'notification', canDeactivate: [SubmenuGuard], component: NotificationComponent },
  { path: 'celAuditUser', canDeactivate: [SubmenuGuard], component: CelAuditComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MenuGuard, SubmenuGuard, AccessGuard]
})
export class CelRoutingModule { }
