import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtclIdDeactivationComponent } from './ctcl-id-deactivation/ctcl-id-deactivation.component';
import {CtclRoutingModule} from './ctcl-routing.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CtclIdActivationComponent } from './ctcl-id-activation/ctcl-id-activation.component';
import {CtclIdModificationComponent} from './ctcl-id-modification/ctcl-id-modification.component';
import { CtclIdRequestReportComponent } from './ctcl-id-request-report/ctcl-id-request-report.component'
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from 'angular-datatables';
import { CtclIdApproverejectComponent } from './ctcl-id-approvereject/ctcl-id-approvereject.component';
import { VerifyRequestByOtpComponent } from './verify-request-by-otp/verify-request-by-otp.component';
import { MinuteSecondsPipePipe } from './pipes/minute-seconds-pipe.pipe';
import { MessageFormatterPipe } from './pipes/message-formatter.pipe';
import { ElementstyleDirective } from './directives/elementstyle.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [CtclIdDeactivationComponent, CtclIdActivationComponent, CtclIdModificationComponent, CtclIdRequestReportComponent, CtclIdApproverejectComponent, VerifyRequestByOtpComponent, MinuteSecondsPipePipe, MessageFormatterPipe,ElementstyleDirective] ,
  imports: [
    CommonModule,
    CtclRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    DataTablesModule,
    BsDatepickerModule],
  
  providers: []
})
export class CtclModule { }
