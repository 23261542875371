import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { throwError, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
// import { AimlEligibleService } from 'src/app/service/aiml-eligible.service'; --removed as all API calls are added in enit-compliance service
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';
import { config } from 'src/app/valueObject/config';
import { catchError } from 'rxjs/operators';
import {EnitComplianceServiceService} from 'src/app/service/enit-compliance-service.service'
declare var $ : any
@Component({
  selector: 'app-add-aimleligible-trade-user',
  templateUrl: './add-aimleligible-trade-user.component.html',
  styleUrls: ['./add-aimleligible-trade-user.component.css']
})
export class AddAIMLEligibleTradeUserComponent implements OnInit {
  addAIMLEligibleForm: FormGroup;
  config: any = config;
  memberName: any = [];
  quarter: any = [];
  fromDate: any = [];
  message: any;
  data: any = {};
  dtOptions: any = {};
  requestList: any = [];
  AIMLUSerData: any=[];
  memberDetails: any [];
  memberList: any=[];
  currentQuarter: any=[];
  dateList: any =[];
  yearData: any=[];
  
  requestObject: any [];

  dtTrigger: Subject<any> = new Subject<any>();
  datatableElement: DataTableDirective;
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private aimlEligibleService: EnitComplianceServiceService,
    private toastr: ToastrService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private datepipe: DatePipe,
  ) { }

  get f() {
    return this.addAIMLEligibleForm.controls;
  }
  ngOnInit(): void {
    this.titleService.setTitle('Add AI & ML user');
    this.addAIMLEligibleForm = this.fb.group({
      memberId: [null, Validators.required],
      memberName: [null, Validators.required],
      year: [null, Validators.required],
      quarter :  [null, Validators.required],
      quarterStartDate: [null,Validators.required],
      quarterEndDate: [null,Validators.required],
    })
    this.getComplianceMemberDetails();
}


getComplianceMemberDetails(){
  this.aimlEligibleService.getComplianceMemberDetails().pipe(
    catchError((error: any) => {
      this.AIMLUSerData = [];
      this.dtTrigger.next(true);
      this.cdref.detectChanges();
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
  ).subscribe((response: any) => {
    if (response.statusCode == 200) {
      this.memberList= response.data.memberDetails;
      this.currentQuarter = response.data.currentQuarter;
      this.f.year.setValue(this.currentQuarter.year);
      this.f.quarter.setValue(this.currentQuarter.quarter);
      // this.f.quarterStartDate.setValue(this.currentQuarter.qrtrStartDate);
      this.f.quarterStartDate.setValue(this.datepipe.transform(this.currentQuarter.qrtrStartDate, 'yyyy-MMM-dd'));
      // this.f.quarterEndDate.setValue(this.currentQuarter.qrtrEndDate);
      this.f.quarterEndDate.setValue(this.datepipe.transform(this.currentQuarter.qrtrEndDate, 'yyyy-MMM-dd'));
      this.cdref.detectChanges();
       $('.selectpicker').selectpicker('refresh');
    } else {
      this.AIMLUSerData = [];
      this.dateList = [];
      this.memberDetails= [];
      //this.dtTrigger.next(true);
      this.cdref.detectChanges();
      this.toastr.error(response.message);
    }
  });
}

back() {
  this.router.navigate(['/complianceAdmin/mlAndAiAdmin/aimlEleigibleUsers']);
}

selectMember() {
  this.memberName = this.memberList.find(data => data.memberId == this.f.memberId.value);
  this.f.memberName.setValue(this.memberName.memberName);
   this.cdref.detectChanges();
  $('.selectpicker').selectpicker('refresh');
}
// selectQuarter(){
//   this.quarter = this.dateList.find(data => data.quarter == this.f.quarter.value);
//   this.f.fromDate.setValue(this.quarter.fromDate);
//   this.f.endDate.setValue(this.quarter.endDate);
// }
reset() {
  this.addAIMLEligibleForm.reset();
   $('.selectpicker').selectpicker('refresh');
   this.getComplianceMemberDetails();
}


onSubmit() {
 
  let requestObject ={
    "memberId":this.f.memberId.value,
    // "quarterStartDate":this.f.quarterStartDate.value,
    // "quarterEndDate":this.f.quarterEndDate.value,
    "quarterStartDate": this.datepipe.transform(this.currentQuarter.qrtrStartDate, 'yyyy-MM-dd'),
    "quarterEndDate":this.datepipe.transform(this.currentQuarter.qrtrEndDate, 'yyyy-MM-dd'),
    "quarterDisplayAdress":"",
    "quarterIsDeleted":"0",

  }; 
  this.aimlEligibleService.addEligibleTradeUser(requestObject).pipe(
    catchError((error: any) => {
      this.memberList = [];
      this.dateList = [];
      this.dtTrigger.next(true);
      this.cdref.detectChanges();
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
  ).subscribe((response: any) => {
    if (response.statusCode == 200) {
      //  this.message = response.data;
      let selectedMemId=  this.f.memberId.value*1+10000;
       this.message = "'"+selectedMemId+"' added in current quarter list";
       this.toastr.success(this.message);
       this.reset();
    } else {
      this.toastr.error(response.message);
    }
  });
}
}


