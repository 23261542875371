<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Machine Learning & Artificial Intelligence</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active" *ngIf="entityType=='ENIT'"><a routerLink="eCompliance/mlAndAi/mlaireport/mlAiForm" aria-current="page">Machine Learning & Artificial
                            Intelligence</a></li>
                    <li class="active" *ngIf="entityType=='ADMIN'"><a routerLink="complianceAdmin/mlAndAiAdmin/mlaireportAdmin/mlAiForm" aria-current="page">Machine Learning & Artificial
                            Intelligence</a></li>

                </ul>
            </div>

        </div>
    </div>

    <form [formGroup]="requestForm">

        <div class="card card-body col-lg-12 mt-3 colorborder">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Entity IFSCA Registration Number</label>
                    <input type="text" class="form-control" readonly formControlName="registrationNumber">
                </div>
                <div class="col-lg-3 form-group">
                    <label>Registered Entity Category</label>
                    <input type="text" class="form-control" readonly formControlName="entityCategory">
                </div>
                <div class="col-lg-3 form-group">
                    <label>Entity Name</label>
                    <input type="text" class="form-control" readonly formControlName="memberName">
                </div>
                <div class="col-lg-3 form-group">
                    <label>Entity PAN No.</label>
                    <input type="text" class="form-control" readonly formControlName="panNO">
                </div>
            </div>
            <!-- <div class="row">
                
            </div> -->
        </div>

        <div class="card card-body col-lg-12 mt-3 colorborder">
            <h5>System Details</h5>
     
            <ng-container formArrayName="form">
                <ng-container *ngFor="let add of form.controls; let j = index" [formGroupName]="j" >

                   
                <div class="colorborder mt-3">
                <div class="card-header pointer" (click)="toggle(j)">
                    <h6 class="mb-0" >
                        <i  id="up"  class="fa fa-angle-up pull-right rotate-icon"></i>System Details {{j+1}}<span class="red">*</span>
                    </h6>
                </div>

                <br>
                <div [id]="j" class="ml-2 mr-2 ">
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Application / System name<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemName" readonly>
                        </div>
                        <div class="col-lg-3 form-group ">
                            <label >Type of area where AI or ML is used<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="typeOfArea" readonly>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Date from when the Application/System was used <span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="applicationDateStr" readonly>
                        </div>
                        
                        <div class="col-lg-3 form-group ">
                            <label >Does the system involve order initiation, routing and execution?<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="radio"  value="Y" formControlName="systemOrder" disabled> Yes
                            <input type="radio"  value="N"  formControlName="systemOrder" disabled> No
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Does the system fall under discretionary investment or Portfolio management activities?<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemActivities" disabled> Yes
                            <input type="radio" value="N"  formControlName="systemActivities" disabled> No
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >Does the system disseminate investment or trading advice or strategies?<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemDisseiminate" disabled> Yes
                            <input type="radio" value="N"  formControlName="systemDisseiminate" disabled> No
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Is the application/system used in area of Cyber Security to detect attacks<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemUsedArea" disabled> Yes
                            <input type="radio" value="N"  formControlName="systemUsedArea" disabled> No
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >What claims have been made regarding AI and ML Application / System , if any? <span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemClaims" readonly>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >What is the name of the Tool / Technology that is categorized as AI and ML system / Application and submissions are declared vide this response<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemTools" readonly>
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >How was the AI or ML project implemented<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemProject" readonly>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Are the key controls and control points in your AI or ML application or systems in accordance to circular of SEBI that mandate cyber security control requirements?<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemKeyControls" readonly>
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >Is the AI / ML system included in the system audit, if applicable?<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemAudit" disabled > Yes
                            <input type="radio" value="N"  formControlName="systemAudit" disabled > No
                            <input type="radio" value="NA"  formControlName="systemAudit" disabled > NA
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Describe the application / system and how it uses AI / ML as portrayed in the product offering<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="sysyemDescription" readonly>
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >What safeguards are in place to prevent abnormal behavior of the AI or ML application / System <span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemSafeGuard" readonly>
                        </div>
                       
                    </div>
                </div>
                    </div>
                </ng-container>
            </ng-container>
        <br>
        </div>
        <div class="card card-body col-lg-12 mt-3 colorborder">
            <div class="row">
            <div class="col-lg-3 form-group">
                <label>Does system audit report comply to circular no. SEBI/HO/MIRSD/DOS2/CIR/P/2019/10 dated January 4,
                    2019 of SEBI<span class="red">*</span></label>
            </div>
            <div class="col-lg-3 form-group">
                <input type="radio" value="Y" formControlName="mlaisystemAuditReport"  disabled> Yes
                <input type="radio" value="N"  formControlName="mlaisystemAuditReport" disabled> No
                <input type="radio" value="NA"  formControlName="mlaisystemAuditReport" disabled> NA

            </div>
            <div class="col-lg-3 form-group">
                <label>Is there any adverse comment in the System audit report<span class="red">*</span></label>
            </div>
            <div class="col-lg-3 form-group">
                <input type="radio" value="Y" formControlName="mlaisystemAdverse"  disabled> Yes
                <input type="radio" value="N"  formControlName="mlaisystemAdverse" disabled> No
                <input type="radio" value="NA"  formControlName="mlaisystemAdverse" disabled> NA

            </div>
        </div>
            <div class="row justify-content-start mt-2">
                <div class="col-lg-4">
                    <div class="row justify-content-start">
                        <div class="col-lg-4">
                            <button class="btn  btn-cancel full-width" (click)="back()">Back </button>
                        </div>  
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<div class="modal fade" id="nillSubmission" tabindex="-1" role="dialog" aria-labelledby="nillSubmission"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" aria-label="Close" (click)="back()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">ML & AI</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h4>Member has submitted nill data.</h4>
                        </div>
                        <button class="btn  btn-primary middle" (click)="back()">okay </button>
                    </div>
                </div>
           
        </div>
    </div>
</div>






