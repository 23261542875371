<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>CEL Monitoring</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"> <a routerLink="/cel/celMonitoring" skipLocationChange aria-current="page">CEL
                            Monitoring</a></li>
                </ul>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please wait.</p>
        </ngx-spinner>
    </div>
    <div class="">
        <div class="row clearSearch">
            <button type="button" class="btn btn-primary btn-sm mr-2" data-toggle="modal" data-target="#mmInfo">
                MM Info
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-info-circle-fill ml-2" viewBox="0 0 20 20">
                    <path
                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
            </button>
            <button type="button" class="btn btn-cancel btn-sm" aria-label="close" (click)="clearSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <div class="col-lg-6">
            <div class="row newReload">
                <div class="pl-4 mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        (click)="getBranchUserData()" class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16">
                        <title>Reload</title>
                        <path
                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                    </svg>
                </div>
            </div>
        </div>
        <table id="userList" class="table common-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th rowspan="2" class="pre-wrap text-center">Sr No</th>
                    <th rowspan="2" class="pre-wrap text-center">Branch id</th>
                    <th rowspan="2" class="pre-wrap text-center">Branch Name</th>
                    <th rowspan="2" class="pre-wrap text-center">User id</th>
                    <th rowspan="2" class="pre-wrap text-center">User Name</th>
                    <th colspan="2" class="pre-wrap text-center">Total Net Buy Credit for Futures</th>
                    <th colspan="2" class="pre-wrap text-center">Total Net Sell Credit for Futures</th>
                    <th colspan="2" class="pre-wrap text-center">Total Net Buy Credit Options Long</th>
                    <th colspan="2" class="pre-wrap text-center">Total Net Sell Credit Options Short</th>
                    <th rowspan="2" class="pre-wrap text-center">Status</th>
                </tr>
                <tr>

                    <th class="pre-wrap text-center">Limit</th>
                    <th class="pre-wrap text-center">Utilization</th>
                    <th class="pre-wrap text-center">Limit</th>
                    <th class="pre-wrap text-center">Utilization</th>
                    <th class="pre-wrap text-center">Limit</th>
                    <th class="pre-wrap text-center">Utilization</th>
                    <th class="pre-wrap text-center">Limit</th>
                    <th class="pre-wrap text-center">Utilization</th>
                </tr>
                <tr class="column-search">
                    <th></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of branchUserData; let i=index">
                    <td>{{i+1}}</td>
                    <td>{{data.branchId}}</td>
                    <td>{{data.branchName}}</td>
                    <td>{{data.userId}}</td>
                    <td>{{data.userName}}</td>
                    <td class="text-right">{{data.netBuyCreditFutureLimit|currency:'USD'}}</td>
                    <td class="text-right"><span
                            [ngClass]="{'green':data.netBuyCreditFuture<data.netBuyCreditFutureLimit,'red':data.netBuyCreditFuture>=data.netBuyCreditFutureLimit,'yellow':data.netBuyCreditFuture<0}"
                            class="mfont">{{data.netBuyCreditFuture|currency:'USD'}}</span>
                    </td>
                    <td class="text-right">{{data.netSellCreditFutureLimit|currency:'USD'}}</td>
                    <td class="text-right"><span
                            [ngClass]="{'green':data.netSellCreditFuture<data.netSellCreditFutureLimit,'red':data.netSellCreditFuture>=data.netSellCreditFutureLimit,'yellow':data.netSellCreditFuture<0}"
                            class="mfont">{{data.netSellCreditFuture|currency:'USD'}}</span>
                    </td>
                    <td class="text-right">{{data.netBuyCreditOptionLongLimit|currency:'USD'}}</td>
                    <td class="text-right"><span
                            [ngClass]="{'green':data.netBuyCreditOptionLong<data.netBuyCreditOptionLongLimit,'red':data.netBuyCreditOptionLong>=data.netBuyCreditOptionLongLimit,'yellow':data.netBuyCreditOptionLong<0}"
                            class="mfont">{{data.netBuyCreditOptionLong|currency:'USD'}}</span>
                    </td>
                    <td class="text-right">{{data.netSellCreditOptionShortLimit|currency:'USD'}}</td>
                    <td class="text-right"><span
                            [ngClass]="{'green':data.netSellCreditOptionShort<data.netSellCreditOptionShortLimit,'red':data.netSellCreditOptionShort>=data.netSellCreditOptionShortLimit,'yellow':data.netSellCreditOptionShort<0}"
                            class="mfont">{{data.netSellCreditOptionShort|currency:'USD'}}</span>
                    </td>
                    <td><span [ngClass]="{'green':data.status=='Y','red':data.status=='N'}"
                            class="mfont">{{data.status=='Y'?'Active':'Deactive'}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="mmInfo" tabindex="-1" role="dialog" aria-labelledby="mmInfolabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Maintainance Margin</h5>
                        <table class="table common-table" datatable id="mmInfoTable">
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th class="text-right">Maintainance Margin</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of mmInfo">
                                    <td>{{data.symbol}}</td>
                                    <td class="text-right">{{data.mm}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>