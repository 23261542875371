<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>MIS Report</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="/tradeAdmin/misTradeReport/displayMisReport" aria-current="page">MIS Report</a></li>
                </ul>
            </div>

        </div>
    </div>
    <form [formGroup]="misReportForm">
        <div class="container-fluid mt-3 mb-3 theme">
            <div class="card">
                <div class="card-body">
                    <div id="accordion">

                        <div class="card-header pointer" id="Combinedheading">

                            <h6 class="mb-0" data-toggle="collapse" data-target="#collapseCombined"
                                aria-expanded="false" aria-controls="collapseCombined">
                                <i class="fa fa-angle-up pull-right rotate-icon"></i> Generate Ad-hoc Report
                            </h6>
                        </div>

                        <div id="collapseCombined" class="collapse show" aria-labelledby="Combinedheading"
                            data-parent="#accordion">

                            <div class="row">
                                <div class="col-lg-4 form-group">
                                    <br>
                                    <label>Report Type</label>
                                    <select class="form-control requestTypePicker" formControlName="selectMisReport"
                                        data-style="btn-info" title="Select request Type" data-live-search="true">
                                        <option value="">Select</option>
                                        <option *ngFor="let ctclReqDetails of ctclRequestTypeList"
                                            value="{{ctclReqDetails.name}}">
                                            {{ctclReqDetails.name}}
                                        </option>
                                    </select>

                                </div>
                            </div>


                            <div class="row" [style]="{'margin-bottom':'20px'}">
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <button type="submit" class="btn btn-primary col-12"
                                            [disabled]="fmis.selectMisReport.invalid" (click)="reportGenerated(fmis.selectMisReport.value)">Generate Report</button>
                                        </div>
                                        <div class="col-lg-6">
                                            <button type="button" class="btn btn-secondary col-12"
                                                (click)="getctclReportBasedOnReset()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>



    <div class="container-fluid mt-3 mb-3 theme">
        <div class="card mt-3">
            <div class="card-body">

                <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                    <p style="color: rgb(17, 5, 5)">Please wait.</p>
                </ngx-spinner>

                <div class="">
                  <div class="row clearSearch">
                        <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor"
                                class="bi bi-x" viewBox="0 0 15 15">
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg> Filter</button>
                    </div> 
                   <br>
                   <br>
                    <table id="ctclRequestDataGrid"
                    class="table common-table table-bordered table-striped table-hover display nowrap" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2">
                        <thead>
                            <tr>
                                <th class="pre-wrap">SR No.</th>
                                <th class="pre-wrap">Report Id</th>
                                <th class="pre-wrap">File Name</th>
                                <th class="pre-wrap">Report Type</th>
                                <th class="pre-wrap">Report Generation Date</th>
                                <!-- <th class="pre-warp">File Name</th>                                                        -->
                            </tr>

                            <tr class="column-search">
                                <th></th>
                                <th><input type="text" class="form-control form-control-sm"/></th>
                                <th><input type="text" class="form-control form-control-sm"/></th>
                                <th><input type="text" class="form-control form-control-sm"/></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <!-- <th><input type="text" class="form-control form-control-sm" /></th> -->
                                
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let misReport of misGridList; let srNo=index">
                                <td>{{srNo+1}}</td>
                                <td> <button type="button" class="btn btn-link" (click)="downloadMisReport(misReport.requestID,misReport.originalFileName)">{{misReport.requestID}}</button></td> 
                                <td class="nocase"><a href=click></a>{{misReport.originalFileName}}</td>
                                <td><a href=click></a>{{misReport.functinality}}</td>
                                <td><a href=click></a>{{misReport.jobExecutionDate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>