import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { EnitRoutingModule } from './eTrade-routing.module';
import { TradeAdminRoutingModule } from './tradeAdmin-routing.module';
import { EnitComplainceRoutingModule } from './eComplaince-routing.module'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EtmNewneatUseridRequestComponent } from './EnitTrade/etm-newneat-userid-request/etm-newneat-userid-request.component';
import { EtUseridRequestListComponent } from './EnitTrade/et-userid-request-list/et-userid-request-list.component';
import { DisableNeatUserIdComponent} from './EnitTrade/disable-neat-user-id/disable-neat-user-id.component';
import { NeatUserValidityUpdationComponent} from   './EnitTrade/neat-user-validity-updation/neat-user-validity-updation.component';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { ShiftingNeatIdRequestComponent } from './EnitTrade/shifting-neat-id-request/shifting-neat-id-request.component';
import { DisplayAlgoUndertakingDetailsComponent} from './EnitTrade/display-algo-undertaking-details/display-algo-undertaking-details.component'
import { EtNewneatRequestProcessPageComponent} from "./EnitTrade/et-newneat-request-process-page/et-newneat-request-process-page.component";
import { EtNeatToNonneatComponent } from './EnitTrade/et-neat-to-nonneat/et-neat-to-nonneat.component';
import { NeatNonneatReportComponent} from './EnitTrade/neat-nonneat-report/neat-nonneat-report.component'
import { NeatNonneatProcesspageComponent } from './EnitTrade/neat-nonneat-processpage/neat-nonneat-processpage.component';
import { DisableNeatUserIdAdminComponent } from './EnitTrade/disable-neat-user-id-admin/disable-neat-user-id-admin.component'
import { NeatUserValidityUpdationModalComponent } from './EnitTrade/neat-user-validity-updation-modal/neat-user-validity-updation-modal.component'
import { ShiftingNeatIdAdminRequestComponent} from './EnitTrade/shifting-neat-id-admin-request/shifting-neat-id-admin-request.component'
import { PasswordOrUnlockRequestAdminComponent} from './EnitTrade/password-or-unlock-request-admin/password-or-unlock-request-admin.component'
import { PasswordOrUnlockRequestComponent } from './EnitTrade/password-or-unlock-request/password-or-unlock-request.component';
import { EtmMessageRateChangeReportComponent } from './EnitTrade/etm-message-rate-change-report/etm-message-rate-change-report.component';
import { EtmMessageRateModalComponent } from './EnitTrade/etm-message-rate-modal/etm-message-rate-modal.component';
import { EtmMessageRateUserIdRequestComponent } from './EnitTrade/etm-message-rate-user-id-request/etm-message-rate-user-id-request.component';
import { EtMesssageRateAdminComponent } from './EnitTrade/et-messsage-rate-admin/et-messsage-rate-admin.component';
import { EtMessageRateAdminModalComponent } from './EnitTrade/et-message-rate-admin-modal/et-message-rate-admin-modal.component';import { NeatNonneatReportAdminComponent } from './EnitTrade/neat-nonneat-report-admin/neat-nonneat-report-admin.component';
import { NeatNonneatProcessPageAdminComponent } from './EnitTrade/neat-nonneat-process-page-admin/neat-nonneat-process-page-admin.component';
import { EtmNeatUserNameChangeRequestComponent} from './EnitTrade/etm-neat-user-name-change-request/etm-neat-user-name-change-request.component';
import { VerfiyRequestByOtpComponent} from './EnitTrade/verfiy-request-by-otp/verfiy-request-by-otp.component'
import { UnlockResetModalComponent}from './EnitTrade/unlock-reset-modal/unlock-reset-modal.component'
import { EtChangeNameProcessPageComponent } from './EnitTrade/et-change-name-process-page/et-change-name-process-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CtclModule } from './EnitTrade/ctcl/ctcl.module';
import {EnitMisReportComponent} from './EnitTrade/enitmis-report/enit-mis-report.component'
//     <=========== Complaince Modules Below ===========>
import { MlAiRequestComponent } from './EnitCompliance/ml-ai-request/ml-ai-request.component';
import { MlAndAiComponentListComponent } from './EnitCompliance/ml-and-ai-component-list/ml-and-ai-component-list.component';
import { MlAndAiComponentAdminListComponent } from './EnitCompliance/ml-and-ai-component-admin-list/ml-and-ai-component-admin-list.component';
import { AddAIMLEligibleTradeUserComponent } from './EnitCompliance/add-aimleligible-trade-user/add-aimleligible-trade-user.component';
import { AIMLEligibleUserComponent } from './EnitCompliance/aimleligible-user/aimleligible-user.component';
import { EmailTemplateComponent } from './EnitCompliance/email-template/email-template.component';
import { EnitComplianceAdminComponent } from './EnitCompliance/enit-compliance-admin/enit-compliance-admin.component';
import { MlAiModalComponent } from './EnitCompliance/ml-ai-modal/ml-ai-modal.component';
//import { PreviousQuarterMlaiListComponent } from './EnitCompliance/previous-quarter-mlai-list/previous-quarter-mlai-list.component';
import { CyberSecurityEnableUsersComponent } from './EnitCompliance/cyber-security-enable-users/cyber-security-enable-users.component';
import { AddCyberSecurityEnableUsersComponent } from './EnitCompliance/add-cyber-security-enable-users/add-cyber-security-enable-users.component';
import { QuaterlyIncidentReportComponent } from './EnitCompliance/quaterly-incident-report/quaterly-incident-report.component' 
// import {CyberSecurityAdminListComponent} from './EnitCompliance/cyber-security-admin-list/cyber-security-admin-list.component'
import { CyberSecurityAdminListComponent } from './EnitCompliance/cyber-security-admin-list/cyber-security-admin-list.component'
import { AdminCyberSecurityReportComponent } from './EnitCompliance/admin-cyber-security-report/admin-cyber-security-report.component'
import { CyberSecurityMemberListComponent } from './EnitCompliance/cyber-security-member-list/cyber-security-member-list.component';
import { CyberSecurityMemberreportUploadComponent } from './EnitCompliance/cyber-security-memberreport-upload/cyber-security-memberreport-upload.component';
import { PdfDownloaderComponentComponent } from './pdf-downloader-component/pdf-downloader-component.component';
import { HalfYearlyNetworthConfigComponent } from './EnitCompliance/half-yearly-networth-config/half-yearly-networth-config.component'
import { HalfYearlyFormSubmissionComponent } from './EnitCompliance/half-yearly/half-yearly-form-submission/half-yearly-form-submission.component';
import { HalfYearlyFormSubmissionHistoryComponent } from './EnitCompliance/half-yearly/half-yearly-form-submission-history/half-yearly-form-submission-history.component';


@NgModule({
  declarations: [
    EtmNewneatUseridRequestComponent, ShiftingNeatIdRequestComponent, EtUseridRequestListComponent,DisableNeatUserIdComponent,NeatUserValidityUpdationComponent,
    DigitalSignatureComponent,EtNewneatRequestProcessPageComponent,DisplayAlgoUndertakingDetailsComponent, NeatUserValidityUpdationModalComponent,
    EtNeatToNonneatComponent,NeatNonneatReportComponent,NeatNonneatProcesspageComponent, EtmMessageRateChangeReportComponent,EtmMessageRateModalComponent,EtmMessageRateUserIdRequestComponent,
    DisableNeatUserIdAdminComponent,ShiftingNeatIdAdminRequestComponent,PasswordOrUnlockRequestAdminComponent,PasswordOrUnlockRequestComponent,EtMessageRateAdminModalComponent, EtMesssageRateAdminComponent,
    NeatNonneatReportAdminComponent, NeatNonneatProcessPageAdminComponent,EtmNeatUserNameChangeRequestComponent,VerfiyRequestByOtpComponent, UnlockResetModalComponent, EtChangeNameProcessPageComponent,
    EnitMisReportComponent,

    //     <=========== Complaince Modules Below ===========>
    MlAiRequestComponent, MlAndAiComponentListComponent, MlAndAiComponentAdminListComponent, AddAIMLEligibleTradeUserComponent,AIMLEligibleUserComponent,
    EmailTemplateComponent,EnitComplianceAdminComponent, MlAiModalComponent, CyberSecurityEnableUsersComponent, AddCyberSecurityEnableUsersComponent, QuaterlyIncidentReportComponent,
    CyberSecurityAdminListComponent,AdminCyberSecurityReportComponent,CyberSecurityMemberListComponent, CyberSecurityMemberreportUploadComponent,
    PdfDownloaderComponentComponent,
    HalfYearlyNetworthConfigComponent,HalfYearlyFormSubmissionComponent , HalfYearlyFormSubmissionHistoryComponent
    
  ],
  imports: [
    CommonModule,
    EnitRoutingModule,
    TradeAdminRoutingModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BsDatepickerModule,
    SharedModule,
    CtclModule,
    EnitComplainceRoutingModule,
  ]
})
export class EnitModule { }
