//import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service'
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
declare var $:any;



@Component({
  selector: 'app-enit-compliance-admin',
  templateUrl: './enit-compliance-admin.component.html',
  styleUrls: ['./enit-compliance-admin.component.css'],
  /// imports: [NgbAccordionModule]
})
export class EnitComplianceAdminComponent implements OnInit {
  DisableAdminForm: FormGroup;
  ViewExistingEmailForm: FormGroup;
  addYearFrom: FormGroup;
  addEmail: FormGroup;
  CutOffDateForm: FormGroup;
  AddCutOffDateForm: FormGroup;
  UpdateCutOffForm: FormGroup;
  config: any = config;
  minDate: Date = new Date();
  bsValue: any[] =[];
  adminRequestTypeList: any[] = [];
  adminPeriodTypeList: any[] = [];
  memberEmailRequestTypeList: any[] = [];
  cutOffDayTypeList:any[]=[];
  selectDisabled: boolean;
  PeriodListData: any = [];
  popupEmail = false;
  popupPeriodSetting = false;
  items: any = [];
  emailIdToBeEdited: String = '';
  emailIdSerialNo:number;
  updateCutOffId: number;
  updateModuleName: String='';
  updateYear:String='';
  popupEmailEdit = false;
  popupAddEmail = false;
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  dtOptions2:any
  dtOptions1:any
  // ={
  //   processing: true,
  //   dom: 'Blrtip',
  //   pageLength: 2,
  //   buttons:
  //     //'copy', 'excel',
  //     [{
  //       extend: 'copy',
  //       titleAttr: 'Copy',
  //       exportOptions: {
  //         columns: [0, 1, 2, 3, 4],
  //       }
  //     }, {
  //       extend: 'excel',
  //       titleAttr: 'Export to excel',
  //       exportOptions: {
  //         columns: [0, 1, 2, 3, 4],
  //       }
  //     }, {
  //       extend: 'pdf',
  //       titleAttr: 'Download as PDF',
  //       exportOptions: {
  //         columns: [0, 1, 2, 3, 4],
  //       }
  //     }]
  // };
  emailData: any = [];
  deleteReqData: any = [];
  deleteRow: any;
  deleteYear: any;
  deleteModule: any;
  message: any;
  emailIdGridList: any[] = [];
  viewPeriodList:any[]=[];
  viewModuleList: any[] = [];
  cutOffDayGridList: any[]=[];
  selectedRecord:any={};
  // dtOptions1: any = {
  //   processing: true,
  //   dom: 'Blfrtip',
  //   buttons:
  //     //'copy', 'excel',
  //     [{
  //       extend: 'copy',
  //       titleAttr: 'Copy',
  //       exportOptions: {
  //         columns: [0, 1, 2, 3],
  //       }
  //     }, {
  //       extend: 'excel',
  //       titleAttr: 'Export to excel',
  //       exportOptions: {
  //         columns: [0, 1, 2, 3],
  //       }
  //     }, {
  //       extend: 'pdf',
  //       titleAttr: 'Download as PDF',
  //       exportOptions: {
  //         columns: [0, 1, 2, 3],
  //       }
  //     }]
  // };
  dtTrigger1: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(
    private adminComplianceService: EnitComplianceServiceService,
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
    private router: Router,
    private titleService: Title,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.viewCutOffDateList();
    this.viewQuarter();
    this.viewModule();
    this.titleService.setTitle("Admin Setup");
    this.adminRequestTypeList = this.adminComplianceService.adminRequestType;
    this.adminPeriodTypeList = this.adminComplianceService.adminPeriodType;
    this.memberEmailRequestTypeList = this.adminComplianceService.memberEmailNotification;
    this.selectDisabled = true;
    this.cutOffDayTypeList = this.adminComplianceService.cutOffDayType;

    this.addYearFrom = this.formbuilder.group({
      //addYear: ['', Validators.required],
      addYear: ['', [Validators.required, Validators.pattern("([0-9]{4})")]],
    })

    this.addEmail = this.formbuilder.group({
      //emailId:['', [Validators.required, Validators.email]],
      //emailId:['',Validators.required],
      emailId: ['', [Validators.required, Validators.pattern("^([a-z][a-z0-9-_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,)*)+$")]],
      serialNo:['']
  })

    this.DisableAdminForm = this.formbuilder.group({
      adminFinancial: ['', Validators.required],
      adminPeriod: ['', Validators.required],
      year: ['', Validators.required],
      savePeriod: ['', Validators.required]
    })

    this.ViewExistingEmailForm = this.formbuilder.group({
      addEmailId: ['', Validators.required]
    })

    
    this.AddCutOffDateForm=this.formbuilder.group({
      //cutOffYear:['',Validators.required],
      cutOffYear: ['', [Validators.required, Validators.pattern("([0-9]{4})")]],
      cutOffModule:['',Validators.required],
     })
    
    this.UpdateCutOffForm=this.formbuilder.group({
      moduleName:['',Validators.required],
      year:['',Validators.required],
      cutOffDate:  ['', Validators.required],
      quarterData:this.formbuilder.array([])
    })

    this.viewEmailData();
    this.DisableAdminForm.get('adminPeriod').disable();
    // this.enable1();
    this.dtOptions = {
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      pageLength: 4,
      responsive: true,
      buttons:
        [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3],
          }
        },
        ]
    };
  }

  get f() { return this.DisableAdminForm.controls; }

  get femail() { return this.ViewExistingEmailForm.controls; }

  get fyear() { return this.addYearFrom.controls; }

  get faddemail() { return this.addEmail.controls; }

  get cutOff() {return this.AddCutOffDateForm.controls;}
  
  get updateCutOff() {return this.UpdateCutOffForm.controls;}

  enableFinacialYear() {
    // if((this.DisableAdminForm.get('adminFinancial').value)==false){
    this.DisableAdminForm.get('adminPeriod').enable();
    //  }

  }

  openModal(){
    this.faddemail.emailId.setValue('');
     $('#emailModal').modal('show');
   }

  // Save email id in table 

  onSubmit() {
    let requestObject = {
     "emailID": this.faddemail.emailId.value
    }
    this.adminComplianceService.saveForEmailNotification(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(
          this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("Email id Submitted Successfully");
        this.viewEmailData();
        $('#emailModal').modal('hide');
        this.cdref.detectChanges();
      }if (response.statusCode == 201) {
        this.toastr.warning(response.message);
        this.viewEmailData();
        $('#emailModal').modal('hide');
        this.cdref.detectChanges();
      } 
      else if(response.statusCode == 202) {
        this.toastr.warning(response.message);
        this.viewEmailData();
        $('#emailModal').modal('hide');
        this.cdref.detectChanges();
      }
    })
  }


  viewQuarter()
  {
    this.adminComplianceService.viewPeriodData().pipe(
      catchError((error :any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.viewPeriodList = [];
        //  this. dtTrigger1.next(true);
          this.cdref.detectChanges();
        if(error.status!=200){
          return throwError(error.error);
        }
      }
      )).subscribe((response:any) =>{
        if(response.statusCode =200){
          this.viewPeriodList = response.data;
          // this. dtTrigger1.next(true);
          this.cdref.detectChanges();
          //this.toastr.success(response.message);
       } else {
        this.viewPeriodList = [];
        this.toastr.error(response.message);
        // this. dtTrigger1.next(true);
        this.cdref.detectChanges();

        }
      })
  }
   

  getPeriodListData() {
    let requestObject = {
      //"type": this.adminPeriodTypeList.find(data => data.id == this.f.adminPeriod.value).name,
      "type": this.f.adminPeriod.value,
      "year": this.adminRequestTypeList.find(data => data.id == this.f.adminFinancial.value).name
    }
    this.spinner.show();
    this.adminComplianceService.getPeriodList(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.PeriodListData = [];
        this.dtTrigger.next(true);
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode = 200) {
        this.PeriodListData = response.data;
        this.dtTrigger.next(true);
        this.cdref.detectChanges();
      } else {
        this.PeriodListData = [];
        this.dtTrigger.next(true);
        this.cdref.detectChanges();
        this.toastr.error(response.message);
      }
    })
  }

  openModalYear(){
    this.fyear.addYear.setValue('');
    $('#yearModal').modal('show');
   }

  // Save year in table 
  onSubmitYear() {
    let requestObject = {
      "year": this.fyear.addYear.value,
    }
    this.adminComplianceService.saveForPeriodList(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(
          this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("add new year Submitted Successfully");
        $('#yearModal').modal('hide');
      } else if(response.statusCode == 201){
        this.toastr.warning(response.message);
        $('#yearModal').modal('hide');        
      } else {
        this.toastr.warning(response.message);
        $('#yearModal').modal('hide');
      }
    })
  }


  //load email id data 
 setDatatable1(){
  $("#exchangeEmailTable").DataTable().destroy();
  setTimeout(() => {
    $("#exchangeEmailTable").DataTable({
    processing: true,
    orderCellsTop: true,
    dom: 'Blfrtip',
    buttons:
      //'copy', 'excel',
      [{
        extend: 'copy',
        titleAttr: 'Copy',
        exportOptions: {
          columns: [0, 1, 2, 3],
        }
      }, {
        extend: 'excel',
        titleAttr: 'Export to excel',
        exportOptions: {
          columns: [0, 1, 2, 3],
        }
      }, {
        extend: 'pdf',
        titleAttr: 'Download as PDF',
        exportOptions: {
          columns: [0, 1, 2, 3],
        }
      }],
      initComplete: function () {
        this.api().columns().every(function () {
          $('#exchangeEmailTable .column-search input').val('');
          $('#exchangeEmailTable .column-search select').val('.*');
          $('#exchangeEmailTable .column-search').on('keyup change', 'input', function () {
            $('#exchangeEmailTable').DataTable()
              .column($(this).parent().index())
              .search(this.value)
              .draw();
          });
          $('#exchangeEmailTable .column-search').on('keyup change', 'select', function () {
            $('#exchangeEmailTable').DataTable()
              .column($(this).parent().index())
              .search("^" + this.value + "$", true, false, false)
              .draw();
          });
        })
      }
    });
    this.cdref.detectChanges();
  }, 1)
}
  viewEmailData() {
    //$("#exchangeEmailTable").DataTable().destroy();
      this.adminComplianceService.viewExchangeEmailId().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.emailIdGridList = [];
        this.setDatatable1();
        if (error.status != 200) {
          return throwError(error.error);
        }
      }
      )).subscribe((response: any) => {
        if (response.statusCode = 200) {
          this.emailIdGridList = response.data[0];
          this.setDatatable1();
        } else {
          this.emailIdGridList = [];
          this.toastr.error(response.message);
          this.setDatatable1();

        }
      })
  }

  //  viewEmail(): void{
  //   this.viewEmailData()
  //  }


  filterData(email: String, serialNo: number) {
    this.emailIdToBeEdited = email;
    this.faddemail.emailId.setValue(this.emailIdToBeEdited)

    this.emailIdSerialNo = serialNo;
    this.faddemail.serialNo.setValue(this.emailIdSerialNo)

  }


  editEmail() {
    let requestObject = {

      "emailID": this.faddemail.emailId.value,
      "serialNo": this.faddemail.serialNo.value
    }
    this.adminComplianceService.editExchangeEmailId(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(
          this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("Email id Updated Successfully");
        this.viewEmailData();
        $('#editEmailId').modal('hide');
        this.cdref.detectChanges();
      }
      else
        this.toastr.error(response.message);
    })
  }

  // delete email id 
  deleteExchangeEmailIdData(id: number) {
    this.deleteRow = id;
  }


  deleteEmailIdData() {
    this.spinner.show();
    let requestObject = {
      serialNo: this.deleteRow
    }
    this.adminComplianceService.deleteExchangeEmailId(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.message = "Email ID Deleted";
          this.toastr.success(this.message);
          this.viewEmailData();
          $('#delete').modal('hide');
          this.cdref.detectChanges();

        }
        else {
          this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
        }
      }

    )
  }

  //CutOffdate  -View
  setDatatable2(){
    $("#cutoffDateTable").DataTable().destroy();
    setTimeout(()=> {
      $("#cutoffDateTable").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: 'Blrtip',
        pageLength: 2,
        buttons:
          [{
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1, 2, 3, 4],
            }
          }, {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1, 2, 3, 4],
            }
          }, {
            extend: 'pdf',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1, 2, 3, 4],
            }
          }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#cutoffDateTable .column-search input').val('');
            $('#cutoffDateTable .column-search select').val('.*');
            $('#cutoffDateTable .column-search').on('keyup change', 'input', function () {
              $('#cutoffDateTable').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#cutoffDateTable .column-search').on('keyup change', 'select', function () {
              $('#cutoffDateTable').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1)
  }

  viewCutOffDateList(){
    this.adminComplianceService.viewCutOffDate().pipe(
      catchError((error :any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cutOffDayGridList = [];
        this.setDatatable2();
        if(error.status!=200){
          return throwError(error.error);
        }
      }
      )).subscribe((response:any) =>{
        if(response.statusCode =200){
          this.cutOffDayGridList = response.data;
          this.cutOffDayGridList.forEach((data,index)=> data.id=index+1)
          this.setDatatable2()
       } else {
        this.cutOffDayGridList = [];
        this.toastr.error(response.message);
        this.setDatatable2()

        }
      })
  }



  clearSearch() {
    this.setDatatable2();
  }

  openCutoffModal(){
    this.cutOff.cutOffYear.setValue('');
    $('#cutOffModal').modal('show');
   }

  onSubmitCutOffDate(){
    let requestObject ={
      "year" :this.cutOff.cutOffYear.value,
      "moduleName":this.cutOff.cutOffModule.value,
      //"moduleName": this.cutOffDayTypeList.find(data=>data.id==this.cutOff.cutOffModule.value).name  
    }
    this.adminComplianceService.saveForCutOffDate(requestObject).pipe(
    catchError((error: any)=> {
      this.toastr.error(
      this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.toastr.success("CutOff Date added Successfully");
        this.viewCutOffDateList();
        $('#cutOffModal').modal('hide');
        this.cutOff.cutOffModule.setValue(null);
        this.cdref.detectChanges();
      } else if(response.statusCode == 201){
        this.toastr.warning(response.message);
        $('#cutOffModal').modal('hide'); 
        this.cutOff.cutOffModule.setValue(null); 
        this.cdref.detectChanges();      
      } else {
        this.toastr.warning(response.message);
        $('#cutOffModal').modal('hide');
        this.cutOff.cutOffModule.setValue(null);
        this.cdref.detectChanges();
      }
    })
  }
 

  // cutOffDate -Edit

  filterCutOfData(id: number) {
    this.selectedRecord = this.cutOffDayGridList.find(data => data.id == id);
    this.updateCutOff.moduleName.setValue(this.selectedRecord.moduleName);
    this.updateCutOff.year.setValue(this.selectedRecord.year);
    // let quarterData = <FormArray>this.updateCutOff.quarterData;
    let quarterData = <FormArray>this.formbuilder.array([]);
 
    this.selectedRecord.quarterData.forEach((data, index) => {
      quarterData.push(this.formbuilder.group({
        cutOffID: [data.cutOffID],
        quaterName: [data.quaterName],
       cutOffDate: [this.datepipe.transform(data.cutOffDate, 'MM/dd/yyyy')],
       }))
  
    })
    this.updateCutOff.quarterData = quarterData;
    this.cdref.detectChanges();
  }


  updateCutOffDate(){
    let requestObject: any[] = [];
    this.updateCutOff.quarterData.value.forEach((obj)=>{
      requestObject.push({
        "cutOffID": obj.cutOffID,
        "quaterName": obj.quaterName,
        "cutOffDate": this.datepipe.transform(obj.cutOffDate, 'yyyy-MM-dd'),
      })
    })
    
    this.adminComplianceService.editCutOffDate(requestObject).pipe(
    catchError((error: any)=> {
      this.toastr.error(
      this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.toastr.success("CutOff Date Updated Successfully");
        $('#editCutOffDate').modal('hide');
        this.viewCutOffDateList();
        this.cdref.detectChanges();
      }
      else
      this.toastr.error(response.message);
    })
  }

  viewModule()
  {
   this.adminComplianceService.viewModuleData().pipe(
     catchError((error :any)=>{
     this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
     this.viewModuleList = [];
     this.cdref.detectChanges();
   if(error.status!=200){
     return throwError(error.error);
   }
  })).subscribe((response:any) =>{
  if(response.statusCode =200){
     this.viewModuleList = response.data;
     this.cdref.detectChanges();
 } else {
     this.viewModuleList = [];
     this.toastr.error(response.message);
     this.cdref.detectChanges();
}})
}

deleteCutOffYear(moduleName: String, year: String) {
  this.deleteModule = moduleName;
  this.deleteYear = year;
  
}


deleteCutOffDetails() {
  this.spinner.show();
  let requestObject = {
    moduleName: this.deleteModule,
    year: this.deleteYear
  }
  this.adminComplianceService.deleteCutOffYear(requestObject).pipe(
    catchError((error: any) => {
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
  ).subscribe(
    (response: any) => {
      if (response.statusCode == 200) {
        this.message = "CutOff Year Deleted";
        this.toastr.success(this.message);
        this.viewCutOffDateList();
        $('#deleteCutOffDate').modal('hide');
        this.cdref.detectChanges();

      }
      else {
        this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
      }
    }

  )
}


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }
}


