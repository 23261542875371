import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from '../valueObject/config';
import { env } from 'process';


@Injectable({
  providedIn: 'root'
})
export class EnitTradeService {

  newNeatReqData: any[] = [];
  reqList: any[] = [];

  config: any = config;
  mrcList: any[] = []
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };


  constructor(private http: HttpClient) { }

  submitUserIdRequest(requestObject) {
    let URL = environment.URL + this.config.URL.submitUserIdRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options)
  }
  submitAdminUserIdRequest(requestObject) {
    let URL = environment.URL + this.config.URL.submitAdminUserIdRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options)
  }
  getDisplayNewNeatIdRequest() {
    let URL = environment.URL + this.config.URL.displayUIRequestProcessPage;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }
  displayqualificationlist(requestObject) {
    let URL = environment.URL + this.config.URL.displayqualificationlist;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }
  //vedantis work

  getDetailsOnTapIp(memId: any, selectedTapip: string) {
    let URL = environment.URL + this.config.URL.getDetailsOnTapIp + "?" + "memId=" + memId + "&" + "selectedTapip=" + selectedTapip;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  getRelationshipList(selectedOffStatus: string) {
    let URL = environment.URL + this.config.URL.getRelationshipList + "?" + "selectedOffStatus=" + selectedOffStatus;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }
  getTapipdetails(requestObject) {
    let URL = environment.URL + this.config.URL.getTapipdetails;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }
  updateNeatUser(action: string, user: any) {
    let URL = environment.URL + this.config.URL.UpdateNeatRequestId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, user, this.options);
  }


  addNeatUser(userList: any[]) {
    this.newNeatReqData = userList;
  }

  getAllRequests() {
    return this.newNeatReqData;
  }

  getNonNeatRequest(num_REF_NO: any) {//added by Siddhant 2022
    return this.newNeatReqData.find(req => req.num_REF_NO == num_REF_NO);
  }
  getNewNeatRequestData(refNo: any) {
    return this.newNeatReqData.find(req => req.refNo == refNo)
  }

  submitNewNeatReport(requestObject) {
    let URL = environment.URL + this.config.URL.submitNewNeatReport;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  // Vedantis work from here

  getTapID(memCode: String, memId: String) {
    let URL = environment.URL + this.config.URL.getTapID + "?" + "memCode=" + memCode + "&" + "memId=" + memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);

  }

  getReadonlyData(memId: String, tapIp: String) {
    let URL = environment.URL + this.config.URL.getReadonlyData + "?" + "memId=" + memId + "&" + "tapIp=" + tapIp;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  // getReadonlyData(requestObject) {
  //   let URL = environment.URL + this.config.URL.getReadonlyData;
  //   this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
  //   return this.http.post(URL,requestObject, this.options);
  // }

  submitMessageRateChangeRequest(requestObject) {
    let URL = environment.URL + this.config.URL.submitMessageRateChangeRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }



  getMessageRateReport(requestObject) {
    let URL = environment.URL + this.config.URL.getMessageRateReport;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }







  getDropdownData() {
    let URL = environment.URL + this.config.URL.getDropdownData;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  addMrcList(mrcList: any[]) {
    this.mrcList = mrcList;
  }

  getAllMrc() {
    return this.mrcList;
  }

  getMrcByRef(id: number) { // change according to reference number
    return this.mrcList.find(data => data.mrcRefNum == id);
  }


  approvalForMrcStatus(requestObject) {
    let URL = environment.URL + this.config.URL.approvalForMrcStatus;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }




  // admin user MIS for password/unlock component
  getResetUnlockMis(requestObject) {
    let URL = environment.URL + this.config.URL.getResetUnlockMis;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  addReqList(reqList: any[]) {
    this.reqList = reqList;
  }

  getAllRequest() {
    return this.reqList;
  }

  getReqByRef(pcmReqRefNo: string) { // change according to reference number
    return this.reqList.find(data => data.pcmReqRefNo == pcmReqRefNo);
  }


  displayAdminMis(requestObject) {
    let URL = environment.URL + this.config.URL.displayAdminMis;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  updateRequestPwd(requestObject) {
    let URL = environment.URL + this.config.URL.updateRequestPwd;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  downloadCertification(requestObject){
    let URL = environment.URL + this.config.URL.downloadCertification;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  updateDlrNtework(){
    let URL = environment.URL + this.config.URL.updateDlrNtework;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, this.options);
  }


}
