import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { User } from '../valueObject/enitUser';
import { config } from '../valueObject/config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';




@Injectable({
  providedIn: 'root'
})
export class EnitService {
  config: any = config;
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(private http: HttpClient,
     private toastr: ToastrService,
    private router: Router) { }

  users: User[] = [];
  userDetails: any;

  setUserDetails(data: User) {
    this.userDetails = data;
    localStorage.setItem('userDetails', JSON.stringify(data));
  }

  addUsers(userlist: User[]) {
    this.users = userlist;
  }

  getAllUsers() {
    return this.users;
  }

  getUserByMemCode(memId: number) {
    return this.users.find(users => users.memId == memId);
  }
  saveNeatUserNameChange(requestObject) {
    let URL = environment.URL + this.config.URL.saveNeatUserNameChange;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }
  getUserByMemCode2(memId:String) {
    let URL = environment.URL + this.config.URL.getNewNeatIdRequestbySebiNo+"/"+memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }
  getUserByMemCode1(reqId: number) {
    let URL = environment.URL + this.config.URL.getNewNeatIdRequestbySebiNo/reqId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getDealerIdDetails(memCode: String,memId: String) {
    let URL = environment.URL + this.config.URL.DisplayDisableNeatUserList+"/"+memCode+"/"+memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }

  getDisplayAlgoUndertakingDetails(memCode: String,memId: String) {
    let URL = environment.URL + this.config.URL.DisplayAlgoUndertakingDetails+"/"+memCode+"/"+memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }


  getShifingIdDetails(memCode: String,memId: String) {
    let URL = environment.URL + this.config.URL.DisplayShiftingNeatUserList+"/"+memCode+"/"+memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }


  getSingleDealerIdDetails(memCode: String,memId: String,userId:number) {
    let URL = environment.URL + this.config.URL.DisplayNeatUserDealerDetails+"/"+memCode+"/"+memId+"/"+userId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }

  SaveDisableNeatUserIdRequest(requestObject) {
    let URL = environment.URL + this.config.URL.SaveDisableNeatUser;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }
  
  SaveDisableAdminNeatUserIdRequest(requestObject) {
    let URL = environment.URL + this.config.URL.SaveDisableAdminNeatUser;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }

  ApproveUserIdrequest(requestObject) {
    let URL = environment.URL + this.config.URL.ApproveUserIdrequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }
  
  saveAlgoUndertakingRequest(requestObject) {
    let URL = environment.URL + this.config.URL.saveAlgoUndertakingRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }
 
  SaveShiftingNeatUserIdRequest(requestObject) {
    let URL = environment.URL + this.config.URL.SaveShiftingNeatUser;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }

  getDealerIdDetailsForValidityUpdation(requestObject){
   
    let URL = environment.URL + this.config.URL.ValidityUpdateRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }


  SaveValidityUpdateRequestObj(requestObject){
    let URL = environment.URL + this.config.URL.SaveValidityUpdateRequestObj;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }

  getSebiDetailsBymemId(memId : any){ // by siddhant 

    let URL =  environment.URL + this.config.URL.getSebiDetailsBymemId+"/"+memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }

  getNewRequestPswResetPage(memCode: String,memId: String){
    let URL = environment.URL + this.config.URL.newRequestPwdResetPage+"/"+memCode+"/"+memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getCorpMgrList(memId: String,userType:String)
  {
    let URL = environment.URL + this.config.URL.getCorpMgrList+"/"+memId+"/"+userType;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }


  SavePasswordResetReqObj(requestObject){
    let URL = environment.URL + this.config.URL.SavePasswordResetReqObj;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL,requestObject,this.options);
  }
  
  getUserDetails() {
    if (this.userDetails)
      return this.userDetails;
    else
      return JSON.parse(localStorage.getItem('userDetails'));
  }
 
  newNeatReqData: any[] = []; 

getActivatedCtclNeatId(memId : any ){ //by siddhant 2022
    let URL = environment.URL + this.config.URL.getActivatedCtclNeatId + '/' +memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  getDetailsOnUserIdSelect(userID : number){ //by Siddhant 2022
    let URL: string = environment.URL + this.config.URL.getDetailsOnUserIdSelect + '/'  + userID;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  saveNeatNonNeatConvRequest(requestObject : any){ //by Siddhant 2022
    let URL : string = environment.URL + this.config.URL.SaveNeatNonNeatConvRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options);
  }

  getNonNeatReportList(){//by Siddhant 2022
    let URL = environment.URL + this.config.URL.displayNonNeatReport;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL,this.options);
  }

  displayNonNeatReportOfMember(memId: any){//by Siddhant 2022
    let URL = environment.URL + this.config.URL.displayNonNeatReportOfMember +'/' +  memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  saveNonNeatReporttData(requestObject){ //by Siddhant 2022   
    let URL = environment.URL + this.config.URL.saveNonNeatReporttData ;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options);
  }

  getNonNeatRequest(num_REF_NO :any){//added by Siddhant 2022
    return this.newNeatReqData.find(req => req.num_REF_NO == num_REF_NO);
  }

  generateDeclaration(requestObject){
    let URL = environment.URL + this.config.URL.generateDeclaration ;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options);
  }

  downloadDeclaration(){
    let URL = environment.URL + this.config.URL.downloadDeclaration ;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  getShiftingBoxId(shiftTapIp: string){
    let URL = environment.URL + this.config.URL.getShiftingBoxId + '?' + 'tapIp=' + shiftTapIp ;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }
}