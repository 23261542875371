
<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Process Non-Neat Requests</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="tradeAdmin/eUseridRequestA/displayNonNeatReport" aria-current="page">Process Non-Neat Requests</a></li>
                </ul>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please wait.</p>
    </ngx-spinner>
     <table id="NonNeatRequestList" class="table common-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                            <th class="pre-wrap">Sr.No.</th>
                            <th class="pre-wrap">Member Code</th>
                            <th class="pre-wrap">Member Name</th>
                            <th class="pre-wrap">Submission Date</th>
                            <th class="pre-wrap">Segment</th>
                            <th class="pre-wrap">Request Type</th>
                            <th class="pre-wrap">NEAT/CTCL User</th>
                            <th class="pre-wrap">Status</th>
                            <th class="pre-wrap">Approval Date</th>
                            <th class="pre-wrap">User Id</th>
                            <th class="pre-wrap">Branch Manager ID</th>
                            <th class="pre-wrap">Rejected Reason</th>
                            <th class="pre-wrap">Edit</th>
                        </tr>
                        <tr class="column-search">
                            <th></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th>
                                <select class="form-control form-control-sm">
                                    <option value=".*" default selected>SELECT</option>
                                    <option value=".*NEW NEAT USER ID.*">NEW</option>
                                    <option value=".*CHANGE NAME FOR USER.*">DISABLEMENT </option>
                                    <option value=".*CONVERSION.*">CONVERSION </option>
                                </select>
                            </th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th>
                                <select class="form-control form-control-sm">
                                    <option value=".*" default selected>SELECT</option>
                                    <option value=".*Under Processing.*">UNDER PROCESSING</option>
                                    <option value=".*SUBMITTED.*">SUBMITTED</option>
                                    <option value=".*APPROVED.*">APPROVED</option>
                                    <option value=".*REJECTED.*">REJECTED</option>
                                </select>
                            </th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of nonNeatReqsts">
                            <td>{{nonNeatReqsts.indexOf(data)+1}}</td>
                            <td>{{10000+data.num_MEM_ID}}</td>
                            <td>-</td>
                            <td>{{data.num_CREATE_DT | date}}</td>
                            <td>{{data.nud_SEG_CD}}</td>
                            <td>{{data.num_REQ_TYPE}}</td>
                            <td>-</td>
                            <td>{{data.num_STATUS}}</td>
                            <td>{{data.nud_UPDATE_DT}}</td>
                            <td>{{data.nud_USER_ID}}</td>
                            <td>-</td>
                            <td>{{data.num_REASON_RETURN}}</td>
                            <td><i class="fa fa-pencil pointer" aria-hidden="true" (click)="processNonNeatRequest(data.num_REF_NO)"></i>
                        </tr>

                      </tbody> 
                    </table>
    
</div>