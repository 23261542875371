import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RolemanagementRoutingModule } from './rolemanagement-routing.module';
import { AddRoleComponent } from './add-role/add-role.component';
import { RoleComponent } from './role/role.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AddRoleComponent, RoleComponent],
  imports: [
    CommonModule,
    RolemanagementRoutingModule,
    DataTablesModule,
    FormsModule, 
    ReactiveFormsModule,
  ]
})
export class RolemanagementModule { }
