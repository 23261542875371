import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CertificateveirfyOtp } from 'src/app/service/certificateveirfy-otp';
import { config } from 'src/app/valueObject/config';
import { Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/service/app.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-verify-request-by-otp',
  templateUrl: './verify-request-by-otp.component.html',
  styleUrls: ['./verify-request-by-otp.component.css']
})
export class VerifyRequestByOtpComponent implements OnInit {

  submitted = false;
  message: string;
  config: any = config;
  resendDisable: boolean = false;
  subscription: Subscription;
  timeVal: number;
  page: string;
  otpForm: FormGroup;
  verifyOTP: boolean;

  module:String;
  reqtype:Number;
  loginId: String;
  memId: Number;

  @Output() newItemEvent = new EventEmitter<boolean>();

 
  
  constructor(private formBuilder: FormBuilder,private certificateVerfiy:CertificateveirfyOtp, private router: Router,private route: ActivatedRoute,private appService: AppService,private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      if (params['page']) {
        this.page = params['page'];
      }
    });
  }


  get f() { return this.otpForm.controls; }

  ngOnInit(): void {
    this.otpForm = this.formBuilder.group({
      otp:[],
    })
   
  }


  startResendTimer(time) {
    const source = timer(0, 1000);
    this.subscription = source.subscribe(val => {
      this.timeVal = time - val;
      if (this.timeVal === 0) {
        this.stopTimer();
      }
    })
  }

  stopTimer() {
    this.subscription?.unsubscribe();
  }

  generateRequestVerficationOtp() {
    this.stopTimer();
    let requestObject = {
        "loginId":this.loginId,
        "module":this.module,
        "memId":this.memId,
        "reqtype":this.reqtype,
    };
    console.log(requestObject)
      this.certificateVerfiy.generateRequestVerficationOtp(this.config.URL.generateRequestVerficationOtp, requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode && response.statusCode === 200) {
          this.message = this.config.successResponse.login[response.message];
          this.resendDisable = true;
          this.startResendTimer(2 * 60);
          setTimeout(() => {
            this.resendDisable = false;
            this.stopTimer();
          }, 2 * 60 * 1000);
        } 
      });
  }
  onSubmit() {
    
    this.submitted = true;
    let requestObject = {
      "loginId":this.loginId,
      "module":this.module,
      "memId":this.memId,
      "reqtype":this.reqtype,
      "otp": this.f.otp.value
    }

    this.certificateVerfiy.validateOTPRequestVerify(this.config.URL.validateOTPRequestVerfication, requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
          
        }
      })
    ).subscribe((response: any) => {
      if(response.statusCode == 200){
        this.toastr.success("OTP Verified Successfully");
        this.newItemEvent.emit(true)
        this.otpForm.reset();
        this.message = '';
  
      }
      else{
        this.toastr.error("Invalid OTP");
        this.newItemEvent.emit(false);
        this.otpForm.reset();
    
      }
    }
    );
  }

  initializeComponent(module:String,reqtype:Number,loginId: String,memId: Number){
      this.module=module;
      this.reqtype=reqtype;
      this.loginId=loginId;
      this.memId=memId;
      this.resendDisable=false;
      this.otpForm.patchValue({
        otp:""
      })
  }
}
