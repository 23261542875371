<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>CEL User Activation/Suspension</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"> <a routerLink="/cel/activateCelUser" skipLocationChange aria-current="page">
                            CEL User Activation/Suspension</a></li>
                </ul>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please wait.</p>
        </ngx-spinner>
    </div>
    <div class="">
        <div class="row clearSearch">
            <button type="button" class="btn btn-cancel btn-sm" aria-label="close" (click)="clearSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <div class="col-lg-6">
            <div class="row newReload">
                <div class="pl-4 mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        (click)="getCelActivationData()" class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16">
                        <title>Reload</title>
                        <path
                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                    </svg>
                </div>
            </div>
        </div>
        <table id="celActivateTable" class="table common-table" datatable [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th class="pre-wrap">Sr No</th>
                    <th class="pre-wrap">Branch id</th>
                    <th class="pre-wrap">Branch Name</th>
                    <th class="pre-wrap">User id</th>
                    <th class="pre-wrap">User Name</th>
                    <th class="pre-wrap">Enable Activation</th>
                </tr>
                <tr class="column-search">
                    <th></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of celActivationData; let i=index">
                    <td>{{i+1}}</td>
                    <td>{{data.branchId}}</td>
                    <td>{{data.branchName}}</td>
                    <td>{{data.userId}}</td>
                    <td>{{data.userName}}</td>
                    <td>
                        <span class="hidden">{{data.status=='Y'?'Active':'Deactive'}}</span>
                        <svg data-backdrop="static" data-keyboard="false" *ngIf="data.status=='N'"
                            (click)="enableId(data.userId, 'Y')" xmlns="http://www.w3.org/2000/svg" data-toggle="modal"
                            data-target="#celEnable" width="16" height="16" fill="currentColor"
                            class="suspendColor bi bi-x-square usercontrol" viewBox="0 0 16 16">
                            <path
                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <!-- <svg data-backdrop="static" data-keyboard="false" *ngIf="data.status=='Y'"
                            (click)="enableId(data.userId, 'N')" xmlns="http://www.w3.org/2000/svg" data-toggle="modal"
                            data-target="#celEnable" width="16" height="16" fill="currentColor"
                            class="fillColor bi bi-check-square-fill usercontrol" viewBox="0 0 16 16">
                            <path
                                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg> -->
                        <svg data-backdrop="static" data-keyboard="false" *ngIf="data.status=='Y'"
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="fillColor bi bi-check-square-fill usercontrol" viewBox="0 0 16 16">
                            <path
                                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal fade" id="celEnable" tabindex="-1" role="dialog" aria-labelledby="celEnablelabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <h6>Are you sure you want to {{enableStatus=='Y'?'activate':'suspend'}} this user?</h6>
                        <p><strong> User ID :</strong><span>{{selectedUser.userId}}</span></p>
                        <p><strong> User Name :</strong><span>{{selectedUser.userName}}</span></p>
                    </div>
                </div>
            </div>
            <div class="btn-group d-flex" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary col-6" data-dismiss="modal" (click)="enableUser()"
                    aria-label="Close"> Confirm </button>
                <button type="button" class="btn btn-secondary close col-6" data-dismiss="modal" aria-label=" Close"
                    data-dismiss="modal"> Cancel </button>
            </div>
        </div>

    </div>
</div>