import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return String(minutes).padStart(2, '0') + ':' + String(value - minutes * 60).padStart(2, '0');
  }

}