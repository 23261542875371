import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login.service';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/customValidators';
import * as bcrypt from 'bcryptjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  config: any = config;
  passwordPattern: string;
  passwordPolicy: string;
  message: string;
  page: string;
  username: string;
  fieldTextType: any = [false, false, false];

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private appService: AppService) {
    this.route.queryParams.subscribe(params => {
      if (params['page']) {
        this.page = params['page'];
      }
      if (params['username']) {
        this.username = params['username'];
      }
    });
  }

  get f() { return this.changeForm.controls; }

  ngOnInit(): void {
    this.changeForm = this.fb.group({
      username: [this.username, Validators.required],
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: CustomValidators.match('newPassword', 'confirmPassword')
    });
    this.passwordPattern = this.config.data.passwordPattern;
    this.passwordPolicy = this.config.data.passwordPolicy;
  }

  onSubmit() {
    if (!this.changeForm.valid) {
      return;
    }
    let salt = atob(localStorage.getItem(btoa('salt')));
    let encryptedPass = bcrypt.hashSync(this.f.newPassword.value, salt);
    this.f.newPassword.setValue(encryptedPass);
    this.f.confirmPassword.setValue(encryptedPass);
    let oldPassword = bcrypt.hashSync(this.f.oldPassword.value, salt);
    this.f.oldPassword.setValue(oldPassword);
    let changePasswordRequest: any = {
      "loginId": this.f.username.value,
      "password": this.f.newPassword.value,
      "oldPassword": this.f.oldPassword.value
    };
    this.loginService.changePassword(this.config.URL.changePasswordURL, changePasswordRequest).pipe(
      catchError((error: any) => {
        this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
        this.reset();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message) {
        switch (response.message) {
          case "SUCCESSFULL": {
            this.toastr.success("Password updated successfully");
            this.router.navigate(['/login']);
            break;
          }
          case "invalidCred":
          case "PASSWORD_PRESENT_IN_HISTORY": {
            this.toastr.error(this.config.failureResponse.login[response.message]);
            this.message = this.config.failureResponse.login[response.message];
            this.reset();
            break;
          }
          default: {
            this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
            this.reset();
            break;
          }
        }
      }
    });
  }

  reset() {
    this.changeForm.reset();
    this.f.username.setValue(localStorage.getItem('username'));
  }

  back() {
    if (this.page == 'login') {
      this.appService.logout({}).pipe(
        catchError((error: any) => {
          localStorage.removeItem('token');
          localStorage.removeItem('userDetails');
          localStorage.removeItem('username');
          this.router.navigate(['/login']);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.message == "SUCCESSFULL") {
          localStorage.removeItem('token');
          localStorage.removeItem('userDetails');
          localStorage.removeItem('username');
          this.router.navigate(['/login']);
        }
      });
    } else if (this.page == 'home') {
      this.router.navigate(['']);
    }
  }

  toggleFieldTypeTextTrue(id) {
    this.fieldTextType[id] = true;
  }

  toggleFieldTypeTextFalse(id) {
    this.fieldTextType[id] = false;
  }

}
