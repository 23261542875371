<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Member Report Upload</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li   class="active"><a routerLink="eCompliance/incidentReport/memberReportUpload" aria-current="page">Member Report Upload</a></li>  
                </ul>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please wait.</p>
      </ngx-spinner>
      <div class="card mt-3">
        <div class="card-header">
           Member: {{user.entityCode}} - {{user.userName}} 
         </div>
        </div> 
      <form [formGroup]="fileUpload">
        <div class="card card-body colorborder mb-3 usercontrol">
         <div class="row">
            <div class="col-lg-2">
              <h6>
               Please upload Member Report 
              </h6>
            </div>
            <div class="col-lg-4">
              
              <input type="file" class="form-control" formControlName="file" accept=".pdf" title="Select A File" #file (change)="onChange($event)"/>
                <p *ngIf="fileUpload.get('file').invalid && fileUpload.get('file').touched" class="error">

                Please upload file
              </p>
              <div *ngIf="fileError" >
                <p class="error nocase">File should be in pdf format & file size should not be more than 10 MB</p>
             </div>
             <div>
              <p class="error nocase">File should be in pdf format & file size should not be more than 10 MB</p>
            </div>
            </div>
            <div *ngIf="fileName" class="col-lg-4">
              <label>Attached file</label>
              <p>{{fileName}}
                <span class="ml-2 pointer">
                  <svg (click)="removeFile()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                      d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                  </svg>
                </span>
              </p>
            </div>
            
          </div>
          <br>
          <div class="row justify-content-start">
            <div class="col-lg-6">
              <div class="row justify-content-start">
                <div class="col-lg-2">
                  <button class="btn btn-primary" [disabled]="fileUpload.invalid || fileError" (click)="onSubmit()">Upload</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
</div> 