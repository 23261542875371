<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Shifting Of User ID</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="eTrade/eUseridRequest/displayShiftingOfNeatIdRequestPage" aria-current="page">Shifting Of User ID</a></li>
                    
                </ul>
            </div>

        </div>
    </div>
    <div>

        <form [formGroup]="ShiftingNeatUser" >
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                        
                        <div class="col-lg-3 form-group">
                            <label>User Id</label><span class="red">*</span>
                             <select class="form-control selectpicker" formControlName="cusDlrId" data-style="btn-info" title="Select User Id"
                                (change)="selectDlrId()" data-live-search="true">
                                <option *ngFor="let data of cusDlrId" value="{{data}}">{{data}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Name</label><span class="red">*</span>
                            <input type="text" value="" class="form-control" formControlName="UserName" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Type Of User <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="TypeOfUser" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Existing TAP/Child IP <span class="red">*</span></label>
                            <!-- <input type="text" value="" class="form-control" formControlName="ExistingTapIp" readonly > -->
                            <select class="form-control selectpicker" formControlName="ExistingTapIp" data-style="btn-info" title="Select Tap IP"
                            (change)="setTapId()" data-live-search="true">
                            <option *ngFor="let data of existingIpList" value="{{data.ipLevelsId.tapIP}}">{{data.ipLevelsId.tapIP}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>TAP ID</label><span class="red">*</span>
                            <input type="text" class="form-control" formControlName="TapId" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Shift TAP/Child IP<span class="red">*</span></label>
                            <!-- <input type="text" class="form-control" formControlName="ShiftTapId" > -->
                            <select class="form-control selectpicker" formControlName="ShiftTapId" data-style="btn-info"
                            title="Select Tap IP" data-live-search="true" (change)="checkIp()">
                            <option *ngFor="let data of finalArray" value="{{data.cusTapIp}}">{{data.cusTapIp}}
                            </option>
                        </select>                          
                            <p *ngIf="ipError" class="error nocase">
                               {{message}}
                            </p>
                        </div>

                       
                        <div class="col-lg-3 form-group">
                            <label>Existing Tap/Child Ip Address</label><span class="red">*</span>
                            <input type="text" class="form-control" formControlName="ExistingTapChildIpAddress" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Shift To TAP/Child IP Address</label><span class="red">*</span>
                            <input type="text" class="form-control" formControlName="ShiftTapChildIpAddress" readonly>
                        </div>
                        
                       
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>No Of Existing Terminals At The Above TAP IP</label>
                            <input type="text" class="form-control" formControlName="existingTerminalTapIp" readonly>

                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Email ID<span class="red">*</span></label>
                            <input type="email" class="form-control" formControlName="EmailId" >
                            <div *ngIf="f.EmailId.invalid && f.EmailId.touched">
                                <!-- <p *ngIf="f.EmailId.errors.required" class="error nocase">
                                   Email ID required
                                </p> -->
                                <p *ngIf="f.EmailId.errors.email" class="error nocase">
                                    Please enter valid email ID
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Mobile Number<span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="MobileNo"  >
                            <div *ngIf="f.MobileNo.invalid && f.MobileNo.touched" class="error nocase">

                                <!-- <div *ngIf="f.MobileNo.errors.required"> Mobile number required</div> -->
                                <div
                                    *ngIf="f.MobileNo.errors.pattern || f.MobileNo.errors.maxlength || f.MobileNo.errors.minlength">
                                    Mobile number should contain 10 digits only</div>
                            </div>

                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Reason for shifting of User ID<span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="ReasonForShiftingUser" >
                           
                            <p *ngIf="f.ReasonForShiftingUser.invalid && f.ReasonForShiftingUser.touched" class="error nocase">
                                Reason for shifting required
                            </p>
                        </div>  
                    </div>
                    <div>
                        <div class=" form-group">
                            <p> <input type="checkbox" formControlName="CheckType1" > 
                                We hereby confirm that the user shall remain the same.
                            </p>
                        </div>
                        <div class=" form-group">
                            <p> <input type="checkbox" formControlName="CheckType" > 
                                I/We declare to abide by the rules/regulations as communicated by the Exchange/SEBI/IFSCA/other Regulators from time to time.
                            </p>
                        </div>
                    </div>
                    
                    <div class="row justify-content-start mb-5">
                        <div class="col-lg-8">
                            <div class="row justify-content-start">
                                <div class="col-lg-2">
                                    <button class="btn btn-primary full-width"  [disabled]="verifyOTP!=true || submitted"  type="submit" (click)="onSubmit()">Submit</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn  btn-cancel full-width" (click)="reset()" >Reset</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn  btn-primary full-width" [disabled]="ShiftingNeatUser.invalid" data-toggle="modal"  data-target="#cashdeposite">Certify</button>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
        </form>
    </div>
</div>
<div class="modal fade" id="cashdeposite" tabindex="-1" role="dialog" aria-labelledby="cashdepositeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
              </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verfiy-request-by-otp  [module]="module" [reqtype]="reqtype" [loginId]="loginId" [memId]="memId" (newItemEvent)="newItemEvent($event)"></app-verfiy-request-by-otp>
        </div>
    </div>
</div>