export const config = {
    "URL": {
        "captchaURL": "/security/login",
        "validateURL": "/security/validateUser",
        "authenticateURL": "/security/authenticateUser",
        "logoutURL": "/security/logout-jwt-token",
        "generateOTPTokenURL": "/2FA/sendOtp",
        "validateOTPTokenURL": "/2FA/verifyOtp",
        "generateOTPURL": "/2FA/passwordUpdation/sendOtp",
        "validateOTPURL": "/2FA/passwordUpdation/verifyOtp",
        "forgotPasswordURL": "/security/forgotPassword",
        "unlockAccountURL": "/security/unlockUser",
        "changePasswordURL": "/security/changePassword",
        "userListURL": "/security/getUsers",
        "addUserURL": "/security/createUser",
        "updateUserURL": "/security/updateUser",
        "getUserDetails": "/security/getUserMenus",
        "getUserMenusByLoginId": "/security/getUserMenusByLoginId",
        "getEntityTypeData": "/security/getAutoPopulatedUserValues",
        "getEntityCodeData": "/security/getEntityCodeData",
        "getAllMenus": "/security/getAllMenus",
        "saveUserMenuMappings": "/security/saveUserMenuMappings",
        "menuList": "/security/getMenu",
        "saveMenu": "/security/saveMenu",
        "roleList": "/security/getRole",
        "saveRole": "/security/saveRole",
        "getRoleDataById": "/security/getRoleDataById",
        "getFileDownloadExt": "/extranet/getFileDownloadable",
        "getExtranetFiles": "/extranet/getMemberDirStructure",
        "uploadExtranetFile": "/extranet/uploadExtranetFile",
        "getFolderList": "/extranet/getFolderList",
        "celUserListURL": "/security/getCelUsers",
        "addCelUserURL": "/security/createCelUser",
        "updateCelUserURL": "/security/updateCelUser",
        "getBranchAdminMapping": "/cel/getBranchAdminMapping",
        "getBranches": "/cel/getBranches",
        "addBranchAdminMapping": "/cel/addBranchAdminMapping",
        "deleteBranchAdminMapping": "/cel/deleteBranchAdminMapping",
        "getCelLimit": "/cel/getCelLimit",
        "getCelLimitPerUser": "/cel/getCelLimitPerUser",
        "saveCelLimit": "/cel/saveCelLimit",
        "saveProdCoeff": "/cel/saveProdCoeff",
        "getProdCoef": "/cel/getProdCoef",
        "getCelDealerList": "/cel/getDealerList",
        "enableCelMonitoring": "/cel/enableCelMonitoring",
        "getCelActivationList": "/cel/getCelActivationList",
        "activateCelUser": "/cel/activateCelUser",
        "getBranchAlert": "/cel/getBranchAlert",
        "saveBranchAlert": "/cel/saveBranchAlert",
        "getCelUtilization": "/cel/getCelUtilization",
        "getMarketAlert": "/cel/getMarketAlert",
        "getCelAudit": "/cel/getCelAudit",
        "verifyEmail": "/security/verifyEmail",
        "getMMInfo": "/cel/getMMInfo",
        "deleteBranchEmail": "/cel/deleteBranchEmail",
        "sendVerificationLink": "/security/sendVerificationLink",
        //ENIT 
        "displayqualificationlist": "/enit/trade/displayqualificationlist",
        "submitAdminUserIdRequest": "/enit/trade/updateDealerId",
        "DisplayDisableNeatUserList": "/enit/trade/disbleMember",
        "UpdateNeatRequestId": "/enit/trade/UpdateNeatRequestId",
        "SaveShiftingNeatUser": "/enit/trade/saveShiftingNeatIdRequest",
        "DisplayShiftingNeatUserList": "/enit/trade/displayShiftingMember",
        "ValidityUpdateRequest": "/enit/trade/displayNeatUsrValidityUpdtRequest",
        "saveNeatUserNameChange": "/enit/trade/neatUserNameChange",
        "getNewNeatIdRequestbySebiNo": "/enit/trade/displayNewNeatIdRequest",
        "submitUserIdRequest": "/enit/trade/insertdealerdeatils",
        "getTapipdetails": "/enit/trade/getTapipdetails",
        "DisplayNeatUserDealerDetails": "/enit/trade/displayDealer",
        "SaveDisableNeatUser": "/enit/trade/saveDisableNeatIdRequest",
        "SaveDisableAdminNeatUser": "/enit/trade/saveAdminDisableNeatIdRequest",
        "SaveValidityUpdateRequestObj": "/enit/trade/saveValidateData",
        "DisplayAlgoUndertakingDetails": "/enit/trade/displayAlgoUndertaking",
        "saveAlgoUndertakingRequest": "/enit/trade/saveAlgoUndertaking",
        "displayUIRequestProcessPage": "/enit/trade/displayUIRequestProcessPage",
        "getSebiDetailsBymemId": "/enit/trade/displayNewNeatIdRequest",
        "submitNewNeatReport": "/enit/trade/submitNewNeatReport",
        "displayNonNeatReport": "/enit/trade/displayNonNeatReport",
        "saveNonNeatReportData": "/enit/trade/saveNonNeatReportData",
        "SavePasswordResetReqObj": "/enit/trade/submitRequest",
        "getCorpMgrList": "/enit/trade/getCorpMgrUserId",
        "newRequestPwdResetPage": "/enit/trade/newRequestPwdReset",
        "ApproveUserIdrequest": "/enit/trade/ApproveUserIdrequest",
        //vedantis work:
        "getTapID": "/enit/trade/getTapId",
        //"getReadonlyData": "/enit/trade/getSelectedTapIpDetailsForMRC",
        "getReadonlyData": "/enit/trade/getTapIdDetails",
        "submitMessageRateChangeRequest": "/enit/trade/saveMrcRequest",
        "getDropdownData": "/enit/trade/displayMRCReport",
        "getMessageRateReport": "/enit/trade/fetchMRCReportDetails",
        "approvalForMrcStatus": "/enit/trade/approvalForRequestStatusMRC",
        "getResetUnlockMis": "/enit/trade/viewPasswordDetails",
        "displayAdminMis": "/enit/trade/displayAdminMis",
        "updateRequestPwd": "/enit/trade/UpdateRequest",
        "generateDeclaration": "/enit/trade/generateDeclartion",
        "downloadDeclaration": "/enit/trade/donwloadDeclartion",
        "getDetailsOnTapIp": "/enit/trade/getSelectedTapIpDetailsAndTerminalNo",
        "getRelationshipList": "/enit/trade/getRelationListForSelectedOffice",
        "downloadCertification": "/enit/trade/downloadFile",
        "getShiftingBoxId": "/enit/trade/getBoxIdAsperIp",

        //SIDDHANT'S WORK
        "displayNonNeatReportOfMember": "/enit/trade/displayNonNeatReportOfMember",
        "getActivatedCtclNeatId": "/enit/trade/displayNeatToNonNeatRequest",
        "getDetailsOnUserIdSelect": "/enit/trade/getDetailsOnUserIdSelect",
        "SaveNeatNonNeatConvRequest": "/enit/trade/SaveNeatNonNeatConvRequest",
        "saveNonNeatReporttData": "/enit/trade/saveNonNeatReportData",

        // CTCL URL
        "ctclIdDeactivation": "/enit/trade/displayCtclDeActivation",
        "ctclTerminalIdData": "/enit/trade/getNeatAndTerminal",
        "ctclRequestDataForDeact": "/enit/trade/getCtclRequestForDeact",
        "ctclIdActivation": "/enit/trade/displayCtclActivation",
        "ctclNeatIdAndTerminalOffice": "/enit/trade/getNeatId",
        "ctcNeatIdDeactivateDate": "/enit/trade/getNeatIDDeactivateDate",
        "ctclSubmitActivation": "/enit/trade/submitCtclRequest",
        "ctclIdModification": "/enit/trade/displayCtclModification",
        "displayCtclIdReport": "/enit/trade/displayCtclIdReport",
        "fetchCtclIdRequest": "/enit/trade/fetchCtclIdRequest",
        "fetchCTCLDetailsByRefNo": "/enit/trade/fetchCTCLDetailsByRefNo",
        "approvalOfCtcl": "/enit/trade/approvalOfCtcl",
        "ctcdownloadCertHelp": "/enit/trade/downloadCertHelpFile",
        "updateDlrNtework": "UpdateDlrNtework",
        "generateRequestVerficationOtp": "/2FA/sendModuleOtp",
        "validateOTPRequestVerfication": "/2FA/verifyModuleOtp",


        //Compliance URLs Sid & shravan
        "getAimlEligibleData": "/compliance/getEligibleTradeUser",
        "addQuarterTradeUser": "/compliance/addQuarterTradeUser",
        "deleteQuarterTradeUser": "/compliance/deleteQuarterTradeUser",
        "getQuarterDetails": "/compliance/getQuarterDetails",
        "getComplianceMemberDetails": "/compliance/getComplianceMemberDetails",
        "notifyAllTradeUser": "/compliance/notifyAllTradeUser",
        "getMemberDetails": "/compliance/getMemberDetails",
        "saveForEmailNotification": "/compliance/saveEmailNotification",
        "getPeriodList": "/compliance/getDetailsForPeriod",
        "saveForPeriodList": "/compliance/saveYearList",
        "editExchangeEmailId": "/compliance/updateEmail",
        "deleteExchangeEmailId": "/compliance/deleteEmail",
        "viewExchangeEmailId": "/compliance/getEmailList",
        "saveEmailTemplate": "/compliance/addEmailTemplate",
        "deleteEmailTemplate": "/compliance/deleteEmailTemplate",
        "editEmailTemplate": "/compliance/editEmailTemplate",
        "getFunctionality": "/compliance/viewFunctionalityListOnLoad",
        "getNewFunctionality": "/compliance/viewFunctionalityListToAddNewTemplate",
        "viewEmailTokenList": "/compliance/viewEmailTokenList",
        "saveForCutOffDate": "/compliance/saveCutOffList",
        "viewCutOffDate": "/compliance/getCutOffList",
        "editCutOffDate": "/compliance/updateCutOffList",
        "viewPeriodData": "/compliance/getTimelyList",
        "saveMlAiRequest": "/compliance/saveMlAIDetails",
        "saveMlAiNillRequest": "/compliance/saveMlAIDetailsNill",
        "getEligibility": "/compliance/getEligibility",
        "getCutOffDate": "/compliance/getCuffoffFlag",
        "getQuarterForSubmission": "/compliance/GetEligibleQuarter",
        "getMLandAiReport": "/compliance/GetMlAiMisReport",
        "getQuarterDropDowndata": "/compliance/getQuarterStartDate",
        "getDataByRefId": "/compliance/getMlaiDataByRefNo",
        "aiMlFileUpload": "/compliance/uploadFile",
        "getFormSubmissionStatus": "/compliance/IsSubmited",
        "downloadAIMLCyberFile": "/compliance/cyber/dnldCyberSampleFile",

        //Report ashwini abhijeet
        "loadReportData": "/enit-report/enit-report-list",
        "misDownload": "/enit-report/download-ctcl-report",
        "notifyTradeUser": "/compliance/notifyTradeUser",
        //Cyber-Security 
        "getAllCyberSecurityEnabledMembers": "/compliance/cyber/getAllCyberSecurityEnabledMembers",
        "enableCyberSecurityEnabledMember": "/compliance/cyber/enableCyberSecurityEnabledMember",
        "removeCyberSecurityEnabledMember": "/compliance/cyber/removeCyberSecurityEnabledMember",
        "notifyAllCyberSecurityUser": "/compliance/cyber/notifyAllCyberSecurityUser",
        "notifyCyberSecurityUser": "/compliance/cyber/notifyCyberSecurityUser",
        "getCyberSecurityMemberDetails": "/compliance/cyber/getCyberSecurityMemberDetails",

        "misSearch": "/enit-report/getEntityFilter",
        "downloadReport": "/enit-report/generate-ctcl-adhoc-report",
        "quaterlyIncidentReport": "/compliance/api/incident-report/v1.0/incidents",
        "getEnable": "/compliance/api/incident-report/v1.0/getEnable",
        "fetchIncidentIdReport": "/compliance/api/incident-report/v1.0/incidents",

        "getCyberSecurityReport": "/compliance/api/incident-report/v1.0/incidents/search",
        // ashwini abhijeet
        "viewModuleData": "/compliance/getCutOffModuleList",
        "getAllMembers": "/compliance/getAlleMemberDetails",
        "uploadCyberSecurityReport": "/compliance/cyber/uploadCyberSecurity",
        // ashwini - half Yearly networth Config setup
        "viewMemeberNetworthList": "/compliance/getNetworthCategoryInfo",
        "deleteNetworthMember": "/compliance/removeNetworthCategoryInfo",
        "editNetworthList": "/compliance/editNetworthCategoryInfo",
        "saveForNetworth": "/compliance/addNetworthCategoryInfo",
        //abhijeet -Cutoff Delete
        "deleteCutOffYear": "/compliance/deleteCutOffYear",
        "revampTradeApplication": "/enit/app/security/ssoCreate",
        "revampTradeApplicationInspection": "/insp/app/security/ssoCreate",
        "revampMembership": "/membership/app/security-mem/ssoCreate",
        "saveDetails": "/security/saveDetails",
        "uploadFile": "/security/uploadFile",
        "getExchangeAndRegulators": "/security/get-country-details",
        "getParameterFromType": "/security/getParameterFromType",

        "sendOtpOnForRbdEmail": "/security/sendOtpOnForRbdEmail",
        "verfiyRbdOtp": "/security/verfiyRbdOtp",
        "celaringMeberDetails": "/security/celaringMeberDetails",
        "getCountryDetails": "/security/getCountries",

        //LES Shravan
        // "getAllLesOprnlSchemeData": "/les/lesoperatinal/getAllLesOprnlSchemeData",
        // "getClientsAndSchemeList": "/les/lesoperatinal/getClientsAndSchemeList",
        "getDailyFileRecord": "/les/lesoperatinal/getDailyFileRecord",
        "getHourlyFileRecords": "/les/lesoperatinal/getHourlyFileRecords",
        "downloadHourlyIncentiveFile": "/les/lesoperatinal/downloadHourlyIncentiveFile",
        "downloadDailyIncentiveFile": "/les/lesoperatinal/downloadDailyIncentiveFile",
        "filterHistoricalData": "/les/lesoperatinal/filterHistoricalData",

        //les Pranil 
        "getClientsAndSchemeList": "/les/LesSearchDropdownValues",
        "getListMemberFiles":"/les/listMemberFiles",
        "downloadHourlyAndDailyFile":"/les/download",
        "getAllLesOprnlSchemeData": "/les/getAllHourlyMM",

    },

    "data": {
        "passwordPattern": "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@_]).{12,})",
        "userPattern": "^[a-zA-Z0-9@_]*$",
        "captchaPattern": "^[a-zA-Z0-9]*$",
        "passwordPolicy": "Password should contain atleast 1 lower case character, 1 upper case character, 1 numeric and 1 special character. Password Length should be minimum 12 characters. ",
        "entityTypeData": [{
            "id": "TM",
            "name": "Trading Member",
            "menu": ["extranet", "common", "member", "eEnit", "enit", "enitAudit", "eEnitTradeRevamp", "rInspection", "les", "lesOperation",],
        }, {
            "id": "ADMIN",
            "name": "Admin",
            "menu": ["admin", "userlist", "useraccess", "eEnit", "enit", "enitAudit", "eEnitTradeRevamp", "rInspection", "membership", "les", "lesOperation"]
        }, {
            "id": "AUDITOR",
            "name": "AUDITOR",
            "menu": ["enit"]
        }],
        "userTypeData": [{
            "id": 1,
            "name": "Super Admin",
            "menu": ["admin", "userlist", "useraccess"]
        }, {
            "id": 2,
            "name": "Admin",
            "menu": []
        }, {
            "id": 3,
            "name": "Auditor",
            "menu": []
        }],
        "celUserTypeData": [{
            "id": 0,
            "name": "NA",
            "menu": []
        }, {
            "id": 1,
            "name": "Super Admin",
            "menu": ["cel", "assignBranchAdmin", "monitoringEnable", "limitSetup", "alertSetup", "celMonitoring", "prodCoefSetup", "admin", "celUserMgmt", "activateCelUser", "celAuditUser"]
        }, {
            "id": 2,
            "name": "Admin",
            "menu": ["cel", "monitoringEnable", "limitSetup", "alertSetup", "celMonitoring", "prodCoefSetup", "activateCelUser", "celAuditUser"]
        }],
        "countryCodeData": [{
            "id": 91,
            "name": "India"
        }, {
            "id": 65,
            "name": "Singapore"
        }, {
            "id": 852,
            "name": "Hong Kong"
        }]
    },
    "successResponse": {
        "login": {
            "LOGIN_SUCCESS": "User logged in successfully",
            "OTP_SENT_TO_USER": "One time password (OTP) has been sent to registered mobile number & email address",
            "OTP_VALIDATE_SUCCESS": "OTP validated successfully",
            "PASSWORD_UPDATE_SUCCESS": "Your password is updated successfully",
            "UNLOCK_SUCCESS": "Your account is unlocked successfully"
        },
        "user": {
            "ADD_USER_SUCCESS": "User added successfully",
            "UPDATE_USER_SUCCESS": "User details have been updated",
            "DELETE_USER_SUCCESS": "User deleted successfully",
            "ADD_MENU_SUCCESS": "Menu added successfully",
            "UPDATE_MENU_SUCCESS": "Menu details have been updated",
            "USER_ACCESS_SUCCESS": "User access details have been updated"
        }

    },
    "failureResponse": {
        "login": {
            "invalidCred": "Invalid credentials",
            "invalidCaptcha": "Invalid captcha. Please type the text displayed in image",
            "USER_DISABLED_MESSAGE": "Your Login ID is currently locked. Please click on \"Unlock Account\" link or contact System Administrator to unlock user",
            "IS_FIRST_TIME_LOGIN_MSG": "You have logged for the first time. Please change the password.",
            "USER_PASSWORD_EXPIRED": "Your password is expired. Please change the password.",
            "DEFAULT_PASSWORD": "You have logged in with the default password. Please change the password.",
            "PASSWORD_PRESENT_IN_HISTORY": "New password should not be same as last 5 passwords",
            "LOGGED_IN_OTHER_SYSTEM": "You have already logged in from other system.",
            "UNAUTHORIZED_USER": "You are not authorized to perform this operation",
            "OTP_FAILED": "Not able to send OTP. Please try again later",
            "OTP_DISABLED": "OTP is disabled for this user.",
            "INVALID_OTP": "Invalid OTP",
            "SERVICE_UNAVAILABLE": "Service is not available. Please try again later.",
            "OTP_DISABLED_TEMPORARYLY": "OTP is temporarily disabled. Please try again after some time."
        },
        "user": {
            "ADD_USER_FAIL": "Not able to add user",
            "UPDATE_USER_FAIL": "Not able to update user",
            "DELETE_USER_FAIL": "Not able to delete user",
            "ADD_MENU_FAIL": "Not able to add menu",
            "UPDATE_MENU_FAIL": "Not able to update menu",
            "USER_ACCESS_FAIL": "Not able to save user access details"
        },
        "SOMETHING_WENT_WRONG": "Something went wrong"
    }
};