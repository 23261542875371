import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  role: any[] = [];

  constructor() { }

  addRole(roleList: any[]) {
    this.role = roleList;
  }

  getAllRole() {
    return this.role;
  }

  getRoleById(id: number) {
    return this.role.find(data => data.roleId == id);
  }
}
