import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { User } from '../valueObject/enitUser';
import { config } from '../valueObject/config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable({
    providedIn: 'root'
  })
export class CertificateveirfyOtp
{
  config: any = config;
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(private http: HttpClient,
     private toastr: ToastrService,
    private router: Router) { }

  users: User[] = [];
  userDetails: any;

  setUserDetails(data: User) {
    this.userDetails = data;
    localStorage.setItem('userDetails', JSON.stringify(data));
  }

  addUsers(userlist: User[]) {
    this.users = userlist;
  }

  getAllUsers() {
    return this.users;
  }

  getUserByMemCode(memId: number) {
    return this.users.find(users => users.memId == memId);
  }


  generateRequestVerficationOtp(generateRequestVerficationOtp: string, model: any) 
  {
    let URL = environment.URL + generateRequestVerficationOtp;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, model, this.options);
  }

  validateOTPRequestVerify(validateOTPTokenURL: string, user: any) 
  {
    let URL = environment.URL + validateOTPTokenURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

}
