import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { BranchUserService } from 'src/app/service/branch-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CelService } from 'src/app/service/cel.service';
import { catchError } from 'rxjs/operators';
import { config } from 'src/app/valueObject/config';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-limit-setup',
  templateUrl: './limit-setup.component.html',
  styleUrls: ['./limit-setup.component.css']
})

export class LimitSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  access: any = {};
  users: any = [];
  branchUserData: any = [];
  selectedBranchId: string = '';
  selectedUser: any = {};
  userControlId: string = '';
  menucode: string = 'limitSetup';
  config: any = config;
  user: any = {};

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private branchUserService: BranchUserService,
    private router: Router,
    private route: ActivatedRoute,
    private celService: CelService) { }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Limit Setup");
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
  }

  ngAfterViewInit() {
    this.getBranchUserData();
  }

  getBranchUserData() {
    this.celService.getCelLimit(this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.branchUserData = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.branchUserData = response.data[0];
          this.branchUserService.setBranchUserData(this.branchUserData);
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.branchUserData = [];
          this.setDatatable();
        }

      }
    );
  }

  editBranchData(userId) {
    this.router.navigate(['../editLimitSetup'], { queryParams: { "id": userId, "action": "edit" }, skipLocationChange: true, relativeTo: this.route })
  }

  setDatatable() {
    $("#userList").DataTable().destroy();
    setTimeout(() => {
      $("#userList").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#userList .column-search input').val('');
            $('#userList .column-search select').val('.*');
            $('#userList .column-search').on('keyup change', 'input', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#userList .column-search').on('keyup change', 'select', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  ngOnDestroy(): void {
    this.dtTrigger?.unsubscribe();
  }
}
