import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { catchError, retry } from 'rxjs/operators';
import { CtclService } from 'src/app/service/ctcl.service';
import { Subscription } from 'stompjs';
import { CtclIdDeactivationRequest, CtclIdDeactivationResponse, NeatAndterminalResponse, CtclResponseForDeactivation, CtclNeatIdAndTerminalOffice, ctclDisplayDataResponse, CtclDeactivationSubmitReq } from '../ctcl-request-response';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { VerifyRequestByOtpComponent } from '../verify-request-by-otp/verify-request-by-otp.component';

declare var $: any;

@Component({
  selector: 'ctcl-id-deactivation',
  templateUrl: './ctcl-id-deactivation.component.html',
  styleUrls: ['./ctcl-id-deactivation.component.css']
})
export class CtclIdDeactivationComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private ctclService: CtclService, 
    private httpClient: HttpClient, 
    private cdref: ChangeDetectorRef, 
    private fb: FormBuilder, 
    private toastrSrv: ToastrService, 
    private host: ElementRef<HTMLElement>, 
    private route: ActivatedRoute, 
    private router: Router, 
    private userDetailsService: UserDetailsService) {

    this.route.queryParams.subscribe(params => {

      this.displayFromReport = params['displayFromReport'] === undefined ? "NO" : params['displayFromReport'];
      this.memberOrAdminReport = params['memberOrAdminReport'] === undefined ? "NA" : params['memberOrAdminReport'];
      this.ctclRequestIdReport = params['ctclRequestIdReport'] === undefined ? "" : params['ctclRequestIdReport'];
      const ctclModifiedRequestId = this.ctclRequestIdReport.replace('_', '\\').replace('_', '\\');
      this.ctclRequestFromReport = this.ctclService.getCtclReportByRef(ctclModifiedRequestId);
    });

  }

  ngAfterViewInit(): void {
    if (this.displayFromReport === 'YES') {
      this.ctclRequestStatusDetails.nativeElement.value = this.ctclReqStatus;

      if (this.reqExam.get('ctclExamDtlsExamOf').value === 'NISM-CPE') {
        if (this.reqExam.get('ctclExamDtlsExamId').value !== '') {
          let splittedExamId: any[] = this.reqExam.get('ctclExamDtlsExamId').value.split('/')
          if (splittedExamId.length == 4) {
            this.nismexamIdPart1.nativeElement.value = splittedExamId[0];
            this.nismexamIdPart4.nativeElement.value = splittedExamId[2];

            let splitExamIdPart2: any[] = splittedExamId[1].split('-')
            if (splitExamIdPart2.length == 2) {

              this.nismexamIdPart2.nativeElement.value = splitExamIdPart2[0];
              this.nismexamIdPart3.nativeElement.value = splitExamIdPart2[1];
            }

            let splitExamIdPart4 = splittedExamId[3].split('-')
            if (splitExamIdPart4.length == 2) {
              this.nismexamIdPart5.nativeElement.value = splitExamIdPart4[0];
              this.nismexamIdPart6.nativeElement.value = splitExamIdPart4[1];
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
  }

  neatUserIdList: any[] = [];
  terminalIdList: any[] = [];
  purposeOfCtclIdList: any[] = [];
  cityList: any[] = [];
  ctclRelationList: any[] = [];
  ctclLocdtlsConnModeList: any[] = [];
  ctclExamDtlsTitleList: any[] = [];
  ctclExamDtlsCertificatetypeList: any[] = [];
  ctclAddressList: any[] = [];
  disableSubmitButton: boolean = false;
  ctclIdActivationURL: string = "";
  dobOfApprovedPersonError: string = "";
  validityDateOfApprovedPersonError: string = "";
  activationDateError: string = "";
  deactivationDateError: string = "";
  examIdPart1: string = "";
  examIdPart2: string = "";
  examIdPart3: string = "";
  examIdPart4: string = "";
  examIdPart5: string = "";
  examIdPart6: string = "";
  examIdPartError1: string = "";
  examIdPartError2: string = "";
  examIdPartError3: string = "";
  examIdPartError4: string = "";
  examIdPartError5: string = "";
  examIdPartError6: string = "";
  examIdError: string = "";
  ctclDeactivationInitialData;
  ctclActSubmitRequest: any = {};
  declarationCertified: boolean = false;
  ctclIdDeactivationSub;
  ctclReqStatus: string = "";
  ctclApprovalRejectionReq;
  testData: string = "";
  ctclRequestFromReport: any = {};
  userDetails: any = {};
  // for OTP
  module: any = "CTCLDeActivate";
  reqtype: number = 4;
  loginId: string = "";
  verifyOTP: boolean = false;
  memId: Number = 0;

  errorInDeactivationDate:boolean = true;

  @Input() displayFromReport: string = "NO";
  @Input() memberOrAdminReport: string = "NA";
  @Input() ctclRequestIdReport: string = "";
  @ViewChild('approveRejectComment') approveRejectComment: ElementRef;
  @ViewChild('ctclRequestStatusDetails') ctclRequestStatusDetails: ElementRef;
  @ViewChild(VerifyRequestByOtpComponent) verifyOtpComponent!: VerifyRequestByOtpComponent;
  @ViewChild('nismexamIdPart1') nismexamIdPart1 !: ElementRef;
  @ViewChild('nismexamIdPart2') nismexamIdPart2 !: ElementRef;
  @ViewChild('nismexamIdPart3') nismexamIdPart3 !: ElementRef;
  @ViewChild('nismexamIdPart4') nismexamIdPart4 !: ElementRef;
  @ViewChild('nismexamIdPart5') nismexamIdPart5 !: ElementRef;
  @ViewChild('nismexamIdPart6') nismexamIdPart6 !: ElementRef;

  newItemEvent(event: boolean) {
    this.verifyOTP = event;
    // console.log(this.verifyOTP);
    if (this.verifyOTP === true) {
      $('#ctcldeactivation').modal('hide');
    }
  }

  ctclRequest: FormGroup;
  ctclRequestDisabledFields: any;
  ctclRequestValidationFields: any;
  resetExclusionList: any = {};

  ngOnInit(): void {

    if (this.displayFromReport === 'YES') {
      this.ctclRequestDisabledFields = {
        ctclRqdtlsNeatUserIdDisable: true,
        ctclRqdtlsTerminalIdDisable: true,
        ctclRqdtlsAllotDateDisable: true,
        ctclRqdtlsLoginIdDisable: true,
        ctclRqdtlsPurposeCtclIdDisable: true,
        ctclRqdtlsAllotDateStrDisable: true,
        ctclRqdtlsDisableDateStrDisable: true,
        ctclRqdtlsDisableDateDisable: true,
        ctclLocdtlsOfficeStatusDisable: true,
        ctclRqdtlsFatherFirstNameDisable: true,
        ctclRqdtlsFatherMiddleNameDisable: true,
        ctclRqdtlsFatherLastNameDisable: true,
        ctclRqdtlsPanDisable: true,
        ctclRqdtlsCellContactDisable: true,
        ctclRqdtlsEmailDisable: true,
        ctclRqdtlsRelationshipDisable: true,
        ctclReqStatusDisable: true,
        existingAddressDtls: {
          adFlatNoDisable: true,
          adBuildingNameDisable: true,
          adFloorDisable: true,
          cityNmDisable: true,
          stateNmDisable: true,
          adPincodeDisable: true
        },
        addressDtls: {
          adFlatNoDisable: true,
          adBuildingNameDisable: true,
          adFloorDisable: true,
          adStreetNameDisable: true,
          adLandmarkDisable: true,
          adColonyDisable: true,
          adCityDisable: true,
          adStateDisable: true,
          adPincodeDisable: true,
          cityNameDisable: true,
          stateNameDisable: true
        },
        ctclRqdtlsLocationId: {
          ctclAddStdNoDisable: true,
          ctclAddPhoneNoDisable: true,
          ctclLocdtlsConnModeDisable: true
        },
        ctclRqdtlsExamId: {
          ctclExamDtlsExamOfDisable: true,
          ctclExamDtlsExamTypeDisable: true,
          ctclExamDtlsExamIdDisable: true,
          ctclExamDtlsCertificatetypeDisable: true,
          ctclExamDtlsTitleDisable: true,
          ctclExamDtlsFirstNameDisable: true,
          ctclExamDtlsMiddleNameDisable: true,
          ctclExamDtlsLastNameDisable: true,
          ctclExamDtlsDobStrDisable: true,
          ctclExamDtlsValidDtDisable: true,
          ctclExamDtlsValidDtStrDisable: true
        },
        ctclRqdtlsSubBrokerId: {
          ctclSubAuthDtlsSebiRegNoDisable: true,
          ctclSubAuthDtlsNameDisable: true,
          ctclAuthPerNameDisable: true
        }

      }
    }
    else {
      this.ctclRequestDisabledFields = {
        ctclRqdtlsNeatUserIdDisable: false,
        ctclRqdtlsTerminalIdDisable: false,
        ctclRqdtlsLoginIdDisable: true,
        ctclRqdtlsPurposeCtclIdDisable: true,
        ctclRqdtlsAllotDateDisable: true,
        ctclRqdtlsAllotDateStrDisable: true,
        ctclRqdtlsDisableDateStrDisable: false,
        ctclRqdtlsDisableDateDisable: false,
        ctclLocdtlsOfficeStatusDisable: true,
        ctclRqdtlsFatherFirstNameDisable: true,
        ctclRqdtlsFatherMiddleNameDisable: true,
        ctclRqdtlsFatherLastNameDisable: true,
        ctclRqdtlsPanDisable: true,
        ctclRqdtlsCellContactDisable: true,
        ctclRqdtlsEmailDisable: true,
        ctclRqdtlsRelationshipDisable: true,
        existingAddressDtls: {
          adFlatNoDisable: true,
          adBuildingNameDisable: true,
          adFloorDisable: true,
          cityNmDisable: true,
          stateNmDisable: true,
          adPincodeDisable: true
        },
        addressDtls: {
          adFlatNoDisable: true,
          adBuildingNameDisable: true,
          adFloorDisable: true,
          adStreetNameDisable: true,
          adLandmarkDisable: true,
          adColonyDisable: true,
          adCityDisable: true,
          adStateDisable: true,
          adPincodeDisable: true,
          cityNameDisable: true,
          stateNameDisable: true
        },
        ctclRqdtlsLocationId: {
          ctclAddStdNoDisable: true,
          ctclAddPhoneNoDisable: true,
          ctclLocdtlsConnModeDisable: true
        },
        ctclRqdtlsExamId: {
          ctclExamDtlsExamOfDisable: true,
          ctclExamDtlsExamTypeDisable: true,
          ctclExamDtlsExamIdDisable: true,
          ctclExamDtlsCertificatetypeDisable: true,
          ctclExamDtlsTitleDisable: true,
          ctclExamDtlsFirstNameDisable: true,
          ctclExamDtlsMiddleNameDisable: true,
          ctclExamDtlsLastNameDisable: true,
          ctclExamDtlsDobStrDisable: true,
          ctclExamDtlsValidDtDisable: true,
          ctclExamDtlsValidDtStrDisable: true
        },
        ctclRqdtlsSubBrokerId: {
          ctclSubAuthDtlsSebiRegNoDisable: true,
          ctclSubAuthDtlsNameDisable: true,
          ctclAuthPerNameDisable: true
        }

      }
    }


    this.userDetails = this.userDetailsService.getUserDetails() || {};
    this.loginId = this.userDetails.loginId;
    this.memId = this.ctclService.memId;

    this.ctclRequestValidationFields = {
      ctclRqdtlsTerminalId: [
        { error: "emptyValue", message: "Please Enter 12-digit Terminal Id." },
        { error: "lengthIssue", message: "Terminal Id should be of 12-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],

      ctclRqdtlsLoginId: [
        { error: "maxlength", message: "Login Id is required." },
      ],
      adFlatNo: [
        { error: "maxlength", message: "Flat/Door is required and should not be more than 50-digits." },
      ],
      adBuildingName: [
        { error: "maxlength", message: "Building Name is required and should not be more than 100-digits." },
      ],
      adFloor: [
        { error: "maxlength", message: "Floor should not be more than 50-digits." }
      ],
      adStreetName: [
        { error: "maxlength", message: "Street/Road Name is required and should not be more than 100-digits." }
      ],
      adLandmark: [
        { error: "maxlength", message: "Landmark should not be more than 100-digits." }
      ],
      adColony: [
        { error: "maxlength", message: "Area/Locality/Colony is required and should not be more than 100-digits." }
      ],
      adPincode: [
        { error: "maxlength", message: "Pin Code is required and should not be more than 6-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclAddStdNo: [
        { error: "maxlength", message: "STD Code is required and should not be more than 4-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclAddPhoneNo: [
        { error: "maxlength", message: "Phone No is required and should not be more than 10-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclExamDtlsExamId: [
        { error: "maxlength", message: "Registration No is required and should not be more than 12-digits." },
        { error: "pattern", message: "Only Number allowed." }
      ],
      ctclRqdtlsFatherFirstName: [
        { error: "maxlength", message: "First Name is required and should not be more than 100-digits." }
      ],
      ctclRqdtlsFatherMiddleName: [
        { error: "maxlength", message: "Middle Name is required and should not be more than 100-digits." }
      ],
      ctclRqdtlsFatherLastName: [
        { error: "maxlength", message: "Last Name is required and should not be more than 100-digits." }
      ],
      ctclExamDtlsFirstName: [
        { error: "maxlength", message: "First Name is required and should not be more than 100-digits." }
      ],
      ctclExamDtlsMiddleName: [
        { error: "maxlength", message: "Middle Name is required and should not be more than 70-digits." }
      ],
      ctclExamDtlsLastName: [
        { error: "maxlength", message: "Last Name is required and should not be more than 70-digits." }
      ],
      ctclRqdtlsPan: [
        { error: "maxlength", message: "PAN No. is required and should not be more than 10-digits." },
        { error: "pattern", message: "Please enter valid PAN" }
      ],
      ctclRqdtlsCellContact: [
        { error: "maxlength", message: "Mobile Number is required and should not be more than 10-digits." },
        { error: "pattern", message: "Should be minimum 10-digits and Only Number allowed." }
      ],
      ctclRqdtlsEmail: [
        { error: "maxlength", message: "Email ID is required and should not be more than 50-digits." },
        { error: "email", message: "Please enter valid Email ID." }
      ],
      ctclAuthPerName: [
        { error: "maxlength", message: "Name should not be more than 50-digits." },
      ],
      examIdPartError1: [
        { error: "maxlength", message: "Field should not be more than 5-digits." },
        { error: "pattern", message: "Only text allowed." }
      ],
      examIdPartError2: [{
        error: "maxlength", message: "Field should not be more than 10-digits."
      }],
      examIdPartError3: [{
        error: "maxlength", message: "Field should not be more than 8-digits."
      }],
      examIdPartError4: [{
        error: "maxlength", message: "Field should not be more than 20-digits."
      }],
      examIdPartError5: [{
        error: "maxlength", message: "Field should not be more than 20-digits."
      }],
      examIdPartError6: [{
        error: "maxlength", message: "Field should not be more than 10-digits."
      }],
      ctclRqdtlsRelationship: [
        {
          error: "required", message: "Field is required."
        }
      ]

    }

    this.ctclRequest = this.fb.group({
      ctclReqId: [null],
      ctclReqType: ["2"],
      ctclReqRefNo: [null],
      ctclReqMemId: [this.ctclService.memId],
      ctclReqStatus: new FormControl({ value: "", disabled: this.ctclRequestDisabledFields.ctclReqStatusDisable }),
      ctclReqRemarks: [null],
      ctclReqCrtDt: [null],
      ctclReqUpdDt: [null],
      ctclReqCrtBy: [null],
      ctclReqUpdBy: [null],
      addressDtls: [null],
      fromDate: [null],
      toDate: [null],
      submDateStr: [null],
      reqTypeStr: [null],
      existingAddressDtls: [null],
      memName: [null],
      memCode: [this.ctclService.memCode],
      addressValid: [true],
      ctclRequestDetails: this.fb.group({
        ctclRqdtlsSeq: [null],
        ctclRqdtlsData: [null],
        ctclRqdtlsMarketSegment: [null],
        ctclRqdtlsNeatUserId: ["", Validators.required],
        ctclRqdtlsTerminalId: ["", [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12), Validators.minLength(1)]],
        ctclRqdtlsLoginId: ["", [Validators.required]],
        ctclRqdtlsPurposeCtclId: ["", Validators.required],
        ctclRqdtlsAllotDate: [null],
        ctclRqdtlsMapin: [null],
        ctclRqdtlsPan: ["", [Validators.maxLength(10), Validators.pattern('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$')]],
        ctclRqdtlsRelationship: ["", [Validators.required]],
        ctclRqdtlsFatherFirstName: [null, [Validators.maxLength(100)]],
        ctclRqdtlsFatherMiddleName: [null, [Validators.maxLength(100)]],
        ctclRqdtlsFatherLastName: [null, [Validators.maxLength(100)]],
        ctclRqdtlsCrtDt: [null],
        ctclRqdtlsUpdDt: [null],
        ctclRqdtlsCrtBy: [null],
        ctclRqdtlsUpdBy: [null],
        ctclRqdtlsMemId: [null],
        ctclRqdtlsDisableDate: [null,[Validators.required]],
        ctclRqdtlsLtrRefNo: [null],
        ctclRqdtlsModificationDate: [null],
        ctclRqdtlsCellContact: ["", [Validators.maxLength(10), Validators.pattern('[0-9]{10}')]],
        ctclRqdtlsEmail: ["", [Validators.maxLength(50), Validators.email]],
        ctclRqdtlsAllotDateStr: [""],
        ctclRqdtlsDisableDateStr: ["", Validators.required],
        ctclRqdtlsModificationDateStr: [null],
        ctclRqdtlsMarketSegmentStr: [null],
        fatherFullName: [null],
        ctclRqdtlsDataType: [null],
        existingAddressDtls: this.fb.group({
          adFlatNo: [null],
          adBuildingName: [null],
          adFloor: [null],
          cityNm: [null],
          stateNm: [null],
          adPincode: [null]
        }),
        ctclRqdtlsLocationId: this.fb.group({
          ctclLocdtlsLocid: [null],
          ctclLocdtlsAddPar1: [null],
          ctclLocdtlsAddPar2: [null],
          ctclLocdtlsAddPar3: [null],
          ctclLocdtlsAddCity: [null],
          ctclLocdtlsAddPinCode: [null],
          ctclLocdtlsState: [null],
          ctclAddStdNo: [null, [Validators.maxLength(4), Validators.pattern('[0-9]*')]],
          ctclAddIsdNo: [null],
          ctclAddPhoneNo: [null, [Validators.maxLength(10), Validators.pattern('[0-9]*')]],
          ctclLocdtlsConnMode: ["", [Validators.required]],
          ctclLocdtlsOfficeStatus: ["", [Validators.required]],
          ctclLocdtlsCrtDt: [null],
          ctclLocdtlsUpdDt: [null],
          ctclLocdtlsCrtBy: [null],
          ctclLocdtlsUpdBy: [null],
          addressDtls: this.fb.group({
            adId: [null],
            adCity: [3, [Validators.required]],
            adDistrict: [2],
            adState: [1],
            adFlatNo: [null, [Validators.maxLength(50)]],
            adBuildingName: ["", [Validators.maxLength(100)]],
            adFloor: ["", Validators.maxLength(50)],
            adStreetName: ["", [Validators.maxLength(100)]],
            adLandmark: [null, Validators.maxLength(100)],
            adColony: ["", [Validators.maxLength(100)]],
            adCountry: [null],
            adPincode: ["", [Validators.maxLength(6), Validators.pattern('[0-9]*')]],
            creationDate: [null],
            updationDate: [null],
            createdBy: [null],
            updatedBy: [null],
            isDeleted: [null],
            cityNm: ["GANDHINAGAR", Validators.required],
            distNm: ["GANDHINAGAR"],
            stateNm: ["GUJARAT"],
            cityCd: [3],
            distCd: [2],
            stateCd: [1],
            address: [null],
            cityName: ["GANDHINAGAR"],
            distName: ["GANDHINAGAR"],
            stateName: ["GUJARAT"]
          })
        }),
        ctclRqdtlsExamId: this.fb.group({
          ctclExamDtlsSeqNo: [null],
          ctclExamDtlsExamOf: ["NISM"],
          ctclExamDtlsExamId: [null],
          ctclExamDtlsValidDt: [""],
          ctclExamDtlsTitle: ["MR."],
          ctclExamDtlsFirstName: ["", [Validators.maxLength(100)]],
          ctclExamDtlsMiddleName: ["", [Validators.maxLength(70)]],
          ctclExamDtlsLastName: ["", [Validators.maxLength(70)]],
          ctclExamDtlsDob: [""],
          ctclExamDtlsMarks: [null],
          ctclExamDtlsCrtDt: [null],
          ctclExamDtlsUpdDt: [null],
          ctclExamDtlsCrt_by: [null],
          ctclExamDtlsUpd_by: [null],
          ctclExamDtlsExamType: ["NISM"],
          ctclExamDtlsCertificatetype: [""],
          ctclExamDate: [null],
          ctclExamDtlsDobStr: [null],
          ctclExamDateStr: [""],
          ctclExamDtlsValidDtStr: [null],
          personFullName: [null],
          ctclExamOf: [null]
        }),
        ctclRqdtlsSubBrokerId: this.fb.group({
          ctclSubAuthDtlsSeqNo: [null],
          ctclSubAuthDtlsSebiRegNo: [null],
          ctclSubAuthDtlsName: [null],
          ctclAuthPerName: [null, [Validators.maxLength(50)]]
        }),
      })
    });

    if (this.displayFromReport === 'YES') {
      this.updateEntireFormdata(this.ctclRequestFromReport);
    }
    else {
      this.ctclIdActivationURL = this.ctclService.ctclIdActivationURL;
      this.fetchDeactivationDisplayData();

    }

  }
  declarationCertifiedChanged(event) {
    if (event.target.checked)
      this.declarationCertified = true;
    else
      this.declarationCertified = false;
  }


  deactivationDateCalculation(deactivationDate: string) {
    this.errorInDeactivationDate=true;

    if (!Date.parse(deactivationDate)) {
      this.deactivationDateError = "Deactivation Date is inValid.";
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsDisableDateStr').setValue("");
      this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsDisableDate').setValue("");
    } else {
      this.deactivationDateError = "";
      let today = new Date();
      let deactivationDateValue = new Date(deactivationDate);
      deactivationDateValue.setDate(deactivationDateValue.getDate());
      let differenceInTime = deactivationDateValue.getTime() - today.getTime();

      let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < -1) {
        this.deactivationDateError = "Deactivation Date should not be past Date.";
      }
      else {
        this.errorInDeactivationDate=false;
        this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsDisableDateStr').setValue(this.ctclService.formatDate(deactivationDateValue, "dd-MM-yyyy"));
        this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsDisableDate').setValue(this.ctclService.formatDate(deactivationDateValue, "yyyy-MM-dd"));

      }
    }
  }


  ctclIdDeactivationSubmit() {
    // console.log(this.ctclRequest.value)

    const cityDistictStatete = this.ctclAddressList.find((address) => address.cityId == this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId.addressDtls.adCity').value);
    let ctclRequestToSubmit = this.ctclRequest.value;
    ctclRequestToSubmit.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls.adState = cityDistictStatete.stateId;


    this.ctclActSubmitRequest.ctclRequestModel = ctclRequestToSubmit;
    this.ctclActSubmitRequest.ctclRequestModel.ctclRequestDetails.ctclRqdtlsAllotDateStr = this.reqDtls.get('ctclRqdtlsAllotDateStr').value;
    this.ctclActSubmitRequest.ctclDisplayData = {};
    this.ctclActSubmitRequest.ctclDisplayData = this.ctclDeactivationInitialData;
    //this.ctclActSubmitRequest.ctclDisplayData.ctclExamDtlsCertificatetypeList = this.ctclAddressList;
    this.ctclService.submitctclActivationRequest(this.ctclActSubmitRequest).subscribe((ctclActivationRes) => {
      if (ctclActivationRes.statusCode === 200) {
        this.toastrSrv.success(ctclActivationRes.message + " and Request ID: " + ctclActivationRes.data);
        this.terminalIdList = [];
        this.resetFormData({});
      }
    })
  }




  resetFormData(exclusionList: any) {
    let ctclRqdtlsNeatUserIdPrevValue = this.reqDtls.get('ctclRqdtlsNeatUserId').value
    this.ctclRequest.reset();
    if (Object.keys(exclusionList).length !== 0) {
      if (Object.keys(exclusionList).find(data => data === 'ctclRqdtlsNeatUserId') === 'ctclRqdtlsNeatUserId')
        this.reqDtls.patchValue({ ctclRqdtlsNeatUserId: ctclRqdtlsNeatUserIdPrevValue, ctclRqdtlsTerminalId: '', ctclRqdtlsRelationship: '', ctclRqdtlsPurposeCtclId: '' });
    }
    else {
      this.reqDtls.patchValue({ ctclRqdtlsNeatUserId: '', ctclRqdtlsTerminalId: '', ctclRqdtlsRelationship: '', ctclRqdtlsPurposeCtclId: '' });

    }
    this.reqExam.patchValue({ ctclExamDtlsExamOf: 'NISM', ctclExamDtlsExamType: 'NISM', ctclExamDtlsCertificatetype: '' });
    this.reqLocation.patchValue({ ctclLocdtlsOfficeStatus: '', ctclLocdtlsConnMode: '' });
    this.reqAddrDtls.patchValue({ adCity: '' });
    this.ctclRequest.patchValue({
      ctclReqMemId: this.ctclService.memId,
      memCode: this.ctclService.memCode,
      ctclReqType: '2'
    });

    $('#ctclRqdtlsAllotDate').val('');
    $('#ctclRqdtlsDisableDate').val('');
    $('#ctclExamDtlsDob').val('');
    this.cdref.detectChanges();
    $('.neatSelectpicker').selectpicker('refresh');
    $('.terminalOfficeSelectpicker').selectpicker('refresh');
    $('.citySelectpicker').selectpicker('refresh');
    $('.connModePicker').selectpicker('refresh');
    $('#declarationCertified').prop("checked", false);
    $('.ctclExamDtlsCertificatetypePicker').selectpicker('refresh');
    $('.relationShipSelectpicker').selectpicker('refresh');
    $('.neatPurposeSelectpicker').selectpicker('refresh');
    $('.terminalSelectpicker').selectpicker('refresh');
  }


  fetchDeactivationReportDisplayData() {
    this.ctclService.loadCtclDataForActivationReport(this.ctclRequestIdReport).subscribe((responseData: CtclResponseForDeactivation) => {
      if ((responseData.status === "OK" && responseData.statusCode === 200) || (Object.keys(responseData.data).length > 0)) {
        this.updateEntireFormdata(responseData.data);
      }
    }, (errorData) => {
      alert("Error occured while processing request:: " + errorData.message)
    }, () => {
    });

  }

  updateEntireFormdata(responseData: any) {
    this.ctclReqStatus = responseData.ctclReqStatus;

    this.ctclRequest.patchValue({
      ctclReqId: responseData.ctclReqId,
      ctclReqRefNo: responseData.ctclReqRefNo,
      ctclReqStatus: responseData.ctclReqStatus,
      ctclReqRemarks: responseData.ctclReqRemarks,
      ctclReqCrtDt: responseData.ctclReqCrtDt,
      ctclReqUpdDt: responseData.ctclReqUpdDt,
      ctclReqCrtBy: responseData.ctclReqCrtBy,
      ctclReqUpdBy: responseData.ctclReqUpdBy,
      addressDtls: responseData.addressDtls,
      fromDate: responseData.fromDate,
      toDate: responseData.toDate,
      submDateStr: responseData.submDateStr,
      reqTypeStr: responseData.reqTypeStr,
      existingAddressDtls: responseData.existingAddressDtls,
      memName: responseData.memName,
      addressValid: responseData.addressValid
    })

    this.reqDtls.patchValue({
      ctclRqdtlsSeq: responseData.ctclRequestDetails.ctclRqdtlsSeq,
      ctclRqdtlsData: responseData.ctclRequestDetails.ctclRqdtlsData,
      ctclRqdtlsMarketSegment: responseData.ctclRequestDetails.ctclRqdtlsMarketSegment,
      ctclRqdtlsNeatUserId: responseData.ctclRequestDetails.ctclRqdtlsNeatUserId,
      ctclRqdtlsTerminalId: responseData.ctclRequestDetails.ctclRqdtlsTerminalId,
      ctclRqdtlsLoginId: responseData.ctclRequestDetails.ctclRqdtlsLoginId,
      ctclRqdtlsPurposeCtclId: responseData.ctclRequestDetails.ctclRqdtlsPurposeCtclId,
      //ctclRqdtlsAllotDate: (new Date(responseData.ctclRequestDetails.ctclRqdtlsAllotDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }) + ' ' + new Date(responseData.ctclRequestDetails.ctclRqdtlsAllotDate).toLocaleTimeString(),
      ctclRqdtlsAllotDate: responseData.ctclRequestDetails.ctclRqdtlsAllotDate,
      ctclRqdtlsMapin: responseData.ctclRequestDetails.ctclRqdtlsMapin,
      ctclRqdtlsPan: responseData.ctclRequestDetails.ctclRqdtlsPan,
      ctclRqdtlsRelationship: responseData.ctclRequestDetails.ctclRqdtlsRelationship,
      ctclRqdtlsFatherFirstName: responseData.ctclRequestDetails.ctclRqdtlsFatherFirstName,
      ctclRqdtlsFatherMiddleName: responseData.ctclRequestDetails.ctclRqdtlsFatherMiddleName,
      ctclRqdtlsFatherLastName: responseData.ctclRequestDetails.ctclRqdtlsFatherLastName,
      ctclRqdtlsCrtDt: responseData.ctclRequestDetails.ctclRqdtlsCrtDt,
      ctclRqdtlsUpdDt: responseData.ctclRequestDetails.ctclRqdtlsUpdDt,
      ctclRqdtlsCrtBy: responseData.ctclRequestDetails.ctclRqdtlsCrtBy,
      ctclRqdtlsUpdBy: responseData.ctclRequestDetails.ctclRqdtlsUpdBy,
      ctclRqdtlsMemId: responseData.ctclRequestDetails.ctclRqdtlsMemId,
      ctclRqdtlsDisableDate: responseData.ctclRequestDetails.ctclRqdtlsDisableDate,
      ctclRqdtlsLtrRefNo: responseData.ctclRequestDetails.ctclRqdtlsLtrRefNo,
      ctclRqdtlsModificationDate: responseData.ctclRequestDetails.ctclRqdtlsModificationDate,
      ctclRqdtlsCellContact: responseData.ctclRequestDetails.ctclRqdtlsCellContact,
      ctclRqdtlsEmail: responseData.ctclRequestDetails.ctclRqdtlsEmail,
      // ctclRqdtlsAllotDateStr: responseData.ctclRequestDetails.ctclRqdtlsAllotDateStr,
      // ctclRqdtlsDisableDateStr: responseData.ctclRequestDetails.ctclRqdtlsDisableDateStr,
      // ctclRqdtlsModificationDateStr: responseData.ctclRequestDetails.ctclRqdtlsModificationDateStr,
      ctclRqdtlsAllotDateStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsAllotDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      ctclRqdtlsDisableDateStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsDisableDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      ctclRqdtlsModificationDateStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsModificationDate)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      ctclRqdtlsMarketSegmentStr: responseData.ctclRequestDetails.ctclRqdtlsMarketSegmentStr,
      fatherFullName: responseData.ctclRequestDetails.fatherFullName,
      ctclRqdtlsDataType: responseData.ctclRequestDetails.ctclRqdtlsDataType
    })

    this.reqLocation.patchValue({
      ctclLocdtlsLocid: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsLocid,
      ctclLocdtlsAddPar1: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPar1,
      ctclLocdtlsAddPar2: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPar2,
      ctclLocdtlsAddPar3: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPar3,
      ctclLocdtlsAddCity: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddCity,
      ctclLocdtlsAddPinCode: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsAddPinCode,
      ctclLocdtlsState: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsState,
      ctclAddStdNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddStdNo,
      ctclAddIsdNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddIsdNo,
      ctclAddPhoneNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddPhoneNo,
      ctclLocdtlsConnMode: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsConnMode,
      ctclLocdtlsOfficeStatus: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsOfficeStatus,
      ctclLocdtlsCrtDt: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsCrtDt,
      ctclLocdtlsUpdDt: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsUpdDt,
      ctclLocdtlsCrtBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsCrtBy,
      ctclLocdtlsUpdBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsUpdBy
    })


    this.reqAddrDtls.patchValue({
      adId: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adId,
      adCity: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adCity,
      adDistrict: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adDistrict,
      adState: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adState,
      adFlatNo: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adFlatNo,
      adBuildingName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adBuildingName,
      adFloor: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adFloor,
      adStreetName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adStreetName,
      adLandmark: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adLandmark,
      adColony: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adColony,
      adCountry: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adCountry,
      adPincode: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.adPincode,
      creationDate: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.creationDate,
      updationDate: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.updationDate,
      createdBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.createdBy,
      updatedBy: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.updatedBy,
      isDeleted: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.isDeleted,
      cityNm: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.cityNm,
      distNm: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.distNm,
      stateNm: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.stateNm,
      cityCd: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.cityCd,
      distCd: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.distCd,
      stateCd: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.stateCd,
      address: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.address,
      cityName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.cityName,
      distName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.distName,
      stateName: responseData.ctclRequestDetails.ctclRqdtlsLocationId?.addressDtls?.stateName
    });



    this.reqExam.patchValue({
      ctclExamDtlsSeqNo: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsSeqNo,
      ctclExamDtlsExamOf: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamOf === '' ? "NISM" : responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamOf,
      ctclExamDtlsExamId: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamId,
      ctclExamDtlsValidDt: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt,
      ctclExamDtlsTitle: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsTitle,
      ctclExamDtlsFirstName: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsFirstName,
      ctclExamDtlsMiddleName: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsMiddleName,
      ctclExamDtlsLastName: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsLastName,
      ctclExamDtlsDob: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDob,
      ctclExamDtlsMarks: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsMarks,
      ctclExamDtlsCrtDt: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsCrtDt,
      ctclExamDtlsUpdDt: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsUpdDt,
      ctclExamDtlsCrt_by: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsCrt_by,
      ctclExamDtlsUpd_by: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsUpd_by,
      ctclExamDtlsExamType: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamType === '' ? "NISM" : responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamType,
      ctclExamDtlsCertificatetype: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsCertificatetype,
      ctclExamDate: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDate,
      //ctclExamDtlsDobStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDobStr,
      ctclExamDtlsDobStr: (new Date(responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsDob)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }),
      ctclExamDateStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDateStr,
      //ctclExamDtlsValidDtStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDtStr,
      ctclExamDtlsValidDtStr: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt !== null?(new Date(responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsValidDt)).toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' }):'',
      personFullName: responseData.ctclRequestDetails.ctclRqdtlsExamId.personFullName,
      ctclExamOf: responseData.ctclRequestDetails.ctclRqdtlsExamId.ctclExamOf
    });

    this.reqSubBroker.patchValue({
      ctclSubAuthDtlsSeqNo: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSeqNo,
      ctclSubAuthDtlsSebiRegNo: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSebiRegNo,
      ctclSubAuthDtlsName: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsName,
      ctclAuthPerName: responseData.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclAuthPerName

    })

  }
  terminalIdChange() {

    if (this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsTerminalId').value === '') {
      //this.terminalIdList = [];
      this.resetExclusionList.ctclRqdtlsNeatUserId = true;
      this.resetFormData(this.resetExclusionList);
      // On Selecting blank Value no API service will be called
    }
    else {
      this.ctclService.loadCtclDataForDeactivation(this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value, this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsTerminalId').value).subscribe((responseData: CtclResponseForDeactivation) => {
        if (responseData.status === "OK" && responseData.statusCode === 200) {
          this.updateEntireFormdata(responseData.data);
        }
      }, (errorData) => {
        alert("Error occured while processing request:: " + errorData.message)
      }, () => {
      });

    }
  }

  neatUserIdChange() {

    if (this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value === '') {
      // On Selecting blank Value no API service will be called
      this.terminalIdList = [];
      this.resetFormData({});
      this.cdref.detectChanges();
      $('.terminalSelectpicker').selectpicker('refresh');
    }
    else {

      this.ctclService.loadCtclNeatAndTerminalData(this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsNeatUserId').value).subscribe((responseData: NeatAndterminalResponse) => {
        if (responseData.status === "OK" && responseData.statusCode === 200) {
          this.terminalIdList = responseData.data.TerminalIds;
          this.reqDtls.patchValue({
            ctclRqdtlsTerminalId: ""
          });
          this.cdref.detectChanges();
          $('.terminalSelectpicker').selectpicker('refresh');
        }

      }, (errorData) => {
        alert("Error occured while processing request:: " + errorData.message)
      }, () => {
      });

    }

  }


  handleError() {
    // console.log("handle error");
  }

  fetchDeactivationDisplayData() {

    this.ctclService.loadCtclDeactivationData().subscribe((responseData: CtclIdDeactivationResponse) => {
      if (responseData.status === "OK" && responseData.statusCode === 200) {
        this.populateCtclDeactivationPage(responseData);
      }
    });
  }

  populateCtclDeactivationPage(ctclResponseData: CtclIdDeactivationResponse) {

    this.ctclDeactivationInitialData = {};
    this.ctclDeactivationInitialData.ctclAddressList = ctclResponseData.data.ctclAddressList;
    this.ctclAddressList = ctclResponseData.data.ctclAddressList;
    this.ctclDeactivationInitialData.ctclConnModeList = ctclResponseData.data.ctclConnModeList;
    this.ctclDeactivationInitialData.ctclExamDtlsCertificatetypeList = ctclResponseData.data.ctclExamDtlsCertificatetypeList;
    this.ctclDeactivationInitialData.ctclOfcStatusList = ctclResponseData.data.ctclOfcStatusList;
    this.ctclDeactivationInitialData.ctclRelationList = ctclResponseData.data.ctclRelationList;
    this.ctclDeactivationInitialData.purposeCtclList = ctclResponseData.data.purposeCtclList;
    this.ctclDeactivationInitialData.tempList = ctclResponseData.data.tempList;
    this.ctclDeactivationInitialData.titleList = ctclResponseData.data.titleList;
    this.neatUserIdList = ctclResponseData.data.listofNeat;

    this.cdref.detectChanges();
    $('.neatSelectpicker').selectpicker('refresh');
  }


  verifyFormEnabled() {
    // console.log(this.ctclRequest);
  }

  get existingAddr() {
    return this.ctclRequest.get('ctclRequestDetails.existingAddressDtls');
  }

  get reqLocation() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId');
  }

  get reqAddrDtls() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsLocationId.addressDtls');
  }

  get reqExam() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsExamId');
  }

  get reqSubBroker() {
    return this.ctclRequest.get('ctclRequestDetails.ctclRqdtlsSubBrokerId');
  }

  get reqDtls() {
    return this.ctclRequest.get('ctclRequestDetails');
  }

  ctclApproveRejAction(actionType: string) {

    if (actionType === 'Back') {
      if (this.memberOrAdminReport === 'MEMBER') {
        this.router.navigate(['eTrade/eMemberReportingPreTrade/displayCtclIdReport'])
      }
      else if (this.memberOrAdminReport === 'ADMIN') {
        this.router.navigate(['tradeAdmin/eMemberReportingTradeA/displayCtclIdReport'])
      }
    }
    else {
      this.ctclApprovalRejectionReq = {};
      this.ctclApprovalRejectionReq.remarks = this.approveRejectComment.nativeElement.value;
      this.ctclApprovalRejectionReq.status = actionType;
      this.ctclApprovalRejectionReq.refNo = this.ctclRequestIdReport.replace('_', '\\').replace('_', '\\');

      this.ctclService.approvalRejectionOfCtcl(this.ctclApprovalRejectionReq).subscribe(responseData => {
        if (responseData.statusCode === 200) {
          this.ctclRequestStatusDetails.nativeElement.value = actionType;
          this.ctclReqStatus = actionType;
          this.reqDtls.patchValue({ ctclReqStatus: actionType, ctclReqRemarks: this.approveRejectComment.nativeElement.value });
          this.toastrSrv.success("Request " + this.ctclApprovalRejectionReq.refNo + " " + actionType + " successfully");
        }
      })
      // console.log(this.approveRejectComment);
      // console.log("Button Clicked::" + actionType);
    }
  }

  verifyOtpComponentCall() {
    this.verifyOtpComponent.initializeComponent("CTCLDeActivate", 4, this.loginId, this.memId);
  }

}