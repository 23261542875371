import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { catchError } from 'rxjs/operators';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
import { config } from 'src/app/valueObject/config';


declare var $: any
@Component({
  selector: 'app-cyber-security-enable-users',
  templateUrl: './cyber-security-enable-users.component.html',
  styleUrls: ['./cyber-security-enable-users.component.css']
})
export class CyberSecurityEnableUsersComponent implements OnInit {

  searchForm: FormGroup;
  cyberSecurityEnabledForm: FormGroup;
  fileUploadForm: FormGroup;
  filedownload: FormGroup;
  config: any = config;
  message: any;
  currentDate: any;
  data: any = {};
  deleteRow: any;
  dtOptions: any = {};
  requestList: any = [];
  cyberEnabledData: any = [];
  quarterDetails: any = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  selectedYear: any;
  selectedQuarter: any;
  selectedStartDate: any;
  selectedEndDate: any;
  selectedRecord: any;
  quarterList: any;
  currentYear: any;
  currentQuarter: any;
  selectedDeleteRow: any;
  isDisabled = false;
  file: File;
  fileError: boolean;
  notifySelectedRow: any;
  buttonEnable: boolean;
  constructor(
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private cyberSecurityServiceService: EnitComplianceServiceService,
    private toastr: ToastrService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private datepipe: DatePipe,
  ) { }

  get f() {
    return this.cyberSecurityEnabledForm.controls;
  }
  get g() {
    return this.searchForm.controls;
  }
  get k() {
    return this.fileUploadForm.controls;
  }

  get d() {
    return this.filedownload.controls;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Cyber Security Enable')
    this.dtOptions = {
      responsive: true,
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      buttons:
        [
          {
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          },
          {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          }, {
            extend: 'pdf',
            orientation: 'landscape',
            pageSize: 'A4',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          },
        ]
    }
    this.getQuarterData();

    // this.currentDate = this.datepipe.transform("19-01-10",'dd-MM-yy');//this.datepipe.transform(new Date, "dd-MM-yy");
    this.cyberSecurityEnabledForm = this.fb.group({
      cyberSecQrtrId: [null, Validators.required],
      cyberSecQrtrMemId: [null, Validators.required],
      qrtrStartDate: [null, Validators.required],
      qrtrEndDate: [null, Validators.required],
      isMemDeleted: [null, Validators.required],
      memberName: [null, Validators.required],
      memberEmail: [null, Validators.required],

    })
    this.searchForm = this.fb.group({
      year: [null, Validators.required],
      quarter: [null, Validators.required],
      // qrtrStartDate: [null],
      // qrtrEndDate: [null],
    })
    this.fileUploadForm = this.fb.group({
      file: ['', Validators.required],
    })
    this.filedownload = this.fb.group({
      download: ['null', Validators.required]
    });
    // this.getAllCyberSecurityEnabledMembers();
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }
  setdatatable(){
    $("#cyberSecurityEnabledTable").DataTable().destroy();
   setTimeout(() => {
    $("#cyberSecurityEnabledTable").DataTable({
      responsive: true,
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      buttons:
        [
          {
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          },
          {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          }, {
            extend: 'pdf',
            orientation: 'landscape',
            pageSize: 'A4',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          },
        ],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#eligibleAIMLTable .column-search input').val('');
            $('#eligibleAIMLTable .column-search select').val('.*');
            $('#eligibleAIMLTable .column-search').on('keyup change', 'input', function () {
              $('#eligibleAIMLTable').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#eligibleAIMLTable .column-search').on('keyup change', 'select', function () {
              $('#eligibleAIMLTable').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
    })
    this.cdref.detectChanges();
   }, 1);
  }

  clearSearch() {
    this.setdatatable();
  }
  getAllCyberSecurityEnabledMembers() {
    $("#cyberSecurityEnabledTable").DataTable().destroy();
    var requestObject;
    if (this.selectedRecord == null) {
      requestObject = {
        "qrtrStartDate": 0,
        "qrtrEndDate": 0
      }
    } else {

      requestObject = {
        "qrtrStartDate": this.datepipe.transform(this.selectedRecord.qrtrStartDate, 'yyyy-MM-dd'),
        "qrtrEndDate": this.datepipe.transform(this.selectedRecord.qrtrEndDate, 'yyyy-MM-dd')
      }
    }
    this.cyberSecurityServiceService.getAllCyberSecurityEnabledMembers(requestObject).pipe(
      catchError((error: any) => {
        this.cyberEnabledData = [];
        this.setdatatable();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.cyberEnabledData = response.data[0];
        this.setdatatable();
      } else {
        this.cyberEnabledData = [];
        this.setdatatable();
        this.toastr.error(response.message);
      }
    });
  }

  getQuarterData() {
    this.cyberSecurityServiceService.getQuarterDetails().pipe(
      catchError((error: any) => {
        this.quarterDetails = [];
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        //$('.selectpicker').selectpicker('refresh');
        this.quarterDetails = response.data;
        this.quarterList = this.quarterDetails.quarterList;
        this.selectedQuarter = this.quarterDetails.currentQuarter.quarter;
        this.selectedYear = this.quarterDetails.currentQuarter.year;
        this.g.year.setValue(this.selectedYear);
        this.g.quarter.setValue(this.selectedQuarter);
        this.currentYear = this.quarterDetails.currentQuarter.year;
        this.currentQuarter = this.quarterDetails.currentQuarter.quarter
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
        if (this.selectedQuarter == null) {
          this.changeOnQrtrNotAvailable();
          this.toastr.warning("Current Quarter data not available");
        } else {
          this.change();
          this.currentYear = this.selectedYear
          this.currentQuarter = this.selectedQuarter;
        }

        this.getAllCyberSecurityEnabledMembers();
      }
      else {
        this.quarterDetails = [];
        this.toastr.error(response.message);
      }
    });
  }
  ngOnDestroy() {
    this.dtTrigger?.unsubscribe();
  }
  onReset() {
    this.searchForm.reset();
    // this.getQuarterData();
    $('.selectpicker').selectpicker('refresh');
  }
  onSubmit() {
    //window.location.reload();
    this.getAllCyberSecurityEnabledMembers();
  }
  deleteCyberSecurityEnabledUser() {
    let cyberReqObject = this.cyberEnabledData.find(data => data.cyberSecQrtrId == this.deleteRow);
    let requestObject = {
      "cyberSecQrtrId": cyberReqObject.cyberSecQrtrId,
      "cyberSecQrtrMemId": cyberReqObject.cyberSecQrtrMemId,
      "qrtrStartDate": cyberReqObject.qrtrStartDate,
      "qrtrEndDate": cyberReqObject.qrtrEndDate,
      "isMemDeleted": cyberReqObject.isMemDeleted,
      "memberName": cyberReqObject.memberName,
      "memberEmail": cyberReqObject.memberEmail,
    }
    this.cyberSecurityServiceService.removeCyberSecurityEnabledMember(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.message = this.selectedDeleteRow + " is deleted from current quarter list"
          this.toastr.success(this.message);
          this.getAllCyberSecurityEnabledMembers();
        }
        else {
          this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
        }
      })
  }

  deleteFromEnabledList(quarterId: string) {
    this.deleteRow = quarterId;
    this.selectedDeleteRow = this.cyberEnabledData.find(data => data.cyberSecQrtrId == this.deleteRow).cyberSecQrtrMemId * 1 + 10000;

  }

  change() {

    if ((this.g.year.value == this.currentYear) && (this.g.quarter.value == this.currentQuarter)) {
      this.buttonEnable = true;
    }
    else {
      this.buttonEnable = false
    }
    this.selectedRecord = this.quarterList.find(data => (data.year == this.g.year.value && data.quarter == this.g.quarter.value));
    this.selectedYear = this.selectedRecord.year;
    this.selectedQuarter = this.selectedRecord.quarter;
  }
  changeOnQrtrNotAvailable() {
    this.selectedRecord = null;
    this.selectedYear = 0;
    this.selectedQuarter = "Q1";
  }

  changeYear() {
    this.selectedRecord = this.quarterList.find(data => (data.year == this.g.year.value && data.quarter == this.g.quarter.value));

  }
  notifyCurrentQuarterUser() {
    if (this.cyberEnabledData == null) {
      this.toastr.warning("No members to notify")
    }
    else {
      this.selectedRecord = this.quarterList.find(data => (data.year == this.g.year.value && data.quarter == this.g.quarter.value));
      this.selectedStartDate = this.datepipe.transform(this.selectedRecord.qrtrStartDate, 'yyyy-MM-dd');
      this.selectedEndDate = this.datepipe.transform(this.selectedRecord.qrtrEndDate, 'yyyy-MM-dd');

      this.toastr.success("This will notify to all eligible members");
      var requestObject = {
        "year": this.g.year.value,
        "quarter": this.g.quarter.value,
        "qrtrStartDate": this.selectedStartDate,
        "qrtrEndDate": this.selectedEndDate,
      }
      this.cyberSecurityServiceService.notifyAllCyberSecurityUser(requestObject).pipe(
        catchError((error: any) => {
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {

      });
      this.getAllCyberSecurityEnabledMembers();
    }
  }

  selectIdnotifySelectedUser(quarterId: string) {
    this.notifySelectedRow = this.cyberEnabledData.find(data => data.cyberSecQrtrId == quarterId);

  }
  notifySelectedUser() {

    let notifyRequest = {
      "cyberSecQrtrId": this.notifySelectedRow.cyberSecQrtrId,
      "cyberSecQrtrMemId": this.notifySelectedRow.cyberSecQrtrMemId,
      "memberName": this.notifySelectedRow.memberName,
      "memberEmail": this.notifySelectedRow.memberEmail,
      "qrtrEndDate": this.notifySelectedRow.qrtrEndDate,
      "qrtrStartDate": this.notifySelectedRow.qrtrStartDate
    }

    this.cyberSecurityServiceService.notifyCyberSecurityUser(notifyRequest).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.message = "Notification is sent to " + (10000 + 1 * this.notifySelectedRow.cyberSecQrtrMemId);
          this.toastr.success(this.message);
        }
        else {
          this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
        }
      }
    )
    this.getAllCyberSecurityEnabledMembers();

  }

  //for File upload
  onChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      if (this.file.type == 'application/vnd.ms-excel') {
        this.fileError = false;
      }
      else {
        this.fileError = true;
      }
    }

  }
  removeFile() {
    this.fileUploadForm.reset();
    this.file = null;
  }
  onFileSubmit() {
    this.spinner.show();
    this.cyberSecurityServiceService.cyberSecurityFileUpload(this.file).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          this.removeFile();
          this.getQuarterData();
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.removeFile();
        this.toastr.success("File uploaded successfully !!");
        this.getQuarterData();
      } else {
        this.removeFile();
        this.toastr.error(response.message);
        this.getQuarterData();
      }
    })
  }

  downloadFile() {
   // this.spinner.show();
    let fileName ="cyberSecurity";
    this.cyberSecurityServiceService.getFileDownloadable().pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
     // this.spinner.hide();
      if (response.statusCode == 200) {
        let dnldFile = response.data;
        let contentType = 'application/vnd.ms-excel';
        let blob = base64StringToBlob(dnldFile, contentType);
        saveAs(blob,fileName, {
          autoBOM: true
        });
        this.toastr.success("File downloaded Succesfully");
      } else {
        this.toastr.error("File download Failed");
      }
    })
  }
}
