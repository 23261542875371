import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from 'src/app/service/access.guard';
import { MenuGuard } from 'src/app/service/menu.guard';
import { SubmenuGuard } from 'src/app/service/submenu.guard';
import { LesOperationScreenComponent } from './les-operation-screen/les-operation-screen.component'

const routes: Routes = [

  { path: 'lesOperation',  component : LesOperationScreenComponent  }
  //canDeactivate: [SubmenuGuard], ,

 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MenuGuard, SubmenuGuard, AccessGuard]
})

export class LesRoutingModule { }
