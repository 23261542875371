import { Injectable } from '@angular/core';
import { User } from '../valueObject/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users: User[]=[];

  constructor() { }

  addUsers(userlist:User[]){
    this.users=userlist;
  }

  getAllUsers(){
    return this.users;
  }

  getUserById(id:number){
    return this.users.find(user=> user.userId==id);
  }

  getUserByLoginId(id:string){
    return this.users.find(user=> user.userLoginId==id);
  }
  
}
