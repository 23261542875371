import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
@Component({
  selector: 'app-neat-user-validity-updation-modal',
  templateUrl: './neat-user-validity-updation-modal.component.html',
  styleUrls: ['./neat-user-validity-updation-modal.component.css']
})
export class NeatUserValidityUpdationModalComponent implements OnInit {
  validityUpdationAdmin: FormGroup;
  reqObj: any={};
  submitted = false;
  config: any = 'config';
  message: string;
  menucode: string='';
  access:any={};
  user:any={};
  entityType: any;
  constructor(
    private toastr: ToastrService,
    private EnitService: EnitService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private formbuilder : FormBuilder,
    private userDetailsService: UserDetailsService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['refNo']) {
        this.reqObj = enitTradeService.getNewNeatRequestData(params['refNo']);
        if(this.reqObj.cusDlrNm==null){
          this.reqObj.space=" ";
           this.reqObj.fullname=this.reqObj.firstName.concat(  this.reqObj.space).concat(  this.reqObj.middleName).concat(  this.reqObj.space).concat(  this.reqObj.lastName);
           
          //  this.space=" ";
          //  this.fullname=this.reqObj.firstName.concat(  this.space).concat(  this.reqObj.middleName).concat(  this.space).concat(  this.reqObj.lastName);
         }else{
           this.reqObj.fullname=this.reqObj.cusDlrNm;
         }
     }
    });
  }
  back(){
    if(this.entityType=='ENIT'){
      this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage']);
    }
    else if(this.entityType!='ENIT'){
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    }
  }
  ngOnInit(): void {
    this.titleService.setTitle('Neat User Validity Updation');
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.validityUpdationAdmin = this.formbuilder.group({
      memId: [10000+this.reqObj.memId],
      memName: [this.reqObj.memName],
      userId: [this.reqObj.dlr_id],
      firstName: [this.reqObj.fullname],
      tapIp: [this.reqObj.cusTapIp],
      tapId: [this.reqObj.cusTidId],
      existingCertificationDetails: [this.reqObj.examOf],
      oldRegiNo: [this.reqObj.examId],
      validityDate: [this.reqObj.dlex_valid_dt],
      percentage: [this.reqObj.dlex_marks],
      newCertificationDetails: [this.reqObj.examOf],
      newRegiNo: [this.reqObj.examId],
      newValidityDate: [this.reqObj.dlex_valid_dt],
      newPercentage: [this.reqObj.dlex_marks],
      remark: [this.reqObj.cusRemarks],
      CurrentStatus: [this.reqObj.cusReqStatus],
      UpdatedStatus:[null]
    });
  }
  get f() {
    return this.validityUpdationAdmin.controls;
  }
  onSubmit() 
  {
    this.submitted = true;
    let requestObject = {
			"memId":this.f.memId.value-10000,
      "cusRemarks": this.f.remark.value,
      "cusRefNo":this.reqObj.refNo,
      "cusStatus":this.f.UpdatedStatus.value,
      "memName":this.f.memName.value,
      "cusDlrUsrId":this.reqObj.dlr_id
           
    };
    this.EnitService.ApproveUserIdrequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.message = 'Request Updated Successfully';
        this.toastr.success(this.message);
        this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
      } else {
        this.submitted = false;
      }
    })
  }
}
