import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UsermanagementRoutingModule } from './usermanagement-routing.module';
import { UserlistComponent } from './userlist/userlist.component';
import { DataTablesModule } from 'angular-datatables';
import { AddUserComponent } from './add-user/add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UserlistComponent, AddUserComponent],


  imports: [
    CommonModule,
    UsermanagementRoutingModule,
    DataTablesModule,
    FormsModule, ReactiveFormsModule,
    NgxSpinnerModule
  ]
})
export class UsermanagementModule { }
