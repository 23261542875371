import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let options = {};
    if (request.url.includes('security/validateUser')) {
      options = {
        reportProgress: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        })
      };
    }
    if (request.url.includes('/uploadFile') || request.url.includes('/compliance/cyber/uploadCyberSecurity') || request.url.includes('/extranet/uploadExtranetFile')) {
      options = {
        reportProgress: true,
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
      };
    }
    else {
      options = {
        reportProgress: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        })
      };
    }



    let updatedRequest = request.clone(options);


    return next.handle(updatedRequest);
  }
}
