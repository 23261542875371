<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Password Reset/Unlock Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="/eTrade/eUnlockuser/anewRequestPwdReset" aria-current="page">Password
                            Reset/Unlock Request </a></li>
                </ul>
            </div>
        </div>
    </div>
    <form [formGroup]="requestPwdReset" >
            <div class="card card-body col-lg-12 mt-3 colorborder">
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label>Member Code </label>
                        <input type="text" class="form-control" readonly formControlName="memCode">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Member Name </label>
                        <input type="text" class="form-control" readonly formControlName="memName">
                    </div>
                      <div class="col-lg-3 form-group">
                        <label>User Type <span class="red">*</span></label>
                        <select class="form-control selectpicker" formControlName="userType" title="Select User Type"
                           data-style="btn-info" (change)="SelectUserType()">
                            <option  value="CM"> Corporate Manger </option>
                            <option  value="DLR"> Dealer</option>
                        </select>
                        <p *ngIf="f.userType.invalid && f.userType.touched" class="error nocase">
                           User type required
                        </p>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Action <span class="red">*</span></label>
                        <select class="form-control selectpicker" formControlName="actionType"
                            title="Select Action" data-style="btn-info" (change)="changeAction()">
                            <option  value="R"> Request for Password Reset </option>
                            <option  value="U"> Request for Unlocking</option>
                        </select>
                        <p *ngIf="f.actionType.invalid && f.actionType.touched" class="error nocase">
                           Action required
                        </p>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>User Id <span class="red">*</span></label>
                        <select class="form-control selectpicker" formControlName="cusDlrId" data-style="btn-info"
                            title="Select User ID"  data-live-search="true">
                            <option *ngFor="let data of cusDlrId" value="{{data}}">{{data}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="f.actionType.value=='U'"   class="col-lg-3 form-group">
                        <label>Reason for Unlock <span class="red">*</span></label>
                        <select class="form-control " formControlName="reasonForUnlock"
                            title="Select Reason" data-style="btn-info">
                            <option  value="Technical Excengency"> Technical Excengency</option>
                            <option  value="Non Technical Excengency">Non Technical Excengency</option>
                            <option  value="Login Fron Disaster Recovery (DR) site"> Login Fron Disaster Recovery (DR) site</option>
                            <option  value="Testing Fron Disaster Recovery (DR) site"> Testing Fron Disaster Recovery (DR) site</option>
                        </select>
                        <p *ngIf="f.reasonForUnlock.invalid && f.reasonForUnlock.touched" class="error nocase">
                            Reason for unlock required
                        </p>
                    </div>   
                    <div class="col-lg-3 form-group">
                        <label>Requester Email address</label>
                        <input type="email" class="form-control" formControlName="EmailType">
                        <div *ngIf="f.EmailType.invalid && f.EmailType.touched">
                            <!-- <p *ngIf="f.EmailType.errors.required"  class="error nocase">
                                Please enter email ID
                            </p> -->
                            <p *ngIf="f.EmailType.errors.email"  class="error nocase">
                                Please enter valid email ID
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Requester Mobile number</label>
                        <input type="number" class="form-control" formControlName="mobileNo">
                        <div *ngIf="f.mobileNo.invalid && f.mobileNo.touched">

                            <!-- <div *ngIf="f.mobileNo.errors.required"  class="error nocase">Mobile Number required</div> -->
                            <!-- <div
                                *ngIf="f.mobileNo.errors.pattern || f.mobileNo.errors.maxlength || f.mobileNo.errors.minlength"  class="error nocase">
                                Mobile Number Should Be 10 Digit</div> -->
                        </div>
                    </div>
                </div>
                <div class="row justify-content-start mb-5">
                    <div class="col-lg-8">
                        <div class="row justify-content-start">
                            <div class="col-lg-2">
                                <button class="btn btn-primary full-width"  [disabled]="verifyOTP!=true || submitted"  type="submit" (click)="onSubmit()">Submit</button>
                            </div>
                            <div class="col-lg-2">
                                <button type="button" class="btn btn-cancel full-width"  (click)="reset()">Reset</button>
                            </div>
                            <div class="col-lg-2">
                                <button class="btn  btn-primary full-width" [disabled]="requestPwdReset.invalid" data-toggle="modal"  data-target="#cashdeposite">Certify</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </form>
</div>
<div class="modal fade" id="cashdeposite" tabindex="-1" role="dialog" aria-labelledby="cashdepositeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
              </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verfiy-request-by-otp  [module]="module" [reqtype]="reqtype" [loginId]="loginId" [memId]="memId" (newItemEvent)="newItemEvent($event)"></app-verfiy-request-by-otp>
        </div>
    </div>
</div>