import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var $: any

@Component({
  selector: 'app-et-newneat-request-process-page',
  templateUrl: './et-newneat-request-process-page.component.html',
  styleUrls: ['./et-newneat-request-process-page.component.css'],
})
export class EtNewneatRequestProcessPageComponent implements OnInit {
  reqObj: any={};
  newNeatRequest: FormGroup;
  submitted = false;
  status: boolean = false;
  myDataObj: any = {};
  submitFlag: number = 0;
  show : boolean ;
  message: string;
  memCode: String;
  memId: String;
  user:any={};
  access:any={};
  entityType:any;
  menucode: string='';
  config: any = 'config';

  @ViewChild('selectpicker') selectPicker: ElementRef;
  space: any;
  fullname: any;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private userDetailsService: UserDetailsService,
    private titleService: Title,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['refNo']) {
        this.reqObj = enitTradeService.getNewNeatRequestData(params['refNo']);
      }
    });
  }
 
  back(){
    if(this.entityType=='ENIT'){
      this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage']);
    }
    else if(this.entityType!='ENIT'){
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    }
  }
  ngOnInit(): void {
  
    this.titleService.setTitle('Process New Neat Request');
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.newNeatRequest = this.formbuilder.group({
      memId: [10000+this.reqObj.memId],
      memName: [this.reqObj.memName],
      sebiRegNo: [this.reqObj.sebiRegNo],
      reqId: [this.reqObj.cusReqIdBigD],
      assignedDlrId:[this.reqObj.dlr_id],
      assignedTapId:[this.reqObj.cusTidId],
      address:[this.reqObj.addr],
      tapIp:[this.reqObj.cusTapIp],
      noOfExistingTerminals: [this.reqObj.noOfTerminals],
      address1:[this.reqObj.addr],
      certifiedBy:[this.reqObj.examOf],
      title:[this.reqObj.title],
      firstName:[this.reqObj.firstName],
      middleName:[this.reqObj.middleName],
      lastName:[this.reqObj.lastName],
      registrationNo:[this.reqObj.examId],
      dob: [this.reqObj.cusDlrDOBStr],
      validityDate:[this.reqObj.dlex_valid_dt],
      panNo:[this.reqObj.cusDlrPan],
      percentage:[this.reqObj.dlex_marks],
      mobileNo: [this.reqObj.cusReqPhoneNo],
      email: [this.reqObj.cusEmail],
      fatherHusbandname: [this.reqObj.dlr_father_name],
      qualification: [this.reqObj.dlr_qualification],
      relationshipWithUser:[this.reqObj.dlr_mem_relation],
      remarks: [''],
      rem:[this.reqObj.cusRemarks],
      updatedStatus:['',Validators.required],
      dealerId:['',[Validators.required]],
      tapId:[this.reqObj.cusTidId, [Validators.required]],
      nnfCtclFacility:[this.reqObj.cusReqCtclFlag],

      //new fields:
      branchId:[this.reqObj.branchId],
      userType:[this.reqObj.dlr_user_type],
      userRemarks:[this.reqObj.rationale]


    });

    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  onStatusChange(){
    if(this.f.updatedStatus.value == 'APPR'){
      this.f.dealerId.setValidators([Validators.required, Validators.max(9999)]);
      this.f.dealerId.updateValueAndValidity();
      // this.f.tapId.setValidators([Validators.required, Validators.max(99999)]);
      // this.f.tapId.updateValueAndValidity();
    }
    else{
      this.f.dealerId.clearValidators();
      this.f.dealerId.updateValueAndValidity();
      // this.f.tapId.clearValidators();
      // this.f.tapId.updateValueAndValidity();
      this.f.remarks.setValue('');
      this.f.dealerId.setValue('');
      // this.f.tapId.setValue('');
    }
  }

  
  updateDealer(){
    this.enitTradeService.updateDlrNtework().pipe(
      catchError((error: any) => {
        // this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        // this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        console.log(response)
      } else {
       
        console.log(response)
      }
      
    })
  
  }

  
 //start
 submit() {
  
  this.space=" ";
  this.fullname=this.f.firstName.value.concat(  this.space).concat(  this.f.middleName.value).concat(  this.space).concat(  this.f.lastName.value);
  
  let requestObject = {
      "reqMemId": (this.f.memId.value-10000).toString(),
      "reqRefNo": this.reqObj.refNo,
      "reqStatus":this.f.updatedStatus.value,
      "reqSeqNo": {
          "dlrSeqNo": this.reqObj.cusReqSeqNoBd,
          "dlrId": this.f.assignedDlrId.value,
          "dlrNm": this.fullname,     
      },
      "reqRemarks": this.f.remarks.value,
      "dealerId":  this.f.dealerId.value,
      "tapId": this.f.tapId.value,
      "dlrUsrType":this.f.userType.value,
      "branchId":this.f.branchId.value,
   };
 

  this.enitTradeService.submitAdminUserIdRequest(requestObject).pipe(
    catchError((error: any) => {
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      this.submitted = false;
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
  ).subscribe((response: any) => {
    if (response.statusCode == 200) {
      this.message = 'Request Updated Successfully';
      this.toastr.success(this.message);
      this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage']);
    } else {
      this.submitted = false;
    }
    
  })
 }

  get f() {
    return this.newNeatRequest.controls;
  }
  submitData(dataObject: any) {
    if (this.submitFlag != 0 && this.reqObj.cusReqStatus == 'SUB') {
      this.submitFlag = 1;
      this.sendData(this.myDataObj);
    }
  }
  sendData(dataObject: any) {
    return this.enitTradeService.submitNewNeatReport(dataObject);
  }

  reset(){
    this.f.remarks.setValue('');
    this.f.dealerId.setValue('');
    //this.f.tapId.setValue('');
    this.f.updatedStatus.setValue(null)
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');

  }

  // submit() {
  //   if (this.reqObj.cusReqStatus != 'SUB') {
  //     //set request status to submitted
  //     this.reqObj.cusReqStatus='SUB'
  //   }
  // }  
}
