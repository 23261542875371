<form [formGroup]='otpForm'>

    <div id="slide-otp" class="card card-body otp">
        <div class="alert alert-success nocase" role="alert" *ngIf="!!message">
            {{message}} 
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-10 form-group">
                <label>Enter OTP</label>
                <input type="text" class="form-control" formControlName="otp" autocomplete="off">
                
                <!-- <p *ngIf="otpForm.get('otp').invalid && otpForm.get('otp').touched" class="error nocase">
                    Otp is required
                </p> -->
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10 form-group">
                <div class="row">
                <div class="col-lg-6 ">
                    <button class="btn btn-primary full-width" type="button" (click)="generateRequestVerficationOtp()" [disabled]="resendDisable"> {{resendDisable?(timeVal| minuteSeconds):''}} Send OTP</button>
                    <!-- <button class="btn btn-secondary full-width  " type="button" [disabled]="resendDisable"
                    (click)="generateRequestVerficationOtp()">{{resendDisable?(timeVal| minuteSeconds):''}} Resend   
                    </button> -->
                </div>
                <div class="col-lg-6 ">
                    <button class="btn btn-primary  full-width" type="button" (click)="onSubmit()">Submit</button>
                </div>
            </div>
            </div>
    
        </div>
        
        <div class="row justify-content-center">
            <!-- <div class ="">
                <button class="btn btn-secondary full-width  " type="button" [disabled]="resendDisable"
                    (click)="generateRequestVerficationOtp()">{{resendDisable?(timeVal| minuteSeconds):''}} Resend   
                    </button>
            </div> -->
        </div>
    </div>

</form>