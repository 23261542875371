import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CelService } from 'src/app/service/cel.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { CustomValidators } from 'src/app/shared/customValidators';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-branch-alert-setup',
  templateUrl: './branch-alert-setup.component.html',
  styleUrls: ['./branch-alert-setup.component.css']
})
export class BranchAlertSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  user: any = {};
  access: any = {};
  users: any = [];
  branchData: any = [];
  selectedBranch: any = {};
  alertForm: FormGroup;
  userControlId: string = '';
  menucode: string = "alertSetup";
  config: any = config;
  emailArray: FormArray;
  selectedEmail: any = {};
  selectedEmailIndex: number = 0;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private celService: CelService) { }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Branch Alert Setup");
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.alertForm = this.formBuilder.group({
      branchCode: [null, Validators.required],
      branchName: [null, Validators.required],
      notificationPercent: [null, [Validators.required, Validators.min(1), Validators.max(99), CustomValidators.noDecimalNumberValidation]],
      warningPercent: [null, [Validators.required, Validators.min(1), Validators.max(99), CustomValidators.noDecimalNumberValidation]],
      branchEmail: this.formBuilder.array([])
    }, {
      validator: CustomValidators.firstNumberGreater('warningPercent', 'notificationPercent')
    })
  }

  ngAfterViewInit() {
    this.getBranchData();
  }

  get af() { return this.alertForm.controls }

  getBranchData() {
    this.celService.getBranchAlert(this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.branchData = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.branchData = response.data[0];
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.branchData = [];
          this.setDatatable();
        }
      }
    );
  }

  editBranchData(branchId) {
    this.selectedBranch = this.branchData.find(data => data.branchId == branchId)
    this.af.branchCode.setValue(this.selectedBranch.branchCode);
    this.af.branchName.setValue(this.selectedBranch.branchName);
    this.af.notificationPercent.setValue(this.selectedBranch.updatedNotificationPercent);
    this.af.warningPercent.setValue(this.selectedBranch.updatedWarningPercent);
    let emailForm = <FormArray>this.formBuilder.array([]);
    this.selectedBranch.branchEmail?.forEach(element => {
      emailForm.push(this.formBuilder.group({
        branchEmailId: [element.branchEmailId],
        branchId: [element.branchId],
        emailId: [{ value: element.emailId, disabled: true }, Validators.email],
        isVerified: [element.isVerified]
      }))
    });
    this.af.branchEmail = emailForm;
    this.emailArray = <FormArray>this.af.branchEmail;
    this.cdref.detectChanges();
  }

  submit() {
    let requestObject = this.alertForm.getRawValue();
    this.celService.saveBranchAlert(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Branch alerts updated successfully.");
          this.cdref.detectChanges();
          $("#alertUpdate").modal("hide");
          this.getBranchData();
        } else {
          this.toastr.error(response.message);
          this.cdref.detectChanges();
        }
      }
    );
  }

  setDatatable() {
    $("#branchAlert").DataTable().destroy();
    setTimeout(() => {
      $("#branchAlert").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4],
          }
        }, {
          extend: 'pdf',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#branchAlert .column-search input').val('');
            $('#branchAlert .column-search select').val('.*');
            $('#branchAlert .column-search').on('keyup change', 'input', function () {
              $('#branchAlert').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#branchAlert .column-search').on('keyup change', 'select', function () {
              $('#branchAlert').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  deleteEmail(email, index) {
    this.selectedEmail = email;
    this.selectedEmailIndex = index;
    console.log(email);
  }

  deleteEmailId() {
    if (this.selectedEmail.value.branchEmailId) {
      this.celService.deleteBranchEmail(this.selectedEmail.value.branchEmailId).pipe(
        catchError((error: any) => {
          this.spinner.hide();
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.cdref.detectChanges();
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Email ID removed");
          this.cdref.detectChanges();
        } else {
          this.toastr.error(response.message);
          this.cdref.detectChanges();
        }
      })
    }
    let emailForm = <FormArray>this.af.branchEmail;
    emailForm.removeAt(this.selectedEmailIndex);
    this.af.branchEmail = emailForm;
    this.cdref.detectChanges();
  }

  addEmail() {
    let emailForm = <FormArray>this.af.branchEmail;
    emailForm.push(this.formBuilder.group({
      branchEmailId: [null],
      branchId: [this.selectedBranch.branchCode],
      emailId: [null, Validators.email],
      isVerified: [null]
    }))
    this.af.branchEmail = emailForm;
    this.emailArray = <FormArray>this.af.branchEmail;
    this.cdref.detectChanges();
  }

  sendVerificationLink(branchEmailId) {
    this.spinner.show();
    this.celService.sendVerificationLink(branchEmailId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Verification link sent to email ID");
        } else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger?.unsubscribe();
  }
}
