import { Component, OnInit, ViewChild } from '@angular/core';
import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/valueObject/enitUser';
import { config } from 'src/app/valueObject/config';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { EnitTradeRequestListService } from 'src/app/service/enit-trade-request-list.service'
import { UserDetailsService } from 'src/app/service/user-details.service';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
declare var $: any;
@Component({
  selector: 'app-et-userid-request-list',
  templateUrl: './et-userid-request-list.component.html',
  styleUrls: ['./et-userid-request-list.component.css']
})
export class EtUseridRequestListComponent implements OnInit {
  dtOptions: any;
  config: any = config;
  users: User[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  viewUser: any = {};
  entityType: any;
  keyword: string = "name";
  menucode: string = 'displayUIRequestProcessPage';
  text: string;
  showMore: boolean;
  comments: boolean = false;
  user : any = {};
  access: any = {};
  memCode: any;
  memName: any;
  memId: Number;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  userIdRequestSearch: any;
  newNeatReqData : any[]=[];
  searchForm :FormGroup;
  certificateFile: any;

  constructor(
    private EnitTradeRequestListService: EnitTradeRequestListService,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private enitTradeService: EnitTradeService,
    private fb: FormBuilder,
    private userDetailsService: UserDetailsService
  ) { }
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.memId = (this.user.entityCode-10000);
    this.titleService.setTitle('User Request List');
    this.searchForm=this.fb.group({
      reqType:[],
    })
  }
  clearSearch() {
    this.setDatatable();
  }
  get f(){
    return this.searchForm.controls;
  }
  reset() {
    this.userIdRequestSearch.reset();
  }
  ngAfterViewInit() {
    this.displayList();
  }
  getFilteredList(){
    console.log(this.f.reqType.value);
  }
  setDatatable() {
    $("#UserIdRequestList").DataTable().destroy();
    setTimeout(() => {
      $("#UserIdRequestList").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#UserIdRequestList .column-search input').val('');
            $('#UserIdRequestList .column-search select').val('.*');
            $('#UserIdRequestList .column-search').on('keyup change', 'input', function () {
              $('#UserIdRequestList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#UserIdRequestList .column-search').on('keyup change', 'select', function () {
              $('#UserIdRequestList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }
  downloadCertification(fileName: string ,memId: number){
   let requestObject = {
    "fileName":fileName?.concat('.pdf'),
    "memberId": memId
   }
    this.enitTradeService.downloadCertification(requestObject).pipe(catchError((error: any) => {
      if (error.status != 200) {
        return throwError(error.error);
      }
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
    })
    ).subscribe((response: any)=> {
      if(response.statusCode == 200){
        // this.certificateFile = response.data;
        let fileName = requestObject.fileName;
          let file = response.data;
          let contentType = 'application/pdf'
          let blob = base64StringToBlob(file, contentType);
          let blobURL = window.URL.createObjectURL(blob)
          saveAs(blob, fileName, {
            autoBOM: true
          });
          this.toastr.success("PDF downloaded");
          // window.open(blobURL, '_blank')
      }
    })
  }

  downloadDeclaration(fileName: string ,memId: number){
    let requestObject = {
      "fileName":fileName?.concat('.pdf'),
      "memberId": memId
     }
      this.enitTradeService.downloadCertification(requestObject).pipe(catchError((error: any) => {
        if (error.status != 200) {
          return throwError(error.error);
        }
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      })
      ).subscribe((response: any)=> {
        if(response.statusCode == 200){
          // this.certificateFile = response.data;
          let fileName = requestObject.fileName;
            let file = response.data;
            let contentType = 'application/pdf'
            let blob = base64StringToBlob(file, contentType);
            let blobURL = window.URL.createObjectURL(blob)
            saveAs(blob, fileName, {
              autoBOM: true
            });
            this.toastr.success("PDF downloaded");
            // window.open(blobURL, '_blank')
        }
      })
  }
  displayList() {
    this.spinner.show();
    $("#UserIdRequestList").DataTable().destroy();

    if(this.entityType!='ENIT')
    { 
      let req : Number = 1;
      let requestObj={
        requestType : req,
        memID :  null
      }
      this.EnitTradeRequestListService.displayList(requestObj).pipe(
        catchError((error: any) => {
          this.spinner.hide();
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.users = [];
          this.setDatatable();
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.statusCode == 200) {
            this.users = response.data;
            this.enitTradeService.addNeatUser(this.users)
            this.setDatatable();
          } else {
            this.toastr.error(response.message);
            this.setDatatable();
          }
        }
      );

    }
    else if(this.entityType=='ENIT')
    {
      let req : Number = 1;
      let requestObj={
        requestType : req,
        memID :  this.memId
      }
      this.EnitTradeRequestListService.displayList(requestObj).pipe(
        catchError((error: any) => {
          this.spinner.hide();
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.users = [];
          this.setDatatable();
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.statusCode == 200) {
            this.users = response.data;
            this.enitTradeService.addNeatUser(this.users)
            this.setDatatable();
          } else {
            this.toastr.error(response.message);
            this.setDatatable();
          }
        }
      );
    }
  }
  viewUserData(memId: number) {
    this.viewUser = this.users.find(users => users.memId == memId);
  }
  ngOnDestroy(): void { }
  processNewNeatRequest(refNo: number,requestType: number) {
    if(this.entityType!='ENIT')
        { 
          if(requestType==12)
          {
              this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/NeatUsrValidityUpdationProcessPage'], 
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==4)
          {
              this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/DisableNeatUserIdAdminComponent'], 
            { "queryParams": { "refNo": refNo } });
                      
          }
          else if(requestType==1)
          {
              this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/AdminUserIdRequestProcessPage'],
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==3)
          {
              this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/ShiftingNeatIdAdminRequest'], 
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==2)
          {
              this.router.navigate(['tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/changeName'],
              { "queryParams": { "refNo": refNo } });
          }
    }
    else if(this.entityType=='ENIT')
    {
          if(requestType==12)
          {
              this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage/NeatUsrValidityUpdationProcessPage'], 
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==4)
          {
              this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage/DisableNeatUserIdAdminComponent'], 
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==1)
          {
              this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage/AdminUserIdRequestProcessPage'],
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==3)
          {
              this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage/ShiftingNeatIdAdminRequest'], 
              { "queryParams": { "refNo": refNo } });
          }
          else if(requestType==2)
          {
              this.router.navigate(['eTrade/eUseridRequest/displayUIRequestProcessPage/changeName'],
              { "queryParams": { "refNo": refNo } });
          }
    }
  }
}

