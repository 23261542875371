import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CyberSecurityService } from 'src/app/service/cyber-security.service';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
import { DatePipe } from '@angular/common';
import { incidentType } from 'src/app/component/enit/EnitCompliance/quaterly-incident-report/incidentType';
import { incidentSymptoms } from 'src/app/component/enit/EnitCompliance/quaterly-incident-report/incidentSymptoms';
declare var $: any;

@Component({
  selector: 'app-quaterly-incident-report',
  templateUrl: './quaterly-incident-report.component.html',
  styleUrls: ['./quaterly-incident-report.component.css']
})
export class QuaterlyIncidentReportComponent implements OnInit {
  memCode: String;
  memId: any;
  memName: string;
  entityType: any;
  user: any = {};
  memberData: any = {};
  config: any = config;
  isReadOnly: boolean = true;
  nillSubmission: boolean=false;
  nill: string;
  quaterlyForm: FormGroup;
  checkBoxForm: FormGroup;
  quarterDetails: any = [];
  enableData: any;
  enable: boolean;
  formArray1: any = [];
  formArray2: any = [];
  formArray3: any = [];
  formArray4: any = [];
  formArray5: any = [];
  quarterList: any;
  eligibleQuarter: any;
  eligibleYear: any;
  year: any;
  cutOffDate: any;
  isSubmitted: any;
  incidentType: any = incidentType;
  incidentSymptoms: any = incidentSymptoms;
  incidentTypeCheckBox: string[];
  incidentSymptomsCheckBox: string[];
  incidentId: any;

  get f() {
    return this.quaterlyForm.controls;
  }

  get formBriefInfo() {
    return this.quaterlyForm.get("briefInfoTable") as FormArray;
  }
  get formDateTimeInfo() {
    return this.quaterlyForm.get("dateAndTimeTable") as FormArray;
  }
  get formInfoAffected() {
    return this.quaterlyForm.get("infoAffectedSystemTable") as FormArray;
  }
  get formInfoAgency() {
    return this.quaterlyForm.get("infoAgencyNotifyTable") as FormArray;
  }
  get formIpSuspect() {
    return this.quaterlyForm.get("ipSuspectedSourceTable") as FormArray;
  }

  constructor(private formBuilder: FormBuilder,
    private cyberSecurityService: CyberSecurityService,
    private toastr: ToastrService,
    private mlAiService: EnitComplianceServiceService,
    private userDetailsService: UserDetailsService,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef) { }

  hasOtherIncident: boolean = false;
  selectedIncidentNames: String[] =[];
  selectedBehaviourNames: String[] =[];
  hasOtherBehaviour: boolean = false;

  ngOnInit(): void {

    this.user = this.userDetailsService.getUserDetails() || {};
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode - 10000) + "";
    this.entityType = this.user.entityType;
    this.isReadOnly = true;
    this.memName = this.user.userName;

    this.getQuarterForSubmission();

    //this.getMemberDetails();
    this.getEnable();

    this.quaterlyForm = this.formBuilder.group({
      memberName: [this.memName, [Validators.required]],
      stockExchangeName: ['NSE IFSC Limited', [Validators.required]],
      memberId: [this.memCode, [Validators.required]],
      quaterName: ['', [Validators.required]],
      cyberAttackObserved: ['N', [Validators.required]],
      incidentRequestId:[null],
      nillSubmission: [],
      rptOfficerName: [],
      rptOfficerOrgName: [],
      rptOfficerTitle: [],
      rptOfficerFaxPhoneNum: [],
      rptOfficerMobileNum: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      rptOfficerEmail: [],
      rptOfficerAddress: ['', Validators.maxLength(20)],
      affectedSystemPhysicalLocation: [],
      incidentType: [''],
      incidentDescr: [],
      incidentOtherDescr: [],
      incidentSymptoms: [''],
      incidentSymptomsDescr: [],
      incidentSymptomsOtherDescr: [],
      problemExprEarlier: ['N'],
      problemExprEarlierDescr: [],
      problemExperince: [],
      agencyNotified: ['N'],
      numberOfHostAffected: [],
      preventiveMeasureApplied: [],
      preventiveMeasureAppliedDescr: [],
      briefInfoTable: this.formBuilder.array([]),
      dateAndTimeTable: this.formBuilder.array([]),
      infoAffectedSystemTable: this.formBuilder.array([]),
      infoAgencyNotifyTable: this.formBuilder.array([]),
      ipSuspectedSourceTable: this.formBuilder.array([]),
      incidentForm: this.formBuilder.array([]),
      behaviourForm: this.formBuilder.array([]),

      // briefInfoTable: this.formBuilder.array([]),
      // dateAndTimeTable: this.formBuilder.array([]),
      // infoAffectedSystemTable: this.formBuilder.array([]),
      // infoAgencyNotifyTable: this.formBuilder.array([]),
      // ipSuspectedSourceTable: this.formBuilder.array([]),
    })
    this.createFormInputs();
  }

  getQuarterForSubmission() {// get the quarter which is to be set in the form
    this.mlAiService.getQuarterForSubmission().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.quarterList = response.data;
        this.f.quaterName.setValue(this.quarterList);
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }


  getMemberDetails() {
    this.mlAiService.getMemberDetails(this.memCode, this.memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.memberData = response.data;
        this.f.memberName.setValue(this.memberData.memNm);
        this.cdref.detectChanges();
      }
    })
  }



  getEnable() {
    this.cyberSecurityService.getEnable(this.memId).pipe(
      catchError((error: any) => {
        this.quarterDetails = [];
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.enableData = response.data;
        if (response.data?.length == 0) {
          this.enable = false;
          $('#enableData').modal('show');
        }
        else {
          this.enable = true;
          this.getQuarterData();
          $('#enableData').modal('hide');
        }
      }
    })
  }

  getQuarterData() {
    this.mlAiService.getQuarterDetails().pipe(
      catchError((error: any) => {
        this.quarterDetails = [];
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.quarterDetails = response.data.currentQuarter;
        this.eligibleQuarter = this.quarterDetails.quarter;
        this.eligibleYear = this.quarterDetails.year
        if (this.enable = true) {
          this.getCutOffDate();
        }
        // this.getCutOffDate();
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      } else {
        this.quarterDetails = [];
        this.toastr.error(response.message);
      }
    });
  }



  getCutOffDate() {
    this.year = this.eligibleYear.toString();
    const module = "Cyber_Security"
    this.mlAiService.getCutOffDate(this.eligibleQuarter, this.year, module).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.cutOffDate = response.data;
          if (this.cutOffDate == true) {
            //this.f.memberName.setValue(this.memberData.memNm);
            this.getSubmitedDetailsOfCyberSecurity();
          }
          else if (this.cutOffDate == false) {
            $('#cutOffdate').modal('show');
            this.isFormSubmitted();
          }
        }
      }
    )
  }

  isFormSubmitted() {
    let year = this.f.quaterName.value;
    let onlyQuater = year.substr(0, 2);
    let first = year.indexOf('(');
    let last = year.indexOf(')');
    let onlyYear = year.substr(first + 1, last - first - 1);

    this.cyberSecurityService.fetchIncidentIdReport(this.memId, onlyQuater, onlyYear).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        if (response.data?.length != 0 && this.cutOffDate == false) {
          this.enable = false;
          $('#cutOffdate').modal('hide');
          $('#submitted').modal('show');

        }
        else {
          this.enable = true;
          //this.f.memberName.setValue(response.data.memberName);
          this.f.stockExchangeName.setValue(response.data.stockExchangeName);
          //this.f.memberId.setValue(response.data.memberId)
          //this.f.quaterName.setValue(response.data.quarter);

          const addForm1 = this.quaterlyForm.controls.briefInfoTable as FormArray;
          addForm1.push(this.formBuilder.group({
            incidentBriefId:[null],
            incidentDate: [''],
            incidentTime: [''],
            cyberAttackBriefInfo: ['']

          }))
          this.f.briefInfoTable = addForm1;


          const addForm2 = this.quaterlyForm.controls.dateAndTimeTable as FormArray;
          addForm2.push(this.formBuilder.group({
            incidentDttmId:[null],
            incidentDate: [''],
            incidentTime: [''],
          }))
          this.f.dateAndTimeTable = addForm2;


          const addForm3 = this.quaterlyForm.controls.infoAffectedSystemTable as FormArray;
          addForm3.push(this.formBuilder.group({
            incidentAffctSysId:[null],
            ipAddress: [''],
            hostName: [''],
            operatingSystem: [''],
            lastPatched: [''],
            hardwareModel: ['']
          }))
          this.f.infoAffectedSystemTable = addForm3;

          const addForm4 = this.quaterlyForm.controls.infoAgencyNotifyTable as FormArray;
          addForm4.push(this.formBuilder.group({
            incidentAgencyId:[null],
            lawEnforcement: [''],
            privateAgency: [''],
            affectedProductVendor: [''],
            otherAgency: ['']
          }))
          this.f.infoAgencyNotifyTable = addForm4;

          const addForm5 = this.quaterlyForm.controls.ipSuspectedSourceTable as FormArray;
          addForm5.push(this.formBuilder.group({
            incidentSuspectSourceId:[null],
            sourceIp: [''],
            otherInfo: ['']
          }))
          this.f.ipSuspectedSourceTable = addForm5;

          $('#submitted').modal('hide');
        }
      }
    })
  }


  getSubmitedDetailsOfCyberSecurity() {
    let year = this.f.quaterName.value;
    let onlyQuater = year.substr(0, 2);
    let first = year.indexOf('(');
    let last = year.indexOf(')');
    let onlyYear = year.substr(first + 1, last - first - 1);

    this.cyberSecurityService.fetchIncidentIdReport(this.memId, onlyQuater, onlyYear).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.selectedIncidentNames = response.data.incidentType?.split("|");
        this.selectedBehaviourNames = response.data.incidentSymptoms?.split("|");
        this.incidentId = response.data.incidentRequestId;
        //this.f.memberName.setValue(response.data.memberName);
        // this.f.memberId.setValue(response.data.memberId)
        //this.f.quaterName.setValue(response.data.quaterName + "-(" + response.data.year + ")"),
          this.f.cyberAttackObserved.setValue(response.data.cyberAttackObserved),
          this.f.nillSubmission.setValue(response.data.nillSubmission),
          this.f.rptOfficerName.setValue(response.data.rptOfficerName),
          this.f.rptOfficerOrgName.setValue(response.data.rptOfficerOrgName),
          this.f.rptOfficerTitle.setValue(response.data.rptOfficerTitle),
          this.f.rptOfficerFaxPhoneNum.setValue(response.data.rptOfficerFaxPhoneNum),
          this.f.rptOfficerMobileNum.setValue(response.data.rptOfficerMobileNum),
          this.f.rptOfficerEmail.setValue(response.data.rptOfficerEmail),
          this.f.rptOfficerAddress.setValue(response.data.rptOfficerAddress),
          this.f.affectedSystemPhysicalLocation.setValue(response.data.affectedSystemPhysicalLocation);
          this.f.incidentDescr.setValue(response.data.incidentDescr),
          this.f.incidentOtherDescr.setValue(response.data.incidentOtherDescr),
          this.f.incidentSymptomsDescr.setValue(response.data.incidentSymptomsDescr),
          this.f.incidentSymptomsOtherDescr.setValue(response.data.incidentSymptomsOtherDescr),
          this.f.problemExprEarlier.setValue(response.data.problemExprEarlier),
          this.f.problemExprEarlierDescr.setValue(response.data.problemExprEarlierDescr),
          this.f.problemExperince.setValue(response.data.problemExperince),
          this.f.agencyNotified.setValue(response.data.agencyNotified),
          this.f.numberOfHostAffected.setValue(response.data.numberOfHostAffected),
          this.f.preventiveMeasureApplied.setValue(response.data.preventiveMeasureApplied),
          this.f.preventiveMeasureAppliedDescr.setValue(response.data.preventiveMeasureAppliedDescr);
          this.f.incidentRequestId.setValue(response.data.incidentRequestId);
          this.selectOtherIncident();
          this.selectOtherBehaviour();
        if (response.data.incidentBriefInfor?.length > 0) {
          this.formArray1 = response.data.incidentBriefInfor
          const add = this.quaterlyForm.controls.briefInfoTable as FormArray;
          this.formArray1.forEach((Details: any) => {
            add.push(this.formBuilder.group({
              incidentBriefId: [Details.incidentBriefId],
              incidentDate: [this.datePipe.transform(Details.incidentDate, 'MM/dd/yyyy')],
              incidentTime: [Details.incidentTime],
              cyberAttackBriefInfo: [Details.cyberAttackBriefInfo]

            }))
          })
          this.quaterlyForm.controls.briefInfoTable = add;

        }
        else {
          const addForm1 = this.quaterlyForm.controls.briefInfoTable as FormArray;
          addForm1.push(this.formBuilder.group({
            incidentBriefId: [null],
            incidentDate: [''],
            incidentTime: [''],
            cyberAttackBriefInfo: ['']
          }))
          this.f.briefInfoTable = addForm1;
        }

        if (response.data.incidentDateTime?.length > 0) {
          this.formArray2 = response.data.incidentDateTime
          const add2 = this.quaterlyForm.controls.dateAndTimeTable as FormArray;
          this.formArray2.forEach((Details: any) => {
            add2.push(this.formBuilder.group({
              incidentDttmId: [Details.incidentDttmId],
              incidentDate: [this.datePipe.transform(Details.incidentDate, 'MM/dd/yyyy')],
              incidentTime: [Details.incidentTime]
            }))
          })
          this.quaterlyForm.controls.dateAndTimeTable = add2;

        }
        else {
          const addForm2 = this.quaterlyForm.controls.dateAndTimeTable as FormArray;
          addForm2.push(this.formBuilder.group({
            incidentDttmId: [null],
            incidentDate: [''],
            incidentTime: [''],
          }))
          this.f.dateAndTimeTable = addForm2;
        }

        if (response.data.incidentAffectSystmInfo?.length > 0) {
          this.formArray3 = response.data.incidentAffectSystmInfo
          const add3 = this.quaterlyForm.controls.infoAffectedSystemTable as FormArray;
          this.formArray3.forEach((Details: any) => {
            add3.push(this.formBuilder.group({
              incidentAffctSysId: [Details.incidentAffctSysId],
              ipAddress: [Details.ipAddress],
              hostName: [Details.hostName],
              operatingSystem: [Details.operatingSystem],
              lastPatched: [Details.lastPatched],
              hardwareModel: [Details.hardwareModel]
            }))
          })
          this.quaterlyForm.controls.infoAffectedSystemTable = add3;

        } else {
          const addForm3 = this.quaterlyForm.controls.infoAffectedSystemTable as FormArray;
          addForm3.push(this.formBuilder.group({
            incidentAffctSysId: [null],
            ipAddress: [''],
            hostName: [''],
            operatingSystem: [''],
            lastPatched: [''],
            hardwareModel: ['']
          }))
          this.f.infoAffectedSystemTable = addForm3;
        }

        if (response.data.incidentAgencyInfo?.length > 0) {
          this.formArray4 = response.data.incidentAgencyInfo
          const add4 = this.quaterlyForm.controls.infoAgencyNotifyTable as FormArray;
          this.formArray4.forEach((Details: any) => {
            add4.push(this.formBuilder.group({
              incidentAgencyId: [Details.incidentAgencyId],
              lawEnforcement: [Details.lawEnforcement],
              privateAgency: [Details.privateAgency],
              affectedProductVendor: [Details.affectedProductVendor],
              otherAgency: [Details.otherAgency]
            }))
          })
          this.quaterlyForm.controls.infoAgencyNotifyTable = add4;

        } else {
          const addForm4 = this.quaterlyForm.controls.infoAgencyNotifyTable as FormArray;
          addForm4.push(this.formBuilder.group({
            incidentAgencyId: [null],
            lawEnforcement: [''],
            privateAgency: [''],
            affectedProductVendor: [''],
            otherAgency: ['']
          }))
          this.f.infoAgencyNotifyTable = addForm4;

        }

        if (response.data.incidentSuspectSourceInfo?.length > 0) {
          this.formArray5 = response.data.incidentSuspectSourceInfo
          const add5 = this.quaterlyForm.controls.ipSuspectedSourceTable as FormArray;
          this.formArray5.forEach((Details: any) => {
            add5.push(this.formBuilder.group({
              incidentSuspectSourceId: [Details.incidentSuspectSourceId],
              sourceIp: [Details.sourceIp],
              otherInfo: [Details.otherInfo]
            }))
          })
          this.quaterlyForm.controls.ipSuspectedSourceTable = add5;

        } else {
          const addForm5 = this.quaterlyForm.controls.ipSuspectedSourceTable as FormArray;
          addForm5.push(this.formBuilder.group({
            incidentSuspectSourceId: [null],
            sourceIp: [''],
            otherInfo: ['']
          }))
          this.f.ipSuspectedSourceTable = addForm5;
        }
        this.quaterlyForm.controls.incidentForm = this.createIncidents(this.incidentType);
        this.quaterlyForm.controls.behaviourForm = this.createBehaviours(this.incidentSymptoms);

      }
      this.cdref.detectChanges();
    }
    )
  }



  initBriefInfoRows() {
    return this.formBuilder.group({
      incidentBriefId: [],
      incidentDate: [],
      incidentTime: [],
      cyberAttackBriefInfo: []
    });
  }
  initDateAndTimeRows() {
    return this.formBuilder.group({
      incidentDttmId: [],
      incidentDate: [],
      incidentTime: [],
    })
  }
  initInfoAffectedSystemRows() {
    return this.formBuilder.group({
      incidentAffctSysId: [],
      ipAddress: [],
      hostName: [],
      operatingSystem: [],
      lastPatched: [],
      hardwareModel: []
    })
  }
  initInfoAgencyNotifyRows() {
    return this.formBuilder.group({
      incidentAgencyId: [],
      lawEnforcement: [],
      privateAgency: [],
      affectedProductVendor: [],
      otherAgency: []
    })
  }
  initIpSuspectedSourceRows() {
    return this.formBuilder.group({
      incidentSuspectSourceId: [],
      sourceIp: [],
      otherInfo: []
    })
  }





  createFormInputs() {
    // this.checkBoxForm = new FormGroup({
    //   incidents: this.createIncidents(this.incidentType),
    //   behaviours: this.createBehaviours(this.incidentSymptoms)
    // });
    this.getSelectedIncidents();
    this.getSelectedBehaviours();
  }

  createIncidents(incidentsInput) {
    const arr = incidentsInput.map(incident => {
      let inc = this.selectedIncidentNames?.find(data => data == incident.value);
      if (inc)
        incident.selected = true;
      return new FormControl(incident.selected || false);
    });
    return new FormArray(arr);
  }

  getSelectedIncidents() {
    this.selectedIncidentNames = _.map(
      this.quaterlyForm.controls.incidentForm['controls'],
      (incident, i) => {
        return incident.value && this.incidentType[i].value;
      }
    );
    this.getSelectedIncidentName();
  }

  getSelectedIncidentName() {
    this.selectedIncidentNames = _.filter(
      this.selectedIncidentNames,
      function (incident) {
        if (incident !== false) {
          return incident;
        }
      }
    );
  }

  getSelectedIncidentValues(incident: any) {
    // if (this.incidentType?.some(x => x.value === incident)) {
    //   return true;
    // }
    // else {
    //   return false;
    // }
    if(!this.selectedIncidentNames){
      this.selectedIncidentNames=[]
    } 
    let inc = this.selectedIncidentNames?.find(data => data == incident);
    if (inc) {
      this.selectedIncidentNames = this.selectedIncidentNames.filter(data => data != incident)
    } else {
      this.selectedIncidentNames.push(incident);
    }
  }

  createBehaviours(behavoiursInput) {
    const arr = behavoiursInput.map(behaviour => {
      let inc = this.selectedBehaviourNames?.find(data => data == behaviour.value);
      if (inc)
        behaviour.selected = true;
      return new FormControl(behaviour.selected || false);
    });
    return new FormArray(arr);
  }

  getSelectedBehaviours() {
    this.selectedBehaviourNames = _.map(
      this.quaterlyForm.controls.behaviourForm['controls'],
      (behaviour, i) => {
        return behaviour.value && this.incidentSymptoms[i].value;
      }
    );
    this.getSelectedBehavioursName();
  }

  getSelectedBehavioursName() {
    this.selectedBehaviourNames = _.filter(
      this.selectedBehaviourNames,
      function (behaviour) {
        if (behaviour !== false) {
          return behaviour;
        }
      }
    );
  }

  getSelectedBehaviourValues(symptoms: any) {
    // if (this.incidentSymptoms?.some(x => x.value === symptoms)) {
    //   return true;
    // }
    // else {
    //   return false;
    // }
    if(!this.selectedBehaviourNames){
      this.selectedBehaviourNames=[]
    }
    let inc = this.selectedBehaviourNames.find(data => data == symptoms);
    if (inc) {
      this.selectedBehaviourNames = this.selectedBehaviourNames.filter(data => data != symptoms)
    } else {
      this.selectedBehaviourNames.push(symptoms);
    }
  }


  selectOtherIncident() {
    this.hasOtherIncident = this.selectedIncidentNames?.some(x => x === "OTHER");
  }
  selectOtherBehaviour() {
    this.hasOtherBehaviour = this.selectedBehaviourNames?.some(x => x === "OTHER");
  }
  addIncidentBriefInfo() {
    this.formBriefInfo.push(this.initBriefInfoRows());
  }
  deleteIncidentBriefInfo(index) {
    this.formBriefInfo.removeAt(index);
  }
  addInfoAffectedSystem() {
    this.formInfoAffected.push(this.initInfoAffectedSystemRows());
  }
  deleteInfoAffectedSystem(index) {
    this.formInfoAffected.removeAt(index);
  }
  addDate() {
    this.formDateTimeInfo.push(this.initDateAndTimeRows());
  }
  deleteDate(index) {
    this.formDateTimeInfo.removeAt(index);
  }
  addAgencyNotifiedSytem() {
    this.formInfoAgency.push(this.initInfoAgencyNotifyRows());
  }
  deleteAgencyNotifiedSytem(index) {
    this.formInfoAgency.removeAt(index);
  }
  addIncidentSuspectSource() {
    this.formIpSuspect.push(this.initIpSuspectedSourceRows());
  }
  deleteIncidentSuspectSource(index) {
    this.formIpSuspect.removeAt(index);
  }


  saveQuaterlyForm() {
    let year = this.f.quaterName.value;
    let onlyQuater = year.substr(0, 2);
    let first = year.indexOf('(');
    let last = year.indexOf(')');
    let onlyYear = year.substr(first + 1, last - first - 1);

    let quaterlyFormInputs = {
      'memberName': this.f.memberName.value,
      'stockExchangeName': 'NSE IFSC Limited',
      'memberId': this.memId,
      "year": onlyYear,
      'quaterName': onlyQuater,
      'cyberAttackObserved': this.f.cyberAttackObserved.value,
      'nillSubmission':'N',
      'rptOfficerName': this.f.rptOfficerName.value,
      'rptOfficerOrgName': this.f.rptOfficerOrgName.value,
      'rptOfficerTitle': this.f.rptOfficerTitle.value,
      'rptOfficerFaxPhoneNum': this.f.rptOfficerFaxPhoneNum.value,
      'rptOfficerMobileNum': this.f.rptOfficerMobileNum.value,
      'rptOfficerEmail': this.f.rptOfficerEmail.value,
      'rptOfficerAddress': this.f.rptOfficerAddress.value,
      "incidentBriefInfor": [],
      'affectedSystemPhysicalLocation': this.f.affectedSystemPhysicalLocation?.value || '',
      'incidentDateTime': [],
      'incidentAffectSystmInfo': [],
      'incidentType': this.selectedIncidentNames?.length > 0 ? this.selectedIncidentNames.join('|') : '',
      'incidentOtherDescr': this.f.incidentOtherDescr?.value||'',
      'incidentDescr': this.f.incidentDescr?.value||'',
      'incidentSymptoms': (this.selectedBehaviourNames?.length > 0 ? this.selectedBehaviourNames.join("|") : null),
      'incidentSymptomsOtherDescr': this.f.incidentSymptomsOtherDescr?.value||'',
      'incidentSymptomsDescr': this.f.incidentSymptomsDescr?.value||'',
      'problemExprEarlier': this.f.problemExprEarlier?.value||'N',
      'problemExprEarlierDescr': this.f.problemExprEarlierDescr?.value||'',
      'agencyNotified': this.f.agencyNotified?.value || 'N',
      'incidentAgencyInfo': [],
      'incidentSuspectSourceInfo': [],
      'numberOfHostAffected': this.f.numberOfHostAffected?.value||'0',
      'preventiveMeasureApplied': this.f.preventiveMeasureApplied?.value||'N',
      'preventiveMeasureAppliedDescr': this.f.preventiveMeasureAppliedDescr?.value||'',
      'incidentRequestId': this.f.incidentRequestId?.value || null
    };

    let nillSubmissionChecks = {
      'memberName': this.f.memberName.value,
      'stockExchangeName': 'NSE IFSC Limited',
      'memberId': this.memId,
      "year": onlyYear,
      'quaterName': onlyQuater,
      'cyberAttackObserved': this.f.cyberAttackObserved.value,
      'nillSubmission': 'Y',
      'rptOfficerName': null,
      'rptOfficerOrgName': null,
      'rptOfficerTitle': null,
      'rptOfficerFaxPhoneNum': null,
      'rptOfficerMobileNum': null,
      'rptOfficerEmail': null,
      'rptOfficerAddress': null,
      "incidentBriefInfor": null,
      'affectedSystemPhysicalLocation': null,
      'incidentDateTime': null,
      'incidentAffectSystmInfo': null,
      'incidentType': null,
      'incidentOtherDescr': null,
      'incidentDescr': null,
      'incidentSymptoms': null,
      'incidentSymptomsOtherDescr': null,
      'incidentSymptomsDescr': null,
      'problemExprEarlier': null,
      'problemExprEarlierDescr': null,
      'agencyNotified': null,
      'incidentAgencyInfo': null,
      'incidentSuspectSourceInfo': null,
      'numberOfHostAffected': null,
      'preventiveMeasureApplied': null,
      'preventiveMeasureAppliedDescr': null,
      'incidentRequestId': this.f.incidentRequestId?.value || null
    };

    this.f.briefInfoTable['controls'].forEach(element => {
      quaterlyFormInputs.incidentBriefInfor.push({
        'incidentBriefId':element.value.incidentBriefId,
        'incidentDate': element.value.incidentDate,
        'incidentTime': element.value.incidentTime,
        'cyberAttackBriefInfo': element.value.cyberAttackBriefInfo
      })
    });

    this.f.dateAndTimeTable['controls'].forEach(element => {
      quaterlyFormInputs.incidentDateTime.push({
        'incidentDttmId':element.value.incidentDttmId,
        'incidentDate': element.value.incidentDate,
        'incidentTime': element.value.incidentTime,
      })
    });

    this.f.infoAffectedSystemTable['controls'].forEach(element => {
      quaterlyFormInputs.incidentAffectSystmInfo.push({
        'incidentAffctSysId':element.value.incidentAffctSysId,
        'ipAddress': element.value.ipAddress,
        'hostName': element.value.hostName,
        'operatingSystem': element.value.operatingSystem,
        'lastPatched': element.value.lastPatched,
        'hardwareModel': element.value.hardwareModel
      })
    })

    this.f.infoAgencyNotifyTable['controls'].forEach(element => {
      quaterlyFormInputs.incidentAgencyInfo.push({
        'incidentAgencyId':element.value.incidentAgencyId,
        'lawEnforcement': element.value.lawEnforcement,
        'privateAgency': element.value.privateAgency,
        'affectedProductVendor': element.value.affectedProductVendor,
        'otherAgency': element.value.otherAgency
      })
    })

    this.f.ipSuspectedSourceTable['controls'].forEach(element => {
      quaterlyFormInputs.incidentSuspectSourceInfo.push({
        'incidentSuspectSourceId':element.value.incidentSuspectSourceId,
        'sourceIp': element.value.sourceIp,
        'otherInfo': element.value.otherInfo
      })
    })


    if(this.f.cyberAttackObserved.value=='N'){
      this.nill='Y'
    }
    if (this.nill == 'Y') {
      nillSubmissionChecks.nillSubmission='Y';
      this.cyberSecurityService.updateQuaterlyIncidentForm(nillSubmissionChecks).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.reset();
          this.toastr.success(response.message);
        }
      })
    }
    else {
      this.cyberSecurityService.updateQuaterlyIncidentForm(quaterlyFormInputs).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.reset();
          this.toastr.success(response.message);
        }
      })
    }

  }



  onNillSubmission(event) {
    this.nillSubmission = event.target.checked;
    if (this.nillSubmission) {
      this.f.nillSubmission.setValue(true);
      this.nill = 'Y';
    }
    else {
      this.f.nillSubmission.setValue(false);
      this.nill = 'N';
    }
  }

  onCyberCheck() {
    if (this.f.cyberAttackObserved.value=='Y') {
      this.f.nillSubmission.setValue(false)
      this.nill='N'
    }
    else {
      this.f.nillSubmission.setValue(true)
      this.nill='Y'
    }
  }

  reset(){
    this.formBriefInfo.reset();
    this.formDateTimeInfo.reset();
    this.formInfoAffected.reset();
    this.formInfoAgency.reset();
    this.formIpSuspect.reset();
    this.f.cyberAttackObserved.reset();
    // this.f.incidentRequestId.reset();
    this.f.nillSubmission.reset();
    this.f.rptOfficerName.reset();
    this.f.rptOfficerOrgName.reset();
    this.f.rptOfficerTitle.reset();
    this.f.rptOfficerFaxPhoneNum.reset();
    this.f.rptOfficerMobileNum.reset();
    this.f.rptOfficerEmail.reset();
    this.f.rptOfficerAddress.reset();
    this.f.affectedSystemPhysicalLocation.reset();
    this.f.incidentType.reset();
    this.f.incidentDescr.reset();
    this.f.incidentOtherDescr.reset();
    this.f.incidentSymptoms.reset();
    this.f.incidentSymptomsDescr.reset();
    this.f.incidentSymptomsOtherDescr.reset();
    this.f.problemExprEarlierDescr.reset();
    this.f.problemExperince.reset();
    this.f.agencyNotified.reset();
    this.f.numberOfHostAffected.reset();
    this.f.preventiveMeasureApplied.reset();
    this.f.preventiveMeasureAppliedDescr.reset();
   
  }
 
  
}