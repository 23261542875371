import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { VerfiyRequestByOtpComponent } from '../verfiy-request-by-otp/verfiy-request-by-otp.component';
import { CertificateveirfyOtp } from 'src/app/service/certificateveirfy-otp';
import { config } from 'src/app/valueObject/config';
declare var $:any;
@Component({
  selector: 'app-password-or-unlock-request',
  templateUrl: './password-or-unlock-request.component.html',
  styleUrls: ['./password-or-unlock-request.component.css']
})
export class PasswordOrUnlockRequestComponent implements OnInit {  
  requestPwdReset: FormGroup;
  cusDlrId: any=[];
  config: any = config;
  message: string;
  menucode = 'anewRequestPwdReset';
  submitted = false;
  memCode: String;
  memId: String;
  access: any = {};
  entityType: any;
  memName:String;
  user:any={};
  module: any="Password Reset/Unlock";
  reqtype: number = 3;
  loginId: string;
  verifyOTP: boolean;
  otp: VerfiyRequestByOtpComponent;
  reason: string;
  constructor(
    private route: ActivatedRoute,
    private formbuilder: FormBuilder, 
    private toastr: ToastrService,
    private EnitService: EnitService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private userDetailsService:UserDetailsService,
    private certificateVerfiy: CertificateveirfyOtp
    
  ) { }
  get f() { return this.requestPwdReset.controls; }
  newItemEvent(event: boolean){
    this.verifyOTP=event;
    console.log(this.verifyOTP);
    if(this.verifyOTP == true){
      $('#cashdeposite').modal('hide');
    }
  }
  ngOnInit(): void {
    $('.selectpicker').selectpicker();
    $('.selectpicker').selectpicker('refresh');
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.loginId = this.user.loginId;
    this.titleService.setTitle('Password Reset / Unlock Request');
    this.requestPwdReset = this.formbuilder.group({
      memCode:[this.memCode,Validators.required],
      memName:[this.memName,Validators.required],
      userType:['',Validators.required],
      actionType:['',Validators.required],
      cusDlrId:['',Validators.required],
      reasonForUnlock:[''],
      // EmailType:['',[Validators.required, Validators.email]],
      // mobileNo:['',[Validators.required,
      //   Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      //   Validators.minLength(10), Validators.maxLength(10)]],
      EmailType:['',[Validators.email]],
      mobileNo:[''],
    })
  }

  
  changeAction(){
    if(this.f.actionType.value=='R'){
      this.f.reasonForUnlock.clearValidators();
      this.f.reasonForUnlock.updateValueAndValidity();
    }
    else if(this.f.actionType.value == 'U'){
      this.f.reasonForUnlock.setValue('');
      this.f.reasonForUnlock.setValidators([Validators.required]);
      this.f.reasonForUnlock.updateValueAndValidity();
    }
  }
  SelectUserType()
  {
    this.EnitService.getCorpMgrList(this.memId,this.f.userType.value).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response:any)=>{
      if(response!= null){
        this.cusDlrId=response;
        this.cdref.detectChanges();
         $('.selectpicker').selectpicker('refresh');
    }
      this.cusDlrId=response;
    })
  }
  reset(){
    this.f.userType.setValue('');
    this.f.actionType.setValue('')
    this.f.cusDlrId.setValue('')
    this.f.reasonForUnlock.setValue(null)
    this.f.EmailType.setValue('')
    this.f.mobileNo.setValue('')
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    this.submitted = false;
    this.verifyOTP = false;

    this.f.reasonForUnlock.clearValidators();
    this.f.reasonForUnlock.updateValueAndValidity();

    this.requestPwdReset.markAsPristine();
    this.requestPwdReset.markAsUntouched();
  }
  onSubmit() {
    this.submitted = true;

    if(this.f.actionType.value == 'R'){
      
      let requestObject = {
        "memId": this.memId,
        "memCode":this.memCode,
        "memName":this.memName,
        "userType":this.f.userType.value,
        "pcmAction":this.f.actionType.value,
        "pcmCorpMgrUsrId":this.f.cusDlrId.value,
        "pcmResonforUnlock":this.f.reasonForUnlock.value || null,
        "pcmEmailId":this.f.EmailType?.value || null,
        "pcmMobileNo":this.f.mobileNo?.value || null,   
      }
      this.EnitService.SavePasswordResetReqObj(requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.submitted = false;
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if(response.statusCode == 409){
          this.toastr.warning(response.message);
          this.reset();
          // this.submitted = true;
          // this.verifyOTP = false
        }
        else if (response.statusCode == 200) {
          let refNo = response.data.pcmReqRefNo;
          this.message = 'Request Sent Successfully With Reference Number : ' +refNo;
          this.toastr.success(this.message);
          this.reset();
          // this.submitted = true;
          // window.location.reload();
        } 
        else{
          this.toastr.error(response.message)
          this.submitted = false;
        }
      })
    }
    else if(this.f.actionType.value == 'U'){
      let requestObject = {
        "memId": this.memId,
        "memCode":this.memCode,
        "memName":this.memName,
        "userType":this.f.userType.value,
        "pcmAction":this.f.actionType.value,
        "pcmCorpMgrUsrId":this.f.cusDlrId.value,
        "pcmResonforUnlockStr":this.f.reasonForUnlock.value,
        "pcmEmailId":this.f.EmailType?.value || null,
        "pcmMobileNo":this.f.mobileNo?.value || null,
      }
      this.EnitService.SavePasswordResetReqObj(requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.submitted = false;
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if(response.statusCode == 409){
          this.toastr.warning(response.message);
          this.reset();
          // this.submitted = true;
          // this.verifyOTP = false
        }
        else if (response.statusCode == 200) {
          let refNo = response.data.pcmReqRefNo;
          this.message = 'Request Sent Successfully With Reference Number : ' +refNo;
          this.toastr.success(this.message);
          this.reset();
          // this.submitted = true;
          // window.location.reload();
        } 
        else{
          this.toastr.error(response.message)
          this.submitted = false;
        }
      })
    }
  //   else if(this.f.userType.value == 'DLR' && this.f.actionType.value == 'R'){
  //     let requestObject = {
  //       "memCode":this.memCode,
  //       "memName":this.memName,
  //       "userType":this.f.userType.value,
  //       "pcmAction":this.f.actionType.value,
  //       "pcmUserId":this.f.cusDlrId.value,
  //       "pcmResonforUnlock":this.f.reasonForUnlock.value || null,
  //       "pcmEmailId":this.f.EmailType.value,
  //       "pcmMobileNo":this.f.mobileNo.value,    
  //     }
  //     this.EnitService.SavePasswordResetReqObj(requestObject).pipe(
  //       catchError((error: any) => {
  //         this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
  //         this.submitted = false;
  //         if (error.status != 200) {
  //           return throwError(error.error);
  //         }
  //       })
  //     ).subscribe((response: any) => {
  //       if (response.statusCode == 200) {
  //         this.message = 'Password Reset/Unlock Request Sent Successfully';
  //         this.toastr.success(this.message);
  //         this.reset();
  //         this.submitted = true;
  //         window.location.reload();
  //       } else {
  //         this.toastr.error(response.message)
  //         this.submitted = false;
  //       }
  //     })
  //   }
  //   else if(this.f.userType.value == 'DLR' && this.f.actionType.value == 'U'){
  //     let requestObject = {
  //       "memCode":this.memCode,
  //       "memName":this.memName,
  //       "memId": this.memId,
  //       "userType":this.f.userType.value,
  //       "pcmAction":this.f.actionType.value,
  //       "pcmUserId":this.f.cusDlrId.value,
  //       "pcmResonforUnlockStr":this.f.reasonForUnlock.value || null,
  //       "pcmEmailId":this.f.EmailType.value,
  //       "pcmMobileNo":this.f.mobileNo.value,   
  //     }
  //     this.EnitService.SavePasswordResetReqObj(requestObject).pipe(
  //       catchError((error: any) => {
  //         this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
  //         this.submitted = false;
  //         if (error.status != 200) {
  //           return throwError(error.error);
  //         }
  //       })
  //     ).subscribe((response: any) => {
  //       if (response.statusCode == 200) {
  //         this.message = 'Password Reset/Unlock Request Sent Successfully';
  //         this.toastr.success(this.message);
  //         this.reset();
  //         this.submitted = true;
  //         window.location.reload();
  //       } else {
  //         this.toastr.error(response.message)
  //         this.submitted = false;
  //       }
  //     })
  //   }
    
    
 }
}
