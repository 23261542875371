import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuGuard } from 'src/app/service/menu.guard';
import { SubmenuGuard } from 'src/app/service/submenu.guard';
import { DisableNeatUserIdAdminComponent } from './EnitTrade/disable-neat-user-id-admin/disable-neat-user-id-admin.component';
import { EtNewneatRequestProcessPageComponent } from './EnitTrade/et-newneat-request-process-page/et-newneat-request-process-page.component';
import { EtUseridRequestListComponent } from './EnitTrade/et-userid-request-list/et-userid-request-list.component';
import { NeatUserValidityUpdationModalComponent } from './EnitTrade/neat-user-validity-updation-modal/neat-user-validity-updation-modal.component';
import { ShiftingNeatIdAdminRequestComponent } from './EnitTrade/shifting-neat-id-admin-request/shifting-neat-id-admin-request.component';
import { EtMesssageRateAdminComponent } from './EnitTrade/et-messsage-rate-admin/et-messsage-rate-admin.component';
import { EtMessageRateAdminModalComponent } from './EnitTrade/et-message-rate-admin-modal/et-message-rate-admin-modal.component';
import { CtclIdRequestReportComponent } from './EnitTrade/ctcl/ctcl-id-request-report/ctcl-id-request-report.component';
import { UnlockResetModalComponent} from './EnitTrade/unlock-reset-modal/unlock-reset-modal.component';
import { PasswordOrUnlockRequestAdminComponent } from './EnitTrade/password-or-unlock-request-admin/password-or-unlock-request-admin.component';
// import { NeatNonneatReportAdminComponent } from './EnitTrade/neat-nonneat-report-admin/neat-nonneat-report-admin.component';
// import { NeatNonneatProcessPageAdminComponent } from './EnitTrade/neat-nonneat-process-page-admin/neat-nonneat-process-page-admin.component';
import { EtChangeNameProcessPageComponent } from './EnitTrade/et-change-name-process-page/et-change-name-process-page.component';
import { NeatNonneatProcessPageAdminComponent } from './EnitTrade/neat-nonneat-process-page-admin/neat-nonneat-process-page-admin.component';
import { NeatNonneatReportComponent } from './EnitTrade/neat-nonneat-report/neat-nonneat-report.component';
import { EnitMisReportComponent } from './EnitTrade/enitmis-report/enit-mis-report.component';
const routes: Routes = [

  {
    path: 'eUserIDRequestA', canActivate: [MenuGuard], canDeactivate: [SubmenuGuard],
    children: [
      {
        path: 'displayUIRequestProcessPage', canDeactivate: [SubmenuGuard],
        children: [
          { path: '', component: EtUseridRequestListComponent },
          { path: 'NeatUsrValidityUpdationProcessPage', component: NeatUserValidityUpdationModalComponent },
          { path: 'ShiftingNeatIdAdminRequest', component: ShiftingNeatIdAdminRequestComponent },
          { path: 'DisableNeatUserIdAdminComponent', component: DisableNeatUserIdAdminComponent },
          { path: 'AdminUserIdRequestProcessPage', component: EtNewneatRequestProcessPageComponent },
          { path: 'changeName', component: EtChangeNameProcessPageComponent},
        ]
      },
      {
        path: 'displayMRCReport', canDeactivate: [SubmenuGuard],
        children: [
          { path: '', component: EtMesssageRateAdminComponent },
          { path: 'messageRateModal', component: EtMessageRateAdminModalComponent }
        ]
      },
      {
        path: 'displayNonNeatReport', canDeactivate: [SubmenuGuard],
        children: [
          { path: '', component: NeatNonneatReportComponent },
          { path: 'NonNeatRequestProcessPage', component: NeatNonneatProcessPageAdminComponent }
        ]
      },
    ]
  },

  {
    path: 'eMemberReportingTradeA', canDeactivate: [SubmenuGuard],
    children: [
      { path: 'displayCtclIdReport', component: CtclIdRequestReportComponent }
    ]
  },
  {
    path: 'ePasswordResetUserId', canDeactivate: [SubmenuGuard],
    children: [
      { path: 'displayAdminPasswordMis', canDeactivate: [SubmenuGuard],
      children:[
        {path:'',component: PasswordOrUnlockRequestAdminComponent},
        {path:'modalForm',component: UnlockResetModalComponent}
      ],
      }
    ]
  },
  {
    path: 'misTradeReport',
    children: [
      { path: 'displayMisReport', component: EnitMisReportComponent }
    ]
  },

 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MenuGuard, SubmenuGuard],
})
export class TradeAdminRoutingModule { }
