<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Cyber Security </h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a>Enit Compliance </a></li>
                    <li>
                        <a routerLink="/complianceAdmin/cyberSecurityAdmin/getCyberSecurityEnabledDetails" skipLocationChange
                            aria-current="page">
                            Cyber Security Enabled User List
                        </a>
                    </li>
                    <li class="active">
                        <a routerLink="complianceAdmin/cyberSecurityAdmin/getCyberSecurityEnabledDetails/add-CyberSecurity" skipLocationChange
                            aria-current="page">
                            Enable Cyber Security
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body col-lg-12 mt-3">
        <form [formGroup]="addCyberSecurityForm">
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Member Id</label>
                     <select class="form-control selectpicker" formControlName="memberId" data-style="btn-info" title="Select Member Id"
                        (change)="selectMember()" data-live-search="true" >
                        <option *ngFor="let data of memberList" value="{{data.memberId}}">{{data.memberId*1+10000}}</option>
                    </select>
                    <p *ngIf="f.memberId.invalid && f.memberId.touched" class="error nocase">
                        Please select Member ID
                      </p>
                </div>
                 <div class="col-lg-3 form-group">
                    <label>Member Name</label>
                    <input type="text" class="form-control" formControlName="memberName" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Year</label>
                    <input type="text" class="form-control" formControlName="year" readonly>
                    <p *ngIf="f.year.invalid && f.year.touched" class="error nocase">
                        Please select Year
                      </p>
                </div>
              <div class="col-lg-3 form-group">
                    <label>Quarter</label>
                    <input type="text" class="form-control" formControlName="quarter" readonly>
                    <p *ngIf="f.quarter.invalid && f.quarter.touched" class="error nocase">
                        Please select Quarter
                      </p>
                </div> 
            </div> 
            <div class="row">
               
                <div class="col-lg-3 form-group">
                    <label>Start Date</label>
                    <input type="text" class="form-control" formControlName="quarterStartDate" readonly>
                </div>
                <div class="col-lg-3 form-group">
                    <label>End Date</label>
                    <input type="text" class="form-control" formControlName="quarterEndDate" readonly>
                </div>
            </div> 

            <div class="row justify-content-start mb-5">
                <div class="col-lg-8">
                    <div class="row justify-content-start">
                        <div class="col-lg-2">
                            <button class="btn btn-primary full-width"  type="submit" [disabled]="addCyberSecurityForm.invalid" (click)="onSubmit()">Submit</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn  btn-cancel full-width" (click)="reset()" >Reset</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-cancel full-width" type="button"
                                (click)="back()">Back</button>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
    </div>
</div>
