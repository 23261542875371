import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login.service';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import * as bcrypt from 'bcryptjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/customValidators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.css']
})
export class ForgotpassComponent implements OnInit {
  resetForm: FormGroup;
  config: any = config;
  message: string;
  passwordPattern: string;
  passwordPolicy: string;
  otp: string;
  username: string;
  fieldTextType: any = [false, false];
  //fieldTextType:boolean=false;
  userPattern:string;
  

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      if (params['otp']) {
        this.otp = params['otp'];
      }
      if (params['username']) {
        this.username = params['username'];
      }
    });
  }

  get f() { return this.resetForm.controls; }

  ngOnInit(): void {
    this.titleService.setTitle("Reset Password")
    this.resetForm = this.fb.group({
      username: [localStorage.getItem('username'), Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: CustomValidators.match('newPassword', 'confirmPassword')
    });

    this.passwordPattern = this.config.data.passwordPattern;
    this.passwordPolicy = this.config.data.passwordPolicy;
    this.userPattern = this.config.data.userPattern;
  }

  onSubmit() {
    if (!this.resetForm.valid) {
      return;
    }
    let salt = atob(localStorage.getItem(btoa('salt')));
    let encryptedPass = bcrypt.hashSync(this.f.newPassword.value, salt);
    this.f.newPassword.setValue(encryptedPass);
    this.f.confirmPassword.setValue(encryptedPass);
    let forgotPasswordRequest: any = {
      "loginId": this.f.username.value,
      "password": this.f.newPassword.value,
      "otp": this.otp
    };
    this.loginService.forgotPassword(this.config.URL.forgotPasswordURL, forgotPasswordRequest).pipe(
      catchError((error: any) => {
        this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
        this.resetForm.reset();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message) {
        switch (response.message) {
          case "SUCCESSFULL": {
            this.toastr.success("Password updated successfully");
            this.router.navigate(['/login'], { "queryParams": { 'message': response.message } });
            break;
          }
          case "invalidCred": {
            this.toastr.error(this.config.failureResponse.login[response.message]);
            this.message = this.config.failureResponse.login[response.message];
            this.reset();
            break;
          }
          case "PASSWORD_PRESENT_IN_HISTORY": {
            this.toastr.error(this.config.failureResponse.login[response.message]);
            this.message = this.config.failureResponse.login[response.message];
            this.reset();
            break;
          }
          default: {
            this.toastr.error(this.config.failureResponse.login["SERVICE_UNAVAILABLE"]);
            this.message = this.config.failureResponse.login["SERVICE_UNAVAILABLE"];
            this.reset();
            break;
          }
        }
      }
    });
  }

  reset() {
    this.f.newPassword.reset();
    this.f.confirmPassword.reset();
  }

  toggleFieldTypeTextTrue(id) {
    this.fieldTextType[id] = true;
  }
  toggleFieldTypeTextFalse(id) {
    this.fieldTextType[id] = false;
  }

}
