<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"> <a routerLink="/eTrade/eNNF/displayAlgoUndertakingDetails"
                        aria-current="page">Algo Undertaking</a></li>

                </ul>
            </div>

        </div>
    </div>
    <div>

        <form [formGroup]="undertakingalgodetails" (ngSubmit)="onSubmit()"  >
            <fieldset>
                <div class="card card-body col-lg-12 mt-3 ">

                    <h5>
                        Algo Undertaking
                    </h5>
                  
                    <div class="row" *ngIf="!ifApproved"  >
                        <div class="col-lg-3 form-group">
                            <label>Name Of Authorised Signatory<span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="AuthName" >
                            
                        </div>
                        <div class="col-lg-3 form-group" >
                            <label>Designation Of Authorised Signatory <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="AuthDesign"   >
                           
                        </div>
                    </div>

                    <div class="row" *ngIf="ifApproved"  >
                        <div class="col-lg-3 form-group">
                            <label>Name Of Authorised Signatory<span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="AuthName" readonly>
                            
                        </div>
                        <div class="col-lg-3 form-group" >
                            <label>Designation Of Authorised Signatory <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="AuthDesign" readonly>
                           
                        </div>
                    </div>
                    
                   
                    <h6>
                        Algo Undertaking
                    </h6>

                    <div>
                        <table class="table table-bordered" >
                            <tr>
                                <th>Sr.no</th>
                                <th>I/We hereby undertake to comply with the below provisions as specified by SEBI /
                                    Exchange</th>
                                <th> Check </th>

                            </tr>
                            <tr *ngFor="let data of algoList">
                                <td>{{data.refValue}} </td>
                                <td>{{data.refDesc}}</td>
                                <td>
                                    <input type="checkbox" *ngIf="!ifApproved" id="checkBox{{data.refValue}}" formControlName="checkBox{{data.refValue}}"  required>
          

                                    <svg *ngIf="ifApproved" 
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="fillColor bi bi-check-square-fill fillColorGreen" viewBox="0 0 16 16">
                                        <path
                                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                    </svg>

                                   
                                </td>
                               
                            </tr>
                          
                            
                        </table>

                    </div>
                   
                    
                    <!-- Modal -->
                   

                 
                <div 
                  class="modal fade"
                  id="myModal"
                  tabindex="-1" role="dialog" 
                  aria-labelledby="exampleModalLabel" (click)="openFormModal()"  aria-hidden="true"  >
                  <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Validation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" routerLink="">
                            <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div class="modal-body">
                        Kindly note as per SEBI guidelines a BMC of 75$ is required to be maintained for Algo trading members. 
                            Hence kindly contact membership department @ 022-26598248 to modify your BMC to allow Algo trading.
                      </div>
                      <div class="modal-footer">
                        <button type="button"  class="btn btn-primary" data-bs-dismiss="modal"  (click)="closeModal()" >
                          Ok
                        </button>
                       
                      </div>
                    </div>
                  </div>
                </div>







                    <br>
                    <div class="row justify-content-start mb-5" *ngIf="!ifApproved">
                        <div class="col-lg-8">
                            <div class="row justify-content-start">
                                <div class="col-lg-2">
                                    <button class="btn btn-primary full-width"  [disabled]="undertakingalgodetails.invalid" type="submit">Submit</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn  btn-primary full-width" type="reset">Reset</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </fieldset>
        </form>
    </div>

</div>