import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LesRoutingModule } from './les-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { TreeviewModule } from 'ngx-treeview';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LesOperationScreenComponent } from './les-operation-screen/les-operation-screen.component'


@NgModule({
  declarations: [
    LesOperationScreenComponent,
  ],
  imports: [
    CommonModule,
    LesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxSpinnerModule,
    BsDatepickerModule,
    TreeviewModule.forRoot(),
  ],

})
export class LesModule { }