import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserDetails } from 'src/app/valueObject/userDetails';
import { DigiSignServiceService } from "./service/digi-sign-service.service";


/* AUTHOR : SIDDHANT SHINDE - SEPT/2022*/

declare var _elk_desksignObj: any;

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.css'],
  providers: [DigiSignServiceService]
})
export class DigitalSignatureComponent implements OnInit {
  myObj = _elk_desksignObj;
  resellerCode : number = 224;
  strInput : string = "this is a strInput...."
  issuerDN : any ;
  subjectDN : any;
  userDetailsObj: any={}
  DScertifyFlag: boolean = false;
  
  data : any ={
      isFormSignedFlag : null,
      signedFormModule : null,
      formData:null,
      sifySignData:null,
      dealerID : null,
      dealerMemID : null,
      signedFormId: 1
  }
  
  

  constructor(private digiSignServive:DigiSignServiceService) {
    
  }

  ngOnInit(): void {
    alert("onInIt was called......")
    this.myObj._elk_initialize(this.mycallback.bind(this))
  }

  getName() {
    alert('getName was called....');
    this.myObj.SignDataInMemoryEx(this.strInput, "", false, true, 0, "", "", "", "", false, 0, this.resellerCode);
    alert(this.myObj._elk_connectionOpen);
  }

  myFunc(){
   
    // console.log(this.readFormData());
    console.log("_elk_connectionOpen status : " +this.myObj._elk_connectionOpen)
    this.myObj._elk_check_connection()
    this.myObj.SignDataInMemoryEx(this.strInput, "", false, true, 0, "", "", "", "", false, 0, this.resellerCode);
  }

  mycallback(apiName: any, status:any, params:any) {

    /***** This is the API completion callback.******/

    var retVal = "";
    var myDScertifyFlag = false;
    
    console.log("apiName : "+apiName)
    console.log("status : "+status)
    console.log(params)
    console.log(params.sig)
    console.log(params.statusCode)
    console.log(status.toUpperCase());
    
    status = status.toUpperCase();

    
    if(apiName == "SignDataDetached" || apiName == "SignDataInMemoryEx"){
      var SifySignData = params.sig;
      
      if(status == "SUCCESS"){ //status == "SUCCESS"
      // if(true){
        
        //document.getElementById("newElock").value = "Y";
        //alert("Form Signing "+status+"\n !! Kindly Submit The Form");
        //formValidationAndCertification();
        //return true;

        myDScertifyFlag = true;
        console.log("STATUS CERTIFY FLAG : "+myDScertifyFlag);

        this.data.isFormSignedFlag=myDScertifyFlag;
        console.log(this.data.isFormSignedFlag);

        this.data.sifySignData=SifySignData;
        console.log(this.data.sifySignData);

        this.data.formData=this.readFormData();
        console.log(this.data.formData);
        
        this.data.signedFormModule=localStorage.getItem('signedFormModule');
        localStorage.removeItem('signedFormModule')
        console.log("MODULE : "+this.data.signedFormModule)

        this.data.dealerID=localStorage.getItem('dealerID')
        localStorage.removeItem('dealerID')
        console.log("DEALER ID  : "+this.data.dealerID)

        console.log(this.data);

        var myUserDetailsObj= localStorage.getItem('userDetails')
        this.userDetailsObj =JSON.parse(myUserDetailsObj);
        this.data.dealerMemID=this.userDetailsObj.entityCode;
        console.log(this.data.dealerMemID);
        

        /*******SEND DATA TO BACKEND******/

        if (this.data!=null || this.data.isFormSignedFlag ==true || this.data.sifySignData!= null || this.data.formData!=null || this.data.signedFormId != null) {
          console.log("ENTERED DIGISIGN SERVICE....")
          this.sendSignedData(this.data).subscribe(
            response=>{
              console.log(response);
            },
            error=>{
              console.log(error); 
            }
          );
        } else {
          alert("Form Signing Failed \n Error: " + status)
        }
    
      }else{
        
        //alert("Form Signing Failed \n Error: " + status);
        //formValidationAndCertification();
        //return false;
        
      }
      //alert("Form Signing Completed !! Kindly Submit The Form");
    }
    else if(apiName == "GetIssuerDN")
    {
      this.issuerDN = params.dn;
    }
    else if(apiName == "GetSubjectDN")
    {
      this.subjectDN = params.dn;
    }
  }

  SignDataNew(inputForm : FormGroup){
    
    // this.data.dealerID=inputForm.controls.amount.value;
    this.data.signedFormModule=inputForm.controls.formModule.value;
    console.log("test form ID......"+this.data.signedFormId)
    //this.data.signedFormModule.signedFormId = inputForm.controls.formModule.value
    console.log("test form ID......"+this.data.signedFormModule.signedFormId)


	  var tempStrInput = this.readFormData();
    console.log("TEST tempStrInput 1 : "+tempStrInput)

	  // if (inputForm.valid) {
      if (true) {
			
	      var nServerCode = 231;
	      var resellerCode = 224;//113
	      var strInput = tempStrInput.replace(/&/g,'AND');
	      strInput = strInput.replace(/\\/g, "\\\\").replace(/\"/g, "\\\""); 

        console.log("TEST tempStrInput 2 : "+tempStrInput);

        //localStorage.setItem('dealerID',inputForm.controls.amount.value);
        localStorage.setItem('signedFormModule',inputForm.controls.formModule.value);

	      try {
	    	 
	    	  var FormData=strInput;
          console.log(this.myObj._elk_connectionOpen)
		      if(this.myObj._elk_connectionOpen == true){
            console.log(this.myObj._elk_connectionOpen)
	    	  	this.myObj.SignDataInMemoryEx(FormData, "", false, true, 0, "", "", "", "", false, 0, resellerCode);
	    	  } else {
			    //formValidationAndCertification();
		      }
		      
	      } catch(e) {
	    	  
	    	  //errorMsgFormSign("Error: " + e.description +", kindly install new E-Lock v4.7.5.7. provided");
	    	  //formValidationAndCertification(); 
	    	 
	      }
		
	    }else{
		  
		  // if(!$(".successMsg-popup").is(":visible") && !$(".successMsg-popupCustom").is(":visible")){ 
			//     errorMsgFormSign("Kindly enter all fields in correct format");
		  //   }
      console.log("ERROR")
	    }
  }

  readFormData(){
    var FormData="";
    $(".formSignClass select:enabled").each(function(){
      FormData += $(this).val()+"-";
    });	
    $(".formSignClass input:text:enabled").each(function(){
      FormData += $(this).val()+"-";
    });
    FormData = FormData.replace(/\\/g, "\\\\"); 
    FormData = FormData.replace(/\&/g,'AND'); 
    return FormData;
  }

  sendSignedData(signedDataObj : any){
    return this.digiSignServive.sendDigiFormData(signedDataObj)
  }
  
}

