<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>LES RealTime Presence</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"><a routerLink="/les/lesOperation" aria-current="page">LES RealTime Presence</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div id="accordion" class="mb-1">
        <div class="card colorborder">
            <div class="card-header pointer" id="memberheading">
                <h6 data-toggle="collapse" data-target="#collapseFilter" aria-expanded="true"
                    aria-controls="collapseFilter">
                    <i class="fa fa-angle-down pull-right customIcon mr-2"></i>Advanced Search
                </h6>
            </div>
            <div id="collapseFilter" class="collapse show" aria-labelledby="memberheading" data-parent="#accordion">
                <form [formGroup]="searchForm">
                    <div class="row ml-2">
                        <div class="col-lg-3 form-group" *ngIf="enableIncentiveFiles">
                            <label>Client ID</label>
                            <select class="form-control selectpicker" formControlName="clientCode" data-style="btn-info"
                                title="Select Client ID">
                                <option *ngFor="let data of lesClientList" value="{{data}}">{{data}}</option>
                            </select>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>LES Schemes</label>
                            <select class="form-control selectpicker" formControlName="lesSchemes" data-style="btn-info"
                                title="Select LES Schemes">
                                <option *ngFor="let data of lesSchemeList" value="{{data.schemeCode}}">
                                    {{data.schemeLabel}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row ml-2">
                        <div class="col-lg-3 form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button class="btn btn-primary full-width" type="submit"
                                        (click)="getAllLesOprnlSchemeData()"
                                        [disabled]="searchForm.invalid">Search</button>
                                </div>
                                <div class="col-lg-6">
                                    <button class="btn btn-cancel full-width" (click)="onReset()">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="card card-body colorborder">
        <div class="row  data-table-lastupdate">
            <div class="pl-4 mb-2 ml-2">
                <h6> Date: <span>{{currentOrderDate}}</span></h6>
            </div>
        </div>
        <div class="table-responsive scrollable">
            <table id="lesRequest" class="table common-table nowrap" datatable [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width: 20px;">Sr. No.</th>
                        <th class="data-table-width-small">Member Code</th>
                        <th class="data-table-width-small">Client ID</th>
                        <th class="data-table-width-large">Contract Descriptor</th>
                        <th class="data-table-width-small">Strike Type</th>
                        <th class="data-table-width-small">Line Indicator</th>
                        <th class="data-table-width-large">Presence(%)</th>
                        <th class="data-table-width-small">Till Time</th>
                    </tr>

                    <tr class="column-search">
                        <th></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                        <th><input type="text" class="form-control form-control-sm" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of lesOperationalSchemeDataList; let i = index" class="pointer" color="blue">
                        <td>{{i+1}}</td>
                        <td>{{data.tmCode}}</td>
                        <td>{{data.clientCode}}</td>
                        <td>{{data.symbol}} {{data.expiryDate | date : 'dd-MMM-yyyy'}}
                            <span *ngIf="data.optType !='XX'">{{data.optType}} </span>
                            <span *ngIf="data.strikePrice !=-1.0">{{data.strikePrice}}</span>
                        </td>
                        <td>{{data.strikeType=="\"\"" || data.strikeType==null || data.strikeType=="" ? 'NA' :
                            data.strikeType}}</td>
                        <td>{{data.lineIndicator}}</td>
                        <td>{{data.mmPercent}}</td>
                        <td>{{data.endTime}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        &nbsp;
        <div *ngIf="enableIncentiveFiles">
            <div class="nav flex-row nav-tabs mt-2" id="v-pills-tab" role="tablist" aria-orientation="horizontal">
                <a class="nav-link fcc-btn active border col-lg-2" id="hourly" data-toggle="tab" href="#hourlyFile"
                    data-target="#hourlyFile" aria-controls="hourlyFile" aria-selected="true"
                    title="Navigate to forwards">Latest Presence File</a>
                <!-- <a class="nav-link fcc-btn border col-lg-2" id="daily" data-toggle="tab" href="#dailyFile"
                    data-target="#dailyFile" aria-controls="dailyFile" aria-selected="true"
                    title="Navigate to swaps">Daily Incentive File</a>
                <a class="nav-link fcc-btn border col-lg-2" id="historical" data-toggle="tab" href="#historicalFile"
                    data-target="#historicalFile" aria-controls="historicalFile" aria-selected="true"
                    title="Navigate to swaps">Historical Daily
                    Incentive File</a> -->
                    
                <!-- <a class="nav-link fcc-btn border col-lg-2" id="monthaly" data-toggle="tab" href="#monthalyFile"
                    (click)="filterHistoricalData()" data-target="#monthalyFile" aria-controls="monthalyFile"
                    aria-selected="true" title="Navigate to swaps">Monthaly
                    Incentive File</a> -->
            </div>

            <div class="tab-content ">
                <div class="tab-pane fade show active" id="hourlyFile" aria-labelledby="hourlyFile">
                    <div class="card card-body colorborder">
                        <div class="row justify-content-center">
                            <h2>Latest Presence File</h2>
                        </div>
                        <div>
                            <div>
                                <div class="row" *ngFor="let data of listHourlyMemberFiles,let i = index">
                                    <div class="col-lg-6 mt-2">
                                        <div class="arrow-cursor" data-toggle="tooltip" data-placement="bottom"
                                            title="Download {{data.fileName}} File"
                                            (click)="downloadHourlyAndDailyFile(data.fileName,'HOURLY')">
                                            {{i+1}}. <u style="color:#007bff">{{data.systemFileName}}</u></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade " id="dailyFile" aria-labelledby="dailyFile">
                    <div class="card card-body colorborder">
                        <div class="row justify-content-center">
                            <h2>Daily Incentive File</h2>
                        </div>
                        <div>
                            <div>
                                <div class="row" *ngFor="let data of listDailyMemberFiles,let i = index">
                                    <div class="col-lg-6 mt-2">
                                        <div class="arrow-cursor" data-toggle="tooltip" data-placement="bottom"
                                            title="Download {{data.fileName}} File"
                                            (click)="downloadHourlyAndDailyFile(data.fileName,'DAILY')">
                                            {{i+1}}. <u style="color:#007bff">{{data.systemFileName}}</u></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade " id="historicalFile" aria-labelledby="historicalFile">
                    <div class="card card-body colorborder">
                        <div class="row justify-content-center">
                            <h2>Historical Daily Incentive File</h2>
                        </div>
                        &nbsp;
                        <div>
                            <div>
                                <form [formGroup]="historicalSearchForm">
                                    <div class="row">
                                        <div class=" col-lg-3 input-group">
                                            <input type="text" class="form-control datepicker date"
                                                #dp="bsDaterangepicker" bsDaterangepicker [(bsValue)]="bsValue"
                                                [bsConfig]="{rangeInputFormat:'DD-MMM-YYYY', displayMonths:1}"
                                                [minDate]="firstDay" [maxDate]="lastDay"
                                                formControlName="incentiveDate">
                                            <div class="input-group-append datepicker cursor">
                                                <span class="input-group-text">
                                                    <i class="fa fa-calendar pointer" [(bsValue)]="bsValue"
                                                        bsDaterangepicker
                                                        [bsConfig]="{ rangeInputFormat:'DD-MMM-YYYY', displayMonths:1}"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-1">
                                            <button class="btn btn-primary full-width" type="submit"
                                                (click)="getHourlyAndDailyFileRecord()"
                                                [disabled]="historicalSearchForm.invalid">Search</button>
                                        </div>
                                        <div class="col-lg-1">
                                            <button class="btn btn-cancel full-width"
                                                (click)="historicalDataReset()">Reset</button>
                                        </div>
                                    </div>
                                </form>
                                &nbsp;
                                &nbsp;
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="table-responsive scrollable">
                                            <table id="historicalData" class="table common-table nowrap" datatable
                                                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Historical Daily Incentive File</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of HistoricalDailyFileList; let i = index"
                                                        class="pointer" color="blue">
                                                        <td>{{i+1}}</td>
                                                        <td (click)="downloadHourlyAndDailyFile(data.systemFileName,'HISTORICAL')"
                                                            title="Download File">
                                                            <u style="color:#007bff">{{data.systemFileName}}</u>
                                                        </td>
                                                        <td>{{data.fileDate | date}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- <div class="tab-pane fade " id="monthalyFile" aria-labelledby="monthalyFile">
                    <div class="card card-body colorborder">
                        <div class="row justify-content-center">
                            <h2>Monthaly Incentive File</h2>
                        </div>
                        &nbsp;
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="table-responsive scrollable">
                                        <table id="historicalData" class="table common-table nowrap" datatable
                                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Monthaly Incentive File</th>
                                                    <th>Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of HistoricalDailyFileList; let i = index"
                                                    class="pointer" color="blue">
                                                    <td>{{i+1}}</td>
                                                    <td (click)="downloadDailyIncentiveFile(data.subDate, data.systemFileName)"
                                                        color="blue" title="Download File">
                                                        {{data.systemFileName}}
                                                    </td>
                                                    <td>{{data.subDate | date}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
</div>