
<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Quarterly Incident Report</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active" routerLink="eCompliance/incidentReport/incidentQuaterlyReptSubm"><a aria-current="page">Quarterly Incident Report</a></li>

                </ul>
            </div>

        </div>
    </div>


    <div class="container-fluid mt-3 mb-3 theme">
        <div class="card card-body col-lg-12 mt-3 colorborder">
            <form [formGroup]="quaterlyForm">
                <h6>Letter/ Report Subject-Quarterly Incident Reporting</h6>
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label for="memberName">Name of the Member</label>
                        <input class="form-control" formControlName="memberName" id="memberName"
                            [readonly]="isReadOnly">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="stockExchangeName">Name of Stock Exchange</label>
                        <input type="text" class="form-control" formControlName="stockExchangeName"
                            id="stockExchangeName" [readonly]="isReadOnly">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="memberId">Member ID</label>
                        <input type="text" class="form-control" formControlName="memberId" id="memberId"
                            [readonly]="isReadOnly">
                    </div>
                </div>
                <hr>
                <h6>Reporting Periodicity</h6>
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <input type="text" class="form-control" formControlName="quaterName" [readonly]="isReadOnly">
                    </div>
                </div>
                <hr>


                <h6>Designated Officer (Reporting Officer details)</h6>
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label for="rptOfficerName">Name</label>
                        <input type="text" formControlName="rptOfficerName" id="rptOfficerName" class="form-control">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="rptOfficerOrgName">Organization</label>
                        <input type="text" formControlName="rptOfficerOrgName" id="rptOfficerOrgName"
                            class="form-control">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="rptOfficerTitle">Title</label>
                        <input type="text" formControlName="rptOfficerTitle" id="rptOfficerTitle" class="form-control">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="rptOfficerFaxPhoneNum">Phone/Fax No</label>
                        <input formControlName="rptOfficerFaxPhoneNum" id="rptOfficerFaxPhoneNum" class="form-control">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="rptOfficerMobileNum">Mobile</label>
                        <input formControlName="rptOfficerMobileNum" id="rptOfficerMobileNum" class="form-control">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label for="rptOfficerEmail">Email</label>
                        <input type="email" formControlName="rptOfficerEmail" id="rptOfficerEmail" class="form-control">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label for="rptOfficerAddress">Address</label>
                        <input type="text" formControlName="rptOfficerAddress" id="rptOfficerAddress"
                            class="form-control">
                    </div>
                </div>



                <hr>
                <h6>Cyber-attack / breach observed in Quarter</h6>
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <input type="radio" id="cyberAttack"
                            formControlName="cyberAttackObserved" value="Y"><label class="radio-inline"
                            for="cyberAttack" (change)="onCyberCheck()" >Yes</label>
                        <input type="radio" id="cyberAttack"
                            formControlName="cyberAttackObserved" (change)="onCyberCheck()" value="N"><label class="radio-inline"
                            for="cyberAttack">No</label>
                    </div>
                </div>
                <hr>
                <h6>Brief Information</h6>

                <ng-template #checkBoxTemplate>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <input type="checkbox" formControlName="nillSubmission" id="nillSubmission"
                                (change)="onNillSubmission($event)" checked="false"
                                ><label for="nillSubmission"
                                > Nil Submission</label>
                        </div>
                    </div>
                </ng-template>
                <ng-container *ngIf="f.cyberAttackObserved.value=='Y';else checkBoxTemplate">

                    <div class="row">
                        <table class="table table-stripped common-table bordered ml-3 mr-3">
                            <thead>
                                <tr>
                                    <th class="col-lg-1">Sr No.</th>
                                    <th>Date</th>
                                    <th>Time (HH:MM:SS)</th>
                                    <th>Brief Information on the Cyber-attack / breached observed</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="briefInfoTable">
                                <ng-container  *ngFor="let incident of quaterlyForm.controls.briefInfoTable.controls; let i = index"
                                [formGroupName]="i">
                                <tr >
                                    <td>
                                    </td>
                                    <td><input class="form-control datepicker" formControlName="incidentDate"
                                            id="incidentDate" bsDatepicker
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY'}">
                                    </td>
                                    <td><input type="time" formControlName="incidentTime" id="incidentTime"
                                            class="form-control" step="2"></td>
                                    <td>
                                        <input formControlName="cyberAttackBriefInfo" id="cyberAttackBriefInfo"
                                            class="form-control" type="text">
                                    </td>
                                    <td >
                                        <i *ngIf="(i+1)<quaterlyForm.controls.briefInfoTable.controls.length" class="fa fa-trash fa-x" (click)="deleteIncidentBriefInfo(i)"></i>
                                        <i *ngIf="(i+1)==quaterlyForm.controls.briefInfoTable.controls.length" class="fa fa-plus fa-x" (click)="addIncidentBriefInfo()"></i>
                                    </td>

                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <hr>
                    <hr>
                    <h6 style="text-align: center;">Annexure 1</h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <h6>Physical location of affected computer / network and name of ISP</h6>
                            <textarea type="text" maxlength="4000" class="form-control"
                                formControlName="affectedSystemPhysicalLocation"
                                id="affectedSystemPhysicalLocation"></textarea>
                        </div>
                    </div>

                    <hr>
                    <h6>Date and time incident occured</h6>
                    <div class="row">
                        <table class="table table-stripped common-table bordered ml-3 mr-3">
                            <thead>
                                <tr>
                                    <th class="col-lg-1">Sr No.</th>
                                    <th>Date</th>
                                    <th>Time (HH:MM:SS)</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="dateAndTimeTable">
                                <ng-container  *ngFor="field of quaterlyForm.controls.dateAndTimeTable.controls; let i = index"
                                [formGroupName]="i">
                                <tr>
                                    <td>
                                    </td>
                                    <td><input class="form-control datepicker" formControlName="incidentDate"
                                            id="incidentDate" bsDatepicker
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM DD, YYYY', maxDate: today}">
                                    </td>
                                    <td><input type="time" formControlName="incidentTime" id="incidentTime"
                                            class="form-control" step="2"></td>
                                    <td >
                                        <i *ngIf="(i+1)<quaterlyForm.controls.dateAndTimeTable.controls.length" class="fa fa-trash fa-x" (click)="deleteDate(i)"></i>
                                        <i *ngIf="(i+1)==quaterlyForm.controls.dateAndTimeTable.controls.length" class="fa fa-plus fa-x" (click)="addDate()"></i>
                                    </td>
                                </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <h6>Information of affected System</h6>
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-stripped common-table bordered">
                                <thead>
                                    <tr>
                                        <th class="col-lg-1">Sr No.</th>
                                        <th>IP Address</th>
                                        <th>Computer / Host Name</th>
                                        <th>Operating System(incl. Ver/release No.)</th>
                                        <th>Last Patched / Updated</th>
                                        <th>Hardware Vendor/ Model</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="infoAffectedSystemTable" >
                                    <ng-container *ngFor="let field of quaterlyForm.controls.infoAffectedSystemTable.controls; let i = index"
                                    [formGroupName]="i">
                                    <tr >
                                        <td>

                                        </td>
                                        <td>
                                            <input id="ipAddress" formControlName="ipAddress" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input id="hostName" formControlName="hostName" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input id="operatingSystem" formControlName="operatingSystem" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input id="lastPatched" formControlName="lastPatched" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input id="hardwareModel" formControlName="hardwareModel" class="form-control" type="text">
                                        </td>
                                        <td >
                                            <i *ngIf="(i+1)<quaterlyForm.controls.infoAffectedSystemTable.controls.length" class="fa fa-trash fa-x" (click)="deleteInfoAffectedSystem(i)"></i>
                                            <i *ngIf="(i+1)==quaterlyForm.controls.infoAffectedSystemTable.controls.length" class="fa fa-plus fa-x" (click)="addInfoAffectedSystem()"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr>
                    <h6>Type of Incident</h6>
                    <div class="col-lg-12">
                        
                        <div class="row" formArrayName="incidentForm">
                            <div class="col-lg-4"
                                *ngFor="let incident of quaterlyForm.controls.incidentForm.controls;let i=index;" [formGroupName]="i">
                                <input type="checkbox" [checked]="incidentType[i].selected||false"
                                (change)="getSelectedIncidentValues(incidentType[i].value); selectOtherIncident();">
                                    <!-- (change)="getSelectedIncidents(incident,incidentType[i].name); selectOtherIncident();"
                                    [checked]="getSelectedIncidentValues(incidentType[i].value)"> -->
                                <label>{{incidentType[i].name}}</label>
                            </div>
                        </div>
                    </div>
                    <input type="text" formControlName="incidentOtherDescr" *ngIf="hasOtherIncident" class="form-control col-lg-12">

                    <hr>
                    <h6>Description of incident</h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <textarea formControlName="incidentDescr" id="incidentDescr" type="textbox"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <hr>
                    <h6>Unusual behaviour / symptoms (Tick the Symptoms)</h6>
                    <div class="col-lg-12">
                        <div formArrayName="behaviourForm">
                        <div class="row"
                            *ngFor="let behaviour of quaterlyForm.controls.behaviourForm.controls;let i=index;" [formGroupName]="i">
                            <div class="col-lg-12">
                                <input type="checkbox" name="behavior" [checked]="incidentSymptoms[i].selected||false"
                                (change)="getSelectedBehaviourValues(incidentSymptoms[i].value); selectOtherBehaviour();">
                                    <!-- (change)="getSelectedBehaviours(behaviour,incidentSymptoms[i].name); selectOtherBehaviour();"
                                    [checked]="getSelectedBehaviourValues(incidentSymptoms[i].value)"> -->
                                <label for="behavior">{{incidentSymptoms[i].name}}</label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <input type="text" formControlName="incidentSymptomsOtherDescr" *ngIf="hasOtherBehaviour" class="form-control col-lg-12">

                    <hr>
                    <h6>Details of Unusual behavior / symptoms</h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <textarea formControlName="incidentSymptomsDescr" class="form-control"></textarea>
                        </div>
                    </div>

                    <hr>
                    <h6>Has this problem been experienced earlier? If yes, details</h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <input type="radio" formControlName="problemExprEarlier"
                                name="problemExprEarlier" id="ProblemExpYes" value="Y"><label for="ProblemExpYes"
                                class="radio-inline">Yes</label>
                            <input type="radio" formControlName="problemExprEarlier"
                                name="problemExprEarlier" id="ProblemExpNo" value="N"><label for="ProblemExpNo"
                                class="radio-inline">No</label>
                            <ng-container *ngIf="f.problemExprEarlier.value=='Y';">
                                <textarea formControlName="problemExprEarlierDescr" class="form-control"></textarea>
                            </ng-container>
                        </div>
                    </div>

                    <hr>
                    <h6>
                        Agencies notified
                    </h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <input type="radio" formControlName="agencyNotified"
                                name="agencyNotified" id="agenciesNotifyYes" value="Y">
                            <label class="radio-inline" for="agenciesNotifyYes">Yes</label>
                            <input type="radio" formControlName="agencyNotified"
                                name="agencyNotified" id="agenciesNotifyNo" value="N">
                            <label class="radio-inline" for="agenciesNotifyNo">No</label>
                        </div>
                    </div>
                    <ng-container *ngIf="f.agencyNotified.value=='Y';">
                        <div class="row">
                            <table class="table table-stripped common-table bordered ml-3 mr-3">
                                <thead>
                                    <tr>
                                        <th class="col-lg-1">Sr No.</th>
                                        <th>Law Enforcement</th>
                                        <th>Private Agency</th>
                                        <th>Affected Product Vendor</th>
                                        <th>Other</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="infoAgencyNotifyTable">
                                    <ng-container *ngFor="let field of quaterlyForm.controls.infoAgencyNotifyTable.controls; let i = index"
                                    [formGroupName]="i">
                                    <tr >
                                        <td>
                                        </td>
                                        <td>
                                            <input formControlName="lawEnforcement" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input formControlName="privateAgency" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input formControlName="affectedProductVendor" class="form-control" type="text">
                                        </td>
                                        <td>
                                            <input formControlName="otherAgency" class="form-control" type="text">
                                        </td>
                                        <td >
                                            <i *ngIf="(i+1)<quaterlyForm.controls.infoAgencyNotifyTable.controls.length" class="fa fa-trash fa-x" (click)="deleteAgencyNotifiedSytem(i)"></i>
                                            <i *ngIf="(i+1)==quaterlyForm.controls.infoAgencyNotifyTable.controls.length" class="fa fa-plus fa-x" (click)="addAgencyNotifiedSytem()"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>


                    <hr>
                    <h6>
                        IP address of apparent or suspected source
                    </h6>
                    <div class="row">
                        <table class="table table-stripped common-table bordered ml-3 mr-3">
                            <thead>
                                <tr>
                                    <th class="col-lg-1">Sr No.</th>
                                    <th>Source IP Address</th>
                                    <th>Other information available</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="ipSuspectedSourceTable">
                                <ng-container *ngFor="let field of quaterlyForm.controls.ipSuspectedSourceTable.controls; let i = index"
                                [formGroupName]="i">
                                <tr >
                                    <td>

                                    </td>
                                    <td>
                                        <input formControlName="sourceIp" class="form-control" type="text">
                                    </td>
                                    <td>
                                        <input formControlName="otherInfo" class="form-control" type="text">
                                    </td>
                                    <td>
                                        <i *ngIf="(i+1)<quaterlyForm.controls.ipSuspectedSourceTable.controls.length" class="fa fa-trash fa-x" (click)="deleteIncidentSuspectSource(i)"></i>
                                        <i *ngIf="(i+1)==quaterlyForm.controls.ipSuspectedSourceTable.controls.length" class="fa fa-plus fa-x" (click)="addIncidentSuspectSource()"></i>
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <hr>
                    <h6>
                        How many host(s) are affected
                    </h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <input type="radio" formControlName="numberOfHostAffected" name="numberOfHostAffected"
                                id="numberOfHostAffected" value="0"><label for="numberOfHostAffected"
                                class="radio-inline">0</label>
                            <input type="radio" formControlName="numberOfHostAffected" name="numberOfHostAffected"
                                id="numberOfHostAffected" value="1-10"><label for="numberOfHostAffected"
                                class="radio-inline">1 to
                                10</label>
                            <input type="radio" formControlName="numberOfHostAffected" name="numberOfHostAffected"
                                id="numberOfHostAffected" value="10-100"><label for="numberOfHostAffected"
                                class="radio-inline">10 to
                                100</label>
                            <input type="radio" formControlName="numberOfHostAffected" name="numberOfHostAffected"
                                id="numberOfHostAffected" value="moreThan100"><label for="numberOfHostAffected"
                                class="radio-inline">More than
                                100</label>
                        </div>
                    </div>

                    <hr>
                    <h6>Where any Action has been taken for mitigation and any preventive measures applied ?</h6>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <input type="radio" formControlName="preventiveMeasureApplied"
                                name="preventiveMeasureApplied" value="Y"><label for="preventiveMeasureApplied"
                                class="radio-inline">Yes</label>
                            <input type="radio" formControlName="preventiveMeasureApplied"
                                name="preventiveMeasureApplied" value="N"><label for="preventiveMeasureApplied"
                                class="radio-inline">No</label>
                            <ng-container *ngIf="f.preventiveMeasureApplied.value=='Y';">
                                <textarea formControlName="preventiveMeasureAppliedDescr"
                                    class="form-control"></textarea>
                            </ng-container>
                        </div>
                    </div>


                </ng-container>

                <!-- <div class="text-center" style="text-align: center;">
                    <button class="btn btn-primary" type="submit" (click)="reset()">Reset</button>
                </div> -->

                <div class="text-center" style="text-align: center;">
                    <button routerLink="/" class="btn btn-primary" type="submit" (click)="saveQuaterlyForm()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="enableData" tabindex="-1" role="dialog" aria-labelledby="enableData" routerLink="/"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">Quarterly Incident Report</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>Member not enable for this quarter</h5>
                        </div>
                    </div>
                </div>
           
        </div>
    </div>
</div>

<div class="modal fade" id="cutOffdate" tabindex="-1" role="dialog" aria-labelledby="cutOffdate"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" routerLink="/" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">Quarterly Incident Report</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>Cut-Off date for the quarter has passed.</h5>
                        </div>
                    </div>
                </div>
           
        </div>
    </div>
</div>

<div class="modal fade" id="submitted" tabindex="-1" role="dialog" aria-labelledby="submitted"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close" routerlink="/">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">Quarterly Incident Report</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>You have already submitted data for this quarter</h5>
                        </div>
                    </div>
                </div>
           
        </div>
    </div>
</div>