import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { config } from 'src/app/valueObject/config';
import { Subscription, timer } from 'rxjs';
import { AppService } from 'src/app/service/app.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  submitted = false;
  message: string;
  config: any = config;
  resendDisable: boolean = false;
  subscription: Subscription;
  timeVal: number;
  page: string;
  otpForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      if (params['page']) {
        this.page = params['page'];
      }
    });
  }

  get f() { return this.otpForm.controls; }

  ngOnInit(): void {
    this.otpForm = this.formBuilder.group({
      otp: [null, Validators.required],
    });

    this.generateOTP();
  }

  generateOTP() {
    let requestObject = {
      "loginId": localStorage.getItem('username')
    };
    if (this.page == 'login') {
      this.loginService.generateOTPToken(this.config.URL.generateOTPTokenURL, requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode && response.statusCode === 200) {
          this.message = this.config.successResponse.login[response.message];
          this.resendDisable = true;
          this.startResendTimer(2 * 60);
          setTimeout(() => {
            this.resendDisable = false;
            this.stopTimer();
          }, 2 * 60 * 1000);
        } else {
          if (response.message == 'OTP_DISABLED_TEMPORARYLY') {
            let disableTime = response.data[0].split("_")[4];
            this.resendDisable = true;
            this.startResendTimer(disableTime);
            setTimeout(() => {
              this.resendDisable = false;
              this.stopTimer();
            }, disableTime * 1000);
            this.toastr.error(this.config.failureResponse.login.OTP_DISABLED_TEMPORARYLY);
          } else {
            this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          }
        }
      });
    } else {
      this.loginService.generateOTP(this.config.URL.generateOTPURL, requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode && response.statusCode === 200) {
          this.message = this.config.successResponse.login[response.message];
          this.resendDisable = true;
          this.startResendTimer(2 * 60);
          setTimeout(() => {
            this.resendDisable = false;
            this.stopTimer();
          }, 2 * 60 * 1000);
        } else {
          if (response.message == 'OTP_DISABLED_TEMPORARYLY') {
            let disableTime = response.data[0].split("_")[4];
            this.resendDisable = true;
            this.startResendTimer(disableTime);
            setTimeout(() => {
              this.resendDisable = false;
              this.stopTimer();
            }, disableTime * 1000);
            this.toastr.error(this.config.failureResponse.login.OTP_DISABLED_TEMPORARYLY);
          } else {
            this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          }
        }
      }
      );
    }
  }

  startResendTimer(time) {
    const source = timer(0, 1000);
    this.subscription = source.subscribe(val => {
      this.timeVal = time - val;
      if (this.timeVal === 0) {
        this.stopTimer();
      }
    })
  }

  stopTimer() {
    this.subscription.unsubscribe();
  }

  back() {
    this.appService.logout({}).pipe(
      catchError((error: any) => {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        this.router.navigate(['/login']);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message == "SUCCESSFULL") {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        this.router.navigate(['/login']);
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    if (!this.otpForm.valid) {
      return;
    }
    let requestObject = {
      "loginId": localStorage.getItem("username"),
      "otp": this.f.otp.value
    }

    this.loginService.validateOTP(this.config.URL.validateOTPURL, requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      let str = response.message;
      if (response.message) {
        let salt = str.substr(0, 29);
        let encryptedMessage = bcrypt.hashSync("SUCCESSFULL", salt);
        let encryptedOtp = "";
        if (response.data[0].length > 30)
          encryptedOtp = bcrypt.hashSync(this.f.otp.value, response.data[0].substr(0, 29));
        if (encryptedMessage == response.message && response.data[0] && encryptedOtp == response.data[0]) {
          if (this.page == 'login') {
            this.toastr.success("You have successfully logged in")
            this.router.navigate(['']);
          } else if (this.page == 'forgotPass') {
            this.router.navigate(['/login/forgotpassword'], { 'queryParams': { 'otp': this.f.otp.value } });
          } else if (this.page == 'accountLock') {
            let unlockObject = {
              "loginId": localStorage.getItem('username'),
              "otp": this.f.otp.value
            }
            this.loginService.unlockAccount(this.config.URL.unlockAccountURL, unlockObject).subscribe(
              (response: any) => {
                if (response.statusCode && response.statusCode === 200) {
                  this.toastr.success("Account unlocked successfully")
                  this.router.navigate(['/login']);
                }
              }
            )
          }
        } else {
          encryptedMessage = bcrypt.hashSync("UNAUTHORIZED_USER", salt)
          if (encryptedMessage == response.message) {
            this.message = "You are not authorized to perform this operation"
            this.toastr.error(this.message);
          }
          encryptedMessage = bcrypt.hashSync("OTP_FAILED", salt)
          if (encryptedMessage == response.message) {
            this.message = "Not able to send OTP. Please try again later"
            this.toastr.error(this.message);
          }
          encryptedMessage = bcrypt.hashSync("USER_DISABLED_MESSAGE", salt)
          if (encryptedMessage == response.message) {
            this.message = "OTP is disabled for this user."
            this.toastr.error(this.message);
          }
          encryptedMessage = bcrypt.hashSync("INVALID_OTP", salt)
          if (encryptedMessage == response.message) {
            this.message = "Invalid OTP"
            this.toastr.error(this.message);
          }
          encryptedMessage = bcrypt.hashSync("OTP_ALREADY_USED", salt)
          if (encryptedMessage == response.message) {
            this.message = "OTP_ALREADY_USED"
            this.toastr.error(this.message);
          }
          encryptedMessage = bcrypt.hashSync("SERVICE_UNAVAILABLE", salt)
          if (encryptedMessage == response.message) {
            this.message = "Service is not available. Please try again later."
            this.toastr.error(this.message);
          }
        }
      }
    }
    );
  }
}
