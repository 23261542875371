import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComponentComponent } from '../component.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { RoleComponent } from './role/role.component';


const routes: Routes = [
  { path: '', component: RoleComponent },
  { path: 'add-role', component: AddRoleComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolemanagementRoutingModule { } 
