import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from 'src/app/service/app.service';
import { MenuService } from 'src/app/service/menu.service';
import { config } from 'src/app/valueObject/config';
declare var $:any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  config: any = config;
  menuData: any = [];
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  viewUser: any = {};
  deleteMenuRecord: any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;


  constructor(private appService: AppService,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private titleService: Title,
    private menuService: MenuService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.titleService.setTitle('Menu Management');
  }

  ngAfterViewInit() {
    this.getMenuData();
  }

  getMenuData() {
    this.appService.getMenuList().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.menuData = [];
        this.setDatatable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.menuData = response.data;
          this.menuService.addMenu(this.menuData);
          this.setDatatable();
        } else {
          this.toastr.error(response.message);
          this.menuData = [];
          this.setDatatable();
        }
      }
    );
  }

  editMenu(id: number) {
    this.router.navigate(['add-menu'], { "queryParams": { "id": id }, relativeTo: this.route, skipLocationChange: true });
  }

  setDatatable(){
    $("#menuList").DataTable().destroy();
    setTimeout(() => {
      $("#menuList").DataTable({
        processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      buttons:[{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2],
          }
        }, {
          extend: 'pdf',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2],
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#menuList .column-search input').val('');
            $('#menuList .column-search select').val('.*');
            $('#menuList .column-search').on('keyup change', 'input', function () {
              $('#menuList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#menuList .column-search').on('keyup change', 'select', function () {
              $('#menuList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
