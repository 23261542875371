<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Edit Limit Setup</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li> <a routerLink="/cel/limitSetup" skipLocationChange aria-current="page">Limit Setup</a>
                    </li>
                    <li class="active">
                        <a routerLink="/cel/editLimitSetup" skipLocationChange aria-current="page">Edit Limit Setup</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <fieldset [attr.disabled]="action=='view'?true:null">
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label>Branch ID<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="branchId" placeholder="Enter Branch ID"
                            readonly>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Branch Name<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="branchName"
                            placeholder="Enter Branch Name" readonly>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>User ID<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="userId" placeholder="Enter User ID"
                            readonly>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>User Name<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="userName" placeholder="Enter User Name"
                            readonly>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label>Total Net Buy Credit for Futures<span class="red">*</span> </label>
                        <input type="number" class="form-control" formControlName="buyCreditFuture"
                            placeholder="Enter Buy Credit for Futeres">
                        <p *ngIf="uf.buyCreditFuture.errors?.required && uf.buyCreditFuture.touched"
                            class="error nocase">
                            Please enter buy credit for futures
                        </p>
                        <p *ngIf="uf.buyCreditFuture.errors?.max && uf.buyCreditFuture.touched" class="error nocase">
                            Amount should not be greater than 13 digits
                        </p>
                        <p *ngIf="uf.buyCreditFuture.errors?.min && uf.buyCreditFuture.touched" class="error nocase">
                            Amount should not be less than 0
                        </p>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Total Net Sell Credit for Futures<span class="red">*</span> </label>
                        <input type="number" class="form-control" formControlName="sellCreditFuture"
                            placeholder="Enter Sell Credit for Futures">
                        <p *ngIf="uf.sellCreditFuture.errors?.required && uf.sellCreditFuture.touched"
                            class="error nocase">
                            Please enter sell credit for futures
                        </p>
                        <p *ngIf="uf.sellCreditFuture.errors?.max && uf.sellCreditFuture.touched" class="error nocase">
                            Amount should not be greater than 13 digits
                        </p>
                        <p *ngIf="uf.sellCreditFuture.errors?.min && uf.sellCreditFuture.touched" class="error nocase">
                            Amount should not be less than 0
                        </p>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Total Net Buy Credit for Options Long<span class="red">*</span> </label>
                        <input type="number" class="form-control" formControlName="buyCreditOptLong"
                            placeholder="Enter Buy Credit for Options Long">
                        <p *ngIf="uf.buyCreditOptLong.errors?.required && uf.buyCreditOptLong.touched"
                            class="error nocase">
                            Please enter buy credit for options long
                        </p>
                        <p *ngIf="uf.buyCreditOptLong.errors?.max && uf.buyCreditOptLong.touched" class="error nocase">
                            Amount should not be greater than 13 digits
                        </p>
                        <p *ngIf="uf.buyCreditOptLong.errors?.min && uf.buyCreditOptLong.touched" class="error nocase">
                            Amount should not be less than 0
                        </p>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Total Net Sell Credit for Options Short<span class="red">*</span> </label>
                        <input type="number" class="form-control" formControlName="sellCreditOptShort"
                            placeholder="Enter Sell Credit for Options Short">
                        <p *ngIf="uf.sellCreditOptShort.errors?.required && uf.sellCreditOptShort.touched"
                            class="error nocase">
                            Please enter sell credit for options short
                        </p>
                        <p *ngIf="uf.sellCreditOptShort.errors?.max && uf.sellCreditOptShort.touched"
                            class="error nocase">
                            Amount should not be greater than 13 digits
                        </p>
                        <p *ngIf="uf.sellCreditOptShort.errors?.min && uf.sellCreditOptShort.touched"
                            class="error nocase">
                            Amount should not be less than 0
                        </p>
                    </div>
                </div>
                <table id="prodCoef" class="table common-table" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger" *ngIf="branchUserData.prodCoef">
                    <thead>
                        <tr>
                            <th rowspan="2" class="pre-wrap">Sr No</th>
                            <th rowspan="2" class="pre-wrap">Symbol</th>
                            <th colspan="3" class="pre-wrap text-center">Product Coeffient</th>
                        </tr>
                        <tr>

                            <th class="pre-wrap">Options Short</th>
                            <th class="pre-wrap">Options Long</th>
                            <th class="pre-wrap">Futures</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="prodCoef">
                        <tr *ngFor="let data of branchUserData.prodCoef; let i=index" [formGroupName]="i">
                            <td>{{i+1}}</td>
                            <td><input type="text" class="form-control" formControlName="symbol"
                                    placeholder="Enter Symbol" readonly></td>
                            <td>
                                <input type="number" class="form-control" formControlName="optShort" min="0" max="100"
                                    placeholder="Enter Options Short Product Coeffient">
                                <p *ngIf="uf.prodCoef.controls[i].controls.optShort.errors?.required && uf.prodCoef.controls[i].controls.optShort.touched"
                                    class="error nocase">
                                    Please enter options short product coeffient
                                </p>
                                <p *ngIf="uf.prodCoef.controls[i].controls.optShort.errors?.max && uf.prodCoef.controls[i].controls.optShort.touched"
                                    class="error nocase">
                                    Product Coefficient should not be greater than 100
                                </p>
                                <p *ngIf="uf.prodCoef.controls[i].controls.optShort.errors?.min && uf.prodCoef.controls[i].controls.optShort.touched"
                                    class="error nocase">
                                    Product Coefficient should not be less than 0
                                </p>
                            </td>
                            <td>
                                <input type="number" class="form-control" formControlName="optLong" min="0" max="100"
                                    placeholder="Enter Options Long Product Coeffient">
                                <p *ngIf="uf.prodCoef.controls[i].controls.optLong.errors?.required && uf.prodCoef.controls[i].controls.optLong.touched"
                                    class="error nocase">
                                    Please enter options long product coeffient
                                </p>
                                <p *ngIf="uf.prodCoef.controls[i].controls.optLong.errors?.max && uf.prodCoef.controls[i].controls.optLong.touched"
                                    class="error nocase">
                                    Product Coefficient should not be greater than 100
                                </p>
                                <p *ngIf="uf.prodCoef.controls[i].controls.optLong.errors?.min && uf.prodCoef.controls[i].controls.optLong.touched"
                                    class="error nocase">
                                    Product Coefficient should not be less than 0
                                </p>
                            </td>
                            <td>
                                <input type="number" class="form-control" formControlName="futures" min="0" max="100"
                                    placeholder="Enter Futures Product Coeffient">
                                <p *ngIf="uf.prodCoef.controls[i].controls.futures.errors?.required && uf.prodCoef.controls[i].controls.futures.touched"
                                    class="error nocase">
                                    Please enter futures product coeffient
                                </p>
                                <p *ngIf="uf.prodCoef.controls[i].controls.futures.errors?.max && uf.prodCoef.controls[i].controls.futures.touched"
                                    class="error nocase">
                                    Product Coefficient should not be greater than 100
                                </p>
                                <p *ngIf="uf.prodCoef.controls[i].controls.futures.errors?.min && uf.prodCoef.controls[i].controls.futures.touched"
                                    class="error nocase">
                                    Product Coefficient should not be less than 0
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
            <div class="row justify-content-start mb-5">
                <div class="col-lg-8">
                    <div class="row justify-content-start">
                        <div class="col-lg-2 usercontrol">
                            <button class="btn btn-primary full-width" (click)="onSubmit()"
                                [disabled]="userForm.invalid || !!(action=='view') || submittedBranch">Submit</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-cancel full-width" type="button" routerLink="../limitSetup"
                                skipLocationChange>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>