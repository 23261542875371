import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from 'src/app/valueObject/config';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, throwError } from 'rxjs';
import { BranchUserService } from 'src/app/service/branch-user.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { CelService } from 'src/app/service/cel.service';
import { catchError } from 'rxjs/operators';
import { SseClient } from 'ngx-sse-client';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-cel-monitoring',
  templateUrl: './cel-monitoring.component.html',
  styleUrls: ['./cel-monitoring.component.css']
})
export class CelMonitoringComponent implements OnInit, AfterViewInit, OnDestroy {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  user: any = {};
  access: any = {};
  users: any = [];
  branchUserData: any = [];
  selectedBranchId: string = '';
  selectedUser: any = {};
  userControlId: string = '';
  menucode: string = 'celMonitoring';
  config: any = config;
  subscription: Subscription;
  mmInfo: any = [];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private branchUserService: BranchUserService,
    private router: Router,
    private sseClient: SseClient,
    private route: ActivatedRoute,
    private celService: CelService) { }

  ngOnInit(): void {
    this.titleService.setTitle("CEL - Monitoring");
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.getMMInfo();
  }

  ngAfterViewInit() {
    this.getBranchUserData();
  }

  getBranchUserData() {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (this.userControlId)
      headers = headers.set('userId', this.userControlId);
    else
      headers = headers.delete('userId');
    this.subscription?.unsubscribe();
    this.subscription = this.sseClient.stream((environment.SSEURL + this.config.URL.getCelUtilization), { reconnectionDelay: 10000 }, { headers }, 'GET').subscribe((event) => {
      this.spinner.show();
      if (event.type == 'error') {
        const errorEvent = event as ErrorEvent;
        this.spinner.hide();
        if (errorEvent.error) {
          this.spinner.hide();
          this.branchUserData = [];
          this.setDatatable();
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        }
      } else {
        const messageEvent = event as MessageEvent;
        var response = JSON.parse(messageEvent.data);
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.branchUserData = response.data;
          this.setDatatable();
        } else {
          this.branchUserData = [];
          this.setDatatable();
          this.toastr.warning(response.message);
        }
      }
    })
  }

  setDatatable() {
    $("#userList").DataTable().destroy();
    setTimeout(() => {
      $("#userList").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        pageLength: 100,
        lengthMenu: [10, 25, 50, 100],
        buttons: [{
          extend: 'copy',
          titleAttr: 'copy'
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel'
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF'
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#userList .column-search input').val('');
            $('#userList .column-search select').val('.*');
            $('#userList .column-search').on('keyup change', 'input', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#userList .column-search').on('keyup change', 'select', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  getMMInfo() {
    this.celService.getMMInfo().pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.mmInfo = [];
        this.setMMTable();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.mmInfo = response.data[0];
          this.setMMTable();
        } else {
          this.toastr.error(response.message);
          this.mmInfo = [];
          this.setMMTable();
        }
      }
    );
  }

  setMMTable() {
    $("#mmInfoTable").DataTable().destroy();
    setTimeout(() => {
      $("#mmInfoTable").DataTable({
        processing: true,
        dom: "Bt",
        buttons: [{
          extend: 'copy',
          titleAttr: 'copy'
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel'
        }, {
          extend: 'pdf',
          titleAttr: 'Download as PDF'
        }]
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  ngOnDestroy() {
    this.dtTrigger?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
