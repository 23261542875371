import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { config } from 'src/app/valueObject/config';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $: any;
@Component({
  selector: 'app-neat-nonneat-processpage',
  templateUrl: './neat-nonneat-processpage.component.html',
  styleUrls: ['./neat-nonneat-processpage.component.css']
})
export class NeatNonneatProcesspageComponent implements OnInit {
  reqObj: any;
  nonNeatReq : FormGroup;
  status: boolean = false;
  myDataObj: any = {};
  submitFlag: number = 0;
  show : boolean ;
  submitted: boolean = false;
  config: any = config;
  entityType: any;
  memCode: any;
  memName: any;
  menucode ="NonNeatRequestProcessPage";
  user: any = {};
  access: any = {};
  proUndertaking: any;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private enitTradeService: EnitTradeService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private enitService: EnitService,
    private userDetailsService:UserDetailsService,
    private titleService: Title,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['num_REF_NO']) {
        this.reqObj = enitTradeService.getNonNeatRequest(params['num_REF_NO']);
      }
    });
   }
  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.titleService.setTitle('Neat Non-Neat Process Page');
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;

    if(this.reqObj.nud_PRO_UNDERTAKING == 'N'){
      this.proUndertaking = "No"
    }
    else if (this.reqObj.nud_PRO_UNDERTAKING == 'Y'){
      this.proUndertaking = "Yes"
    }
    else if (this.reqObj.nud_PRO_UNDERTAKING == null){
      this.proUndertaking = ""
    }
    $('.selectpicker').selectpicker();
    this.nonNeatReq = this.formbuilder.group({
      memCode : [10000+this.reqObj.num_MEM_ID],
      memName : [this.reqObj.mem_NM],
      refNo : [this.reqObj.num_REF_NO],
      productType : [this.reqObj.nud_PRODUCT_TYPE],
      version : [this.reqObj.nud_SW_VER],
      tapIp : [this.reqObj.nud_TAP_IP],
      nud_PRO_ENABLED : [this.reqObj.nud_PRO_ENABLED],
      nud_PRO_UNDERTAKING : [this.proUndertaking],
      currentStatus : [this.reqObj.num_STATUS],
      updateStatus : ['', Validators.required],
      rejReason : ['', Validators.required]
    })
  }
  get f(){
    return this.nonNeatReq.controls;
  }
  showDiv(){
    if (this.f.updateStatus.value=='REJECTED') {
      this.show=true;
    }else if (this.f.updateStatus.value=='APPROVED') {
      this.show=false;
    }
  }
  back(){
    this.router.navigate(['eTrade/eUseridRequest/displayNonNeatReport'])
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    let requestObject = {
      numMemId: 10000-this.f.memCode.value,
      numStatus: this.f.updateStatus.value,
      numRefNo: this.f.refNo.value,
      numUpdBy: 'NSE ADMIN',
      numUpdDt: Date.toString,
      numReasonReturn: this.f.rejReason.value,
    }
    this.enitService.saveNonNeatReporttData(requestObject).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
        this.submitted = false;
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.toastr.success("Request updated successfully");
        this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: true });
        this.submitted = true;
      } else {
        this.toastr.error(response.message);
        this.submitted = false;
      }
    })
  }
}
