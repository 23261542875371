import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitService } from 'src/app/service/enit.service';
import { config } from 'src/app/valueObject/config';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $: any;
@Component({
  selector: 'app-neat-user-validity-updation',
  templateUrl: './neat-user-validity-updation.component.html',
  styleUrls: ['./neat-user-validity-updation.component.css']
})
export class NeatUserValidityUpdationComponent implements OnInit {
  validityUpdation: FormGroup;
  user: any = {};
  cusDlrId: any = [];
  config: any = config;
  submitted = false;
  message: string;
  fileupload: FormGroup
  fileName: string;
  file: File;
  isFileUploaded: boolean = false;
  base64Output: string;
  menucode: string = 'displayNeatUsrValidityUpdtRequest';
  memCode: String;
  memId: String;
  access: any = {};
  entityType: any;
  memName:String;
  maxDate: Date = new Date();
  bsValue: any;
  bsValue1: any;
  constructor(
    private route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private EnitService: EnitService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private userDetailsService: UserDetailsService,
  ) { }
  ngOnInit(): void {
    $('.selectpicker').selectpicker();
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode - 10000) + "";
    this.memName = this.user.userName;
    this.entityType = this.user.entityType;
    this.titleService.setTitle('Neat Validity Request Page');
    this.validityUpdation = this.formbuilder.group({
      SebiRegiCode: ['', [Validators.required]],
      cusDlrId: ['', [Validators.required]],
      UserName: ['', [Validators.required]],
      ChildTapIpType: ['', [Validators.required]],
      TapId: ['', [Validators.required]],
      CertifiedBy: ['', [Validators.required]],
      RegistrationNo: ['', [Validators.required]],
      ValidityDate: ['', [Validators.required]],
      Percentage: [''],
      certificationType: ['', [Validators.required]],
      ExamType: ['', [Validators.required]],
      registratioNumber: ['', [
        Validators.required,
        Validators.minLength(12), Validators.maxLength(12)]],
      ValidateDateType: ['', Validators.required],
      TitleType: ['', Validators.required],
      firstNameType: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z]*$')]],
      middleNameType: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z]*$')]],
      lastNameType: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z]*$')]],
      FatherNameType: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z]*$')]],
      BODType: ['', Validators.required],
      PanType: ['', [Validators.required, Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')]],
      MobileNoType: ['', [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      Validators.minLength(10), Validators.maxLength(10)]],
      QualificationType: ['', Validators.required],
      PercentageType: ['',[Validators.required,Validators.max(100),]],
      EmailType: ['', [Validators.required, Validators.email]],
      UploadCertificate: ['', Validators.required],
      CheckType: [false, Validators.required],
    })
    this.getSebiSebiRegistrationCode(this.memId);
    this.getDealerIdDetailsForValidityUpdation(this.memCode, this.memId);
  }
  get f() { return this.validityUpdation.controls; }
  reset() {
    this.f.cusDlrId.setValue('');
    this.f.UserName.setValue('')
    this.f.ChildTapIpType.setValue('')
    this.f.TapId.setValue('')
    this.f.CertifiedBy.setValue('')
    this.f.RegistrationNo.setValue('')
    this.f.ValidityDate.setValue('')
    this.f.Percentage.setValue('')
    this.f.certificationType.setValue('')
    this.f.ExamType.setValue('')
    this.f.registratioNumber.setValue('')
    this.f.ValidateDateType.setValue('')
    this.f.TitleType.setValue('')
    this.f.firstNameType.setValue('')
    this.f.middleNameType.setValue('')
    this.f.lastNameType.setValue('')
    this.f.FatherNameType.setValue('')
    this.f.BODType.setValue('')
    this.f.PanType.setValue('')
    this.f.MobileNoType.setValue('')
    this.f.QualificationType.setValue('')
    this.f.PercentageType.setValue('')
    this.f.EmailType.setValue('')
    this.f.UploadCertificate.setValue('')
    this.f.CheckType.setValue(false)
    $('.selectpicker').selectpicker('refresh');
  }
  back(){
    this.router.navigate(['']);
  }
  getSebiSebiRegistrationCode(memId: String) {
    this.EnitService.getUserByMemCode2(memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.f.SebiRegiCode.setValue(response.data.memSebiRegCode);
      }
    })
  }
  getDealerIdDetailsForValidityUpdation(memCode: String, memId: String) {
    let requestObject = {
      "memId": memId,
      "memCode": memCode
    }
    this.EnitService.getDealerIdDetailsForValidityUpdation(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.cusDlrId = response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }
  selectDlrId() {
    this.EnitService.getSingleDealerIdDetails(this.memCode, this.memId, this.f.cusDlrId.value).subscribe((response: any) => {
      this.f.UserName.setValue(response.data.cusDlrNm);
      this.f.ChildTapIpType.setValue(response.data.cusDlrTapIp);
      this.f.TapId.setValue(response.data.cusDlrTidTapId);
      this.f.CertifiedBy.setValue(response.data.cusDlrNCFMFlag);
      this.f.RegistrationNo.setValue(response.data.cusDlrRegNo);
      this.f.ValidityDate.setValue(response.data.cusDlrVldtyDtStr);
      this.f.Percentage.setValue(response.data.cusDlrPercentage);
    })
  }
  onSubmit() {
    this.submitted = true;
    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
      "cusDlrUsrId": this.f.cusDlrId.value,
      "cusCertificateType": this.f.certificationType.value,
      "cusExamTypeNismOf": this.f.ExamType.value,
      "cusExamNCFMRegNo": this.f.RegistrationNo.value,
      "cusCertValidDtStr": this.f.ValidateDateType.value,
      "cusTitle": this.f.TitleType.value,
      "cusFirstName": this.f.firstNameType.value,
      "cusMiddleName": this.f.middleNameType.value,
      "cusLastName": this.f.lastNameType.value,
      "cusFatherHusbandName": this.f.FatherNameType.value,
      "cusDOB": this.f.BODType.value,
      "cusPanNo": this.f.PanType.value,
      "cusContactNo": this.f.MobileNoType.value,
      "cusSelectedQualification": this.f.QualificationType.value,
      "cusExamPercentage": this.f.PercentageType.value,
      "cusEmailId": this.f.EmailType.value,
      "fileUploadPanBase64": this.f.UploadCertificate.value,
    };
    this.EnitService.SaveValidityUpdateRequestObj(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.message = 'Validity Updation Request Sent Successfully';
        this.toastr.success(this.message);
        this.reset()
        $('.selectpicker').selectpicker('refresh');
      } else {
        this.submitted = false;
      }
    })
  }
  onFileSelected(event) {
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;
      console.log(this.base64Output)
    });
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;

    
  }
}





