<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>CTCL ID Activation</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eTrade/eMemberReportingPreTrade/displayCtclActivation"
                            aria-current="page">CTCL ID Activation</a></li>

                </ul>
            </div>

        </div>
    </div>
    <form [formGroup]="ctclRequest">
        <div class="container-fluid mt-3 mb-3 theme">
            <div formGroupName="ctclRequestDetails">

                <div class="card">
                    <div class="card-body">
                        <div class="row">


                            <ng-container *ngIf="displayFromReport==='YES'; else neatIdForNonReport">
                                <div class="col-lg-3 form-group">
                                    <label>NEAT User ID<span class="red">*</span> </label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsNeatUserId">
                                </div>
                            </ng-container>

                            <ng-template #neatIdForNonReport>
                                <div class="col-lg-3 form-group">
                                    <label>NEAT User ID<span class="red">*</span> </label>
                                    <select class="form-control neatSelectpicker" formControlName="ctclRqdtlsNeatUserId"
                                        data-live-search="true" data-style="btn-info" (change)="neatUserIdChange()"
                                        [attr.disable] title="Select Neat User ID">
                                        <option value="">--Select--</option>
                                        <option *ngFor="let neatUser of neatUserIdList" value="{{neatUser}}">
                                            {{neatUser}}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>






                            <div class="col-lg-3 form-group">
                                <label>12 Digit CTCL Terminal Id<span class="red">*</span> </label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsTerminalId"
                                    (change)="getNeatDeactivatedateAndTerminalIdExistance()">

                                <div *ngIf="reqDtls.get('ctclRqdtlsTerminalId').invalid && reqDtls.get('ctclRqdtlsTerminalId').touched"
                                    class="error nocase">

                                    <div
                                        *ngIf="reqDtls.get('ctclRqdtlsTerminalId').errors['emptyValue'] || reqDtls.get('ctclRqdtlsTerminalId').errors['required']">
                                        {{ctclRequestValidationFields.ctclRqdtlsTerminalId[0].message}}</div>

                                    <div *ngIf="reqDtls.get('ctclRqdtlsTerminalId').errors['pattern']; else otherIssue">
                                        {{ctclRequestValidationFields.ctclRqdtlsTerminalId[2].message}}</div>

                                    <ng-template #otherIssue>
                                        <div
                                            *ngIf="reqDtls.get('ctclRqdtlsTerminalId').errors['maxlength'] || reqDtls.get('ctclRqdtlsTerminalId').errors['lengthIssue']">
                                            {{ctclRequestValidationFields.ctclRqdtlsTerminalId[1].message}}</div>
                                    </ng-template>

                                </div>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Login id for 12 Digit CTCL Terminal Id<span class="red">*</span></label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsLoginId">
                                <div class="error nocase"
                                    *ngIf="(reqDtls.get('ctclRqdtlsLoginId').invalid && reqDtls.get('ctclRqdtlsLoginId').touched) && (reqDtls.get('ctclRqdtlsLoginId').errors['maxlength'] || reqDtls.get('ctclRqdtlsLoginId').errors['required'])">
                                    {{ctclRequestValidationFields.ctclRqdtlsLoginId[0].message}}</div>
                            </div>

                            <ng-container *ngIf="displayFromReport==='YES'; else purposeIdForNonReport">
                                <div class="col-lg-3 form-group">
                                    <label>Purpose of CTCL ID<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsPurposeCtclId">
                                </div>
                            </ng-container>

                            <ng-template #purposeIdForNonReport>
                                <div class="col-lg-3 form-group">
                                    <label>Purpose of CTCL ID<span class="red">*</span></label>
                                    <select class="form-control neatPurposeSelectpicker"
                                        formControlName="ctclRqdtlsPurposeCtclId" data-style="btn-info"
                                        title="Select Purpose">
                                        <option value="">--Select--</option>
                                        <option *ngFor="let purposeId of purposeOfCtclIdList"
                                            value="{{purposeId.refValue}}">
                                            {{purposeId.refDesc}}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>

                        </div>
                        <div class="row">


                            <ng-container *ngIf="displayFromReport==='YES'; else dateOfActivationForNonReport">
                                <div class="col-lg-6 form-group">
                                    <label>Date of activation of the 12 digit CTCL id by the trading member to the
                                        approved
                                        person
                                        operating
                                        terminal<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsAllotDateStr">
                                </div>
                            </ng-container>

                            <ng-template #dateOfActivationForNonReport>
                                <div class="col-lg-6 form-group">
                                    <label>Date of activation of the 12 digit CTCL id by the trading member to the
                                        approved
                                        person
                                        operating
                                        terminal<span class="red">*</span></label>
                                    <input type="date" value="" class="form-control" id="ctclRqdtlsAllotDate"
                                        formControlName="ctclRqdtlsAllotDate"
                                        (blur)="activationDateCalculation($event.target.value)"
                                        [disabled]="ctclRequestDisabledFields.ctclRqdtlsAllotDateDisable">
                                    <div class="error nocase" *ngIf="activationDateError !='' ">
                                        {{activationDateError}}</div>
                                </div>
                            </ng-template>




                        </div>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-header">
                        Office Details
                    </div>
                    <div class="card-body">
                        <div formGroupName="ctclRqdtlsLocationId">
                            <div class="row">

                                <ng-container *ngIf="displayFromReport==='YES'; else ctclTerminalForNonReport">
                                    <div class="col-lg-6 form-group">
                                        <label>Status of Office where CTCL terminal is located<span
                                                class="red">*</span></label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="ctclLocdtlsOfficeStatus">
                                    </div>
                                </ng-container>

                                <ng-template #ctclTerminalForNonReport>
                                    <div class="col-lg-6 form-group">
                                        <label>Status of Office where CTCL terminal is located<span
                                                class="red">*</span></label>
                                        <select class="form-control terminalOfficeSelectpicker"
                                            formControlName="ctclLocdtlsOfficeStatus" data-style="btn-info"
                                            (change)="ctclLocdtlsOfficeStatusChange()" title="Select Status">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let terminalOffice of officeTerminalList"
                                                value="{{terminalOffice.refValue}}">{{terminalOffice.refDesc}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>

                            </div>

                            <div formGroupName="addressDtls">
                                <div class="row">
                                    <div class="col-lg-3 form-group">
                                        <label>Flat/Door No<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adFlatNo">
                                        <div class="error nocase"
                                            *ngIf="(reqAddrDtls.get('adFlatNo').invalid && reqAddrDtls.get('adFlatNo').touched) && (reqAddrDtls.get('adFlatNo').errors['maxlength'] || reqAddrDtls.get('adFlatNo').errors['required'])">
                                            {{ctclRequestValidationFields.adFlatNo[0].message}}</div>
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Building Name<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="adBuildingName">
                                        <div class="error nocase"
                                            *ngIf="(reqAddrDtls.get('adBuildingName').invalid && reqAddrDtls.get('adBuildingName').touched) && (reqAddrDtls.get('adBuildingName').errors['maxlength'] || reqAddrDtls.get('adBuildingName').errors['required'])">
                                            {{ctclRequestValidationFields.adBuildingName[0].message}}</div>
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Floor</label>
                                        <input type="text" value="" class="form-control" formControlName="adFloor">
                                        <div class="error nocase"
                                            *ngIf="(reqAddrDtls.get('adFloor').invalid && reqAddrDtls.get('adFloor').touched) && (reqAddrDtls.get('adFloor').errors['maxlength'])">
                                            {{ctclRequestValidationFields.adFloor[0].message}}</div>
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Street/Road Name<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adStreetName">
                                        <div class="error nocase"
                                            *ngIf="(reqAddrDtls.get('adStreetName').invalid && reqAddrDtls.get('adStreetName').touched) && (reqAddrDtls.get('adStreetName').errors['maxlength'] || reqAddrDtls.get('adStreetName').errors['required'])">
                                            {{ctclRequestValidationFields.adStreetName[0].message}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 form-group">
                                        <label>Landmark</label>
                                        <input type="text" value="" class="form-control" formControlName="adLandmark">
                                        <div class="error nocase"
                                            *ngIf="(reqAddrDtls.get('adLandmark').invalid && reqAddrDtls.get('adLandmark').touched) && (reqAddrDtls.get('adLandmark').errors['maxlength'])">
                                            {{ctclRequestValidationFields.adLandmark[0].message}}</div>
                                    </div>

                                    <div class="col-lg-3 form-group">
                                        <label>Area/Locality/Colony<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adColony">
                                        <div class="error nocase"
                                            *ngIf="(reqAddrDtls.get('adColony').invalid && reqAddrDtls.get('adColony').touched) && (reqAddrDtls.get('adColony').errors['maxlength'] || reqAddrDtls.get('adColony').errors['required'])">
                                            {{ctclRequestValidationFields.adColony[0].message}}</div>
                                    </div>


                                    <ng-container *ngIf="displayFromReport==='YES'; else cityForNonReport">
                                        <div class="col-lg-3 form-group">
                                            <label>City<span class="red">*</span></label>
                                            <input type="text" value="" class="form-control" formControlName="cityName">
                                        </div>
                                    </ng-container>

                                    <ng-template #cityForNonReport>
                                        <div class="col-lg-3 form-group">
                                            <label>City<span class="red">*</span></label>
                                            <select class="form-control citySelectpicker" formControlName="adCity"
                                                data-live-search="true" data-style="btn-info" (change)="cityChange()"
                                                title="Select City">
                                                <option value="">--Select--</option>
                                                <option *ngFor="let cityData of ctclAddressList"
                                                    value="{{cityData.cityId}}"
                                                    data-myData="{{cityData.cityId+'@@'+cityData.districtId+'@@'+cityData.districtNm+'@@'+cityData.stateId+'@@'+cityData.stateNm}}">
                                                    {{cityData.cityNm}}
                                                </option>
                                            </select>
                                        </div>
                                    </ng-template>




                                    <ng-container *ngIf="displayFromReport==='YES'; else stateForNonReport">
                                        <div class="col-lg-3 form-group">
                                            <label>State</label>
                                            <input type="text" value="" class="form-control"
                                                formControlName="stateName">
                                        </div>
                                    </ng-container>

                                    <ng-template #stateForNonReport>
                                        <div class="col-lg-3 form-group">
                                            <label>State</label>
                                            <input type="text" value="" class="form-control" formControlName="stateNm">
                                        </div>
                                    </ng-template>



                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <div formGroupName="ctclRqdtlsLocationId">
                                    <div formGroupName="addressDtls">
                                        <label>Pin Code<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control" formControlName="adPincode">
                                        <div *ngIf="reqAddrDtls.get('adPincode').invalid && reqAddrDtls.get('adPincode').touched"
                                            class="error nocase">

                                            <div
                                                *ngIf="reqAddrDtls.get('adPincode').errors['pattern']; else otherPinIssue">
                                                {{ctclRequestValidationFields.adPincode[1].message}}</div>

                                            <ng-template #otherPinIssue>
                                                <div
                                                    *ngIf="reqAddrDtls.get('adPincode').errors['maxlength'] || reqAddrDtls.get('adPincode').errors['required']">
                                                    {{ctclRequestValidationFields.adPincode[0].message}}</div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-12 form-group">
                                        <div
                                            [ngStyle]="{'color': '#666666','font-size':'12px','line-height':'17px','font-family':'Poppins SemiBold','text-transform':'capitalize'}">
                                            Telephone Number<span class="red">*</span></div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 form-group">
                                        <div formGroupName="ctclRqdtlsLocationId">
                                            <input type="text" value="" class="form-control"
                                                formControlName="ctclAddStdNo">
                                            <div *ngIf="reqLocation.get('ctclAddStdNo').invalid && reqLocation.get('ctclAddStdNo').touched"
                                                class="error nocase">

                                                <div
                                                    *ngIf="reqLocation.get('ctclAddStdNo').errors['pattern']; else otherAddressIssue">
                                                    {{ctclRequestValidationFields.ctclAddStdNo[1].message}}</div>

                                                <ng-template #otherAddressIssue>
                                                    <div
                                                        *ngIf="reqLocation.get('ctclAddStdNo').errors['maxlength'] || reqLocation.get('ctclAddStdNo').errors['required']">
                                                        {{ctclRequestValidationFields.ctclAddStdNo[0].message}}</div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-8 form-group">
                                        <div formGroupName="ctclRqdtlsLocationId">
                                            <input type="text" value="" class="form-control"
                                                formControlName="ctclAddPhoneNo">
                                            <div *ngIf="reqLocation.get('ctclAddPhoneNo').invalid && reqLocation.get('ctclAddPhoneNo').touched"
                                                class="error nocase">

                                                <div
                                                    *ngIf="reqLocation.get('ctclAddPhoneNo').errors['pattern']; else otherPhoneIssue">
                                                    {{ctclRequestValidationFields.ctclAddPhoneNo[1].message}}</div>

                                                <ng-template #otherPhoneIssue>
                                                    <div
                                                        *ngIf="reqLocation.get('ctclAddPhoneNo').errors['maxlength'] || reqLocation.get('ctclAddPhoneNo').errors['required']">
                                                        {{ctclRequestValidationFields.ctclAddPhoneNo[0].message}}</div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <ng-container *ngIf="displayFromReport==='YES'; else modeOfConnectionForNonReport">
                                <div class="col-lg-3 form-group">
                                    <div formGroupName="ctclRqdtlsLocationId">
                                        <label>Mode of Connectivity<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="ctclLocdtlsConnMode">
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #modeOfConnectionForNonReport>
                                <div class="col-lg-3">
                                    <div formGroupName="ctclRqdtlsLocationId">
                                        <label>Mode of Connectivity<span class="red">*</span></label>
                                        <select class="form-control connModePicker"
                                            formControlName="ctclLocdtlsConnMode" data-style="btn-info"
                                            title="Select Mode">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let connectivityMode of ctclLocdtlsConnModeList"
                                                value="{{connectivityMode.refValue}}">{{connectivityMode.refDesc}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </ng-template>





                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header">
                        Details Of The Proposed User
                    </div>
                    <div class="card-body">
                        <div formGroupName="ctclRqdtlsExamId">

                            <div class="row">
                                <div class="col-lg-3">
                                    <label>Certified By</label>
                                </div>

                                <div class="col-lg-3">
                                    <div class="form-check" [style]="{'padding-left':'0rem'}">
                                        <label>Exam Type</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" value="NISM" class="form-check-input"
                                            id="certifiedByRadioNISM" formControlName="ctclExamDtlsExamOf"
                                            [checked]="reqExam.get('ctclExamDtlsExamOf').value==='NISM'"
                                            (change)="certifiedByChange()">
                                        <label class="form-check-label" for="certifiedByRadioNISM">NISM</label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input type="radio" value="NISM-CPE" class="form-check-input"
                                            id="certifiedByRadioNISM_CPE" formControlName="ctclExamDtlsExamOf"
                                            (change)="certifiedByChange()">
                                        <label class="form-check-label" for="certifiedByRadioNISM_CPE">NISM-CPE</label>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" value="NISM" class="form-check-input" id="examType"
                                            formControlName="ctclExamDtlsExamType"
                                            [checked]="reqExam.get('ctclExamDtlsExamType').value==='NISM'">
                                        <label class="form-check-label" for="examType">NISM</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row" [ngStyle]="{'margin-top':'15px'}">
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-12 form-group">
                                            <div
                                                [ngStyle]="{'color': '#666666','font-size':'12px','line-height':'17px','font-family':'Poppins SemiBold','text-transform':'capitalize'}">
                                                Registration No Of Certificate</div>
                                        </div>
                                    </div>

                                    <div
                                        *ngIf="reqExam.get('ctclExamDtlsExamOf').value == 'NISM'; else nismcertificate">
                                        <div class="row">
                                            <div class="col-lg-1 form-group">
                                                <input type="text" value="" class="form-control" value="NISM"
                                                    formControlName="ctclExamDtlsExamType" id="ctclExamType1" disabled>
                                            </div>

                                            <div class="col-lg-5 form-group">                
                                                <input type="text" value="" class="form-control" #nismexamIdPart0
                                                    (input)="examIdPart0 = $event.target.value"
                                                    (blur)="registerCertificateChange($event)" id="examIdPart0"
                                                    [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                <div class="error nocase" *ngIf="examIdError !='' ">
                                                    {{examIdError}}</div>
                                                <!-- <div *ngIf="reqExam.get('ctclExamDtlsExamId').invalid && reqExam.get('ctclExamDtlsExamId').touched"
                                                    class="error nocase">

                                                    <div
                                                        *ngIf="reqExam.get('ctclExamDtlsExamId').errors['pattern']; else otherExamIdIssue">
                                                        {{ctclRequestValidationFields.ctclExamDtlsExamId[1].message}}
                                                    </div>

                                                    <ng-template #otherExamIdIssue>
                                                        <div
                                                            *ngIf="reqExam.get('ctclExamDtlsExamId').errors['maxlength'] || reqExam.get('ctclExamDtlsExamId').errors['required']">
                                                            {{ctclRequestValidationFields.ctclExamDtlsExamId[0].message}}
                                                        </div>
                                                    </ng-template>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row" [ngStyle]="{'margin-top':'-8px'}">

                                            <div class="col-lg-12 form-group">
                                                <div [ngStyle]="{'color': '#666666','font-size':'12px'}">NISM
                                                    Registration
                                                    No.
                                                    as issued by NISM : ************ (12 digit registration
                                                    no)</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <ng-template #nismcertificate>
                                        <div class="row">
                                            <div class="col-lg-10">
                                                <div class="row">
                                                    <div class="col-lg-1 form-group">
                                                        <input type="text" class="form-control" id="examIdPart1"
                                                            #nismexamIdPart1 (input)="examIdPart1 = $event.target.value"
                                                            (blur)="registerCertificateChange($event.target)"
                                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                        <div class="error nocase" *ngIf="examIdPartError1.length !=''">
                                                            {{ctclRequestValidationFields.examIdPartError1[0].message}}
                                                        </div>
                                                    </div>/
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="examIdPart2"
                                                            #nismexamIdPart2 (input)="examIdPart2 = $event.target.value"
                                                            (blur)="registerCertificateChange($event.target)"
                                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                        <div class="error nocase" *ngIf="examIdPartError2.length !=''">
                                                            {{ctclRequestValidationFields.examIdPartError2[0].message}}
                                                        </div>
                                                    </div>-

                                                    <div class="col-lg-1 form-group">
                                                        <input type="text" class="form-control" id="examIdPart3"
                                                            #nismexamIdPart3 (input)="examIdPart3 = $event.target.value"
                                                            (blur)="registerCertificateChange($event.target)"
                                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                        <div class="error nocase" *ngIf="examIdPartError3.length !=''">
                                                            {{ctclRequestValidationFields.examIdPartError3[0].message}}
                                                        </div>
                                                    </div>/
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="examIdPart4"
                                                            #nismexamIdPart4 (input)="examIdPart4 = $event.target.value"
                                                            (blur)="registerCertificateChange($event.target)"
                                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                        <div class="error nocase" *ngIf="examIdPartError4.length !=''">
                                                            {{ctclRequestValidationFields.examIdPartError4[0].message}}
                                                        </div>
                                                    </div>/
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="examIdPart5"
                                                            #nismexamIdPart5 (input)="examIdPart5 = $event.target.value"
                                                            (blur)="registerCertificateChange($event.target)"
                                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                        <div class="error nocase" *ngIf="examIdPartError5.length !=''">
                                                            {{ctclRequestValidationFields.examIdPartError5[0].message}}
                                                        </div>
                                                    </div>-

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="examIdPart6"
                                                            #nismexamIdPart6 (input)="examIdPart6 = $event.target.value"
                                                            (blur)="registerCertificateChange($event.target)"
                                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsExamIdDisable">
                                                        <div class="error nocase" *ngIf="examIdPartError6.length !=''">
                                                            {{ctclRequestValidationFields.examIdPartError6[0].message}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" [ngStyle]="{'margin-top':'-8px'}">

                                            <div class="col-lg-12 form-group">
                                                <div [ngStyle]="{'color': '#666666','font-size':'12px'}">Kindly enter
                                                    the NISM certification details in any of the following formats
                                                    mandatorily
                                                    CPE Code as issued by NISM: ABCDE/*****-**/***** & **/CITY- 7 digit
                                                    Certificate No.</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <ng-container *ngIf="displayFromReport==='NO'">
                                <div class="row">
                                    <div class="col-sm-12"><button type="button" class="btn btn-link" click="">Check
                                        Affiliation</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-link" (click)="downloadCertificateHelpFile()">Certification
                                            Help</button></div>
                            </div>
                            </ng-container>


                            <div class="row">


                                <ng-container *ngIf="displayFromReport==='YES'; else certificateTypeForNonReport">
                                    <div class="col-lg-6 form-group">
                                        <label>Certificate Type</label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="ctclExamDtlsCertificatetype">
                                    </div>
                                </ng-container>

                                <ng-template #certificateTypeForNonReport>
                                    <div class="col-lg-6 form-group">
                                        <label>Certificate Type</label>
                                        <select class="form-control ctclExamDtlsCertificatetypePicker"
                                            formControlName="ctclExamDtlsCertificatetype" data-style="btn-info"
                                            title="Select Certificate Type">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let certificateType of ctclExamDtlsCertificatetypeList"
                                                value="{{certificateType.refValue}}">{{certificateType.refDesc}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>







                            </div>

                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <div [ngStyle]="{'color': 'red','margin-bottom':'-10px'}">Name of approved person
                                        operating CTCL Terminal</div>
                                </div>
                            </div>

                            <div class="row">


                                <ng-container *ngIf="displayFromReport==='YES'; else examDtlsTitleForNonReport">
                                    <div class="col-lg-3 form-group">
                                        <label>Title</label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="ctclExamDtlsTitle">
                                    </div>
                                </ng-container>

                                <ng-template #examDtlsTitleForNonReport>
                                    <div class="col-lg-3 form-group">
                                        <label>Title</label>
                                        <select class="form-control ctclExamDtlsTitlePicker"
                                            formControlName="ctclExamDtlsTitle" data-style="btn-info">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let examDtlsTitle of ctclExamDtlsTitleList"
                                                value="{{examDtlsTitle.refValue}}">{{examDtlsTitle.refValue}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>






                                <div class="col-lg-3 form-group">
                                    <label>First Name<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsFirstName">
                                    <div class="error nocase"
                                        *ngIf="(reqExam.get('ctclExamDtlsFirstName').invalid && reqExam.get('ctclExamDtlsFirstName').touched) && (reqExam.get('ctclExamDtlsFirstName').errors['maxlength'] || reqExam.get('ctclExamDtlsFirstName').errors['required'])">
                                        {{ctclRequestValidationFields.ctclExamDtlsFirstName[0].message}}</div>
                                </div>

                                <div class="col-lg-3 form-group">
                                    <label>Middle Name</label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsMiddleName">
                                    <div class="error nocase"
                                        *ngIf="(reqExam.get('ctclExamDtlsMiddleName').invalid && reqExam.get('ctclExamDtlsMiddleName').touched) && (reqExam.get('ctclExamDtlsMiddleName').errors['maxlength'] || reqExam.get('ctclExamDtlsMiddleName').errors['required'])">
                                        {{ctclRequestValidationFields.ctclExamDtlsMiddleName[0].message}}</div>
                                </div>

                                <div class="col-lg-3 form-group">
                                    <label>Last Name<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclExamDtlsLastName">
                                    <div class="error nocase"
                                        *ngIf="(reqExam.get('ctclExamDtlsLastName').invalid && reqExam.get('ctclExamDtlsLastName').touched) && (reqExam.get('ctclExamDtlsLastName').errors['maxlength'] || reqExam.get('ctclExamDtlsLastName').errors['required'])">
                                        {{ctclRequestValidationFields.ctclExamDtlsLastName[0].message}}</div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <div [ngStyle]="{'color': 'red','margin-bottom':'-10px'}">Father's name of approved
                                    person(First name, Middle Name,Last
                                    name)</div>
                            </div>
                        </div>

                        <div class="row">
                            <ng-container *ngIf="displayFromReport==='YES'; else fatherTitleForNonReport">
                                <div class="col-lg-3 form-group">
                                    <label>Title</label>
                                    <input type="text" value="MR." class="form-control" disabled>
                                </div>
                            </ng-container>

                            <ng-template #fatherTitleForNonReport>
                                <div class="col-lg-3 form-group">
                                    <label>Title</label>
                                    <select class="form-control" data-style="btn-info" title="Mr." disabled>
                                        <option value="MR.">MR.</option>
                                    </select>
                                </div>
                            </ng-template>






                            <div class="col-lg-3 form-group">
                                <label>First Name<span class="red">*</span></label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsFatherFirstName">
                                <div class="error nocase"
                                    *ngIf="(reqDtls.get('ctclRqdtlsFatherFirstName').invalid && reqDtls.get('ctclRqdtlsFatherFirstName').touched) && (reqDtls.get('ctclRqdtlsFatherFirstName').errors['maxlength'] || reqDtls.get('ctclRqdtlsFatherFirstName').errors['required'])">
                                    {{ctclRequestValidationFields.ctclRqdtlsFatherFirstName[0].message}}</div>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Middle Name</label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsFatherMiddleName">
                                <div class="error nocase"
                                    *ngIf="(reqDtls.get('ctclRqdtlsFatherMiddleName').invalid && reqDtls.get('ctclRqdtlsFatherMiddleName').touched) && (reqDtls.get('ctclRqdtlsFatherMiddleName').errors['maxlength'])">
                                    {{ctclRequestValidationFields.ctclRqdtlsFatherMiddleName[0].message}}</div>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Last Name<span class="red">*</span></label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsFatherLastName">
                                <div class="error nocase"
                                    *ngIf="(reqDtls.get('ctclRqdtlsFatherLastName').invalid && reqDtls.get('ctclRqdtlsFatherLastName').touched) && (reqDtls.get('ctclRqdtlsFatherLastName').errors['maxlength'] || reqDtls.get('ctclRqdtlsFatherLastName').errors['required'])">
                                    {{ctclRequestValidationFields.ctclRqdtlsFatherLastName[0].message}}</div>
                            </div>
                        </div>

                        <div class="row">


                            <ng-container *ngIf="displayFromReport==='YES'; else dateOfBirthForNonReport">
                                <div class="col-lg-6 form-group">
                                    <div formGroupName="ctclRqdtlsExamId">
                                        <label>Date of birth of approved person<span class="red">*</span></label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="ctclExamDtlsDobStr" disabled>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #dateOfBirthForNonReport>
                                <div class="col-lg-6 form-group">
                                    <div formGroupName="ctclRqdtlsExamId">
                                        <label>Date of birth of approved person<span class="red">*</span></label>
                                        <input type="date" value="" class="form-control" id="ctclExamDtlsDob"
                                            formControlName="ctclExamDtlsDob"
                                            (blur)="ageCalculation($event.target.value)"
                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsDobStrDisable"
                                             >
                                        <div class="error nocase" *ngIf="dobOfApprovedPersonError !='' ">
                                            {{dobOfApprovedPersonError}}</div>
                                    </div>
                                </div>
                            </ng-template>


                            <ng-container *ngIf="displayFromReport==='YES'; else validityOfCertForNonReport">
                                <div class="col-lg-6 form-group">
                                    <div formGroupName="ctclRqdtlsExamId">
                                        <label>Validity of NISM/NISM-CPE Certifications of the approved person</label>
                                        <input type="text" value="" class="form-control"
                                            formControlName="ctclExamDtlsValidDtStr">
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #validityOfCertForNonReport>
                                <div class="col-lg-6 form-group">
                                    <div formGroupName="ctclRqdtlsExamId">
                                        <label>Validity of NISM/NISM-CPE Certifications of the approved person</label>
                                        <input type="date" value="" class="form-control" id="ctclExamDtlsValidDt"
                                            formControlName="ctclExamDtlsValidDt"
                                            (blur)="validyDateCalculation($event.target.value)"
                                            [disabled]="ctclRequestDisabledFields.ctclRqdtlsExamId.ctclExamDtlsValidDtStrDisable">
                                        <div class="error nocase" *ngIf="validityDateOfApprovedPersonError !='' ">
                                            {{validityDateOfApprovedPersonError}}</div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>


                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <label>PAN of the approved person operating CTCL Terminal<span
                                        class="red">*</span></label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsPan" style="text-transform: uppercase;">
                                <div *ngIf="reqDtls.get('ctclRqdtlsPan').invalid && reqDtls.get('ctclRqdtlsPan').touched"
                                    class="error nocase">

                                    <div *ngIf="reqDtls.get('ctclRqdtlsPan').errors['pattern']; else otherPanIssue">
                                        {{ctclRequestValidationFields.ctclRqdtlsPan[1].message}}</div>

                                    <ng-template #otherPanIssue>
                                        <div
                                            *ngIf="reqDtls.get('ctclRqdtlsPan').errors['maxlength'] || reqDtls.get('ctclRqdtlsPan').errors['required']">
                                            {{ctclRequestValidationFields.ctclRqdtlsPan[0].message}}</div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label>Mobile Number<span class="red">*</span></label>
                                <input type="text" value="" class="form-control"
                                    formControlName="ctclRqdtlsCellContact">
                                <div *ngIf="reqDtls.get('ctclRqdtlsCellContact').invalid && reqDtls.get('ctclRqdtlsCellContact').touched"
                                    class="error nocase">

                                    <div
                                        *ngIf="reqDtls.get('ctclRqdtlsCellContact').errors['pattern']; else otherCellIssue">
                                        {{ctclRequestValidationFields.ctclRqdtlsCellContact[1].message}}</div>

                                    <ng-template #otherCellIssue>
                                        <div
                                            *ngIf="reqDtls.get('ctclRqdtlsCellContact').errors['maxlength'] || reqDtls.get('ctclRqdtlsCellContact').errors['required']">
                                            {{ctclRequestValidationFields.ctclRqdtlsCellContact[0].message}}</div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 form-group">
                                <label>Email ID<span class="red">*</span></label>
                                <input type="text" value="" class="form-control" formControlName="ctclRqdtlsEmail">
                                <div *ngIf="reqDtls.get('ctclRqdtlsEmail').invalid && reqDtls.get('ctclRqdtlsEmail').touched"
                                    class="error nocase">

                                    <div *ngIf="reqDtls.get('ctclRqdtlsEmail').errors['email']; else otherEmailIssue">
                                        {{ctclRequestValidationFields.ctclRqdtlsEmail[1].message}}</div>

                                    <ng-template #otherEmailIssue>
                                        <div
                                            *ngIf="reqDtls.get('ctclRqdtlsEmail').errors['maxlength'] || reqDtls.get('ctclRqdtlsEmail').errors['required']">
                                            {{ctclRequestValidationFields.ctclRqdtlsEmail[0].message}}</div>
                                    </ng-template>
                                </div>
                            </div>

                            <ng-container *ngIf="displayFromReport==='YES'; else relationshipForNonReport">
                                <div class="col-lg-6 form-group">
                                    <label>Relationship of the approved person operating CTCL Terminal with trading
                                        member<span class="red">*</span></label>
                                    <input type="text" value="" class="form-control"
                                        formControlName="ctclRqdtlsRelationship">
                                </div>
                            </ng-container>

                            <ng-template #relationshipForNonReport>
                                <div class="col-lg-6 form-group">
                                    <label>Relationship of the approved person operating CTCL Terminal with trading
                                        member<span class="red">*</span></label>

                                    <select class="form-control relationShipSelectpicker"
                                        formControlName="ctclRqdtlsRelationship" data-style="btn-info"
                                        title="Select Relationship">
                                        <option selected value="">Select</option>
                                        <option *ngFor="let relationship of ctclRelationList"
                                            value="{{relationship.refValue}}">
                                            {{relationship.refDesc}}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>
                        </div>

                        <ng-container
                            *ngIf="reqLocation.get('ctclLocdtlsOfficeStatus').value==='REGISTERED SUB-BROKER OFFICE'">
                            <div class="card mt-3">
                                <div class="card-header">
                                    Details Of The Proposed User
                                </div>
                                <div class="card-body">
                                    <div formGroupName="ctclRqdtlsSubBrokerId">

                                        <div class="row">
                                            <div class="col-lg-6 form-group">
                                                <label>Sub Broker Code to whom CTCL Terminal is allotted&nbsp;<spane
                                                        [style]="{color:'red'}">*</spane></label>
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclSubAuthDtlsSebiRegNo">
                                            </div>

                                            <div class="col-lg-6 form-group">
                                                <label>Name of Sub Broker to whom CTCL Terminal is allotted (if
                                                    applicable)</label>
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclSubAuthDtlsName">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="reqLocation.get('ctclLocdtlsOfficeStatus').value==='APPROVED AUTHORIZED PERSON OFFICE'">
                            <div class="card mt-3">
                                <div class="card-header">
                                    Authorized Person
                                </div>
                                <div class="card-body">
                                    <div formGroupName="ctclRqdtlsSubBrokerId">

                                        <div class="row">
                                            <div class="col-lg-6 form-group">
                                                <label>Name of Authorized Person to whom terminal is allotted</label>
                                                <input type="text" value="" class="form-control"
                                                    formControlName="ctclAuthPerName">
                                                <div class="error nocase"
                                                    *ngIf="(reqSubBroker.get('ctclAuthPerName').invalid && reqSubBroker.get('ctclAuthPerName').touched) && (reqSubBroker.get('ctclAuthPerName').errors['maxlength'])">
                                                    {{ctclRequestValidationFields.ctclAuthPerName[0].message}}</div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>

            <ng-container *ngIf="displayFromReport==='YES'">
                <div class="card mt-3">
                    <div class="card-body">
                        <ng-container *ngIf="displayFromReport ==='YES'">
                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <label>Current Status<span class="red">*</span></label>
                                    <input type="text" class="form-control" disabled #ctclRequestStatusDetails>
                                </div>

                                <ng-container
                                    *ngIf="memberOrAdminReport==='ADMIN' && (ctclReqStatus ==='Approved' || ctclReqStatus ==='Rejected')">
                                    <div class="col-lg-6 form-group">
                                        <label>Comment</label>
                                        <input placeholder="Please enter Approve/Reject Comment" type="text" [value]=""
                                            class="form-control" #approveRejectComment disabled
                                            formControlName="ctclReqRemarks">
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="memberOrAdminReport==='ADMIN' && (ctclReqStatus !='Approved' && ctclReqStatus !='Rejected') ">
                                    <div class="col-lg-6 form-group">
                                        <label>Comment</label>
                                        <input placeholder="Please enter Approve/Reject Comment" type="text"
                                            formControlName="ctclReqRemarks" class="form-control" #approveRejectComment>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="memberOrAdminReport !='ADMIN'">
                                    <div class="col-lg-6 form-group">
                                        <label>Comment</label>
                                        <input placeholder="Please enter Approve/Reject Comment" type="text" [value]=""
                                            class="form-control" disabled #approveRejectComment
                                            formControlName="ctclReqRemarks">
                                    </div>
                                </ng-container>

                            </div>
                        </ng-container>


                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="displayFromReport!=='YES'">
                <div class="card mt-3">
                    <div class="card-header">
                        Declaration
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="declarationCertified"
                                        (change)="declarationCertifiedChanged($event)">
                                    <p class="form-check-label" for="declarationCertified">I/We declare to abide by
                                        the
                                        rules/regulations as communicated by the Exchange/SEBI/IFSCA/other Regulators from
                                        time to
                                        time.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </ng-container>


            <ng-container *ngIf="memberOrAdminReport==='NA' && displayFromReport==='NO'">

                <div class="card mt-3">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-lg-5">
                                <div class="row">

                                    <div class="col-lg-3">
                                        <button type="button" class="btn btn-primary col-12"
                                            (click)="verifyOtpComponentCall()" data-toggle="modal"
                                            [disabled]="ctclRequest.invalid || errorInactivationDate || errorInbirthDate || errorInvalidityDate"
                                            data-target="#ctclactivation">Certify</button>
                                    </div>

                                    <div class="col-lg-3">
                                        <button type="submit" class="btn btn-primary col-12"
                                        [disabled]="ctclRequest.invalid || !declarationCertified || !verifyOTP || errorInactivationDate|| errorInbirthDate || errorInvalidityDate"
                                            (click)="ctclIdActivationSubmit()">Submit</button>
                                    </div>
                                    <div class="col-lg-3">
                                        <button type="button" class="btn btn-secondary col-12"
                                            (click)="resetFormData()">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="memberOrAdminReport==='ADMIN' && displayFromReport==='YES' && (ctclReqStatus !=='Approved' && ctclReqStatus !=='Rejected'); else displayBackButton ">
                <div class="card mt-3">
                    <div class="card-body">

                        <app-ctcl-id-approvereject
                            (approveOrRejectSelection)="ctclApproveRejAction($event)"></app-ctcl-id-approvereject>
                    </div>
                </div>
            </ng-container>

            <ng-template #displayBackButton>
                <ng-container *ngIf="displayFromReport==='YES'">
                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-3">
                                    <button type="button" class="btn btn-secondary col-4"
                                        (click)="ctclApproveRejAction('Back')">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-template>
        </div>
    </form>
</div>

<div data-keyboard=false data-backdrop=false class="modal fade" id="ctclactivation" tabindex="-1" role="dialog"
    aria-labelledby="ctclactivationLabel" aria-hidden="true" keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
            </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verify-request-by-otp (newItemEvent)="newItemEvent($event)"
                #verifyOtpComponent></app-verify-request-by-otp>
        </div>
    </div>
</div>