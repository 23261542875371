import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { CtclService } from 'src/app/service/ctcl.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError } from 'rxjs/operators';
import { config } from 'src/app/valueObject/config';
import { DataTableDirective } from 'angular-datatables';
import { CtclreportGridReq, DisplayCtclIdReport } from '../ctcl-request-response';
import { CtclIdActivationComponent } from '../ctcl-id-activation/ctcl-id-activation.component';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-ctcl-id-request-report',
  templateUrl: './ctcl-id-request-report.component.html',
  styleUrls: ['./ctcl-id-request-report.component.css']
})
export class CtclIdRequestReportComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

  ctclRequestTypeList: any[] = [];
  ctclReportRequest: FormGroup;
  ctclRequestDisabledFields: any = {};
  dtOptions: any={};
  dtTrigger: Subject<any> = new Subject<any>();
  config: any = config;
  ctclGridList: any[] = [];
  ctclReportList: any[] = [];
  ctclreportGridReq: CtclreportGridReq;
  ctclRequestTypeSelected: number;
  displayFromReport: string = "YES";
  memberOrAdminReport: string = "MEMBER";
  ctclRequestIdReport: string = "";
  ctclEntityType: string = "";
  memberNameList: any[] = [];
  noSearchField:string[]=[];


  // @ViewChild(CtclIdActivationComponent, { static: false }) ctclIdActivationComponent: CtclIdActivationComponent;
  //activeComponentRef: ComponentRef<CtclIdActivationComponent>;

  constructor(private ctclService: CtclService, private fb: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService, private cdref: ChangeDetectorRef, private router: Router) {

  }

  ngOnInit(): void {

    this.ctclRequestTypeList = this.ctclService.ctclRequestType;
    this.ctclEntityType = this.ctclService.entityType;
    this.noSearchField=['ctcl-memberName-nosearch','ctcl-memberCode-nosearch','ctcl-terminal-id-nonsearch',''];


    this.ctclReportRequest = this.fb.group({
      ctclMemberName: [this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memName],
      ctclMemberCode: [this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memCode],
      ctclMemId: [this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memId],
      //ctclEntityType: [this.ctclService.entityType],
      ctclRqdtlsTerminalId: [""],
      ctclRequestType: [""],
      ctclFromDate: [""],
      ctclToDate: [""]
    });



    this.ctclRequestDisabledFields = {
      ctclMemberNameDisable: true,
      ctclMemberCodeDisable: true

    }

    // this.dtOptions = {
    //   scrollX: true,
    //   paging: true,
    //   responsive: true,
    //   processing: true,
    //   orderCellsTop: true,
    //   dom: "Blrtip",
    //   pagingType:'full_numbers',
    //   buttons: [
    //     {
    //       extend: 'copy',
    //       titleAttr: 'Copy',
    //       exportOptions: {
    //         columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    //       }
    //     }, {
    //       extend: 'excel',
    //       titleAttr: 'Export to excel',
    //       exportOptions: {
    //         columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    //       }
    //     }, {
    //       extend: 'pdf',
    //       orientation: 'landscape',
    //       pageSize: 'A4',
    //       titleAttr: 'Download as PDF',
    //       exportOptions: {
    //         columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    //       }
    //     },
    //   ],
    //   rowCallback:(row:Node, data: any[] | Object, index:Number)=>{
    //     const self=this;
    //     $('td',row).off('click');
    //     $('td',row).on('click',()=>{
    //       self.someClickHandler(data);
    //     });
    //     return row;
    //   }

      

    // };
  }

  someClickHandler(info:any): void{
    console.log("Row Clicked::"+info.id);
  }
 

  getctclReportBasedOnReset() {
    this.ctclReportRequest.reset();
    this.ctclReportRequest.patchValue({
      ctclMemberName: this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memName,
      ctclMemberCode: this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memCode,
      ctclMemId: this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memId,
      ctclRqdtlsTerminalId: "",
      ctclRequestType: "",
      ctclFromDate: "",
      ctclToDate: ""
    })

    this.cdref.detectChanges()
    $('.requestTypePicker').selectpicker('refresh');

    this.displayList();
  }

  ngAfterViewInit() {
    this.loadCtclRequestType();
    this.displayList();
  }

  getctclReportBasedOnFilter() {
    this.displayList();
  }

  displayList() {
    this.spinner.show();
    this.ctclreportGridReq = {
      memName: this.ctclReportRequest.get('ctclMemberName').value,
      memCode: this.ctclReportRequest.get('ctclMemberCode').value,
      memId: this.ctclReportRequest.get('ctclMemId').value,
      ctclRqdtlsTerminalId: this.ctclReportRequest.get('ctclRqdtlsTerminalId').value,
      ctclReqType: this.ctclReportRequest.get('ctclRequestType').value,
      fromDate: this.ctclReportRequest.get('ctclFromDate').value,
      toDate: this.ctclReportRequest.get('ctclToDate').value
    }

    let verifyFormEmpty: boolean = true;

    Object.keys(this.ctclReportRequest.controls).forEach(formControl => {
      if (formControl === 'ctclMemberCode' || formControl==='ctclMemId') {
        if (((this.ctclReportRequest.controls[formControl]).value) === ' ') {
          verifyFormEmpty = false;
        }
      }
      else {
        if (((this.ctclReportRequest.controls[formControl]).value).trim() !== "") {
          verifyFormEmpty = false;
        }
      }
    });

    if (verifyFormEmpty.valueOf() == true) {
      this.spinner.hide();
      //this.setDatatable();
    }
    else {
      this.ctclService.displayGridList(this.ctclreportGridReq).pipe(
        catchError((error: any) => {
          this.spinner.hide();
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.ctclGridList = [];
          this.setDatatable();
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.statusCode == 200) {
            if (response.data === "") {
              // Do Nothing
              this.ctclGridList = [];
              this.setDatatable();
            }
            else {
              if (this.ctclEntityType !== 'ENIT') {
                this.ctclGridList = response.data.filter((responseData) => Number(responseData.memCode) === this.ctclReportRequest.get('ctclMemberCode').value);
                this.ctclService.addCtclReportList(this.ctclGridList);
              }

              else {
                this.ctclGridList = response.data.filter((responseData) => Number(responseData.memCode) === this.ctclService.memCode);
                this.ctclService.addCtclReportList(this.ctclGridList);
              }
              this.setDatatable();
            }
          } else {
            this.toastr.error(response.message);
            this.setDatatable();
          }

        }
      );
    }
    // }
  }

  setDatatable() {
    $("#ctclRequestDataGrid").DataTable().destroy();
    setTimeout(() => {
      $("#ctclRequestDataGrid").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }],
        initComplete: function () {
          // this.api().columns().every(function () {
          //   $('input',this.footer()).on('keyup change', function () {
          //     if(this['name'] !==''){
          //         // Dont do filtering on these whitelisted field
          //     }
          //     else{
          //     $('#ctclRequestDataGrid').DataTable()
          //       .column($(this).parent().index())
          //       .search(this.value)
          //       .draw();
          //   }
          // }
          //   );
          // })

          this.api().columns().every(function () {
            $('#ctclRequestDataGrid .column-search input').val('');
            $('#ctclRequestDataGrid .column-search select').val('.*');
            $('#ctclRequestDataGrid .column-search').on('keyup change', 'input', function () {
              $('#ctclRequestDataGrid').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#ctclRequestDataGrid .column-search').on('keyup change', 'select', function () {
              $('#ctclRequestDataGrid').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        },
      });
      this.cdref.detectChanges();
    }, 1);
  }
  clearSearch() {
    this.setDatatable();
  }

  setRequestType(ctclRequestId: string) {
    this.displayFromReport = "YES";
    if (this.ctclEntityType !== 'ENIT') {
      this.memberOrAdminReport = "ADMIN";
    }
    else {
      this.memberOrAdminReport = "MEMBER";
    }
    let modifiedRequestId = ctclRequestId.replace("\\", "_").replace("\\", "_");
    this.ctclRequestIdReport = modifiedRequestId;
    const ctclReportData = this.ctclService.getCtclReportByRef(ctclRequestId);

    if(ctclReportData.ctclReqType===1){
      this.router.navigate(['eTrade/eMemberReportingPreTrade/displayCtclActivation'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport,"displayFromReport": this.displayFromReport,"memberOrAdminReport": this.memberOrAdminReport } })
    }
    if(ctclReportData.ctclReqType===2){
      this.router.navigate(['eTrade/eMemberReportingPreTrade/displayCtclDeactivation'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport,"displayFromReport": this.displayFromReport,"memberOrAdminReport": this.memberOrAdminReport } })
    }
    if(ctclReportData.ctclReqType===3){
      this.router.navigate(['eTrade/eMemberReportingPreTrade/displayCtclModification'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport,"displayFromReport": this.displayFromReport,"memberOrAdminReport": this.memberOrAdminReport } })
    }

    // if (ctclReportData.ctclReqType === 1) {
    //   this.router.navigate(['enit/eTrade/memberReportingPreTrade/displayCtclActivation'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport, "displayFromReport": this.displayFromReport, "memberOrAdminReport": this.memberOrAdminReport } })
    // }
    // if (ctclReportData.ctclReqType === 2) {
    //   this.router.navigate(['enit/eTrade/memberReportingPreTrade/displayCtclDeactivation'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport, "displayFromReport": this.displayFromReport, "memberOrAdminReport": this.memberOrAdminReport } })
    // }
    // if (ctclReportData.ctclReqType === 3) {
    //   this.router.navigate(['enit/eTrade/memberReportingPreTrade/displayCtclModification'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport, "displayFromReport": this.displayFromReport, "memberOrAdminReport": this.memberOrAdminReport } })
    // }


    //this.router.navigate(['enit/eTrade/memberReportingPreTrade/displayCtclActivation'], { "queryParams": { "ctclRequestIdReport": this.ctclRequestIdReport,"displayFromReport": this.displayFromReport,"memberOrAdminReport": this.memberOrAdminReport } })
  }

  loadCtclRequestType() {
    this.ctclService.loadCtclRequestType().subscribe((responseData: DisplayCtclIdReport) => {
      if (responseData.status === "OK" && responseData.statusCode === 200) {
        this.memberNameList = responseData.data.memDetList;
      }
    });

    this.cdref.detectChanges()
    $('.requestTypePicker').selectpicker('refresh');

  }


  memNameSelection() {
    const memName = this.ctclReportRequest.get('ctclMemberName').value;

    if (memName === '') {
      this.ctclReportRequest.patchValue({
        ctclMemberName: this.ctclService.memName,
        ctclMemberCode: this.ctclService.memCode,
        ctclMemId: this.ctclService.memId
      });
    }
    else {
      const memberDetils = (this.memberNameList.filter((memberData) => memberData.spName === memName)[0]);
      this.ctclReportRequest.patchValue({
        ctclMemberCode: (Number(memberDetils.symbol)),
        ctclMemId: memberDetils.spMemId
      });
    }
  }

  memCodeSelection() {

    const memCode = this.ctclReportRequest.get('ctclMemberCode').value;
    if (memCode === '') {
      this.ctclReportRequest.patchValue({
        ctclMemberName: this.ctclService.memName,
        ctclMemberCode: this.ctclService.memCode,
        ctclMemId: this.ctclService.memId
      });
    }
    else {
      const memberDetils = (this.memberNameList.filter((memberData) => memberData.symbol === memCode)[0]);
      this.ctclReportRequest.patchValue({
        ctclMemberName: memberDetils.spName,
        ctclMemId: memberDetils.spMemId,
        ctclMemberCode: (Number(memberDetils.symbol))
      });
    }
  }
  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }
}



