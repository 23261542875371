import { Component, OnInit } from '@angular/core';
import { AppService } from '../service/app.service';
import { UserDetailsService } from '../service/user-details.service';
import { config } from 'src/app/valueObject/config';
import { MemberportalSsoService } from '../service/memberportal-sso.service';

declare var $: any;

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
	show: any;
	menuListFinal: any = [];
	user: any = {};
	// active: any;
	// step: boolean = false;
	// id: any;
	// condition1: boolean;
	active: boolean;
	config: any = config;
	hiddenMenus: any = {};

	constructor(private userDetailsService: UserDetailsService,
		private appService: AppService, private memberPortalSSO: MemberportalSsoService) { }

	ngOnInit(): void {
		this.getUserDetails();
	}

	getUserDetails() {
		this.appService.getUserDetails(0).subscribe(
			(response: any) => {
				this.userDetailsService.setUserDetails(response.data && response.data[0]);
				this.user = response.data[0];
				this.setMenuData();
			}
		);

	}

	setMenuData() {
		let entityTypeMenuData = this.config.data.entityTypeData.find(type => type.id == this.user.entityType);
		let userTypeMenuData = this.config.data.userTypeData.find(type => type.id == this.user.userType);
		let celUserTypeMenuData = this.config.data.celUserTypeData.find(type => type.id == this.user.celUserType);
		this.user.menusList.forEach(element => {
			if (celUserTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (celUserTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (celUserTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.user.menusList.forEach(element => {
			if (userTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (userTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (userTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
		this.user.menusList.forEach(element => {
			if (entityTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
				let menuData = {
					accessType: element.accessType,
					checkedBy: element.checkedBy,
					checkedDate: element.checkedDate,
					createdBy: element.createdBy,
					createdDate: element.createdDate,
					isAccessAllowed: element.isAccessAllowed,
					isActive: element.isActive,
					isDeleted: element.isDeleted,
					lastModifiedBy: element.lastModifiedBy,
					lastModifiedDate: element.lastModifiedDate,
					lvl: element.lvl,
					menuCode: element.menuCode,
					menuId: element.menuId,
					menuName: element.menuName,
					parentId: element.parentId,
					roleId: element.roleId,
					userId: element.userId,
					sappType: element.sappType,
					sdsmUrl: element.sdsmUrl,
					userMenuMapId: element.userMenuMapId,
					userMenusImpls: []
				}
				this.menuListFinal.push(menuData);
			}
			if (element.userMenusImpls) {
				element.userMenusImpls.forEach(element1 => {
					if (entityTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
						let menuData2 = {
							accessType: element1.accessType,
							checkedBy: element1.checkedBy,
							checkedDate: element1.checkedDate,
							createdBy: element1.createdBy,
							createdDate: element1.createdDate,
							isAccessAllowed: element1.isAccessAllowed,
							isActive: element1.isActive,
							isDeleted: element1.isDeleted,
							lastModifiedBy: element1.lastModifiedBy,
							lastModifiedDate: element1.lastModifiedDate,
							lvl: element1.lvl,
							menuCode: element1.menuCode,
							menuId: element1.menuId,
							menuName: element1.menuName,
							parentId: element1.parentId,
							roleId: element1.roleId,
							userId: element1.userId,
							sappType: element1.sappType,
							sdsmUrl: element1.sdsmUrl,
							userMenuMapId: element1.userMenuMapId,
							userMenusImpls: []
						}
						if (element1.userMenusImpls) {
							element1.userMenusImpls.forEach(element2 => {
								if (entityTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
									menuData2.userMenusImpls.push(element2);
								}
							});
						}
						this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
					}
				});
			}
		})
	}

	dropdown(i) {
		this.active = i;
	}

	toggle(index: string | number) {
		this.show[index] = !this.show[index];
	}

	stopRedirection(event: MouseEvent) {
		event.stopPropagation();
	}

	menuRedirection(event: MouseEvent) {
		$(".dropdown-submenu").dropdown('toggle');
		event.stopPropagation();
	}

	accessApplication(menu: any) {
		this.memberPortalSSO.submitUserIdRequest(menu.menuCode);
	}

}



