<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Half Yearly Networth</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eCompliance/halfYearlyNetworth/networthConfigSetup"
                            aria-current="page">netWorth config setup</a></li>
                </ul>
            </div>
        </div>
    </div>



    <div class="container-fluid mt-3 mb-3 theme">
        <div class="card mt-3">
            <div class="card-body">

                <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                    <p style="color: rgb(17, 5, 5)">Please wait.</p>
                </ngx-spinner>

                <div class="">

                    <div class="row clearSearch">
                        
                        <div></div>
                         <div>
                        <button type="button" class="btn btn-cancel btn-sm" aria-label="close" (click)="clearSearch()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                                viewBox="0 0 15 15">
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg> Filter</button>
                            </div>
                    </div>
                    <br>

                    <div>
                        <button type="button" class="btn btn-cancel btn-sm ; text-center" style="width: 100px" aria-label="close" aria-hidden="true" data-toggle="modal" data-target="#addNetworth" >Add</button>
                    </div>
                    
                    <table id="networthDataTable"
                        class="table common-table table-bordered table-striped table-hover display nowrap" datatable
                        [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
                        <thead>
                            <tr>
                                <th class="pre-wrap">SR No.</th>
                                <th class="pre-wrap">Membership Type</th>
                                <th class="pre-wrap">Minimum Networth Requirement</th>
                                <th class="pre-wrap">Edit</th>
                                <th class="pre-warp">Delete</th>

                            </tr>

                            <tr class="column-search">
                                <th></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <!-- <th><input type="text" class="form-control form-control-sm" /></th> -->
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>


                        <tbody>
                            <tr *ngFor="let networthList of halfYearlyNetworthList; let srNo=index">
                                <td>{{srNo+1}}</td>
                                <td>{{networthList.memCatType}}</td>
                                <td>{{networthList.memMinNwrth}}</td>
                                <td><i class="fa fa-pencil" aria-hidden="true" data-toggle="modal" (click)="filterData(networthList.memCatId,networthList.memMinNwrth,networthList.memCatType)"   
                                        data-target="#editNetworth"></i></td>
                                <td><i class="fa fa-trash pointer" aria-hidden="true" data-toggle="modal" (click)="deleteHalfYearlyNetworth(networthList.memCatId)"
                                        data-target="#deleteNetworth"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" aria-hidden="true" id="editNetworth">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-header">
                <h5 class="modal-title">edit Minimum Networth</h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="EditNetworthform">

                    <div class="row">
                        <div class="col-lg-4">
                        <label>Minimum Networth</label>
                    </div>
                   </div>
                   <div class="row">
                    <div class="col-lg-10">
                        <input class="form-control" type="text" formControlName="memMinNwrth">
                    </div>
                    </div>
                    <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close"
                    [disabled]="EditNetworthform.invalid"  (click)="editNetworthList()">Update</button>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deleteNetworth" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center">
                <h6>Are you sure you want to delete this entry?</h6>
                <div class="d-flex justify-content-center mt-3">
                    <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                            data-dismiss="modal">Cancel</button></div>
                    <div class="col-3"><button type="button" class="btn btn-primary full-width"
                            (click)="deleteNetworthList()" data-dismiss="modal">Delete</button></div>

                </div>
            </div>

        </div>
    </div>
</div>



<div class="modal fade" aria-hidden="true" id="addNetworth">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-header">
                <h5 class="modal-title">add Half Yearly Networth</h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="AddNetworthform">

                    <div class="row">
                        <div class="col-lg-4">
                        <label>Membership Type</label>
                    </div>
                    </div>

                   <div class="row">
                    <div class="col-lg-10">
                        <input class="form-control" type="text" formControlName="memCatType">
                    </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                        <label>Minimum Networth</label>
                    </div>
                    </div>

                   <div class="row">
                    <div class="col-lg-10">
                        <input class="form-control" type="text" formControlName="memMinNwrth">
                    </div>
                    </div>

                    <button class="btn btn-primary mt-2" data-dismiss="modal" aria-label="Close"
                    [disabled]="AddNetworthform.invalid"  (click)="addNetworthList()">add</button>
                </form>
            </div>
        </div>
    </div>
</div>