import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitService } from 'src/app/service/enit.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import * as bcrypt from 'bcryptjs';
import { config } from 'src/app/valueObject/config';
declare var $:any;
 
@Component({
  selector: 'app-disable-neat-user-id',
  templateUrl: './disable-neat-user-id.component.html',
  styleUrls: ['./disable-neat-user-id.component.css']
})
export class DisableNeatUserIdComponent implements OnInit {
  disableNeatUser: FormGroup;
  cusDlrId: any=[];
  memCode: String;
  memId: String;
  submitted = false;
  config: any = 'config';
  message: string;
  menucode = 'displayDisableNeatIdRequestPage';
  user: any = {};
  access: any = {};
  memName:string;
  entityType:any;
// for OTP
  module: any="DISABLE";
  reqtype: number = 4;
  loginId: string;
  verifyOTP: boolean;
 
  constructor(private formbuilder: FormBuilder, 
    private toastr: ToastrService,
    private EnitService: EnitService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
   ) { }
 
    newItemEvent(event: boolean){
      this.verifyOTP=event;
      console.log(this.verifyOTP);
      if(this.verifyOTP == true){
        $('#cashdeposite').modal('hide');
      }
    }
 
  ngOnInit(): void {
    this.titleService.setTitle('Disable Neat User Id');
    this.disableNeatUser = this.formbuilder.group({
      cusDlrId: ['',Validators.required],
       UserName : ['',Validators.required],
       TypeOfUser:['',Validators.required],
       ExistingTapIp:['',Validators.required],
       TapId:['',Validators.required],
       ExistingTapChildIpAddress:['',Validators.required],
       existingTerminalTapIp:['',Validators.required],
      //  EmailId:['',[Validators.required, Validators.email]],
      //  MobileNo:['',[Validators.required,
      //   Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      //   Validators.minLength(10), Validators.maxLength(10)]],
      EmailId:['',[Validators.email]],
      MobileNo:[''],
       ReasonForDisableUser:['',Validators.required],
       CheckType: [false, [Validators.pattern('true')]]
    })
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.memName = this.user.userName;
    this.entityType = this.user.entityType;
 
    this.loginId = this.user.loginId;
    this.getDealerIdDetails(this.memCode,this.memId);
  }
  get f() { return this.disableNeatUser.controls; }
  getDealerIdDetails(memCode: String,memId: String){
    this.EnitService.getDealerIdDetails(memCode,memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response:any)=>{
      if(response.statusCode==200){
        this.cusDlrId=response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
    })
  }
  selectDlrId(){
   this.EnitService.getSingleDealerIdDetails(this.memCode, this.memId, this.f.cusDlrId.value).subscribe((response:any)=>{
   if(response.data.cusDlrTidTapId==null){
  this.toastr.warning('Tap ID for User ID '+ this.f.cusDlrId.value + ' not found');
  this.f.UserName.setValue(response.data.cusDlrNm);
    this.f.TypeOfUser.setValue(response.data.cusDlrType);
    this.f.ExistingTapIp.setValue(response.data.cusDlrTapIp);
    this.f.TapId.setValue(response.data.cusDlrTidTapId);
    this.f.ExistingTapChildIpAddress.setValue(response.data.txtRegOffAddress);
    this.f.existingTerminalTapIp.setValue(response.data.noOfTerminals)
   }
   else{
    this.f.UserName.setValue(response.data.cusDlrNm);
    this.f.TypeOfUser.setValue(response.data.cusDlrType);
    this.f.ExistingTapIp.setValue(response.data.cusDlrTapIp);
    this.f.TapId.setValue(response.data.cusDlrTidTapId);
    this.f.ExistingTapChildIpAddress.setValue(response.data.txtRegOffAddress);
    this.f.existingTerminalTapIp.setValue(response.data.noOfTerminals)
   }
})
}
  reset(){
    this.disableNeatUser.reset();
    $('.selectpicker').selectpicker('refresh');
    this.submitted = false;
    this.verifyOTP = false;
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    let requestObject = {
			"memId":this.memId,
			"memCode": this.memCode,
			"cusDlrUsrId":this.f.cusDlrId.value,
			"cusDlrNm":this.f.UserName.value,
			"cusDlrType":this.f.TypeOfUser.value,
			"cusDlrTapIp":this.f.ExistingTapIp.value,
			"cusDlrTidTapId":this.f.TapId.value,
			"noOfTerminals":this.f.existingTerminalTapIp.value,
			"txtRegOffAddress":this.f.ExistingTapChildIpAddress.value,
			"cusEmail":this.f.EmailId?.value || null,
			"cusContactNo":this.f.MobileNo?.value || null,
			"cusReason":this.f.ReasonForDisableUser.value ,
    };
    this.EnitService.SaveDisableNeatUserIdRequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
 
 
 
 
        let str = response.message;
        if (response.message) {
          let salt = str.substr(0, 29);
          let encryptedMessage = bcrypt.hashSync("SUCCESSFULL", salt);
          let encryptedOtp = "";
          if (response.data[0].length > 30)
            encryptedOtp = bcrypt.hashSync(this.f.otp.value, response.data[0].substr(0, 29));
          if (encryptedMessage == response.message && response.data[0] && encryptedOtp == response.data[0]) {
            let refNo = response.data;
            this.message = 'Dealer Sent For Disablement With Reference Number: ' + refNo;
            this.toastr.success(this.message);
            this.reset();
            this.submitted=true;
 
 
          } else {
            encryptedMessage = bcrypt.hashSync("UNAUTHORIZED_USER", salt)
            if (encryptedMessage == response.message) {
              this.message = "You are not authorized to perform this operation"
              this.toastr.error(this.message);
            }
            encryptedMessage = bcrypt.hashSync("OTP_FAILED", salt)
            if (encryptedMessage == response.message) {
              this.message = "Not able to send OTP. Please try again later"
              this.toastr.error(this.message);
            }
            encryptedMessage = bcrypt.hashSync("USER_DISABLED_MESSAGE", salt)
            if (encryptedMessage == response.message) {
              this.message = "OTP is disabled for this user."
              this.toastr.error(this.message);
            }
            encryptedMessage = bcrypt.hashSync("INVALID_OTP", salt)
            if (encryptedMessage == response.message) {
              this.message = "Invalid OTP"
              this.toastr.error(this.message);
            }
            encryptedMessage = bcrypt.hashSync("OTP_ALREADY_USED", salt)
            if (encryptedMessage == response.message) {
              this.message = "OTP_ALREADY_USED"
              this.toastr.error(this.message);
            }
            encryptedMessage = bcrypt.hashSync("SERVICE_UNAVAILABLE", salt)
            if (encryptedMessage == response.message) {
              this.message = "Service is not available. Please try again later."
              this.toastr.error(this.message);
            }
          }
        }
 
 
 
        // window.location.reload();
      } else {
        this.toastr.error(response.message);
        this.submitted = false;
      }
    })
  }
}