import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { config } from 'src/app/valueObject/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { AppService } from 'src/app/service/app.service';
import { ToastrService } from 'ngx-toastr';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserControlService } from 'src/app/service/user-control.service';
declare var $: any;

@Component({
  selector: 'app-usercontrol',
  templateUrl: './usercontrol.component.html',
  styleUrls: ['./usercontrol.component.css']
})
export class UsercontrolComponent implements OnInit, OnDestroy {

  searchUserForm: FormGroup;
  submitted = false;
  config: any = config;
  userDetails: any = {};
  menuList: any = [];
  menuListFinal: any = [];
  subMenuList: any = [];
  subMenuList2: any = [];

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private appService: AppService,
    private toastr: ToastrService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private userControlService: UserControlService) { }

  ngOnInit(): void {
    this.searchUserForm = this.formBuilder.group({
      userLoginId: [null, [Validators.required, Validators.pattern("^[0-9a-zA-Z]\\S{4,}$")]],
      menuCode: [null],
      subMenuCode: [null],
      subMenuCode2: [null]
    });
  }

  ngAfterViewInit(): void {
    $('.selectpicker').selectpicker();
  }

  get f() { return this.searchUserForm.controls; }

  getMenuList() {
    this.spinner.show();
    this.appService.getUserDetailsByLoginId(this.f.userLoginId.value).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.userControlService.setUserControlId(this.f.userLoginId.value);
        this.userControlService.setUserControlDetails(response.data[0]);
        this.menuList = response.data[0].menusList?.filter(data => (data.isAccessAllowed == 'Y' && data.isActive == 'Y' && data.isDeleted == 'N') && (data.menuCode == 'margin' || data.menuCode == 'collateral' || data.menuCode == 'admin' || data.menuCode == 'bank' || data.menuCode == 'trade' || data.menuCode == 'extranet' || data.menuCode == 'cel'));
        this.userDetails = response.data[0];
        this.setMenuData();
        this.subMenuList = [];
        this.subMenuList2 = [];
        this.f.menuCode.setValue('');
        this.f.subMenuCode.setValue('');
        this.f.subMenuCode2.setValue('');
        this.router.navigate(['/usercontrol']);
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
    );
  }

  selectMenu() {
    this.subMenuList = this.menuListFinal.find(data => data.menuCode == this.f.menuCode.value).userMenusImpls?.filter(data => (data.isAccessAllowed == 'Y' && data.isActive == 'Y' && data.isDeleted == 'N'));
    this.subMenuList2 = [];
    this.f.subMenuCode.setValue('');
    this.f.subMenuCode2.setValue('');
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  selectSubMenu() {
    this.subMenuList2 = this.subMenuList.find(data => data.menuCode == this.f.subMenuCode.value).userMenusImpls?.filter(data => (data.isAccessAllowed == 'Y' && data.isActive == 'Y' && data.isDeleted == 'N'));
    this.f.subMenuCode2.setValue('');
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  onSubmit() {
    let path = '/usercontrol/' + this.f.menuCode.value + (this.f.subMenuCode.value ? '/' + this.f.subMenuCode.value + (this.f.subMenuCode2.value ? '/' + this.f.subMenuCode2.value : '') : '');
    // this.router.navigateByUrl(path, { skipLocationChange: true });
    if (this.f.userLoginId.value == this.userDetails.loginId) {
      this.router.navigate([path], { skipLocationChange: true });
    } else {
      this.toastr.warning("Please enter valid user id");

    }

  }

  setMenuData() {
    let entityTypeMenuData = this.config.data.entityTypeData.find(type => type.id == this.userDetails.entityType);
    let userTypeMenuData = this.config.data.userTypeData.find(type => type.id == this.userDetails.userType);
    let celUserTypeMenuData = this.config.data.celUserTypeData.find(type=>type.id==this.userDetails.celUserType);
    this.userDetails.menusList.forEach(element => {
      if (celUserTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
        let menuData = {
          accessType: element.accessType,
          checkedBy: element.checkedBy,
          checkedDate: element.checkedDate,
          createdBy: element.createdBy,
          createdDate: element.createdDate,
          isAccessAllowed: element.isAccessAllowed,
          isActive: element.isActive,
          isDeleted: element.isDeleted,
          lastModifiedBy: element.lastModifiedBy,
          lastModifiedDate: element.lastModifiedDate,
          lvl: element.lvl,
          menuCode: element.menuCode,
          menuId: element.menuId,
          menuName: element.menuName,
          parentId: element.parentId,
          roleId: element.roleId,
          userId: element.userId,
          userMenuMapId: element.userMenuMapId,
          userMenusImpls: []
        }
        this.menuListFinal.push(menuData);
      }
      if (element.userMenusImpls) {
        element.userMenusImpls.forEach(element1 => {
          if (celUserTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
            let menuData2 = {
              accessType: element1.accessType,
              checkedBy: element1.checkedBy,
              checkedDate: element1.checkedDate,
              createdBy: element1.createdBy,
              createdDate: element1.createdDate,
              isAccessAllowed: element1.isAccessAllowed,
              isActive: element1.isActive,
              isDeleted: element1.isDeleted,
              lastModifiedBy: element1.lastModifiedBy,
              lastModifiedDate: element1.lastModifiedDate,
              lvl: element1.lvl,
              menuCode: element1.menuCode,
              menuId: element1.menuId,
              menuName: element1.menuName,
              parentId: element1.parentId,
              roleId: element1.roleId,
              userId: element1.userId,
              userMenuMapId: element1.userMenuMapId,
              userMenusImpls: []
            }
            if (element1.userMenusImpls) {
              element1.userMenusImpls.forEach(element2 => {
                if (celUserTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
                  menuData2.userMenusImpls.push(element2);
                }
              });
            }
            this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
          }
        });
      }
    });
    this.userDetails.menusList.forEach(element => {
      if (userTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
        let menuData = {
          accessType: element.accessType,
          checkedBy: element.checkedBy,
          checkedDate: element.checkedDate,
          createdBy: element.createdBy,
          createdDate: element.createdDate,
          isAccessAllowed: element.isAccessAllowed,
          isActive: element.isActive,
          isDeleted: element.isDeleted,
          lastModifiedBy: element.lastModifiedBy,
          lastModifiedDate: element.lastModifiedDate,
          lvl: element.lvl,
          menuCode: element.menuCode,
          menuId: element.menuId,
          menuName: element.menuName,
          parentId: element.parentId,
          roleId: element.roleId,
          userId: element.userId,
          userMenuMapId: element.userMenuMapId,
          userMenusImpls: []
        }
        this.menuListFinal.push(menuData);
      }
      if (element.userMenusImpls) {
        element.userMenusImpls.forEach(element1 => {
          if (userTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
            let menuData2 = {
              accessType: element1.accessType,
              checkedBy: element1.checkedBy,
              checkedDate: element1.checkedDate,
              createdBy: element1.createdBy,
              createdDate: element1.createdDate,
              isAccessAllowed: element1.isAccessAllowed,
              isActive: element1.isActive,
              isDeleted: element1.isDeleted,
              lastModifiedBy: element1.lastModifiedBy,
              lastModifiedDate: element1.lastModifiedDate,
              lvl: element1.lvl,
              menuCode: element1.menuCode,
              menuId: element1.menuId,
              menuName: element1.menuName,
              parentId: element1.parentId,
              roleId: element1.roleId,
              userId: element1.userId,
              userMenuMapId: element1.userMenuMapId,
              userMenusImpls: []
            }
            if (element1.userMenusImpls) {
              element1.userMenusImpls.forEach(element2 => {
                if (userTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
                  menuData2.userMenusImpls.push(element2);
                }
              });
            }
            this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
          }
        });
      }
    })
    this.userDetails.menusList.forEach(element => {
      if (entityTypeMenuData.menu.includes(element.menuCode) && this.menuListFinal.filter(menu => menu.menuCode == element.menuCode).length == 0) {
        let menuData = {
          accessType: element.accessType,
          checkedBy: element.checkedBy,
          checkedDate: element.checkedDate,
          createdBy: element.createdBy,
          createdDate: element.createdDate,
          isAccessAllowed: element.isAccessAllowed,
          isActive: element.isActive,
          isDeleted: element.isDeleted,
          lastModifiedBy: element.lastModifiedBy,
          lastModifiedDate: element.lastModifiedDate,
          lvl: element.lvl,
          menuCode: element.menuCode,
          menuId: element.menuId,
          menuName: element.menuName,
          parentId: element.parentId,
          roleId: element.roleId,
          userId: element.userId,
          userMenuMapId: element.userMenuMapId,
          userMenusImpls: []
        }
        this.menuListFinal.push(menuData);
      }
      if (element.userMenusImpls) {
        element.userMenusImpls.forEach(element1 => {
          if (entityTypeMenuData.menu.includes(element1.menuCode) && (this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls && this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.filter(menu => menu.menuCode == element1.menuCode).length == 0)) {
            let menuData2 = {
              accessType: element1.accessType,
              checkedBy: element1.checkedBy,
              checkedDate: element1.checkedDate,
              createdBy: element1.createdBy,
              createdDate: element1.createdDate,
              isAccessAllowed: element1.isAccessAllowed,
              isActive: element1.isActive,
              isDeleted: element1.isDeleted,
              lastModifiedBy: element1.lastModifiedBy,
              lastModifiedDate: element1.lastModifiedDate,
              lvl: element1.lvl,
              menuCode: element1.menuCode,
              menuId: element1.menuId,
              menuName: element1.menuName,
              parentId: element1.parentId,
              roleId: element1.roleId,
              userId: element1.userId,
              userMenuMapId: element1.userMenuMapId,
              userMenusImpls: []
            }
            if (element1.userMenusImpls) {
              element1.userMenusImpls.forEach(element2 => {
                if (entityTypeMenuData.menu.includes(element2.menuCode) && (menuData2.userMenusImpls && menuData2.userMenusImpls.filter(menu => menu.menuCode == element2.menuCode).length == 0)) {
                  menuData2.userMenusImpls.push(element2);
                }
              });
            }
            this.menuListFinal[this.menuListFinal.length - 1].userMenusImpls.push(menuData2);
          }
        });
      }
    })
  }

  ngOnDestroy() {
    this.userControlService.setUserControlDetails(null);
    this.userControlService.setUserControlId(null);
  }

}
