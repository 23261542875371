import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
declare var $:any;

@Component({
  selector: 'app-half-yearly-networth-config',
  templateUrl: './half-yearly-networth-config.component.html',
  styleUrls: ['./half-yearly-networth-config.component.css']
})
export class HalfYearlyNetworthConfigComponent implements OnInit {

  halfYearlyNetworthList: any[] = [];
  config: any = config;
  EditNetworthform :FormGroup;
  AddNetworthform:FormGroup;
  message: string;
  deleteRow: number;
  memMinNwrth: String = '';
  memCatType:String='';
  memCatId:number;
  dtTrigger1: Subject<any> = new Subject<any>();
  dtOptions1:any={
    processing: true,
    dom: 'Blrtip',
    pageLength: 4,
    buttons:
      //'copy', 'excel',
      [{
        extend: 'copy',
        titleAttr: 'Copy',
        exportOptions: {
          columns: [0, 1, 2, 3, 4],
        }
      }, {
        extend: 'excel',
        titleAttr: 'Export to excel',
        exportOptions: {
          columns: [0, 1, 2, 3, 4],
        }
      }, {
        extend: 'pdf',
        titleAttr: 'Download as PDF',
        exportOptions: {
          columns: [0, 1, 2, 3, 4],
        }
      }]
  };
  
  
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(
    private enitComplianceService: EnitComplianceServiceService,
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private cdref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.viewMemeberNetworthList();


     this.EditNetworthform = this.formbuilder.group({
      memCatId :[''],
      memMinNwrth:['', [Validators.required, Validators.pattern("(^[0-9]*$)")]],
      memCatType:['']

     })

     this.AddNetworthform = this.formbuilder.group({
      memCatId :[''],
      memMinNwrth:['', [Validators.required, Validators.pattern("(^[0-9]*$)")]],
      //memMinNwrth:['', [Validators.required, Validators.pattern("([0-9]{20})")]],
      memCatType:['', [Validators.required, Validators.pattern("[a-zA-Z]*$")]]

     })
  }

  get f() { return this.EditNetworthform.controls; }

  get fadd() { return this.AddNetworthform.controls; }


viewMemeberNetworthList()
  {
    $("#networthDataTable").DataTable().destroy();

   this.enitComplianceService.viewMemeberNetworthList().pipe(
     catchError((error :any)=>{
     this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
     this.halfYearlyNetworthList = [];
     this.dtTrigger1.next(true);
     this.setSearchFunction();
     this.cdref.detectChanges();
   if(error.status!=200){
     return throwError(error.error);
   }
  })).subscribe((response:any) =>{
  if(response.statusCode =200){
     this.halfYearlyNetworthList = response.data;
     this.dtTrigger1.next(true);
     this.setSearchFunction();
     this.cdref.detectChanges();
 } else {
     this.halfYearlyNetworthList = [];
     this.toastr.error(response.message);
     this.dtTrigger1.next(true);
     this.setSearchFunction();
     this.cdref.detectChanges();
}})
}

setSearchFunction() {
  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.columns().every(function () {
      $('#networthDataTable .column-search').on('keyup change', 'input', function () {
        $('#networthDataTable').DataTable()
          .column($(this).parent().index())
          .search(this.value)
          .draw();
      });
    });
  });

  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.columns().every(function () {
      $('#networthDataTable .column-search').on('keyup change', 'select', function () {
        $('#networthDataTable').DataTable()
          .column($(this).parent().index())
          .search("^" + this.value + "$", true, false, false)
          .draw();
      });
    });
  });
}

clearSearch() {
  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.columns().every(function (col) {
      $('#networthDataTable .column-search input').val('');
      $('#networthDataTable .column-search select').val('.*');
      $('#networthDataTable').DataTable()
        .column(col)
        .search('', true)
        .draw();
    })
  })
}


filterData(memCatId: number, memMinNwrth: String, memCatType : String){
  this.memCatId=memCatId
  this.f.memCatId.setValue(this.memCatId);
  this.memMinNwrth=memMinNwrth
  this.f.memMinNwrth.setValue(this.memMinNwrth);
  this.memCatType=memCatType
  this.f.memCatType.setValue(this.memCatType);
}

editNetworthList(){
  $("#networthDataTable").DataTable().destroy();
  let requestObject = {

   "memCatId": this.f.memCatId.value,
   "memMinNwrth": this.f.memMinNwrth.value,
   "memCatType":this.f.memCatType.value
    
  }
   this.enitComplianceService.editNetworth(requestObject).pipe(
     catchError((error: any) => {
       this.toastr.error(
         this.config.failureResponse.SOMETHING_WENT_WRONG);
       if (error.status != 200) {
        return throwError(error.error);
       }
     })
   ).subscribe((response: any) => {
     if (response.statusCode == 200) {
       this.toastr.success(" Minimum NetWorth Updated Successfully");
       this.viewMemeberNetworthList();
       $('#editNetworth').modal('hide');
       this.cdref.detectChanges();
    }
     else
       this.toastr.error(response.message);
   })

}

deleteHalfYearlyNetworth(id: number){
  this.deleteRow = id;
}

deleteNetworthList(){
  $("#networthDataTable").DataTable().destroy();
   this.enitComplianceService.deleteNetworthMember(this.deleteRow).pipe(
     catchError((error: any) => {
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
       if (error.status != 200) {
         return throwError(error.error);
       }
     })
   ).subscribe(
     (response: any) => {
       if (response.statusCode == 200) {
         this.message = "networth ID Deleted";
         this.toastr.success(this.message);
         this.viewMemeberNetworthList();
         $('#deleteNetworth').modal('hide');
         this.cdref.detectChanges();

      }
       else {
         this.toastr.warning(this.config.failureResponse.trade[response.statusCode]);
       }
     }

   )
 }


 addNetworthList() {
  let requestObject = {
    "memCatType":this.fadd.memCatType.value,
    "memMinNwrth": this.fadd.memMinNwrth.value
  }
  this.enitComplianceService.saveForNetworth(requestObject).pipe(
    catchError((error: any) => {
      this.toastr.error(
        this.config.failureResponse.SOMETHING_WENT_WRONG);
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
  ).subscribe((response: any) => {
    if (response.statusCode == 200) {
      this.toastr.success("Half Yeraly Networth added Successfully");
      this.viewMemeberNetworthList();
      this.fadd.memCatType.setValue(null);
      this.fadd.memMinNwrth.setValue(null);
      $('#addNetworth').modal('hide');
      this.cdref.detectChanges();
    }
    else {
      this.toastr.warning(response.message);
      this.viewMemeberNetworthList();
      this.fadd.memCatType.setValue(null);
      this.fadd.memMinNwrth.setValue(null);
      $('#addNetworth').modal('hide');
      this.cdref.detectChanges();
    }
  })
}

 ngOnDestroy(): void {
  this.dtTrigger1.unsubscribe();
  }

}
