<form [formGroup]='validateUserForm' (ngSubmit)="onSubmit()">

    <div id="account-locked" class="accountlock">
        <div class="row">
            <div class="col-sm-12 form-group">
                <label>Login ID</label>
                <input type="text" class="form-control" id="username" formControlName="username" [pattern]="userPattern" autocomplete="off">
                <p *ngIf="f.username.invalid && f.username.touched && f.username.errors?.required" class="error nocase">
                    Please enter Login ID
                </p>
                <p *ngIf="f.username.invalid && f.username.touched && f.username.errors?.pattern" class="error nocase">
                    Please enter a valid Login ID
                </p>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 form-group">
                <div class="row">
                    <div class="col-lg-10">
                        <div class="captcha-frame"><img class='imgCaptcha' src="{{captchaSource}}"></div>
                    </div>
                    <div class="col-lg-2">
                        <div class=" captcha-frame mlspacing"><svg class="reloadimg" version="1.1" id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                y="0px" viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;"
                                xml:space="preserve" (click)="reloadCaptcha()">
                                <g>
                                    <g>
                                        <path d="M409.6,0c-9.426,0-17.067,7.641-17.067,17.067v62.344C304.667-5.656,164.478-3.386,79.411,84.479
                       c-40.09,41.409-62.455,96.818-62.344,154.454c0,9.426,7.641,17.067,17.067,17.067S51.2,248.359,51.2,238.933
                       c0.021-103.682,84.088-187.717,187.771-187.696c52.657,0.01,102.888,22.135,138.442,60.976l-75.605,25.207
                       c-8.954,2.979-13.799,12.652-10.82,21.606s12.652,13.799,21.606,10.82l102.4-34.133c6.99-2.328,11.697-8.88,11.674-16.247v-102.4
                       C426.667,7.641,419.026,0,409.6,0z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M443.733,221.867c-9.426,0-17.067,7.641-17.067,17.067c-0.021,103.682-84.088,187.717-187.771,187.696
                       c-52.657-0.01-102.888-22.135-138.442-60.976l75.605-25.207c8.954-2.979,13.799-12.652,10.82-21.606
                       c-2.979-8.954-12.652-13.799-21.606-10.82l-102.4,34.133c-6.99,2.328-11.697,8.88-11.674,16.247v102.4
                       c0,9.426,7.641,17.067,17.067,17.067s17.067-7.641,17.067-17.067v-62.345c87.866,85.067,228.056,82.798,313.122-5.068
                       c40.09-41.409,62.455-96.818,62.344-154.454C460.8,229.508,453.159,221.867,443.733,221.867z" />
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group">
                <label>Enter Displayed Text</label>
                <input type="text" class="form-control" id="entertext" formControlName="captcha" [pattern]="captchaPattern" autocomplete="off">
                <p *ngIf="f.captcha.invalid && f.captcha.touched && f.captcha.errors?.required" class="error nocase">
                    Captcha is required
                </p>
                <p *ngIf="f.captcha.invalid && f.captcha.touched && f.captcha.errors?.pattern" class="error nocase">
                    Please enter a valid captcha
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 mb-1">
                <button class="btn btn-primary full-width" type="submit" id="btnaccountlock"
                    [disabled]="validateUserForm.invalid">{{submitButton}}
                </button>
            </div>
            <div class="col-lg-4 mb-1">
                <button class="btn btn-cancel full-width" type="button" id="backaccountlock"
                    routerLink="/login">Back</button>
            </div>
        </div>
    </div>
</form>
