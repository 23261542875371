import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RbdServiceService } from '../service/rbd-service.service';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-remote-broker-dealer',
  templateUrl: './remote-broker-dealer.component.html',
  styleUrls: ['./remote-broker-dealer.component.css']
})
export class RemoteBrokerDealerComponent {

  rbdRegistrationForm: FormGroup;
  showOther: boolean = false;
  listofMemberExchange: any[];
  isDisplayVerify: boolean = false;
  isDisplaySendOtp: boolean = true;
  listofClearingMember: any[];
  listofCountryDetails: ExchangeRegulatorResponse[];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  selectedClearingMember: string;
  isSelected: boolean = false;
  resendDisable: boolean = false;
  subscription: Subscription;
  timeVal: number;
  responseFileName: string;
  fileError: boolean;
  exchanges: ExchangeDTO[];
  regulators: RegularotDTO[];

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private titleService: Title, private rbdservice: RbdServiceService,
    private datePipe: DatePipe) {
  }

  get f() { return this.rbdRegistrationForm.controls; }

  ngOnInit(): void {
    console.log(this.datePipe.transform('2024-04-07T13:29:48.000+00:00', 'dd-MM-yyyy'));

    // [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY'}"

    // $('#successModal').modal('show');

    this.getParameterFromType();
    this.celaringMeberDetails();
    this.contryDetails();


    this.rbdRegistrationForm = this.formBuilder.group({
      entityName: [null, [Validators.required]],
      legalIdentifier: [null],
      country: [null, [Validators.required, Validators.pattern("^[0-9]{1,}$")]],
      exchange: [null, [Validators.required, Validators.pattern("^[0-9]{1,}$")]],
      regulator: [''],
      // otherExchange: [null, [Validators.required]],
      otherExchange: [''],
      rbdcpn: [null, [Validators.required]],
      cnumber: [null, [Validators.required]],
      desg: [null, [Validators.required]],
      email: [null, [Validators.required]],
      memberShip: [''],
      file: [''],
      otp: [null, Validators.required],
      remarks: [''],
      clearingMember: ['']
    })
  }

  filterExchanges() {
    // console.log(this.f.country.errors);

    let selectedCountryId: number = this.rbdRegistrationForm.get('country').value;

    this.exchanges = this.listofCountryDetails.find(country => country.id == selectedCountryId)?.exchanges;
  }

  filterRegulators() {
    // console.log(this.f.exchange);

    let exchangeId: number = this.rbdRegistrationForm.get('exchange').value;

    this.regulators = this.exchanges.find(exchange => exchange.exchangeId == exchangeId)?.regulator;
  }

  getExchangeAndRegulators() {
    // console.log(this.rbdRegistrationForm.get('country'));
    this.spinner.show();

    this.rbdservice.getExchangeAndRegulators(this.rbdRegistrationForm.get('country').value)
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.exchanges = response.data;

          this.spinner.hide();
        }
      });
  }

  //for File upload
  onChange(event) {
    if (event.target.files[0]) {
      this.spinner.show();

      if (event.target.files[0].type == 'application/pdf' || event.target.files[0].type.includes('image')) {
        this.fileError = false;
        this.rbdservice.uploadFile(event.target.files[0])
          .subscribe((response: any) => {
            if (response.statusCode == 200) {
              this.responseFileName = response.data;

              this.toastr.success(response.message);
              this.spinner.hide();
            }
          });
      }

      else {
        this.fileError = true;
      }
    }
  }

  setDatatable() {
    $("#userList").DataTable().destroy();
    setTimeout(() => {
      $("#userList").DataTable({
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons:
          [{
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          }, {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          }, {
            extend: 'pdf',
            orientation: 'landscape',
            pageSize: 'A4',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: [0, 1, 2, 3],
            }
          }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#userList .column-search input').val('');
            $('#userList .column-search select').val('.*');
            $('#userList .column-search').on('keyup change', 'input', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#userList .column-search').on('keyup change', 'select', function () {
              $('#userList').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }


  hideShow() {
    $('#otp').show();
    this.fileError = false;
  }

  otherSelection() {
    if (this.f.exchange.value == '-1') {
      // this.f.exchange.setValidators([Validators.required]);
      this.f.exchange.updateValueAndValidity();
    }

    if (this.f.regulator.value == '-1') {
      // this.f.regulator.setValidators([Validators.required]);
      this.f.regulator.updateValueAndValidity();
    }
  }

  saveDetails() {
    // console.log(this.rbdRegistrationForm.invalid);

    if (this.rbdRegistrationForm.get('memberShip').value == 'N') {
      $("#errorModal").modal('show');
    } else {
      let countryDetails: ExchangeRegulatorResponse = this.listofCountryDetails.find(country => country.id == this.rbdRegistrationForm.get('country').value);
      let exchangeDetails: ExchangeDTO = countryDetails.exchanges.find(exchange => exchange.exchangeId == this.rbdRegistrationForm.get('exchange').value);
      let regulatorDetails: RegularotDTO = exchangeDetails.regulator.find(regulator => regulator.regulatorId == this.rbdRegistrationForm.get('regulator').value);

      this.spinner.show();
      let details =
      {
        "entityName": this.f.entityName.value,
        "country": countryDetails.countryName,
        "legalEntityIdentifier": this.f.legalIdentifier.value,
        "memExchange": exchangeDetails.exchangeName,
        "regulator": regulatorDetails?.regulatorName,
        "contactPersonName": this.f.rbdcpn.value,
        "contactNumber": this.f.cnumber.value,
        "designation": this.f.desg.value,
        "email": this.f.email.value,
        "url": "",
        "currentMembershipFlag": this.f.memberShip.value,
        "preferedClearingMember": this.selectedClearingMember,
        "fileName": this.responseFileName,
        "remarks": this.f.remarks.value
      }

      // console.log(details);

      this.rbdservice.saveDetails(details).pipe(
        catchError((error: any) => {
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          $('#successModal').modal('show');
          // this.toastr.success(response.message);
          // this.router.navigate(['/rbdThanyou']);
          this.reset();
          this.spinner.hide();
        } else {
          this.toastr.warning(response.message);
        }
      });
    }
  }

  getParameterFromType() {
    this.rbdservice.getParameterFromType('Member_Of_The_Exchange').pipe(
      catchError((error: any) => {
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.listofMemberExchange = response.data;
        this.cdref.detectChanges;
      }
    });
  }

  sendOtpOnForRbdEmail() {
    let otpBody = {
      "emailId": this.f.email.value,
      "mobileNo": this.f.cnumber.value,
    }

    this.rbdservice.sendOtpOnForRbdEmail(otpBody).pipe(
      catchError((error: any) => {
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("Otp Send Sucessfully");
        this.isDisplayVerify = true;
        this.resendDisable = true;
        this.isDisplaySendOtp = false;
        this.startResendTimer(2 * 60);
        setTimeout(() => {
          this.resendDisable = false;
          this.stopTimer();
        }, 2 * 60 * 1000);
      }
    });
  }

  verfiyRbdOtp() {
    let otpBody = {
      "emailId": this.f.email.value,
      "mobileNo": this.f.cnumber.value,
      "otp": this.f.otp.value,
    }

    this.rbdservice.verfiyRbdOtp(otpBody).pipe(
      catchError((error: any) => {
        if (error.status != 200) {


          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.toastr.success("OTP Verified Sucessfully");
        this.isDisplayVerify = false;
        this.isDisplaySendOtp = false;
        this.rbdRegistrationForm.get('otp').disable();
      }
      else if (response.statusCode == 5) {
        this.toastr.error("Invalid OTP");
      }
      else if (response.statusCode == 6) {
        this.toastr.warning("OTP Expired");
      }
      else if (response.statusCode == 4) {
        this.toastr.warning("OTP ALREADY USED");
      }
    });
  }

  celaringMeberDetails() {
    this.rbdservice.celaringMeberDetails().pipe(
      catchError((error: any) => {
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.listofClearingMember = response.data;
        this.setDatatable();

      }
    });
  }



  onclickOnRadio(data: any) {
    this.isSelected = true;
    this.selectedClearingMember = data.name;
    this.f.clearingMember.setValue(data.name);
    // console.log(this.selectedClearingMember);
  }


  startResendTimer(time) {
    const source = timer(0, 1000);
    this.subscription = source.subscribe(val => {
      this.timeVal = time - val;
      if (this.timeVal === 0) {
        this.stopTimer();
      }
    })
  }


  stopTimer() {
    this.subscription.unsubscribe();
  }

  reset() {
    this.rbdRegistrationForm.reset();
    this.selectedClearingMember = null;
    this.isDisplayVerify = false;
    this.resendDisable = false;
    this.isDisplaySendOtp = true;
    this.rbdRegistrationForm.get('otp').enable();
    this.f.exchange.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');

  }


  contryDetails() {
    this.rbdservice.countryDetails().pipe(
      catchError((error: any) => {
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.listofCountryDetails = response.data;
      }
    });
  }


}

export class ExchangeRegulatorResponse {
  id: number;
  countryName: string;
  exchanges: ExchangeDTO[];
}

export class ExchangeDTO {
  exchangeId: number;
  exchangeName: string;
  regulator: RegularotDTO[];
}

export class RegularotDTO {
  regulatorId: number;
  regulatorName: string;
}