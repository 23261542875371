import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BranchUserService {
  branchUserData: any = [];

  constructor() { }

  setBranchUserData(branchUserData) {
    this.branchUserData = branchUserData;
  }

  getBranchUserData() {
    return this.branchUserData;
  }

  getUserById(userId) {
    return this.branchUserData.find(data => data.userId == userId);
  }

}
