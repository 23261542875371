import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/valueObject/config';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  config: any = config;

  constructor(private http: HttpClient) { }

  logout(user: any) {
    let URL = environment.URL + this.config.URL.logoutURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

  getUserList(userControlId) {
    let URL = environment.URL + this.config.URL.userListURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  addUser(user: any) {
    let URL = environment.URL + this.config.URL.addUserURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

  updateUser(action: string, user: any) {
    let URL = environment.URL + this.config.URL.updateUserURL + '/' + action;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, user, this.options);
  }

  getUserDetails(userId: number) {
    let URL = environment.URL + this.config.URL.getUserDetails + '/' + userId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getUserDetailsByLoginId(userId: string) {
    let URL = environment.URL + this.config.URL.getUserMenusByLoginId + '/' + userId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  saveUserMenuMappings(request: any) {
    let URL = environment.URL + this.config.URL.saveUserMenuMappings;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, request, this.options);
  }

  getEntityTypeData() {
    let URL = environment.URL + this.config.URL.getEntityTypeData;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getEntityCodeData(entityType) {
    let URL = environment.URL + this.config.URL.getEntityCodeData + '?type=' + entityType;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getMenuList() {
    let URL = environment.URL + this.config.URL.menuList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  saveMenu(request: any) {
    let URL = environment.URL + this.config.URL.saveMenu;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, request, this.options);
  }

  getRoleList() {
    let URL = environment.URL + this.config.URL.roleList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  saveRole(request: any) {
    let URL = environment.URL + this.config.URL.saveRole;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, request, this.options);
  }

  addRole(request: any) {
    let URL = environment.URL + this.config.URL.addRole;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, request, this.options);
  }

  getRoleDataById(id: number) {
    let URL = environment.URL + this.config.URL.getRoleDataById + '/' + id;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  sendVerificationLink(userId) {
    let URL = environment.URL + this.config.URL.sendVerificationLink + "/" + userId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }
}
