<form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div id="reset-pass" class="resetpass">
        <div class="row">
            <div class="col-sm-12 form-group">
                <label>Login ID</label>
                <input type="text" class="form-control" id="username" formControlName="username" [pattern]="userPattern" autocomplete="off" readonly>
                <p *ngIf="f.username.invalid && f.username.touched && f.username.errors?.required" class="error nocase">
                    Please enter Login ID
                </p>
                <p *ngIf="f.username.invalid && f.username.touched && f.username.errors?.pattern"
                    class="error nocase">
                    Please enter a valid login ID
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group">
                <label>New Password
                    <a class="badge badge-light pointer" data-toggle="modal" data-target="#passwordPolicy">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                        </svg>&nbsp;<small>Password Policy</small>
                    </a>
                </label>

                <div class="input-group">
                    <input [type]="fieldTextType[0]?'text':'password'" class="form-control"
                        formControlName="newPassword" [pattern]="passwordPattern" autocomplete="off">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa" [ngClass]="{ 'fa-eye-slash':!fieldTextType[0], 'fa-eye':fieldTextType[0]}"
                                (mousedown)="toggleFieldTypeTextTrue(0)" (mouseup)="toggleFieldTypeTextFalse(0)"
                                (mouseout)="toggleFieldTypeTextFalse(0)"></i>
                        </span>
                    </div>
                </div>
                <p *ngIf="f.newPassword.errors?.required && f.newPassword.touched" class="error nocase">
                    Please enter new password
                </p>
                <p *ngIf="f.newPassword.invalid && f.newPassword.touched && !(f.newPassword.errors?.required)"
                    class="error nocase">
                    {{passwordPolicy}}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group">
                <label>Confirm Password</label>
                <div class="input-group">
                    <input [type]="fieldTextType[1]?'text':'password'" class="form-control" id="confirmPassword"
                        formControlName="confirmPassword" autocomplete="off">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa" [ngClass]="{ 'fa-eye-slash':!fieldTextType[1], 'fa-eye':fieldTextType[1]}"
                                (mousedown)="toggleFieldTypeTextTrue(1)" (mouseup)="toggleFieldTypeTextFalse(1)"
                                (mouseout)="toggleFieldTypeTextFalse(1)"></i>
                        </span>
                    </div>
                </div>
                <p *ngIf="f.confirmPassword.errors?.required && f.confirmPassword.touched" class="error nocase">
                    Please enter confirm password
                </p>
                <p *ngIf="f.confirmPassword.errors?.matching && f.confirmPassword.touched && !(f.confirmPassword.errors?.required)"
                    class="error nocase">
                    Confirm password should be same as new password.
                </p>

            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 mb-1">
                <button class="btn btn-primary full-width" type="submit" id="btnaccountlock"
                    [disabled]="resetForm.invalid">
                    Reset Password
                </button>
            </div>
            <div class="col-lg-4 mb-1">
                <button class="btn btn-cancel full-width" type="button" id="backforgot"
                    routerLink="/login">Back</button>
            </div>
        </div>
    </div>
</form>
<div class="modal fade" id="passwordPolicy" tabindex="-1" role="dialog" aria-labelledby="passwordPolicyLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-password-policy></app-password-policy>
        </div>
    </div>
</div>