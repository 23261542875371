import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { check } from 'ngx-bootstrap-icons/lib/icons/check';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnitService } from 'src/app/service/enit.service';
import { config } from 'src/app/valueObject/config';
declare var $: any;

@Component({
  selector: 'app-display-algo-undertaking-details',
  templateUrl: './display-algo-undertaking-details.component.html',
  styleUrls: ['./display-algo-undertaking-details.component.css']
})
export class DisplayAlgoUndertakingDetailsComponent implements OnInit {


  undertakingalgodetails: FormGroup;
  user: any = {};
  memCode: String = '10002';
  memId: String = '2';
  submitted = false;
  config: any = config;
  message: string;
  menuCode = 'userlist';
  userDetails: any;
  ifApproved: boolean=false;
  algoList:any=[];
  AuthName: any;
  AuthDesign: any;
  ifsurrendered: boolean;
  modaldemo: boolean;
  formModal: any;
  

  constructor(private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private EnitService: EnitService,
    private router: Router,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {

    this.titleService.setTitle('Algo Undertaking');
    this.getDisplayAlgoUndertakingDetails(this.memCode, this.memId);

    this.undertakingalgodetails = this.formbuilder.group({
      AuthName: [''],
      AuthDesign: [''],
      checkBox1: [false, Validators.pattern('true'),Validators.required],
      checkBox2: [false, Validators.pattern('true'),Validators.required],
      checkBox3: [false, Validators.pattern('true'),Validators.required],
      checkBox4: [false, Validators.pattern('true'),Validators.required],
      checkBox5: [false, Validators.pattern('true'),Validators.required],
      checkBox6: [false, Validators.pattern('true'),Validators.required],
      checkBox7: [false, Validators.pattern('true'),Validators.required],
      checkBox8: [false, Validators.pattern('true'),Validators.required],
      checkBox9: [false, Validators.pattern('true'),Validators.required],
      checkBox10: [false, Validators.pattern('true'),Validators.required],
      
    })
    
  }

 
  get f() { return this.undertakingalgodetails.controls; }

 
  openFormModal() {
      $('#myModal').modal({backdrop:'static',keyboard:false},'show');
  }
 closeModal() {
    this.router.navigate(['']);
    this.formModal.hide();
  }

  getDisplayAlgoUndertakingDetails(memCode: String, memId: String) {

    this.EnitService.getDisplayAlgoUndertakingDetails(memCode, memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) 
      {
        if(response.data.cusmemCategory != '0' && response.data.cusmemCategory != '4'){
          this.openFormModal();
        }

        if (response.data.cusUndertakingStatus=='APPROVED' && response.data.cusmemCategory != '0' && response.data.cusmemCategory == '4') {
          this.f.AuthName.setValue(response.data.cusAumAuthName);
          this.f.AuthDesign.setValue(response.data.cusAumAuthDesign);
        
        }
        
        if(response.data.cusUndertakingStatus=='SURRENDERED' || response.data.cusmemCategory != '0' && response.data.cusmemCategory == '4'){
          this.AuthName= '',
          this.AuthDesign= ''
         
        }

        if(response.data.algoundertakList.length>0){
          if(response.data.cusUndertakingStatus=='APPROVED'){
            this.ifApproved=true;
          }
          

          if(response.data.cusUndertakingStatus=='SURRENDERED')
          {
            this.ifsurrendered=false;
          }

          if(response.data.cusUndertakingStatus=='SURRENDERED' || response.data.cusUndertakingStatus!='APPROVED')
          {
            this.ifsurrendered=false;
          }

        }
        else{
          this.ifsurrendered=true;
        }
        this.algoList=response.data.algoundertakList;
        this.cdref.detectChanges();
      } else {
        this.message = response.message;
        this.toastr.error(this.message);
      }
     
    })
    
   
    
  }
 
  onSubmit() {

    this.submitted = true;
    let requestObject = {

      "memId":"2",
      "memCode":"10002",
      "MemName":"Mansukh IFSC Broking Private Limited",
      "cusAumAuthName": this.f.AuthName.value,
      "cusAumAuthDesign":this.f.AuthDesign.value
      
    };
    if (!requestObject) {
      false;
    } else {
      console.log(JSON.stringify(requestObject));

    }
    console.log(requestObject);
    this.EnitService.saveAlgoUndertakingRequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.message = 'Algorithm Undertaking Successfully';
        this.toastr.success(this.message);
        this.router.navigate(['/enit/eTrade/AdminUserIdRequestList']);
      } else {

        this.submitted = false;
      }

    })

  }


}

