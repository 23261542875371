import { parseHostBindings } from '@angular/compiler';
import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appElementstyle]'
})
export class ElementstyleDirective {

  constructor() { }


@HostBinding('style.color') backcolor="red"

}
