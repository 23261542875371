import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  title = 'c2n';
  showHead: boolean = false;
  hideHead: boolean = true;


  ngOnInit() {
    this.bnIdle.startWatching(2 * 60 * 60).subscribe((isTimedOut: boolean) => {

      if (isTimedOut) {
        console.log("Token expired");
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        localStorage.removeItem('salt');
      }
    });
  }

  constructor(private router: Router,
    private bnIdle: BnNgIdleService,
    private toastr: ToastrService) {
    // on route change to '/login', set the variable showHead to false
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/') {
          this.showHead = false;
          this.hideHead = true;
        } else {
          this.showHead = true;
          this.hideHead = false;
        }
      }
    });
  }
}
