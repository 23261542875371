<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Edit Branch Admin</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li> <a routerLink="/cel/assignBranchAdmin" skipLocationChange aria-current="page">Branch Admin</a>
                    </li>
                    <li class="active">
                        <a routerLink="/cel/editAssignBranchAdmin" skipLocationChange aria-current="page">Edit Branch
                            Admin</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <form [formGroup]="userForm">
            <fieldset [attr.disabled]="action=='view'?true:null">
                <div class="row">
                    <div class="col-lg-3 form-group">
                        <label>Admin User ID<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="userId" placeholder="Enter User ID"
                            readonly>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Admin User Name<span class="red">*</span> </label>
                        <input type="text" class="form-control" formControlName="userName" placeholder="Enter User Name"
                            readonly>
                    </div>
                    <div class="col-lg-6 text-right align-self-center">
                        <button class="btn btn-primary" data-toggle="modal" data-target="#adminUserModal">Add
                            Branch</button>
                    </div>
                </div>
                <table id="branchTable" class="table common-table" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger" *ngIf="userData.branches">
                    <thead>
                        <tr>
                            <th class="pre-wrap">Sr No</th>
                            <th class="pre-wrap">Branch ID</th>
                            <th class="pre-wrap">Branch Name</th>
                            <th class="pre-wrap">Remove</th>
                        </tr>
                        <tr class="column-search">
                            <th></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th><input type="text" class="form-control form-control-sm" /></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of userData.branches; let i=index">
                            <td>{{i+1}}</td>
                            <td>{{data.branchId}}</td>
                            <td>{{data.branchName}}</td>
                            <td>
                                <i class="fa fa-trash pointer usercontrol" aria-hidden="true" data-toggle="modal"
                                    data-target="#delete" (click)="deleteBranchId(data.branchId)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
            <div class="row justify-content-start mb-5">
                <div class="col-lg-8">
                    <div class="row justify-content-start">
                        <div class="col-lg-2 usercontrol">
                            <button class="btn btn-primary full-width" (click)="onSubmit()"
                                [disabled]="!!(action=='view')">Submit</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-cancel full-width" type="button" routerLink="../assignBranchAdmin"
                                skipLocationChange>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="adminUserModal" tabindex="-1" role="dialog" aria-labelledby="adminUserLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <form [formGroup]="adminForm">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <label>Select Branch ID<span class="red">*</span> </label>
                            <select class="form-control selectpicker" formControlName="branchId" data-live-search="true"
                                title="Select Branch ID" data-style="btn-info">
                                <option *ngFor="let data of branchData" value="{{data.branchId}}">{{data.branchId
                                    +'-'+ data.branchName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="btn-group d-flex" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary col-6" data-dismiss="modal" aria-label="Close"
                        (click)="onSave()">Save</button>
                    <button type="button" class="btn btn-cancel col-6" data-dismiss="modal"
                        aria-label="Close">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center">
                <h6>Are you sure you want to delete this entry?</h6>
                <div class="d-flex justify-content-center mt-3">
                    <div class="col-3"><button type="button" class="btn btn-secondary full-width"
                            data-dismiss="modal">Cancel</button></div>
                    <div class="col-3"><button type="button" class="btn btn-primary full-width" (click)="deleteBranch()"
                            data-dismiss="modal">Delete</button></div>

                </div>
            </div>

        </div>
    </div>
</div>