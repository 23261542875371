import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { CtclService } from 'src/app/service/ctcl.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError } from 'rxjs/operators';
import { config } from 'src/app/valueObject/config';
import { DataTableDirective } from 'angular-datatables';
import { CtclreportGridReq, DisplayCtclIdReport } from '../ctcl/ctcl-request-response';
import { Router, ActivatedRoute } from '@angular/router';
import {MisReportService } from 'src/app/service/misreport.service';
import {saveAs} from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { MisreportGridReq } from 'src/app/component/enit/EnitTrade/ctcl/ctcl-request-response';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-enit-mis-report',
  templateUrl: './enit-mis-report.component.html',
  styleUrls: ['./enit-mis-report.component.css']
})
export class EnitMisReportComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

  ctclRequestTypeList: any[] = [];
  ctclReportRequest: FormGroup;
  misReportForm: FormGroup;
  ctclRequestDisabledFields: any = {};
  dtOptions: any={};
  dtTrigger: Subject<any> = new Subject<any>();
  config: any = config;
  ctclGridList: any[] = [];
  ctclReportList: any[] = [];
  ctclreportGridReq: CtclreportGridReq;
  ctclRequestTypeSelected: number;
  displayFromReport: string = "YES";
  memberOrAdminReport: string = "MEMBER";
  ctclRequestIdReport: string = "";
  ctclEntityType: string = "";
  memberNameList: any[] = [];
  noSearchField:string[]=[];
  misGridList: any[] = [];
  misreportGridReq : MisreportGridReq;
  dtTrigger2: Subject<any> = new Subject<any>();
  fDate: any;
  f: any;


  constructor(private ctclService: CtclService,
    
     private misreportservice : MisReportService ,private fb: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService, private cdref: ChangeDetectorRef, private router: Router,
     private datepipe: DatePipe) {
    
  }

  ngOnInit(): void {

    this.ctclRequestTypeList = this.misreportservice.ctclRequestType;
    this.ctclEntityType = this.ctclService.entityType;
    this.noSearchField=['ctcl-memberName-nosearch','ctcl-memberCode-nosearch','ctcl-terminal-id-nonsearch',''];


    this.ctclReportRequest = this.fb.group({
      ctclMemberName: [this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memName],
      ctclMemberCode: [this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memCode],
      ctclMemId: [this.ctclEntityType === 'ADMIN' ? "" : this.ctclService.memId],
      //ctclEntityType: [this.ctclService.entityType],
      ctclRqdtlsTerminalId: [""],
      ctclRequestType: [""],
      ctclFromDate: [""],
      ctclToDate: [""]
    });

 
    
    this.misReportForm=this.fb.group({
      selectMisReport: [null, Validators.required]
    })

    this.ctclRequestDisabledFields = {
      ctclMemberNameDisable: true,
      ctclMemberCodeDisable: true

    }

    this.dtOptions = {
      scrollX: true,
      paging: true,
      responsive: true,
      processing: true,
      orderCellsTop: true,
      dom: "Blrtip",
      pagingType:'full_numbers',
      buttons: [
        {
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3,4],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4],
          }
        },
      ],
      rowCallback:(row:Node, data: any[] | Object, index:Number)=>{
        const self=this;
        $('td',row).off('click');
        $('td',row).on('click',()=>{
          self.someClickHandler(data);
        });
        return row;
      }};
    this.loadMisReportData();
   // this.downloadMisReport();
   // this.getMisReportBasedOnFilter();
  }

  get fmis() { return this.misReportForm.controls; }

  loadMisReportData(){
    this.misreportservice.loadReportData().pipe(
      catchError((error :any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.misGridList = [];
        this.setDatatable();
        if(error.status!=20){
          return throwError(error.error);
        }
      }
      )).subscribe((response:any) =>{
        if(response.statusCode =200){
          this.misGridList = response.data;
          this.setDatatable();
       } else {
        this.toastr.error(response.message);
        this.setDatatable();

        }
      })
  }


 


  clearSearch() {
    this.setDatatable();
  }
  
  setDatatable() {
    $("#ctclRequestDataGrid").DataTable().destroy();
    setTimeout(() => {
      $("#ctclRequestDataGrid").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6,7,8,9],
          }
        }],
        rowCallback:(row:Node, data: any[] | Object, index:Number)=>{
          const self=this;
          $('td',row).off('click');
          $('td',row).on('click',()=>{
            self.someClickHandler(data);
          });
          return row;
        },        
        initComplete: function () {
          this.api().columns().every(function () {
            $('#ctclRequestDataGrid .column-search input').val('');
            $('#ctclRequestDataGrid .column-search select').val('.*');
            $('#ctclRequestDataGrid .column-search').on('keyup change', 'input', function () {
              $('#ctclRequestDataGrid').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#ctclRequestDataGrid .column-search').on('keyup change', 'select', function () {
              $('#ctclRequestDataGrid').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

   downloadMisReport(requestID,originalFileName){
    let reqObj = {
      "requestID": requestID
    }

    this.misreportservice.misDownload(requestID).subscribe(
    (response: any) => {
      this.spinner.hide();
         if (response.statusCode == 200) {
          this.toastr.success("File Downloaded");
          let file = response.data;
          let contentType = 'text/csv';
         // let fileName=response.data;
          let blob = base64StringToBlob(file, contentType);
          saveAs(blob, originalFileName, {
            autoBOM: true
          });
        } else {
          console.log("Error while downaloding MisReport File");
          this.toastr.error(response.message);
         }
       }
      )
    }

    reportGenerated(reportType :any){

      this.fDate = this.datepipe.transform(new Date(), 'ddMMyyyy');
      console.log(this.fDate)
      this.misreportservice.downloadReport(this.fmis.selectMisReport.value).subscribe(
        (response: any) => {
          this.spinner.hide();
             if (response.statusCode == 200) {
              this.toastr.success(" Report Downloaded");
              let file = response.data;
              let contentType = 'text/csv';
              let fileName= "NIFSC_F_CTCL_"+this.fDate+".csv";
              let blob = base64StringToBlob(file, contentType);
              saveAs(blob, fileName ,{
                autoBOM: true
              });
            } else {
              console.log("Error while downaloding Report File");
              this.toastr.error(response.message);
             }
           }
          )

    }

 
  someClickHandler(info:any): void{
    console.log("Row Clicked::"+info.id);
  }


  

  getctclReportBasedOnReset() {
    this.misReportForm.reset();
  }

 

  getMisReportBasedOnFilter(){
    this.misreportservice.misDownload(this.misreportGridReq).pipe(
    catchError((error: any) => {
      this.spinner.hide();
      this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
      this.ctclGridList = [];
      this.dtTrigger.next(true);
      this.dtTrigger.next(true); 
    //  this.setSearchFunction();
      this.cdref.detectChanges();
      if (error.status != 200) {
        return throwError(error.error);
      }
    })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          if (response.data === "") {
            this.ctclGridList = response.data[0];
            this.dtTrigger.next(true);
           // this.setSearchFunction();
            this.cdref.detectChanges();
          }
        }else{
          this.toastr.error(response.message);
          this.dtTrigger.next(true);
         // this.setSearchFunction();
          this.cdref.detectChanges();
          }
      }
    )
  
    
  }

  loadCtclRequestType() {
    this.ctclService.loadCtclRequestType().subscribe((responseData: DisplayCtclIdReport) => {
      if (responseData.status === "OK" && responseData.statusCode === 200) {
        this.memberNameList = responseData.data.memDetList;
      }
    });

    this.cdref.detectChanges()
    $('.requestTypePicker').selectpicker('refresh');

  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }
}



