import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from 'src/app/valueObject/config';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CelService } from 'src/app/service/cel.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
declare var $: any;

@Component({
  selector: 'app-monitoring-enable',
  templateUrl: './monitoring-enable.component.html',
  styleUrls: ['./monitoring-enable.component.css']
})
export class MonitoringEnableComponent implements OnInit, AfterViewInit, OnDestroy {

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any;
  config: any = config;
  user: any = {};
  access: any = {};
  message: any;
  users: any = [];
  userData: any = [];
  selectedUser: any = {};
  monitorEnable: FormGroup;
  menucode = 'monitoringEnable';
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  userControlId: string = '';
  currentDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private celService: CelService,
  ) { }

  get f() {
    return this.monitorEnable.controls;
  }
  ngOnInit(): void {
    this.titleService.setTitle("CEL - Monitoring Enablement/Disablement");
    this.userDetailsService.getUserDetails();
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menucode) : this.userDetailsService.checkUserAccess(this.menucode) || {};
  }

  ngAfterViewInit() {
    this.getUserData();
  }

  getUserData() {
    this.celService.getDealerList(this.userControlId).pipe(
      catchError((error: any) => {
        this.userData = [];
        this.setDatatable();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.userData = response.data[0];
        this.celService.addDealerData(this.userData);
        this.setDatatable();
      } else {
        this.userData = [];
        this.setDatatable();
        this.toastr.error(response.message);
      }
    });
  }

  setDatatable() {
    $("#dealerTable").DataTable().destroy();
    setTimeout(() => {
      $("#dealerTable").DataTable({
        orderCellsTop: true,
        responsive: true,
        dom: 'Blrtip',
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthMenu: [10, 25, 50, 100],
        processing: true,
        buttons: [{
          extend: 'copy',
          footer: true,
          titleAttr: 'Copy',
          exportOptions: {
            titleAttr: 'Copy',
          }
        }, {
          extend: 'excel',
          footer: true,
          titleAttr: 'Export to Excel',
          exportOptions: {
            titleAttr: 'Export to excel',
          }
        }, {
          extend: 'pdf',
          footer: true,
          titleAttr: 'Download as PDF',
          exportOptions: {
            titleAttr: 'Download as PDF',
          }
        }],
        initComplete: function () {
          this.api().columns().every(function () {
            $('#dealerTable .column-search input').val('');
            $('#dealerTable .column-search select').val('.*');
            $('#dealerTable .column-search').on('keyup change', 'input', function () {
              $('#dealerTable').DataTable()
                .column($(this).parent().index())
                .search(this.value)
                .draw();
            });
            $('#dealerTable .column-search').on('keyup change', 'select', function () {
              $('#dealerTable').DataTable()
                .column($(this).parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.cdref.detectChanges();
    }, 1);
  }

  clearSearch() {
    this.setDatatable();
  }

  enableId(userId) {
    this.selectedUser = this.userData.find(data => data.userId == userId);
  }

  enableUser(status) {
    if (this.selectedUser.updatedStatus != this.selectedUser.monitoringStatus) {
      this.toastr.warning("The user is already " + (status == 1 ? "enabled" : "disabled") + ". It will be effective from next trading day.")
      this.cdref.detectChanges();
    } else {
      this.celService.enableCelMonitoring(this.selectedUser.userId, status).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          if (status == 1) {
            this.message = "CEL Monitoring for user " + this.selectedUser.userId + " will be enabled from the next trading day";
            this.toastr.success(this.message);
            $("#celLimit").modal('show');
          } else {
            this.message = "CEL Monitoring for user " + this.selectedUser.userId + " will be disabled from the next trading day";
            this.toastr.success(this.message);
          }
          this.getUserData();
        }
        else {
          this.message = "Couldn't Enable/Disable the user";
          this.toastr.error(this.message);
        }
      })
    }
  }

  editLimit() {
    this.router.navigate(['../editLimitSetup'], { queryParams: { "id": this.selectedUser.userId, "action": "edit" }, skipLocationChange: true, relativeTo: this.route })
  }

  getDateString(date: string) {
    let dataObject = new Date(date);
    let dateString = dataObject.getDay() + dataObject.getMonth() + dataObject.getFullYear();
    console.log("DateString - ", dateString);
    return dateString;
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

}
