import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BranchAdminService {

  branchAdminData: any = [];

  constructor() { }

  setBranchAdminData(branchAdminData) {
    this.branchAdminData = branchAdminData;
  }

  getBranchAdminData() {
    return this.branchAdminData;
  }

  getBranchById(userId) {
    return this.branchAdminData.find(data => data.userId == userId);
  }
}
