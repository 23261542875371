import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-half-yearly-form-submission-history',
  templateUrl: './half-yearly-form-submission-history.component.html',
  styleUrls: ['./half-yearly-form-submission-history.component.css']
})
export class HalfYearlyFormSubmissionHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
