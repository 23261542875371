import { Injectable } from '@angular/core';
import { UserDetails } from 'src/app/valueObject/userDetails';
import { config } from 'src/app/valueObject/config';
import { AppService } from 'src/app/service/app.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  private userDetails: UserDetails;
  config: any = config;

  constructor(private appService: AppService,
    private toastr: ToastrService,
    private router: Router) { }

  setUserDetails(data: UserDetails) {
    this.userDetails = data;
    localStorage.setItem('userDetails', JSON.stringify(data));
  }

  getUserDetails() {
    this.getUserDetailsFromServer();
    if (this.userDetails)
      return this.userDetails;
    else
      return JSON.parse(localStorage.getItem('userDetails'));
  }

  getUserDetailsFromServer() {
    this.appService.getUserDetails(0).pipe(
      catchError((error: any) => {
        this.userDetails = null;
        this.toastr.clear();
        this.toastr.warning("Session Expired. Please login again.");
        this.logout();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.setUserDetails(response.data && response.data[0]);
          this.userDetails = response.data[0];
        } else {
          this.userDetails = null;
          this.toastr.clear();
          this.toastr.warning("Session Expired. Please login again.");
          this.logout();
        }
      }
    );
  }

  getUserDetailsDashboard() {
    return this.appService.getUserDetails(0).pipe(
      catchError((error: any) => {
        this.userDetails = null;
        this.toastr.clear();
        this.toastr.warning("Session Expired. Please login again.");
        this.logout();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    );
  }

  checkUserAccess(menuCode: string) {
    if (!this.userDetails)
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let menu = this.userDetails.menusList.filter(menu => (menu.menuCode == menuCode));
    if (menu.length == 0) {
      this.userDetails.menusList.forEach(element => {
        if (element.userMenusImpls) {
          let menu1 = element.userMenusImpls.filter(submenu => (submenu.menuCode == menuCode));
          menu.push(...menu1);
          element.userMenusImpls.forEach(element1 => {
            if (element1.userMenusImpls) {
              let menu1 = element1.userMenusImpls.filter(submenu => (submenu.menuCode == menuCode));
              menu.push(...menu1);
            }
          })
        }
      });
    }
    return menu[0];
  }

  logout() {
    this.appService.logout({}).pipe(
      catchError((error: any) => {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        this.router.navigate(['/login']);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userDetails');
      localStorage.removeItem('username');
      this.router.navigate(['/login']);
    });
  }
}
