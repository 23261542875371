import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toast, ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {EnitComplianceServiceService} from 'src/app/service/enit-compliance-service.service'
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'src/app/valueObject/config';
import { DatePipe } from '@angular/common'
declare var $:any;

@Component({
  selector: 'app-ml-ai-request',
  templateUrl: './ml-ai-request.component.html',
  styleUrls: ['./ml-ai-request.component.css']
})
export class MlAiRequestComponent implements OnInit {

  requestForm : FormGroup;
  memCode: String;
  memId: any;
  config: any = config;
  user: any = {};
  access: any = {};
  memName:string;
  entityType:any;
  formIndex: number = 1;
  number: number = 1;
  list : any = [1];
  maxDate : Date = new Date();
  bsValueArray: any = [];
  bsValue: any=[];
  date: any;
  content: any = true;
  up: boolean = true;
  down: boolean = false;
  memberData : any ={};
  mlAiData : any = [];
  quarterDetails : any = [];
  quarterList: any;
  selectedQuarter : any;
  selectedYear : any;
  eligibilityData : any;
  eligible: boolean;
  cutOffDate: boolean;
  year : string;
  eligibleQuarter : any;
  isSubmitted: boolean;
  refId: string;
  machineDetailsArray: []
  eligibleYear: any;

  // form: FormArray;
  constructor(
    private formBuilder : FormBuilder,
    private router : Router,
    private titleService : Title,
    private cdref : ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private mlAiService: EnitComplianceServiceService,
  ) { }

  get f(){
    return this.requestForm.controls;
  }
  ngOnInit(): void {
    
    this.user = this.userDetailsService.getUserDetails() || {};
    this.memCode = this.user.entityCode;
    this.memId = (<number>this.user.entityCode-10000)+"";
    this.entityType = this.user.entityType;
    this.getQuarterForSubmission();
    this.getMemberDetails();
    this.getEligibility();

    this.titleService.setTitle("ML & AI Details");
    this.requestForm = this.formBuilder.group({
    quarter:[''],
    registrationNumber:[],
    entityCategory:['Trading Member'],
    memberName:[],
    panNO:[],
    memCode:[this.memCode],
    form : this.formBuilder.array([]),
    mlaisystemAuditReport:[null, Validators.required],
    mlaisystemAdverse: [null, Validators.required]
  })
  // this.getMemberDetails();
  $('.selectpicker').selectpicker();

  //  const add =  this.requestForm.controls.form as FormArray;
  //   add.push(this.formBuilder.group({
  //     systemName: [this.mlAiData.systemName, Validators.required],
  //     applicationDateStr: [this.mlAiData.applicationDateStr, Validators.required],
  //     typeOfArea: [this.mlAiData.typeOfArea, Validators.required],
  //     systemOrder: ['Y', Validators.required],
  //     systemActivities: ['Y', Validators.required],
  //     systemDisseiminate: ['Y', Validators.required],
  //     systemUsedArea: ['Y', Validators.required],
  //     systemClaims: [this.mlAiData.systemClaims, Validators.required],
  //     systemTools: [this.mlAiData.systemTools, Validators.required],
  //     systemProject: [this.mlAiData.systemProject, Validators.required],
  //     systemKeyControls: [this.mlAiData.systemKeyControls, Validators.required],
  //     systemAudit: ['Y', Validators.required],
  //     sysyemDescription: [this.mlAiData.sysyemDescription, Validators.required],
  //     systemSafeGuard: [this.mlAiData.systemSafeGuard, Validators.required],
  //   }))
  //   this.f.form = add;
    $('.selectpicker').selectpicker();
  }

  get form(){
    return this.requestForm.controls['form'] as FormArray
  }
  close(){
    $("#submitted").modal('hide');
    $("#cutOffdate").modal('hide');
    this.router.navigate(['eCompliance/mlAndAi/mlaireport'])
    
  }
  addForm(){
    // $('.selectpicker').selectpicker();
    const add =  this.requestForm.controls.form as FormArray;
    this.number = this.number + 1;

    add.push(this.formBuilder.group({
      systemName: ['', Validators.required],
      applicationDateStr: ['', Validators.required],
      typeOfArea: ['', Validators.required],
      systemOrder: ['Y', Validators.required],
      systemActivities: ['Y', Validators.required],
      systemDisseiminate: ['Y', Validators.required],
      systemUsedArea: ['Y', Validators.required],
      systemClaims: ['', Validators.required],
      systemTools: ['', Validators.required],
      systemProject: ['', Validators.required],
      systemKeyControls: ['', Validators.required],
      systemAudit: ['Y', Validators.required],
      sysyemDescription: ['', Validators.required],
      systemSafeGuard: ['', Validators.required],
    }))
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker();
    $('.selectpicker').selectpicker('refresh');
  }

  removeForm(j : number){
    if(this.number == 1){
     this.toastr.warning("Minimum One Machine Details Required")
    }
    else if( this.number > 1){
      const add =  this.requestForm.controls.form as FormArray;
      add.removeAt(j);
      this.number = this.number - 1
    }
  }

  getQuarterForSubmission(){// get the quarter which is to be set in the form
    this.mlAiService.getQuarterForSubmission().pipe(
      catchError((error: any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.quarterList = response.data;
        this.f.quarter.setValue(this.quarterList);
        this.refId = this.memCode +"/"+"MLAI"+"/"+this.f.quarter.value;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');  
        
      }
    })
  }

  getMemberDetails(){
    this.mlAiService.getMemberDetails(this.memCode, this.memId).pipe(
      catchError((error: any)=>{
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.memberData = response.data;
        this.f.registrationNumber.setValue(this.memberData.memSebiRegNo);
        this.f.memberName.setValue(this.memberData.memNm);
        this.f.panNO.setValue(this.memberData.memItPanNo);
        this.cdref.detectChanges();
      }
    })
  }

  getEligibility(){
  
    this.mlAiService.getEligibility(this.memId).pipe(
      catchError((error: any) => {
        this.quarterDetails = [];
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        } 
      })
    ).subscribe((response : any) => {
      if(response.statusCode == 200){
        this.eligibilityData = response.data;
        if(response.data?.length == 0 ){
          this.eligible = false;
          $('#eligibility').modal('show');
        }
       
        else {
          this.eligible = true;
          this.getQuarterData();
          this.refId = this.memCode +"/"+"MLAI"+"/"+this.f.quarter.value;
          $('#eligibility').modal('hide');
        }
      }
    })
  }

  getQuarterData() {
    this.mlAiService.getQuarterDetails().pipe(
      catchError((error: any) => {
        this.quarterDetails = [];
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.quarterDetails = response.data.currentQuarter;
        this.eligibleQuarter = this.quarterDetails.quarter;
        this.eligibleYear = this.quarterDetails.year
        if(this.eligible == true){
          this.getCutOffDate(); 
        }
        // this.getCutOffDate();
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');    
      } else {
        this.quarterDetails = [];
        this.toastr.error(response.message);
      }
    });
  }
  getCutOffDate(){
    this.year = this.eligibleYear.toString();
    const module = "ML_AI"
    this.mlAiService.getCutOffDate(this.eligibleQuarter, this.year, module ).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        } 
      })
    ).subscribe(
      (response: any) =>{
        if (response.statusCode == 200 ){
          this.cutOffDate = response.data;
          if(this.cutOffDate == true){
            this.f.registrationNumber.setValue(this.memberData.memSebiRegNo);
            this.f.memberName.setValue(this.memberData.memNm);
            this.f.panNO.setValue(this.memberData.memItPanNo);
            this.getMachineDetails();
          }
          else if (this.cutOffDate == false){
            $('#cutOffdate').modal('show');
            this.isFormSubmitted();
          }

          // if(this.cutOffDate == true){
          //   this.getEligibility();
          // }
          // else if (this.cutOffDate == false) {
          //   this.eligible = false;
          //   $('#cutOffdate').modal('show');
          // }
        }
      }
    )
  }

  isFormSubmitted(){
    let requestObject = {
      "qcdMemId": {
          "memId": this.memId,
      },
      "memCode": this.memCode,
      "mlaiRefNO":this.refId,
    }
    this.mlAiService.getFormSubmissionStatus(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        } 
      })
    ).subscribe((response : any) => {
      if(response.statusCode == 200){
        this.isSubmitted = response.data.presentFlag;
        if(this.isSubmitted == true && this.cutOffDate == false){
          this.eligible = false;
          $('#cutOffdate').modal('hide');
          $('#submitted').modal('show');

        }
        else{
          this.eligible = true;
          const add =  this.requestForm.controls.form as FormArray;
            add.push(this.formBuilder.group({
              systemName: [ '', Validators.required],
              applicationDateStr: ['', Validators.required],
              typeOfArea: ['', Validators.required],
              systemOrder: ['Y', Validators.required],
              systemActivities: ['Y', Validators.required],
              systemDisseiminate: ['Y', Validators.required],
              systemUsedArea: ['Y', Validators.required],
              systemClaims: ['' ,Validators.required],
              systemTools: ['', Validators.required],
              systemProject: ['', Validators.required],
              systemKeyControls: ['', Validators.required],
              systemAudit: ['Y', Validators.required],
              sysyemDescription: ['', Validators.required],
              systemSafeGuard: ['', Validators.required],
            }))
            this.f.form = add;
          $('#submitted').modal('hide');
          
        }
      }
    })
  }


  getMachineDetails(){
    let requestObject = {
      "mlaiID": null,
      "qcdMemId": {
          "memId":this.memId,
      },
      "mlaiRefNO":this.refId,
      "memCode": this.memCode,
    }

    this.mlAiService.getDataByRefId(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      ((response: any) => {
        if(response.statusCode == 200 ){
          // this.f.panNO.setValue(response.data.panNO);
          // this.f.registrationNumber.setValue(response.data.registrationNumber);
          // this.f.memberName.setValue(response.data.memberName);
          this.f.registrationNumber.setValue(this.memberData.memSebiRegNo);
          this.f.memberName.setValue(this.memberData.memNm);
          this.f.panNO.setValue(this.memberData.memItPanNo);
          this.machineDetailsArray = response.data.mlaiSystemDetails;
          this.f.mlaisystemAdverse.setValue(response.data.mlaisystemAdverse);
          this.f.mlaisystemAuditReport.setValue(response.data.mlaisystemAuditReport)
          // if(this.machineDetailsArray.length<1)
          if(response.data=="" || response.data.mlaiSystemDetails == null){
          const add =  this.requestForm.controls.form as FormArray;
            add.push(this.formBuilder.group({
              systemName: [ '', Validators.required],
              applicationDateStr: ['', Validators.required],
              typeOfArea: ['', Validators.required],
              systemOrder: ['Y', Validators.required],
              systemActivities: ['Y', Validators.required],
              systemDisseiminate: ['Y', Validators.required],
              systemUsedArea: ['Y', Validators.required],
              systemClaims: ['' ,Validators.required],
              systemTools: ['', Validators.required],
              systemProject: ['', Validators.required],
              systemKeyControls: ['', Validators.required],
              systemAudit: ['Y', Validators.required],
              sysyemDescription: ['', Validators.required],
              systemSafeGuard: ['', Validators.required],
            }))
            this.f.form = add;
          }
          else{
            const add =  this.requestForm.controls.form as FormArray;
            this.machineDetailsArray.forEach((machineDetails: any)=>
              {
                add.push(this.formBuilder.group({
                  systemName: [machineDetails.systemName ],
                  applicationDateStr: [this.datePipe.transform(machineDetails.applicationDateStr, 'MM/dd/yyyy') ],
                  typeOfArea: [machineDetails.typeOfArea ],
                  systemOrder: [machineDetails.systemOrder ],
                  systemActivities: [machineDetails.systemActivities ],
                  systemDisseiminate: [machineDetails.systemDisseiminate ],
                  systemUsedArea: [machineDetails.systemUsedArea ],
                  systemClaims: [machineDetails.systemClaims ],
                  systemTools: [machineDetails.systemTools ],
                  systemProject: [machineDetails.systemProject ],
                  systemKeyControls: [machineDetails.systemKeyControls ],
                  systemAudit: [machineDetails.systemAudit ],
                  sysyemDescription: [machineDetails.sysyemDescription],
                  systemSafeGuard: [machineDetails.systemSafeGuard ],
                }))
              }
            )
  
            this.f.form = add;
            this.number = this.machineDetailsArray.length;
          }
          // const add =  this.requestForm.controls.form as FormArray;
          // this.machineDetailsArray.forEach((machineDetails: any)=>
          //   {
          //     add.push(this.formBuilder.group({
          //       systemName: [machineDetails.systemName ],
          //       applicationDateStr: [machineDetails.applicationDateStr ],
          //       typeOfArea: [machineDetails.typeOfArea ],
          //       systemOrder: [machineDetails.systemOrder ],
          //       systemActivities: [machineDetails.systemActivities ],
          //       systemDisseiminate: [machineDetails.systemDisseiminate ],
          //       systemUsedArea: [machineDetails.systemUsedArea ],
          //       systemClaims: [machineDetails.systemClaims ],
          //       systemTools: [machineDetails.systemTools ],
          //       systemProject: [machineDetails.systemProject ],
          //       systemKeyControls: [machineDetails.systemKeyControls ],
          //       systemAudit: [machineDetails.systemAudit ],
          //       sysyemDescription: [machineDetails.sysyemDescription],
          //       systemSafeGuard: [machineDetails.systemSafeGuard ],
          //     }))
          //   }
          // )

          // this.f.form = add;
        }
      })
    )
  }


  

  nilSubmission(){

    let requestObject = {
      "mlaiID": this.memId,
      "qcdMemId": {
        "memId": this.memId,
      },
      "mlaisystemAdverse": this.f.mlaisystemAdverse.value,
      "mlaisystemAuditReport": this.f.mlaisystemAuditReport.value,
      "mlaicreatedBy": this.memCode,
      "mlaiupdatedBy": null,
      "mlaiisDel": null,
      "mlaiRefNO": this.refId,
      "panNO": this.f.panNO.value,
      "registrationNumber": this.f.registrationNumber.value,
      "memberName": this.f.memberName.value,
      "memCode": this.memCode,
      "moduleType":"ML_AI",
      "mlaiSystemDetails": [{
        "systemId": null,
        "systemName": null,
        "applicationDateStr":null,
        "typeOfArea": null,
        "systemOrder": null,
        "systemActivities": null,
        "systemDisseiminate": null,
        "systemUsedArea": null,
        "systemClaims": null,
        "systemTools": null,
        "systemProject": null,
        "systemKeyControls": null,
        "systemAudit": null,
        "sysyemDescription": null,
        "systemSafeGuard": null,
        "description": null
      }]
  }
    this.mlAiService.saveMlAiNillRequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }  
      })
    ).subscribe(
      (response: any) => {
        if (response.statusCode == 200){
          this.toastr.success("Request Sent for Nill Submission")
        }
      }
    )
  }  

 

  reset(){
    this.form.reset();
  }
  submit(){
    let requestObject = {
      "mlaiID": this.memId,
      "qcdMemId": {
        "memId": this.memId,
      },
      "mlaiRefNO":this.refId,
      "mlaisystemAdverse": this.f.mlaisystemAdverse.value,
      "mlaisystemAuditReport": this.f.mlaisystemAuditReport.value,
      "mlaicreatedBy": this.memCode,
      "mlaiupdatedBy": null,
      "mlaiisDel": null,
      "panNO": this.f.panNO.value,
      "moduleType":"ML_AI",
      "registrationNumber": this.f.registrationNumber.value,
      "memberName": this.f.memberName.value,
      "memCode": this.memCode,
      "mlaiSystemDetails":[],
    }
    this.f.form['controls'].forEach(element => {
      requestObject.mlaiSystemDetails.push({
        "systemId": null,
        "systemName": element.value.systemName,
        "applicationDateStr":this.datePipe.transform(element.value.applicationDateStr, 'dd-MM-yyyy'),
        // "applicationDateStr":"03-MAR-2023",
        "typeOfArea":element.value.typeOfArea,
        "systemOrder":element.value.systemOrder,
        "systemActivities": element.value.systemActivities,
        "systemDisseiminate": element.value.systemDisseiminate,
        "systemUsedArea": element.value.systemUsedArea,
        "systemClaims": element.value.systemClaims,
        "systemTools": element.value.systemTools,
        "systemProject": element.value.systemProject,
        "systemKeyControls": element.value.systemKeyControls,
        "systemAudit": element.value.systemAudit,
        "sysyemDescription": element.value.sysyemDescription,
        "systemSafeGuard": element.value.systemSafeGuard,
        "description": element.value.description,
      })
    })
    this.spinner.show();
    this.mlAiService.saveMlAiRequest(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if(response.statusCode == 200){
        this.toastr.success("Request Submitted Successfully");
        this.form.reset();
        this.f.mlaisystemAuditReport.setValue(null);
        this.f.mlaisystemAdverse.setValue(null);
      }
      else{
        this.toastr.warning(response.message);
      }
    })
  }
  toggle(j){
    var div = document.getElementById(j);
    $(div).toggle();
  }
}
