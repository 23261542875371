<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Shifting Neat Id Request</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li *ngIf="entityType!='ENIT'" class="active"> <a routerLink="tradeAdmin/eUserIDRequestA/displayUIRequestProcessPage/ShiftingNeatIdAdminRequest"
                                aria-current="page">Shifting Neat Id Request</a></li>
                    <li *ngIf="entityType=='ENIT'" class="active"> <a routerLink="eTrade/eUseridRequest/displayUIRequestProcessPage/ShiftingNeatIdAdminRequest"
                                 aria-current="page">Shifting Neat Id Request</a></li>
    
                </ul>
            </div>
        </div>
    </div>
    <div>
        <form [formGroup]="ShiftingOfNeatUserIdAdmin" >
                <div class="card card-body col-lg-12 mt-3 colorborder">
                  
                    <div class="row">                                                     
                        <div class="col-lg-3 form-group">
                                 <label>Member Code</label>
                                 <input type="text" class="form-control" readonly formControlName="memCode">
                        </div>
                        <div class="col-lg-3 form-group">                            
                            <label>Member Name</label>
                            <input type="text" class="form-control" readonly formControlName="memberName">
                        </div>
                        <div class="col-lg-3 form-group">
                                <label>User Id</label>
                                <input type="text" class="form-control" readonly formControlName="userId">
                        </div>
                        <div class="col-lg-3 form-group">                        
                                <label>UserName</label>
                                <input type="text" class="form-control" readonly formControlName="Username">
                        </div>
                    </div> 
                    <div class="row">                             
                        <div class="col-lg-3 form-group">
                            <label>Type Of User</label>
                            <input type="text" class="form-control" readonly formControlName="typeOfUser">
                        </div>
                        <div class="col-lg-3 form-group">
                              <label>TAP/Child IP</label>
                            <input type="text" class="form-control" readonly formControlName="TapIp">
                        </div>
                         <div class="col-lg-3 form-group">
                           <label>Tap ID</label>
                            <input type="text" class="form-control" readonly formControlName="tapId">
                        </div>                           
                    </div>  
                     <h5>Shifted VSAT/LL Address</h5>
                    <div  class="row" >
                        <div class="col-lg-3 form-group">                   
                            <label>Shift To TAP/Child IP</label>
                            <input type="text" class="form-control" readonly formControlName="shiftTapip">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Existing TAP/Child IP Address</label>
                            <input type="text" class="form-control" readonly 
                            formControlName="existingtapipaddress">
                        </div>
                        <div class="col-lg-3 form-group" *ngIf="entityType!='ENIT'">
                            <label>Shift To Box ID</label>
                            <input type="text" class="form-control" readonly *ngIf="shiftingBoxId != null"  formControlName="boxId">
                            <input type="text" class="form-control"  *ngIf="shiftingBoxId == null"
                            formControlName="boxId">
                        </div>

                    </div>   
                        <h5>Existing VSAT/LL Address</h5>
                    <div  class="row" >
                        <div class="col-lg-3 form-group">
                            <label>Reason for shifting of User ID</label>
                            <input type="text" class="form-control" readonly formControlName="Reason">                     
                        </div> 
                        <div class="col-lg-3 form-group">
                            <label>Shift To TAP/Child IP Address</label>
                            <input type="text" class="form-control" readonly formControlName="shiftinftapipAddress">
                        </div>   
                    </div>
                  <div *ngIf="entityType!='ENIT'">
                     <!-- if data is submitted then load this div -->
                     <div *ngIf="reqObj.cusReqStatus=='APPR' || reqObj.cusReqStatus=='RJCT'">
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Current Status  </label>
                                <input *ngIf="reqObj.cusReqStatus=='APPR'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="APPROVED">
                                <input *ngIf="reqObj.cusReqStatus=='RJCT'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="REJECTED">
                                <input *ngIf="reqObj.cusReqStatus=='UNDER_PROC'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="UNDER PROCESSING">
                                <input *ngIf="reqObj.cusReqStatus=='DOC_AWAT'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="DOCUMENT AWAITED">
                                <input *ngIf="reqObj.cusReqStatus=='DOC_RECVD'"   formControlName="CurrentStatus" type="text" class="form-control" readonly value="DOCUMENT RECEIVED">
                            </div>
                            <div class="col-lg-3 form-group">
                                <label for="">Remarks  </label>
                                <input type="text" class="form-control" readonly formControlName="remark">
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="row">
                            <div class="col-lg-4 form-group">
                                <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                     <!-- if data is submitted then load this div -->
                     <div *ngIf="reqObj.cusReqStatus!='APPR' && reqObj.cusReqStatus!='RJCT' ">
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <label>Current Status  </label>
                                <input formControlName="CurrentStatus" type="text" class="form-control" readonly value="In Progress">
                               
                            </div>
                            <div class="col-lg-3 form-group">
                                <label>Select Status</label>
                 
                                <select class="form-control form-control-sm" formControlName="UpdatedStatus" (change)="statusChange()">
                                    <option value="" selected hidden>SELECT STATUS</option>
                                    <option value="APPR">APPROVED</option>
                                    <option value="RJCT">REJECTED</option>
                                    <option value="UNDER_PROC">UNDER PROCESSING</option>
                                    <option value="DOC_AWAT">DOCUMENT AWAITED</option>
                                    <option value="DOC_RECVD">DOCUMENT RECEIVED</option>
                                </select>
                            </div>
                            <div class="col-lg-3 form-group">
                                <label for="">Remarks  </label>
                                <input type="text" class="form-control"  formControlName="remark">
                            </div>
                            
                        </div>
                        
                    <div class="row">
                        <div class="col-lg-4">
                        <div class="row">
                                
                            <div class="col-lg-3 form-group">
                                <button class="btn  btn-primary full-width" [disabled]="ShiftingOfNeatUserIdAdmin.invalid" type="submit" (click) ="onSubmit()">Submit</button>
                            </div>
                            <div class="col-lg-3 form-group">
                                <button class="btn  btn-cancel full-width" type="button" (click)="reset()">Reset</button>
                            </div>
                            <div class="col-lg-3 form-group">
                                <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                            </div>
                           
                        </div>
                        </div>
                    </div>
                    </div>
                    <!-- otherwise load this div for submission of data -->
                </div>  
                <div class="row" *ngIf="entityType=='ENIT'">
                    <div class="col-lg-3 form-group">
                        <label>Current Status  </label>
                        <input *ngIf="reqObj.cusReqStatus=='APPR'"   formControlName="CurrentStatus"  class="form-control" readonly value="APPROVED">
                        <input *ngIf="reqObj.cusReqStatus=='RJCT'"   formControlName="CurrentStatus"  class="form-control" readonly value="REJECTED">
                        <input *ngIf="reqObj.cusReqStatus=='UNDER_PROC'"   formControlName="CurrentStatus"  class="form-control" readonly value="UNDER PROCESSING">
                        <input *ngIf="reqObj.cusReqStatus=='DOC_AWAT'"   formControlName="CurrentStatus"  class="form-control" readonly value="DOCUMENT AWAITED">
                        <input *ngIf="reqObj.cusReqStatus=='DOC_RECVD'"   formControlName="CurrentStatus"  class="form-control" readonly value="DOCUMENT RECEIVED">
                        <input *ngIf="reqObj.cusReqStatus=='SUB'"   formControlName="CurrentStatus"  class="form-control" readonly value="SUBMITTED">
                    </div>
                    <div class="col-lg-3 form-group">
                        <label>Remarks  </label>
                        <input type="text" class="form-control"  formControlName="remark">
                    </div>
                </div>
                
                    <div class="row" *ngIf="entityType=='ENIT'">
                        <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-4 form-group">
                                <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>      
        </form>
       </div>
</div>
