import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
   user: any={}
   page: string;
   username:any;
   userId: number;
  constructor(
    private userDetailsService: UserDetailsService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private appService: AppService
  ) { 
    // this.route.queryParams.subscribe(params => {
    //   if (params['page']) {
    //     this.page = params['page'];
    //   }
    //   if(params['username']){
    //     this.username = params['username'];
    //   }
    // });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Profile')
    this.user=this.userDetailsService.getUserDetails();
  
    //this.user=this.userService.getUserById;
  }
  

}
