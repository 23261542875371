import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/valueObject/config';
import { Observable } from 'rxjs';
import { CtclActivationSubmitResponse, CtclApprovalRejectionReq, CtclApprovalRejectionRes, CtclIdActivationResponse, CtclIdDeactivationResponse, CtclIdModificationResponse, CtclNeatIdAndTerminalOffice, CtclNeatIdDeactivationDateRes, CtclreportGridReq, CtclReportGridResponse, CtclResponseForDeactivation, CtclResponseForModification, DisplayCtclIdReport, NeatAndterminalResponse } from '../component/enit/EnitTrade/ctcl/ctcl-request-response';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CtclService {

  config:any=config;
  userDetailsObj:any={};
  memId:number=0;
  memCode:number=0;
  memName:string="";
  entityType:string="";
  ctclReportList: any[]=[];

  ctclRequestType:Array<{id:number, name: string}>=Array(
    {"id":1,"name":"CTCL ACTIVATION REQUEST"},
    {"id":2,"name":"CTCL DEACTIVATION REQUEST"},
    {"id":3,"name":"CTCL MODIFICATION REQUEST"}
  );

  constructor(private httpClient: HttpClient) {
    var userDetailsJSON= localStorage.getItem('userDetails')
    if(userDetailsJSON != 'undefined')
      this.userDetailsObj =JSON.parse(userDetailsJSON);
      this.memCode=(Number)(this.userDetailsObj.entityCode);
      this.memId=this.memCode-10000;
      this.memName=this.userDetailsObj.userName;
      this.entityType=this.userDetailsObj.entityType;

   }

   neatUserIdList:any=[{id:1,data:'Test1'}, {id:2,data:'Test2'},{id:3,data:'Test3'}];
   ctclIdDeactivationURL:string=environment.URL+this.config.URL["ctclIdDeactivation"];
   ctclTerminalIdURL:string=environment.URL+this.config.URL["ctclTerminalIdData"];
   ctclRequestDataForDeactURL:string=environment.URL+this.config.URL["ctclRequestDataForDeact"];
   ctclIdActivationURL:string=environment.URL+this.config.URL["ctclIdActivation"];
   ctclIdmodificationURL:string=environment.URL+this.config.URL["ctclIdModification"];
   ctclSubmitActivationURL:string=environment.URL+this.config.URL["ctclSubmitActivation"];
   ctcNeatIdAndTerminalOfficeURL:string=environment.URL+this.config.URL["ctclNeatIdAndTerminalOffice"];
   ctcNeatIdDeactivateDateURL:string=environment.URL+this.config.URL["ctcNeatIdDeactivateDate"];
   displayCtclIdReportURL:string=environment.URL+this.config.URL["displayCtclIdReport"];
   fetchCtclIdReportURL:string=environment.URL+this.config.URL["fetchCtclIdRequest"];
   ctclRequestDataForRefURL:string=environment.URL+this.config.URL["fetchCTCLDetailsByRefNo"];
   ctcapprovalOfCtclURL:string=environment.URL+this.config.URL["approvalOfCtcl"];
   ctcdownloadCertHelpURL:string=environment.URL+this.config.URL["ctcdownloadCertHelp"];
   
  
   neatUserIdEmitter=new EventEmitter<String>();

   getNeatIDDeactivateDate(neatUserId:string, terminalId:string):Observable<CtclNeatIdDeactivationDateRes>{
    let CtclNeatIdDeactRes: Observable<CtclNeatIdDeactivationDateRes> = null;
    const options = {
      params: new HttpParams().set('memId', (this.memId).toString()).append('neatUserId',neatUserId).append('terminalId',terminalId),
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    CtclNeatIdDeactRes= this.httpClient.get<CtclNeatIdDeactivationDateRes>(this.ctcNeatIdDeactivateDateURL, options)
      .pipe(
        
      )
    return CtclNeatIdDeactRes;

   }

   downloadCertificateHelpFile():Observable<any>{

    const options = {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.get(this.ctcdownloadCertHelpURL,options);
   }


   onNeatUserIdSelection(neatUserId:string){
      return this.neatUserIdEmitter.emit(neatUserId);
   }

   loadCtclActivationData():Observable<CtclIdActivationResponse>{
      let ctclActivationResponse: Observable<CtclIdActivationResponse> =null;
      const options = {
        params: new HttpParams().set('memCode', this.memCode.toString()).append('memId', this.memId.toString()),
        reportProgress: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
  
      ctclActivationResponse= this.httpClient.get<CtclIdActivationResponse>(this.ctclIdActivationURL, options)
        .pipe(
          
        )
      return ctclActivationResponse;

   }

   loadCtclModificationData():Observable<CtclIdModificationResponse>{
    let ctclModificationResponse: Observable<CtclIdModificationResponse> =null;
    const options = {
      params: new HttpParams().set('memCode', this.memCode.toString()).append('memId', this.memId.toString()),
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    ctclModificationResponse= this.httpClient.get<CtclIdModificationResponse>(this.ctclIdmodificationURL, options)
      .pipe(
        
      )
    return ctclModificationResponse;

 }

   loadCtclDeactivationData():Observable<CtclIdDeactivationResponse>{
    let ctclDeactivationResponse: Observable<CtclIdDeactivationResponse> =null;
    const options = {
      params: new HttpParams().set('memCode', this.memCode.toString()).append('memId', this.memId.toString()),
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    ctclDeactivationResponse= this.httpClient.get<CtclIdDeactivationResponse>(this.ctclIdDeactivationURL, options)
      .pipe(
        
      )
    return ctclDeactivationResponse;

 }

displayGridList(ctclreportGridReq: CtclreportGridReq){

  let ctclReportGridRes: Observable<CtclReportGridResponse> =null;
  const options = {
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  ctclReportGridRes= this.httpClient.post<CtclReportGridResponse>(this.fetchCtclIdReportURL, ctclreportGridReq, options)
    .pipe(
      
    )
  return ctclReportGridRes;

}

loadCtclRequestType(){
  let ctclRequestTypeResponse: Observable<DisplayCtclIdReport> =null;
  const options = {
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  ctclRequestTypeResponse= this.httpClient.get<DisplayCtclIdReport>(this.displayCtclIdReportURL, options)
    .pipe(
      
    )
  return ctclRequestTypeResponse;


}


 loadCtclNeatAndTerminalData(ctclNeatUserId:string):Observable<NeatAndterminalResponse>{
  let ctclNeatAndTerminalResponse: Observable<NeatAndterminalResponse> =null;
  const options = {
    params: new HttpParams().set('memId', this.memId.toString()).append('neatUserId', ctclNeatUserId),
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  ctclNeatAndTerminalResponse= this.httpClient.get<NeatAndterminalResponse>(this.ctclTerminalIdURL, options)
    .pipe(
      
    )
  return ctclNeatAndTerminalResponse;

}

loadCtclDataForDeactivation(ctclNeatUserId:string, ctclTerminalId:string):Observable<CtclResponseForDeactivation>{
  let ctclDeactivationResponse: Observable<CtclResponseForDeactivation> =null;
  const options = {
    params: new HttpParams().set('memId', this.memId.toString()).append('neatUserId', ctclNeatUserId).append('terminalId', ctclTerminalId),
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  
  

  ctclDeactivationResponse= this.httpClient.get<CtclResponseForDeactivation>(this.ctclRequestDataForDeactURL, options)
    .pipe(
      
    )
  return ctclDeactivationResponse;

}



loadCtclDataForActivationReport(ctclrefno:string):Observable<CtclResponseForDeactivation>{
  let ctclActivationReportResponse: Observable<CtclResponseForDeactivation> =null;
  const options = {
    params: new HttpParams().set('ctclrefno', ctclrefno),
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  
  

  ctclActivationReportResponse= this.httpClient.get<CtclResponseForDeactivation>(this.ctclRequestDataForRefURL, options)
    .pipe(
      
    )
  return ctclActivationReportResponse;

}


loadCtclDataForModification(ctclNeatUserId:string, ctclTerminalId:string):Observable<CtclResponseForModification>{
  let ctclModificationResponse: Observable<CtclResponseForModification> =null;
  const options = {
    params: new HttpParams().set('memId', this.memId.toString()).append('neatUserId', ctclNeatUserId).append('terminalId', ctclTerminalId),
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  

  ctclModificationResponse= this.httpClient.get<CtclResponseForModification>(this.ctclRequestDataForDeactURL, options)
    .pipe(
      
    )
  return ctclModificationResponse;

}




   submitctclActivationRequest(ctclActivationsubmitReq:any):Observable<CtclActivationSubmitResponse>{

    let ctclActivationSubmitRes:Observable<CtclActivationSubmitResponse> = null;

    const options = {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

    ctclActivationSubmitRes=this.httpClient.post<CtclActivationSubmitResponse>(this.ctclSubmitActivationURL,ctclActivationsubmitReq, options);

    return ctclActivationSubmitRes;
    

   }
   

   getNeatAndTerminalOffice():Observable<CtclNeatIdAndTerminalOffice>{
    let ctclNeatIdAndTerminalOfficeResponse: Observable<CtclNeatIdAndTerminalOffice> =null;
    const options = {
      params: new HttpParams().set('memCode', this.memCode.toString()),
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    ctclNeatIdAndTerminalOfficeResponse= this.httpClient.get<CtclNeatIdAndTerminalOffice>(this.ctcNeatIdAndTerminalOfficeURL, options)
      .pipe(
        
      )
    return ctclNeatIdAndTerminalOfficeResponse;

 }


 neatUserIdChange(neatUserId:any){
  let neatAndTerminalResponse: Observable<NeatAndterminalResponse> =null;
    const options = {
      params: new HttpParams().set('memId', this.memId.toString()).append('neatUserId', neatUserId),
      reportProgress: true
    }


    neatAndTerminalResponse = this.httpClient.get<NeatAndterminalResponse>(this.ctclTerminalIdURL, options);
    return neatAndTerminalResponse;
}
   

 formatDate(dateToFormat:Date, dateFormat:string):string{

  if(dateFormat==="dd-MM-yyyy hh:mm:ss a"){
    return (dateToFormat.getDate() <10?"0"+dateToFormat.getDate():dateToFormat.getDate())+"-"+("0"+(dateToFormat.getMonth()+1)).slice(-2)+"-"+dateToFormat.getFullYear() + " " +(new Date()).getHours()+":"+(new Date()).getMinutes()+":"+(new Date()).getSeconds()+" "+((new Date()).getHours()>=12?"PM":"AM")
  }
  else if(dateFormat==="dd-MM-yyyy"){
    // default format is dd-MM-yyyy
    return (dateToFormat.getDate() <10?"0"+dateToFormat.getDate():dateToFormat.getDate())+"-"+("0"+(dateToFormat.getMonth()+1)).slice(-2)+"-"+dateToFormat.getFullYear();
  }
  else{
    return dateToFormat.getFullYear()+"-"+("0"+(dateToFormat.getMonth()+1)).slice(-2)+ "-"+(dateToFormat.getDate() <10?"0"+dateToFormat.getDate():dateToFormat.getDate());
  }
  
 }

 approvalRejectionOfCtcl(approvalRejReq:CtclApprovalRejectionReq):Observable<CtclApprovalRejectionRes>{
  let approvalRejResponse: Observable<CtclApprovalRejectionRes> =null;

  const options = {
    reportProgress: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  approvalRejResponse= this.httpClient.post<CtclApprovalRejectionRes>(this.ctcapprovalOfCtclURL,approvalRejReq, options)
    .pipe(
      
    )
  return approvalRejResponse;
}

addCtclReportList(ctclDataFromReport: any[]) {
  this.ctclReportList = ctclDataFromReport;
}

getCtclReportByRef(reportId: string) { // change according to reference number
  return this.ctclReportList.find(data => data.ctclReqRefNo == reportId);
}

}
