import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../valueObject/config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivateCelUserService {

  celActivationData: any[] = [];
  config: any = config;
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getCelActivation(userControlId: string) {
    let URL = environment.URL + this.config.URL.getCelActivationList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    if (userControlId) {
      this.options.headers = this.options.headers.set('userId', userControlId);
    } else {
      this.options.headers = this.options.headers.delete('userId');
    }
    return this.http.get(URL, this.options);
  }

  activateCelMonitoring(userId: string, status: string) {
    let URL = environment.URL + this.config.URL.activateCelUser + '/' + userId + '/' + status;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, {}, this.options);
  }
  addCelActivationData(celActivationData: any[]) {
    this.celActivationData = celActivationData;
  }


}
