<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Neat User Name Change</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="/enit/eTrade/AdminUserIdRequestList">Enit</a></li>
                    <li *ngIf="entityType == 'ENIT'" class="active"><a routerLink="/eTrade/eUseridRequest/displayUIRequestProcessPage/changeName"
                            aria-current="page">Neat User Name Change</a>
                    </li>
                    <li *ngIf="entityType != 'ENIT'" class="active"><a routerLink="/tradeAdmin/eUseridRequestA/displayUIRequestProcessPage/changeName"
                        aria-current="page">Neat User Name Change</a>
                    </li>

                </ul>
            </div>

        </div>
    </div>
    <div>

        <form [formGroup]="changeName">
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h6>
                        Change User Name Request Details
                    </h6>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Member Code</label>
                            <input type="text" class="form-control" readonly formControlName="memCode">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Member Name</label>
                            <input type="text" class="form-control" readonly formControlName="memName">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>User Name</label>
                            <input type="text" class="form-control" readonly formControlName="userName" >
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>TAP/Child IP</label>
                            <input type="text" class="form-control" readonly formControlName="tapChildIp" >
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Tap ID</label>
                            <input type="text" class="form-control" readonly formControlName="tapId" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Request ID</label>
                            <input type="text" class="form-control" readonly formControlName="reqId" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>User ID</label>
                            <input type="text" class="form-control" readonly formControlName="userId" >
                        </div>
                    </div>
                </div>




                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <h6>
                        Details Of The Proposed User
                    </h6>
                    <div class="row">
                 

                        <div class="col-lg-3 form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" readonly formControlName="title" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>First Name</label>
                            <input type="text" class="form-control" readonly formControlName="firstName" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Middle Name</label>
                            <input type="text" class="form-control" readonly formControlName="middleName" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Last Name</label>
                            <input type="text" class="form-control" readonly formControlName="lastName" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label> Certified By</label>
                            <input type="text" class="form-control" readonly formControlName="certifiedBy">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Registration No Of Certificate</label>
                            <input type="text" class="form-control" readonly formControlName="regNo">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Validity Date</label>
                            <input type="text" class="form-control" readonly formControlName="validityDate" >
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Date Of Birth</label>
                            <input type="text"  class="form-control" readonly formControlName="dob" >
                        </div>
                    </div>

                    <div class="row">
                        <!-- <div class="col-lg-3 form-group">
                            <label>Percentage</label>
                            <input type="text" class="form-control" readonly formControlName="percentage" >
                        </div> -->

                        <div class="col-lg-3 form-group">
                            <label>Qualification</label>
                            <input type="text" class="form-control" readonly formControlName="qualification" >
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Father's/ Husband's Name</label>
                            <input type="text"  class="form-control" readonly formControlName="fatherHusband" >
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>PAN Number</label>
                            <input type="text" class="form-control" readonly formControlName="panNo" >
                        </div>
                      
                        <div class="col-lg-3 form-group">
                            <label>Contact Number</label>
                            <input type="text" class="form-control" readonly formControlName="contact" >
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Email</label>
                            <input type="text" class="form-control" readonly formControlName="email" >
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Reason for name change </label>
                            <input type="text" class="form-control" readonly formControlName="reason">
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Other details if any </label>
                            <input type="text" class="form-control" readonly formControlName="userRemarks">
                        </div>                                
                        <div class="col-lg-3 form-group">
                            <label>Current Status </label>
                            <input *ngIf="reqObj.cusReqStatus=='APPR'" type="text" class="form-control" readonly
                                value="APPROVED">
                            <input *ngIf="reqObj.cusReqStatus=='RJCT'" type="text" class="form-control" readonly
                                value="REJECTED">
                                <input *ngIf="reqObj.cusReqStatus=='SUB'" type="text" class="form-control" readonly
                                value="SUBMITTED">
                        </div>  
                        <div class="col-lg-3 form-group">
                            <label> Relationship of Proposed User with Trading Member/Regd. sub-broker</label>
                            <input type="text" class="form-control" readonly formControlName="relationship" >
                        </div> 
                        <div class="col-lg-3 form-group">
                            <label> Remarks</label>
                            <input type="text" class="form-control" readonly formControlName="remarks" >
                        </div>     

                    </div>
                    <div class="row" *ngIf="entityType=='ENIT'">
                        <div class="col-lg-3 form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button class="btn btn-cancel full-width" type="button" (click)="back()">Back</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div *ngIf="entityType!='ENIT'">
                    <!-- if data is submitted then load this div -->
                <div *ngIf="reqObj.cusReqStatus=='APPR' || reqObj.cusReqStatus=='RJCT'">
                    <div class="card card-body col-lg-12 mt-3 colorborder">
                        <div class="row">
                           
                            <div class="col-lg-3 form-group">
                                <label>Remarks  </label>
                                <input type="text" class="form-control" readonly formControlName="remarks">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button class="btn  btn-cancel full-width" type="button" (click)="back()">Back</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                    <!-- otherwise load this div for submission of data -->
    
                    <div *ngIf="reqObj.cusReqStatus==null || !reqObj.cusReqStatus || reqObj.cusReqStatus=='' || reqObj.cusReqStatus=='SUB'"
                        class="card card-body col-lg-12 mt-3 colorborder">
                        <div class="row">
                        
                            <div class="col-lg-3 form-group">
                                <label>Select Status</label>
                                <select class="form-control selectpicker" formControlName="updatedStatus" title="Select Status">
                                    
                                    <option value="APPR">APPROVED</option>
                                    <option value="RJCT">REJECTED</option>
                                    <!-- <option value="UNDER_PROC">UNDER PROCESSING</option>
                                    <option value="DOC_AWAT">DOCUMENT AWAITED</option>
                                    <option value="DOC_RECVD">DOCUMENT RECEIVED</option> -->
                                </select>
                            </div>
                            <div class="col-lg-3 form-group">
                                <label>Remarks</label>
                                <input type="text" class="form-control" formControlName="remarks">
    
                            </div>
                           
    
                           
                        </div>
                        <div class="row justify-content-start mb-5">
                            <div class="col-lg-4">
                                <div class="row justify-content-start">
                                   
                                    <div class="col-lg-3">
                                        <button  class="btn btn-cancel full-width" (click)="reset()">Reset</button>
                                    </div>
                                    <div class="col-lg-3">
                                        <button class="btn btn-primary full-width" [disabled]="changeName.invalid" (click)="submit()">Submit</button>
                                    </div>
                                    <div class="col-lg-3">
                                        <button  class="btn btn-cancel full-width" (click)="back()">Back</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
        </form>
    </div>
</div>



