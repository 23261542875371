import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { config } from '../valueObject/config';
import { Observable } from 'rxjs';
import { CtclActivationSubmitResponse, CtclApprovalRejectionReq, CtclApprovalRejectionRes, CtclIdActivationResponse, CtclIdDeactivationResponse, CtclIdModificationResponse, CtclNeatIdAndTerminalOffice, CtclNeatIdDeactivationDateRes, CtclreportGridReq, CtclReportGridResponse, CtclResponseForDeactivation, CtclResponseForModification, DisplayCtclIdReport, NeatAndterminalResponse } from '../component/enit/EnitTrade/ctcl/ctcl-request-response';
import { retry } from 'rxjs/operators';
import { misReportResponse } from '../component/enit/EnitTrade/ctcl/ctcl-request-response';
import {MisreportGridReq} from '../component/enit/EnitTrade/ctcl/ctcl-request-response';


@Injectable({
  providedIn: 'root'
})

export class MisReportService {

  config:any=config;
  userDetailsObj:any={};
  memId:number=0;
  memCode:number=0;
  memName:string="";
  entityType:string="";
  ctclReportList: any[]=[];
  fileName:File;


 
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  ctclRequestType:Array<{id:number, name: string}>=Array(
    {"id":1,"name":"CTCL Report"},
    //{"id":2,"name":"USER Report"}
  );

  constructor(private http: HttpClient) {
    var userDetailsJSON= localStorage.getItem('userDetails')||"";
    if(userDetailsJSON != 'undefined')
      this.userDetailsObj =JSON.parse(userDetailsJSON);
      this.memCode=(Number)(this.userDetailsObj.entityCode);
      this.memId=this.memCode-10000;
      this.memName=this.userDetailsObj.userName;
      this.entityType=this.userDetailsObj.entityType;

   }



    misDownload(requestID:any) {
    let URL = environment.URL + this.config.URL.misDownload+"?report_id="+requestID;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL,this.options);
  }

  loadReportData(){
    let URL = environment.URL + this.config.URL.loadReportData;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  downloadReport(reportType){
    let URL = environment.URL + this.config.URL.downloadReport+"?reportType="+reportType;;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }
  
}
