<!--The content below is only a placeholder and can be replaced.-->
<div>
  <strong>Html To PDF in Angular Application</strong>
</div>
<div>
  <input type="button" />
</div>

<pre>
    <div id="content">
    <div></div>
    </div>
    <div>
    <table id="contentToConvert">
    <tbody>
    <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
    </tr>
    <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
    </tr>
    <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
    </tr>
    <tr>
    <td>Ernst Handel</td>
    <td>Roland Mendel</td>
    <td>Austria</td>
    </tr>
    <tr>
    <td>Island Trading</td>
    <td>Helen Bennett</td>
    <td>UK</td>
    </tr>
    <tr>
    <td>Laughing Bacchus Winecellars</td>
    <td>Yoshi Tannamuri</td>
    <td>Canada</td>
    </tr>
    <tr>
    <td>Magazzini Alimentari Riuniti</td>
    <td>Giovanni Rovelli</td>
    <td>Italy</td>
    </tr>
    </tbody>
    </table>
    </div>
    </pre>
