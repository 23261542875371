import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/valueObject/config';



@Injectable({
  providedIn: 'root'
})
export class LoginService {

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  config = config;

  constructor(private http: HttpClient) { }

  getCaptcha(captchaURL: string) {
    let URL = environment.URL + captchaURL;
    return this.http.get(URL + "?_dc=" + (new Date().getTime()), this.options);
  }

  validateUser(validateURL: string, user: any) {
    let URL = environment.URL + validateURL;
    return this.http.post(URL, user, this.options);
  }

  authenticateUser(authenticateURL: string, user: any) {
    let URL = environment.URL + authenticateURL;
    return this.http.post(URL, user, this.options);
  }

  generateOTPToken(generateOTPTokenURL: string, user: any) {
    let URL = environment.URL + generateOTPTokenURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

  generateOTP(generateOTPURL: string, user: any) {
    let URL = environment.URL + generateOTPURL;
    return this.http.post(URL, user, this.options);
  }

  validateOTPToken(validateOTPTokenURL: string, user: any) {
    let URL = environment.URL + validateOTPTokenURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

  validateOTP(validateOTPURL: string, user: any) {
    let URL = environment.URL + validateOTPURL;
    return this.http.post(URL, user, this.options);
  }

  forgotPassword(forgotPasswordURL: string, user: any) {
    let URL = environment.URL + forgotPasswordURL;
    return this.http.post(URL, user, this.options);
  }

  unlockAccount(unlockAccountURL: string, user: any) {
    let URL = environment.URL + unlockAccountURL;
    return this.http.post(URL, user, this.options);
  }

  changePassword(changePasswordURL: string, user: any) {
    let URL = environment.URL + changePasswordURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, user, this.options);
  }

  logoutFromPreviousSession(token: string) {
    let URL = environment.URL + this.config.URL.logoutURL;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + token);
    return this.http.post(URL, {}, this.options);

  }

  verifyEmail(verificationId: string) {
    let URL = environment.URL + this.config.URL.verifyEmail + '/' + verificationId;
    return this.http.get(URL,this.options);
  }
}
