import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from '../valueObject/config';



@Injectable({
  providedIn: 'root'
})
export class LesService {
  tmLimitData: any[] = [];
  fileType: string;
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  config = config;
  requestList: any = []
  constructor(private http: HttpClient) { }


  //LES
  getAllLesOprnlSchemeData(requestObject) {
    let URL = environment.URL + this.config.URL.getAllLesOprnlSchemeData;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  //NEW API Added
  getListMemberFiles(requestObject) {
    let URL = environment.URL + this.config.URL.getListMemberFiles;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  getClientsAndSchemeList() {
    let URL = environment.URL + this.config.URL.getClientsAndSchemeList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  downloadHourlyAndDailyFile(fileName: any, FileType: any) {
    let URL = environment.URL + this.config.URL.downloadHourlyAndDailyFile + "/" + fileName + "?FileType=" + FileType;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }
}

