import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-base',
  templateUrl: './login-base.component.html',
  styleUrls: ['./login-base.component.css']
})
export class LoginBaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // openUserManual(type) {
  //   if (type == 'c2n')
  //     window.open('assets/User_Manual_C2N.pdf', '_blank')
  //   if (type == 'cel')
  //     window.open('assets/CEL_Manual.pdf', '_blank')
  // }

}
