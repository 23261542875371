import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CtclIdActivationComponent } from './ctcl-id-activation/ctcl-id-activation.component';
import { CtclIdDeactivationComponent } from './ctcl-id-deactivation/ctcl-id-deactivation.component';
import { CtclIdModificationComponent } from './ctcl-id-modification/ctcl-id-modification.component';
import { CtclIdRequestReportComponent } from './ctcl-id-request-report/ctcl-id-request-report.component';

const routes: Routes = [
{
    path:'displayCtclDeactivation',
    component: CtclIdDeactivationComponent
},
{
  path:'displayCtclActivation',
  component: CtclIdActivationComponent
}
,
{
  path:'displayCtclModification',
  component: CtclIdModificationComponent
},
{
  path:'displayCtclIdReport',
  component: CtclIdRequestReportComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CtclRoutingModule { }
