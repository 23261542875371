import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/app/valueObject/config';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: any = config;
  constructor(private http: HttpClient) {
  }

  getConfig() {
    return this.config;
  }

  getConfigDataByName(name: string): any {
    return this.config.data[name];;
  }

  getConfigURLByName(name: string): string {
    return this.config.URL[name];
  }

}
