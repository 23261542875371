import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ctcl-id-approvereject',
  templateUrl: './ctcl-id-approvereject.component.html',
  styleUrls: ['./ctcl-id-approvereject.component.css']
})
export class CtclIdApproverejectComponent implements OnInit {

  @Output() approveOrRejectSelection: EventEmitter<string> = new EventEmitter<string>();


  submitApproveOrRejectReq(requestSelection: string) {
    this.approveOrRejectSelection.emit(requestSelection);

  }
  constructor() { }

  ngOnInit(): void {
  }

}
