import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * This class all the generic validation methods. 
 * Few of them can be used as parameters in FormControl Object
 * 
 * @export
 * @class CustomValidators
 * @extends {Validators}
 */
export class CustomValidators extends Validators {

    /**
     * This method is used as custome Validator to validate time.
     * Currently, it checks field is in 00:00:00 format
     * Kindly check @example to see how to use it. 
     * 
     * @static
     * @param {string} value 
     *      value is field
     * @returns
     * @memberOf CustomValidators
     * @example 
     *  new FormControl('',[<any>CustomValidators.timeValidator])     
     */

    static timeValidator(value: string) {
        if (value == null || value == "")
            return false;
        var regex = new RegExp("^(2[0-3]|1[0-9]|0[0-9]|[^0-9][0-9]):([0-5][0-9]|[0-9]):([0-5][0-9]|[0-9])$");
        return (regex.test(value)) ? true : false;
    }


    // TODO Can be optimized in code length
    /**
     * This method is as Validator in FormControl used to validate IP addresses.
     * It can be used to either check a single IP address or multiple IP addresses 
     * separated by a separator.
     * Kindly check @example to see how to use it 
     * @static
     * @param {string} separator 
     *      Separator used to separate multiple IP addresses.
     *      Pass null if the input field accepts only single IP address 
     * @returns 
     * @memberOf CustomValidators
     * @example 
     * for Single IP Address : new FormControl('',[CustomValidators.validateIPAddress(null)])
     * for Multiple IP Address : new FormControl('',[CustomValidators.validateIPAddress(<saparator>)])
     *  where <separator> can be any separator string, e.g: CustomValidators.validateIPAddress("|")
     */
    static validateIPAddress(separator: string) {
        return (formControl: FormControl) => {
            let IP_REGEX_STRT = "^(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
            if (formControl.value == null || formControl.value == undefined || formControl.value == "")
                return null;
            let value: string = formControl.value;
            // use Code if need  for the IP address with space in IP pipe seprated.    
            // value = value.replace(/ /g, '');
            if (separator == null) {
                var regex = new RegExp(IP_REGEX_STRT + "))$");
                return (regex.test(value)) ? null : {
                    validateIPAddress: {
                        valid: false
                    }
                };
            } else {
                var regex = new RegExp(IP_REGEX_STRT + "(\\" + separator + "?))*)$");
                return (value.charAt(value.length - 1) != separator && regex.test(value)) ? null : {
                    validateIPAddress: {
                        valid: false
                    }
                };

            }
        }
    }

    /**
     * This method checks if the file name has valid extention based on the list 
     * of valid Extension passed as second param
     * NOTE: This method CANNOT be used as formControl Validators
     * @static
     * @param {string} fileName The name of file whose extention has to be checked
     * @param {string[]} requiredExtnList Array of extentions that are valid
     * @returns {boolean} returns TRUE is fileName has valid extention, else false
     * 
     * @memberOf CustomValidators
     * 
     * @example CustomValidators.isFileExtnValid("Test.txt",["pdf","docx"]) 
     * This will return FALSE as "txt" is not valid as per second param List
     */
    static isFileExtnValid(fileName: string, requiredExtnList: string[]): boolean {
        let matchFileExtension = this.matchFileExtension
        // 'this' is send as parameters to matchFileExtension callback to access the method from outside scope of callback.
        return requiredExtnList.some(ext => matchFileExtension(ext, fileName, this));
    }

    /**
     * Method to get the file extension of the fileName 
     */
    static getFileExtension(fileName: String): String {
        let fileExtension = fileName.split('.');
        if (fileName.startsWith(".") && fileExtension.length === 1) {
            return fileName;
        }
        return fileExtension.pop();
    }

    /**
     * Method to compair the file allowed extension and the fileName
     * 'obj' represents the 'this' where method is located to access the method in nested callback directly. 
     */
    static matchFileExtension(extension: String, fileName: String, obj: any): boolean {
        let fileExtension = obj.getFileExtension(fileName);
        return extension.toUpperCase() === fileExtension.toUpperCase()
    }

    /* TODO */
    /**
     * Older version of the code if need at any condition using for is commented for reference.
     */
    // static isFileExtnValid(fileName: string, requiredExtnList: string[]): boolean {
    //     let dotIndex = fileName.lastIndexOf(".");
    //     if (dotIndex < 0) // for file names without extention
    //         return false;
    //     let fileExtn = fileName.substring(dotIndex);
    //     for (let requiredExtn of requiredExtnList) {
    //         if (requiredExtn.toUpperCase() == fileExtn.toUpperCase())
    //             return true;
    //     }
    //     return false;
    // }


    /**
     * This method is used as FormControl Validator to validate blank spaces.
     * Kindly check @example to see how to use it. 
     * 
     * @static
     * @param {FormControl} formControl 
     *      formControl of the field (this is passed automatically when used as validator)
     * @returns
     * @memberOf CustomValidators
     * @example 
     *  new FormControl('',[<any>CustomValidators.isBlankSpaces])     
    
     */

    static isNonSpacedStr(formControl: FormControl) {
        var value: string = formControl.value;
        if (value != undefined && value.indexOf(' ') !== -1) {
            return {
                isNonSpacedStr: {
                    valid: false
                }
            }
        }
    }

    static isBlankSpaces(formControl: FormControl) {
        var value: string = formControl.value;

        if (value == null || value == "" || value.trim() == "")
            return {
                isBlankSpaces: {
                    valid: false
                }
            }
    }


    /**
             * This method is used as FormControl Validator to validate password and confirmPassword.
             * Kindly check @example to see how to use it. 
             * @memberOf CustomValidators
             * @example 
             * matchingPasswords: this.fb.group({
                    new_password: new FormControl("", [Validators.required]),
                    re_enter_new_password: new FormControl("", [Validators.required])
                }, {
                        validator: CustomValidators.compairPassword('new_password', 're_enter_new_password')
                    })     
             */

    static comparePassword(passwordKey: string, repeatPasswordKey: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let password = group.controls[passwordKey];
            let repeatPassword = group.controls[repeatPasswordKey];
            if (password.value !== repeatPassword.value) {
                return {
                    passwordMismatch: true
                };
            }
        }
    }
    static match(controlName: string, checkControlName: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const checkControl = controls.get(checkControlName);
            if (checkControl.errors && !checkControl.errors.matching) {
                return null;
            }
            if (control.value !== checkControl.value) {
                controls.get(checkControlName).setErrors({ matching: true });
                return { matching: true };
            } else {
                return null;
            }
        };
    }


    /** 
           * This method is used as FormControl Validator to validate UpperCase.
           * Kindly check @example to see how to use it. 
           * 
           * @static
           * @param {FormControl} formControl 
           *      formControl of the field (this is passed automatically when used as validator)
           * @returns
           * @memberOf CustomValidators
           * @example 
           *  new FormControl('',[<any>CustomValidators.upperCaseValidtion])     
           */

    static upperCaseValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/[A-Z]+/g);
        return (regex.test(value)) ? null : {
            upperCaseValidtion: {
                valid: false
            }
        }
    }

    /**
           * This method is used as FormControl Validator to validate alphaNumericValidtion.
           * Kindly check @example to see how to use it. 
           * 
           * @static
           * @param {FormControl} formControl 
           *      formControl of the field (this is passed automatically when used as validator)
           * @returns
           * @memberOf CustomValidators
           * @example 
           *  new FormControl('',[<any>CustomValidators.alphaNumericValidtion])     
           */

    static alphaNumericValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/[0-9a-zA-Z]/gi);
        return (regex.test(value)) ? null : {
            alphaNumericValidtion: {
                valid: false
            }
        }
    }

    /**
          * This method is used as FormControl Validator to validate specialCaracterValidtion.
          * Kindly check @example to see how to use it. 
          * 
          * @static
          * @param {FormControl} formControl 
          *      formControl of the field (this is passed automatically when used as validator)
          * @returns
          * @memberOf CustomValidators
          * @example 
          *  new FormControl('',[<any>CustomValidators.specialCaracterValidtion])     
          */

    static specialCharacterValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/[^0-9a-zA-Z]/gi);
        return (regex.test(value)) ? null : {
            specialCaracterValidtion: {
                valid: false
            }
        }
    }

    /**
          * This method is used as FormControl Validator to validate numberValidtion.
          * Kindly check @example to see how to use it. 
          * 
          * @static
          * @param {FormControl} formControl 
          *      formControl of the field (this is passed automatically when used as validator)
          * @returns
          * @memberOf CustomValidators
          * @example 
          *  new FormControl('',[<any>CustomValidators.numberValidtion])     
          */
    static numberValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/[0-9]+/g);
        return (regex.test(value)) ? null : {
            numberValidation: {
                valid: false
            }
        }
    }

    /**
          * This method is used as FormControl Validator to validate numberValidtion wit decimal upto two point.
          * Kindly check @example to see how to use it. 
          * 
          * @static
          * @param {FormControl} formControl 
          *      formControl of the field (this is passed automatically when used as validator)
          * @returns
          * @memberOf CustomValidators
          * @example 
          *  new FormControl('',[<any>CustomValidators.decimalNumberValidation])     
          */

    static decimalNumberValidation(formControl: FormControl) {
        var value: string = formControl.value;
        if (value === "" || value == null || value === '') {
        } else {
            let regex = new RegExp(/^[0-9]+(\.[0-9]{0,2})?$/gm);
            return (regex.test(value)) ? null : {
                decimalNumberValidation: {
                    valid: false
                }
            }
        }
    }
    static decimalNumberValidationForstrikePrice(formControl: FormControl) {
        var value: string = formControl.value;
        if (value === "" || value == null || value === '') {
        } else {

            let regex = new RegExp(/^[0-9]+(\.[0-9]{0,2})?$/gm);
            // var result= regex.test(value);
            var result = (regex.test(value)) ? null
                : {
                    decimalNumberValidation: {
                        valid: false
                    }
                }
            if (result == null) {
                if (value != undefined || value != null) {
                    var tempval = value.toString().split(".");
                    return tempval[0].length > 7 ? { decimalNumberValidation: { valid: false } } : null

                }
                else {
                    return result;
                }
            } else {
                return result;
            }
        }
    }

    /**
         * This method is used as FormControl Validator to validate noDecimalNumberValidation.
         * Kindly check @example to see how to use it. 
         * 
         * @static
         * @param {FormControl} formControl 
         *      formControl of the field (this is passed automatically when used as validator)
         * @returns
         * @memberOf CustomValidators
         * @example 
         *  new FormControl('',[<any>CustomValidators.noDecimalNumberValidation])     
         */
    static noDecimalNumberValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/^(0|[1-9]\d*)$/gm);
        return (regex.test(value)) ? null : {
            noDecimalNumberValidation: {
                valid: false
            }
        }
    }

    /**
       * This method is used as FormControl Validator to validate allowOnlyNumberValidation.
       * Kindly check @example to see how to use it. 
       * 
       * @static
       * @param {FormControl} formControl 
       *      formControl of the field (this is passed automatically when used as validator)
       * @returns
       * @memberOf CustomValidators
       * @example 
       *  new FormControl('',[<any>CustomValidators.allowOnlyNumberValidation])     
       */
    static allowOnlyNumberValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/^[0-9]+$/);
        return (regex.test(value)) ? null : {
            allowOnlyNumberValidation: {
                valid: false
            }
        }
    }


    /**
           * This method is used as FormControl Validator to validate allowOnlyAlphaNumericWithNoSplCharsValidation.
           * Kindly check @example to see how to use it. 
           * 
           * @static
           * @param {FormControl} formControl 
           *      formControl of the field (this is passed automatically when used as validator)
           * @returns
           * @memberOf CustomValidators
           * @example 
           *  new FormControl('',[<any>CustomValidators.allowOnlyAlphaNumericWithNoSplCharsValidation])     
           */

    static allowOnlyAlphaNumericWithNoSplCharsValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/^([0-9a-zA-Z]+)$/);
        return (regex.test(value)) ? null : {
            allowOnlyAlphaNumericWithNoSplCharsValidation: {
                valid: false
            }
        }
    }

    /**
          * This method is used as FormControl Validator to validate allowOnlyAlphaNumericWithFewSplCharsValidation.
          * Kindly check @example to see how to use it. 
          * 
          * @static
          * @param {FormControl} formControl 
          *      formControl of the field (this is passed automatically when used as validator)
          * @returns
          * @memberOf CustomValidators
          * @example 
          *  new FormControl('',[<any>CustomValidators.allowOnlyAlphaNumericWithFewSplCharsValidation])     
          */

    static allowOnlyAlphaNumericWithFewSplCharsValidation(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/^([0-9a-zA-Z&-]+)$/);
        return (regex.test(value)) ? null : {
            allowOnlyAlphaNumericWithFewSplCharsValidation: {
                valid: false
            }
        }
    }

    static multipleOfLakhs(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/[0-9]+/g);
        return (regex.test(value)) ? ((formControl.value % 100000 == 0) ? null : {
            multipleOfLakhs: {
                valid: false
            }
        }) : {
            numberValidation: {
                valid: false
            }
        }
    }

    static greaterThanOneThousand(formControl: FormControl) {
        var value: string = formControl.value;
        let regex = new RegExp(/[0-9]+/g);
        return (regex.test(value)) ? ((formControl.value >= 10000) ? null : {
            greaterThanOneThousand: {
                valid: false
            }
        }) : {
            numberValidation: {
                valid: false
            }
        }
    }

    static firstNumberGreater(firstControlName: string, secondControlName: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const firstControl = controls.get(firstControlName);
            const secondControl = controls.get(secondControlName);
            if (secondControl.errors && !secondControl.errors.greater) {
                return null;
            }
            if (firstControl.value <= secondControl.value) {
                controls.get(firstControlName).setErrors({ greater: true });
                return { greater: true };
            } else {
                return null;
            }
        };
    }
}