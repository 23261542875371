import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { config } from 'src/app/valueObject/config';
import { CelService } from 'src/app/service/cel.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UserControlService } from 'src/app/service/user-control.service';
import { AppService } from 'src/app/service/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-add-cel-user',
  templateUrl: './add-cel-user.component.html',
  styleUrls: ['./add-cel-user.component.css']
})
export class AddCelUserComponent implements OnInit, AfterViewInit {
  Checkedstatus: any;
  submitted = false;
  adduserForm: FormGroup;
  user: any = {};
  action: string = 'add';
  initialEntityType: string;
  initialEntityCode: string;
  config: any = config;
  entityCodeData: any = [];
  message: string;
  userDetails: any;
  disableEntity: boolean = true;
  disableStatus: boolean = false;
  menuCode = 'celUserMgmt';
  access: any = {};
  approvalForm: FormGroup;
  userControlId: string = '';
  celUserTypeData: any = [];
  countryCodeData: any = [];

  @ViewChild('selectpicker') selectPicker: ElementRef;


  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private appService: AppService,
    private celService: CelService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private userControlService: UserControlService) {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.user = this.userService.getUserById(params['id']);
      }
    });
  }

  get f() { return this.adduserForm.controls; }

  ngOnInit(): void {
    this.titleService.setTitle('CEL - User Management');
    this.getEntityTypeData();
    this.celUserTypeData = this.config.data.celUserTypeData.filter(data => data.id != 0);
    this.userControlId = this.userControlService.getUserControlId();
    this.countryCodeData = this.config.data.countryCodeData;
    this.userDetails = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails();
    this.approvalForm = this.formBuilder.group({
      comment: [null]
    });

    if (this.user && this.user.userId) {
      this.action = 'update';
      this.adduserForm = this.formBuilder.group({
        userLoginId: [this.user.userLoginId, [Validators.required, Validators.pattern("^[a-zA-Z0-9]{1}[a-zA-Z0-9_]{4,}$")]],
        entityType: ['TM', Validators.required],
        entityCode: [this.user.entityCode, Validators.required],
        userName: [this.user.userName, [Validators.required]], // Validators.pattern(".*[a-zA-Z]+.*")]],
        celUserType: [this.user.celUserType, Validators.required],
        userEmail: [this.user.userEmail, [Validators.required, Validators.email]],
        countryCode: [this.user.countryCode],
        userMobile: [this.user.userMobile, Validators.pattern("^[0-9]{8,12}$")],
        isActive: [this.user.isActive, Validators.required],
        isDeleted: [this.user.isDeleted, Validators.required],
        comments: [this.user.comments]
      });
      this.adduserForm.get('celUserType').disable();
    } else {
      this.action = 'add';
      this.adduserForm = this.formBuilder.group({
        userLoginId: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9]{1}[a-zA-Z0-9_]{4,}$")]],
        entityType: ['TM', Validators.required],
        entityCode: [(this.userDetails.entityType != 'NICCL' && this.userDetails.entityType != 'NSEIFSC') ? this.userDetails.entityCode : '', Validators.required],
        userName: [null, [Validators.required, Validators.pattern("^[0-9a-zA-Z]+.*")]],
        celUserType: [null, Validators.required],
        userEmail: [null, [Validators.required, Validators.email]],
        userMobile: [null, Validators.pattern("^[0-9]{8,12}$")],
        countryCode: [91],
        isActive: ['Y', Validators.required],
        comments: [this.user.comments]
      });

    }
    if (this.userDetails.entityType == 'NICCL' || this.userDetails.entityType == 'NSEIFSC') {
      this.disableEntity = false;
    }
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menuCode) : this.userDetailsService.checkUserAccess(this.menuCode);
    if (this.access.accessType === "R" || this.access.roleId == 2) {
      this.action = 'view';
      this.adduserForm.disable();
    }
    if (this.disableEntity || this.action == 'view') {
      this.adduserForm.get('entityCode').disable();
    }
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  ngAfterViewInit() {
    $('.selectpicker').selectpicker();
  }

  getEntityTypeData() {
    this.appService.getEntityTypeData().pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        let entityTypeData = response.data[0].userCreationDropdown;
        this.entityCodeData = entityTypeData.find(data => data.entityType == 'TM').entityAndCompanyBeans;
        if (this.action == 'update' || this.action == 'view') {
          this.entityCodeData = entityTypeData.find(data => data.entityType == this.user.entityType).entityAndCompanyBeans;
          this.f.entityCode.setValue(this.user.entityCode);
          this.f.celUserType.setValue(this.user.celUserType);
        } else if (this.userDetails.entityType != 'NICCL' && this.userDetails.entityType != 'NSEIFSC') {
          this.entityCodeData = entityTypeData.find(data => data.entityType == this.userDetails.entityType).entityAndCompanyBeans;
          this.f.entityCode.setValue(this.userDetails.entityCode);
        }
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');

      } else {
        this.toastr.error(response.message);
      }

    })
  }

  onSubmit() {
    this.submitted = true;

    let requestObject = {
      "userId": this.user.userId || null,
      "userLoginId": this.f.userLoginId.value,
      "userName": this.f.userName.value,
      "entityCode": this.f.entityCode.value,
      "entityType": this.f.entityType.value,
      "userEmail": this.f.userEmail.value,
      "countryCode": this.f.countryCode.value,
      "userMobile": this.f.userMobile.value,
      "userType": 2,
      "celUserType": this.f.celUserType.value,
      "isActive": this.f.isActive.value,
      "isDeleted": this.f.isDeleted ? this.f.isDeleted.value : 'N',
      "checkedBy": this.user.checkedBy || null,
      "checkedDate": this.user.checkedDate || null,
      "createdBy": this.user.createdBy || this.userDetailsService.getUserDetails().userId,
      "createdDate": this.user.createdDate || new Date(),
      "lastModifiedBy": this.userDetailsService.getUserDetails().userId,
      "lastModifiedOn": new Date(),
      approval: null
    };

    if (this.action == 'add') {
      this.celService.addUser(requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.submitted = false;
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.message = 'User created successfully';
          this.toastr.success(this.message);
          this.router.navigate(['../celUserMgmt'], { relativeTo: this.route, skipLocationChange: true });
        } else {
          this.toastr.error(response.message);
          this.submitted = false;
        }

      })
    } else {
      this.celService.updateUser(requestObject).pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.submitted = false;
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      ).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.message = 'User updated successfully.';
          this.toastr.success(this.message);
          this.router.navigate(['../celUserMgmt'], { relativeTo: this.route, skipLocationChange: true });
        } else {
          this.message = response.message;
          this.toastr.error(this.message);
        }
      })
    }
  }

  sendVerificationLink(branchEmailId) {
    this.spinner.show();
    this.celService.sendVerificationLink(branchEmailId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.toastr.success("Verification link sent to email ID");
        } else {
          this.toastr.error(response.message);
        }
      }
    );
  }

}
