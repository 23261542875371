<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Disable Neat User</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eTrade/eUseridRequest/displayDisableNeatIdRequestPage" aria-current="page">Disable Neat User</a></li>
                    
                </ul>
            </div>

        </div>
    </div>
    <div>

        <form [formGroup]="disableNeatUser">
                <div class="card card-body col-lg-12 mt-3 colorborder">
                    <div class="row">
                        
                        <div class="col-lg-3 form-group">
                            <label>User ID</label>
                             <select class="form-control selectpicker" formControlName="cusDlrId" data-style="btn-info" title="Select User Id"
                                (change)="selectDlrId()" data-live-search="true">
                                <option *ngFor="let data of cusDlrId" value="{{data}}">{{data}}</option>
                            </select>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>User Name</label>
                            <input type="text" value="" class="form-control" formControlName="UserName" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Type Of User <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="TypeOfUser" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Existing TAP/Child IP <span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="ExistingTapIp" readonly >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>TAP ID</label>
                            <input type="text" value="" class="form-control" formControlName="TapId" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Existing Tap/Child Ip Address</label>
                            <input type="text" class="form-control" value="" formControlName="ExistingTapChildIpAddress" readonly>
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>No Of Existing Terminals At The Above TAP IP<span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="existingTerminalTapIp" readonly>

                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Email ID</label>
                            <input type="email" class="form-control" formControlName="EmailId" >
                            <div *ngIf="f.EmailId.invalid && f.EmailId.touched">
                                <!-- <p *ngIf="f.EmailId.errors.required" class="error nocase">
                                    Please enter email ID
                                </p> -->
                                <p *ngIf="f.EmailId.errors.email" class="error nocase">
                                    Please enter valid email ID
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group">
                            <label>Mobile Number</label>
                            <input type="number" class="form-control" formControlName="MobileNo"  >
                            <div *ngIf="f.MobileNo.invalid && f.MobileNo.touched" class="error nocase">

                                <!-- <div *ngIf="f.MobileNo.errors.required">Required Mobile Number</div> -->
                                <div
                                    *ngIf="f.MobileNo.errors.maxlength || f.MobileNo.errors.minlength">
                                    Mobile Number Should Be 10 Digit
                                </div>
                                <div
                                    *ngIf="f.MobileNo.errors.pattern" >
                                    Mobile Number Should Contain Numbers Only
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-3 form-group">
                            <label>Reason For Disabling Of User ID<span class="red">*</span></label>
                            <input type="text" class="form-control" formControlName="ReasonForDisableUser" >
                           
                            <p *ngIf="f.ReasonForDisableUser.invalid && f.ReasonForDisableUser.touched" class="error nocase">
                                Reason for disabling dealer required
                            </p>
                        </div>  
                    </div>
                    <div class=" form-group">
                        <p> <input type="checkbox" formControlName="CheckType" > 
                            I/We declare to abide by the rules/regulations as communicated by the Exchange/SEBI/IFSCA/other Regulators from time to time.
                        </p>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-lg-8">
                            <div class="row justify-content-start">
                                <div class="col-lg-2">
                                    <button class="btn btn-primary full-width"  [disabled]="verifyOTP!=true || submitted"  type="submit" (click)="onSubmit()">Submit</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn  btn-cancel full-width" (click)="reset()" >Reset</button>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn  btn-primary full-width" [disabled]="disableNeatUser.invalid" data-toggle="modal"  data-target="#cashdeposite">Certify</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                 </div>
        </form>
    </div>
</div>

<div class="modal fade" id="cashdeposite" tabindex="-1" role="dialog" aria-labelledby="cashdepositeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verify OTP</h5>
              </div>
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <app-verfiy-request-by-otp  [module]="module" [reqtype]="reqtype" [loginId]="loginId" [memId]="memId" (newItemEvent)="newItemEvent($event)"></app-verfiy-request-by-otp>
        </div>
    </div>
</div>