import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { config } from 'src/app/valueObject/config';
import { ExtranetService } from 'src/app/service/extranet.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { UserControlService } from 'src/app/service/user-control.service';

declare var $: any;
@Component({
  selector: 'app-circular',
  templateUrl: './circular.component.html',
  styleUrls: ['./circular.component.css']
})
export class CircularComponent implements OnInit {
  config: any = config;
  fileName: string;
  file: File;
  dnldFile: any;
  items: any = [];
  path: string;
  treeConfig: any = TreeviewConfig.create({
    hasCollapseExpand: true,
    hasFilter: true,
    maxHeight: 550
  });
  userControlId: string = '';
  @ViewChild(TreeviewComponent, { static: false })
  treeviewComponent: TreeviewComponent;

  constructor(
    private spinner: NgxSpinnerService,
    private extranetService: ExtranetService,
    private toastr: ToastrService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private userControlService: UserControlService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Extranet - Circular');
    this.userControlId = this.userControlService.getUserControlId();
    this.getFiles();
    this.userDetailsService.getUserDetails();
  }

  getFiles() {
    this.spinner.show();
    this.extranetService.getExtranetFiles('Circular', this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.items = [];
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.items = [];
        response.data.children?.forEach(element => {
          let treeviewItem = new TreeviewItem(element)
          treeviewItem.setCollapsedRecursive(true);
          this.items.push(treeviewItem);
        });
        this.cdref.detectChanges();
      } else {
        this.items = [];
        this.cdref.detectChanges();
        this.toastr.error(response.message);
      }
    })
  }

  downloadFile(fileItem) {
    let path = {
      "filepath": fileItem.value.filePath
    }
    this.extranetService.getFileDownloadable(path).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        let file = response.data[0];
        let contentType = 'text/csv';
        let blob = base64StringToBlob(file, contentType);
        saveAs(blob, fileItem.text, {
          autoBOM: true
        })
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    })

  }

  clearSearch() {
    this.treeviewComponent.onFilterTextChange('')
    this.treeviewComponent.filterText = '';
    this.items = [...this.items];
  }

}
