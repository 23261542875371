import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from 'src/app/service/access.guard';
import { MenuGuard } from 'src/app/service/menu.guard';
import { SubmenuGuard } from 'src/app/service/submenu.guard';
import { CommonComponent } from './common/common.component';
import { CircularComponent } from './circular/circular.component';
import { MemberfolderComponent } from './memberfolder/memberfolder.component';


const routes: Routes = [

  { path: 'common', canDeactivate: [SubmenuGuard], component: CommonComponent },
  // { path: 'circular', canDeactivate: [SubmenuGuard], component: CircularComponent },
  { path: 'member', canDeactivate: [SubmenuGuard], component: MemberfolderComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MenuGuard, SubmenuGuard, AccessGuard]
})
export class ExtranetRoutingModule { }
