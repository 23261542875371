<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>Machine Learning & Artificial Intelligence</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active"><a routerLink="eCompliance/mlAndAi/mlal" aria-current="page">Machine Learning & Artificial
                            Intelligence</a></li>
                    <!-- <li class="active"><a routerLink="/eComplaince/mlAi" aria-current="page">Machine Learning & Artificial
                            Intelligence</a></li>     -->

                </ul>
            </div>

        </div>
    </div>

<div *ngIf="eligible">
    <form [formGroup]="requestForm">

        <div class="card card-body col-lg-12 mt-3 colorborder">
            <div class="row">
                <div class="col-lg-3 form-group">
                <label>Quarter</label><span class="red">*</span>
                <!-- <input type="text" class="form-control"  formControlName="quarter"> -->
               <select class="form-control " formControlName="quarter" data-style="btn-info" title="Select Quarter">
                     <option value="{{quarterList}}">{{quarterList}}</option> 
                </select>
                </div>
                <div class="col-lg-3 form-group">
                    <label>Entity IFSCA Registration Number</label>
                    <input type="text" class="form-control" readonly formControlName="registrationNumber">
                </div>
                <div class="col-lg-3 form-group">
                    <label>Registered Entity Category</label>
                    <input type="text" class="form-control" readonly
                        formControlName="entityCategory">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label>Entity Name</label>
                    <input type="text" class="form-control" readonly
                        formControlName="memberName">
                </div>
                <div class="col-lg-3 form-group">
                    <label>Entity PAN No.</label>
                    <input type="text" class="form-control" readonly formControlName="panNO">
                </div>
            </div>
        </div>

        <div class="card card-body col-lg-12 mt-3 colorborder">
            <h5>System Details</h5>
     
            <ng-container formArrayName="form">
                <ng-container *ngFor="let add of form.controls; let j = index" [formGroupName]="j" >

                    <!-- collapse bar here -->
                <!-- <h6 class="pointer"><i class="fa fa-plus" style="font-size: 18px;" (click)="toggle(j)"></i> System Details {{j+1}}<span class="red">*</span></h6> -->
                <div class="colorborder mt-3">
                <div class="card-header pointer" (click)="toggle(j)">
                    <h6 class="mb-0" >
                        <i  id="up"  class="fa fa-angle-up pull-right rotate-icon"></i>System Details {{j+1}}<span class="red">*</span>
                        <!-- <i  id="down"  class="fa fa-angle-down pull-right rotate-icon"></i> -->
                    </h6>
                </div>
                
                <div class="row justify-content-end">
                   
                    <div class="mr-4 mt-2">
                        <button class="btn btn-cancel mb-3" (click)="removeForm(j)">Delete Row </button>
                        <!-- <i class="fa fa-trash pointer" style="font-size:36px" (click)="removeForm(j)" aria-hidden="true"></i> -->
                    </div>
                </div>
                <div [id]="j" class="ml-2 mr-2 ">
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Application / System name<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemName">
                        </div>
                        <div class="col-lg-3 form-group ">
                            <label >Type of area where AI or ML is used<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <select class="form-control " formControlName="typeOfArea" data-style="btn-info" title="Select Type">
                                <!-- <option *ngFor="let data of cusDlrId" value="{{data}}">{{data}}</option> -->
                                <option  value="AML">AML</option>
                                <option  value="Compliance">Compliance</option>
                                <option  value="KYC">KYC</option>
                                <option  value="Order Execution">Order Execution</option>
                                <option  value="Surveillance">Surveillance</option>
                                <option  value="Others">Others</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Date from when the Application/System was used <span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <!-- <input type="text" class="form-control" formControlName="date"> -->
                            <div class="input-group pointer">
                                <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue[j]"
                                    value="{{bsValue[j] | date:'MM/dd/yyyy'}}" formControlName="applicationDateStr" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="" [maxDate]="maxDate">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar"  [(bsValue)]="bsValue[j]" bsDatepicker [maxDate]="maxDate"
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 form-group ">
                            <label >Does the system involve order initiation, routing and execution?<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="radio"  value="Y" formControlName="systemOrder"> Yes
                            <input type="radio"  value="N"  formControlName="systemOrder"> No
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Does the system fall under discretionary investment or Portfolio management activities?<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemActivities"> Yes
                            <input type="radio" value="N"  formControlName="systemActivities"> No
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >Does the system disseminate investment or trading advice or strategies?<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemDisseiminate"> Yes
                            <input type="radio" value="N"  formControlName="systemDisseiminate"> No
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Is the application/system used in area of Cyber Security to detect attacks<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemUsedArea"> Yes
                            <input type="radio" value="N"  formControlName="systemUsedArea"> No
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >What claims have been made regarding AI and ML Application / System , if any? <span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemClaims">
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >What is the name of the Tool / Technology that is categorized as AI and ML system / Application and submissions are declared vide this response<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemTools">
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >How was the AI or ML project implemented<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemProject">
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Are the key controls and control points in your AI or ML application or systems in accordance to circular of SEBI that mandate cyber security control requirements?<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemKeyControls">
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >Is the AI / ML system included in the system audit, if applicable?<span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="radio" value="Y" formControlName="systemAudit"> Yes
                            <input type="radio" value="N"  formControlName="systemAudit"> No
                            <input type="radio" value="NA"  formControlName="systemAudit"> NA
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 form-group ">
                            <label >Describe the application / system and how it uses AI / ML as portrayed in the product offering<span class="red">*</span> &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        </div>

                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="sysyemDescription">
                        </div>

                        <div class="col-lg-3 form-group ">
                            <label >What safeguards are in place to prevent abnormal behavior of the AI or ML application / System <span
                                    class="red">*</span></label>
                        </div>
                        <div class="col-lg-3 form-group">
                            <input type="text" class="form-control" formControlName="systemSafeGuard">
                        </div>
                       
                    </div>
                </div>

                    <!-- <tr >
                            <td>
                            <div class="row ">
                                <div class="col-lg-3 form-group ">
                                    <label>User Name</label>
                                    <input type="text"  class="form-control" formControlName="name" >
                                </div>
                                <div class="col-lg-3 form-group">
                                    <label>Type Of User <span class="red">*</span></label>
                                    <input type="text"  class="form-control" formControlName="number" />
                                </div>
                                <div class="col-lg-3 form-group">
                                    <label><span class="red"></span></label>
                                    <button class="btn  btn-cancel " (click)="removeForm(j)">Remove </button>
                                </div>
                            </div>
                            </td>
                        </tr> -->
                    </div>
                </ng-container>
            </ng-container>
        <br>
    
            <div class="row justify-content-center">
                <div class="col-sm-2 form-group">
                    <button class="btn btn-primary ml-4" (click)="addForm()">Add Row</button>
                </div>

            </div>
        </div>
        <div class="card card-body col-lg-12 mt-3 colorborder">
            <div class="row">
            <div class="col-lg-3 form-group">
                <label>Does system audit report comply to circular no. SEBI/HO/MIRSD/DOS2/CIR/P/2019/10 dated January 4,
                    2019 of SEBI<span class="red">*</span></label>
            </div>
            <div class="col-lg-3 form-group">
                <!-- <input type="radio" formControlName="" class="form-control"> -->
                <input type="radio" value="Y" formControlName="mlaisystemAuditReport"> Yes
                <input type="radio" value="N"  formControlName="mlaisystemAuditReport"> No
                <input type="radio" value="NA"  formControlName="mlaisystemAuditReport"> NA

            </div>
            <div class="col-lg-3 form-group">
                <label>Is there any adverse comment in the System audit report<span class="red">*</span></label>
            </div>
            <div class="col-lg-3 form-group">
                <!-- <input type="radio" formControlName="" class="form-control"> -->
                <input type="radio" value="Y" formControlName="mlaisystemAdverse"> Yes
                <input type="radio" value="N"  formControlName="mlaisystemAdverse"> No
                <input type="radio" value="NA"  formControlName="mlaisystemAdverse"> NA

            </div>
        </div>
            <div class="row justify-content-start mt-2">
                <div class="col-lg-4">
                    <div class="row justify-content-start">
                        <div class="col-lg-4">
                            <button class="btn  btn-primary full-width" (click)="nilSubmission()">NIL Submission </button>
                        </div>
                        <div class="col-lg-4">
                            <button class="btn  btn-cancel full-width" (click)="reset()">Reset </button>
                        </div>
                        <div class="col-lg-4">
                            <button class="btn  btn-primary full-width" [disabled]="requestForm.invalid"
                                (click)="submit()">Submit </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>
</div>

<div class="modal fade" id="eligibility" tabindex="-1" role="dialog" aria-labelledby="eligibility" routerLink="/"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close" routerLink="/">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">ML & AI</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>Member not eligible for this quarter</h5>
                        </div>
                    </div>
                </div>
           
        </div>
    </div>
</div>


<div class="modal fade" id="cutOffdate" tabindex="-1" role="dialog" aria-labelledby="cutOffdate"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">ML & AI</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>Cut-Off date for the quarter has passed.</h5>
                        </div>
                    </div>
                </div>
           
        </div>
    </div>
</div>
 <!-- use when data is already submitted -->
<div class="modal fade" id="submitted" tabindex="-1" role="dialog" aria-labelledby="submitted" 
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h4 class="modal-title">ML & AI</h4>
              </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>You have already submitted data for this quarter</h5>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>