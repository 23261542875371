<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex">
                <h1>{{ctclEntityType !=='ADMIN'?'CTCL ID Member Report':'CTCL ID Admin Report'}}</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a routerLink="">Enit</a></li>
                    <li class="active">
                        <ng-container *ngIf="ctclEntityType!=='ADMIN'; else adminLinkCreation">
                            <a routerLink="/eTrade/eMemberReportingPreTrade/displayCtclIdReport"
                                aria-current="page">CTCL ID Member Report</a>
                        </ng-container>

                        <ng-template #adminLinkCreation>
                            <a routerLink="/tradeAdmin/eMemberReportingTradeA/displayCtclIdReport"
                                aria-current="page">CTCL ID Admin Report</a>
                        </ng-template>
                    </li>

                </ul>
            </div>

        </div>
    </div>
    <form [formGroup]="ctclReportRequest">
        <div class="container-fluid mt-3 mb-3 theme">
            <div class="card">
                <div class="card-body">
                    <div class="row">

                        <ng-container *ngIf="ctclEntityType !=='ADMIN'; else memlistAndCodeForAdmin">
                            <div class="col-lg-3 form-group">
                                <label>Member Name<span class="red">*</span> </label>
                                <input type="text" value="" class="form-control" formControlName="ctclMemberName"
                                    readonly="ctclRequestDisabledFields.ctclMemberNameDisable" name="ctcl-memberName-nosearch">
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Member Code<span class="red">*</span> </label>
                                <input type="text" value="" class="form-control" formControlName="ctclMemberCode"
                                    readonly="ctclRequestDisabledFields.ctclMemberCodeDisable" name="ctcl-memberCode-nosearch">

                            </div>

                        </ng-container>

                        <ng-template #memlistAndCodeForAdmin>
                            <div class="col-lg-3 form-group">
                                <label>Member Name<span class="red">*</span></label>
                                <select class="form-control terminalOfficeSelectpicker" formControlName="ctclMemberName"
                                    data-style="btn-info" (change)="memNameSelection()" data-live-search="true" name="ctcl-memberName-nosearch">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let memberName of memberNameList" value="{{memberName.spName}}">
                                        {{memberName.spName}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label>Member Code<span class="red">*</span></label>
                                <select class="form-control terminalOfficeSelectpicker" formControlName="ctclMemberCode"
                                    data-style="btn-info" (change)="memCodeSelection()" data-live-search="true" name="ctcl-memberCode-nosearch">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let memberCode of memberNameList" value="{{memberCode.symbol}}">
                                        {{memberCode.symbol}}
                                    </option>
                                </select>
                            </div>
                        </ng-template>


                        <div class="col-lg-3 form-group">
                            <label>CTCL Terminal Id<span class="red">*</span> </label>
                            <input type="text" value="" class="form-control" formControlName="ctclRqdtlsTerminalId" name="ctcl-terminal-id-nonsearch">
                        </div>

                        <div class="col-lg-3 form-group">
                            <label>Request Type</label>
                            <select class="form-control requestTypePicker" formControlName="ctclRequestType"
                                data-style="btn-info" title="Select request Type" data-live-search="true">
                                <option value="">Select</option>
                                <option *ngFor="let ctclReqDetails of ctclRequestTypeList"
                                    value="{{ctclReqDetails.id}}">
                                    {{ctclReqDetails.name}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="row">
                        

                        <div class="col-lg-3 form-group">
                            <label>From date</label>
                            <input type="date" value="" class="form-control" formControlName="ctclFromDate">
                            <!-- <div class="input-group pointer">
                                <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue"
                                    value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="ctclFromDate" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="" >
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar"  [(bsValue)]="bsValue" bsDatepicker
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-lg-3 form-group">
                            <label>To Date</label>
                            <input type="date" value="" class="form-control" formControlName="ctclToDate">
                            <!-- <div class="input-group pointer">
                                <input type="text" class="form-control datepicker"  [(bsValue)]="bsValue1"
                                    value="{{bsValue | date:'MM/dd/yyyy'}}" formControlName="ctclToDate" bsDatepicker
                                    [bsConfig]="{ isAnimated: true}" placeholder="" >
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar"  [(bsValue)]="bsValue" bsDatepicker 
                                            [bsConfig]="{ isAnimated: true }"></i>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="row" [style]="{'margin-bottom':'20px'}">
                    </div>

                    <div class="row">
                        <div class="col-lg-3">
                            <div class="row">
                                <div class="col-lg-6">
                                    <button type="submit" class="btn btn-primary full-width"
                                        (click)="getctclReportBasedOnFilter()">Search</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="button" class="btn btn-secondary full-width"
                                        (click)="getctclReportBasedOnReset()">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>



    <div class="container-fluid mt-3 mb-3 theme">
        <div class="card mt-3">
            <div class="card-body">

                <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                    <p style="color: white">Please wait.</p>
                </ngx-spinner>

                <div class="">
                    <div class="row clearSearch">
                        <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor"
                                class="bi bi-x" viewBox="0 0 15 15">
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg> Filter</button>
                    </div>
                    <table id="ctclRequestDataGrid"
                        class="table common-table table-bordered table-striped table-hover displayTable nowrap" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th class="pre-wrap">SR No.</th>
                                <th class="pre-wrap">Request Id</th>
                                <th class="pre-wrap">Member Code</th>
                                <th class="pre-wrap">Member Name</th>
                                <th class="pre-wrap">Submission Date</th>
                                <th class="pre-wrap">Request Type</th>
                                <th class="pre-wrap">Status</th>
                                <th class="pre-wrap">Neat User Id</th>
                                <th class="pre-wrap">CTCL Terminal Id</th>
                                <th class="pre-wrap">Market Segment</th>
                                <th class="pre-wrap">CTCL Login Id</th>
                                <th class="pre-wrap">Purpose</th>
                                <th class="pre-wrap">Allotment Date and Time</th>
                                <th class="pre-wrap">Office Status</th>
                                <th class="pre-wrap">Office Address</th>
                                <th class="pre-wrap">City</th>
                                <th class="pre-wrap">Pin Code</th>
                                <th class="pre-wrap">State</th>
                                <th class="pre-wrap">Phone No</th>
                                <th class="pre-wrap">Connection Mode</th>
                                <th class="pre-wrap">Exam Registration No</th>
                                <th class="pre-wrap">Person Name</th>
                                <th class="pre-wrap">Father Name</th>
                                <th class="pre-wrap">Date Of Birth</th>
                                <th class="pre-wrap">Validity Date</th>
                                <th class="pre-wrap">MAPIN No</th>
                                <th class="pre-wrap">PAN No</th>
                                <th class="pre-wrap">Relationship with Member</th>
                                <th class="pre-wrap">SubBroker Reg. No.</th>
                                <th class="pre-wrap">Sub Broker Name</th>
                                <th class="pre-wrap">Authorized Person Name</th>
                                <th class="pre-wrap">Data Type</th>
                            </tr>

                            <tr class="column-search">
                                <th></th>
                                <th><input type="text" class="form-control form-control-sm"/></th>
                                <th><input type="text" class="form-control form-control-sm"/></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                                <th><input type="text" class="form-control form-control-sm" /></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let ctclReport of ctclGridList; let srNo=index">
                                <td>{{srNo+1}}</td>
                                <td><button class="btn btn-link full-width" type="button" data-toggle="modal"
                                        data-target="#ctclRequestTypePopUp"
                                        (click)="setRequestType(ctclReport.ctclReqRefNo)">{{ctclReport.ctclReqRefNo}}</button>
                                </td>
                                <td>{{ctclReport.memCode}}</td>
                                <td>{{ctclReport.memName}}</td>
                                <td>{{ctclReport.submDateStr}}</td>
                                <td>{{ctclReport.reqTypeStr}}</td>
                                <td>{{ctclReport.ctclReqStatus}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsNeatUserId}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsTerminalId}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsMarketSegment}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLoginId}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsPurposeCtclId}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsAllotDate}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsOfficeStatus}}</td>
                                <td>{{(ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.adFlatNo==='Null'?'
                                    ':ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.adFlatNo) +'
                                    '+ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.adBuildingName + '
                                    '+(ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.adFloor ===null?"":ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.adFloor)}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.cityName}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.adPincode}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.addressDtls?.stateName}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.ctclAddPhoneNo}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsLocationId.ctclLocdtlsConnMode}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsExamId.ctclExamDtlsExamId}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsExamId.personFullName}}</td>
                                <td>{{ctclReport.ctclRequestDetails.fatherFullName}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsExamId?.ctclExamDtlsDob}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsExamId?.ctclExamDtlsValidDt}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsMapin}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsPan}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsRelationship}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsSebiRegNo}}
                                </td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclSubAuthDtlsName}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsSubBrokerId.ctclAuthPerName}}</td>
                                <td>{{ctclReport.ctclRequestDetails.ctclRqdtlsDataType}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- <div data-keyboard=false data-backdrop=false class="modal fade" id="ctclRequestTypePopUp" tabindex="-1" role="dialog"
    aria-labelledby="ctclRequestTypePopUpLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="ctclRequestTypeSelected===1">
                    <ctcl-id-activation [displayFromReport]="displayFromReport"
                        [memberOrAdminReport]="memberOrAdminReport"
                        [ctclRequestIdReport]="ctclRequestIdReport"></ctcl-id-activation>
                </ng-container>

                <ng-container *ngIf="ctclRequestTypeSelected===2">
                    <ctcl-id-deactivation [displayFromReport]="displayFromReport"
                        [memberOrAdminReport]="memberOrAdminReport"
                        [ctclRequestIdReport]="ctclRequestIdReport"></ctcl-id-deactivation>
                </ng-container>

                <ng-container *ngIf="ctclRequestTypeSelected===3">
                    <ctcl-id-modification></ctcl-id-modification>
                </ng-container>

            </div>
        </div>
    </div>
</div> -->