

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, ReplaySubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { EnitService } from 'src/app/service/enit.service';
import { config } from 'src/app/valueObject/config';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { DatePipe } from '@angular/common'

declare var $: any;
@Component({
  selector: 'app-etm-neat-user-name-change-request',
  templateUrl: './etm-neat-user-name-change-request.component.html',
  styleUrls: ['./etm-neat-user-name-change-request.component.css']
})
export class EtmNeatUserNameChangeRequestComponent implements OnInit {
  neatUserNameChange: FormGroup;
  submitted = false;
  message: string;
  user: any = {};
  cusDlrId: any = [];
  config: any = config;
  memCode: String;
  menucode = 'displayDisableNeatIdRequestPage';
  getDisplayNewNeatIdRequest: any;
  enitId: number = 0;
  access: any = {};

  date: Date = new Date();
  formDate: any;
  bsValue: any;
  bsValue1: any;
  maxDate: any;
  entityType: any;
  memName: any;
  memId: any;
  module: any = "New Neat User ID";
  reqtype: number = 3;
  loginId: string;
  verifyOTP: boolean;
  titleList: any = [];
  qualificationList: any = [];
  cityList: any = [];
  cusTapIpList: any = [];
  refcertilist: any = [];
  relationshipList: any = [];
  refOffcList: any = [];
  base64Output: string;
  base64Output1: string;
  registrationNumber: string;
  dobString: any;
  validityDateString: any;
  registrationNumber1: string;
  registrationNumber2: string;
  space: any;
  fullname: any;
  tapIDError: boolean = false;
  fileError: boolean = false;
  fileError1: boolean = false;
  maxDateToday: any;   //set max date as of today 
  maxMonthToday: any;  // set max month 
  maxMonth: any; //set max month according to JS

  //for validity date
  validityDate: any;
  validityMonth: any;
  minDateValidity: any;

  constructor(private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private EnitService: EnitService,
    private router: Router,
    private titleService: Title,
    private enitTradeService: EnitTradeService,
    private cdref: ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe,) { }

  newItemEvent(event: boolean) {
    this.verifyOTP = event;
    console.log(this.verifyOTP);
    if (this.verifyOTP == true) {
      $('#cashdeposite').modal('hide');
    }
  }

  ngOnInit(): void {
    this.formDate = this.datepipe.transform(this.date, 'dd-MMM-yyyy');
    this.maxDateToday = this.datepipe.transform(this.date, 'dd');  // gives date
    this.maxMonthToday = this.datepipe.transform(this.date, 'MM'); // gives month but acc to normal calendar
    this.maxMonth = this.maxMonthToday - 1; // gives month acc to JS 

    const currentYear=new Date().getFullYear();
    this.maxDate=new Date(currentYear-18, this.maxMonth , this.maxDateToday);
    this.validityDate = this.maxDateToday -1;
    if(this.maxMonthToday == 12){
      this.validityMonth = 0;
      this.minDateValidity = new Date(currentYear+1, this.validityMonth , this.validityDate);
    }
    else if (this.maxMonthToday != 12){
      this.validityMonth = this.maxMonthToday;
      this.minDateValidity = new Date(currentYear, this.validityMonth , this.validityDate);
    }

    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    $('.selectpicker').selectpicker();
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.memId = (<number>this.user.entityCode - 10000) + "";
    this.titleService.setTitle('Neat User Name Change');
    this.loginId = this.user.loginId;

   

    this.neatUserNameChange = this.formbuilder.group({
      SebiRegiCode: ['', Validators.required],
      cusDlrId: [''],
      UserName: [''],
      ExistingTapIp: [''],
      TapId: [''],
      // certificationType:  ['', Validators.required],
      certificationType: [''],
      registraionNoType: [''],
      registraionNo: [],
      ValidateDateType: [''],
      ExamType: [''],
      TitleType: [null, Validators.required],
      firstNameType: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z ]*$')]],
      middleNameType: [null, [
        // Validators.required,
        //  Validators.minLength(3),
        Validators.maxLength(70),
        //Validators.pattern('^[a-zA-Z ]*$')
      ]],
      lastNameType: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(70),
        Validators.pattern('^[a-zA-Z ]*$')]],
      FatherNameType: [null, 
        //[ Validators.required,
        // Validators.minLength(3),
        // Validators.maxLength(70),
        // Validators.pattern('^[a-zA-Z ]*$')]
      ],
      BODType: [null, 
        // Validators.required
      ],
      PanType: [null, [
        // Validators.required, 
        Validators.pattern('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$')]],
      MobileNoType: [null, 
      // [Validators.required,
      // Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      // Validators.minLength(10), Validators.maxLength(10)]
    ],
      QualificationType: [null, Validators.required],
      OfficeStatusType: [null, Validators.required],
      EmailType: [null, [
        // Validators.required,
         Validators.email]],
      // PercentageType: [null, [
      //   Validators.required, 
      //   Validators.max(100)]],
      RelationshipOfUserType: ['', Validators.required],
      DateType: [this.formDate, Validators.required],
      PlaceType: ['', Validators.required],
      CheckType: [false, Validators.pattern('true')],
      CheckType1: [false, Validators.pattern('true')],
      declarationAndPan: ['', Validators.required],
      certificate: [''],


      nismRegNo4: [],
      nismRegNo5: [],
      nismRegNo6: [],
      nismRegNo7: [],
      nismRegNo8: [],
      nismRegNo9: [],
      certificationType1: [],

       // new fields as per req
       userRemarks: [''],
       reason:['', Validators.required]
    })
    this.getDealerIdDetails(this.memCode, this.memId);
    this.getSebiSebiRegistrationCode(this.memId);
    this.EnitService.getUserByMemCode1(this.enitId);
    this.dropDownData();
  }
  get f() { return this.neatUserNameChange.controls; }


  getDealerIdDetails(memCode: String, memId: String) {

    this.EnitService.getDealerIdDetails(memCode, memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.cusDlrId = response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
    })

  }

  openFileFormat() {
    let link = document.createElement("a");
    link.download = "DeclarationByApprovedUser";
    link.href = "assets/DeclarationByApprovedUser.doc";
    link.click();
  }
  changeCertiDetails() {
    if (this.f.certificationType.value == 'NISM') {
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();

      this.f.registraionNoType.setValidators([Validators.required, Validators.pattern('^[0-9]{12}$')]);
      this.f.registraionNoType.updateValueAndValidity();

      this.f.ExamType.setValidators(Validators.required);
      this.f.ExamType.updateValueAndValidity();

      this.f.nismRegNo4.setValue(null);
      this.f.nismRegNo5.setValue(null);
      this.f.nismRegNo6.setValue(null);
      this.f.nismRegNo7.setValue(null);
      this.f.nismRegNo8.setValue(null);
      this.f.nismRegNo9.setValue(null);


      this.f.nismRegNo4.clearValidators();
      this.f.nismRegNo4.updateValueAndValidity();

      this.f.nismRegNo5.clearValidators();
      this.f.nismRegNo5.updateValueAndValidity();

      this.f.nismRegNo6.clearValidators();
      this.f.nismRegNo6.updateValueAndValidity();

      this.f.nismRegNo7.clearValidators();
      this.f.nismRegNo7.updateValueAndValidity();

      this.f.nismRegNo8.clearValidators();
      this.f.nismRegNo8.updateValueAndValidity();

      this.f.nismRegNo9.clearValidators();
      this.f.nismRegNo9.updateValueAndValidity();

      // this.f.registraionNoType.clearValidators();
      // this.f.registraionNoType.updateValueAndValidity();

      this.f.ValidateDateType.setValidators(Validators.required);
      this.f.ValidateDateType.updateValueAndValidity()

      this.f.certificate.setValidators(Validators.required);
      this.f.certificate.updateValueAndValidity();
    }
    else if (this.f.certificationType.value == 'NISM-CPE') {
      this.cdref.detectChanges();
      $('.selectpicker').selectpicker();

      this.f.certificationType1.setValidators(Validators.required);
      this.f.certificationType1.updateValueAndValidity();

      this.f.ExamType.clearValidators();
      this.f.ExamType.updateValueAndValidity();

      this.f.certificate.setValidators(Validators.required);
      this.f.certificate.updateValueAndValidity();

      this.f.ValidateDateType.setValidators(Validators.required);
      this.f.ValidateDateType.updateValueAndValidity();

      if (this.f.certificationType1.value == 'NISM' || this.f.certificationType1.value == 'MCXSX') {
        this.f.registraionNoType.setValidators([Validators.required,Validators.pattern('^[0-9]{12}$')]);
        this.f.registraionNoType.updateValueAndValidity();

        this.f.nismRegNo4.clearValidators();
        this.f.nismRegNo4.updateValueAndValidity();

        this.f.nismRegNo5.clearValidators();
        this.f.nismRegNo5.updateValueAndValidity();

        this.f.nismRegNo6.clearValidators();
        this.f.nismRegNo6.updateValueAndValidity();

        this.f.nismRegNo7.clearValidators();
        this.f.nismRegNo7.updateValueAndValidity();

        this.f.nismRegNo8.clearValidators();
        this.f.nismRegNo8.updateValueAndValidity();

        this.f.nismRegNo9.clearValidators();
        this.f.nismRegNo9.updateValueAndValidity();

      }
      else if (this.f.certificationType1.value == 'NISM-CPE') {



        // this.f.nismRegNo4.setValidators([Validators.required, Validators.pattern("^[A-Za-z]{5}$")])
        this.f.nismRegNo4.setValidators([Validators.required])
        this.f.nismRegNo4.updateValueAndValidity();
        // this.f.nismRegNo5.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9]{10}$")])
        this.f.nismRegNo5.setValidators([Validators.required])
        this.f.nismRegNo5.updateValueAndValidity();
        // this.f.nismRegNo6.setValidators([Validators.required, Validators.pattern("^[0-9]{8}$")])
        this.f.nismRegNo6.setValidators([Validators.required])
        this.f.nismRegNo6.updateValueAndValidity();
        // this.f.nismRegNo7.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9]{20}$")])
        this.f.nismRegNo7.setValidators([Validators.required])
        this.f.nismRegNo7.updateValueAndValidity();
        // this.f.nismRegNo8.setValidators([Validators.required, Validators.pattern("^[A-Za-z]{20}$")])
        this.f.nismRegNo8.setValidators([Validators.required])
        this.f.nismRegNo8.updateValueAndValidity();
        // this.f.nismRegNo9.setValidators([Validators.required, Validators.pattern("^[0-9]{10}$")])
        this.f.nismRegNo9.setValidators([Validators.required])
        this.f.nismRegNo9.updateValueAndValidity();
        this.f.registraionNoType.setValue(null);
        this.f.registraionNoType.clearValidators();
        this.f.registraionNoType.updateValueAndValidity();
      }
    }
  }

  getRelationshipList() {
    this.enitTradeService.getRelationshipList(this.f.OfficeStatusType.value).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          this.relationshipList = []
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.relationshipList = response.data;
        this.cdref.detectChanges();
        $('.selectpicker').selectpicker();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }


  dropDownData() {
    let requestObject = {
      "memId": this.memId,
      "memCode": this.memCode,
    };

    this.enitTradeService.displayqualificationlist(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.qualificationList = response.data.refQualificationList;
        this.titleList = response.data.titleListProp;
        this.refOffcList = response.data.refOffcStatusList;
        this.refcertilist = response.data.refCertificateList;
        this.cityList = response.data.placesList;

        this.cdref.detectChanges();
        $('.selectpicker').selectpicker('refresh');
      }
      else {
        this.toastr.error(response.message);
      }
    })
  }
  getSebiSebiRegistrationCode(memId: String) {
    this.EnitService.getUserByMemCode2(memId).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.f.SebiRegiCode.setValue(response.data.memSebiRegCode);
      }
    })
  }
  selectDlrId() {
    this.EnitService.getSingleDealerIdDetails(this.memCode, this.memId, this.f.cusDlrId.value).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.f.UserName.setValue(response.data.cusDlrNm);
        this.f.ExistingTapIp.setValue(response.data.cusDlrTapIp);
        this.f.TapId.setValue(response.data.cusDlrTidTapId);

        if(response.data.cusDlrTidTapId == null){
          this.f.TapId.setValue(response.data.cusTidTapId);
        }
        else{
          this.f.TapId.setValue(response.data.cusDlrTidTapId);
        }
        //if(response.data.cusTidTapId!=null){
        //  this.f.TapId.setValue(response.data.cusDlrTidTapId);
        //  this.tapIDError = false;
        //}
        // else if(response.data.cusTidTapId==null){
        //   this.toastr.warning("TAP ID for dealer ID " +this.f.cusDlrId.value + " not found")
        //   this.f.TapId.setValue(response.data.cusTidTapId);
        //   this.tapIDError = true;
        // } removed this because 2 fields might have TAP ID value
      }
      //cusTidTapId ===> cusDlrTidTapId changed it as per new requirement
      else {
        this.toastr.error(response.message);
      }

    })
  }
  reset() {

    this.f.certificationType.setValue(null);
    this.f.cusDlrId.setValue(null);
    this.f.ExamType.setValue(null);
    this.f.certificationType1.setValue(null);
    this.f.TitleType.setValue(null);
    this.f.QualificationType.setValue(null);
    this.f.OfficeStatusType.setValue(null);
    this.f.RelationshipOfUserType.setValue(null);
    this.f.PlaceType.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    
    this.f.registraionNoType.setValue(null)
    this.f.ValidateDateType.setValue(null)
    this.f.nismRegNo4.setValue(null)
    this.f.nismRegNo5.setValue(null)
    this.f.nismRegNo6.setValue(null)
    this.f.nismRegNo7.setValue(null)
    this.f.nismRegNo8.setValue(null)
    this.f.nismRegNo9.setValue(null)
    this.f.firstNameType.setValue(null)
    this.f.middleNameType.setValue(null)
    this.f.lastNameType.setValue(null)
    this.f.FatherNameType.setValue(null)
    this.f.BODType.setValue(null)
    this.f.PanType.setValue(null)
    this.f.MobileNoType.setValue(null)
    // this.f.PercentageType.setValue(null)
    this.f.EmailType.setValue(null)
    this.f.declarationAndPan.setValue('')
    this.f.certificate.setValue('')
    this.f.CheckType.setValue(false);
    this.f.CheckType1.setValue(false);
    this.fileError = false;
    this.fileError1 = false;
  
    this.submitted = false;
    this.verifyOTP = false;

    this.f.UserName.setValue('');
    this.f.ExistingTapIp.setValue('');
    this.f.TapId.setValue('');

    this.neatUserNameChange.markAsPristine();
    this.neatUserNameChange.markAsUntouched();
  }

  onFileSelected(event) {
    if(event.target.files && event.target.files[0]){
      let file = event.target.files[0];
      if(file.type == 'application/pdf'){
        // console.log("CORRECT TYPE");
        this.fileError = false;
      }
      else{
        // console.log("INCORRECT TYPE");
        this.fileError = true;
      }
    }
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;
    });
  }

  onFileSelected1(event) {
    if(event.target.files && event.target.files[0]){
      let file = event.target.files[0];
      if(file.type == 'application/pdf'){
        // console.log("CORRECT TYPE");
        this.fileError1 = false;
      }
      else{
        // console.log("INCORRECT TYPE");
        this.fileError1 = true;
      }
    }
    this.convertFile1(event.target.files[0]).subscribe(base641 => {
      this.base64Output1 = base641;
    });
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;


  }
  convertFile1(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  onSubmit() {

    if (this.f.certificationType.value == 'NISM') {
      this.registrationNumber = this.f.registraionNoType.value
    }
    else if (this.f.certificationType.value == 'NISM-CPE') {

      if (this.f.certificationType1.value == 'NISM') {
        this.registrationNumber = this.f.registraionNoType.value
      }
      else if (this.f.certificationType1.value == 'MCXSX') {
        this.registrationNumber1 = this.f.registraionNoType.value
      }
      else if (this.f.certificationType1.value == 'NISM-CPE') {
        this.registrationNumber2 = (this.f.nismRegNo4.value).concat(this.f.nismRegNo5.value).concat(this.f.nismRegNo6.value).concat(this.f.nismRegNo7.value).concat(this.f.nismRegNo8.value).concat(this.f.nismRegNo9.value)
      }
    }
    this.dobString = this.datepipe.transform(this.f.BODType.value, 'dd-MMM-yyyy')
    this.validityDateString = this.datepipe.transform(this.f.ValidateDateType.value, 'dd-MMM-yyyy');

    this.space=" ";
    this.fullname=this.f.firstNameType.value.concat(  this.space).concat(  this.f.middleNameType.value).concat(  this.space).concat(  this.f.lastNameType.value);
    
    
    let requestObject = {        
    "memId": this.memId,
    "memCode": this.memCode,
    "selectedTapIp":this.f.ExistingTapIp.value,
    "dlrTidId": this.f.TapId.value,
    "noOfExistingTerminals": "",
    "cusCertificateType": this.f.certificationType.value,
    "cusExamTypeNCFM": "",
    "cusExamTypeNISM": this.f.ExamType.value|| null,
     //"cusCertificateType": this.f.ExamType.value,
     // "cusExamTypeNCFM": this.f.certificationType.value,
    "cusExamTypeOther": this.f.certificationType1.value || null,
    // "cusCertificateType":this.f.certificationType.value,
    // "cusExamTypeNISM ":this.f.ExamType.value || null, 
    "cusExamNISMRegNo": this.registrationNumber || null,
    "cusExamMCXSXRegNo":this.registrationNumber1 || null,
    "cusExamMCXMCCPRegNo":this.registrationNumber2 || null,
    "cusExamNCDEXRegNo":"",
    "cusExamCmpltNo": this.registrationNumber|| this.registrationNumber1 || this.registrationNumber2,
    "cusTitle": this.f.TitleType.value,
    "cusDlrUsrId":this.f.cusDlrId.value,
    "cusFirstName": this.f.firstNameType.value,
    "cusMiddleName": this.f.middleNameType.value,
    "cusLastName": this.f.lastNameType.value,
    "cusDOB": this.dobString || '',
    "cusCertValidDtStr":this.validityDateString ,
    // "cusExamPercentage":this.f.PercentageType.value || null,
    "cusFatherHusbandName": this.f.FatherNameType.value || null,
    "cusPanNo": this.f.PanType.value?.toUpperCase() || null,
    "cusContactNo":this.f.MobileNoType.value || null ,
    "cusEmailId": this.f.EmailType.value || "",
    "cusSelectedQualification": this.f.QualificationType.value,
    "selectedOffStatus": "",
    "cusSelectedRelationship": this.f.RelationshipOfUserType.value,
    "cusDeclDateStr": this.f.DateType.value,
    "selectedPlaceId":this.f.PlaceType.value,
    "fileUploadPanBase64": this.base64Output,
    "fileUploadPanFileName": "",
    "fileUploadCertArrFileName": this.base64Output1,
    "fileUploadPanArr": "",
    "fileUploadCertArr": "",
    "CusDlrNm":this.fullname,

    //adding new fields
    "rationale":this.f.userRemarks.value || null,
    "cusReason":this.f.reason.value,

    };


    this.EnitService.saveNeatUserNameChange(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.submitted = false;
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        let refNo = response.data;
        this.submitted = true;
        this.reset();
        this.message = 'Requested Successfully With Reference Number: ' + refNo;
        this.toastr.success(this.message);

      } else {
        this.toastr.error(response.message)
        this.submitted = false;
      }
    })
  }
}
