
<div class="mt-3 ">
       <div class="row justify-content-center w-100">
           <div class="col-lg-12">
            <h6 class="nocase">{{message}}</h6>
            <p class="nocase">you must change your password and login again !</p>
           </div>
    </div>
    <div class="row justify-content-center mb-5">
        <div class="col-lg-6">
            <button class="btn btn-primary full-width" type="submit" (click)="changePassword()">Change Password</button>
        </div>

        <div class="col-lg-5">
            <button class="btn btn-cancel full-width" type="button" (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>
