<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Access Management</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li> <a routerLink="/admin/useraccess" skipLocationChange aria-current="page">Access Management</a>
                    </li>
                    <li class="active"> <a routerLink="/admin/useraccess/accessmapping" skipLocationChange
                            aria-current="page">Access
                            Mapping</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="bg-white card card-body colorborder">
        <div class="row pt-2 pb-2">
            <div class="col-lg-6">
                <h2>Login Id : {{user && user.userLoginId}} </h2>
            </div>
            <div class="col-lg-6 pr-4 text-right">
                <button data-backdrop="static" data-keyboard="false" class="btn btn-primary" data-toggle="modal"
                    data-target="#accessDetails" [disabled]="disableManage">Manage </button>
            </div>
        </div>
        <form [formGroup]="userAccessForm" (ngSubmit)="onSubmit()">
            <table class=" table common-table access-table ">
                <thead>
                    <tr>
                        <th class="pre-wrap multiSelect">
                            <input type="checkbox" [checked]="false" formControlName="checked" (change)="onSelectAll()">
                        </th>
                        <th class="pre-wrap">Menu</th>
                        <!-- <th class="pre-wrap">Role </th> -->
                        <th class="pre-wrap">Is access allowed </th>
                        <th class="pre-wrap">Access Type </th>
                        <!-- <th class="pre-wrap">Status </th> -->
                        <!-- <th class="pre-wrap" *ngIf="action!='view'">Verify
                            <input class="multiSelect" type="checkbox" [checked]="false" formControlName="verify"
                                (change)="onVerifyAll()">
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="accessMapping" formArrayName="accessMapping">
                        <ng-container *ngFor="let menuAccess of accessMapping.controls;let i=index" [formGroupName]="i">
                            <tr>
                                <td class="multiSelect">
                                    <input type="checkbox" [checked]="false" formControlName="checked"
                                        (change)="onSelectMenu(menuAccess)">
                                </td>
                                <td class="title"><strong>{{menuAccess.value.menuName}}</strong> </td>
                                <!-- <td><select class="form-control-sm selectpicker bg-white" formControlName="roleId"
                                        data-style="btn-info"
                                        *ngIf="menuAccess.value.menuCode=='admin' || menuAccess.value.menuCode=='bank'">
                                        <option value="1">Maker</option>
                                        <option value="2">Checker</option>
                                    </select>
                                    <select class="form-control-sm selectpicker bg-white" data-style="btn-info"
                                        formControlName="roleId"
                                        *ngIf="menuAccess.value.menuCode!='admin' && menuAccess.value.menuCode!='bank'">
                                        <option value="1" selected>NA</option>
                                    </select>
                                </td> -->
                                <td><select class="form-control-sm selectpicker bg-white"
                                        formControlName="isAccessAllowed" data-style="btn-info">
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select></td>
                                <td>
                                    <select class="form-control-sm selectpicker bg-white" formControlName="accessType"
                                        data-style="btn-info"
                                        *ngIf="menuAccess.value.menuCode!='common' && menuAccess.value.menuCode!='enit'">
                                        <option value="R">Read-only</option>
                                        <option value="W">Read-Write</option>
                                    </select>
                                    <select class="form-control-sm selectpicker bg-white" formControlName="accessType"
                                        data-style="btn-info" *ngIf="menuAccess.value.menuCode=='common'">
                                        <option value="R" selected>Read-only</option>
                                    </select>
                                    <select class="form-control-sm selectpicker bg-white" formControlName="accessType"
                                        data-style="btn-info" *ngIf="menuAccess.value.menuCode=='enit'">
                                        <option value="W" selected>Read-Write</option>
                                    </select>
                                </td>
                                <!-- <td><span
                                        [ngClass]="{'green':menuAccess.value.isActive=='Y','red':menuAccess.value.isActive=='N'}"
                                        class="mfont">{{menuAccess.value.isActive=='Y'?'Active':'Deactive'}}</span>
                                </td> -->
                                <!-- <td *ngIf="action!='view'">
                                    <svg *ngIf="!menuAccess.value.checkedBy"
                                        (click)="checkRecordMenu(menuAccess,'approve')"
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-check-square" viewBox="0 0 16 16">
                                        <path
                                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                        <path
                                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                    </svg>
                                    <svg *ngIf="menuAccess.value.checkedBy"
                                        (click)="checkRecordMenu(menuAccess,'reject')"
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="fillColor bi bi-check-square-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                    </svg>
                                </td> -->
                            </tr>
                            <ng-container formArrayName="userMenusImpls">
                                <ng-container
                                    *ngFor="let subMenuAccess of menuAccess.get('userMenusImpls').controls; let j=index"
                                    [formGroupName]="j">
                                    <tr>
                                        <td width="90px" class="multiSelect">
                                            <input type="checkbox" [checked]="false" formControlName="checked"
                                                (change)="onSelectSubMenu(menuAccess,subMenuAccess)">
                                        </td>
                                        <td>&nbsp;&nbsp;<strong>{{subMenuAccess.value.menuName}}</strong></td>
                                        <!-- <td><select class="form-control-sm selectpicker bg-white"
                                                formControlName="roleId" data-style="btn-info"
                                                *ngIf="menuAccess.value.menuCode=='admin' || menuAccess.value.menuCode=='bank'">
                                                <option value="1">Maker</option>
                                                <option value="2">Checker</option>
                                            </select>
                                            <select class="form-control-sm selectpicker bg-white"
                                                formControlName="roleId" data-style="btn-info"
                                                *ngIf="menuAccess.value.menuCode!='admin' && menuAccess.value.menuCode!='bank'">
                                                <option value="1" selected>NA</option>
                                            </select>
                                        </td> -->
                                        <td><select class="form-control-sm selectpicker bg-white"
                                                formControlName="isAccessAllowed" data-style="btn-info">
                                                <option value="Y">Yes</option>
                                                <option value="N">No</option>
                                            </select></td>
                                        <td>
                                            <select class="form-control-sm selectpicker bg-white"
                                                formControlName="accessType" data-style="btn-info"
                                                *ngIf="subMenuAccess.value.menuCode!='common' && subMenuAccess.value.menuCode!='enit'">
                                                <option value="R">Read-only</option>
                                                <option value="W">Read-Write</option>
                                            </select>
                                            <select class="form-control-sm selectpicker bg-white"
                                                formControlName="accessType" data-style="btn-info"
                                                *ngIf="subMenuAccess.value.menuCode=='common'">
                                                <option value="R" selected>Read-only</option>
                                            </select>
                                            <select class="form-control-sm selectpicker bg-white"
                                                formControlName="accessType" data-style="btn-info"
                                                *ngIf="subMenuAccess.value.menuCode=='enit'">
                                                <option value="W" selected>Read-Write</option>
                                            </select>
                                        </td>
                                        <!-- <td><span
                                                [ngClass]="{'green':subMenuAccess.value.isActive=='Y','red':subMenuAccess.value.isActive=='N'}"
                                                class="mfont">{{subMenuAccess.value.isActive=='Y'?'Active':'Deactive'}}</span>
                                        </td> -->
                                        <!-- <td *ngIf="action!='view'">
                                            <svg *ngIf="!subMenuAccess.value.checkedBy"
                                                (click)="checkRecordSubMenu(menuAccess,subMenuAccess,'approve')"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                <path
                                                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                <path
                                                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                            </svg>
                                            <svg *ngIf="subMenuAccess.value.checkedBy"
                                                (click)="checkRecordSubMenu(menuAccess,subMenuAccess,'reject')"
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="fillColor bi bi-check-square-fill"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                            </svg>
                                        </td> -->
                                    </tr>
                                    <ng-container formArrayName="userMenusImpls2">
                                        <ng-container
                                            *ngFor="let subMenuAccess2 of subMenuAccess.get('userMenusImpls2').controls; let k=index"
                                            [formGroupName]="k">
                                            <tr>
                                                <td width="90px" class="multiSelect">
                                                    <input type="checkbox" [checked]="false" formControlName="checked"
                                                        (change)="onSelectSubMenu2(menuAccess,subMenuAccess,subMenuAccess2)">
                                                </td>
                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{{subMenuAccess2.value.menuName}}</td>
                                                <!-- <td><select class="form-control-sm selectpicker bg-white"
                                                        formControlName="roleId" data-style="btn-info"
                                                        *ngIf="menuAccess.value.menuCode=='admin' || menuAccess.value.menuCode=='bank'">
                                                        <option value="1">Maker</option>
                                                        <option value="2">Checker</option>
                                                    </select>
                                                    <select class="form-control-sm selectpicker bg-white"
                                                        formControlName="roleId" data-style="btn-info"
                                                        *ngIf="menuAccess.value.menuCode!='admin' && menuAccess.value.menuCode!='bank'">
                                                        <option value="1" selected>NA</option>
                                                    </select>
                                                </td> -->
                                                <td><select class="form-control-sm selectpicker bg-white"
                                                        formControlName="isAccessAllowed" data-style="btn-info">
                                                        <option value="Y">Yes</option>
                                                        <option value="N">No</option>
                                                    </select></td>
                                                <td>
                                                    <select class="form-control-sm selectpicker bg-white"
                                                        formControlName="accessType" data-style="btn-info"
                                                        *ngIf="subMenuAccess2.value.menuCode!='common' && subMenuAccess2.value.menuCode!='enit'">
                                                        <option value="R">Read-only</option>
                                                        <option value="W">Read-Write</option>
                                                    </select>
                                                    <select class="form-control-sm selectpicker bg-white"
                                                        formControlName="accessType" data-style="btn-info"
                                                        *ngIf="subMenuAccess2.value.menuCode=='common'">
                                                        <option value="R" selected>Read-only</option>
                                                    </select>
                                                    <select class="form-control-sm selectpicker bg-white"
                                                        formControlName="accessType" data-style="btn-info"
                                                        *ngIf="subMenuAccess2.value.menuCode=='enit'">
                                                        <option value="W">Read-Write</option>
                                                    </select>
                                                </td>
                                                <!-- <td><span
                                                        [ngClass]="{'green':subMenuAccess2.value.isActive=='Y','red':subMenuAccess2.value.isActive=='N'}"
                                                        class="mfont">{{subMenuAccess2.value.isActive=='Y'?'Active':'Deactive'}}</span>
                                                </td> -->
                                                <!-- <td *ngIf="action!='view'">
                                                    <svg *ngIf="!subMenuAccess2.value.checkedBy"
                                                        (click)="checkRecordSubMenu2(menuAccess,subMenuAccess,subMenuAccess2,'approve')"
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-check-square"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                        <path
                                                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                    </svg>
                                                    <svg *ngIf="subMenuAccess2.value.checkedBy"
                                                        (click)="checkRecordSubMenu2(menuAccess,subMenuAccess,subMenuAccess2,'reject')"
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="fillColor bi bi-check-square-fill"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                                    </svg>
                                                </td> -->
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
            <div class="row justify-content-start pb-2 pl-2">
                <div class="col-lg-8">
                    <div class="row justify-content-start">
                        <div class="col-lg-2 usercontrol">
                            <button class="btn btn-primary full-width" [disabled]="!!(action=='view')">Submit</button>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-cancel full-width" type="button" routerLink="../"
                                skipLocationChange>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="accessDetails" tabindex="-1" role="dialog" aria-labelledby="accessDetailsLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <form [formGroup]="multiSelectMenu">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 form-group mt-2 popup-deco text-center">
                            <h5>{{user.userLoginId}}</h5>
                            <p>Select the access details</p>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-12">
                            <label for="roleId">Role </label><br>
                            <select class="form-control form-control-sm selectpicker bg-white" id="roleId"
                                formControlName="roleId" data-style="btn-info" title="Select Role">
                                <option value="1">Maker</option>
                                <option value="2">Checker</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-12">
                            <label for="isAccessAllowed">Is access allowed</label><br>
                            <select class="form-control form-control-sm selectpicker bg-white" id="isAccessAllowed"
                                formControlName="isAccessAllowed" data-style="btn-info"
                                title="Select Is Access Allowed">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label for="accessType">Access Type</label><br>
                            <select class="form-control form-control-sm selectpicker bg-white" id="accessType"
                                formControlName="accessType" data-style="btn-info" title="Select Access Type">
                                <option value="R">Read-only</option>
                                <option value="W">Read-Write</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="btn-group d-flex" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary col-6" data-dismiss="modal" aria-label="Close"
                        (click)="onSave()">Save</button>
                    <button type="button" class="btn btn-cancel col-6" data-dismiss="modal"
                        aria-label="Close">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>