import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/service/app.service';
import { config } from 'src/app/valueObject/config';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-password-expired',
  templateUrl: './password-expired.component.html',
  styleUrls: ['./password-expired.component.css']
})
export class PasswordExpiredComponent implements OnInit {

  errorCode: string;
  message: string;
  config: any = config;
  username: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private appService: AppService) { }

  ngOnInit(): void {
    this.errorCode = this.route.snapshot.queryParams["message"];
    this.username = this.route.snapshot.queryParams["username"];
    this.message = this.config.failureResponse.login[this.errorCode];
  }

  changePassword(){
    this.router.navigate(['/login/changepassword'],{queryParams:{'page':'login', 'username':this.username}})
  }

  cancel(){
    this.appService.logout({}).pipe(
      catchError((error: any) => {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        this.router.navigate(['/login']);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.message == "SUCCESSFULL") {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('username');
        this.router.navigate(['/login']);
      }
    });
  }

}
