import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/valueObject/config';
import { Title } from '@angular/platform-browser';
import { EnitTradeService } from 'src/app/service/enit-trade.service';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-unlock-reset-modal',
  templateUrl: './unlock-reset-modal.component.html',
  styleUrls: ['./unlock-reset-modal.component.css']
})
export class UnlockResetModalComponent implements OnInit {

  modalData: any={};
  modalForm: FormGroup;
  date: any;
  config: any =config;
  entityType: any;
  memCode: any;
  memName: any;
  menucode ="";
  user: any = {};
  access: any = {};
  memId: any;
  entityCode: any;
  processDate: any;
  requestDate: any;

  @ViewChild('selectpicker') selectPicker: ElementRef;
  constructor(
    private enitTradeService: EnitTradeService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private userDetailsService: UserDetailsService,
    private datePipe: DatePipe
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['pcmReqRefNo']) {
        this.modalData = this.enitTradeService.getReqByRef(params['pcmReqRefNo']);
      }
    });
   }
   get f(){
    return this.modalForm.controls;
   }

   ngAfterViewInit() {
    $('.selectpicker').selectpicker();
  }

  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.entityType = this.user.entityType;
    this.entityCode = this.user.entityCode
    this.memId = (<number>this.user.entityCode-10000)+"";
   // this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.titleService.setTitle('Password Reset / Unlock Request')
    this.processDate = this.datePipe.transform(this.modalData.pcmModifyDate, 'dd-MMM-yyyy');
    this.requestDate = this.datePipe.transform(this.modalData.pcmCreatedDate, 'dd-MMM-yyyy')
    this.modalForm = this.fb.group({
      refNumber:[this.modalData.pcmReqRefNo],
      memCode:[this.modalData.memCode],
      memName:[this.modalData.memName],
      mgrUserId:[this.modalData.pcmCorpMgrUsrId],
      status:[this.modalData.pcmStatus],
      reqDateTime:[this.requestDate],
      procDateTIme:[this.processDate],
      UpdateStatus:['', Validators.required]
    })

    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
    
  }

  back(){
    if(this.entityType != 'ENIT'){
    this.router.navigate(['tradeAdmin/ePasswordResetUserId/displayAdminPasswordMis'])
    }
    else if(this.entityType =='ENIT'){
      this.router.navigate(['eTrade/eUnlockuser/displayPasswordMis'])
    }
  }
  reset(){
    this.f.UpdateStatus.setValue(null);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }



  onSubmit(){
    let requestObject = {
    "memCode": this.modalData.memCode,
    "pcmReqId": this.modalData.pcmReqId,
    "pcmAction": this.modalData.pcmAction,
    "pcmReqRefNo": this.modalData.pcmReqRefNo,
    "pcmCorpMgrUsrId": this.modalData.pcmCorpMgrUsrId,
    "pcmStatus":this.f.UpdateStatus.value,
    "pcmEmailId": this.modalData.pcmEmailId,
    }

    this.enitTradeService.updateRequestPwd(requestObject).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any)=>{
      if(response.statusCode == 200){
        this.toastr.success("Request Updated Successfully");
        this.router.navigate(['tradeAdmin/ePasswordResetUserId/displayAdminPasswordMis'])
      }
      else{
        this.toastr.error(response.message);
      }
    })
  }

}
