import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComponentComponent } from '../component.component';
import { AccessdetailsComponent } from './accessdetails/accessdetails.component';
import { UseraccesslistComponent } from './useraccesslist/useraccesslist.component';



const routes: Routes = [
  { path: '', component: UseraccesslistComponent },
  { path: 'accessmapping', component: AccessdetailsComponent }
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccessmanagementRoutingModule { }
