import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { config } from 'rxjs/internal/config';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { EnitComplianceServiceService } from 'src/app/service/enit-compliance-service.service';
declare var $: any;

@Component({
  selector: 'app-cyber-security-admin-list',
  templateUrl: './cyber-security-admin-list.component.html',
  styleUrls: ['./cyber-security-admin-list.component.css'],
})
export class CyberSecurityAdminListComponent implements OnInit {
  entityType: any;
  user: any = {};
  access: any = {};
  searchForm: FormGroup;
  QuarterstartDateList: any = [];
  cisreport: any = [];
  config: any = config;
  memList = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bsValue: any;
  bsValue1: any;
  memName: string;
  memCode: string;
  fDate: any;
  tDate: any;
  menucode = 'cyberSecReportAdmin';
  maxDate: Date = new Date();

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(
    private toastr: ToastrService,
    private titleService: Title,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private enitComplianceServiceService: EnitComplianceServiceService,
    private formbuilder: FormBuilder,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe,
  ) {}

  get f() {
    return this.searchForm.controls;
  }

  ngOnInit(): void {
    this.user = this.userDetailsService.getUserDetails() || {};
    this.access = this.userDetailsService.checkUserAccess(this.menucode) || {};
    this.entityType = this.user.entityType;
    this.memCode = this.user.entityCode;
    this.memName = this.user.userName;
    this.getAllMembers();
    this.titleService.setTitle('Cyber Intelligence and Security Report');
    $('.selectpicker').selectpicker();
    this.searchForm = this.fb.group({
      memname: [],
      memCode: [],
      submissionFDate: [''],
      submissiontTDate: [''],
    });
  }

  
  setDatatable(){
    $("#cisMIS").DataTable().destroy(); 
    setTimeout(() => {
      $("#cisMIS").DataTable({
        responsive: true,
        processing: true,
        orderCellsTop: true,
        dom: "Blrtip",
        buttons: [{
          extend: 'copy',
          titleAttr: 'Copy',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          }
        }, {
          extend: 'excel',
          titleAttr: 'Export to excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          pageSize: 'A4',
          titleAttr: 'Download as PDF',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          }
        }],
      })
      this.cdref.detectChanges();
    }, 1);
  }

  getAllMembers() {
    this.enitComplianceServiceService.getAllMembers().pipe(
        catchError((error: any) => {
          this.memList = [];
          this.dtTrigger.next(true);
          this.cdref.detectChanges();
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      )
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.memList = response.data.memberDetails;
          this.cdref.detectChanges();
          $('.selectpicker').selectpicker('refresh');
        } else {
          this.memList = [];
          //this.dtTrigger.next(true);
          this.cdref.detectChanges();
          this.toastr.error(response.message);
        }
      });
  }

  onSubmit() {
    this.spinner.show();
    $('#cisMIS').DataTable().destroy();
    let requestObject = {
      "memberId":this.f.memCode?.value,
      "year":null,
      "quaterName":null,
      "submissionStartDate":this.datepipe.transform(this.f.submissionFDate.value, 'yyyy-MM-dd') || null,
      "submissionEndDate":this.datepipe.transform(this.f.submissiontTDate.value, 'yyyy-MM-dd') || null,
      "incidentRequestId":null,
      "pageSize":3,
      "pageNumber":2,
      "orderByFieldId":null,
      "orderType":null
    };
    this.enitComplianceServiceService.getCyberSecurityReport(requestObject)
      .pipe(
        catchError((error: any) => {
          this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
          this.cisreport = [];
          this.setDatatable();
          if (error.status != 200) {
            return throwError(error.error);
          }
        })
      )
      .subscribe((respone: any) => {
        this.spinner.hide();
        if (respone.statusCode == 200) {
          this.cisreport = respone.data;
          this.enitComplianceServiceService.addcisList(this.cisreport);
          this.setDatatable();
        } else {
          this.toastr.error(respone.message);
          this.setDatatable();
        }
      });
  }

  open(id: number) {
    this.router.navigate(['complianceAdmin/cyberSecurityAdmin/cyberSecurityList/cyberSecurityReport'], { queryParams: { id: id } });
  }

  memCodeChange() {
    let member = this.memList.find((data) => data.memberId == this.f.memCode.value);
    this.f.memname.setValue(member.memberName);
    this.cdref.detectChanges();
    $('.selectpicker').selectpicker('refresh');
  }

  onReset() {
    this.f.submissionFDate.reset();
    this.f.submissiontTDate.reset();
    this.f.memname.reset();
    this.f.memCode.reset();
    $('.selectpicker').selectpicker('refresh');
  }
}
